/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionLogResponse } from '../models/ActionLogResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ActionLogService {

    /**
     * Get Action Log
     * Get action log listing.
     * @returns ActionLogResponse Successful Response
     * @throws ApiError
     */
    public static getActionLog(): CancelablePromise<ActionLogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/action_log/',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Clear Global Selection
     * Clear global selection of animals, pups, cages and tanks.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static clearGlobalSelection(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/action_log/clear_global_selection',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
