import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    Subscribable,
} from "knockout";

interface TypedResponse<T> extends Response {
    json(): Promise<T>;
}

type FetchFactory<T> = (signal: AbortSignal) => Promise<TypedResponse<T>>;

interface FetchExtenderParameters<T> {
    fn: FetchFactory<T>;
    cleanup?: (arg0: any) => any;
    undefined?: any;
    disable?: Subscribable<boolean>;
}

declare module "knockout" {

    // noinspection JSUnusedGlobalSymbols
    export interface ExtendersOptions<T> {
        fetch?: FetchFactory<T> | FetchExtenderParameters<T>;
    }

}

interface ExtenderObservables {
    inProgress?: Observable<boolean>;
    lastSuccess?: Observable<Date | undefined>;
    forceReload?: () => void;
    disable?: Observable<boolean>;
}

export type FetchExtended<T> = T & ExtenderObservables;

/**
 * KnockoutJS extender to fill an observable with data loaded asyncrounously (via
 * AJAX), e.g. loading a list of licences depending on the selected species.
 * Usage: provide a function or an object with fn and json args.
 *   <target> = ko.observable.extend(
 *      {fetch: <function or object with { fn: <function>,
 *                                         json: <bool>,
 *                                         disable:true,
 *                                         cleanup: <function>,
 *                                         undefined: undefined }})
 *
 * The function will be called to update the observables value. It should
 * return a Promise object resolving to the new value result. The resolved
 * value is decoded via JSON.parse. If disable is an observable, this
 * observable will be used as disable (see below). If the fetch
 * is disabled or fn returns undefined, the value of the option 'undefined'
 * is set.
 *
 * A function given as argument for cleanup, will receive the data in case
 * of success as first argument. Its return value will be used as value then.
 * This is intended to be used if the return value is not in the shape to
 * be used directly.
 *
 * The observable gets extended with the following properties:
 *     inProgress:
 *          An observable that will be set to true while waiting for a new value
 *          (use this to indicate loading status of the target observable).
 *
 *     disable:
 *          An observable (false by default, set with the disable option), when
 *          set to True, no updates are made until it is set to false (setting
 *          to false will force an update).
 *
 *     lastSuccess:
 *          An observable, that contains the Date() of the last successful
 *          evaluation (e.g. to show how recent data is) or unknown until that.
 *
 *     forceReload:
 *          A function to call to force data reload.
 *
 */
ko.extenders.fetch = <T>(
    target: FetchExtended<Observable<T>> | FetchExtended<ObservableArray<T>>,
    options: FetchFactory<T> | FetchExtenderParameters<T>,
) => {
    // options
    if (typeof options === "function") {
        options = { fn: options };
    }

    const fn = options.fn;
    const cleanUpFn = options.cleanup;
    const undefinedValue = options.undefined;

    // state
    let controller = new AbortController();
    let currentPromise: Promise<TypedResponse<T>> | undefined;
    const trigger = ko.observable().extend({ notify: "always" });
    target.inProgress = ko.observable(false);
    target.lastSuccess = ko.observable(undefined);
    target.forceReload = function () {
        trigger.valueHasMutated();
    };
    target.disable = ko.isObservable(options.disable) ? options.disable : ko.observable(!!options.disable);

    // reactive fetching
    ko.computed(() => {
        // allow to force reevaluation by changing this value
        trigger();

        // abort any in-flight evaluation
        controller.abort();
        controller = new AbortController();

        if (target.disable()) {
            target(undefinedValue);
            return target;
        }

        const signal = controller.signal;
        const promise = fn(signal);
        currentPromise = promise;

        if (promise) {
            target.inProgress(true);
            promise
                .then((response) => response.json())
                .then((data) => {
                    if (typeof cleanUpFn === "function") {
                        return cleanUpFn(data);
                    }
                    return data;
                })
                .then((data) => {
                    if (promise === currentPromise) {
                        target(data);
                        target.lastSuccess(new Date());
                    }
                })
                .finally(() => {
                    if (promise === currentPromise) {
                        target.inProgress(false);
                    }
                });
        }
    });
    return target;
};
