/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCommentSchema } from '../models/AddCommentSchema';
import type { BlindCommentWidgetSeed } from '../models/BlindCommentWidgetSeed';
import type { Body_getCommentWidgetSeed } from '../models/Body_getCommentWidgetSeed';
import type { CommentAttributeDetails } from '../models/CommentAttributeDetails';
import type { CommentWidgetSeed } from '../models/CommentWidgetSeed';
import type { PostCommentResponse } from '../models/PostCommentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommentsService {

    /**
     * Mark Comment Visible
     * Mark a comment as visible.
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static markCommentVisible({
        commentId,
    }: {
        commentId: number,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/comments/{comment_id}/visible',
            path: {
                'comment_id': commentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark Comment Hidden
     * Mark a comment as hidden.
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static markCommentHidden({
        commentId,
    }: {
        commentId: number,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/comments/{comment_id}/visible',
            path: {
                'comment_id': commentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Comment Widget Seed
     * Get a seed for the comment widget.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCommentWidgetSeed({
        requestBody,
    }: {
        requestBody: Body_getCommentWidgetSeed,
    }): CancelablePromise<(CommentWidgetSeed | BlindCommentWidgetSeed)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/comments/seed',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Comment
     * Post a comment.
     *
     * Return the comment ID and a new seed for the comment widget.
     * @returns PostCommentResponse Successful Response
     * @throws ApiError
     */
    public static postComment({
        requestBody,
    }: {
        requestBody: AddCommentSchema,
    }): CancelablePromise<PostCommentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/comments/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Attributes
     * Update the comment attribute list.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCommentAttributeList({
        requestBody,
    }: {
        requestBody: Array<CommentAttributeDetails>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/comments/attributes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
