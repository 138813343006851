/**
 * Show a popup to set the weight for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    AjaxResponse,
    cgiScript,
    checkDecimal,
    getFormattedCurrentDate,
    getFormData,
    getUrl,
    isInvalidCalendarDate,
} from "../lib/utils";

import template from "./setWeight.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    weight: string;
    species_weight_unit: string;
}

class SetWeightViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly weighingDate: CheckExtended<Observable<string>>;
    private readonly weight: CheckExtended<Observable<string>>;
    private readonly errors: ko.ObservableArray<string>;
    private readonly errorMessages: ko.PureComputed<string[]>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly seed: FetchExtended<Observable<AjaxResponse<Seed>>>;


    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.weight = ko.observable().extend({
            invalid: (v) => {
                return v ? checkDecimal(v, session.localesConf.decimalSymbol, 5, 3) : true;
            },
        });

        this.weighingDate = ko.observable(getFormattedCurrentDate()).extend({
            invalid: (v) => {
                return !v ? getTranslation("Please enter a valid date") :
                    isInvalidCalendarDate(v) ? getTranslation("Invalid date") :
                    false;
            },
        });

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.animalId) {
                    return fetch(getUrl(cgiScript("set_weight.py"), { animal_id: this.animalId }), { signal });
                } else if (this.pupId) {
                    return fetch(getUrl(cgiScript("set_weight.py"), { pup_id: this.pupId }), { signal });
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.weight(seed.weight);
            }
        });

        this.errorMessages = ko.pureComputed(() => {
            const res = _.compact([
                this.weight.errorMessage(),
                this.weighingDate.errorMessage(),
            ]);

            // extend the list with server side error messages
            return res.concat(this.errors() || []);
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.seed.inProgress() ||
                     this.weighingDate.isInvalid() ||
                     this.weight.isInvalid());
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            weight: this.weight(),
            weighing_date: this.weighingDate(),
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        fetch(cgiScript("set_weight.py"), { method: "POST", body: this.getFormData() })
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error",
                );
            });
    };
}

export const showSetWeight = dialogStarter(SetWeightViewModel, template, params => ({
    name: "SetWeight",
    width: 280,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    title: params.title,
}));
