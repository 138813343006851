import {
    components,
    Observable,
} from "knockout";
import * as ko from "knockout";

import { getElementWindow } from "../../lib/utils";

import template from "./colorPicker.html";
import "./colorPicker.scss";

interface ColorPickerParams {
    showNoColorOption?: boolean;
    editable?: boolean;
    fieldName?: string;
    initialColor?: string;
    selectedColor?: Observable<string>;
    changeColorCallback?: (color: string) => void;
}


// supported highlight colors in lists
const availableColors = [

    { value: "color-dark-black", label: "Black" },
    { value: "color-dark-chocolate", label: "Chocolate" },
    { value: "color-dark-maroon", label: "Maroon" },
    { value: "color-dark-coffee", label: "Coffee" },
    { value: "color-dark-palatine", label: "Palatine" },
    { value: "color-dark-violet", label: "Violet" },
    { value: "color-dark-navy", label: "Navy" },
    { value: "color-dark-midnight-green", label: "Midnight Green" },
    { value: "color-dark-olive", label: "Olive" },

    // Middle Dark
    { value: "color-dark-black-reddish", label: "Black Reddish" },
    { value: "color-dark-brown", label: "Brown" },
    { value: "color-dark-burgundy", label: "Burgundy" },
    { value: "color-light-orange", label: "Orange" },
    { value: "color-dark-purple", label: "Purple" },
    { value: "color-dark-indigo", label: "Indigo" },
    { value: "color-dark-blue", label: "Blue" },
    { value: "color-dark-teal", label: "Teal" },
    { value: "color-dark-moss", label: "Moss" },

    // Bright level
    { value: "color-dark-black-bluish", label: "Black Bluish" },
    { value: "color-light-sand", label: "Sand" },
    { value: "color-dark-red", label: "Red" },
    { value: "color-light-yellow", label: "Yellow" },
    { value: "color-dark-fuchsia", label: "Fuchsia" },
    { value: "color-dark-berry", label: "Berry" },
    { value: "color-light-french-blue", label: "French Blue" },
    { value: "color-light-turquoise", label: "Turquoise" },
    { value: "color-light-green", label: "Green" },

    // Soft colors
    { value: "color-light-gray", label: "Gray" },
    { value: "color-light-camel", label: "Camel" },
    { value: "color-light-pink", label: "Pink" },
    { value: "color-light-pale-yellow", label: "Pale Yellow" },
    { value: "color-light-dusty-rose", label: "Dusty Rose" },
    { value: "color-light-lavender", label: "Lavender" },
    { value: "color-light-sky", label: "Sky" },
    { value: "color-light-aqua", label: "Aqua" },
    { value: "color-light-mantis", label: "Mantis" },

    // Pale Colores
    { value: "color-light-silver", label: "Silver" },
    { value: "color-light-beige", label: "Beige" },
    { value: "color-light-baby-pink", label: "Baby Pink" },
    { value: "color-light-cream", label: "Cream" },
    { value: "color-light-mauve", label: "Mauve" },
    { value: "color-light-pale-pink", label: "Pale Pink" },
    { value: "color-light-baby-blue", label: "Baby Blue" },
    { value: "color-light-pale-turquoise", label: "Pale Turquoise" },
    { value: "color-light-pale-green", label: "Pale Green" },
];

/**
 * Color picker to choose from predefined colors
 *
 * @param showNoColorOption
 *        Whether there should be an option, on the bottom of all colors, to
 *        set an empty color (to remove the color of a subject), e.g. for
 *        color pickers in QuickSelect.
 *
 * @param editable
 *        If true, the color is editable, otherwise it's just a placeholder for
 *        the color picker button should be shown.
 *
 * @param fieldName
 *        The `name` attribute of the color picker when the result is sent in
 *        a form.
 *
 * @param initialColor
 *        Which color is set initially, default is 'color-dark-black'.
 *
 * @param selectedColor
 *        Outgoing observable that holds the selected color key (for further
 *        handling in Javascript).
 *
 * @param changeColorCallback
 *        Callback when the user select a color in the color picker (e.g.
 *        in QS enable the action checkbox).
 */
class ColorPickerViewModel {

    public availableColors: {
        value: string;
        label: string;
    }[];
    public editable: boolean;
    public visible: Observable<boolean>;
    public fieldName: string;
    public selectedColor: Observable<string>;
    public onChange: () => void;
    private showNoColorOption: boolean;

    constructor({
        showNoColorOption = true,
        editable = true,
        fieldName,
        initialColor,
        selectedColor,
        changeColorCallback,
    }: ColorPickerParams, componentElement: HTMLDivElement) {

        this.availableColors = availableColors;
        this.showNoColorOption = showNoColorOption;

        this.editable = editable !== false;
        this.visible = ko.observable(false);
        this.fieldName = fieldName;
        this.selectedColor = ko.isObservable(selectedColor) ? selectedColor : ko.observable();
        this.selectedColor(initialColor !== undefined ? initialColor : "color-dark-black");
        this.selectedColor.subscribe(() => this.visible(false));

        if (typeof changeColorCallback === "function") {
            this.selectedColor.subscribe(changeColorCallback);
        }

        // Close picker on click outside
        getElementWindow(componentElement).document.addEventListener("click", (e) => {
            if (!componentElement.contains(e.target as HTMLElement)) {
                this.visible(false);
            }
        });
    }

    private show = () => {
        if (this.editable) {
            this.visible(!this.visible());
        }
    };



}

export class ColorPickerComponent {

    constructor() {

        return {
            viewModel: {
                createViewModel: (params: ColorPickerParams, componentInfo: components.ComponentInfo) => {
                    return new ColorPickerViewModel(params, componentInfo.element as HTMLDivElement);
                },
            },
            template: template,
        };
    }

}
