/**
 * Show a pop-up to create new microwell plate.
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    NewMicroWellPlateDialogOptions,
    ExistentMicroWellPlateDialogOptions,
    IdLabelProperty,
    IdNameProperty,
    MicroWellPlateMode,
    WellplatesService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";

import template from "./microwellPlateDetails.html";


type MicroWellPlateDialogOptions = NewMicroWellPlateDialogOptions | ExistentMicroWellPlateDialogOptions;

interface MicrowellPlateDetailParams {
    wellplateId?: number;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

class MicrowellPlateDetailsViewModel {
    private readonly dialog: HtmlDialog;
    private readonly params: MicrowellPlateDetailParams;

    public readonly formatId: Observable<number>;
    public readonly code: Observable<string>;
    public readonly referenceText: Observable<string>;
    public readonly fillDirection: Observable<"horizontal" | "vertical">;
    public readonly responsibleId: CheckExtended<Observable<number>>;
    public readonly responsibleCaption: Observable<string>;
    public readonly seed: FetchBackendExtended<Observable<MicroWellPlateDialogOptions | undefined>>;
    public readonly possibleFormats: ObservableArray<IdLabelProperty>;
    public readonly possibleFillDirections: ObservableArray<{ value: string; label: string }>;
    public readonly possibleModes: ObservableArray<{ value: string; label: string }>;
    public readonly possibleResponsibles: ObservableArray<IdNameProperty>;
    public readonly canApply: PureComputed<boolean>;

    private readonly editable: Observable<boolean>;
    private readonly applyInProgress: Observable<boolean>;
    private readonly reloadRequired: Observable<boolean>;
    private readonly displayFormat: Observable<"overview" | "listing">;
    private readonly mode: Observable<MicroWellPlateMode | null>;
    public readonly selectedPosition: Observable<string | null>;

    constructor(dialog: HtmlDialog, params: MicrowellPlateDetailParams) {
        this.dialog = dialog;
        this.params = params;

        this.code = ko.observable();
        this.formatId = ko.observable();
        this.fillDirection = ko.observable();
        this.referenceText = ko.observable("");
        this.responsibleId = ko.observable().extend({
            invalid: (v) => {
                if (!v) {
                    return getTranslation("Please select a responsible");
                }

                return false;
            },
        });
        this.responsibleCaption = ko.observable();
        this.editable = ko.observable(session.userPermissions.microwell_plate_edit);
        this.reloadRequired = ko.observable(false);
        this.applyInProgress = ko.observable(false);
        this.possibleFormats = ko.observableArray();
        this.possibleFillDirections = ko.observableArray();
        this.possibleModes = ko.observableArray();
        this.possibleResponsibles = ko.observableArray();
        this.displayFormat = ko.observable("overview");
        this.mode = ko.observable();
        this.selectedPosition = ko.observable(null);

        /**
         * Get options for dialog (popup).
         */
        this.seed = ko.observable().extend({
            fetchBackend: () => WellplatesService.getWellplatesDialogSeed({ wellplateId: this.params.wellplateId }),
        });

        this.seed.subscribe((seed) => {
            this.possibleFormats(seed.possible_formats);
            this.possibleFillDirections(seed.possible_fill_directions);
            this.possibleModes(seed.possible_modes);
            this.possibleResponsibles(seed.possible_responsibles);
            this.fillDirection("vertical"); //preselect "vertical" as default

            if (!this.params.wellplateId) {
                this.responsibleId(session.userId);
            } else if ("code" in seed) {
                this.code(seed.code);
                this.formatId(seed.format_id);
                this.referenceText(seed.reference);
                this.fillDirection(seed.fill_direction);
                this.responsibleId(seed.responsible_id);
                this.mode(seed.mode);

                if (!seed.responsible_id) {
                    this.responsibleCaption(getTranslation("None"));
                }

                // if wellplate is “in use” set the editable flag to 'false' to restrict editing of fields
                if (Object.keys(seed.used_positions).length) {
                    this.editable(false);
                }

                // wellplate is “exported” or closed “exported” or "deleted"
                if (seed.status !== "active") {
                    this.editable(false);
                }
            }
        });

        this.canApply = ko.pureComputed(() => {
            return this.code() && this.formatId() && this.responsibleId.isValid() && this.applyInProgress() === false;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (params.closeCallback) {
                params.closeCallback();
            }

            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);
        let request;
        if (!this.params.wellplateId) {
            request = WellplatesService.addWellplate({
                code: this.code(),
                formatId: this.formatId(),
                fillDirection: this.fillDirection(),
                mode: this.mode(),
                reference: this.referenceText(),
                responsibleId: this.responsibleId(),
            });
        } else if (this.editable()) {
            request = WellplatesService.updateWellplate({
                wellplateId: this.params.wellplateId,
                code: this.code(),
                formatId: this.formatId(),
                fillDirection: this.fillDirection(),
                mode: this.mode(),
                reference: this.referenceText(),
                responsibleId: this.responsibleId(),
            });
        } else {
            request = WellplatesService.updateUsedWellplate({
                wellplateId: this.params.wellplateId,
                responsibleId: this.responsibleId(),
            });
        }
        request
            .then(() => {
                this.dialog.close();
                if (this.params.wellplateId) {
                    notifications.showNotification(getTranslation("Wellplate updated"), "success");
                } else {
                    notifications.showNotification(getTranslation("Wellplate added"), "success");
                }
            })
            .catch((error) => {
                if (typeof error?.body?.detail === "string") {
                    notifications.showNotification(error.body.de, "error");
                } else {
                    notifications.showNotification(
                        getTranslation("Action failed. The data could not be saved. Please try again."),
                        "error",
                    );
                    writeException(error);
                }
            })
            .finally(() => this.applyInProgress(false));
    };

    public invalidatePosition = (position: string) => {
        notifications.showConfirm(
            _.template(getTranslation("Do you want to invalidate position <%- position %>?"))({ position }),
            () => {
                this.applyInProgress(true);
                WellplatesService.invalidateWellplatePosition({
                    wellplateId: this.params.wellplateId,
                    wellplatePosition: position,
                })
                    .then(() => showMicrowellPlateDetails(this.params))
                    .finally(() => this.applyInProgress(false));
            },
        );
    };
}

export const showMicrowellPlateDetails = htmlDialogStarter(MicrowellPlateDetailsViewModel, template, params => ({
    name: "MicrowellPlateDetails",
    width: 450,
    closeOthers: true,
    position: { inset: { top: 20 } },
    title: params.wellplateId ? getTranslation("Wellplate") : getTranslation("Create wellplate"),
}));
