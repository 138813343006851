import { observable } from "knockout";

import {
    AnimalsService,
    CancelablePromise,
    GetAnimalCohortIdResponse,
    PupsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";
import { EmptyObject } from "../lib/utils";

import template from "./setCohortId.html";

interface AnimalReference {
    animalId: number;
}

interface PupReference {
    pupId: number;
}

interface Params {
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetCohortIdViewModel {
    // params
    private readonly dialog;
    private readonly params;

    // state
    private readonly cohortId = observable("");
    private readonly reloadRequired = observable(false);
    private readonly fetchInProgress = observable(true);
    private readonly updateInProgress = observable(false);

    constructor(dialog: HtmlDialog, params: Params & (AnimalReference | PupReference)) {
        this.dialog = dialog;
        this.params = params;
        let request: CancelablePromise<GetAnimalCohortIdResponse>;
        if ("animalId" in params) {
            request = AnimalsService.getAnimalCohortId({ animalId: params.animalId });
        } else if ("pupId" in params) {
            request = PupsService.getPupCohortId({ pupId: params.pupId });
        } else {
            throw new Error("Invalid parameters.");
        }
        request
            .then((response) => {
                this.cohortId(response.cohort_id);
                this.dialog.setTitle(
                    getTranslation("Set cohort ID for %s.").replace("%s", String(response.eartag_or_id)),
                );
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.params.closeCallback) {
                this.params.closeCallback();
            }

            if (this.params.reloadCallback && this.reloadRequired()) {
                this.params.reloadCallback();
            }
        });
    }

    public applyLabel = () => {
        this.reloadRequired(true);
        this.updateInProgress(true);

        let request: CancelablePromise<EmptyObject>;
        if ("animalId" in this.params) {
            request = AnimalsService.setAnimalCohortId({
                animalId: this.params.animalId,
                cohortId: this.cohortId(),
            });
        } else if ("pupId" in this.params) {
            request = PupsService.setPupCohortId({
                pupId: this.params.pupId,
                cohortId: this.cohortId(),
            });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then(() => {
                this.dialog.close();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.updateInProgress(false));
    };
}

export const showSetCohortId = htmlDialogStarter(SetCohortIdViewModel, template, params => ({
    name: "SetCohortId",
    width: 300,
    position: { anchor: params.eventTarget, dialogHandle: "right top" },
    closeOthers: true,
    title: getTranslation("Set cohort ID."),
}));
