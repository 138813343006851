import { Observable } from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import { showOrderRequestDetails } from "../../dialogs";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    isInvalidCalendarDate,
    normalizeDate,
    printUrl,
} from "../../lib/utils";
import { pyratFrontend } from "../../pyratFrontend";

import filterTemplate from "./orderRequestListFilter.html";



interface Arguments extends ListViewArguments {
    export_args: any;
}


const OrderRequestListFilters = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {
        id: ListFilterItem,

        owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        responsible_basic_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        responsible_staff_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        initiator_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        delivery_date_string: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "label");
            }
        },

        delivery_date_from: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("delivery_date_to"), compareFromDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        delivery_date_to: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("delivery_date_from"), compareToDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        close_date_from: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("close_date_to"), compareFromDate),
                });

                // set status filter to "Closed" when close date is selected
                this.value.subscribe((v) => {
                    if (v && !isInvalidCalendarDate(v)) {
                        const statusFilter = filter.allFilters().status;
                        statusFilter.value("Closed");
                    }
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        close_date_to: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("close_date_from"), compareToDate),
                });

                // set status filter to "Closed" when close date is selected
                this.value.subscribe((v) => {
                    if (v && !isInvalidCalendarDate(v)) {
                        const statusFilter = filter.allFilters().status;
                        statusFilter.value("Closed");
                    }
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        status: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                // reset close date range if selected status not equal 'Closed'
                value.subscribe((v) => {
                    if (v !== "Closed") {
                        filter.allFilters().close_date_from.value(undefined);
                        filter.allFilters().close_date_to.value(undefined);
                    }
                });
                this.text = _.map(seed.possible_values, "label");
            }
        },

        priority: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "label");
            }
        },

        origin_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        species_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => {
                    return this.initialized() === true || this.serialize() === seed.default_value;
                };
            }
        },

        comment: ListFilterItem,
    };
};


class OrderRequestList {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: OrderRequestListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Order request filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("new-request", () => {
            pyratFrontend.dialogs.showOrderRequestDetails({
                orderRequestId: null,
                reloadCallback: this.listView.reload,
            });
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        // Table Body

        // links to detail popups

        listViewElement
            .querySelectorAll("td:not(.imported_animals, .exported_animals)")
            .forEach((element: HTMLElement) => {
                element.classList.add("clickable");
                element.setAttribute("title", getTranslation("Show order details"));
            });

        this.listView.onCellClick("td:not(.imported_animals, .exported_animals)", (args) => {
            this.showOrderRequestDetailsDialog(args.rowId, parseInt(args.rowId, 10));
        });

        this.listView.onCellClick("td.imported_animals a", (args) => {
            pyratFrontend.top.mainMenu.openAndResetListFilter("get_animal_list", {
                animalid: _.map(String((args.event.target as HTMLLinkElement).dataset.animalIds).split(","),
                    _.partial(parseInt, _, 10)),
                state: "live",
            });
        });

        this.listView.onCellClick("td.exported_animals a", (args) => {
            pyratFrontend.top.mainMenu.openAndResetListFilter("get_animal_list", {
                animalid: _.map(String((args.event.target as HTMLLinkElement).dataset.animalIds).split(","),
                    _.partial(parseInt, _, 10)),
                state: "live",
            });
        });
    }

    public showOrderRequestDetailsDialog = (highlightRowId: string, openOrderRequestId: number, loadCallback?: () => void) => {
        showOrderRequestDetails({
            orderRequestId: openOrderRequestId,
            closeCallback: () => {
                this.listView.unHighlightRow(highlightRowId);
            },
            reloadCallback: () => {
                this.listView.reload({ flashRowId: highlightRowId });
            },
            loadCallback: loadCallback,
        });
        this.listView.highlightRow(highlightRowId);
    };
}

export const initOrderRequestList = (args: Arguments): void => {
    const orderRequestList = new OrderRequestList(document.querySelector("div.listview"), args);

    // @ts-expect-error: required by procedure shortcuts
    window.orderRequestList = orderRequestList;
};
