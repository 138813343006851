/**
 * List procedures of a subject and provide input fields to add new procedures
 *
 * @param currentProcedures
 *        Procedures that are already added to the subjects.
 *        Leave undefined when added procedures (or placeholder, if there are
 *        no procedures yet) shall not be displayed, e.g. in QuickSelect (QS).
 *
 * @param availableProcedures
 *        Procedures available to be added to the subjects.
 *        Needed only when `allowAddProcedures` is `true`.
 *
 * @param availableClassifications
 *        Classifications available for the procedure assignment.
 *
 * @param animalId
 *        Database ID of the animal for which to display and modify procedures.
 *        Only needed when the new procedures are submitted through the widget
 *        (when the widget is not embedded e.g. in a QS pop-up).
 *
 * @param pupId
 *        Database ID of the pup for which to display and modify procedures.
 *        Only needed when the new procedures are submitted through the widget
 *        (when the widget is not embedded e.g. in a QS pop-up).
 *
 * @param tankIds
 *        Database IDs of the tanks for which to display and modify procedures.
 *        Only needed when the new procedures are submitted through the widget
 *        (when the widget is not embedded e.g. in a QS pop-up).
 *
 * @param allowAddProcedures
 *        Whether current user is allowed to add new procedures to the subjects.
 *        Default `false`.
 *
 * @param reloadCallback
 *        Function to call when data has been applied, e.g. to reload a list or
 *        detail page to display the new data.
 *
 * @param initialProcedureId
 *        A database ID of a procedure that is preselected in the drop-down
 *        field.
 *
 * @param initialClassificationId
 *        A database ID of a classification that is preselected in the drop-down
 *        field.
 *  *
 * @param quickSelectActionId
 *        When embedded to a QuickSelect pop-up, give the `id` of the checkbox
 *        so that it can be linked to its label (to activate the checkbox when
 *        the label is clicked).
 *
 * @param procedureHint
 *        Some hint to display below the procedure select drop-down.
 *
 * @param valid
 *        Function to call to pass the information if all input fields of the
 *        widget contain valid data.
 *
 * @param errorMessage
 *        Function to call to pass the first error message of the input fields
 *        or `false` when there is no error.
 *
 * @param serialize
 *        Function to call to pass the new procedure data to the outside.
 *
 */

import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import { ProceduresService } from "../../backend/v1";
import { writeException } from "../../lib/excepthook";
import { getTranslation } from "../../lib/localize";
import { session } from "../../lib/pyratSession";
import { notifications } from "../../lib/pyratTop";
import {
    getFormattedCurrentDate,
    isDateLowerThanDate,
    isInvalidCalendarDate,
} from "../../lib/utils";
import { CheckExtended } from "../extensions/invalid";

import template from "./procedureWidget.html";

import "./procedureWidget.scss";

interface Procedure {
    assign_id: number;  // primary key of reference between procedure and subject
    procedure_id: number;
    procedure_name: string;
    comment: string;
    procedure_date: string;  // when the procedure is said to be performed
    actor_fullname: string;  // who added the procedure
    edit_time: string;  // when was the procedure added to the subject
    canceled: boolean;
    removeInProgress?: Observable<boolean>;
    can_delete: boolean;
    tank_id?: number;
}

interface AddProcedure {
    procedure_id: number;
    procedure_dates: string[];
    procedure_classification_id: number;
    procedure_comment: string;
}

interface Classification {
    classification_id: number;
    classification_name: string;
    license_number: string;
}

interface ProcedureWidgetParams {
    currentProcedures?: Procedure[];
    availableProcedures?: { id: number; name: string }[];
    availableClassifications?: Classification[];
    animalId?: number;
    pupId?: number;
    tankIds?: number[];
    allowAddProcedures?: boolean;
    reloadCallback?: () => void;
    initialProcedureId?: number;
    initialClassificationId?: number;
    quickSelectActionId?: string;
    procedureHint?: string;
    valid?: (value: boolean) => void;
    errorMessage?: (value: string | false) => void;
    serialize?: (value: AddProcedure) => void;
}

class ProcedureWidgetViewModel {

    public currentProcedures: Procedure[];
    public availableProcedures: { id: number; name: string }[];
    public availableClassifications: Classification[];

    public allowAddProcedures: boolean;
    public showAddProcedureSection: Observable<boolean>;
    public toggleShowAddProcedureSection: () => void;
    public quickSelectActionId: string;
    public procedureLabel: string;
    public procedureHint: string;
    public procedureId: CheckExtended<Observable<number>>;
    public procedureDate: CheckExtended<Observable<string>>;
    public repeatedProceduresVisible: Observable<boolean>;
    public repetitionDatesNameAttribute: PureComputed<string>;
    public repetitionDates: ObservableArray<CheckExtended<Observable<string>>>;
    public classificationId: Observable<number>;
    public comment: Observable<string>;
    public canSubmit: PureComputed<boolean>;
    public showRemovedRows: Observable<boolean>;
    public readonly maxDate: "today" | null;

    private reloadCallback: () => void;
    private readonly errorMessage: PureComputed<string | false>;
    private readonly serialize: PureComputed<AddProcedure>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly tankIds: number[];

    constructor(params: ProcedureWidgetParams) {
        if (ko.unwrap(params.currentProcedures) === undefined) {
            this.currentProcedures = undefined;
        } else {
            this.currentProcedures = _.map(ko.unwrap(params.currentProcedures), (procedure) => {
                return _.assignIn(procedure, {
                    removeInProgress: ko.observable(false),
                });
            });
        }

        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.tankIds = ko.unwrap(params.tankIds);

        this.allowAddProcedures = params.allowAddProcedures || false;
        this.reloadCallback = params.reloadCallback;

        this.showAddProcedureSection = ko.observable(false);
        this.toggleShowAddProcedureSection = () => {
            this.showAddProcedureSection(!this.showAddProcedureSection());
        };

        this.quickSelectActionId = params.quickSelectActionId;
        this.procedureLabel = this.quickSelectActionId ? getTranslation("Add procedure") : getTranslation("Procedure");
        this.procedureHint = params.procedureHint;

        this.maxDate = session.pyratConf.PROCEDURE_ASSIGN_FUTURE_DATE === false ? "today" : null;

        this.availableProcedures = params.availableProcedures;
        this.procedureId = ko.observable(params.initialProcedureId).extend({
            invalid: (v) => {
                if (!v) {
                    return getTranslation("Please select a procedure");
                }

                return false;
            },
        });

        this.procedureDate = ko.observable(getFormattedCurrentDate()).extend({
            invalid: (v) => {
                if (!v) {
                    return getTranslation("Please enter a valid date");
                }

                if (isInvalidCalendarDate(v)) {
                    return getTranslation("Invalid date");
                }

                return false;
            },
        });
        this.repeatedProceduresVisible = ko.observable(false);
        this.repetitionDatesNameAttribute = ko.pureComputed(() => {
            return this.repeatedProceduresVisible() ? "procedure_date" : undefined;
        });
        this.repetitionDates = ko.observableArray();

        this.availableClassifications = params.availableClassifications;
        this.classificationId = ko.observable(ko.unwrap(params.initialClassificationId));

        this.comment = ko.observable();

        this.submitInProgress = ko.observable(false);
        this.canSubmit = ko.pureComputed(() => {
            let allDatesValid;

            if (this.procedureId.isInvalid() || this.procedureDate.isInvalid() || this.submitInProgress()) {
                return false;
            }

            if (this.repeatedProceduresVisible()) {
                if (!this.repetitionDates().length) {
                    return false;
                }

                // loop through all procedure dates and check if they are valid
                allDatesValid = _.every(this.repetitionDates(), (date) => {
                    return date.isValid();
                });

                if (!allDatesValid) {
                    return false;
                }
            }

            return true;
        });
        this.canSubmit.subscribe((v) => {
            if (typeof params.valid === "function") {
                params.valid(v);
            }
        });
        this.canSubmit.notifySubscribers(this.canSubmit());

        this.errorMessage = ko.pureComputed(() => {
            if (this.procedureId.errorMessage()) {
                return this.procedureId.errorMessage();
            }

            if (this.procedureDate.errorMessage()) {
                return this.procedureDate.errorMessage();
            }

            if (this.repeatedProceduresVisible()) {
                return _.reduce(this.repetitionDates(), (errorMessage: string, date: CheckExtended<Observable<string>>) => {
                    return errorMessage || date.errorMessage();
                }, "") || false;
            }

            return false;
        });
        this.errorMessage.subscribe((v) => {
            if (typeof params.errorMessage === "function") {
                params.errorMessage(v);
            }
        });
        this.errorMessage.notifySubscribers(this.errorMessage());

        this.showRemovedRows = ko.observable(false);

        this.serialize = ko.pureComputed(() => {
            return {
                procedure_id: this.procedureId(),
                procedure_dates: this.repeatedProceduresVisible() ?
                    _.map(this.repetitionDates(), ko.unwrap) :
                    [this.procedureDate()],
                procedure_classification_id: this.classificationId(),
                procedure_comment: this.comment() || "",
            };
        });
        this.serialize.subscribe((v) => {
            if (typeof params.serialize === "function") {
                params.serialize(v);
            }
        });
        this.serialize.notifySubscribers(this.serialize());
    }

    public addProcedure = () => {
        this.submitInProgress(true);
        ProceduresService.addProcedure({
            requestBody: {
                procedure_id: this.procedureId(),
                procedure_dates: this.repeatedProceduresVisible()
                    ? _.map(this.repetitionDates(), ko.unwrap)
                    : [this.procedureDate()],
                ...(this.tankIds ? { tank_ids: this.tankIds } : {}),
                ...(this.animalId ? { animal_id: this.animalId } : {}),
                ...(this.pupId ? { pup_id: this.pupId } : {}),
                classification_id: this.classificationId(),
                comment: this.comment() || "",
            },
        })
            .then(() => {
                notifications.showNotification(getTranslation("Procedure added"), "success");
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."));
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };

    public toggleShowRemovedRows = () => {
        this.showRemovedRows(!this.showRemovedRows());
    };

    public removeProcedure = (procedure: Procedure) => {
        procedure.removeInProgress();
        ProceduresService.removeProcedure({
            assignId: procedure.assign_id,
            ...(procedure.tank_id ? { tankId: procedure.tank_id } : {}),
            ...(this.animalId ? { animalId: this.animalId } : {}),
            ...(this.pupId ? { pupId: this.pupId } : {}),
        })
            .then(() => {
                notifications.showNotification(getTranslation("Procedure deleted"), "success");
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."));
                    writeException(reason);
                }
            })
            .finally(() => procedure.removeInProgress(false));
    };

    public canAssignFutureDate = (dateValue: string) => {
        const assignFutureDate = session.pyratConf.PROCEDURE_ASSIGN_FUTURE_DATE;

        if (assignFutureDate === false && isDateLowerThanDate(getFormattedCurrentDate(), dateValue)) {
            return getTranslation("Procedure date can't be in the future");
        }

        return false;
    };
}

export class ProcedureWidgetComponent {

    constructor() {
        return {
            viewModel: ProcedureWidgetViewModel,
            template,
        };
    }
}
