import {
    PureComputed,
    Observable,
} from "knockout";
import * as ko from "knockout";

import { getTranslation } from "../../lib/localize";

interface ClickToEditParams {
    value: string | Observable<string>;
    caption: string | Observable<string>;
    title: string | Observable<string>;
    editClass: string | Observable<string>;
}


/**
 * Register click-to-edit component for text.
 *
 * The behavior, should be the same as the editable tables in PyRATs admin section.
 * You'll see just a text value (the span element) and get an input element to
 * edit the value if you click on the text.
 *
 * @param value: The (current) value. Can be String (static) or an ko.observable
 * from your model (highly dynamic).
 *
 * @param caption: The caption/placeholder text. This is displayed, if nothing is
 * entered (no value), Can be String (static) or an ko.observable from your model
 * (highly dynamic). If caption is not passed (or undefined), translation of 'None'
 * will be used. If you do not want to have a caption, pass null not ''.
 *
 * @param title: The title attribute for the elements. Can be String (static) or
 * an ko.observable from your model (highly dynamic).
 *
 * @param editClass: Style Class name to assign to edit element (<input/>).
 * Can be String (static) or an ko.observable from your model (highly dynamic).
 *
 * @example:
 *      <ko-click-to-edit params="value: myValue, caption: myCaption"></ko-click-to-edit>
 */
class ClickToEditViewModel {

    private preEditValue: string;

    public value: Observable<string>;
    public caption: Observable<string>;
    public title: Observable<string>;
    public editClass: Observable<string>;
    public valueOrCaption: PureComputed<string>;
    public editing: Observable<boolean>;

    public edit = (model: ClickToEditViewModel, event: MouseEvent) => {

        const inputEl = (event.target as HTMLElement).nextSibling;

        this.preEditValue = inputEl.textContent;
        this.editing(true);

        const focusEvent = document.createEvent("HTMLEvents");
        focusEvent.initEvent("focus", true, false);
        inputEl.dispatchEvent(focusEvent);

        const selectEvent = document.createEvent("HTMLEvents");
        selectEvent.initEvent("select", true, false);
        inputEl.dispatchEvent(selectEvent);

    };

    public keyDown = (model: ClickToEditViewModel, event: KeyboardEvent) => {

        const eventTarget = event.target as HTMLElement;

        // leave editing mode when hit Return
        if (event.key === "Enter") {

            const blurEvent = document.createEvent("HTMLEvents");
            blurEvent.initEvent("blur", true, false);
            eventTarget.dispatchEvent(blurEvent);

            // Returning false, causes knockout to prevent event's default
            // action. This could be, submitting a form if our element is part of one.
            return false;
        }

        // restore input elements value and leave editing mode when hit ESC
        if (event.key === "Esc" || event.key === "Escape") {

            eventTarget.textContent = this.preEditValue;

            const changeEvent = document.createEvent("HTMLEvents");
            changeEvent.initEvent("change", true, false);
            eventTarget.dispatchEvent(changeEvent);

            const blurEvent = document.createEvent("HTMLEvents");
            blurEvent.initEvent("blur", true, false);
            eventTarget.dispatchEvent(blurEvent);

            // Returning false, causes knockout to prevent event's default
            // action. This could be, closing our popup if we are part of one.
            return false;
        }
        return true;
    };

    constructor(params: ClickToEditParams) {


        // Make sure, our value (this.value) is observable.
        this.value = ko.isObservable(params.value) ? params.value : ko.observable(params.value);

        // Also caption. If caption is not given, we use translation of 'None'.
        this.caption = ko.isObservable(params.caption) ? params.caption : ko.observable(undefined !== params.caption ? params.caption : getTranslation("None"));
        this.title = ko.isObservable(params.title) ? params.title : ko.observable(params.title);
        this.editClass = ko.isObservable(params.editClass) ? params.editClass : ko.observable(params.editClass);

        // This computed helps to decide whether to show the value or the caption.
        this.valueOrCaption = ko.pureComputed(() => {
            const val = this.value();
            const cap = this.caption();
            return (undefined === val || val === null || val === "") ? cap : val;
        });

        this.editing = ko.observable(false);
    }
}

export class ClickToEditComponent {

    constructor() {

        return {
            viewModel: ClickToEditViewModel,
            // language=HTML
            template: `
                <span style="cursor:pointer"
                      data-bind="text: valueOrCaption()+' &#9660;',
                                 visible: !editing(),
                                 click: edit,
                                 attr: {title: title(),
                                        'class': editClass()}"></span>
                <input id="uid"
                       type="text"
                       data-bind="textInput: value,
                                  visible: editing(),
                                  hasFocus: editing,
                                  attr: {placeholder: caption()},
                                  event: {keydown: keyDown},
                                  keydownBubble: false"/>
            `,
        };
    }
}
