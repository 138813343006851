import * as ko from "knockout";

import {
    getSessionItem,
    setSessionItem,
    getLocalItem,
    setLocalItem,
    isSupported,
} from "../../lib/browserStorage";

declare module "knockout" {
    export interface ExtendersOptions {
        localStorage?: string;
        sessionStorage?: string;
    }
}

/** Store the value of an observable in the browser and restore it every time the observable is loaded.
 *
 * The given parameter must be a application wide unique key to identify this observable on load.
 *
 * Example:
 *
 *   map.scale = ko.observable(100).extend({localStorage: 'building_map.scale_factor'});
 *
 */
ko.extenders.localStorage = function (target, key) {
    if (isSupported()) {

        target.subscribe(function (newValue) {
            setLocalItem(key, newValue);
        });

        getLocalItem(key).then(value => {
            if (value !== undefined) {
                target(value);
            }
        });

    }

    return target;
};
ko.extenders.sessionStorage = function (target, key) {

    if (isSupported()) {

        target.subscribe(function (newValue) {
            setSessionItem(key, newValue);
        });

        getSessionItem(key).then(value => {
            if (value !== undefined) {
                target(value);
            }
        });

    }

    return target;
};
