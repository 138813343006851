/**
 * Show a popup to set the location for a cage.
 *
 * @param cageId: Database ID of the cage.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Computed,
    Observable,
    PureComputed,
} from "knockout";

import {
    CagesService,
    GetCageLocationResponse,
} from "../backend/v1";
import {
    LocationItem,
    PreselectLocationItem,
} from "../knockout/components/locationPicker/locationPicker";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";

import template from "./setCageLocation.html";

interface Params {
    cageId: number;
    eventTarget: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetCageLocationViewModel {
    private readonly dialog: HtmlDialog;

    // params
    private readonly cageId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    public preselectLocation: PreselectLocationItem;
    public selectedLocation: CheckExtended<Observable<LocationItem>>;
    public rackId: Computed<number>;
    public cagePosition: Observable<string>;
    public confirmSanitaryStatus: Observable<boolean>;
    public canSubmit: PureComputed<boolean>;
    private readonly fetchInProgress = ko.observable(true);
    private readonly submitInProgress= ko.observable(false);
    public readonly seed: Observable<GetCageLocationResponse | undefined> = ko.observable();


    constructor(dialog: HtmlDialog, params: Params) {

        this.dialog = dialog;
        this.cageId = params.cageId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.selectedLocation = ko.observable();
        this.preselectLocation = null;

        this.selectedLocation.extend({
            invalid: (v) => {
                return !!(session.pyratConf.MANDATORY_LOCATION && !v);
            },
        });

        this.rackId = ko.computed(() => {
            return this.selectedLocation() ? this.selectedLocation().rack_id : undefined;
        });

        this.cagePosition = ko.observable("");
        this.confirmSanitaryStatus = ko.observable(false);

        CagesService
            .getCageLocation({ cageId: this.cageId })
            .then((response) => {
                this.seed(response);
                if (response.rack_id) {
                    this.preselectLocation = { type: "rack", id: response.rack_id };
                }
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.fetchInProgress() ||
                     this.selectedLocation.isInvalid());
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    public submit = () => {
        this.submitInProgress(true);

        CagesService
            .setCageLocation({
                cageId: this.cageId,
                rackId: this.rackId(),
                cagePosition: this.cagePosition(),
                confirmedSanitaryStatus: this.confirmSanitaryStatus(),
            })
            .then((response) => {
                if (response?.confirm === "confirm_sanitary_status") {
                    notifications.showConfirm(
                        response.message,
                        () => {
                            // post again, this time with confirmed sanitary status
                            this.confirmSanitaryStatus(true);
                            this.submit();
                        },
                        {
                            onCancel: () => {
                                this.submitInProgress(false);
                            },
                        },
                    );
                } else {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(getTranslation("Cage location changed"), "success");
                }
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };
}

export const showSetCageLocation = htmlDialogStarter(SetCageLocationViewModel, template, params => ({
    name: "SetCageLocation",
    width: 325,
    title: params.title,
    position: { anchor: params.eventTarget },
}));
