import * as ko from "knockout";
import { template } from "lodash";

import { ScheduledReportsService } from "../backend/v1";
import { dialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";

import pupupTemplate from "./scheduledReportDetails.html";

interface Params {
    reportId: number | null;
}

interface Report {
    module_name: string;
    class_name: string;
    description: string;
}
class ScheduledReportDetailViewModel {

    public reportId: number;
    private readonly dialog: KnockoutPopup;

    public availableReports: ko.ObservableArray<Report> = ko.observableArray([]);
    public availableRepetitions: ko.ObservableArray<string> = ko.observableArray([]);
    public errors: ko.ObservableArray<string> = ko.observableArray([]);
    public reportName: ko.Observable<string> = ko.observable();
    public selectedReport: ko.Observable<Report> = ko.observable();
    public reportRepetition: ko.Observable<string> = ko.observable();
    public scheduledRun: ko.Observable<string> = ko.observable();
    public deleteReport = ko.observable(false);
    public inProgress = ko.observable(false);
    public dependenciesInProgress = ko.observable(true);
    public fetchInProgress = ko.observable(true);

    constructor({ reportId }: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;
        this.reportId = reportId;

        if (reportId === null) {
            dialog.setTitle(getTranslation("New scheduled report"));
        } else {
            dialog.setTitle(template(getTranslation("Scheduled report #<%- reportId %>"))(this));
        }

        const dependencies = Promise.all([
            ScheduledReportsService.getAvailableReports().then((reports) => {
                // @ts-expect-error: The type is not defined properly yet.
                this.availableReports(reports);
            }),
            ScheduledReportsService.getAvailableRepetitions().then((repetitions) => {
                // @ts-expect-error: The type is not defined properly yet.
                this.availableRepetitions(repetitions);
            }),
        ]).finally(() => this.dependenciesInProgress(false));

        if (reportId) {
            const reportDetailsRequest = ScheduledReportsService.getReportDetails({ reportId: reportId });
            dependencies
                .then(() => {
                    reportDetailsRequest.then((seed) => {
                        this.reportName(seed.report_name);
                        this.reportRepetition(seed.repeat);
                        this.scheduledRun(seed.scheduled_run);
                    });
                })
                .catch((reason) => {
                    if (typeof reason.body?.detail == "string") {
                        this.errors.push(reason.body.detail);
                    } else {
                        this.errors.push(getTranslation("General error."));
                        writeException(reason);
                    }
                })
                .finally(() => this.fetchInProgress(false));
        } else {
            this.fetchInProgress(false);
        }
    }

    private canSubmit = () => {
        if (this.reportId === null) {
            if (!this.selectedReport()) {
                return false;
            }
        }
        return true;
    };

    private submitForm = () => {

        this.errors.removeAll();
        this.inProgress(true);

        let request: Promise<any>;
        if (this.reportId === null) {
            request = ScheduledReportsService.scheduleReport({
                requestBody: {
                    report_module: this.selectedReport().module_name,
                    report_class: this.selectedReport().class_name,
                    scheduled_run: this.scheduledRun() || null,
                    repeat: this.reportRepetition() || null,
                },
            });
        } else if (this.deleteReport()) {
            request = ScheduledReportsService.deleteReport({ reportId: this.reportId });
        } else if (this.reportId) {
            request = ScheduledReportsService.updateReport({
                reportId: this.reportId,
                requestBody: {
                    report_name: this.reportName(),
                    scheduled_run: this.scheduledRun() || null,
                    repeat: this.reportRepetition() || null,
                },
            });
        }

        if (request) {
            request.then(() => {
                this.dialog.close();
                location.reload();
            })
                .catch((reason) => {
                    if (typeof reason.body?.detail == "string") {
                        this.errors.push(reason.body.detail);
                    } else {
                        this.errors.push(getTranslation("General error."));
                        writeException(reason);
                    }
                })
                .finally(() => this.inProgress(false));
        }

    };
}

export const showScheduledReportDetails = dialogStarter(ScheduledReportDetailViewModel, pupupTemplate, {
    name: "ScheduledReportDetails",
    width: 400,
});
