export type OrderItem = [string, "asc" | "desc"];

/**
 * Wrap a list of order-by definitions.
 *
 * currentOrder should be a list o[<colname>, <direction>] pairs.
 * Apply special behavior to the defaultOrderColumn.
 */
export class OrderBy {
    private readonly currentOrder: OrderItem[];
    private readonly defaultOrderColumns: string[];

    constructor(currentOrder: OrderItem[], defaultOrderColumn: string | string[]) {
        this.currentOrder = currentOrder;
        if (typeof defaultOrderColumn === "string") {
            this.defaultOrderColumns = [defaultOrderColumn];
        } else {
            this.defaultOrderColumns = defaultOrderColumn;
        }
    }

    /**
     * Toggle a single column within the current order.
     * Return a string usable as an order_by query parameter.
     */
    public toggle = (columnName: string) => {
        if (this.isDefaultOrder() && !this.defaultOrderColumns.includes(columnName)) {
            // If the current order is the default order and a column, which is not
            // part of the default order, was clicked, we only want to sort by this.
            return this.serialize([[columnName, "asc"]]);
        } else {
            let found = false;
            const order: OrderItem[] = this.currentOrder.map((x) => {
                const name = x[0];
                const direction = x[1];
                if (name === columnName) {
                    found = true;
                    return [name, direction === "asc" ? "desc" : "asc"];
                } else {
                    return x;
                }
            });
            if (!found) {
                order.push([columnName, "asc"]);
            }
            return this.serialize(order);
        }
    };

    /**
     * Remove the given column from the current order.
     * Return a string usable as an order_by query parameter.
     */
    public remove = (columnName: string) => {
        if (this.isDefaultOrder()) {
            // not removable
            return this.serialize(this.currentOrder);
        } else {
            const order = this.currentOrder.filter((x) => {
                const name = x[0];
                return name !== columnName;
            });
            if (order.length) {
                return this.serialize(order);
            } else {
                return this.serialize(this.defaultOrderColumns.map(o => [o, "asc"]));
            }
        }
    };

    /**
     * Return a string parsable with richform.get_order_by.
     */
    private serialize = (order: OrderItem[]) => {
        return order.map((x) => `${x[0]}:${x[1]}`).join(",");
    };

    /**
     * Decide whether currentOrder is the default order.
     */
    private isDefaultOrder = () => {
        return (
            this.currentOrder.length === this.defaultOrderColumns.length &&
            this.currentOrder.every((o, i) => this.defaultOrderColumns[i] === o[0])
        );
    };
}
