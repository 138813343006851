/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_updateLocationDetails } from '../models/Body_updateLocationDetails';
import type { LocationDetailSeed } from '../models/LocationDetailSeed';
import type { LocationPickerCage } from '../models/LocationPickerCage';
import type { LocationPickerLocation } from '../models/LocationPickerLocation';
import type { LocationPickerTankPosition } from '../models/LocationPickerTankPosition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationsService {

    /**
     * Get Location Detail Seed
     * Get the location detail seed.
     * @returns LocationDetailSeed Successful Response
     * @throws ApiError
     */
    public static getLocationDetails({
        locationId,
    }: {
        locationId: string,
    }): CancelablePromise<LocationDetailSeed> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/{location_id}/details',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Location Endpoint
     * Update a location from the detail page.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateLocationDetails({
        locationId,
        requestBody,
    }: {
        locationId: string,
        requestBody: Body_updateLocationDetails,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/locations/{location_id}/details',
            path: {
                'location_id': locationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Rack Positions
     * Generate a position matrix for a rack.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static generateRackPositions({
        firstPosition,
        lastPosition,
        rowCount,
        columnCount,
        secondPosition,
        startingPoint = 'top_left',
        countingDirection = 'horizontal',
        countingOrder = 'even',
    }: {
        firstPosition: string,
        lastPosition: string,
        rowCount?: (number | null),
        columnCount?: (number | null),
        secondPosition?: (string | null),
        startingPoint?: 'bottom_left' | 'top_left' | 'top_right' | 'bottom_right',
        countingDirection?: 'vertical' | 'horizontal',
        countingOrder?: 'even' | 'meandering',
    }): CancelablePromise<Array<Array<string>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/positions',
            query: {
                'first_position': firstPosition,
                'last_position': lastPosition,
                'row_count': rowCount,
                'column_count': columnCount,
                'second_position': secondPosition,
                'starting_point': startingPoint,
                'counting_direction': countingDirection,
                'counting_order': countingOrder,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Location Endpoint
     * Delete a location.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteLocation({
        locationId,
    }: {
        locationId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Location Endpoint
     * Create a new location.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createLocation({
        parentLocationId,
    }: {
        parentLocationId?: (string | null),
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/locations/',
            query: {
                'parent_location_id': parentLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Picker Locations
     * Get locations for the location picker.
     * @returns LocationPickerLocation Successful Response
     * @throws ApiError
     */
    public static getPickerLocations({
        pickerType,
        parentLocation,
        withNoLocation = true,
        preselectType,
        preselectId,
    }: {
        pickerType?: Array<'building' | 'area' | 'room' | 'rack' | 'cage' | 'transfer'>,
        parentLocation?: (string | null),
        withNoLocation?: boolean,
        preselectType?: ('building' | 'area' | 'room' | 'rack' | 'cage' | null),
        preselectId?: (number | null),
    }): CancelablePromise<Array<LocationPickerLocation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/picker/locations',
            query: {
                'picker_type': pickerType,
                'parent_location': parentLocation,
                'with_no_location': withNoLocation,
                'preselect_type': preselectType,
                'preselect_id': preselectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Picker Cages
     * Get cages for the location picker
     * @returns LocationPickerCage Successful Response
     * @throws ApiError
     */
    public static getPickerCages({
        locationId,
    }: {
        locationId: string,
    }): CancelablePromise<Array<LocationPickerCage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/picker/cages/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Picker Tanks
     * Get tanks for the location picker
     * @returns LocationPickerTankPosition Successful Response
     * @throws ApiError
     */
    public static getPickerTanks({
        locationId,
    }: {
        locationId: string,
    }): CancelablePromise<Array<LocationPickerTankPosition>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/picker/tanks/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
