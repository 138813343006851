import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
    Subscribable,
} from "knockout";
import * as _ from "lodash";

import {
    LicensesService,
    ListFilterDefinition,
    StrainNameDetailsWithGroup,
    StrainsService,
} from "../../backend/v1";
import { showTankDetails } from "../../dialogs";
import { FetchExtended } from "../../knockout/extensions/fetch";
import { FetchBackendExtended } from "../../knockout/extensions/fetchBackend";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    frames,
    mainMenu,
} from "../../lib/pyratTop";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    isInvalidCalendarDate,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./workRequestListFilter.html";



const WorkRequestListFilters = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {
        animal_eartag: ListFilterItem,

        subject_location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => this.initialized() === true || this.serialize() === seed.default_value;
            }
        },

        approved: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name") as string[];
            }
        },

        cagenumber: ListFilterItem,

        class_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "class_name") as string[];
            }
        },

        tank_id: ListFilterItem,

        close_date_from: class extends ListFilterItem {
            public value: CheckExtended<Observable<string>>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("close_date_to"), compareFromDate),
                });

                // set status filter to "Closed" when close date is selected
                this.value.subscribe((v) => {
                    if (v && !isInvalidCalendarDate(v)) {
                        const statusFilter = filter.allFilters().status_id_or_unresolved;
                        statusFilter.value(
                            _.result(_.find(statusFilter.seed.possible_values, { status_name: "Closed" }), "status_id"));
                    }
                });
            }
            public valid = () => this.value.isValid();
        },

        close_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("close_date_from"), compareToDate),
                });

                // set status filter to "Closed" when close date is selected
                this.value.subscribe((v) => {
                    if (v && !isInvalidCalendarDate(v)) {
                        const statusFilter = filter.allFilters().status_id_or_unresolved;
                        statusFilter.value(
                            _.result(_.find(statusFilter.seed.possible_values, { status_name: "Closed" }), "status_id"));
                    }
                });
            }
            public valid = () => this.value.isValid();
        },

        contains: ListFilterItem,

        creator_userid: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "fullname") as string[];
            }
        },

        due_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("due_date_to"), compareFromDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        due_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("due_date_from"), compareToDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        due_date_string: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "label") as string[];
            }
        },

        id: ListFilterItem,

        license_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        classification_id: class extends ListFilterItem {
            private licenseId: PureComputed<number>;
            private possibleClassifications: FetchExtended<ObservableArray>;
            private inProgress: Observable<boolean>;
            private disable: Observable<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.licenseId = ko.pureComputed(() => {
                    // for some reason params.allFilters().licence_id.value() fires twice with the same value (if set)
                    // which would cause possibleClassifications async extender to fetch classification list twice.
                    // By serving licence_id.value() through a pureComputed here, we avoid that because pureComputed
                    // does not fire a change/update multiple times if the value is still the same.
                    return filter.allFilters().license_id && filter.allFilters().license_id.value();
                });
                this.possibleClassifications = ko.observableArray();
                this.possibleClassifications.extend({
                    fetchBackend: () => {
                        if (this.licenseId()) {
                            return LicensesService.getLicenseClassificationOptions({
                                licenseId: this.licenseId(),
                                which: "for_filter",
                            });
                        }
                    },
                });
                this.possibleClassifications.subscribeOnce(() => {
                    value(seed.current_value);
                });

                this.inProgress = this.possibleClassifications.inProgress;
                this.disable = this.possibleClassifications.inProgress;

                // update search string in order to find select options (wordings) via filter search
                this.text = ko.pureComputed(() => {
                    return _.map(this.possibleClassifications(), "name");
                });

                this.valid = () => {
                    return !this.possibleClassifications.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        open_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("open_date_to"), compareFromDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        open_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("open_date_from"), compareToDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        pertain_userid: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        unbound_animal_owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        priority_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "priority_name");
            }
        },

        project_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.concat(
                    _.map(seed.possible_values, "name"),
                    _.map(seed.possible_values, "owner_fullname"),
                );
            }
        },

        pup_eartag: ListFilterItem,

        required_location_permission: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => this.initialized() === true || this.serialize() === seed.default_value;
            }
        },

        responsible_userid: class extends ListFilterItem {
            private possibleValues: PureComputed<{ fullname: string; userid: number }[]>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValueArguments = ko.pureComputed(() => {
                    if (filter.allFilters().pertain_userid) {
                        return {
                            owner_ids: filter.allFilters().pertain_userid.model.serialize(),
                        };
                    }
                });

                this.possibleValues = ko.pureComputed(() => {
                    return _.union([{ userid: 0, fullname: getTranslation("None") }], seed.possible_values());
                });

                this.text = ko.pureComputed(() => {
                    return _.map(seed.possible_values(), "fullname") as string[];
                });
            }
        },

        status_id_or_unresolved: class extends ListFilterItem {
            private value: Observable<number>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value;

                // reset close date range if selected status not equal 'Closed'
                this.value.subscribe((v) => {
                    const closedStatusId = _.result(_.find(seed.possible_values, { status_name: "Closed" }), "status_id");
                    if (v && v !== closedStatusId) {
                        filter.allFilters().close_date_from.value(undefined);
                        filter.allFilters().close_date_to.value(undefined);
                    }
                });
                this.text = _.map(seed.possible_values, "status_label") as string[];
            }
        },

        strain: class extends ListFilterItem {
            private inProgress: Observable<boolean>;
            private disable: Subscribable<boolean>;
            private readonly ownerStrains: FetchBackendExtended<ObservableArray<StrainNameDetailsWithGroup>>;
            public staticValues: { name_with_id: string; id: number }[];
            private readonly possibleValues: PureComputed<{ name_with_id: string; id: number }[]>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.ownerStrains = ko.observableArray();
                this.ownerStrains.extend({
                    fetchBackend: () => {
                        if (filter.allFilters().pertain_userid) {
                            return StrainsService.getStrainsByUsageForOwners({
                                ownerIds: filter.allFilters().pertain_userid.model.serialize(),
                                activeOrInactive: "active",
                            });
                        }
                    },
                });

                this.staticValues = [{ id: 0, name_with_id: getTranslation("None") }];
                this.possibleValues = ko.pureComputed(() => {
                    return this.staticValues.concat(this.ownerStrains());
                });

                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.inProgress = this.ownerStrains.inProgress;
                this.disable = this.ownerStrains.inProgress;

                this.text = ko.pureComputed(() => {
                    return _.map(this.possibleValues(), "name_with_id");
                });

                this.valid = () => {
                    return !this.ownerStrains.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        species_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.map(seed.possible_values, "name");
            }
        },

    };
};


class WorkRequestList {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: ListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: WorkRequestListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Work request filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("new-request", () => {
            frames.openListDetailPopup(
                getUrl(cgiScript("new_request.py")),
                frames.reloadListIframe,
            );
        });

        // printing
        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        // Table Body

        // links to detail popups

        listViewElement
            .querySelectorAll("td:not(.animals_pups_cages, .involved_tanks, .involved_tanks_count)")
            .forEach((element: HTMLElement) => {
                element.classList.add("clickable");
                element.setAttribute("title", getTranslation("Show work request details"));
            });


        this.listView.onCellClick("td:not(.animals_pups_cages, .involved_tanks, .involved_tanks_count)", (args) => {
            this.showWorkRequestDetails(args.rowId, parseInt(args.rowId, 10));
        });

        this.listView.onCellClick(".animal_link", (args) => {
            this.listView.highlightRow(args.rowId);
            frames.openListDetailPopup(
                getUrl(cgiScript("mousedetail.py"), {
                    animalid: parseInt(args.element.getAttribute("data_id"), 10),
                }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });

        this.listView.onCellClick(".pup_link", (args) => {
            this.listView.highlightRow(args.rowId);
            frames.openListDetailPopup(
                getUrl(cgiScript("pupdetail.py"), {
                    animalid: parseInt(args.element.getAttribute("data_id"), 10),
                }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });

        this.listView.onCellClick(".cage_link", (args) => {
            this.listView.highlightRow(args.rowId);
            frames.openListDetailPopup(
                getUrl(cgiScript("cagedetail.py"), {
                    cageid: parseInt(args.element.getAttribute("data_id"), 10),
                }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });

        this.listView.onCellClick(".tank_link", (args) => {
            showTankDetails({
                tankId: parseInt((args.event.target as HTMLAnchorElement).dataset.tankId, 10),
            });
        });

        this.listView.onCellClick("td.involved_tanks_count a", (args) => {
            mainMenu.openAndResetListFilter("get_tank_list", { work_request_id: args.rowId });
        });
    }

    public showWorkRequestDetails = (highlightRowId: string, openWorkRequestId: number) => {
        this.listView.highlightRow(highlightRowId);
        frames.openListDetailPopup(
            getUrl(cgiScript("requestdetail.py"), { incidentid: openWorkRequestId }),
            () => this.listView.unHighlightRow(highlightRowId),
        );
    };
}

export const initWorkRequestList = (args: ListViewArguments): void => {
    const workRequestList = new WorkRequestList(document.querySelector("div.listview"), args);

    // @ts-expect-error: required by procedure shortcuts
    window.workRequestList = workRequestList;

};
