import * as ko from "knockout";
import { Observable } from "knockout";

import { assert } from "../../../lib/assert";
import { KnockoutPopup } from "../../../lib/popups";
import { dialogStarter } from "../../dialogStarter";

import { LocationItem } from "./locationPicker";
import { TankPosition } from "./tankPicker";

interface TankPickerDialogParams {
    location: LocationItem;
    onApply?: (position: TankPosition) => void;
    clickEvent?: MouseEvent;
}

class TankPickerDialogComponent {

    private readonly dialog: KnockoutPopup;
    public selectedPosition: Observable<TankPosition>;
    public location: LocationItem;

    constructor(params: TankPickerDialogParams, dialog: KnockoutPopup) {
        assert(params.location.type === "rack", "rack position type required");
        this.dialog = dialog;
        this.selectedPosition = ko.observable(undefined);
        this.location = params.location;
        this.dialog.setTitle(params.location.rack_name);
        this.selectedPosition.subscribe((position) => {
            if (typeof params.onApply == "function") {
                params.onApply(position);
            }
            this.dialog.close();
        });
    }
}

export const showTankPicker = dialogStarter(TankPickerDialogComponent, `
    <ko-tank-picker style="margin: 10px;
                           display: block;"
                    params="location: location,
                            selectedPosition: selectedPosition"></ko-tank-picker>
`, params => ({
    height: 350,
    anchor: params.clickEvent.target as HTMLElement,
    handle: "left bottom",
}));
