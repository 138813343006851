import { session } from "../pyratSession";
import { detailPopup } from "../pyratTop/frames";

export type SearchMode = "single" | "many";
export type SearchCode = "rfid" | "barcode";

interface SearchBoxOptions {
    // The html element that contains the searchbox widget.
    element: HTMLElement;

    // Callback function when the search button is submitted.
    onSubmitCallback: (searchMode: SearchMode, searchValue: string) => void;

    searchCode?: SearchCode;

    // In many mode, collect all read code in a list,
    // in single mode, submit after the first matching code is found.
    searchMode?: SearchMode;
}

/**
 * Client side code for the interactive barcode/RFID searchbox widget.
 **/
export class SearchBox {
    public onSubmit: (searchValue: string, searchMode: SearchMode) => void;
    private readonly searchBoxElement: HTMLElement;
    private readonly codeInput: HTMLInputElement;
    private readonly goButton: HTMLInputElement;
    private readonly goButtonLabel: string;
    private readonly searchCode: SearchCode;
    private searchMode: SearchMode;
    private readonly codeList: string[];

    constructor({ element, onSubmitCallback, searchCode = "barcode", searchMode = "single" }: SearchBoxOptions) {
        this.searchBoxElement = element;
        this.searchCode = searchCode;
        this.searchMode = searchMode;
        this.onSubmit = onSubmitCallback;

        this.codeInput = element.querySelector("input[name=code]");
        this.goButton = element.querySelector("input[name=go]");
        this.goButtonLabel = this.goButton.value;

        this.codeList = [];

        // set show mode dependent inputs
        this.setSearchMode(searchMode);

        // toggle search mode
        element.querySelector(".search-mode").addEventListener("click", () => {
            this.toggleSearchMode();
            this.codeInput.focus();
        });

        // submit barcodes/rfid when clicking 'go'
        this.goButton.addEventListener("click", this.submit);

        if (searchCode === "rfid") {
            this.rfidSetup();
        } else if (searchCode === "barcode") {
            this.barcodeSetup();
        }

        window.setTimeout(() => {
            this.codeInput.focus();
        }, 0);
    }

    /**
     * Switch between single and many scan mode
     */
    public toggleSearchMode = () => {
        if (this.searchMode === "single") {
            this.setSearchMode("many");
        } else {
            this.setSearchMode("single");
        }
    };

    /**
     * Activate search mode
     */
    public setSearchMode = (value: SearchMode) => {
        if (value === "many") {
            (this.searchBoxElement.querySelector(".single-mode") as HTMLElement).style.display = "none";
            (this.searchBoxElement.querySelector(".many-mode") as HTMLElement).style.display = "";
            this.searchMode = "many";
        } else if (value === "single") {
            (this.searchBoxElement.querySelector(".single-mode") as HTMLElement).style.display = "";
            (this.searchBoxElement.querySelector(".many-mode") as HTMLElement).style.display = "none";
            this.searchMode = "single";
        }
    };

    // submit whatever the current filter value is
    public submit = () => {
        let value;
        if (this.searchCode === "rfid") {
            if (this.searchMode === "single") {
                value = this.codeInput.value;
            } else {
                value = this.codeList.join(",");
            }
        } else {
            // Bhe barcode(s) are always visible.
            // The filter accepts space and comma as separator.
            value = this.codeInput.value;
        }
        if (value.length) {
            // submit if searchstring is available
            this.onSubmit(value, this.searchMode);
        }
    };

    private rfidSetup = () => {
        // Read an RFID from the codeInput and take action when it matches RFID_REGEX.
        // Either submit it (single mode) or queue it up and clear the codeInput (many mode).
        this.codeInput.addEventListener("keyup", () => {
            const re = new RegExp(session.pyratConf.RFID_REGEX);
            const value = this.codeInput.value;

            // Close any possibly open detailPopup (when a user scans one by one)
            detailPopup?.close();

            if (value.match(re)) {
                if (this.searchMode === "single") {
                    // Directly submit the form
                    this.onSubmit(value, this.searchMode);
                } else {
                    // push new RFID to the batch codeList
                    this.codeList.push(value);
                    this.codeInput.value = "";
                    if (this.codeList.length) {
                        this.goButton.value = `${this.goButtonLabel} (${this.codeList.length})`;
                        this.goButton.disabled = false;
                    } else {
                        this.goButton.value = this.goButtonLabel;
                        this.goButton.disabled = true;
                    }
                }
            }
        });
    };

    /**
     * Setup barcode input field
     */
    private barcodeSetup = () => {
        // Replace 'enter' characters directly following an input sequence with a
        // space to be able to work with most barcode readers default
        // configurations. Barcode readers can be configured to use other
        // characters after reading a barcode too, but having them work
        // out-of-the-box is nice for customers too.
        // Pressing enter without having typed any other characters ENTER_DELAY_MS
        // milliseconds before will just submit the form (so a normal user
        // pressing enter will feel no difference).
        const ENTER_DELAY_MS = 50;

        // For single mode, submit the form SUBMIT_TYPING_DELAY_MS milliseconds after the last keypress.
        const SUBMIT_TYPING_DELAY_MS = 1000;

        let enterTimeout: number;
        let firstInput = true;
        let currentTimeout: number;

        this.goButton.disabled = false;

        this.codeInput.addEventListener("keyup", () => {
            if (this.searchMode === "single") {
                if (currentTimeout) {
                    window.clearTimeout(currentTimeout);
                }
                currentTimeout = window.setTimeout(this.submit, SUBMIT_TYPING_DELAY_MS);
            }

            if (enterTimeout) {
                window.clearTimeout(enterTimeout);
            }
            enterTimeout = window.setTimeout(() => {
                enterTimeout = undefined;
            }, ENTER_DELAY_MS);
        });
        this.codeInput.addEventListener("keypress", (event) => {
            if (firstInput && this.searchMode === "single") {
                // clear the field in single-mode after the first input
                this.codeInput.value = "";
            }

            if (event.code === "Enter") {
                if (enterTimeout) {
                    window.clearTimeout(enterTimeout);
                    enterTimeout = window.setTimeout(() => {
                        enterTimeout = undefined;
                    }, ENTER_DELAY_MS);
                    this.codeInput.value =
                        this.codeInput.value.substring(0, this.codeInput.selectionStart) +
                        " " +
                        this.codeInput.value.substring(this.codeInput.selectionStart);
                    event.preventDefault();
                }

                this.submit();
            }

            firstInput = false;
        });
        this.codeInput.addEventListener("keydown", (event) => {
            if (event.ctrlKey && event.code === "KeyJ") {
                // ignore CTRL-J generated by barcode reader in an attempt to
                // insert a '\r' character
                event.preventDefault();
            }
        });
    };
}
