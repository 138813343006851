/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_addProcedure } from '../models/Body_addProcedure';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProceduresService {

    /**
     * Post Procedure
     * Add procedure
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addProcedure({
        requestBody,
    }: {
        requestBody: Body_addProcedure,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/procedures/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Procedure
     * Remove procedure
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeProcedure({
        assignId,
        animalId,
        pupId,
        tankId,
    }: {
        assignId: number,
        animalId?: (number | null),
        pupId?: (number | null),
        tankId?: (number | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/procedures/',
            query: {
                'assign_id': assignId,
                'animal_id': animalId,
                'pup_id': pupId,
                'tank_id': tankId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
