/**
 * Show the cryotank details pop-up.
 *
 * @param cryotankId
 *        The database ID of the cryotank.
 *        Can be left out to create a new cryotank.
 *
 * @param reloadCallback
 *        Function to call when data has been applied and popup is closed
 *        (e.g. to reload a list to display new data).
 *
 * @param closeCallback
 *        Function to call whenever the popup is closed, whether data was
 *        applied or not (e.g. to unhighlight a row in listview table).
 *
 */

import * as ko from "knockout";

import {
    CryotankLayoutNode,
    CryotanksService,
    DisplayHintProperties,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";

import template from "./cryotankDetails.html";
import "./cryotankDetails.scss";

interface Params {
    cryotankId: number;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

interface LayoutTemplate {
    description: string;
    layout: Array<CryotankLayoutNode>;
}

interface LayoutNode {
    id: ko.Observable<number>;
    name: CheckExtended<ko.Observable<string>>;
    nodeType: "container" | "straw";
    numbering: ko.Observable<string>;
    displayHintId: ko.Observable<"" | "round-slots">;
    displayRows: ko.ObservableArray<number>;
    displayRowsString: ko.PureComputed<string>;
    parentCapacity: CheckExtended<ko.Observable<number>>;
    parentCapacityRaw: ko.Observable<number>;
    numberingSum: ko.PureComputed<number>;
    errorMessage: ko.PureComputed<string | false>;
    _destroy?: true;
}

class CryotankDetailsViewModel {
    private dialog: HtmlDialog;
    private reloadRequired: ko.Observable<boolean>;
    private cryotankId: number;
    private cryotankName: CheckExtended<ko.Observable<string>>;
    private cryotankDescription: ko.Observable<string>;
    private cryotankActive: ko.Observable<boolean>;
    private mutable: ko.Observable<boolean>;
    private layoutTemplates: Array<LayoutTemplate>;
    private layoutTemplate: ko.Observable<LayoutTemplate>;
    private layoutNodes: CheckExtended<ko.ObservableArray<LayoutNode>>;
    private isLayoutEmpty: ko.PureComputed<boolean>;
    private nodeTemplates: Array<CryotankLayoutNode>;
    private nodeTemplate: ko.Observable<CryotankLayoutNode>;
    private nodeDisplayHints: ko.ObservableArray<DisplayHintProperties>;
    private loadInProgress: ko.Observable<boolean>;
    private errorMessage: ko.Observable<string>;
    private allValid: ko.Computed<boolean>;
    private submitInProgress: ko.Observable<boolean>;

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;

        this.cryotankId = params.cryotankId;
        this.reloadRequired = ko.observable(false);

        this.dialog.setTitle(this.cryotankId ? getTranslation("Edit cryotank") : getTranslation("Create new cryotank"));
        this.dialog.addOnClose(() => {
            if (params.closeCallback) {
                params.closeCallback();
            }

            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        this.cryotankName = ko.observable().extend({
            normalize: (v) => v ? v.trim() : v,
            invalid: (v) => {
                if (!v) {
                    return getTranslation("Name can't be empty");
                }

                return false;
            },
        });
        this.cryotankDescription = ko.observable().extend({
            normalize: (v) => v ? v.trim() : v,
        });
        this.cryotankActive = ko.observable(true);
        this.mutable = ko.observable(false);

        /**
         * A predefined list of real-world tank layouts.
         * Makes it easier for users to get started and we do not have to
         * explain the intricacies of this editor in the getting started manual.
         * Leaving the description untranslated for now (too much work).
         *
         */
        this.layoutTemplates = [
            {
                description: "Storage system with 42 towers, 2 boxes in each tower, each box having 75 visotube slots in honeycomb form",
                layout: [
                    {
                        name: "Tower",
                        node_type: "container",
                        numbering_string: "1..42",
                    },
                    {
                        name: "Box",
                        node_type: "container",
                        numbering_string: "1..2",
                    },
                    {
                        name: "Slot",
                        node_type: "container",
                        numbering_string: "1..75",
                        display_hints: ["round-slots"],
                        display_rows: [7, 8, 7, 8, 7, 8, 7, 8, 7, 8],
                    },
                    {
                        name: "Straw",
                        node_type: "straw",
                        parent_capacity: 10,
                    },
                ],
            },
            {
                description: "Tank with 6 canisters, 10 canes per canister, 2 visotube slots per cane",
                layout: [
                    {
                        name: "Canister",
                        node_type: "container",
                        numbering_string: "1..6",
                    },
                    {
                        name: "Cane",
                        node_type: "container",
                        numbering_string: null,
                        display_rows: [2, 3, 3, 2],
                        parent_capacity: 10,
                    },
                    {
                        name: "Visotube",
                        node_type: "container",
                        numbering_string: "1,2",
                        display_hints: ["round-slots"],
                    },
                    {
                        name: "Straw",
                        node_type: "straw",
                        parent_capacity: 10,
                    },
                ],
            },
            {
                description: "Custom Layout",
                layout: [
                    {
                        name: "",
                        node_type: "container",
                        parent_capacity: 0,
                    },
                ],
            },
        ];
        this.layoutTemplate = ko.observable();

        this.layoutNodes = ko.observableArray().extend({
            invalid: (v) => {
                const activeNodes = v.filter((node) => !node._destroy);

                if (!activeNodes.length) {
                    return getTranslation("Please create a layout for this cryotank");
                }

                if (activeNodes.slice(-1)[0].nodeType === "container") {
                    return getTranslation("The last subdivision in a cryotank must be straws");
                }

                if (activeNodes.slice(0, -1).some((node) => node.nodeType !== "container")) {
                    return getTranslation("All subdivisions but the last one must be containers");
                }

                return activeNodes.reduce((invalid, node) => invalid || node.errorMessage(), false);
            },
        });
        this.isLayoutEmpty = ko.pureComputed(() => this.layoutNodes().every((node) => node._destroy));

        /**
         * A predefined set of layout nodes ('add subdivisions').
         * Labels are not translated as everybody is using the English terms
         * anyway (Tower/Rack/Box ...) and the labels end up in the database
         * where we can't translate them when displaying.
         *
         */
        this.nodeTemplates = [
            {
                name: "Container",
                node_type: "container",
                numbering_string: "1..10",
            },
            {
                name: "Straw",
                node_type: "straw",
                parent_capacity: 10,
            },
        ];
        this.nodeTemplate = ko.observable();

        this.nodeDisplayHints = ko.observableArray();

        this.loadInProgress = ko.observable(true);
        CryotanksService.getCryotankNodeDisplayHintsForSetting().then((response) => {
            this.nodeDisplayHints(response);
        }).catch(() => {
            notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
        }).finally(() => {
            if (this.cryotankId) {
                CryotanksService.getCryotankDetails({ cryotankId: this.cryotankId }).then((response) => {
                    this.cryotankName(response.name);
                    this.cryotankDescription(response.description);
                    this.cryotankActive(response.active);
                    this.mutable(response.mutable);
                    this.addLayoutNodes(response.layout);
                    this.loadInProgress(false);
                }).catch(() => {
                    notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
                    this.loadInProgress(false);
                });
            } else {
                this.mutable(true);
                this.loadInProgress(false);
            }
        });

        this.errorMessage = ko.observable();
        this.allValid = ko.computed(() => {
            this.errorMessage(undefined);

            if (this.cryotankName.isInvalid()) {
                this.errorMessage(this.cryotankName.errorMessage());
                return false;
            }

            if (this.layoutNodes.isInvalid()) {
                this.errorMessage(this.layoutNodes.errorMessage());
                return false;
            }

            return true;
        });

        this.submitInProgress = ko.observable(false);
    }

    private parseNumberingRangePoint = (numberingRangePoint: any) => {
        let gridParts;
        let res: {
            type: "number";
            value: number;
        } | {
            type: "letter";
            value: string;
        } | {
            type: "grid";
            value: [string, number];
        };

        if (numberingRangePoint.match("^[0-9]+$")) {
            res = {
                type: "number",
                value: parseInt(numberingRangePoint, 10),
            };
        } else if (numberingRangePoint.match("^[A-Za-z]$")) {
            res = {
                type: "letter",
                value: numberingRangePoint,
            };
        } else if (numberingRangePoint.match("^[A-Z]-[0-9]{1,2}$")) {
            gridParts = numberingRangePoint.split("-");
            res = {
                type: "grid",
                value: [gridParts[0], parseInt(gridParts[1], 10)],
            };
        }

        return res;
    };

    private addLayoutNodes = (nodeTemplates: Array<CryotankLayoutNode>) => {
        nodeTemplates.forEach((nodeTemplate) => this.addNode(nodeTemplate));
    };

    private addNode = (nodeTemplate: CryotankLayoutNode) => {
        const nodeName: CheckExtended<ko.Observable<string>> = ko.observable(nodeTemplate.name).extend({
            normalize: (v) => v ? v.trim() : v,
            invalid: (v) => {
                if (!v) {
                    return getTranslation("Name can't be empty");
                }

                return false;
            },
        });
        const nodeNumbering: ko.Observable<string> = ko.observable();
        const parentCapacity: CheckExtended<ko.Observable<number>> = ko.observable(nodeTemplate.parent_capacity).extend({
            invalid: (v) => {
                if (isNaN(v) || v <= 0) {
                    return getTranslation("Cryotank capacity must be larger than 0");
                }

                return false;
            },
        });
        const parentCapacityRaw = ko.observable(nodeTemplate.parent_capacity);
        const displayRows = ko.observableArray(nodeTemplate.display_rows);
        const displayRowsString = ko.pureComputed({
            read: () => displayRows().map((x) => x.toString()).join(", "),
            write: (v) => displayRows((v || "").split(",").map((x) => parseInt(x.trim(), 10)).filter((x) => x !== undefined && x >= 0)),
        });
        const numberingSum = ko.pureComputed(() => {
            const numbering = (nodeNumbering() || "").trim();
            let items;
            let rangeStart;
            let rangeEnd;
            let rows;
            let cols;
            let res: {
                items?: Array<string>;
                type?: "number" | "letter" | "grid";
                start?: number | string | [string, number];
                end?: number | string | [string, number];
                count?: number;
            };

            if (numbering.indexOf("..") >= 0) {
                items = numbering.split("..").map((n) => n.trim());
                rangeStart = this.parseNumberingRangePoint(items[0]);
                rangeEnd = this.parseNumberingRangePoint(items[1] || "");

                if (!rangeStart || !rangeEnd || rangeStart.type !== rangeEnd.type) {
                    res = undefined;
                } else {
                    res = {
                        type: rangeStart.type,
                        start: rangeStart.value,
                        end: rangeEnd.value,
                    };

                    if (res.type === "number") {
                        // @ts-expect-error: ... must be of type 'any', 'number', 'bigint' or an enum type
                        res.count = res.end - res.start + 1;
                    } else if (res.type === "letter") {
                        // @ts-expect-error: Property 'charCodeAt' does not exist on type 'string | number | [string, number]'
                        res.count = res.end.charCodeAt(0) - res.start.charCodeAt(0) + 1;
                    } else if (res.type === "grid") {
                        // @ts-expect-error: Element implicitly has an 'any' type
                        rows = res.end[0].charCodeAt(0) - res.start[0].charCodeAt(0) + 1;
                        // @ts-expect-error: Element implicitly has an 'any' type
                        cols = res.end[1] - res.start[1] + 1;
                        if (rows > 0 && cols > 0) {
                            res.count = rows * cols;
                        }
                    }

                    if (!(res.count && res.count > 0)) {
                        res = undefined;
                    }
                }
            } else {
                items = numbering.split(",").map((n) => n.trim()).filter((n) => n);
                res = {
                    items: items,
                    count: items.length,
                };
            }

            return res?.count;
        });
        numberingSum.subscribe((v) => {
            parentCapacity(v);
            parentCapacityRaw(v);
        });

        if (!this.mutable()) {
            parentCapacityRaw.subscribe(() => {
                if (parentCapacity.isValid() && nodeTemplate.parent_capacity && nodeTemplate.parent_capacity !== parentCapacity()) {
                    notifications.showConfirm(
                        getTranslation("You are about to change the capacity of the cryotank. It can not be changed back after this.")
                            + " "
                            + getTranslation("Are you sure you want to proceed?"),
                        () => true,
                        {
                            onCancel: () => {
                                nodeNumbering(nodeTemplate.numbering_string);
                                parentCapacity(nodeTemplate.parent_capacity);
                                parentCapacityRaw(nodeTemplate.parent_capacity);
                            },
                        },
                    );
                }
            });
        }

        nodeNumbering(nodeTemplate.numbering_string);  // initialize after all subscriptions have been set up

        this.layoutNodes.push({
            id: ko.observable(nodeTemplate.id),
            name: nodeName,
            nodeType: nodeTemplate.node_type,
            numbering: nodeNumbering,
            displayHintId: ko.observable(nodeTemplate.display_hints?.length === 1 ? nodeTemplate.display_hints[0] : undefined),
            displayRows: displayRows,
            displayRowsString: displayRowsString,
            parentCapacity: parentCapacity,
            parentCapacityRaw: parentCapacityRaw,
            numberingSum: numberingSum,
            errorMessage: ko.pureComputed(() => {
                if (nodeName.errorMessage()) {
                    return nodeName.errorMessage();
                }

                if (parentCapacity.errorMessage()) {
                    return parentCapacity.errorMessage();
                }

                return false;
            }),
        });
    };

    private removeNode = (node: LayoutNode) => {
        if (node.id()) {
            this.layoutNodes.destroy(node);
        } else {
            this.layoutNodes.remove(node);
        }
    };

    private deleteCryotank = () => {
        this.submitInProgress(true);

        CryotanksService.deleteCryotank({ cryotankId: this.cryotankId }).then(() => {
            this.submitInProgress(false);
            this.reloadRequired(true);
            this.dialog.close();
        }).catch(() => {
            notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
            this.submitInProgress(false);
        });
    };

    private serialize = () => {
        return {
            name: this.cryotankName() || null,
            description: this.cryotankDescription() || null,
            active: this.cryotankActive(),
            requestBody: this.layoutNodes().map((node) => {
                if (node._destroy) {
                    return {
                        id: node.id(),
                        delete: true,
                    };
                }

                return {
                    id: node.id(),
                    name: node.name() || null,
                    node_type: node.nodeType,
                    numbering_string: node.numbering() || null,
                    display_hints: node.displayHintId() ? [node.displayHintId()] : null,
                    display_rows: node.displayRows() || null,
                    parent_capacity: node.parentCapacity() || null,
                };
            }),
        };
    };

    private submitChanges = () => {
        this.submitInProgress(true);

        if (this.cryotankId) {
            CryotanksService.updateCryotank({
                cryotankId: this.cryotankId,
                ...this.serialize(),
            }).then(() => {
                this.submitInProgress(false);
                this.reloadRequired(true);
                notifications.showNotification(getTranslation("Cryotank updated"), "success");
            }).catch((response) => {
                notifications.showNotification(response.body.message || getTranslation("Action failed. The data could not be saved. Please try again."), "error");
                this.submitInProgress(false);
            });
        } else {
            CryotanksService.createCryotank(this.serialize()).then(() => {
                this.submitInProgress(false);
                this.reloadRequired(true);
                this.dialog.close();
            }).catch((response) => {
                notifications.showNotification(response.body.message || getTranslation("Action failed. The data could not be saved. Please try again."), "error");
                this.submitInProgress(false);
            });
        }
    };

}

export const showCryotankDetails = htmlDialogStarter(CryotankDetailsViewModel, template, {
    name: "CryotankDetails",
    width: 550,
    position: {
        inset: { top: 20, right: 20 },
    },
    closeOthers: true,
});
