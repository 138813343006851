import {
    observable,
    Observable,
    ObservableArray,
    observableArray,
    PureComputed,
    pureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    CompetenceAvailableProcedure,
    CompetenceAvailableSpecies,
    CompetenceAvailableUser,
    CompetenceCompetentUser,
    CompetenceDetails,
    TrainingRecordsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    addMonths,
    formatDate,
    parseDate,
} from "../lib/utils";

import competenceDetailsTemplate from "./competenceDetails.html";
import trainingDatesTemplate from "./competenceDetailsTrainingDates.html";

interface Params {
    competenceId: number;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface CompetentUserItem extends CompetenceCompetentUser {
    editable: () => boolean;
    showUserTrainingDates: () => void;
    updateComptetentUser: () => void;
    addTrainingDate: Observable<boolean>;
    newTrainingDate: Observable<string>;
    newExpirationDate: Observable<string>;
    newCompetentToTrain: Observable<boolean>;
    next_refresher_date: PureComputed<string>;
}

class TrainingDatesViewModel {
    private dialog: HtmlDialog;
    private params: CompetentUserItem;
    constructor(dialog: HtmlDialog, params: CompetentUserItem) {
        this.dialog = dialog;
        this.params = params;
        dialog.setTitle(
            _.template("<%= user_label %> <%= user_name %>")({
                user_label: getTranslation("User"),
                user_name: params.user_fullname,
            }),
        );
    }

    public close = () => {
        this.dialog.close();
    };
}

const showTrainingDates = htmlDialogStarter(TrainingDatesViewModel, trainingDatesTemplate, {
    name: "CompetenceDetailsTrainingDates",
    modal: false, // TODO: we want it to be modal, but it's not working with the flatpickr calendar
    width: 450,
    position: { inset: { top: 220, right: 40 } },
    closeOthers: true,
});

class CompetenceDetailViewModel {
    private dialog: HtmlDialog;
    private generalError: Observable<string>;
    private competenceId: number;
    private hideExpiredCompetentUsers: Observable<boolean>;
    private competenceName: CheckExtended<Observable<string>>;
    private competenceDescription: Observable<string>;
    private competentUsers: ObservableArray<CompetentUserItem>;
    private permittedProcedures: ObservableArray<number>; // ids
    private applicableSpecies: ObservableArray<number>; // ids
    private refresherMonths: CheckExtended<Observable<number>>;

    private competenceData: Observable<CompetenceDetails>;
    private allAvailableUsers: ObservableArray<CompetenceAvailableUser>;
    private availableUsers: PureComputed<CompetenceAvailableUser[]>;
    private availableSpecies: ObservableArray<{ selected: boolean } & CompetenceAvailableSpecies>;

    private availableProcedures: ObservableArray<{ selected: boolean } & CompetenceAvailableProcedure>;
    private listReloadRequired: Observable<boolean>;
    private fetchInProgress: Observable<boolean>;
    private updateInProgress: Observable<boolean>;
    private newCompetentUser: {
        add: () => void;
        achieveDate: Observable<string>;
        competentToTrain: Observable<boolean>;
        list: ObservableArray<{
            user_id: number;
            user_fullname: string;
            achieve_date: string;
            expiration_date: string;
            competent_to_train: boolean;
        }>;
        user: Observable<CompetenceAvailableUser>;
        remove: (item: any) => void;
        expirationDate: Observable<string>;
    };
    private canSave: PureComputed<boolean>;
    private removeCompetence: Observable<boolean>;

    /**
     * actors with permission "training_record_update_user" can update (add/edit) competent users of training record if:
     *   - actor is admin (both new & existing training record) or
     *   - actor belongs to the competent users pool of an existing training record and is "competent to train"
     */
    public canUpdateCompetentUser = observable(session.userPermissions.training_record_update_user);

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;

        /* internals */
        this.generalError = observable("");
        this.hideExpiredCompetentUsers = observable(true);

        /* competence details */
        this.competenceId = params.competenceId;
        this.competenceName = observable().extend({
            invalid: (v) => {
                return !_.size(v);
            },
        });
        this.competenceName.subscribe((v) => {
            dialog.setTitle(_.template(getTranslation("Competence <%= name %>"))({ name: v }));
        });
        this.competenceDescription = observable("");
        this.competentUsers = observableArray([]);
        this.permittedProcedures = observableArray();
        this.applicableSpecies = observableArray();
        this.refresherMonths = observable().extend({
            invalid: (v) => {
                if (!((_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0) || _.isUndefined(v))) {
                    return getTranslation("Invalid number");
                }

                return false;
            },
        });

        /* other data */
        this.allAvailableUsers = observableArray();
        TrainingRecordsService.getAvailableUsers().then((users) => {
            this.allAvailableUsers(users);
        });
        this.availableUsers = pureComputed(() => {
            let competentUserIds: number[] = [];
            const competenceData = this.competenceData();
            const allAvailableUsers = this.allAvailableUsers();
            const newCompetentUserIds = _.map(this.newCompetentUser.list(), "user_id");

            if (allAvailableUsers?.length) {
                if (competenceData) {
                    competentUserIds = _.map(
                        _.filter(competenceData.competent_users, (assignment) => {
                            return !assignment.expired;
                        }),
                        "user_id",
                    );
                }
                return _.filter(allAvailableUsers, (userData) => {
                    return (
                        !_.includes(competentUserIds, userData.userid) &&
                        !_.includes(newCompetentUserIds, userData.userid)
                    );
                });
            }

            return [];
        });
        this.availableSpecies = observableArray();
        this.availableProcedures = observableArray();

        /* observables */
        this.listReloadRequired = observable(false);

        this.dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }
            if (this.listReloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        this.fetchInProgress = observable(false);
        this.competenceData = observable();
        if (params.competenceId !== null) {
            this.fetchCompetenceData();
        } else {
            this.dialog.setTitle(getTranslation("Competence"));
            TrainingRecordsService.getAvailableSpecies().then((species) => {
                species.forEach((s) => {
                    this.availableSpecies.push({ selected: false, ...s });
                });
            });
            TrainingRecordsService.getAvailableProcedures().then((procedures) => {
                procedures.forEach((p) => {
                    this.availableProcedures.push({ selected: false, ...p });
                });
            });
        }

        /* new competent user */
        this.newCompetentUser = {
            // list of new users
            list: observableArray(),

            // single user
            user: observable(),
            achieveDate: observable(),
            expirationDate: observable(),
            competentToTrain: observable(false),

            add: () => {
                this.newCompetentUser.list.unshift({
                    user_id: this.newCompetentUser.user().userid,
                    user_fullname: this.newCompetentUser.user().fullname,
                    achieve_date: this.newCompetentUser.achieveDate(),
                    expiration_date: this.newCompetentUser.expirationDate(),
                    competent_to_train: this.newCompetentUser.competentToTrain(),
                });
                this.newCompetentUser.user(undefined);
                this.newCompetentUser.achieveDate(undefined);
                this.newCompetentUser.expirationDate(undefined);
                this.newCompetentUser.competentToTrain(false);
            },

            remove: (item) => {
                this.newCompetentUser.list.remove(item);
            },
        };

        this.updateInProgress = observable(false);
        this.canSave = pureComputed(() => {
            return !this.updateInProgress() && this.competenceName.isValid() && this.refresherMonths.isValid();
        });

        this.removeCompetence = observable(false);
    }

    public fetchCompetenceData() {
        this.fetchInProgress(true);
        TrainingRecordsService.getCompetenceDetails({
            competenceId: this.competenceId,
        })
            .then((competenceDetail) => {
                this.competenceData(competenceDetail);
                this.canUpdateCompetentUser(competenceDetail["can_update_competent_user"]);

                const applicableSpeciesIds = _.map(competenceDetail.applicable_species, "species_id");
                this.availableSpecies([]);
                TrainingRecordsService.getAvailableSpecies().then((species) => {
                    species.forEach((s) => {
                        this.availableSpecies.push({
                            selected: applicableSpeciesIds.indexOf(s.id) > -1,
                            ...s,
                        });
                    });
                });

                const permittedProcedureIds = _.map(competenceDetail.permitted_procedures, "procedure_id");
                this.availableProcedures([]);
                TrainingRecordsService.getAvailableProcedures().then((procedures) => {
                    procedures.forEach((p) => {
                        this.availableProcedures.push({
                            selected: permittedProcedureIds.indexOf(p.id) > -1,
                            ...p,
                        });
                    });
                });

                this.competenceName(competenceDetail.competence_name);
                this.competenceDescription(competenceDetail.competence_description);
                this.refresherMonths(competenceDetail.refresher_months || undefined);

                this.competentUsers([]);
                competenceDetail.competent_users.forEach((assignment: CompetenceCompetentUser) => {
                    let dialog: TrainingDatesViewModel = null;
                    const item = {
                        editable: () =>
                            this.canUpdateCompetentUser() &&
                            (
                                session.userId === assignment.user_id ||
                                this.allAvailableUsers().some((item) => {
                                    return item.userid === assignment.user_id;
                                })
                            ),

                        showUserTrainingDates: () => {
                            dialog = showTrainingDates(item);
                        },

                        updateComptetentUser: () => {
                            this.updateInProgress(true);
                            TrainingRecordsService.updateCompetentUserAssignment({
                                assignmentId: assignment.assignment_id,
                                requestBody: {
                                    expiration_date: item.newExpirationDate(),
                                    competent_to_train: item.newCompetentToTrain(),
                                    training_date: item.addTrainingDate()
                                        ? item.newTrainingDate() || "today"
                                        : undefined,
                                },
                            })
                                .then(() => {
                                    if (dialog) {
                                        dialog.close();
                                    }
                                    this.listReloadRequired(true);
                                    this.fetchCompetenceData();
                                })
                                .catch(() => {
                                    notifications.showNotification(
                                        getTranslation("Action failed. The data could not be saved. Please try again."),
                                        "error",
                                    );
                                })
                                .finally(() => {
                                    this.updateInProgress(false);
                                });
                        },

                        addTrainingDate: observable(false),
                        newTrainingDate: observable(),
                        newExpirationDate: observable(assignment.expiration_date),
                        newCompetentToTrain: observable(assignment.competent_to_train),

                        next_refresher_date: pureComputed(() => {
                            const trainingDate = parseDate(assignment.training_date);
                            let nextRefresherDate;

                            if (this.refresherMonths() && this.refresherMonths.isValid()) {
                                nextRefresherDate = addMonths(trainingDate, this.refresherMonths());
                                return formatDate(nextRefresherDate);
                            }
                        }),
                        ...assignment,
                    };
                    this.competentUsers.push(item);
                });
            })
            .catch((error) => {
                this.generalError(error);
            })
            .finally(() => {
                this.fetchInProgress(false);
            });
    }
    public closeDialog = () => {
        this.dialog.close();
    };

    public saveCompetence = () => {
        this.updateInProgress(true);
        this.listReloadRequired(true);

        if (this.removeCompetence()) {
            TrainingRecordsService.deleteCompetence({ competenceId: this.competenceId })
                .then(() => {
                    this.dialog.close();
                })
                .catch(() => {
                    this.generalError(getTranslation("General unexpected error occurred."));
                });
        } else {
            const requestBody = {
                competence_name: this.competenceName(),
                competence_description: this.competenceDescription(),
                refresher_months: this.refresherMonths() || undefined,
                new_permitted_procedure_ids: this.permittedProcedures() || [],
                new_applicable_species_ids: this.applicableSpecies() || [],
                new_competent_users:
                    this.newCompetentUser.list().map((a) => {
                        return {
                            user_id: a.user_id,
                            achieve_date: a.achieve_date,
                            expiration_date: a.expiration_date,
                            competent_to_train: a.competent_to_train,
                        };
                    }) || [],
            };
            if (this.competenceId) {
                TrainingRecordsService.updateCompetence({ competenceId: this.competenceId, requestBody })
                    .then(() => {
                        this.dialog.close();
                    })
                    .catch(() => {
                        this.generalError(getTranslation("General unexpected error occurred."));
                    });
            } else {
                TrainingRecordsService.createCompetence({ requestBody })
                    .then(() => {
                        this.dialog.close();
                    })
                    .catch(() => {
                        this.generalError(getTranslation("General unexpected error occurred."));
                    });
            }
        }
    };
}

export const showCompetenceDetails = htmlDialogStarter(CompetenceDetailViewModel, competenceDetailsTemplate, {
    name: "CompetenceDetails",
    width: 680,
    position: { inset: { top: 20, right: 20 } },
    closeOthers: true,
});
