import {
    AjaxPopup,
    AjaxPopupParams,
    closePopup,
} from "../popups";
import {
    cgiScript,
    getUrl,
    onceDocumentLoaded,
} from "../utils";

import { DetailPopup } from "./detailPopup";


export const FrameName = {
    detail: "detailframe",
    list: "listiframe",
    logout: "logoutframe",
};


export const loadLogoutFrame = () => {
    try {
        const logoutFrame = document.getElementById(FrameName.logout) as HTMLIFrameElement;
        if (logoutFrame.getAttribute("src").indexOf("choose_alias.py") === -1 ||
            logoutFrame.contentWindow.document.location.href.indexOf("new_alias") > -1) {
            logoutFrame.setAttribute("src", getUrl(cgiScript("choose_alias.py"), { popup: 1 }));
        }
    } catch (err) {
        // could not get iframe document anymore
    }
};

/* Let the mouse/rat in the pyrat logo blink. */
export const logoWink = () => {
    const logo = document.getElementById("pyrat_logo");
    logo.classList.add("wink");
    setTimeout(() => {
        logo.classList.remove("wink");
    }, 200);
};


/** Open ajax popup from a list table in listIFrame.
 *
 * Restore the scroll position and flash the changed row.
 *
 * @param scrollSelector A selector for the scrollable element (for example the table div).
 * @param flashSelector A selector for the element that should be highlighted after the page reloaded (for example the table row).
 * @param ...popupParams see pyratFrontend.popups.AjaxPopup
 */
export const openListAjaxPopup = ({
    scrollSelector,
    flashSelector,
    ...popupParams
}: { scrollSelector?: string; flashSelector?: string } & AjaxPopupParams) => {

    let offsetTop: number;
    let offsetLeft: number;
    if (scrollSelector) {
        const scrollElement = getListElement(scrollSelector);
        offsetTop = scrollElement.scrollTop;
        offsetLeft = scrollElement.scrollLeft;
    }

    const reloadCallback = function () {
        if (flashSelector) {
            flashListElement(flashSelector);
        }
        if (scrollSelector) {
            scrollListElementTo(scrollSelector, offsetLeft, offsetTop);
        }
    };

    popupParams.reloadCallback = () => {
        reloadListIframe({ "reloadCallback": reloadCallback });
    };

    return new AjaxPopup(popupParams);
};


/** Open detail window from a list table in listiframe.
 *
 * Provides functionality of jumping back to scroll position
 * and highlighting the current row.
 */
export const openListDetailPopup = (link: string, closeCallback?: () => void, tableDivSelector?: string, tableRowSelector?: string) => {
    detailPopup.open(link);

    if (typeof closeCallback === "function") {detailPopup.onCloseCallback = closeCallback; }

    if (tableDivSelector && tableRowSelector) {
        detailPopup.onReloadAfterClosingCallback = getDefaultListIframeReloadFunction(tableDivSelector, tableRowSelector);
    }
};

/**
 * Highlight an element in listiframe.
 *
 * For example a table row.
 */
export const flashListElement = (selector: string) => {
    const element = getListElement(selector);
    if (element) {
        element.classList.add("selected-row");

        setTimeout(() => {
            element.style.opacity = "0.7";
            element.classList.remove("selected-row");

            setTimeout(() => {
                element.style.opacity = "1";
            }, 400);
        }, 1500);
    }
};

/**
 * Get element in listiframe.
 */
export const getListElement = (selector: string) => {
    const listIFrame = document.getElementById("listiframe") as HTMLIFrameElement;
    const mainContent = listIFrame.contentWindow;
    const rightFrame = mainContent.document.getElementById("rightframe") as HTMLIFrameElement;
    const listContent = rightFrame ? rightFrame.contentWindow : mainContent;
    return listContent.document.body.querySelector(selector) as HTMLElement | undefined;
};

/**
 * Restore the given scroll position of an element in listiframe.
 */
export const scrollListElementTo = (selector: string, x: number, y: number) => {
    const element = getListElement(selector);
    if (element) {
        element.scrollTo(x, y);
    }
};

export const getDefaultListIframeReloadFunction = (tableDivSelector: string, rowSelector: string) => {
    const tableDivElement = getListElement(tableDivSelector);
    const offsetTop = tableDivElement.scrollTop;
    const offsetLeft = tableDivElement.scrollLeft;
    return () => {
        flashListElement(rowSelector);
        scrollListElementTo(tableDivSelector, offsetLeft, offsetTop);
    };
};

/* Open a quickselect popup window.
 *
 * Provides functionality of jumping back to scroll position
 * and highlighting the changed row.
 *
 * Note: This code must be in top. It cannot be in listiframe,
 * because when listiframe reloads, the code defined within can't be used
 * anymore. IE will show up with "Can't execute code from a freed script".
 *
 * always use POST for 2 reasons:
 * - animalids may be too large to fit into a single request line (default
 *   Apache limit ~ 8k)
 * - this function is also used to implement the 'back' function in
 *   generic_action_response_page template, which sometimes has to POST
 *   data to QS-subdialogs in order to go back
 */
export const openQuickSelect = (url: string, params: { [key: string]: string }) => {
    detailPopup.open({ url: url, params: params, method: "POST" });
};

/** Reload the main (list) iframe.
 *
 * @param reloadCallback Function that gets called after the iframe is reloaded.
 * @param newHref Do not reload, instead load given url.
 */
export const reloadListIframe = ({ reloadCallback, newHref }: { reloadCallback?: () => void; newHref?: string } = {}) => {

    const mainFrame = document.getElementById("listiframe") as HTMLIFrameElement;
    if (!mainFrame) {
        window.location.reload();
        return;
    }

    const mainContent = mainFrame.contentWindow;
    const listFrame = mainContent.document.getElementById("rightframe") ? mainContent.document.getElementById("rightframe") as HTMLIFrameElement : mainFrame;
    const listContent = listFrame.contentWindow;

    // show the loading pane over the old page
    const loadingElement = listFrame.contentDocument.getElementById("loading");
    if (loadingElement) {
        loadingElement.style.display = "";
    }

    // Reload and unset reloadAfterClosing detailPopup.
    listContent.location.href = getUrl(
        newHref || listContent.location.href,
        { reloaded: 1 },
        { absoluteUrl: true },
    );
    detailPopup.setReloadAfterClosing(false);

    // setup reload callback
    listFrame.addEventListener("load", () => {
        if (reloadCallback) {
            onceDocumentLoaded(listFrame.contentWindow.document, reloadCallback);
        }
    }, { once: true });
};



export const showTabs = () => {
    const headerElement = document.getElementById("header");
    headerElement.querySelectorAll(".show_on_second_page")
        .forEach((element: HTMLElement) => element.style.display = "block");
    (headerElement.querySelector(".gap_container") as HTMLDivElement).style.flexGrow = "0";

};

export const hideTabs = () => {
    const headerElement = document.getElementById("header");
    headerElement.querySelectorAll(".show_on_second_page")
        .forEach((element: HTMLElement) => element.style.display = "none");
    (headerElement.querySelector(".gap_container") as HTMLDivElement).style.flexGrow = "1";

};

export const openList = (link: string) => {

    // if we leave this listiframe, we do not want to reload it anymore
    detailPopup.setReloadAfterClosing(false);
    detailPopup.close();
    closePopup("AjaxPopup");
    const listIFrame = document.getElementById(FrameName.list) as HTMLIFrameElement;
    if (listIFrame) {
        listIFrame.setAttribute("src", link);
    }
};

export let detailPopup: DetailPopup;
export const initDetailPopup = () => {
    detailPopup = new DetailPopup();
};
