import dialogPolyfill from "dialog-polyfill";
import * as $ from "jquery";

import { assert } from "./assert";
import { getTranslation } from "./localize";
import { frames } from "./pyratTop";
import { runScriptTags } from "./runScriptTags";
import {
    getElementViewportPosition,
    getElementWindow,
    getFormData,
    getUrl,
} from "./utils";

// import required for Safari < 15.4 (released 2022-03, required until 2024-03)

export type PopupCallback = () => void;

interface Inset {
    left?: number | "auto" | undefined;
    right?: number | "auto" | undefined;
    top?: number | "auto" | undefined;
    bottom?: number | "auto" | undefined;
}

type HandleVerticalReference = "top" | "middle" | "bottom";
type HandleHorizontalReference = "left" | "center" | "right";

export interface PopupParams {
    name?: string;
    width?: number | "auto";
    height?: number | "auto";
    title?: string;
    anchor?: HTMLElement | Inset;  // former "clickElement" and "absPos" option
    handle?: `${HandleHorizontalReference} ${HandleVerticalReference}`;  // former "my" property of "position" option
    modal?: boolean;
    modalOnDrag?: boolean;
    escalate?: boolean;
    closeOthers?: boolean;
    closeOnEscape?: boolean;
    destroyOnClose?: boolean;
    onOpen?: PopupCallback;  // former "open" option
    onClose?: PopupCallback;  // former "close" option
    reloadCallback?: PopupCallback; // former "reload" option
}

export interface AjaxPopupParams extends PopupParams {
    method: "GET" | "POST";
    url: string;
    data: {[key: string]: string};
}

interface ElementPopupParams extends PopupParams {
    element: HTMLElement;
}

/** Check if the given object is a jQuery object. **/
function isJQueryObject(obj: any): obj is typeof $ {
    return typeof obj === "object" && typeof obj.jquery !== "undefined";
}

/** Check if the given object is a AbsolutePosition object. **/
function isInset(anchor: HTMLElement | Inset): anchor is Inset {
    return typeof anchor === "object" && (
        ("left" in anchor)
        || ("right" in anchor)
        || ("top" in anchor)
        || ("bottom" in anchor)
    );
}

/** Create a new positioning object in top context.
 *
 * This is a temporary element for dialog positioning, to make sure positioning of
 * top-dialogs to elements in frame works as expected.
 *
 * @param anchor - Element to position at.
 */
function createPositioningElement(anchor: HTMLElement) {
    const positioningElement = document.createElement("div");
    window.top.document.body.append(positioningElement);
    positioningElement.style.position = "absolute";
    const elementViewPortPosition = getElementViewportPosition(anchor);
    if (typeof elementViewPortPosition.left === "number") {
        positioningElement.style.left = `${elementViewPortPosition.left}px`;
    }
    if (typeof elementViewPortPosition.top === "number") {
        positioningElement.style.top = `${elementViewPortPosition.top}px`;
    }
    if (typeof elementViewPortPosition.width === "number") {
        positioningElement.style.width = `${elementViewPortPosition.width}px`;
    }
    if (typeof elementViewPortPosition.height === "number") {
        positioningElement.style.height = `${elementViewPortPosition.height}px`;
    }
    return  positioningElement;
}


/** Generator to create a new positioning object
 *
 * @param anchor - Element to position at.
 */
function* withPositioningElement(anchor: HTMLElement) {
    const positioningElement = createPositioningElement(anchor);
    yield positioningElement;
    positioningElement.remove();
}


class Popup {

    public triggerReloadCallbackOnClose = false;
    public readonly params: PopupParams;

    private readonly isTop: boolean;
    private readonly jQuery: JQueryStatic;
    public readonly element: HTMLElement;
    private readonly $element: JQuery;
    private readonly onOpen: PopupCallback[];
    private readonly onClose: PopupCallback[];

    /** Create a new popup
     *
     * @param element: HTML to convert to a popup.
     *
     * @param params - Popup parameters.
     *
     * @param params.title - The title of the popup.
     *
     * @param params.name - Name of the popup. If given, the popup is registered
     * in the PopupRegistry and it's ensures the is only a single popup with this name.
     * This allows to retrieve the popup instance later from the registry.
     *
     * @param params.width - The width (px) of the popup or "auto". Default: "auto"
     *
     * @param params.height - The height (px) of the popup or "auto". Default: "auto"
     *
     * @param params.onOpen - Function that gets called when the popup is opened.
     *
     * @param params.onClose - Function that gets called when the popup is closed.
     *
     * @param params.reloadCallback - A public function that can be called from the popup content when the
     * form is submitted. This is not triggered manually but via the `triggerReloadCallback` method.
     *
     * @param params.anchor - HTMLElement or AbsolutePosition object where the new dialog should be positioned.
     *
     * @param params.handle - String description of where the point of the popup what is
     * positioned over the `anchor`. Default: "left top"
     *
     * @param params.modal - Prevent uses from clicking behind the popup, by drawing a semitransparent
     * overlay behind it.
     *
     * @param params.modalOnDrag: If true (default) the modal overlay is shown but invisible, when the popup
     * is dragged and hidden when the popup loses focus.
     *
     * @param params.escalate - Always show the Popup in the top frame.
     *
     * @param params.closeOthers - Automatically close other popups with the same name. Default: true
     *
     * @param params.destroyOnClose -  Automatically destroy the popup when it's closed. Destroying means,
     * the original HTMLElement is restored to it's initial state and to unregister the popup from the
     * PopupRegistry. Default: true.
     *
     * @param params.closeOnEscape - Close the dialog when the Esc key was pressed.
     *
     */
    constructor(element: HTMLElement, params: PopupParams) {

        this.params = {
            width: "auto",
            height: "auto",
            handle: "left top",
            modal: false,
            modalOnDrag: !params.modal,
            escalate: false,
            closeOthers: true,
            closeOnEscape: true,
            destroyOnClose: true,
            ...params,
        };

        if (params.escalate) {
            // @ts-expect-error: We need top jQuery to calculate positions when escalating the dialog top the top frame.
            this.jQuery = window.top.jQuery;
        } else {
            this.jQuery = $;
        }

        this.isTop = window == window.top;
        this.$element = this.jQuery(element);
        this.element = element;

        this.onOpen = [];
        if (this.params.onOpen) {
            this.addOnOpen(this.params.onOpen);
        }
        this.onClose = [];
        if (this.params.onClose) {
            this.addOnClose(this.params.onClose);
        }

        if (this.params.closeOthers && this.params.name) {
            registry.close(this.params.name);
        }

        this.open();

    }

    /** Add a new callback, called after the popup was opened. **/
    public addOnOpen = (callback: PopupCallback) => {
        this.onOpen.push(callback);
    };

    /** Add a new callback, called after the popup was closed. **/
    public addOnClose = (callback: PopupCallback) => {
        this.onClose.push(callback);
    };

    /** Adjust the position and size of the popup
     *
     * @param anchor - HTMLElement or AbsolutePosition object to position the popup over.
     * @param handle - String describing the point on the popup placed over the `anchor`.
     */
    public immediateReposition = (anchor = this.params.anchor, handle = this.params.handle) => {

        const dialogElement = this.$element.closest(".ui-dialog");

        if (isJQueryObject(anchor)) {
            // Somebody illegally gave a jQuery element as anchor.
            anchor = $(anchor).get(0);
        }

        if (isHTMLElement(anchor)) {
            try {
                if (this.isTop && getElementWindow(anchor) != window) {
                    // A top-frame popup instance was created from within a frame.
                    // We need to a virtual element to position the dialog against.
                    for (const positioningElement of withPositioningElement(anchor)) {
                        dialogElement.position({ of: positioningElement, my: handle, collision: "fit" });
                    }
                } else {
                    dialogElement.position({ of: anchor, my: handle, collision: "fit" });
                }
            } catch (e) {
                // Catch "Permission denied" by positioning
                // against an element which might be gone in case
                // dialog is opened while list iframe was reloaded.
            }
        } else if (isInset(anchor)) {

            if (document.querySelector("html").getAttribute("dir") == "rtl") {
                // swap position left and right if the page is RTL
                [anchor.left, anchor.right] = [anchor.right, anchor.left];
            }

            dialogElement.css({
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                ...anchor,
            });

            // When moving the dialog element, the left and top offset will be set accordingly.
            // But if the dialog is already pinned to right or/and bottom, moving the dialog
            // will end up changing its width and height - you then have an accordion.
            if (anchor.right || anchor.bottom) {
                dialogElement.css({ right: "auto", bottom: "auto", ...dialogElement.position() });
            }
        } else {
            dialogElement.position({ my: "center", at: "center", of: window });
        }

        // TODO: Maybe make this behavior optional?
        // Set max height and scroll bars, if content is higher than the window.
        this.setHeightLimit();
    };

    /** Same as `immediateReposition` but covered in a setTimeout, to allow other code to render. **/
    public reposition = (anchor = this.params.anchor, handle = this.params.handle) => {
        window.setTimeout(() => {
            this.immediateReposition(anchor, handle);
        }, 0);
    };

    public setHeightLimit = () => {
        try {
            this.$element.css("max-height", "90vh");
            this.$element.css("overflow-y", "auto");
        } catch {
            // pass (ignore exception)
        }
    };

    public setTitle = (text: string) => {
        // check if element has a dialog instance
        if (this.$element.hasClass("ui-dialog-content")) {
            this.$element.dialog("option", "title", text);
        }
    };

    /** Instantiate (if required) and open the popup. **/
    public open = () => {
        if (this.$element.dialog("instance")) {
            this.$element.dialog("open");
        } else {
            this.$element.dialog({
                resizable: false,
                closeText: "",
                modal: this.params.modal || this.params.modalOnDrag,
                // @ts-expect-error: DOM-nodes are also accepted.
                appendTo: this.params.escalate ? window.top.document.body : window.document.body,
                dialogClass: "ajax-popup-dialog",
                stack: false, //  don't move dialog to top of dialog stack (increase z-index)
                width: this.params.width,
                height: this.params.height,
                title: this.params.title,
                closeOnEscape: this.params.closeOnEscape,
                open: () => {
                    this.onOpen.forEach(callback => {
                        if (typeof callback === "function") {
                            callback();
                        }
                    });
                },
                close: () => {
                    this.onClose.forEach(callback => {
                        if (typeof callback === "function") {
                            callback();
                        }
                    });
                    if (this.triggerReloadCallbackOnClose) {
                        this.triggerReloadCallback();
                    }
                    if (this.params.destroyOnClose) {
                        this.destroy();
                    }
                },
                drag: () => {
                    // TODO: Maybe make this behavior optional?
                    this.setHeightLimit();
                },
            });

            // set aria label for the close button
            this.$element
                .closest(".ui-dialog")
                .find(".ui-dialog-titlebar-close")
                .attr("title", getTranslation("Close"))
                .attr("aria-label", getTranslation("Close"));

            // Remove minHeight to allow smaller dialogs.
            this.$element.css("min-height", "");


            if (this.params.modalOnDrag) {
                const overlay = this.jQuery(".ui-widget-overlay");
                if (!this.params.modal) {
                    overlay.css("background-image", "none").hide();
                }
                this.$element
                    .on("dialogdragstart", () => {
                        overlay.show();
                    })
                    .on("dialogdragstop", () => {
                        overlay.hide();
                    });
            }

            // position the dialog
            this.immediateReposition();

            // self register this popup
            if (this.params.name) {
                registry.register(this, this.params.name);
            }

        }
    };

    /** Close the popup **/
    public close = () => {
        if (this.$element.dialog("instance")) {
            this.$element.dialog("close");
        }
    };

    /** Trigger the reloadCallback function, given as parameter to the popup during creation **/
    public triggerReloadCallback = () => {
        if (typeof this.params.reloadCallback === "function") {
            this.params.reloadCallback();
        }
    };

    /** Destroy the popup.
     *
     * The original HTMLElement is restored to it's initial state and popup is removed from the
     * PopupRegistry.
     */
    private destroy = () => {
        try {
            this.$element.dialog("destroy");
        } catch {
            // pass
        }
        registry.remove(this);
    };

}


export class AjaxPopup extends Popup {

    private readonly method: "GET" | "POST";
    private readonly url: string;
    private readonly data: { [key: string]: string };
    private readonly loadingBox: HTMLDivElement;
    private readonly errorMessage: HTMLDivElement;
    private readonly contentElement: HTMLDivElement;

    /* Create a new popup and load the content vai a GET or POST request.
    *
    * The name "AjaxPopup" is used for the registry by default.
    * .
    * @param params.method: The method for sending data to the server (GET or POST). Default: "GET"
    *
    * @param params.url: The url to load the popup content from.
    *
    * @param params.data: Data to send as FormData (for POST) or query string (for GET).
    *
    * All params from the parent `Popup` class are also accepted.
    */
    constructor(params: AjaxPopupParams) {

        const root = document.createElement("div");
        const { method, url, data, ...popupParams } = params;
        super(root, {
            name: "AjaxPopup",
            ...popupParams,
        });

        const loadingBox = document.createElement("div");
        loadingBox.style.padding = "30px 70px";
        loadingBox.id = "ajax-popup-loading-box";
        const loadingCirce = document.createElement("div");
        loadingCirce.style.marginTop = "0";
        loadingCirce.classList.add("loading_circle");
        loadingBox.appendChild(loadingCirce);
        root.appendChild(loadingBox);

        const errorMessage = document.createElement("div");
        errorMessage.id = "ajax-popup-error-message";
        const errorParagraph = document.createElement("p");
        errorParagraph.classList.add("error");
        errorParagraph.classList.add("txt-center");
        errorParagraph.innerText = getTranslation("Error while loading the data. Please try again.");
        errorMessage.appendChild(errorParagraph);
        root.appendChild(errorMessage);

        const contentElement = document.createElement("div");
        root.appendChild(contentElement);

        // params
        this.method = method || "GET";
        this.url = url;
        this.data = data;

        // elements
        this.loadingBox = loadingBox;
        this.errorMessage = errorMessage;
        this.contentElement = contentElement;

        // actually "first" load
        this.reload();
    }

    /** (Re-)Load the data for the popup. **/
    public reload = (): void => {
        this.errorMessage.style.display = "none";
        this.contentElement.innerHTML = "";
        this.loadingBox.style.display = "";
        this.immediateReposition();

        let request;
        if (this.method === "GET") {
            request = fetch(getUrl(this.url, this.data));
        } else if (this.method === "POST") {
            request = fetch(this.url, { method: "POST", body: getFormData(this.data) });
        }

        request
            .then(response => {
                assert(response.ok, `Request to ${response.url} failed.`);
                return response;
            })
            .then(response => response.text())
            .then((body) => {
                this.loadingBox.style.display = "none";
                this.contentElement.innerHTML = body;
                runScriptTags(this.contentElement);

                // bind event handler on 'Cancel' button
                this.contentElement.querySelectorAll(".ajax-popup-cancel-btn").forEach(button => {
                    button.addEventListener("click", (event) => {
                        event.preventDefault();
                        this.close();
                    });
                });

            })
            .catch(() => {
                this.loadingBox.style.display = "none";
                this.errorMessage.style.display = "";
            })
            .then(() => {
                this.reposition();
            });
    };

    /**
     * Submit a HTMLFormElement as GET request in the ListIframe and reload the list.
     *
     * @deprecated: Do no use! Better use fetch requests and reload manually.
     *
     * @param submittedForm: Form element to submit.
     */
    public applyFilterForm = (submittedForm: HTMLFormElement): false => {

        const data = $(submittedForm).serialize();
        const url = $(submittedForm).attr("action");
        const newHref = url + "?" + data;

        // close filter window and reload the list
        frames.reloadListIframe({ newHref: newHref });
        this.close();

        // return false to prevent default submit action
        return false;
    };
}

/* Create a new popup from a HtmlElement.
 *
 * Bind click on all child elements with class popup-close-btn to close the
 * popup (to imitate the old `popup.js -> DivPopup` behavior).
 */
export class ElementPopup extends Popup {

    constructor(params: ElementPopupParams) {
        const { element, ...popupParams } = params;
        super(element, popupParams);

        // bind event handler on 'Cancel' button
        element.querySelectorAll(".popup-close-btn").forEach(button => {
            button.addEventListener("click", (event) => {
                event.preventDefault();
                this.close();
            });
        });

    }
}


export class KnockoutPopup extends Popup {

    constructor(element: HTMLElement, params: PopupParams) {
        super(element, {
            escalate: true,
            ...params,
        });
        window.addEventListener("unload", () => this.close());
    }
}

// Position based on the window inset
interface InsetPosition {
    inset: Inset;  // former "absPos" option
}


// Position based on the position of another element
interface AnchorElementPosition {
    anchor: HTMLElement;  // former "clickElement"
    dialogHandle?: `${HandleHorizontalReference} ${HandleVerticalReference}`;  // former "my" property of "position" option
    anchorHandle?: `${HandleHorizontalReference} ${HandleVerticalReference}`;  // former "at" property of "position" option
}

export interface HtmlDialogParams extends Omit<PopupParams, "width" | "modalOnDrag" | "escalate" | "destroyOnClose" | "handle" | "anchor"> {
    width?: number | "auto" | "fit-content";

    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    position?: InsetPosition | AnchorElementPosition | null;
}

function isInsetPosition(position: HtmlDialogParams["position"]): position is InsetPosition {
    return (
        typeof position === "object" &&
        "inset" in position &&
        typeof position.inset === "object" &&
        isInset(position.inset)
    );
}

function isHTMLElement(anchor: any): anchor is HTMLElement {
    return anchor instanceof HTMLElement
        || (typeof anchor === "object" && Object.getPrototypeOf(anchor.constructor).name === "HTMLElement");
}

function isAnchorElementPosition(position: HtmlDialogParams["position"]): position is AnchorElementPosition {
    return typeof position === "object" && "anchor" in position && isHTMLElement(position.anchor);
}

export class HtmlDialog {
    public triggerReloadCallbackOnClose = false;
    public readonly params: HtmlDialogParams;
    private readonly onOpen: PopupCallback[];
    private readonly dialog: HTMLDialogElement;
    private readonly titleElement: HTMLHeadingElement;
    private dragState: {
        startY: number;
        startX: number;
        startDialogRect: DOMRect;
    } | null = null;

    /** Create a new popup using the HTML dialog element.
     *
     * @param element: HTML element to convert to a popup.
     *
     * @param params - Popup parameters.
     *
     * @param params.title - The title of the popup.
     *
     * @param params.name - Name of the popup. If given, the popup is registered
     * in the PopupRegistry, and it's ensures the is only a single popup with this name.
     * This allows to retrieve the popup instance later from the registry.
     *
     * @param params.width - The width (px) of the popup or "auto" or "fit-content". Default: "fit-content"
     *
     * @param params.height - The height (px) of the popup or "auto". Default: "auto"
     *
     * @param params.onOpen - Function that gets called when the popup is opened.
     *
     * @param params.onClose - Function that gets called when the popup is closed.
     *
     * @param params.reloadCallback - A public function that can be called from the popup content when the
     * form is submitted. This is not triggered manually but via the `triggerReloadCallback` method.
     *
     * @param params.position - Definition where the new dialog should be positioned.
     *
     * @param params.position.inset - InsetPosition object where the new dialog should be positioned.
     *
     * @param params.position.anchor - HTMLElement or AbsolutePosition object where the new dialog should be positioned.
     *
     * @param params.position.dialogHandle - String description of what point of the popup  is
     * positioned over the `anchor`. Only if the anchor is an HTML element. Default: "center middle"
     *
     * @param params.position.anchorHandle - String description of where the point of the popup what is
     * positioned over the `anchor`. Only if the anchor is an HTML element. Default: "left top"
     *
     * @param params.modal - Prevent uses from clicking behind the popup, by drawing a semitransparent
     * overlay behind it.
     *
     * @param params.closeOthers - Automatically close other popups with the same name. Default: true
     *
     * @param params.closeOnEscape - Close the dialog when the Esc key was pressed.
     *
     */
    constructor(element: HTMLElement, params: HtmlDialogParams) {
        this.params = {
            width: "fit-content",
            modal: false,
            title: "",
            closeOthers: true,
            closeOnEscape: true,
            ...params,
        };

        this.dialog = document.createElement("dialog");
        dialogPolyfill.registerDialog(this.dialog);
        this.dialog.classList.add("html-dialog-popup");

        if (typeof this.params.width === "number") {
            this.dialog.style.width = `${this.params.width}px`;
        } else if (this.params.width) {
            this.dialog.style.width = this.params.width;
        }
        if (typeof this.params.minWidth === "number") {
            this.dialog.style.minWidth = `${this.params.minWidth}px`;
        }
        if (typeof this.params.maxWidth === "number") {
            this.dialog.style.maxWidth = `${this.params.maxWidth}px`;
        }

        if (typeof this.params.height === "number") {
            this.dialog.style.height = `${this.params.height}px`;
        } else if (this.params.height) {
            this.dialog.style.height = this.params.height;
        }
        if (typeof this.params.minHeight === "number") {
            this.dialog.style.minHeight = `${this.params.minHeight}px`;
        }
        if (typeof this.params.maxHeight === "number") {
            this.dialog.style.maxHeight = `${this.params.maxHeight}px`;
        }

        const header = document.createElement("header");
        header.addEventListener("mousedown", this.onDragStart);
        header.addEventListener("touchstart", this.onDragStart);
        document.addEventListener("mousemove", this.onDrag);
        document.addEventListener("touchmove", this.onDrag);
        document.addEventListener("mouseup", this.onDragEnd);
        document.addEventListener("touchend", this.onDragEnd);

        this.titleElement = document.createElement("h1");
        this.titleElement.title = this.params.title;
        this.titleElement.innerText = this.params.title;

        const closeButton = document.createElement("button");
        closeButton.addEventListener("click", this.close);
        closeButton.type = "button";
        closeButton.title = getTranslation("Close");
        closeButton.ariaLabel = getTranslation("Close");
        closeButton.classList.add("html-dialog-popup-close-button");

        header.appendChild(this.titleElement);
        header.appendChild(closeButton);
        this.dialog.appendChild(header);

        const main = document.createElement("main");
        main.appendChild(element);

        this.dialog.appendChild(main);

        this.onOpen = [];
        if (this.params.onOpen) {
            this.addOnOpen(this.params.onOpen);
        }
        if (this.params.onClose) {
            this.addOnClose(this.params.onClose);
        }
        this.dialog.addEventListener("close", () => {
            if (this.triggerReloadCallbackOnClose && typeof this.params.reloadCallback === "function") {
                this.params.reloadCallback();
            }
        });
        this.dialog.addEventListener("cancel", (event) => {
            if (!this.params.closeOnEscape) {
                event.preventDefault();
            }
        });

        if (this.params.closeOthers && this.params.name) {
            registry.close(this.params.name);
        }

        document.body.append(this.dialog);
        this.dialog.addEventListener("close", () => {
            document.body.removeChild(this.dialog);
        });

        this.open();
    }

    /** Add a new callback, called after the popup was opened. **/
    public addOnOpen = (callback: PopupCallback) => {
        this.onOpen.push(callback);
    };

    /** Add a new callback, called after the popup was closed. **/
    public addOnClose = (callback: PopupCallback) => {
        if (typeof callback === "function") {
            this.dialog.addEventListener("close", callback);
        }
    };

    /** Adjust the position and size of the popup
     *
     * @param position.anchor - HTMLElement or AbsolutePosition object to position the popup over.
     * @param position.dialogHandle - String describing the point on the dialog placed over the `anchor`.
     * @param position.anchorHandle - String describing the point on the `anchor` where the dialog is placed over.
     */
    public immediateReposition = (
        position: HtmlDialogParams["position"] = this.params.position,
    ) => {

        let dialogPosition: Inset = {
            left: "auto",
            right: "auto",
            top: 20,
            bottom: "auto",
        };

        if (isInsetPosition(position)) {
            // position is an inset
            dialogPosition = { ...dialogPosition, ...position.inset };

            // swap position left and right if the page is RTL
            if (document.querySelector("html").getAttribute("dir") == "rtl") {
                [dialogPosition.left, dialogPosition.right] = [dialogPosition.right, dialogPosition.left];
            }

        } else if (isAnchorElementPosition(position)) {
            // position is an HTMLElement
            const {
                anchor,
                anchorHandle = "middle center",
                dialogHandle = "left top",
            } = position as AnchorElementPosition;
            let anchorReferenceX = 0;
            let anchorReferenceY = 0;
            let left = 0;
            let top = 0;
            const anchorRect = anchor.getBoundingClientRect();
            const dialogRect = this.dialog.getBoundingClientRect();

            // calculate the reference point on the anchor
            const anchorHandlePosition = anchorHandle.split(" ");
            if (anchorHandlePosition.includes("top")) {
                anchorReferenceY = anchorRect.top;
            } else if (anchorHandlePosition.includes("middle")) {
                anchorReferenceY = anchorRect.top + anchorRect.height / 2;
            } else if (anchorHandlePosition.includes("bottom")) {
                anchorReferenceY = anchorRect.top + anchorRect.height;
            }
            if (anchorHandlePosition.includes("left")) {
                anchorReferenceX = anchorRect.left;
            } else if (anchorHandlePosition.includes("center")) {
                anchorReferenceX = anchorRect.left + anchorRect.width / 2;
            } else if (anchorHandlePosition.includes("right")) {
                anchorReferenceX = anchorRect.left + anchorRect.width;
            }

            // calculate the position of the popup
            const dialogHandlePosition = dialogHandle.split(" ");
            if (dialogHandlePosition.includes("top")) {
                top = anchorReferenceY;
            } else if (dialogHandlePosition.includes("middle")) {
                top = anchorReferenceY - dialogRect.height / 2;
            } else if (dialogHandlePosition.includes("bottom")) {
                top = anchorReferenceY - dialogRect.height;
            }
            if (dialogHandlePosition.includes("left")) {
                left = anchorReferenceX;
            } else if (dialogHandlePosition.includes("center")) {
                left = anchorReferenceX - dialogRect.width / 2;
            } else if (dialogHandlePosition.includes("right")) {
                left = anchorReferenceX - dialogRect.width;
            }

            dialogPosition = { top: top, right: "auto", bottom: "auto", left: left };
        }

        // use dialog width to calculate inset if left and right are "auto"
        if (dialogPosition.left === "auto" && dialogPosition.right === "auto") {
            const dialogRect = this.dialog.getBoundingClientRect();
            const windowWidth = window.innerWidth;
            const dialogWidth = dialogRect.width;
            dialogPosition.left = (windowWidth - dialogWidth) / 2;
        }

        if (typeof dialogPosition.top === "number") {
            this.dialog.style.top = `${dialogPosition.top}px`;
        } else {
            this.dialog.style.top = dialogPosition.top;
        }
        if (typeof dialogPosition.right === "number") {
            this.dialog.style.right = `${dialogPosition.right}px`;
        } else {
            this.dialog.style.right = dialogPosition.right;
        }
        if (typeof dialogPosition.bottom === "number") {
            this.dialog.style.bottom = `${dialogPosition.bottom}px`;
        } else {
            this.dialog.style.bottom = dialogPosition.bottom;
        }
        if (typeof dialogPosition.left === "number") {
            this.dialog.style.left = `${dialogPosition.left}px`;
        } else {
            this.dialog.style.left = dialogPosition.left;
        }

        // ensure the dialog is not outside the viewport
        const dialogRect = this.dialog.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const collisionMargin = "5px";
        if (dialogRect.right > windowWidth) {
            this.dialog.style.left = "auto";
            this.dialog.style.right = collisionMargin;
        }
        if (dialogRect.left < 0) {
            this.dialog.style.left = collisionMargin;
            this.dialog.style.right = "auto";
        }
        if (dialogRect.top < 0) {
            this.dialog.style.top = collisionMargin;
            this.dialog.style.bottom = "auto";
        }
    };

    /** Same as `immediateReposition` but covered in a setTimeout, to allow other code to render. **/
    public reposition = (
        position: HtmlDialogParams["position"] = this.params.position,
    ) => {
        window.setTimeout(() => {
            this.immediateReposition(position);
        }, 0);
    };

    public setTitle = (text: string) => {
        this.titleElement.title = text;
        this.titleElement.innerText = text;
    };

    /** Instantiate (if required) and open the popup. **/
    public open = () => {
        if (this.params.modal) {
            this.dialog.showModal();
        } else {
            this.dialog.show();
        }

        this.onOpen.forEach((callback) => {
            if (typeof callback === "function") {
                callback();
            }
        });

        // position the dialog
        this.immediateReposition();

        // self register this popup
        if (this.params.name) {
            registry.register(this, this.params.name);
        }
    };

    /** Close the popup **/
    public close = () => {
        this.dialog.close();
        registry.remove(this);
    };

    private onDragStart = (event: MouseEvent | TouchEvent) => {

        if (this.dragState) {
            // already dragging
            event.preventDefault();
            return;
        }

        // disable text selection, disable pull to refresh, enable move cursor
        this.dialog.classList.add("dragging");

        const dialogRect = this.dialog.getBoundingClientRect();
        if (window.TouchEvent && event instanceof TouchEvent) {
            this.dragState = {
                startY: event.touches[0].clientY,
                startX: event.touches[0].clientX,
                startDialogRect: dialogRect,
            };
        } else if (event instanceof MouseEvent) {
            this.dragState = {
                startX: event.clientX,
                startY: event.clientY,
                startDialogRect: dialogRect,
            };
        } else {
            // unexpected event
            return;
        }
    };

    private onDrag = (event: MouseEvent | TouchEvent) => {
        if (!this.dragState) {
            // not dragging
            return;
        }

        event.preventDefault();
        window.getSelection().removeAllRanges();

        let clientX;
        let clientY;
        if (window.TouchEvent && event instanceof TouchEvent) {
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;
        } else if (event instanceof MouseEvent) {
            clientX = event.clientX;
            clientY = event.clientY;
        } else {
            // unexpected event
            return;
        }

        // ignore all movements outside the viewport
        if (clientX < 0 || clientY < 0 || clientX > window.innerWidth || clientY > window.innerHeight) {
            return;
        }

        // calculate the delta from the start position
        let deltaX = clientX - this.dragState.startX;
        let deltaY = clientY - this.dragState.startY;

        // if the new position is out of screen, adjust the delta to the border
        if (this.dragState.startDialogRect.left + deltaX < 0) {
            deltaX = -this.dragState.startDialogRect.left;
        } else if (this.dragState.startDialogRect.right + deltaX > window.innerWidth) {
            deltaX = window.innerWidth - this.dragState.startDialogRect.right;
        }
        if (this.dragState.startDialogRect.top + deltaY < 0) {
            deltaY = -this.dragState.startDialogRect.top;
        } else if (this.dragState.startDialogRect.bottom + deltaY > window.innerHeight) {
            deltaY = window.innerHeight - this.dragState.startDialogRect.bottom;
        }

        // calculate the new position and move the dialog
        const newLeft = this.dragState.startDialogRect.left + deltaX;
        const newTop = this.dragState.startDialogRect.top + deltaY;
        this.dialog.style.top = `${newTop}px`;
        this.dialog.style.right = "auto";
        this.dialog.style.bottom = "auto";
        this.dialog.style.left = `${newLeft}px`;
    };

    private onDragEnd = () => {
        this.dragState = null;
        this.dialog.classList.remove("dragging");
        document.body.style.touchAction = "auto";
    };
}



class PopupRegistry {

    private readonly registry: {
        [key: string]: Popup | HtmlDialog;
    };

    constructor() {
        this.registry = {};
    }

    /** Get the named popup instance from the registry. **/
    public get = (name: string): HtmlDialog | Popup | AjaxPopup | ElementPopup | KnockoutPopup | undefined => {
        return this.registry?.[name];
    };

    /** Close the named popup instance. **/
    public close = (name: string) => {
        this.get(name)?.close();
    };

    /** Reposition the named popup instance. **/
    public reposition = (name: string) => {
        this.get(name)?.reposition();
    };

    /** Trigger the reloadCallback function of the named popup instance. **/
    public triggerReloadCallback = (name: string) => {
        const dialogInstance = this.get(name);
        if (!(dialogInstance instanceof HtmlDialog)) {
            dialogInstance?.triggerReloadCallback();
        }
    };

    /** Remove the given popup instance from the registry. **/
    public remove = (popup: Popup | HtmlDialog): void => {
        for (const key in this.registry) {
            if (this.registry[key] === popup) {
                delete this.registry[key];
            }
        }
    };

    /** Add the given popup instance to the registry if the name is not yet used. */
    public register = (popup: Popup | HtmlDialog, name: string): void => {
        if (name in this.registry) {
            throw Error(`Popup name ${JSON.stringify(name)} already in use. ` +
                        "Choose a different name, no name, or close the other popup before.");
        } else {
            this.registry[name] = popup;
        }
    };

}

const registry = new PopupRegistry();
export const getPopup = registry.get;
export const closePopup = registry.close;
export const repositionPopup = registry.reposition;
export const triggerReloadCallback = registry.triggerReloadCallback;
