/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_saveLicenseSignOff } from '../models/Body_saveLicenseSignOff';
import type { IdLabelProperty } from '../models/IdLabelProperty';
import type { LicenseClassificationOption } from '../models/LicenseClassificationOption';
import type { LicenseClassificationOptionDelimiter } from '../models/LicenseClassificationOptionDelimiter';
import type { LicenseDefaultSignOffDetails } from '../models/LicenseDefaultSignOffDetails';
import type { LicenseOption } from '../models/LicenseOption';
import type { LicenseOptionDelimiter } from '../models/LicenseOptionDelimiter';
import type { LicenseSignOffDetails } from '../models/LicenseSignOffDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LicensesService {

    /**
     * Get License Options Endpoint
     * Get the list of licenses for setting a license.
     *
     * Replacement for former:
     * - ajax_service -> get_licence_list_json
     * - ajax_service -> get_licenses_for_association
     * - ajax_service -> get_licenses_for_setting
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getLicenseOptions({
        speciesId,
        strainId,
        which = 'for_setting',
        addDelimiter = false,
    }: {
        speciesId?: (number | null),
        strainId?: (Array<number> | null),
        which?: 'for_setting' | 'for_association' | 'for_filter',
        addDelimiter?: boolean,
    }): CancelablePromise<Array<(LicenseOption | LicenseOptionDelimiter)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/licenses/license_options',
            query: {
                'species_id': speciesId,
                'strain_id': strainId,
                'which': which,
                'add_delimiter': addDelimiter,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get License Classification Options Endpoint
     * Get the list of classifications for setting a license.
     *
     * Replacement for former:
     * - ajax_service -> get_licence_classifications_json
     * - ajax_service -> get_license_classifications_for_association
     * - ajax_service -> get_license_classifications_for_setting
     * - ajax_service -> get_license_classifications_for_filter
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getLicenseClassificationOptions({
        licenseId,
        speciesId,
        strainId,
        which = 'for_setting',
        addDelimiter = false,
    }: {
        licenseId: number,
        speciesId?: (number | null),
        strainId?: (Array<number> | null),
        which?: 'for_setting' | 'for_association' | 'for_filter',
        addDelimiter?: boolean,
    }): CancelablePromise<Array<(LicenseClassificationOption | LicenseClassificationOptionDelimiter)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/licenses/license/{license_id}/classification_options',
            path: {
                'license_id': licenseId,
            },
            query: {
                'species_id': speciesId,
                'strain_id': strainId,
                'which': which,
                'add_delimiter': addDelimiter,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Procedures For Classification Endpoint
     * Get procedures for the given classification.
     *
     * Replacement for former:
     * - ajax_service -> get_procedures_for_classification
     * @returns IdLabelProperty Successful Response
     * @throws ApiError
     */
    public static getProceduresForClassification({
        classificationId,
    }: {
        classificationId: number,
    }): CancelablePromise<Array<IdLabelProperty>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/licenses/classification/{classification_id}/procedures',
            path: {
                'classification_id': classificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get License Sign Off Data
     * Get the sign-off details for a license or default (license_id=0).
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getLicenseSignOff({
        licenseId,
    }: {
        licenseId: number,
    }): CancelablePromise<(LicenseSignOffDetails | LicenseDefaultSignOffDetails)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/licenses/license/{license_id}/sign_off',
            path: {
                'license_id': licenseId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post License Sign Off Data
     * Save the sign-off details for a license or the default (license_id=0).
     * @returns any Successful Response
     * @throws ApiError
     */
    public static saveLicenseSignOff({
        licenseId,
        requestBody,
    }: {
        licenseId: number,
        requestBody: Body_saveLicenseSignOff,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/licenses/license/{license_id}/sign_off',
            path: {
                'license_id': licenseId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
