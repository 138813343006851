import {
    Observable,
    observableArray,
    pureComputed,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import {
    showLicenseDetails,
    showLicenseSignOff,
} from "../../dialogs";
import { Option as SelectemOption } from "../../knockout/components/selectem";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { mainMenu } from "../../lib/pyratTop";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getFormattedCurrentDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./licenseListFilter.html";


interface Arguments extends ListViewArguments {
    export_args: any;
}

// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = (filter: ListFilterModel) => ({
    license_number: class extends ListFilterItem {
        public readonly possibleValues: PureComputed<{ name: string; id: number }[]>;
        public readonly currentCustomValues: ObservableArray<string>;

        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.currentCustomValues = observableArray();
            this.possibleValues = pureComputed(() => {
                return _.map(seed.possible_values, (licenseNumber) => {
                    return {
                        id: licenseNumber,
                        name: licenseNumber,
                    };
                });
            });

            this.text = seed.possible_values;

            this.deserialize = (newValue) => {
                if (typeof newValue === "string" && seed.possible_values.includes(newValue) === false) {
                    this.currentCustomValues.push(newValue);
                }
                value(newValue);
            };
        }
    },

    license_title: ListFilterItem,

    license_type_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    government_id: ListFilterItem,

    valid_from: class extends ListFilterItem {
        public readonly value: CheckExtended<Observable<string>>;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("valid_to"), compareFromDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    valid_to: class extends ListFilterItem {
        public readonly value: CheckExtended<Observable<string>>;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("valid_from"), compareToDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    budget_id: class extends ListFilterItem {
        public readonly staticValues: { name: string; id: number }[];
        public readonly possibleValues: { name: string; id: number }[];
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.staticValues = [{ id: 0, name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values);

            this.text = _.map(seed.possible_values, "name");
        }
    },

    project_leader_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "fullname");
        }
    },

    permitted_user_id: class extends ListFilterItem {
        public readonly staticValues: { fullname: string; userid: number }[];
        public readonly possibleValues: { fullname: string; userid: number }[];
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.staticValues = [{ userid: 0, fullname: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values);
            this.text = _.map(seed.possible_values, "fullname");
        }
    },

    status: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    expired: ListFilterItem,

    overused: ListFilterItem,

    deleted: ListFilterItem,

    classification_species_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((option: any) => {
                return option.name;
            });
        }
    },

    classification_strain_name_or_id: class extends ListFilterItem {
        public readonly staticValues: { name_with_id: string; id: number }[];
        public readonly possibleValues: PureComputed<{ name_with_id: string; id: number }[]>;
        public readonly selectemValue: ObservableArray<SelectemOption<{ id: number | string; name_with_id: string }>>;
        public readonly currentCustomValues: ObservableArray<string>;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.selectemValue = observableArray();
            this.selectemValue.subscribe((newValue) => {
                newValue.forEach((opt) => {
                    if (opt.id === opt.name_with_id && opt.id.indexOf("*") === -1) {
                        opt.valid(false);
                    }
                });
            });

            this.currentCustomValues = observableArray();
            this.staticValues = [{ id: 0, name_with_id: getTranslation("None") }];
            this.possibleValues = pureComputed(() => {
                return this.staticValues.concat(seed.possible_values);
            });

            this.text = pureComputed(() => {
                return _.map(seed.possible_values, "name_with_id");
            });

            this.valid = pureComputed(() => {
                return _.every(this.selectemValue(), (opt) => {
                    return !opt.valid || opt.valid();
                });
            });

            this.deserialize = (newValue) => {
                const customValues = Array.isArray(newValue)
                    ? newValue.filter((value) => {
                        return typeof value === "string";
                    })
                    : [];

                if (customValues.length) {
                    this.currentCustomValues(customValues);
                }
                value(newValue);
            };
        }
    },

    page_size: ListFilterItem,
});

class List {
    private readonly listView: ListView;
    private readonly args: Arguments;

    public showSignOff = (licenseId: number | null) => {
        showLicenseSignOff({ licenseId, reloadCallback: this.listView.reload });
    };

    public showDetails = (licenseId: number | null) => {
        this.listView.highlightRow(licenseId);
        showLicenseDetails({
            licenseId: licenseId,
            onClose: () => this.listView.unHighlightRow(licenseId),
            reloadCallback: () => this.listView.reload(),
        });
    };

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("License filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("create-button", () => {
            this.showDetails(null);
        });

        this.listView.onMenuBoxClick("default-sign-off-button", () => {
            this.showSignOff(null);
        });
        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onCellClick("td.classifications_used_animals a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                licence_assign: {
                    licence: parseInt(args.rowId, 10),
                    classification: args.element.dataset.classificationId,
                    assign_from: args.element.dataset.validFrom,
                    assign_to: getFormattedCurrentDate(),
                },
            });
        });

        this.listView.onCellClick("td.classifications_used_pups a", (args) => {
            mainMenu.openAndResetListFilter("get_pup_list", {
                licence_assign: {
                    licence: parseInt(args.rowId, 10),
                    classification: args.element.dataset.classificationId,
                    assign_from: args.element.dataset.validFrom,
                    assign_to: getFormattedCurrentDate(),
                },
            });
        });

        this.listView.onCellClick("td.license_number.clickable, td.license_title.clickable", (args) => {
            this.showDetails(parseInt(args.rowId, 10));
        });

        this.listView.onCellClick("td.sign_off_button input", (args) => {
            this.showSignOff(parseInt(args.rowId, 10));
        });
    }
}

export const initLicenseList = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
