import {
    Subscribable,
    Computed,
    PureComputed,
    Observable,
    ObservableArray,
} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";

import {
    AnimalsService,
    CagesService,
    LicenseClassificationOption,
    LicenseClassificationOptionDelimiter,
    LicenseOption,
    LicensesService,
    NextCagenumberSuffix,
    PupsService,
    UsersService,
} from "../backend/v1";
import {
    PreselectLocationItem,
    LocationItem,
} from "../knockout/components/locationPicker/locationPicker";
import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    frames,
    mainMenu,
} from "../lib/pyratTop";
import {
    cgiScript,
    getFormData,
    getUrl,
    AjaxResponse,
    isInvalidCalendarDate,
    isInvalidEartagPrefix,
    isInvalidEartagSuffix,
    isInvalidCagePrefix,
    isInvalidCageSuffix,
} from "../lib/utils";

import template from "./animalExportQuickselect.html";

import "/scss/quick_select.scss";


// The definition of Seed is quite complex here, so we use a namespace knowing it is not recommended.
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Seed {

    interface Context {
        animals: {
            animalid: number;
            eartag: string;
            cagetype: "Breeding" | "Stock" | "Experiment";
            species_id: number | undefined;
            strain_id: number | undefined;
            state: string;
        }[];
        pups: {
            pupid: number;
            eartag_or_id: string;
            cagetype: "Breeding" | "Stock" | "Experiment";
            species_id: number | undefined;
            strain_id: number | undefined;
            state: string;
        }[];
        pup_list_was_extended: boolean;
    }

    interface Content {
        export_to_scientist_action?: ExportToScientistAction;
        export_to_institution_action?: ExportToInstitutionAction;
        set_license_action?: SetLicenseAction;
        perform_workrequest_action?: PerformWorkrequestAction;
    }

    interface WorkRequestDetails {
        behavior_name: string;
        new_owner_id: number | undefined;
        change_responsible: boolean | undefined;
        new_responsible_id: number | undefined;
        new_location: PreselectLocationItem | undefined;
        license_id: number | undefined;
        classification_id: number | undefined;
        facility_id: number;
        export_comment: string;
    }

    interface ExportToScientistAction {
        subactions: string[];
        owners: {
            id: number;
            label: string;
            prefixes: string[];
        }[];
        workrequest_details?: WorkRequestDetails;
    }

    interface ExportToInstitutionAction {
        institutions: {
            id: number;
            name: string;
        }[];
        workrequest_details?: WorkRequestDetails;
    }

    interface SetLicenseAction {
        licenses: {
            id: number;
            name: string;
        }[];
        workrequest_details?: WorkRequestDetails;
    }

    interface PerformWorkrequestAction {
        workrequest_handling: {
            workrequest_id: number;
            close_workrequest: boolean;
        };
    }
}

interface Response {
    success: boolean;
    context: {
        animals: {
            animal_id: number[];
            eartag: string;
        };
        pups: {
            pup_id: number[];
            eartag: string;
        };
    };
    content: {
        export_to_scientist_action?: {
            not_updated_pup_cage_ids?: number[];
        };
        export_to_institution_action?: any;
        set_license_action?: any;
    };
}

interface Params {
    animalIds?: number[];
    pupIds?: number[];
    actions?: string[];
    reloadCallback?: () => void;
    workrequestId?: number;
    closeWorkrequest?: boolean;
}

abstract class Action {

    public qs: AnimalExportQuickselectViewModel;
    public requireConclusion = true;
    public selected: Subscribable<boolean>;
    public enabled: Subscribable<boolean>;
    public valid: Subscribable<boolean>;
    public possible: Subscribable<boolean>;
    public errors: ObservableArray<string>;

    protected constructor(qs: AnimalExportQuickselectViewModel) {
        this.qs = qs;
        this.selected = ko.observable(false);
        this.enabled = ko.observable(true);
        this.valid = ko.observable(true);
        this.errors = ko.observableArray([]);
        this.possible = ko.observable(true);
    }

    public serialize = () => {
        return {};
    };
}


// TODO: Waiting for "inherit" from in TypeScript to replace "Action" return value with implementation.
// noinspection JSPotentiallyInvalidUsageOfThis
const actionModels: { [key in keyof Seed.Content]: (qs: AnimalExportQuickselectViewModel, seed: Seed.Content[key]) => Action } = {

    export_to_scientist_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        // subactions (checkboxes)
        public subactionAnimalAssignId: Observable<boolean>;
        public subactionAnimalSetResponsible: Observable<boolean>;
        public subactionCageSetLocation: Observable<boolean>;
        public subactionCageAssignId: Observable<boolean>;
        public subactionCageSetResponsible: Observable<boolean>;
        public subactionNewCageMoveAnimals: Observable<boolean>;
        public subactionExportStrains: Observable<boolean>;
        public subactionExportProjects: Observable<boolean>;

        public ownerId: CheckExtended<Observable<number>>;
        public comment: Observable<string>;
        public prefixes: Computed<string[]>;
        public availableResponsibles: FetchBackendExtended<Observable<{
            userid: number;
            fullname: string;
        }>>;

        private readonly getIncompatibleSanitaryStatusLocations: (newRackId: number) => Promise<string[]>;

        // animal assign id
        public animalPrefixFromList: CheckExtended<Observable<string>>;
        public animalPrefix: CheckExtended<Observable<string>>;
        public animalKeepSuffix: Observable<boolean>;
        public animalSuffix: CheckExtended<Observable<string>>;
        public animalNextFreeEartag: FetchBackendExtended<Observable<string>>;
        public animalNextFreeEartagError: Observable<string>;

        // animal set responsible:
        public animalResponsibleId: Observable<number>;

        // cage assign id
        public cagePrefixFromList: CheckExtended<Observable<string>>;
        public cagePrefix: CheckExtended<Observable<string>>;
        public cageSuffix: CheckExtended<Observable<string>>;
        public cageNextFreeSuffix: FetchBackendExtended<Observable<NextCagenumberSuffix>>;

        // cage set location
        public cageSelectedLocation: CheckExtended<Observable<LocationItem>>;
        public cageUnselectLocation: Observable<string>;
        public cagePreselectLocation: Observable<PreselectLocationItem>;
        public cageRackId: Computed<number>;
        public cagePositions: Observable<string>;
        public cageSelectLocationAfterApply: () => void;

        public cageConfirmSanitaryStatus: CheckExtended<Observable<boolean>>;
        public cageShowConfirmSanitaryStatus: Computed<boolean>;
        public cageIncompatibleSanitaryStatus: ObservableArray<string> = ko.observableArray([]);

        // move to new cage
        public newCageSelectedLocation: CheckExtended<Observable<LocationItem>>;
        public newCageUnselectLocation: Observable<string>;
        public newCagePreselectLocation: Observable<PreselectLocationItem>;
        public newCageRackId: Computed<number>;
        public newCagePrefix: CheckExtended<Observable<string>>;
        public newCageType: Observable<string>;
        public newCageCategoryId: CheckExtended<Observable<number>>;
        public newCageOpenManyNewCages: Observable<boolean>;
        public newCagePerCage: CheckExtended<Observable<number>>;
        public newCageKeepAnimalsTogether: Observable<boolean>;
        public newCageFillMethod: Observable<string>;
        public newCagePositions: Observable<string>;
        public newCageSelectLocationAfterApply: () => void;

        public newCageConfirmSanitaryStatus: CheckExtended<Observable<boolean>>;
        public newCageShowConfirmSanitaryStatus: Computed<boolean>;
        public newCageIncompatibleSanitaryStatus: ObservableArray<string> = ko.observableArray([]);

        // cage set responsible
        public cageResponsibleId: Observable<number>;
        public enableCageSetResponsible: Computed<boolean>;

        constructor() {
            super(qs);

            this.ownerId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.ownerId.subscribe(() => {
                this.selected(true);
            });

            this.comment = ko.observable();
            this.comment.subscribe(() => {
                this.selected(true);
            });

            // show prefixes depending on the selected owner
            this.prefixes = ko.pureComputed(() => {
                if (this.ownerId()) {
                    const owner = _.find(seed.owners, { id: this.ownerId() });
                    return owner.prefixes;
                }
                return [];
            });

            // load responsibles according to the selected owner
            this.availableResponsibles = ko.observable().extend({
                fetchBackend: () => {
                    if (this.ownerId() && this.ownerId.isValid()) {
                        return UsersService.getResponsibles({ userId: [this.ownerId()] });
                    }
                },
            });

            // get incompatible sanitary status locations for selected cage rack id
            this.getIncompatibleSanitaryStatusLocations = async (newRackId: number) => {
                let incompatibleSanitaryStatus: string[] = [];

                if (this.qs.context()?.pups.length) {
                    incompatibleSanitaryStatus = incompatibleSanitaryStatus.concat(
                        await PupsService
                            .getPupIncompatibleSanitaryStatusLocations({
                                pupIds: this.qs.context()?.pups.map(({ pupid }) => pupid),
                                newRackId: newRackId,
                            })
                            .then((response) => response),
                    );
                }

                if (this.qs.context()?.animals.length) {
                    incompatibleSanitaryStatus = incompatibleSanitaryStatus.concat(
                        await AnimalsService
                            .getAnimalIncompatibleSanitaryStatusLocations({
                                animalIds: this.qs.context()?.animals.map(({ animalid }) => animalid),
                                newRackId: newRackId,
                            })
                            .then((response) => response),
                    );
                }

                return incompatibleSanitaryStatus;
            };

            /* animal assign id */

            this.subactionAnimalAssignId = ko.observable(false);
            this.subactionAnimalAssignId.subscribe(() => {
                this.selected(true);
            });

            this.animalPrefixFromList = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionAnimalAssignId()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.animalPrefix = ko.observable()
                .extend({ normalize: _.trim })
                .extend({
                    invalid: (v) => {
                        if (this.selected() && this.subactionAnimalAssignId()) {
                            return !v || isInvalidEartagPrefix(v);
                        }
                        return false;
                    },
                });

            this.animalPrefix.subscribe(() => {
                this.subactionAnimalAssignId(true);
            });

            this.animalKeepSuffix = ko.observable(true);
            this.animalSuffix = ko.observable()
                .extend({ normalize: _.trim })
                .extend({
                    invalid: (v) => {
                        if (this.selected() && this.subactionAnimalAssignId() && !this.animalKeepSuffix()) {
                            return !v || isInvalidEartagSuffix(v);
                        }
                        return false;
                    },
                });

            // copy over the selected prefix from dropdown to text field
            this.animalPrefixFromList.subscribe(() => {
                this.animalPrefix(this.animalPrefixFromList());
            });

            this.animalNextFreeEartagError = ko.observable();

            // load next free suffix according to selected prefix
            // do not send the ajax call until config.PREFEARTAGSIZE characters are given
            this.animalNextFreeEartag = ko.observable().extend({
                fetchBackend: () => {
                    if (this.animalPrefix() && this.animalPrefix.isValid() && !this.animalKeepSuffix()) {
                        return AnimalsService.getNextFreeEartag({
                            eartagPrefix: this.animalPrefix(),
                        });
                    }
                },
            });
            this.animalNextFreeEartag.onCatch = (e) => {
                // warning (e.g. "There are not enough IDs available for this prefix")
                this.animalNextFreeEartagError(e?.body?.detail);
            };

            // extract next free suffix from next free eartag
            this.animalNextFreeEartag.subscribe((v) => {
                this.animalSuffix("");
                this.animalNextFreeEartagError("");
                if (v) {
                    this.animalSuffix(v.split("-")[1]);
                }
            });

            /* cage assign id */

            this.subactionCageAssignId = ko.observable(false);
            this.subactionCageAssignId.subscribe(() => {
                this.selected(true);
            });

            this.cagePrefixFromList = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionCageAssignId()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.cagePrefix = ko.observable()
                .extend({ normalize: _.trim })
                .extend({
                    invalid: (v) => {
                        if (this.selected() && this.subactionCageAssignId()) {
                            return !v || isInvalidCagePrefix(v);
                        }
                        return false;
                    },
                });
            this.cagePrefix.subscribe(() => {
                this.subactionCageAssignId(true);
            });

            this.cageSuffix = ko.observable()
                .extend({ normalize: _.trim })
                .extend({
                    invalid: (v) => {
                        if (this.selected() && this.subactionCageAssignId()) {
                            return !v || isInvalidCageSuffix(v);
                        }
                        return false;
                    },
                });

            // copy over the selected prefix from dropdown to text field
            this.cagePrefixFromList.subscribe(() => {
                this.cagePrefix(this.cagePrefixFromList());
            });

            // load next free cage suffix according to selected prefix
            // returns an object of all types where type is key and number is value.
            // eg: {'Breeding': '01454', 'Experiment': '00001', 'Stock': '12523'}
            this.cageNextFreeSuffix = ko.observable().extend({
                fetchBackend: () => {
                    if (this.cagePrefix() && this.cagePrefix.isValid()) {
                        return CagesService.getNextFreeCagenumberSuffix({ prefix: this.cagePrefix() });
                    }
                },
            });

            // load next free cage suffix into selectedCageSuffix
            // depending on the cage types of the selected animals
            this.cageNextFreeSuffix.subscribe((v) => {
                const cagetypesFromSelectedAnimals: string[] = [];
                const suffixes = [];
                let minSuffix;

                if (v) {
                    _.forEach(qs.context().animals, (a) => {
                        if (!cagetypesFromSelectedAnimals.includes(a.cagetype)) {
                            cagetypesFromSelectedAnimals.push(a.cagetype);
                        }
                    });
                    _.forEach(qs.context().pups, (p) => {
                        if (!cagetypesFromSelectedAnimals.includes(p.cagetype)) {
                            cagetypesFromSelectedAnimals.push(p.cagetype);
                        }
                    });

                    if (cagetypesFromSelectedAnimals.includes("Breeding")) {
                        suffixes.push(v.Breeding);
                    }
                    if (cagetypesFromSelectedAnimals.includes("Stock")) {
                        suffixes.push(v.Stock);
                    }
                    if (cagetypesFromSelectedAnimals.includes("Experiment")) {
                        suffixes.push(v.Experiment);
                    }
                    // display the lowest suffix in case of different cage types
                    minSuffix = suffixes.sort()[0];
                    this.cageSuffix(minSuffix);
                } else {
                    this.cageSuffix("");
                }
            });

            this.subactionAnimalSetResponsible = ko.observable();
            this.subactionAnimalSetResponsible.subscribe(() => {
                this.selected(true);
            });

            this.animalResponsibleId = ko.observable();
            this.animalResponsibleId.subscribe(() => {
                this.subactionAnimalSetResponsible(true);
            });

            /**
             * Cage set location
             */
            this.subactionCageSetLocation = ko.observable();
            this.subactionCageSetLocation.subscribe(() => {
                this.selected(true);
            });

            this.cageSelectedLocation = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionCageSetLocation()) {
                        if (session.pyratConf.MANDATORY_LOCATION && !v) {
                            return true;
                        }
                        return (v && !v.rack_id) ? getTranslation("Please select a rack") : false;
                    }
                    return false;
                },
            });
            this.cagePreselectLocation = ko.observable();
            this.cageUnselectLocation = ko.observable();
            this.cageRackId = ko.computed(() => {
                return this.cageSelectedLocation() ? this.cageSelectedLocation().rack_id : undefined;
            });
            this.cagePositions = ko.observable();
            this.cageConfirmSanitaryStatus = ko.observable(false)
                .extend({
                    invalid: (value) => {
                        if (value === false
                            && this.selected()
                            && this.subactionCageSetLocation()
                            && this.cageIncompatibleSanitaryStatus().length > 0
                        ) {
                            if (session.userPermissions.animal_set_lower_sanitary_status) {
                                return getTranslation("Incompatible sanitary status. Problematic locations:")
                                    + " " + this.cageIncompatibleSanitaryStatus().join(", ") + ". "
                                    + getTranslation("Move anyway?");
                            } else {
                                return getTranslation("Can't move!") + " "
                                    + getTranslation("Incompatible sanitary status.");
                            }
                        }

                        return false;
                    },
                });

            // show confirm checkbox for existing incompatible sanitary status location(s) and user permission
            this.cageShowConfirmSanitaryStatus = ko.computed(() => {
                return this.cageIncompatibleSanitaryStatus().length > 0
                    && session.userPermissions.animal_set_lower_sanitary_status;
            });

            // check related action checkbox and potential incompatible sanitary status after applying location picker
            this.cageSelectLocationAfterApply = () => {
                this.subactionCageSetLocation(true);
                this.cageConfirmSanitaryStatus(false);
                this.cageIncompatibleSanitaryStatus([]);

                if (this.cageRackId()) {
                    this.getIncompatibleSanitaryStatusLocations(this.cageRackId())
                        .then((value) => this.cageIncompatibleSanitaryStatus(value));
                }
            };

            /**
             * Move to new cage
             */
            this.subactionNewCageMoveAnimals = ko.observable();
            this.subactionNewCageMoveAnimals.subscribe(() => {
                this.selected(true);
            });

            this.newCageSelectedLocation = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionNewCageMoveAnimals()) {
                        if (session.pyratConf.MANDATORY_LOCATION && !v) {
                            return true;
                        }
                        return (v && !v.rack_id) ? getTranslation("Please select a rack") : false;
                    }
                    return false;
                },
            });
            this.newCagePreselectLocation = ko.observable();
            this.newCageUnselectLocation = ko.observable();
            this.newCageRackId = ko.computed(() => {
                return this.newCageSelectedLocation() ? this.newCageSelectedLocation().rack_id : undefined;
            });
            this.newCagePositions = ko.observable();

            this.newCagePrefix = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionNewCageMoveAnimals()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.newCageType = ko.observable();
            this.newCageCategoryId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionNewCageMoveAnimals()) {
                        return !v;
                    }
                    return false;
                },
            });

            this.newCageOpenManyNewCages = ko.observable(false);
            this.newCagePerCage = ko.observable(0);
            this.newCageKeepAnimalsTogether = ko.observable(false);
            this.newCageFillMethod = ko.observable("cage_fillevenly");

            this.newCageOpenManyNewCages.subscribe(() => {
                this.subactionNewCageMoveAnimals(true);
            });

            this.newCagePerCage.extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionNewCageMoveAnimals() &&
                            this.newCageOpenManyNewCages() && !this.newCageKeepAnimalsTogether()) {
                        if (!v || v <= 0) {
                            return true;
                        }
                        if (!(String(v).match(/^\d+$/))) {
                            return getTranslation("Invalid number");
                        }
                    }
                    return false;
                },
            });

            // activate 'open many cages' checkbox when 'animals per cage' is entered
            this.newCagePerCage.subscribe(() => {
                this.newCageOpenManyNewCages(true);
            });

            this.newCageConfirmSanitaryStatus = ko.observable(false)
                .extend({
                    invalid: (value) => {
                        if (value === false
                            && this.selected()
                            && this.subactionNewCageMoveAnimals()
                            && this.newCageIncompatibleSanitaryStatus().length
                        ) {
                            if (session.userPermissions.animal_set_lower_sanitary_status) {
                                return getTranslation("Incompatible sanitary status. Problematic locations:")
                                    + " " + this.newCageIncompatibleSanitaryStatus().join(", ") + ". "
                                    + getTranslation("Move anyway?");
                            } else {
                                return getTranslation("Can't move!") + " "
                                    + getTranslation("Incompatible sanitary status.");
                            }
                        }

                        return false;
                    },
                });

            // show confirm checkbox for existing incompatible sanitary status location(s) and user permission
            this.newCageShowConfirmSanitaryStatus = ko.computed(() => {
                return this.newCageIncompatibleSanitaryStatus().length > 0
                    && session.userPermissions.animal_set_lower_sanitary_status;
            });

            // check related action checkbox and potential incompatible sanitary status after applying location picker
            this.newCageSelectLocationAfterApply = () => {
                this.subactionNewCageMoveAnimals(true);
                this.newCageConfirmSanitaryStatus(false);
                this.newCageIncompatibleSanitaryStatus([]);

                if (this.newCageRackId()) {
                    this.getIncompatibleSanitaryStatusLocations(this.newCageRackId())
                        .then((value) => this.newCageIncompatibleSanitaryStatus(value));
                }
            };

            /* cage set responsible */

            this.subactionCageSetResponsible = ko.observable();
            this.subactionCageSetResponsible.subscribe(() => {
                this.selected(true);
            });

            this.cageResponsibleId = ko.observable();
            this.cageResponsibleId.subscribe(() => {
                this.subactionCageSetResponsible(true);
            });

            // disable 'set cage responsible' if the option 'move to new cage' is available
            // but not activated, because it will set the responsible of the new cage
            this.enableCageSetResponsible = ko.computed(() => {
                return this.subactionNewCageMoveAnimals();
            });

            // clear 'set cage responsible' checkbox whenever it get's deactivated
            this.enableCageSetResponsible.subscribe((v) => {
                if (!v) {
                    this.subactionCageSetResponsible(false);
                }
            });

            /* export strains / projects */

            this.subactionExportStrains = ko.observable(false);
            this.subactionExportStrains.subscribe(() => {
                this.selected(true);
            });

            this.subactionExportProjects = ko.observable(false);
            this.subactionExportProjects.subscribe(() => {
                this.selected(true);
            });

            // values from work request details
            if (seed?.workrequest_details?.behavior_name == "exp_to_scientist") {
                this.selected(true);
                this.ownerId(seed?.workrequest_details?.new_owner_id);

                if (seed.subactions.includes("animal_set_responsible") && seed.workrequest_details?.change_responsible) {
                    this.subactionAnimalSetResponsible(true);
                    this.availableResponsibles.subscribeOnce(() => {
                        setTimeout(() => {
                            this.animalResponsibleId(seed?.workrequest_details?.new_responsible_id);
                        }, 0);
                    });
                }
                if (seed.subactions.includes("cage_set_responsible") && seed.workrequest_details?.change_responsible) {
                    this.subactionCageSetResponsible(true);
                    this.availableResponsibles.subscribeOnce(() => {
                        setTimeout(() => {
                            this.cageResponsibleId(seed?.workrequest_details?.new_responsible_id);
                        }, 0);
                    });
                }
                if (seed.subactions.includes("cage_set_location") && seed.workrequest_details?.new_location) {
                    this.subactionCageSetLocation(true);
                    this.cagePreselectLocation(seed.workrequest_details?.new_location);
                }
                if (seed.subactions.includes("new_cage_move_animals") && seed.workrequest_details?.new_location) {
                    this.subactionNewCageMoveAnimals(true);
                    this.newCagePreselectLocation(seed.workrequest_details?.new_location);
                }
            }

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !(this.ownerId.isInvalid() ||
                         this.animalPrefixFromList.isInvalid() ||
                         this.animalPrefix.isInvalid() ||
                         this.animalSuffix.isInvalid() ||
                         this.cagePrefixFromList.isInvalid() ||
                         this.cagePrefix.isInvalid() ||
                         this.cageSuffix.isInvalid() ||
                         this.cageSelectedLocation.isInvalid() ||
                         this.cageConfirmSanitaryStatus.isInvalid() ||
                         this.newCageSelectedLocation.isInvalid() ||
                         this.newCageConfirmSanitaryStatus.isInvalid() ||
                         this.newCagePrefix.isInvalid() ||
                         this.newCageCategoryId.isInvalid() ||
                         this.newCagePerCage.isInvalid());
            });
        }

        public serialize = () => {
            return {
                owner_id: this.ownerId(),
                comment: this.comment(),
                subactions: {
                    ...(this.subactionAnimalAssignId() && {
                        animal_set_id: {
                            animal_prefix: this.animalPrefix(),
                            keep_animal_suffix: this.animalKeepSuffix(),
                            animal_suffix: this.animalSuffix(),
                        },
                    }),
                    ...(this.subactionAnimalSetResponsible() && {
                        animal_set_responsible: {
                            animal_responsible_id: this.animalResponsibleId(),
                        },
                    }),
                    ...(this.subactionCageSetLocation() && {
                        cage_set_location: {
                            rack_id: this.cageRackId(),
                            cage_positions: this.cagePositions(),
                            confirm_sanitary_status: this.cageConfirmSanitaryStatus(),
                        },
                    }),
                    ...(this.subactionCageAssignId() && {
                        cage_set_id: {
                            cage_prefix: this.cagePrefix(),
                            cage_suffix: this.cageSuffix(),
                        },
                    }),
                    ...(this.subactionNewCageMoveAnimals() && {
                        new_cage_move_animals: {
                            rack_id: this.newCageRackId(),
                            cage_prefix: this.newCagePrefix(),
                            cage_type: this.newCageType(),
                            cage_category_id: this.newCageCategoryId(),
                            cage_positions: this.newCagePositions(),
                            open_many_new_cages: this.newCageOpenManyNewCages(),
                            animals_per_cage: this.newCagePerCage(),
                            keep_animals_together: this.newCageKeepAnimalsTogether(),
                            cage_fillmethod: this.newCageFillMethod(),
                            confirm_sanitary_status: this.newCageConfirmSanitaryStatus(),
                        },
                    }),
                    ...(this.subactionCageSetResponsible() && {
                        cage_set_responsible: {
                            cage_responsible_id: this.cageResponsibleId(),
                        },
                    }),
                    ...(this.subactionExportStrains() && {
                        export_strains: {},
                    }),
                    ...(this.subactionExportProjects() && {
                        export_projects: {},
                    }),
                },
            };
        };

    }),

    export_to_institution_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public institutionId: CheckExtended<Observable<number>>;
        public comment: Observable<string>;

        constructor() {
            super(qs);

            this.institutionId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.institutionId.subscribe(() => {
                this.selected(true);
            });

            this.comment = ko.observable();
            this.comment.subscribe(() => {
                this.selected(true);
            });

            // values from work request details
            if (seed?.workrequest_details?.behavior_name === "exp_to_facility") {
                this.selected(true);
                this.institutionId(seed?.workrequest_details?.facility_id || undefined);
                this.comment(seed?.workrequest_details?.export_comment || undefined);
            }

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }


                return !this.institutionId.isInvalid();
            });
        }

        public serialize = () => {
            return {
                institution_id: this.institutionId(),
                comment: this.comment(),
            };
        };

    }),

    set_license_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public strainIds: Computed<number[]>;
        public speciesIds: Computed<number[]>;
        public noSpeciesSelected: Computed<boolean>;
        public multipleSpeciesSelected: Computed<boolean>;
        public commonSpeciesId: Computed<number>;
        public licenseId: CheckExtended<Observable<number>>;
        public classificationId: CheckExtended<Observable<number>>;

        public availableLicenses: FetchBackendExtended<ObservableArray<LicenseOption>>;
        public availableClassifications: FetchBackendExtended<ObservableArray<LicenseClassificationOption|LicenseClassificationOptionDelimiter>>;

        public licenseAssignDate: CheckExtended<Observable<string>>;
        public licenseOveruseSelection: Computed<LicenseClassificationOption>;
        public licenseOveruseSelectionConfirm: CheckExtended<Observable<boolean>>;

        constructor() {
            super(qs);

            this.strainIds = ko.computed(() => {
                return _.chain(
                    _.concat((qs.context().animals).map((animalData) => {
                        return animalData.strain_id || 0;
                    }), (qs.context().pups).map((pupData) => {
                        return pupData.strain_id || 0;
                    }))).uniq().value();
            });

            this.speciesIds = ko.computed(() => {
                return _.chain(
                    _.concat((qs.context().animals).map((animalData) => {
                        return animalData.species_id;
                    }), (qs.context().pups).map((pupData) => {
                        return pupData.species_id;
                    }))).uniq().value();
            });

            this.noSpeciesSelected = ko.computed(() => {
                return !!(this.speciesIds().length === 1 && !this.speciesIds()[0]);
            });

            this.multipleSpeciesSelected = ko.computed(() => {
                return (this.speciesIds().length > 1);
            });

            this.commonSpeciesId = ko.computed(() => {
                if (this.speciesIds().length === 1 && this.speciesIds()[0]) {
                    return this.speciesIds()[0];
                }
            });

            this.licenseId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v && getTranslation("Please select a license");
                    }
                    return false;
                },
            });
            this.availableLicenses = ko.observableArray().extend({
                fetchBackend: () => {
                    if (this.commonSpeciesId()) {
                        return LicensesService.getLicenseOptions({
                            speciesId: this.commonSpeciesId(),
                            strainId: this.strainIds(),
                        });
                    }
                },
            });

            this.classificationId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v && getTranslation("Please select a classification");
                    }
                    return false;
                },
            });
            this.availableClassifications = ko.observableArray().extend({
                fetchBackend: () => {
                    if (this.commonSpeciesId() && this.licenseId()) {
                        return LicensesService.getLicenseClassificationOptions({
                            licenseId: this.licenseId(),
                            speciesId: this.commonSpeciesId(),
                            strainId: this.strainIds(),
                        });
                    }
                },
            });

            this.licenseAssignDate = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        if (v && isInvalidCalendarDate(v)) {
                            return getTranslation("Invalid date");
                        }
                    }
                    return false;
                },
            });

            // return potential selection of overused license classification
            // (=> number of selected animals & pups is bigger than related available slots)
            this.licenseOveruseSelection = ko.computed(() => {
                return this.availableClassifications()
                    .find((item) => {
                        const option = item as LicenseClassificationOption;
                        return option.id === this.classificationId()
                            && (option.animal_number - option.used - qs.context().animals.length - qs.context().pups.length) < 0;
                    }) as LicenseClassificationOption;
            });

            this.licenseOveruseSelectionConfirm = ko.observable(false)
                .extend({
                    invalid: (value) => {
                        if (value === false
                            && this.selected()
                            && this.licenseOveruseSelection()
                        ) {
                            const animalCount = qs.context().animals.length + qs.context().pups.length;
                            const availableCount = this.licenseOveruseSelection().animal_number - this.licenseOveruseSelection().used;

                            if (availableCount < 0) {
                                return getTranslation("You try to assign %s license classifications but it's already overused by %s")
                                    .replace("%s", String(animalCount))
                                    .replace("%s", String(Math.abs(availableCount)));
                            } else {
                                return getTranslation("You try to assign %s license classifications but there are just %s left")
                                    .replace("%s", String(animalCount))
                                    .replace("%s", String(availableCount));
                            }
                        }

                        return false;
                    },
                });

            // values from work request details
            if (seed?.workrequest_details?.behavior_name === "exp_to_scientist" || seed?.workrequest_details?.behavior_name === "exp_to_facility") {
                if (seed?.workrequest_details?.license_id || seed?.workrequest_details?.classification_id) {
                    this.selected(true);

                    this.availableLicenses.subscribeOnce(() => {
                        setTimeout(() => {
                            this.licenseId(seed?.workrequest_details?.license_id);
                        }, 0);
                    });
                    this.availableClassifications.subscribeOnce(() => {
                        setTimeout(() => {
                            this.classificationId(seed?.workrequest_details?.classification_id);
                        }, 0);
                    });
                }
            }

            this.enabled = ko.pureComputed(() => {
                const allLiveAnimals = qs.context().animals
                    .map((data) => { return data.state; })
                    .concat(qs.context().pups.map((data) => { return data.state; }))
                    .every((state) => state == "live");

                return !!this.commonSpeciesId()
                    && (allLiveAnimals || session.userPermissions.animal_not_live_set_license);
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.licenseAssignDate.errorMessage()) {
                    this.errors.push(this.licenseAssignDate.errorMessage());
                }

                if (this.licenseOveruseSelectionConfirm.errorMessage()) {
                    this.errors.push(this.licenseOveruseSelectionConfirm.errorMessage());
                }

                return !(this.licenseId.isInvalid() ||
                         this.classificationId.isInvalid() ||
                         this.licenseAssignDate.isInvalid() ||
                         this.licenseOveruseSelectionConfirm.isInvalid());
            });
        }

        public serialize = () => {
            return {
                classification_id: this.classificationId(),
                assign_date: this.licenseAssignDate() || undefined,
                confirmed_overuse: this.licenseOveruseSelectionConfirm(),
            };
        };
    }),

    perform_workrequest_action: (qs, seed) => new class extends Action {

        public workrequestId = seed.workrequest_handling.workrequest_id;
        public requireConclusion = false;

        public closeWorkrequest: Observable<boolean>;

        public serialize = () => ({
            workrequest_id: seed.workrequest_handling.workrequest_id,
            close_workrequest: seed.workrequest_handling.close_workrequest,
        });

        constructor() {
            super(qs);
            this.selected(!!seed.workrequest_handling.workrequest_id);
        }

    },
};


class AnimalExportQuickselectViewModel {

    public reloadRequired: Observable<boolean>;
    public seed: FetchExtended<Observable<AjaxResponse<{ context: Seed.Context; content: Seed.Content }>>>;
    public context: PureComputed<Seed.Context | undefined>;
    public conclusion: ObservableArray<{ text: string; click: () => void }>;
    public actions: PureComputed<{ [key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]> }>;
    public applyInProgress: Observable<boolean>;
    public errors: ObservableArray<string>;
    private readonly params: Params;
    private readonly dialog: KnockoutPopup;
    private scenery: PureComputed<{ loading: boolean } |
        { conclusion: AnimalExportQuickselectViewModel } |
        { context: AnimalExportQuickselectViewModel; actions: AnimalExportQuickselectViewModel } |
        { error: boolean }>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.params = params;
        this.dialog = dialog;
        this.reloadRequired = ko.observable(false);

        this.dialog.addOnClose(() => {
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        // get initial data
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(cgiScript("quickselect_export.py"), {
                    method: "POST",
                    body: getFormData({
                        action: "get_options",
                        data: JSON.stringify({
                            actions: this.params.actions,
                            context: {
                                animal_ids: this.params.animalIds || [],
                                pup_ids: this.params.pupIds || [],
                            },
                            workrequest_id: this.params.workrequestId,
                            close_workrequest: this.params.closeWorkrequest,
                        }),
                    }), signal,
                });
            },
        });

        this.context = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed?.success) {
                return seed?.context;
            } else {
                return undefined;
            }
        });

        // initialize the actions with their seeded data
        this.actions = ko.pureComputed(() => {
            const actions: {[key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]>} = {};
            const seed = this.seed();
            if (seed?.success && seed?.content) {
                Object.keys(seed.content).forEach((actionName: keyof Seed.Content) => {
                    if (actionName in actionModels) {
                        // @ts-expect-error: TODO: Typing is too complicated here. I did not get it right.
                        // Waiting for "inherit" from implementation in TypeScript.
                        actions[actionName] = actionModels[actionName](this, seed.content[actionName]);
                    }
                });
            }
            return actions;
        });


        // result handling

        this.conclusion = ko.observableArray([]);
        this.errors = ko.observableArray([]);
        this.applyInProgress = ko.observable(false);

        // routing
        this.scenery = ko.pureComputed(() => {

            if (this.seed.inProgress()) {
                return { loading: true };
            }

            if (this.conclusion().length) {
                return { conclusion: this };
            }

            if (this.seed() && this.seed().success) {
                return { context: this, actions: this };
            }

            return { error: true };

        });

    }

    public showAnimalDetails = (animalId: number) => {
        frames.detailPopup.open(
            getUrl(cgiScript("mousedetail.py"), { animalid: animalId }, { absoluteUrl: true }),
        );
    };

    public showPupDetails = (pupId: number) => {
        frames.detailPopup.open(
            getUrl(cgiScript("pupdetail.py"), { animalid: pupId }, { absoluteUrl: true }),
        );
    };

    public close = () => {
        this.dialog.close();
    };

    public canApply = () => {

        if (this.applyInProgress()) {
            return false;
        } else if (this.context().animals.length < 1 && this.context().pups.length < 1) {
            return false;
        } else if (!_.some(_.invokeMap(this.actions(), "selected"))) {
            // check if any action is selected
            return false;
        }

        // check if all selected actions are valid
        return _.every(this.actions(), (val) => {
            return val.selected() && val.valid ? Boolean(val.valid()) : true;
        });

    };

    public applyQuickselect = () => {

        this.errors.removeAll();

        const data = {
            context: { animal_ids: _.map(this.context().animals, "animalid"), pup_ids: _.map(this.context().pups, "pupid") },
            content: _
                .chain(this.actions())
                .pickBy((a) => {
                    return a.selected();
                })
                .mapValues((a) => {
                    return a.serialize();
                })
                .value(),
        };

        this.applyInProgress(true);
        this.reloadRequired(true);
        _.forEach(this.actions(), (action) => {
            action.errors.removeAll();
        });

        fetch(cgiScript("quickselect_export.py"), {
            method: "POST",
            body: getFormData({
                action: "post_data",
                data: JSON.stringify(data),
            },
            ) })
            .then((response) => response.json())
            .then((response: Response) => {

                if (response.success) {

                    this.conclude(response);

                    const autoClose = !_.some(response?.content, (action_data, action: keyof Response["content"]) => {
                        return this.actions()[action] && this.actions()[action].requireConclusion;
                    });

                    if (autoClose) {
                        // no action requires a conclusion, so we immediately close it
                        this.close();
                    }

                } else {
                    // client error
                    _.forEach(response.content, (messages, name: keyof Response["content"]) => {
                        // put error messages to the related actions,
                        // "export_to_scientist" errors look confusing below the action (too many subactions)
                        // hence put them to general error section
                        if (this.actions()[name] && this.actions()[name].errors && name !== "export_to_scientist_action") {
                            _.forEach(messages, (m) => {
                                this.actions()[name].errors.push(m);
                            });
                        } else {
                            _.forEach(messages, (m) => {
                                this.errors.push(m);
                            });
                        }
                    });
                }
            })
            .catch(() => {
                this.errors.push(getTranslation("General quickselect error."));
            })
            .finally(() => this.applyInProgress(false));

    };

    private conclude = (value: Response) => {

        // clear old results
        this.conclusion.removeAll();

        const notUpdatedPupCageIds = value?.content?.export_to_scientist_action?.not_updated_pup_cage_ids;

        if (notUpdatedPupCageIds?.length) {
            this.conclusion.push({
                text:
                    getTranslation("Attention: Active litters with several possible mothers exist in cages") + "." +
                    getTranslation("Ownership of pups not automatically changed. Please use the pup list to change the ownership of these pups.") +
                    " " + _.template(getTranslation("Show cages (<%- count %>)."))({ count: notUpdatedPupCageIds.length }),
                click: () => {
                    this.reloadRequired(false);
                    mainMenu.openAndResetListFilter("get_cage_list", { cageid: notUpdatedPupCageIds });
                },
            });
        }

        if (!this.conclusion().length) {
            // no conclusion was needed, so we close it
            this.close();
        }
    };
}


// dialog starter
export const showAnimalExportQuickselect = dialogStarter(AnimalExportQuickselectViewModel, template, {
    name: "AnimalExportQuickselect",
    width: 600,
    anchor: {
        top: 5,
        right: 5,
    },
    closeOthers: true,
    title: getTranslation("Quick Select - Export animals"),
});
