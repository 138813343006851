import {
    Observable,
    PureComputed,
} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import { showBreedingPerformanceDetails } from "../../dialogs";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListFilterMutationsItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    frames,
    notifications,
} from "../../lib/pyratTop";
import {
    baseUrl,
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    sendPostForm,
    printUrl,
} from "../../lib/utils";
import { pyratFrontend } from "../../pyratFrontend";

import filterTemplate from "./breedingPerformanceReportListFilter.html";



interface Arguments extends ListViewArguments {
    export_args: any;
    filter_only: boolean;
    breeding_performance_summary_data: any;
}


const BreedingPerformanceReportListFilters = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {

        birth_date_from: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("birth_date_to"), compareFromDate,
                        false, true),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        birth_date_to: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("birth_date_from"), compareToDate,
                        false, true),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        eartag: ListFilterItem,

        cage_number: ListFilterItem,

        labid: ListFilterItem,

        sex: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        species_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        responsible_id: class extends ListFilterItem {
            private possibleValues: PureComputed<{ fullname: string; userid: number }[]>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValueArguments = ko.pureComputed(() => {
                    if (filter.allFilters().owner_id) {
                        return {
                            owner_ids: filter.allFilters().owner_id.model.serialize(),
                        };
                    }
                });

                this.possibleValues = ko.pureComputed(() => {
                    return _.union([{ userid: 0, fullname: getTranslation("None") }], seed.possible_values());
                });

                this.text = ko.pureComputed(() => {
                    return _.map(seed.possible_values(), "fullname") as string[];
                });
            }
        },

        strain_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name_with_id");
            }
        },

        mutation_grade: class extends ListFilterMutationsItem {
            strainNameOrIdFilter = ko.pureComputed(() => {
                return filter.allFilters().strain_id;
            });
        },

        wean_location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.valid = () => {
                    return this.initialized() === true || this.serialize() === seed.default_value;
                };
            }
        },

        state: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = _.map(seed.possible_values, "name");
            }
        },

        page_size: ListFilterItem,
    };
};


class BreedingPerformanceReportList {
    private listView: ListView;
    private args: Arguments;
    private readonly birthDateFrom: string;
    private readonly birthDateTo: string;

    public readonly breedingPerformanceSummaryData: any;
    public readonly breedingPerformanceGraph: PureComputed<any>;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;
        this.birthDateFrom = args.export_args.birth_date_from;
        this.birthDateTo = args.export_args.birth_date_to;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
            { useColumnSelector: !args.filter_only },
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            this.showFilter(args);
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        if (!args.filter_only) {
            this.listView.onMenuBoxClick("export-details-to-excel", () => {
                const selectedRowIds = this.listView.getSelectedRowIds();
                if (!selectedRowIds.length) {
                    notifications.showModal(getTranslation("No rows selected"));
                    return;
                }
                sendPostForm(
                    baseUrl("frontend/reports/breeding_performance_details_csv"),
                    {
                        animal_id: selectedRowIds,
                        birth_date_from: this.birthDateFrom || "",
                        birth_date_to: this.birthDateTo || "",
                    },
                    { target: "_blank" },
                );
            });

            this.listView.onMenuBoxClick("export-list-to-excel", () => {
                showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
            });

            this.listView.onMenuBoxClick("print-button", () => {
                printUrl(getUrl(window.location.href, { show_print: "true" }));
            });
        }

        // Report summary (Graph)

        this.breedingPerformanceSummaryData = args.breeding_performance_summary_data;
        this.breedingPerformanceGraph = ko.pureComputed(() => {
            const labels = [];
            const values = [];

            if (this.breedingPerformanceSummaryData) {
                labels.push(getTranslation("weaned"));
                values.push(this.breedingPerformanceSummaryData.weaned_total_count);

                labels.push(getTranslation("unexpected"));
                values.push(this.breedingPerformanceSummaryData.mortality_unexpected_count);

                labels.push(getTranslation("reduced"));
                values.push(this.breedingPerformanceSummaryData.mortality_reduced_count);

                labels.push(getTranslation("other sacrifice reasons"));
                values.push(this.breedingPerformanceSummaryData.mortality_other_count);

                labels.push(getTranslation("unweaned"));
                values.push(this.breedingPerformanceSummaryData.unweaned_total_count);
            }

            return {
                type: "doughnut",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: pyratFrontend.charts.colorSchemes.mixed,
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend: {
                            display: true,
                            align: "start",
                            position: "bottom",
                            rtl: document.querySelector("html").getAttribute("dir") === "rtl",
                            labels: {
                                boxWidth: 20,
                                filter: (legendItem: any, chartData: any) => chartData.datasets[0].data[legendItem.index],
                            },
                        },
                    },
                    maintainAspectRatio: false,
                },
            };
        });

        // Table Body

        const noDataText = listViewElement.querySelector(".list-no-data");
        if (noDataText) {
            noDataText.classList.add("clickable");
            noDataText.addEventListener("click", () => {
                this.showFilter(args);
            });
        }

        this.listView.onCellClick("td.eartag a", (args) => {
            this.listView.highlightRow(args.rowId);
            showBreedingPerformanceDetails({
                animalId: parseInt(args.rowId, 10),
                birthDateFrom: this.birthDateFrom,
                birthDateTo: this.birthDateTo,
                closeCallback: () => { this.listView.unHighlightRow(args.rowId); },
            });
        });

        this.listView.onCellClick("td.cage_number a", (args) => {
            this.listView.highlightRow(args.rowId);
            frames.openListDetailPopup(
                getUrl(cgiScript("cagedetail.py"), {
                    cageid: parseInt(args.element.getAttribute("cageid"), 10),
                }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });

        this.listView.onCellClick("td.actions input.export_to_csv", (args) => {
            sendPostForm(
                baseUrl("frontend/reports/breeding_performance_details_csv"),
                {
                    animal_id: args.rowId,
                    birth_date_from: this.birthDateFrom || "",
                    birth_date_to: this.birthDateTo || "",
                },
                { target: "_blank" },
            );
        });
    }

    public showFilter = (args: Arguments) => {
        showListFilter({
            viewName: args.view_name,
            filterModels: BreedingPerformanceReportListFilters,
            filterTemplate: filterTemplate,
            title: getTranslation("Breeding performance filter"),
        });
    };
}

export const initBreedingPerformanceReportList = (args: Arguments): void => {
    const container: HTMLDivElement = document.querySelector("div.listview");
    const breedingPerformanceReportList = new BreedingPerformanceReportList(container, args);
    ko.applyBindings(breedingPerformanceReportList, container);
};
