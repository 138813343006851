/**
 * Show the sperm QuickSelect pop-up.
 *
 * @param spermIds
 *        Database IDs of the sperm volumes on which the actions will be applied.
 *
 * @param reloadCallback
 *        Function to call when data has been applied and popup is closed
 *        (e.g. to reload a list to display new data).
 *
 */

import * as ko from "knockout";

import {
    AddCommentSchema,
    BlindCommentWidgetSeed,
    IdNameProperty,
    OocyteDonor,
    Owner,
    ProjectForSetting,
    QualityProperties,
    SpermQuickselectActionsResponse,
    SpermQuickselectContextRow,
    SpermService,
    StrainOption,
} from "../backend/v1";
import { showEmbryoQuickselect } from "../dialogs";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { showColumnSelect } from "../lib/listView";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    checkDecimal,
    isInvalidCalendarDate,
    normalizeDate,
} from "../lib/utils";

import template from "./spermQuickselect.html";
import "/scss/quick_select.scss";

interface Params {
    spermIds: Array<number>;
    reloadCallback?: () => void;
}

interface ContextRow extends SpermQuickselectContextRow {
    selected: ko.Observable<boolean>;
}

interface StrawLabel {
    label: ko.Observable<string>;
    origin: ko.Observable<"generated" | "progress" | "custom">;
    position: number;
}

interface CryopreserveAssignment {
    sperm: ContextRow;
    strawCount: CheckExtended<ko.Observable<number>>;  // how many straws
    spermVolumePerStraw: CheckExtended<ko.Observable<number>>;  // volume of sperm per straw
    strawVolumeInput: ko.Observable<string>;  // sperm volumes of each straw as space separated "list"
    strawVolumes: CheckExtended<ko.PureComputed<Array<number>>>;  // content of `strawVolumeInput` as list of numbers
    fillStrawVolumes: () => void;
}

interface RevitalizeAssignment {
    sperm: ContextRow;
    oocyteCount: CheckExtended<ko.Observable<number>>;
    spermVolume: CheckExtended<ko.Observable<number>>;
    isUsed: ko.PureComputed<boolean>;
}

abstract class QuickselectAction {

    private qs: SpermQuickselectViewModel;
    private actionKey: ko.PureComputed<string>;
    public selected: ko.Subscribable<boolean>;
    public enabled: ko.Subscribable<boolean>;
    public possibleStateIds: Array<number>;
    public possible: ko.Subscribable<boolean>;
    public valid: ko.Subscribable<boolean>;
    public errors: ko.ObservableArray<string>;
    public applicable: ko.PureComputed<boolean>;

    public requireConclusion = true;

    protected constructor(qs: SpermQuickselectViewModel) {
        this.qs = qs;
        this.actionKey = ko.pureComputed(() => {
            return Object.keys(this.qs.actions() || {}).find((actionKey) => {
                return this.qs.actions()[actionKey] === this;
            });
        });

        this.selected = ko.observable(false);
        this.enabled = ko.observable(true);
        this.possibleStateIds = undefined;  // `undefined` means the action is allowed for all sperm states
        this.possible = ko.pureComputed(() => {
            if (!this.possibleStateIds) {
                return true;
            }

            return qs.selectedContext().every((spermRow) => {
                return this.possibleStateIds.includes(spermRow.state_id);
            });
        });
        this.valid = ko.observable(true);
        this.errors = ko.observableArray();

        this.applicable = ko.pureComputed(() => this.enabled() && this.possible() && this.selected());
    }

    public serialize = () => ({});
}

const actionModels: { [actionKey: string]: (qs: SpermQuickselectViewModel, seed: { [key: string]: any }) => QuickselectAction } = {
    /**
     *                                  enabled                         possible_states
     * discard_action                   true                            incubated, cryopreserved
     * adjust_volume_action             selectedContext.length === 1    <all>
     * define_quality_action            true                            <all>
     * add_comment_action               true                            <all>
     * add_document_action              true                            <all>
     * set_project_action               true                            <all>
     * set_strain_action                true                            incubated
     * cryopreserve_assemble_action     true                            incubated
     * cryopreserve_move_action         true                            cryopreserved
     * cryopreserve_set_date_action     true                            cryopreserved
     * cryopreserve_thaw_action         true                            cryopreserved
     * revitalize_action                true                            incubated
     * export_to_scientist_action       true                            incubated, cryopreserved
     * export_to_institution_action     true                            incubated, cryopreserved
     * export_to_xls_action             true                            <all>
     *
     */

    discard_action: (qs, seed) => new (class extends QuickselectAction {
        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_incubation_state_id,
                seed.sperm_cryopreservation_state_id,
            ];
        }
    }),

    adjust_volume_action: (qs) => new (class extends QuickselectAction {
        private volume: CheckExtended<ko.Observable<number>>;

        constructor() {
            super(qs);

            this.volume = ko.observable().extend({
                invalid: (v) => {
                    return !(v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0));
                },
            });
            this.volume.subscribe(() => {
                this.selected(true);
            });

            qs.selectedContext.subscribe((selectedContext) => {
                let actionSelected;

                // enable only for one sperm at once
                if (selectedContext.length === 1) {
                    actionSelected = this.selected();
                    this.volume(selectedContext[0]?.volume);
                    this.selected(actionSelected);  // set to previous value again
                    this.enabled(true);
                } else {
                    this.volume(undefined);
                    this.selected(false);
                    this.enabled(false);
                }
            });
            qs.selectedContext.notifySubscribers(qs.selectedContext());

            this.valid = ko.pureComputed(() => this.volume.isValid());
        }

        public serialize = () => ({ volume: this.volume() });
    }),

    define_quality_action: (qs, seed) => new (class extends QuickselectAction {
        private rating: ko.Observable<"very_poor" | "poor" | "fair" | "good" | "very_good">;
        private progressivePortion: CheckExtended<ko.Observable<number>>;
        private spermQualityRatings: Array<QualityProperties>;

        constructor() {
            super(qs);

            this.rating = ko.observable();
            this.progressivePortion = ko.observable().extend({
                invalid: (v) => {
                    return !(v === undefined ||
                             v >= 0 && v <= 100 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0));
                },
            });

            this.rating.subscribe(() => {
                this.selected(true);
            });
            this.progressivePortion.subscribe(() => {
                this.selected(true);
            });

            this.spermQualityRatings = seed.possible_sperm_qualities;

            this.valid = ko.pureComputed(() => this.progressivePortion.isValid());
        }

        public serialize = () => ({
            quality_rating: this.rating(),
            quality_progressive_portion: this.progressivePortion(),
        });
    }),

    add_comment_action: (qs, seed) => new (class extends QuickselectAction {
        private comment: ko.Observable<AddCommentSchema>;
        private commentWidgetSeed: BlindCommentWidgetSeed;

        constructor() {
            super(qs);

            this.comment = ko.observable();
            this.comment.subscribe(() => {
                this.selected(true);
            });

            this.commentWidgetSeed = seed.comment_widget_data;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (!this.comment()?.comment?.length && !this.comment()?.attributes?.length) {
                    if (this.applicable()) {
                        this.errors.push(getTranslation("The comment field is empty"));
                    }

                    return false;
                }

                return true;
            });

        }

        public serialize = () => this.comment();
    }),

    add_document_action: (qs) => new (class extends QuickselectAction {
        private newDocumentIds: ko.ObservableArray<number | { message: string }>;
        private pendingAction: ko.Observable<boolean>;

        constructor() {
            super(qs);

            this.newDocumentIds = ko.observableArray();
            this.newDocumentIds.subscribe((newValue) => {
                this.selected(Boolean(newValue.length));
            });

            this.pendingAction = ko.observable(false);

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.pendingAction() || !this.newDocumentIds().length) {
                    return false;
                }

                return !this.newDocumentIds().some((documentId) => {
                    if (typeof documentId === "object") {
                        if (this.applicable()) {
                            this.errors.push(documentId.message);
                        }

                        return true;
                    }

                    return false;
                });
            });
        }

        public serialize = () => ({ document_ids: this.newDocumentIds() });
    }),

    set_project_action: (qs, seed) => new (class extends QuickselectAction {
        private projectId: CheckExtended<ko.Observable<number>>;
        private possibleProjects: Array<ProjectForSetting>;

        constructor() {
            super(qs);

            this.projectId = ko.observable().extend({
                invalid: (v) => {
                    if (!v && session.pyratConf.TRANSGENIC_MANDATORY_PROJECT) {
                        return getTranslation("Please select a project");
                    }

                    return false;
                },
            });
            this.projectId.subscribe(() => {
                this.selected(true);
            });

            this.possibleProjects = seed.active_projects;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.projectId.isInvalid()) {
                    if (this.applicable() && this.projectId.errorMessage()) {
                        this.errors.push(this.projectId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ project_id: this.projectId() });
    }),

    set_strain_action: (qs, seed) => new (class extends QuickselectAction {
        private strainId: CheckExtended<ko.Observable<number>>;
        private possibleStrains: Array<StrainOption>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_incubation_state_id,
            ];

            this.strainId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select a Line / Strain");
                    }

                    return false;
                },
            });
            this.strainId.subscribe(() => {
                this.selected(true);
            });

            this.possibleStrains = seed.active_strains;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.strainId.isInvalid()) {
                    if (this.applicable() && this.strainId.errorMessage()) {
                        this.errors.push(this.strainId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ strain_id: this.strainId() });
    }),

    cryopreserve_assemble_action: (qs, seed) => new (class extends QuickselectAction {
        private combinations: ko.PureComputed<Array<CryopreserveAssignment>>;
        private selectedCombinations: ko.PureComputed<Array<CryopreserveAssignment>>;
        private strawLabels: CheckExtended<ko.ObservableArray<StrawLabel>>;  // labels for each straw, `strawLabels.length` === `strawCount`
        private strawVolumes: CheckExtended<ko.PureComputed<Array<number>>>;  // content of all `strawVolumes` of all combinations

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_incubation_state_id,
            ];

            this.combinations = ko.pureComputed(() => {
                return qs.context().map((spermRow) => {
                    const strawCount = ko.observable();
                    const spermVolumePerStraw = ko.observable();
                    const strawVolumeInput: ko.Observable<string> = ko.observable().extend({
                        rateLimit: {
                            timeout: 500,
                            method: "notifyWhenChangesStop",
                        },
                    });

                    strawCount.extend({
                        invalid: (v) => {
                            return !((v === undefined ||
                                      v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0)) &&
                                     (!spermVolumePerStraw() || v > 0));
                        },
                    });

                    spermVolumePerStraw.extend({
                        invalid: (v) => {
                            return !((v === undefined ||
                                      v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0)) &&
                                     (!strawCount() || v > 0));
                        },
                    });

                    strawVolumeInput.subscribe(() => {
                        this.selected(true);
                    });

                    return {
                        sperm: spermRow,
                        strawCount: strawCount,
                        spermVolumePerStraw: spermVolumePerStraw,
                        strawVolumeInput: strawVolumeInput,
                        strawVolumes: ko.pureComputed(() => {
                            return (strawVolumeInput() || "").split(/[-/\\_;., ]/).filter((volume) => volume).map((volume) => parseInt(volume, 10));
                        }).extend({
                            invalid: (v) => {
                                return !(v.length &&
                                         v.every((volume) => volume > 0) &&
                                         v.reduce((sum, volume) => sum + volume, 0) <= spermRow.volume);
                            },
                        }),
                        fillStrawVolumes: () => {
                            const volumes = [];
                            let i;

                            for (i = 0; i < strawCount(); i++) {
                                volumes.push(spermVolumePerStraw());
                            }

                            strawVolumeInput(volumes.join(" "));
                        },
                    };
                });
            });

            this.selectedCombinations = ko.pureComputed(() => {
                return this.combinations().filter((assignment) => assignment.sperm.selected());
            });

            this.strawLabels =  ko.observableArray().extend({
                invalid: (v) => {
                    const duplicateStrawLabels = v.map((label) => {
                        return String(label.label()).trim();
                    }).filter((label, index, strawLabels) => {
                        return label.length && strawLabels.indexOf(label) !== index;
                    });

                    if (v?.some((label) => label.origin() === "progress")) {
                        return getTranslation("Please wait, ... straw labels still loading");
                    }

                    if (duplicateStrawLabels.length) {
                        return getTranslation("Duplicate straw labels") + ": " + duplicateStrawLabels.join(", ");
                    }

                    return false;
                },
            });

            this.strawVolumes = ko.pureComputed(() => {
                return this.selectedCombinations().reduce((memo, assignment) => {
                    memo.push(...assignment.strawVolumes());
                    return memo;
                }, []);
            }).extend({
                invalid: (v) => {
                    return !(v.length &&
                             v.every((volume) => volume > 0) &&
                             v.reduce((sum, volume) => sum + volume, 0) <= qs.selectedVolume());
                },
            });

            this.strawVolumes.subscribe((volumes) => {
                const labels = this.strawLabels();
                const customLabels = labels.filter((label) => label.origin() === "custom");
                let i;

                for (i = labels.length; i < volumes.length; i++) {
                    labels.push({
                        label: ko.observable("..."),
                        origin: ko.observable("progress"),
                        position: i,
                    });
                }
                for (i = labels.length; i > volumes.length; i--) {
                    labels[i - 1] = {
                        label: ko.observable("..."),
                        origin: ko.observable("progress"),
                        position: i,
                    };
                }
                this.strawLabels(labels);

                SpermService.getNextFreeSpermStrawLabels({ count: volumes.length }).then((response) => {
                    const generatedLabels: Array<StrawLabel> = response.map((label: string, i: number) => {
                        return {
                            label: ko.observable(String(label)),
                            origin: ko.observable("generated"),
                            position: i,
                        };
                    });

                    // combine generated labels and custom labels
                    let combinedLabels: Array<StrawLabel> = generatedLabels.concat(customLabels.filter((label) => generatedLabels.indexOf(label) === -1));
                    // the custom labels overwrite the generated labels on duplicate positions
                    combinedLabels = Object.values(combinedLabels.reduce((memo, label) => {
                        return { ...memo, [label.position]: label };
                    }, {}));
                    // sort by position
                    this.strawLabels(combinedLabels.sort((a, b) => a.position - b.position));
                }).catch(qs.handleErrorResponse);
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.strawVolumes.isInvalid()) {
                    return false;
                }

                if (this.strawLabels.isInvalid()) {
                    if (this.applicable() && this.strawLabels.errorMessage()) {
                        this.errors.push(this.strawLabels.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        private toggleLabel = (label: string) => {
            const toggledStrawLabels = this.strawLabels().map((strawLabel) => {
                if (strawLabel.label() === label) {
                    strawLabel.origin("custom");
                }
                return strawLabel;
            });

            this.strawLabels.notifySubscribers(toggledStrawLabels);
        };

        public serialize = () => ({
            sperm_straw_volumes: this.selectedCombinations().map((assignment) => ({
                sperm_id: assignment.sperm.sperm_id,
                straw_volumes: assignment.strawVolumes(),
            })),
            straw_labels: this.strawLabels().map((label) => label.label()),
        });
    }),

    cryopreserve_move_action: (qs, seed) => new (class extends QuickselectAction {
        private cryotankId: CheckExtended<ko.Observable<number>>;
        private possibleCryotanks: Array<IdNameProperty>;
        private cryotankPath: CheckExtended<ko.ObservableArray<string>>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_cryopreservation_state_id,
            ];

            this.cryotankId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select a cryotank");
                    }

                    return false;
                },
            });
            this.possibleCryotanks = seed.cryotanks;

            this.cryotankPath = ko.observableArray().extend({
                invalid: (v) => {
                    if (this.cryotankId() && !v.length) {
                        return getTranslation("No position in the cryotank selected");
                    }

                    return false;
                },
            });

            this.cryotankId.subscribe(() => {
                this.selected(true);
                this.cryotankPath([]);
            });
            this.cryotankPath.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.cryotankId.isInvalid()) {
                    if (this.applicable() && this.cryotankId.errorMessage()) {
                        this.errors.push(this.cryotankId.errorMessage());
                    }

                    return false;
                }

                if (this.cryotankPath.isInvalid()) {
                    if (this.applicable() && this.cryotankPath.errorMessage()) {
                        this.errors.push(this.cryotankPath.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({
            cryotank_id: this.cryotankId() || null,
            cryotank_address: this.cryotankPath().map((x) => {
                return { label: x };
            }) || null,
        });
    }),

    cryopreserve_set_date_action: (qs, seed) => new (class extends QuickselectAction {
        private freezeDate: CheckExtended<ko.Observable<string>>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_cryopreservation_state_id,
            ];

            this.freezeDate = ko.observable().extend({
                normalize: normalizeDate,
                invalid: (v) => !(v && !isInvalidCalendarDate(v)),
            });
            this.freezeDate.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.pureComputed(() => {
                return this.freezeDate.isValid();
            });
        }

        public serialize = () => ({ cryopreservation_freeze_date: this.freezeDate() || null });
    }),

    cryopreserve_thaw_action: (qs, seed) => new (class extends QuickselectAction {
        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_cryopreservation_state_id,
            ];
        }
    }),

    revitalize_action: (qs, seed) => new (class extends QuickselectAction {
        private oocyteDonorAnimal: ko.Observable<OocyteDonor>;  // selected in the drop-down to be added when the button is clicked
        private oocyteDonorAnimals: ko.ObservableArray<OocyteDonor>;  // all possible animals that can be donor
        private possibleOocyteDonorAnimals: ko.PureComputed<Array<OocyteDonor>>;  // `oocyteDonorAnimals` minus `selectedOocyteDonors`
        private selectedOocyteDonors: ko.ObservableArray<OocyteDonor>;  // selected to perform this action
        private strainId: ko.Observable<number>;
        private possibleStrains: Array<StrainOption>;
        private searchDonorInProgress: ko.Observable<boolean>;
        private combinations: ko.PureComputed<Array<RevitalizeAssignment>>;
        private selectedCombinations: ko.PureComputed<Array<RevitalizeAssignment>>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_incubation_state_id,
            ];

            this.oocyteDonorAnimal = ko.observable();
            this.oocyteDonorAnimals = ko.observableArray(seed.available_oocyte_donors);
            this.possibleOocyteDonorAnimals = ko.pureComputed(() => {
                return this.oocyteDonorAnimals().filter((donorAnimal) => {
                    return !this.selectedOocyteDonors().includes(donorAnimal);
                });
            });
            this.selectedOocyteDonors = ko.observableArray();
            this.selectedOocyteDonors.subscribe(() => {
                this.selected(true);
            });

            this.strainId = ko.observable(seed.last_revitalization_strain);
            this.possibleStrains = seed.active_strains;
            this.searchDonorInProgress = ko.observable(false);

            this.combinations = ko.pureComputed(() => {
                return qs.context().map((spermRow) => {
                    const oocyteCount = ko.observable();
                    const spermVolume = ko.observable();

                    oocyteCount.extend({
                        invalid: (v) => {
                            return !((v === undefined ||
                                      v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0)) &&
                                     (!spermVolume() || v > 0));
                        },
                    });

                    spermVolume.extend({
                        invalid: (v) => {
                            return !((v === undefined ||
                                      v > 0 && v <= spermRow.volume && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0)) &&
                                     (!oocyteCount() || v > 0));
                        },
                    });

                    return {
                        sperm: spermRow,
                        oocyteCount: oocyteCount,
                        spermVolume: spermVolume,
                        isUsed: ko.pureComputed(() => spermVolume() && oocyteCount()),
                    };
                });
            });

            this.selectedCombinations = ko.pureComputed(() => {
                return this.combinations().filter((assignment) => assignment.sperm.selected());
            });

            this.valid = ko.pureComputed(() => {
                if (!this.selectedCombinations().some((assignment) => assignment.isUsed())) {
                    // at least one of the selected sperm volumes must be specified to be revitalized
                    return false;
                }

                return this.selectedCombinations().every((assignment) => {
                    // the inputs must be valid
                    return assignment.oocyteCount.isValid() && assignment.spermVolume.isValid();
                });
            });
        }

        private addDonor = () => {
            this.selectedOocyteDonors.push(this.oocyteDonorAnimal());
        };

        private removeDonor = (donorAnimal: OocyteDonor) => {
            this.selectedOocyteDonors.remove(donorAnimal);
        };

        private searchDonors = () => {
            this.searchDonorInProgress(true);
            SpermService.getOocyteDonorsByStrainForSetting({
                strainId: this.strainId(),
            }).then((response) => {
                this.oocyteDonorAnimals(response);
            }).finally(() => this.searchDonorInProgress(false));
        };

        public serialize = () => ({
            assignments: this.selectedCombinations().filter((assignment) => assignment.isUsed()).map((assignment) => ({
                sperm_id: assignment.sperm.sperm_id,
                animal_ids: this.selectedOocyteDonors().map((donorAnimal) => donorAnimal.animal_id),
                oocyte_count: assignment.oocyteCount(),
                sperm_volume: assignment.spermVolume(),
            })),
        });
    }),

    export_to_scientist_action: (qs, seed) => new (class extends QuickselectAction {
        private ownerId: CheckExtended<ko.Observable<number>>;
        private possibleOwners: Array<Owner>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_incubation_state_id,
                seed.sperm_cryopreservation_state_id,
            ];

            this.ownerId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select an owner");
                    }

                    return false;
                },
            });
            this.ownerId.subscribe(() => {
                this.selected(true);
            });

            this.possibleOwners = seed.active_owners;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.ownerId.isInvalid()) {
                    if (this.applicable() && this.ownerId.errorMessage()) {
                        this.errors.push(this.ownerId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ owner_id: this.ownerId() });
    }),

    export_to_institution_action: (qs, seed) => new (class extends QuickselectAction {
        private institutionId: CheckExtended<ko.Observable<number>>;
        private possibleInstitutions: Array<IdNameProperty>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.sperm_incubation_state_id,
                seed.sperm_cryopreservation_state_id,
            ];

            this.institutionId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select a facility");
                    }

                    return false;
                },
            });
            this.institutionId.subscribe(() => {
                this.selected(true);
            });

            this.possibleInstitutions = seed.available_institutions;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.institutionId.isInvalid()) {
                    if (this.applicable() && this.institutionId.errorMessage()) {
                        this.errors.push(this.institutionId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ institution_id: this.institutionId() });
    }),

    export_to_xls_action: (qs) => new (class extends QuickselectAction {
        public requireConclusion = false;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {
                const otherActionSelected = Object.values(qs.actions() || {}).some((action) => {
                    return action !== this && action.applicable();
                });

                this.errors.removeAll();

                if (otherActionSelected) {
                    if (this.applicable()) {
                        this.errors.push(getTranslation("This cannot be combined with other actions"));
                    }

                    return false;
                }

                return true;
            });
        }
    }),

};

class SpermQuickselectViewModel {
    private dialog: HtmlDialog;
    private reloadRequired: ko.Observable<boolean>;
    private loadInProgress: ko.Observable<boolean>;
    public readonly context: ko.ObservableArray<ContextRow>;
    public readonly actions: ko.Observable<{[actionKey: string]: QuickselectAction}>;
    public readonly selectedContext: ko.PureComputed<Array<ContextRow>>;
    public readonly selectedVolume: ko.PureComputed<number>;  // sum of all selected sperm volume in the context
    private dialogTitle: ko.PureComputed<string>;
    private scenery: ko.PureComputed<{
        loading: boolean;
    } | {
        conclusion: SpermQuickselectViewModel;
    } | {
        context: SpermQuickselectViewModel;
        actions: SpermQuickselectViewModel;
    } | {
        error: boolean;
    }>;
    private canApply: ko.PureComputed<boolean>;
    private applyInProgress: ko.Observable<boolean>;
    private conclusion: ko.ObservableArray<{ text: string; click: () => void; warning?: boolean }>;

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;
        this.reloadRequired = ko.observable(false);

        this.dialog.addOnClose(() => {
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        this.loadInProgress = ko.observable(true);
        this.context = ko.observableArray();
        this.actions = ko.observable();
        SpermService.getSpermQuickselectSeed({
            requestBody: params.spermIds,
        }).then((response) => {
            this.context(response.context?.map((spermRow) => ({
                ...spermRow,
                selected: ko.observable(params.spermIds.includes(spermRow.sperm_id)),
            })));

            this.actions(Object.keys(response.actions).reduce((memo, actionKey) => {
                if (Object.hasOwn(actionModels, actionKey)) {
                    return {
                        ...memo,
                        [actionKey]: actionModels[actionKey](this, response.actions[actionKey]),
                    };
                }

                return memo;
            }, {}));
        }).catch(this.handleErrorResponse).finally(() => this.loadInProgress(false));

        this.selectedContext = ko.pureComputed(() => {
            return this.context().filter((spermRow) => spermRow.selected());
        });

        this.selectedVolume = ko.pureComputed(() => this.selectedContext().reduce((sum, spermRow) => {
            return sum + spermRow.volume;
        }, 0));

        this.dialogTitle = ko.pureComputed(() => {
            if (this.loadInProgress()) {
                return getTranslation("Quick Select");
            }

            return getTranslation("Quick Select") +
                    " (" + getTranslation("<%- volume %> µl sperm selected").replace("<%- volume %>", String(this.selectedVolume())) + ")";
        });
        this.dialogTitle.subscribe((dialogTitle) => {
            this.dialog.setTitle(dialogTitle);
        });

        this.scenery = ko.pureComputed(() => {
            if (this.loadInProgress()) {
                return { loading: true };
            }

            if (this.conclusion().length) {
                return { conclusion: this };
            }

            if (this.context()?.length && this.actions() && Object.keys(this.actions()).length) {
                return {
                    context: this,
                    actions: this,
                };
            }

            return { error: true };
        });

        this.canApply = ko.pureComputed(() => {
            if (this.applyInProgress()) {
                return false;
            }

            if (this.selectedVolume() < 1) {
                return false;
            }

            // any possible action selected at all?
            if (!Object.values(this.actions()).some((action) => action.applicable())) {
                return false;
            }

            // check if all possible selected actions are valid
            return Object.values(this.actions()).every((action) => {
                return !action.applicable() || !action.valid || Boolean(action.valid());
            });
        });

        this.applyInProgress = ko.observable(false);

        this.conclusion = ko.observableArray();
    }

    private toggleSelection = () => {
        this.context().forEach((spermRow) => {
            spermRow.selected(!spermRow.selected());
        });
    };

    public handleErrorResponse = (response: any) => {
        if (typeof response.body?.detail === "string") {
            notifications.showNotification(response.body.detail, "error");
        } else {
            notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
            writeException(response);
        }
    };

    private applyQuickselect = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);
        Object.values(this.actions()).forEach((action) => {
            action.errors.removeAll();
        });

        SpermService.applySpermQuickselectActions({
            requestBody: {
                context: {
                    sperm_ids: this.selectedContext().map((spermRow) => spermRow.sperm_id),
                },
                content: Object.keys(this.actions()).reduce((memo, actionKey) => {
                    const action = this.actions()[actionKey];

                    if (action.applicable()) {
                        return {
                            ...memo,
                            [actionKey]: action.serialize(),
                        };
                    }

                    return memo;
                }, {}),
            },
        }).then((response) => {
            this.conclude(response);
        }).catch((response) => {
            const content = response.body?.detail?.content;

            if (content) {
                Object.keys(content).forEach((actionKey) => {
                    if (this.actions()[actionKey] && this.actions()[actionKey].errors) {
                        content[actionKey].forEach((message: string) => {
                            this.actions()[actionKey].errors.push(message);
                        });
                    } else {
                        content[actionKey].forEach((message: string) => {
                            notifications.showNotification(message, "error");
                        });
                    }
                });
            } else {
                notifications.showNotification(getTranslation("General quickselect error."), "error");
                writeException(response);
            }
        }).finally(() => this.applyInProgress(false));
    };

    private conclude = (value: SpermQuickselectActionsResponse) => {
        const cryopreserveAssembleAction = value?.content?.cryopreserve_assemble_action;
        const revitalizeAction = value?.content?.revitalize_action;
        const exportAction = value?.content?.export_to_xls_action;
        const spermIds = value?.context?.sperm_ids;
        const thawedSpermIds = cryopreserveAssembleAction?.thawed_sperm_ids;
        const embryoIds = revitalizeAction?.embryo_ids;
        const canClose = Object.keys(value?.content).every((actionKey) => {
            const action = this.actions()[actionKey];

            return !action?.applicable() || !action?.requireConclusion;
        });

        if (canClose) {
            if (exportAction) {
                this.reloadRequired(false);
                showColumnSelect({
                    mode: "export",
                    viewName: "spermlist",
                    exportArgs: {
                        spermid: exportAction.sperm_ids,
                        page_start: 0,
                        page_size: exportAction.sperm_ids.length,
                    },
                });
            }

            // no conclusion was needed, so we close it
            this.dialog.close();
        } else {
            this.conclusion.removeAll();

            if (spermIds?.length) {
                this.conclusion.push({
                    text: getTranslation("Show sperm volumes (<%- count %>)").replace("<%- count %>", String(spermIds.length)),
                    click: () => {
                        mainMenu.openAndResetListFilter("get_sperm_list", {
                            sperm_id: spermIds,
                        });
                    },
                });
            }

            if (embryoIds?.length) {
                this.conclusion.push({
                    text: getTranslation("Show group of embryos (<%- count %>)").replace("<%- count %>", String(embryoIds.length)),
                    click: () => {
                        mainMenu.openAndResetListFilter("get_embryo_list", {
                            embryo_id: embryoIds,
                        });
                    },
                });
                this.conclusion.push({
                    text: getTranslation("Open Quick Select for the embryos to perform another task"),
                    click: () => {
                        showEmbryoQuickselect({
                            embryoIds: embryoIds,
                        });
                    },
                });
            }

            if (thawedSpermIds?.length) {
                this.conclusion.push({
                    text: getTranslation("Some of the sperm volumes were frozen, prior to the cryopreservation. Their thaw date was reset. Show these sperm volumes.") +
                            " (" + thawedSpermIds.length + ")",
                    click: () => {
                        mainMenu.openAndResetListFilter("get_sperm_list", {
                            sperm_id: thawedSpermIds,
                        });
                    },
                    warning: true,
                });
            }
        }
    };

}

export const showSpermQuickselect = htmlDialogStarter(SpermQuickselectViewModel, template, {
    name: "SpermQuickselect",
    title: getTranslation("Quick Select"),
    width: 600,
    position: {
        inset: { top: 20, right: 20 },
    },
    closeOthers: true,
});
