/**
 * Show the embryo details pop-up.
 *
 * @param embryoGroupKey
 *        The unique hash of the embryo group.
 *
 * @param loadCallback
 *        Function to call when the popup has been loaded.
 *
 * @param reloadCallback
 *        Function to call when data has been applied and popup is closed
 *        (e.g. to reload a list to display new data).
 *
 * @param closeCallback
 *        Function to call whenever the popup is closed, whether data was
 *        applied or not (e.g. to unhighlight a row in listview table).
 *
 */

import * as ko from "knockout";

import {
    CommentWidgetSeed,
    CommentsService,
    DocumentWidgetSeed,
    DocumentsService,
    EmbryoDetails,
    EmbryoEvent,
    EmbryosService,
    IdLabelProperty,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import {
    frames,
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    cgiScript,
    getUrl,
} from "../lib/utils";

import template from "./embryoDetails.html";
import "/scss/popup_details_common_styles.scss";

interface Params {
    embryoGroupKey: string;
    loadCallback?: () => void;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

class EmbryoDetailsViewModel {
    private dialog: HtmlDialog;
    private tab: ko.Observable<"history" | "comments" | "documents">;
    private reloadRequired: ko.Observable<boolean>;
    private loadInProgress: ko.Observable<boolean>;
    private embryoDetails: ko.Observable<EmbryoDetails>;
    private embryoEvents: ko.ObservableArray<EmbryoEvent>;
    private embryoComments: ko.Observable<CommentWidgetSeed>;
    private embryoDocuments: ko.Observable<DocumentWidgetSeed>;
    private scenery: ko.PureComputed<{
        loading: boolean;
    } | {
        error: string;
    } | {
        meta: EmbryoDetails;
        history: Array<EmbryoEvent>;
        comments: CommentWidgetSeed;
        documents: DocumentWidgetSeed;
    }>;
    private dialogTitle: ko.PureComputed<string>;

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;

        this.tab = ko.observable("history");
        this.reloadRequired = ko.observable(false);

        this.dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }

            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        this.loadInProgress = ko.observable(false);
        this.embryoDetails = ko.observable();
        this.embryoEvents = ko.observableArray();
        this.embryoComments = ko.observable();
        this.embryoDocuments = ko.observable();

        if (params.embryoGroupKey) {
            this.loadInProgress(true);
            EmbryosService.getEmbryoDetails({ embryoGroupKey: params.embryoGroupKey }).then((response) => {
                this.embryoDetails(response);

                // if the details loaded successfully it means the user has permission to see the embryo details
                // and the rest of the data can be fetched too
                Promise.all([
                    EmbryosService.getEmbryoHistory({ embryoGroupKey: params.embryoGroupKey }).then((response) => this.embryoEvents(response)),
                    CommentsService.getCommentWidgetSeed({
                        requestBody: {
                            subjects: { embryo_id: this.embryoDetails().embryo_group_ids },
                        },
                    }).then((response: CommentWidgetSeed) => this.embryoComments(response)),
                    DocumentsService.getDocumentWidgetSeed({
                        requestBody: {
                            embryo_id: this.embryoDetails().embryo_group_ids,
                        },
                    }).then((response) => this.embryoDocuments(response)),
                ]).catch(this.handleErrorResponse).finally(() => this.loadInProgress(false));
            }).catch((response) => {
                this.handleErrorResponse(response);
                this.loadInProgress(false);
            });
        }

        this.scenery = ko.pureComputed(() => {
            if (this.loadInProgress()) {
                return { loading: true };
            }

            if (this.embryoDetails() && this.embryoEvents() && this.embryoComments() && this.embryoDocuments()) {
                return {
                    meta: this.embryoDetails(),
                    history: this.embryoEvents(),
                    comments: this.embryoComments(),
                    documents: this.embryoDocuments(),
                };
            }

            return { error: getTranslation("General unexpected error occurred.") };
        });
        this.scenery.subscribeOnce(() => {
            if (typeof params.loadCallback === "function") {
                params.loadCallback();
            }
        });

        this.dialogTitle = ko.pureComputed(() => {
            let embryoData;

            // @ts-expect-error: Property 'meta' does not exist on type '{ loading: boolean; }'
            if (this.scenery().meta) {
                embryoData = this.embryoDetails();

                return embryoData.embryo_count + " " + getTranslation("Embryos") + ", " + embryoData.state_label;
            }

            return getTranslation("Embryo details");
        });
        this.dialogTitle.subscribe((dialogTitle) => {
            this.dialog.setTitle(dialogTitle);
        });

    }

    private historyMetaLabel = (key: string) => {
        const labels = {
            age_label: getTranslation("Cell stage"),
            animal: getTranslation("Animal"),
            donor_animals: getTranslation("Donors"),
            fertilisation_sperm: getTranslation("Sperm"),
            fertilisation_oocyte_donors: getTranslation("Oocyte donors"),
            fertilisation_sperm_donors: getTranslation("Sperm donors"),
            owner_fullname: getTranslation("Owner"),
            project_label: getTranslation("Project"),
            strain_name: getTranslation("Line / Strain"),
            origin_name: getTranslation("Origin"),
            institution_name: getTranslation("Facility"),
            transfer_location_label: getTranslation("Side"),
            cryotank_content_address: getTranslation("Position / Label"),
            freeze_date: getTranslation("Freezing date"),
            thaw_date: getTranslation("Thaw date"),
            state_label: getTranslation("State"),
        };

        // @ts-expect-error: Element implicitly has an 'any' type
        return Object.hasOwn(labels, key) && labels[key] || key;
    };

    private historyMetaValue = (key: string, value: any) => {
        const element = document.createElement("span");
        let link;
        let icon;

        if (key === "cryotank_content_address") {
            element.innerText = value.map((cryotankAddressBreadcrumb: IdLabelProperty) => cryotankAddressBreadcrumb.label).join(" > ");

        } else if (key === "animal") {
            link = document.createElement("a");

            link.innerText = value.eartag;
            link.classList.add("subtle-link");
            link.title = getTranslation("View details for: %s").replace("%s", value.eartag);
            link.addEventListener("click", () => {
                frames.detailPopup.open(getUrl(cgiScript("mousedetail.py"), {
                    animalid: value.id,
                }, {
                    absoluteUrl: true,
                }));
            });
            element.appendChild(link);

        } else if (key === "donor_animals" || key === "fertilisation_oocyte_donors" || key === "fertilisation_sperm_donors") {
            value.forEach((animal: { id: number; eartag: string }) => {
                const link = document.createElement("a");

                link.innerText = animal.eartag;
                link.classList.add("subtle-link");
                link.title = getTranslation("View details for: %s").replace("%s", animal.eartag);
                link.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("mousedetail.py"), {
                        animalid: animal.id,
                    }, {
                        absoluteUrl: true,
                    }));
                });
                element.appendChild(link);
            });

        } else if (key === "fertilisation_sperm") {
            link = document.createElement("a");
            icon = document.createElement("span");

            link.innerText = value.volume + " µl";
            link.classList.add("subtle-link");
            link.title = getTranslation("Show in sperm list");
            link.addEventListener("click", () => {
                mainMenu.openAndResetListFilter("get_sperm_list", {
                    event_id: value.event_id,
                });
            });
            icon.classList.add("icon_button");
            icon.classList.add("only_icon_img");
            icon.classList.add("icon_filter");
            link.insertBefore(icon, link.firstChild);
            element.appendChild(link);

        } else {
            element.innerText = value;
        }

        return element;
    };

    private reseedComments = (seed: CommentWidgetSeed) => {
        this.embryoComments(seed);
        this.reloadRequired(true);
    };

    private handleErrorResponse = (response: any) => {
        if (typeof response.body?.detail === "string") {
            notifications.showNotification(response.body.detail, "error");
        } else {
            notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
            writeException(response);
        }
    };

}

export const showEmbryoDetails = htmlDialogStarter(EmbryoDetailsViewModel, template, {
    name: "EmbryoDetails",
    width: 600,
    position: {
        inset: { top: 20, right: 20 },
    },
    closeOthers: true,
});
