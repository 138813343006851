/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicUser } from '../models/BasicUser';
import type { OwnersForGroupWidget } from '../models/OwnersForGroupWidget';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Get Active Owner List
     * Get a list of active owners.
     * @returns BasicUser Successful Response
     * @throws ApiError
     */
    public static getActiveOwners(): CancelablePromise<Array<BasicUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/active_owners',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Responsible User List
     * Get a list of active owners.
     * @returns BasicUser Successful Response
     * @throws ApiError
     */
    public static getResponsibles({
        userId,
        status = 'active',
    }: {
        userId: Array<number>,
        status?: 'all' | 'active',
    }): CancelablePromise<Array<BasicUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/responsibles',
            query: {
                'user_id': userId,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Prefixes
     * Get a list of prefixes for a user.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getPrefixes({
        userId,
    }: {
        userId?: (number | null),
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/prefixes',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User List For Setting
     * Get a list of users for setting.
     * @returns BasicUser Successful Response
     * @throws ApiError
     */
    public static getUsersForSetting({
        level,
        status = 'all',
    }: {
        level: Array<'admin' | 'staff' | 'scientist' | 'secondary' | 'virtual' | 'vet' | 'accountant' | 'sci_fac' | 'sci_lab' | 'strain_db_user' | 'staff_transgenic'>,
        status?: 'active' | 'inactive' | 'all',
    }): CancelablePromise<Array<BasicUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/for_setting',
            query: {
                'level': level,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Owners For Group Widget
     * Return a list of owners for a group widget.
     * @returns OwnersForGroupWidget Successful Response
     * @throws ApiError
     */
    public static getOwnersForGroupWidget({
        groupIds,
        status = 'active',
    }: {
        groupIds?: Array<number>,
        status?: 'active' | 'inactive',
    }): CancelablePromise<Array<OwnersForGroupWidget>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/owners_for_group_widget',
            query: {
                'group_ids': groupIds,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
