import * as ko from "knockout";
import * as _ from "lodash";

import {
    IdNameProperty,
    SacrificeReasonCreate,
    SacrificeReasonUpdate,
    SacrificeReasonDelete,
    SacrificeReasonsService,
} from "../backend/v1";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";


interface Arguments {
    sacrifice_reasons: {
        id: number;
        name: string;
        permitted_work_request_classes: IdNameProperty[];
        unexpected: boolean;
        available: boolean;
        deletable: boolean;
    }[];
    work_request_classes: IdNameProperty[];
}

interface Row {
    id?: number;
    name: CheckExtended<ko.Observable<string>>;
    unexpected: ko.Observable<boolean>;
    permittedWorkRequestClasses: ko.ObservableArray<IdNameProperty>;
    availableWorkRequestClasses: ko.PureComputed<IdNameProperty[]>;
    selectedWorkRequestClass: ko.Observable<IdNameProperty>;
    available: ko.Observable<boolean>;
    deletable: boolean;
    toggleDelete: SacrificeReasonList["toggleDelete"];
    inserted: ko.Observable<boolean>;
    deleted: ko.Observable<boolean>;
    changed: ko.Observable<boolean>;
}

class SacrificeReasonList {
    public readonly args: Arguments;
    public readonly allowEdit: boolean = session.userPermissions.sacrifice_reason_update;
    public readonly hideUnavailable: ko.Observable<boolean>;

    public readonly sacrificeReasons: ko.ObservableArray<Row>;
    public readonly sacrificeReasonChanges: ko.PureComputed<(SacrificeReasonCreate | SacrificeReasonUpdate | SacrificeReasonDelete)[]>;
    public readonly canSubmit: ko.PureComputed<boolean>;
    public readonly submitInProgress: ko.Observable<boolean> = ko.observable(false);
    public readonly errorMessage: ko.PureComputed<string | boolean>;

    constructor(args: Arguments) {

        this.args = args;
        this.hideUnavailable = ko.observable(true);

        this.sacrificeReasons = ko.observableArray(
            _.map(this.args.sacrifice_reasons || [], (row) => {
                const rowModel: Row = {
                    id: row.id,
                    name: ko.observable(row.name).extend({
                        trim: true,
                        invalid: (v) => {
                            if (!v) {
                                return getTranslation("Please enter a name");
                            }

                            return false;
                        },
                    }),
                    permittedWorkRequestClasses: ko.observableArray(
                        _.map(row.permitted_work_request_classes || [], (wrc) => {
                            return {
                                id: wrc.id,
                                name: wrc.name,
                            };
                        }),
                    ),
                    availableWorkRequestClasses: ko.pureComputed(() => {
                        // get free work request classes (without the assigned ones)
                        return _.filter(this.args.work_request_classes, (item) => {
                            return !_.find(rowModel.permittedWorkRequestClasses(), item);
                        });
                    }),
                    selectedWorkRequestClass: ko.observable(),
                    unexpected: ko.observable(row.unexpected),
                    available: ko.observable(row.available),
                    deletable: row.deletable,
                    toggleDelete: this.toggleDelete,
                    inserted: ko.observable(false),
                    changed: ko.observable(false),
                    deleted: ko.observable(false),
                };
                const handleChange = () => {
                    rowModel.changed(true);
                    this.highlightSaveButton();
                };

                rowModel.name.subscribe(handleChange);
                rowModel.permittedWorkRequestClasses.subscribe(handleChange);
                rowModel.unexpected.subscribe(handleChange);
                rowModel.available.subscribe(handleChange);

                return rowModel;
            }),
        );

        this.errorMessage = ko.pureComputed(() => {
            return _.reduce(
                this.sacrificeReasons(),
                (msg, row) => {
                    if (!row.deleted() && (row.inserted() || row.changed())) {
                        return msg || row.name.errorMessage();
                    }
                    return msg;
                },
                false,
            );
        });

        this.sacrificeReasonChanges = ko.pureComputed(() => {
            return _.chain(this.sacrificeReasons())
                .filter((row) => {
                    return row.inserted() || row.changed() || row.deleted();
                })
                .map((row) => {
                    if (row.deleted()) {
                        // delete sacrifice reason
                        return {
                            action: "delete" as SacrificeReasonDelete["action"],
                            id: row.id,
                        };
                    } else if (row.changed() && row.id) {
                        // update sacrifice reason
                        return {
                            action: "update" as SacrificeReasonUpdate["action"],
                            id: row.id,
                            name: row.name(),
                            permitted_work_request_class_ids: _.map(row.permittedWorkRequestClasses(), "id"),
                            unexpected: row.unexpected(),
                            available: row.available(),
                        };
                    } else if (row.inserted()) {
                        // create sacrifice reason
                        return {
                            action: "create" as SacrificeReasonCreate["action"],
                            name: row.name(),
                            permitted_work_request_class_ids: _.map(row.permittedWorkRequestClasses(), "id"),
                        };
                    }
                })
                .value();
        });

        this.canSubmit = ko.computed(() => {
            const anyChange = _.some(this.sacrificeReasons(), function(row) {
                return row.inserted() || row.changed() || row.deleted();
            });
            const anyInvalid = _.some(this.sacrificeReasons(), function(row) {
                return row.name.isInvalid();
            });

            return !this.submitInProgress()
                && !anyInvalid
                && anyChange;
        });
    }

    public highlightSaveButton = () => {
        const saveButton = document.getElementById("save_button");

        saveButton.classList.add("blink_twice");
        saveButton.addEventListener("animationend", function () {
            this.classList.remove("blink_twice");
        });
    };

    toggleDelete = (row: Row) => {
        if (!row.id) {
            this.sacrificeReasons.remove(row);
        } else {
            row.deleted(!row.deleted());
            if (row.deleted()) {
                this.highlightSaveButton();
            }
        }
    };

    public addWorkRequestClass = (row: Row) => {
        row.permittedWorkRequestClasses.push(row.selectedWorkRequestClass());
    };


    public removeWorkRequestClass = (row: Row, item: IdNameProperty) => {
        row.permittedWorkRequestClasses.remove(item);
    };

    public addSacrificeReason = () => {
        const rowModel: Row = {
            name: ko.observable().extend({
                trim: true,
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please enter a name");
                    }
                    return false;
                },
            }),
            permittedWorkRequestClasses: ko.observableArray([]),
            availableWorkRequestClasses: ko.pureComputed(() => {
                // get free work request classes (without the assigned ones)
                return _.filter(this.args.work_request_classes, (item) => {
                    return !_.find(rowModel.permittedWorkRequestClasses(), item);
                });
            }),
            selectedWorkRequestClass: ko.observable(),
            unexpected: ko.observable(false),
            available: ko.observable(true),
            deletable: true,
            inserted: ko.observable(true),
            changed: ko.observable(true),
            deleted: ko.observable(false),
            toggleDelete: this.toggleDelete,
        };
        this.sacrificeReasons.push(rowModel);
        this.highlightSaveButton();
    };

    public submit = () => {
        this.submitInProgress(true);
        SacrificeReasonsService.updateSacrificeReasons({ requestBody: this.sacrificeReasonChanges() })
            .then(() => {
                notifications.showNotification(getTranslation("Changes saved"), "success");
                this.reload();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
                    writeException(reason);
                }
            })
            .finally(() => {
                this.submitInProgress(false);
            });
    };

    public reload = () => {
        window.location.reload();
    };
}

export const initSacrificeReasonList = (args: Arguments): void => {
    ko.applyBindings(new SacrificeReasonList(args), document.getElementById("sacrifice_reason_list"));
};
