import {
    observable,
    observableArray,
    ObservableArray,
    pureComputed,
    PureComputed,
} from "knockout";

import {
    GroupedStrainForSetting,
    StrainsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";

import template from "./blockStrain.html";


class BlockStrainViewModel {
    public fetchInProgress = observable(true);
    public submitInProgress = observable(false);
    public strainName = observable("");
    public numberOfAnimals = observable(0);
    public numberOfPups = observable(0);
    public totalCount: PureComputed<number>;
    public possibleStrains: ObservableArray<GroupedStrainForSetting> = observableArray();
    public selectedStrain: ObservableArray<GroupedStrainForSetting> = observableArray();
    private dialog: HtmlDialog;
    private strainId: number;

    constructor(dialog: HtmlDialog, parameters: { strainId: number }) {
        this.dialog = dialog;
        this.strainId = parameters.strainId;
        StrainsService.getStrainBlockDetails({ strainId: this.strainId })
            .then((response) => {
                this.strainName(response.strainName);
                this.numberOfAnimals(response.numberOfAnimals);
                this.numberOfPups(response.numberOfPups);
                this.possibleStrains(response.possibleStrains);
                dialog.setTitle(getTranslation("Line / Strain") + ": " + this.strainName());
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        this.totalCount = pureComputed(() => this.numberOfAnimals() + this.numberOfPups());
    }

    public submit = (action: "reassign_mice" | "reassign_mutations" | "block") => {
        this.submitInProgress(true);
        StrainsService.blockStrain({
            strainId: this.strainId,
            reassignTo: this.selectedStrain()?.[0]?.id,
            action: action,
        })
            .then(() => {
                notifications.showNotification(getTranslation("Strain blocked."), "success");
                this.dialog.close();
                location.reload();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };
}
export const showBlockStrainDialog = htmlDialogStarter(BlockStrainViewModel, template, {
    name: "BlockStrain",
    modal: true,
    minWidth: 400,
    position: { inset: { top: 120 } },
    closeOthers: true,
});
