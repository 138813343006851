import { Observable } from "knockout";

import {
    ListFilterDefinition,
    OriginsService,
} from "../../backend/v1";
import {
    ListFilterItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { notifications } from "../../lib/pyratTop";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    getUrl,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./originListFilter.html";


interface Arguments extends ListViewArguments {
    export_args: any;
}

const ListFilters = () => ({
    status: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => {
                return item.name;
            });
        }
    },
});

class List {
    private listView: ListView;
    private args: Arguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Origin filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("create-new-origin", () => {
            openListDetailPopup(
                getUrl(cgiScript("origin_detail.py"), {
                    origin: "new",
                }),
            );
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.export_args,
            });
        });

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        // list item controls

        this.listView.onCellClick("td.name.clickable", (args) => {
            openListDetailPopup(
                getUrl(cgiScript("origin_detail.py"), {
                    origin: parseInt(args.rowId, 10),
                }), () => this.listView.unHighlightRow(args.rowId),
            );
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.action .origin-deactivate", (args) => {
            OriginsService.deactivateOrigin({
                originId: parseInt(args.rowId, 10),
            })
                .then(() => notifications.showNotification(getTranslation("Origin successfully updated"), "success"))
                .catch(() =>
                    notifications.showNotification(
                        getTranslation("Action failed. The data could not be saved. Please try again."),
                        "error",
                    ),
                )
                .finally(() => this.listView.reload());
        });

        this.listView.onCellClick("td.action .origin-activate", (args) => {
            OriginsService.activateOrigin({
                originId: parseInt(args.rowId, 10),
            })
                .then(() => notifications.showNotification(getTranslation("Origin successfully updated"), "success"))
                .catch(() =>
                    notifications.showNotification(
                        getTranslation("Action failed. The data could not be saved. Please try again."),
                        "error",
                    ),
                )
                .finally(() => this.listView.reload());
        });

        this.listView.onCellClick("td.action .origin-delete", (args) => {
            notifications.showConfirm(getTranslation("Are you sure you want to delete this origin?"), () => {
                OriginsService.deleteOrigin({
                    originId: parseInt(args.rowId, 10),
                })
                    .then(() =>
                        notifications.showNotification(getTranslation("Origin successfully deleted"), "success"),
                    )
                    .catch(() =>
                        notifications.showNotification(
                            getTranslation("Action failed. The data could not be saved. Please try again."),
                            "error",
                        ),
                    )
                    .finally(() => this.listView.reload());
            });
            return false;
        });
    }
}

export const initOriginList = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
