import * as ko from "knockout";
import {
    components,
    Observable,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import template from "./koProgress.html";
import "./koProgress.scss";


interface ProgressParams {
    value: Observable;
    fullHeight: boolean | Observable<boolean>;
}

class ProgressViewModel {

    private fullHeight: boolean;
    private percent: PureComputed<number>;
    private percentStr: PureComputed<string>;

    constructor(params: ProgressParams) {
        if (!ko.isObservable(params.value)) {
            throw new Error("The value param need to be a knockout observable.");
        }

        this.fullHeight = !!ko.unwrap(params.fullHeight);
        this.percent = ko.pureComputed(() => {
            const value = params.value();

            if (!_.isNumber(value) || value < 0 || value > 1) {
                throw new Error("the value needs to be a Number between 0 and 1.");
            }

            return Math.round(value * 100);
        });

        this.percentStr = ko.pureComputed(() => {
            return this.percent() + "%";
        });
    }
}


export class ProgressComponent {

    constructor() {

        return {
            viewModel: {
                createViewModel: (params: any, componentInfo: components.ComponentInfo) => {

                    // ensure the component is used through <progress/> HTML element so we can use scoped styles
                    if (componentInfo.element.nodeName.toLowerCase() !== "ko-progress") {
                        throw new Error("Only use the progress component through <ko-progress/> HTML tag.");
                    }

                    return new ProgressViewModel(params);
                },
            },
            template,
        };
    }
}
