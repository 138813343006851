import "./colonyPedigree.scss";
import {
    applyBindings,
    computed,
    Computed,
    observable,
    Observable,
    observableArray,
    ObservableArray,
} from "knockout";
import * as _ from "lodash";

import { ReportsService } from "../backend/v1";
import { showTankDetails } from "../dialogs";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import {
    getSessionItem,
    setSessionItem,
} from "../lib/browserStorage";
import { getTranslation } from "../lib/localize";
import { session } from "../lib/pyratSession";
import { frames } from "../lib/pyratTop";
import {
    cgiScript,
    el,
    getUrl,
} from "../lib/utils";

interface Arguments {
    kind?: string;
    label?: string;
    strain_autocomplete: any;
}

interface Node {
    id: number | "children" | "siblings";
}

type Edge = [Node["id"], Node["id"]];

interface Animal {
    sex: "m" | "f" | "?";
    animal_access_permitted: boolean;
    eartag: string;
    strain_name_with_id: string;
    cagenumber: string;
    dateborn: string;
    datesacrificed: string;
    mutations: {
        mutationname: string;
        mutationgrade?: string;
    }[];
    parents: {
        parent_id: number;
        parent_eartag: string;
        parent_sex: "m" | "f" | "?";
    }[];
}

interface AnimalNode extends Animal, Node {
    animals?: Animal[];
}

interface Tank {
    parent_tank_ids: number[] | null;
    tank_id: number;
    tank_position: string;
    tank_label: string;
    status: "open" | "closed" | "exported" | "joined";
    strain_name_with_id: string;
    number_of_male: number;
    number_of_female: number;
    number_of_unknown: number;
    generation: string;
    tank_access_permitted: boolean;
}

interface TankNode extends Tank, Node {
    tanks?: Tank[];
}

interface Strain extends Node {
    name_with_id: string;
    owner_fullname: string;
    project_label: string;
    severity_level: string;
    created: string;
    num_animals_live: number;
    num_tank_animals_alive: number;
    num_animals: number;
    num_tank_animals_released: number;
    num_pups_live: number;
    num_pups: number;
    num_cages_total: number;
    num_tanks: number;
    mutations: {
        name: string;
    }[];
}

interface StrainNode extends Strain, Node {
    parents?: Strain[];
    children?: Strain[];
}

interface Pedigree {
    root: number | "children" | "siblings";
    nodes: Node[];
    edges: Edge[];
    kind: string;
}

class PedigreeKindModel {
    public manager: ColonyPedigree;
    public nodeLabel: (pedigree_data: Pedigree, node: Node) => HTMLElement;
    public nodeClass: (pedigree_data: Pedigree, node: Node) => string;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
    constructor(manager: ColonyPedigree) {
    }

    public nodeId = (node: Node) => {
        return node.id;
    };
}

class PedigreeKind {
    public available: boolean;
    public label: string;
    public placeholder: string;
    public params: { [key: string]: any };
    public request: () => {
        kind: "animal_pedigree" | "tank_pedigree" | "strain_pedigree";
        label: string;
        nearby?: "half_siblings_and_children" | "full_siblings_and_children" | "siblings_and_children";
    };
    public model: typeof PedigreeKindModel;
}

class AnimalPedigreeModel extends PedigreeKindModel {

    constructor(manager: ColonyPedigree) {
        super(manager);
        this.manager = manager;
    }

    public nodeClass = (pedigree_data: Pedigree, node: AnimalNode) => {
        const classNames = [];

        if (node.id === "children" || node.id === "siblings") {
            classNames.push("list_node");
        } else {
            if (node.sex === "m") {
                classNames.push("animal_male_sex");
            } else if (node.sex === "f") {
                classNames.push("animal_female_sex");
            } else {
                classNames.push("animal_unknown_sex");
            }

            if (node.animal_access_permitted) {
                classNames.push("animal_access_permitted");
            } else {
                classNames.push("animal_access_denied");
            }

            if (node.datesacrificed) {
                classNames.push("animal_dead");
            } else {
                classNames.push("animal_alive");
            }

            if (node.id === pedigree_data.root) {
                classNames.push("root_node");
            }

        }

        return classNames.join(" ");
    };

    public nodeLabel = (pedigree_data: Pedigree, node: AnimalNode): HTMLElement => {
        const c = document.createElement("div");
        const actions = document.createElement("div");
        actions.classList.add("actions");

        if (node.id === "children" || node.id === "siblings") {
            c.classList.add("list_node", `${node.id}_node`);
            c.style.maxWidth = "200px";

            if (node.id === "siblings") {
                const h1 = document.createElement("h1");
                h1.textContent = getTranslation("Siblings");
                c.appendChild(h1);
            } else if (node.id === "children") {
                const h1 = document.createElement("h1");
                h1.textContent = getTranslation("Children");
                c.appendChild(h1);
            }

            _.forEach(_.chain(node.animals)
                .sortBy("birth_id")
                .groupBy("birth_id")
                .value(), (birth_animals) => {

                const birthGroup = document.createElement("div");
                birthGroup.classList.add("birth");

                birth_animals.forEach((animal: any, index: number) => {
                    const animalSpan = document.createElement("span");
                    animalSpan.textContent = animal.eartag;
                    // @ts-expect-error: animal.sec is "m"|"f"|"?"
                    animalSpan.classList.add("animal", { "m": "male", "f": "female", "?": "unknown" }?.[animal.sex], animal.datesacrificed ? "animal_dead" : "animal_alive");
                    animalSpan.addEventListener("click", () => {
                        this.manager.subject(this.manager.kinds.animal_pedigree);
                        this.manager.subjectLabel(animal.eartag);
                        this.manager.generateRequest();
                    });
                    birthGroup.appendChild(animalSpan);

                    if (index + 1 < birth_animals.length) {
                        const commaSpan = document.createElement("span");
                        commaSpan.textContent = ",";
                        commaSpan.classList.add("comma");
                        birthGroup.appendChild(commaSpan);
                        birthGroup.appendChild(document.createTextNode(" "));
                    }
                });

                c.appendChild(birthGroup);
            });

        } else {
            c.classList.add("animal_node");
            c.style.minWidth = `${_.size(node.eartag) * 12 + 60}px`;

            const sexSpan = document.createElement("span");
            sexSpan.classList.add("icon_button", "only_icon_img", node.sex === "m" ? "icon_male" : node.sex === "f" ? "icon_female" : "icon_unknown");
            c.appendChild(sexSpan);

            const h1 = document.createElement("h1");
            h1.title = node.eartag;
            h1.textContent = node.eartag;
            h1.classList.add("eartag");
            c.appendChild(h1);

            const strainDiv = document.createElement("div");
            strainDiv.classList.add("strain");
            const strainSpan = document.createElement("span");
            strainSpan.textContent = node.strain_name_with_id;
            strainDiv.appendChild(strainSpan);
            c.appendChild(strainDiv);

            node.mutations.forEach((mutation: any) => {
                const mutationDiv = document.createElement("div");
                mutationDiv.classList.add("mutation");
                const mutationLabel = document.createElement("label");
                mutationLabel.textContent = mutation.mutationname;
                const mutationSpan = document.createElement("span");
                mutationSpan.textContent = mutation.mutationgrade;
                mutationDiv.append(mutationLabel, mutationSpan);
                c.appendChild(mutationDiv);
            });

            const cageDiv = document.createElement("div");
            cageDiv.classList.add("cagenumber");
            const cageIconSpan = document.createElement("span");
            cageIconSpan.classList.add("icon_button", "icon_cage", "only_icon_img");
            const cageNumSpan = document.createElement("span");
            cageNumSpan.textContent = node.cagenumber;
            cageDiv.append(cageIconSpan, cageNumSpan);
            c.appendChild(cageDiv);

            const dobDiv = document.createElement("div");
            dobDiv.classList.add("day_of_birth");
            const dobIconSpan = document.createElement("span");
            dobIconSpan.classList.add("icon_button", "icon_born", "only_icon_img");
            const dobSpan = document.createElement("span");
            dobSpan.textContent = node.dateborn;
            dobDiv.append(dobIconSpan, dobSpan);
            c.appendChild(dobDiv);

            if (node.datesacrificed) {
                const dodDiv = document.createElement("div");
                dodDiv.classList.add("day_of_death");
                const dodIconSpan = document.createElement("span");
                dodIconSpan.classList.add("icon_button", "icon_death", "only_icon_img");
                const dodSpan = document.createElement("span");
                dodSpan.textContent = node.datesacrificed;
                dodDiv.append(dodIconSpan, dodSpan);
                c.appendChild(dodDiv);
            }

            if (node.parents && node.parents.length && node.id !== pedigree_data.root) {
                const pedSpan = document.createElement("span");
                pedSpan.classList.add("icon_button", "icon_pedigree", "only_icon_img", "right");
                pedSpan.addEventListener("click", () => {
                    this.manager.subject(this.manager.kinds.animal_pedigree);
                    this.manager.subjectLabel(node.eartag);
                    this.manager.generateRequest();
                });
                actions.appendChild(pedSpan);
            }

            if (node.animal_access_permitted === true) {
                const eyeSpan = document.createElement("span");
                eyeSpan.classList.add("icon_button", "icon_eye", "only_icon_img", "right");
                eyeSpan.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("mousedetail.py"), { animalid: node.id }, { absoluteUrl: true }));
                });
                actions.appendChild(eyeSpan);
            }

            if (actions.children.length) {
                c.appendChild(actions);
            }
        }

        return c;
    };

}

class TankPedigreeModel extends PedigreeKindModel {

    constructor(manager: ColonyPedigree) {
        super(manager);
        this.manager = manager;
    }

    public nodeClass = (pedigree_data: Pedigree, node: TankNode) => {
        const classNames = [];

        if (node.id === "children" || node.id === "siblings") {
            classNames.push("list_node");
        } else {

            if (node.tank_access_permitted) {
                classNames.push("tank_access_permitted");
            } else {
                classNames.push("tank_access_denied");
            }

            if (node.status == "open") {
                classNames.push("tank_open");
            }

            if (node.id === pedigree_data.root) {
                classNames.push("root_node");
            }

        }

        return classNames.join(" ");
    };

    public nodeLabel = (pedigree_data: Pedigree, node: TankNode) => {

        const c = el("div");
        const actions = el("div", { classList: ["actions"] });

        if (node.id === "children" || node.id === "siblings") {

            c.classList.add("list_node");
            c.classList.add(node.id + "_node");
            c.style.maxWidth = "200px";

            if (node.id === "siblings") {
                c.append(el("h1", {}, getTranslation("Siblings")));
            } else if (node.id === "children") {
                c.append(el("h1", {}, getTranslation("Children")));
            }

            node?.tanks?.forEach((tank, index) => {

                const tankElement = el("span", { classList: ["tank"] }, String(tank.tank_id));

                if (tank.status !== "open") {
                    tankElement.classList.add("tank_not_open");
                }

                if (tank.tank_label) {
                    tankElement.innerText += ` (${tank.tank_label})`;
                }

                tankElement.addEventListener("click", () => {
                    this.manager.subject(this.manager.kinds.tank_pedigree);
                    this.manager.subjectLabel(String(tank.tank_id));
                    this.manager.generateRequest();
                });

                c.append(tankElement);
                if (index + 1 < node.tanks.length) {
                    c.append(el("span", { classList: ["comma"] }, ", "));
                }

            });

        } else {

            c.classList.add("tank_node");

            // sizes must be defined in js for calculations
            c.style.minWidth = String(node.tank_id).length * 12 + 60 + "px";

            /* Animal Id */
            const heading = el("h1", { classList: ["tank_id"] }, String(node.tank_id));
            c.append(heading);

            /* Strain name */
            if (node.tank_label) {
                heading.append(el("span", { classList: ["tank_label"] }, node.tank_label));
            }

            c.append(el("table", { classList: ["animal_counts"] },
                el("tr", {},
                    el("th", { classList: ["icon_button", "icon_male"] }),
                    el("td", {}, String(node.number_of_male)),
                    el("th", { classList: ["icon_button", "icon_female"] }),
                    el("td", {}, String(node.number_of_female)),
                    el("th", { classList: ["icon_button", "icon_unknown"] }),
                    el("td", {}, String(node.number_of_unknown)),
                ),
            ));

            /* Strain name */
            if (node.strain_name_with_id) {
                c.append(el("div", { classList: ["strain"] },
                    el("span", {}, node.strain_name_with_id),
                ));
            }

            /* Generation */
            if (node.generation) {
                c.append(el("div", { classList: ["generation"] },
                    el("label", {}, getTranslation("Generation") + ": "),
                    el("span", {}, node.generation),
                ));
            }

            /* Link pedigree from here */
            if (node.parent_tank_ids?.length && node.tank_id !== pedigree_data.root) {
                const actionElement = el("span", { classList: ["icon_button", "icon_pedigree", "only_icon_img", "right"] });
                actionElement.addEventListener("click", () => {
                    this.manager.subject(this.manager.kinds.tank_pedigree);
                    this.manager.subjectLabel(String(node.tank_id));
                    this.manager.generateRequest();
                });
                actions.appendChild(actionElement);
            }

            /* Link animal details popup */
            if (node.tank_access_permitted === true) {
                const actionElement = el("span", { classList: ["icon_button", "icon_eye", "only_icon_img", "right"] });
                actionElement.addEventListener("click", () => {
                    showTankDetails({ tankId: node.id as number });
                });
                actions.appendChild(actionElement);
            }

            if (actions.childNodes.length) {
                c.append(actions);
            }

        }

        return c;
    };

}

class StrainPedigreeModel extends PedigreeKindModel {

    constructor(manager: ColonyPedigree) {
        super(manager);
        this.manager = manager;
    }

    public nodeClass = (pedigree_data: Pedigree, node: StrainNode) => {
        const classNames = [];

        if (node.id === "children") {
            classNames.push("list_node");
        } else {
            if (node.num_animals_live > 0 || node.num_pups_live > 0 || node.num_tank_animals_alive > 0) {
                classNames.push("strain_used_live");
            } else {
                classNames.push("strain_used_dead");
            }

            if (node.id === pedigree_data.root) {
                classNames.push("root_node");
            }
        }

        return classNames.join(" ");
    };

    public nodeLabel = (pedigree_data: Pedigree, node: StrainNode): HTMLElement => {
        const nodeDiv = document.createElement("div");
        nodeDiv.style.minWidth = "200px";
        const actions = document.createElement("div");
        actions.className = "actions";
        const childGroup = document.createElement("div");
        childGroup.className = "children";

        if (node.id === "children") {
            _.sortBy(node.children, "name_with_id").forEach((strain, index) => {
                const childSpan = document.createElement("span");
                childSpan.className = "child subtle-link";
                childSpan.textContent = strain.name_with_id;
                childSpan.title = strain.name_with_id;
                childSpan.addEventListener("click", () => {
                    this.manager.subject(this.manager.kinds.strain_pedigree);
                    this.manager.subjectLabel(String(strain.id));
                    this.manager.generateRequest();
                });
                childGroup.appendChild(childSpan);

                if (index + 1 < node.children.length) {
                    const commaSpan = document.createElement("span");
                    commaSpan.className = "comma";
                    commaSpan.textContent = ", ";
                    childGroup.appendChild(commaSpan);
                }
            });

            nodeDiv.style.maxWidth = "250px";
            nodeDiv.classList.add("list_node", `${node.id}_node`);
            const header = document.createElement("h1");
            header.textContent = getTranslation("Children");
            nodeDiv.appendChild(header);
            nodeDiv.appendChild(childGroup);

        } else {
            nodeDiv.classList.add("strain_node");

            /* strain_name */
            const nameHeader = document.createElement("h1");
            nameHeader.title = node.name_with_id;
            nameHeader.textContent = node.name_with_id;
            nameHeader.className = "strain_name";
            nodeDiv.appendChild(nameHeader);

            /* owner_fullname */
            const ownerDiv = document.createElement("div");
            ownerDiv.className = "owner_name";
            const ownerSpan = document.createElement("span");
            ownerSpan.textContent = node.owner_fullname;
            ownerDiv.appendChild(ownerSpan);
            nodeDiv.appendChild(ownerDiv);

            /* project label */
            if (node.project_label) {
                const projectDiv = document.createElement("div");
                projectDiv.className = "project_label";
                const projectLabel = document.createElement("label");
                projectLabel.textContent = getTranslation("Project") + ": ";
                const projectSpan = document.createElement("span");
                projectSpan.textContent = node.project_label;
                projectDiv.appendChild(projectLabel);
                projectDiv.appendChild(projectSpan);
                nodeDiv.appendChild(projectDiv);
            }

            /* severity level */
            if (node.severity_level) {
                const severityDiv = document.createElement("div");
                severityDiv.className = "severity_level";
                const severityLabel = document.createElement("label");
                severityLabel.textContent = getTranslation("Severity level") + ": ";
                const severitySpan = document.createElement("span");
                severitySpan.textContent = node.severity_level;
                severityDiv.appendChild(severityLabel);
                severityDiv.appendChild(severitySpan);
                nodeDiv.appendChild(severityDiv);
            }

            /* creation date */
            if (node.created) {
                const creationDiv = document.createElement("div");
                creationDiv.className = "creation_date";
                const creationLabel = document.createElement("label");
                creationLabel.textContent = getTranslation("Creation date") + ": ";
                const creationSpan = document.createElement("span");
                creationSpan.textContent = node.created;
                creationDiv.appendChild(creationLabel);
                creationDiv.appendChild(creationSpan);
                nodeDiv.appendChild(creationDiv);
            }

            /* mutations */
            if (node.mutations.length > 0) {
                const mutationHeader = document.createElement("h2");
                mutationHeader.textContent = getTranslation("Mutations") + ": ";
                mutationHeader.className = "mutations";
                nodeDiv.appendChild(mutationHeader);

                const mutationList = document.createElement("ul");
                mutationList.className = "mutations";
                _.map(node.mutations, (mutation) => {
                    const mutationItem = document.createElement("li");
                    mutationItem.textContent = mutation.name;
                    mutationItem.className = "mutations";
                    mutationList.appendChild(mutationItem);
                });
                nodeDiv.appendChild(mutationList);
            }

            /* num animals alive/total */
            const animalDiv = document.createElement("div");
            animalDiv.className = "animal_count";
            const animalLabelAlive = document.createElement("label");
            animalLabelAlive.textContent = getTranslation("Animals alive") + ": ";
            const animalSpanAlive = document.createElement("span");
            animalSpanAlive.textContent = String(node.num_animals_live + node.num_tank_animals_alive);
            const animalLabelTotal = document.createElement("label");
            animalLabelTotal.textContent = getTranslation("total") + ": ";
            const animalSpanTotal = document.createElement("span");
            animalSpanTotal.textContent = String(node.num_animals + node.num_tank_animals_released);
            animalDiv.appendChild(animalLabelAlive);
            animalDiv.appendChild(animalSpanAlive);
            animalDiv.appendChild(document.createTextNode(" ("));
            animalDiv.appendChild(animalLabelTotal);
            animalDiv.appendChild(animalSpanTotal);
            animalDiv.appendChild(document.createTextNode(")"));
            nodeDiv.appendChild(animalDiv);

            if (session.pyratConf.TERRESTRIAL) {
                /* num pups */
                const pupDiv = document.createElement("div");
                pupDiv.className = "pup_count";
                const pupLabelAlive = document.createElement("label");
                pupLabelAlive.textContent = getTranslation("Pups alive") + ": ";
                const pupSpanAlive = document.createElement("span");
                pupSpanAlive.textContent = String(node.num_pups_live);
                const pupLabelTotal = document.createElement("label");
                pupLabelTotal.textContent = getTranslation("total") + ": ";
                const pupSpanTotal = document.createElement("span");
                pupSpanTotal.textContent = String(node.num_pups);
                pupDiv.appendChild(pupLabelAlive);
                pupDiv.appendChild(pupSpanAlive);
                pupDiv.appendChild(document.createTextNode(" ("));
                pupDiv.appendChild(pupLabelTotal);
                pupDiv.appendChild(pupSpanTotal);
                pupDiv.appendChild(document.createTextNode(")"));
                nodeDiv.appendChild(pupDiv);

                /* num cages */
                const cageDiv = document.createElement("div");
                cageDiv.className = "cage_count";
                const cageLabel = document.createElement("label");
                cageLabel.textContent = getTranslation("Open cages") + ": ";
                const cageSpan = document.createElement("span");
                cageSpan.textContent = String(node.num_cages_total);
                cageDiv.appendChild(cageLabel);
                cageDiv.appendChild(cageSpan);
                nodeDiv.appendChild(cageDiv);
            }

            /* num tanks */
            if (session.pyratConf.AQUATIC) {
                const tankDiv = document.createElement("div");
                tankDiv.className = "tank_tanks";
                const tankLabel = document.createElement("label");
                tankLabel.textContent = getTranslation("Open tanks") + ": ";
                const tankSpan = document.createElement("span");
                tankSpan.textContent = String(node.num_tanks);
                tankDiv.appendChild(tankLabel);
                tankDiv.appendChild(tankSpan);
                nodeDiv.appendChild(tankDiv);
            }

            /* detail */
            const detailSpan = document.createElement("span");
            detailSpan.className = "icon_button icon_eye only_icon_img right";
            detailSpan.addEventListener("click", () => {
                frames.detailPopup.open(
                    getUrl(cgiScript("edit_strain.py"), { strainid: node.id }, { absoluteUrl: true }),
                );
            });
            actions.appendChild(detailSpan);

            if (node.id !== pedigree_data.root && node.parents && node.parents.length) {
                const pedigreeSpan = document.createElement("span");
                pedigreeSpan.className = "icon_button icon_pedigree only_icon_img right";
                pedigreeSpan.addEventListener("click", () => {
                    this.manager.subject(this.manager.kinds.strain_pedigree);
                    this.manager.subjectLabel(String(node.id));
                    this.manager.generateRequest();
                });
                actions.appendChild(pedigreeSpan);
            }

            if (actions.children.length) {
                nodeDiv.appendChild(actions);
            }
        }

        return nodeDiv;
    };

}

class ColonyPedigree {

    public graphRoot: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
    public kinds: { [key: string]: PedigreeKind };
    public error: Observable<boolean>;
    public message: Observable<string>;
    public depth: Observable<number>;
    public subject: Observable<PedigreeKind>;
    public subjectLabel: Observable<string>;
    public history: ObservableArray<[PedigreeKind, string]>;
    public requestData: Observable<Parameters<typeof ReportsService.getColonyPedigree>[0]>;
    public seed: FetchBackendExtended<Observable<{ pedigree: Pedigree }>>;
    public model: Computed<PedigreeKindModel>;
    public rankDir: Observable<"LR" | "TB" | "RL" | "BT">;
    public density: Observable<number>;
    public scale: Observable<number>;
    public border: number;
    public graph: Computed<d3.Selection<d3.BaseType, any, HTMLElement, any>>;

    constructor(args: Arguments, d3: any, dagreD3: any) {

        this.kinds = {
            animal_pedigree: {
                available: session.pyratConf.TERRESTRIAL,
                label: getTranslation("Animal"),
                placeholder: getTranslation("ID"),
                model: AnimalPedigreeModel,
                params: {
                    nearby: observable(undefined),
                },
                request: () => {
                    return {
                        "kind": "animal_pedigree",
                        "label": this?.subjectLabel(),
                        "nearby": this.kinds.animal_pedigree.params.nearby() || null,
                    };
                },
            },
            tank_pedigree: {
                available: session.pyratConf.AQUATIC,
                label: getTranslation("Tank"),
                placeholder: getTranslation("ID"),
                model: TankPedigreeModel,
                params: {
                    nearby: observable(undefined),
                },
                request: () => {
                    return {
                        "kind": "tank_pedigree",
                        "label": this?.subjectLabel(),
                        "nearby": this.kinds.animal_pedigree.params.nearby() || null,
                    };
                },
            },
            strain_pedigree: {
                available: session.userPermissions.pedigree_view_strain,
                label: getTranslation("Line / Strain"),
                placeholder: getTranslation("Name"),
                model: StrainPedigreeModel,
                params: {
                    autocomplete: args.strain_autocomplete,
                },
                request: () => {
                    return {
                        kind: "strain_pedigree",
                        label: this?.subjectLabel(),
                    };
                },
            },
        };

        this.graphRoot = d3.selectAll("#colony_pedigree #graph");
        this.error = observable(false);
        this.message = observable(getTranslation("Define a starting point to draw a pedigree"));

        this.subject = observable(this.kinds[args.kind]);
        getSessionItem("pedigree_history_last_subject").then((value: string) => {
            this.subject(this.kinds[args.kind] ||
                this.kinds[value] ||
                this.kinds.animal_pedigree);
        });

        this.subjectLabel = observable(args.label);
        getSessionItem("pedigree_history_last_subject_label").then((value: string) => {
            this.subjectLabel(args.label || value);
        });

        this.depth = observable(4);
        this.requestData = observable();

        if (this.kinds[args.kind] && args.label) {
            _.defer(() => {
                this.generateRequest();
            });
        }

        this.history = observableArray([]);
        this.requestData.subscribe(() => {
            this.history.push([this.subject(), this.subjectLabel()]);
            setSessionItem("pedigree_history_last_subject", _.findKey(this.kinds, this.subject()));
            setSessionItem("pedigree_history_last_subject_label", this.subjectLabel());
        });

        this.seed = observable().extend({
            rateLimit: 0,
            fetchBackend: () => {
                this.error(false);
                this.message(undefined);
                if (this.requestData()) {
                    return ReportsService.getColonyPedigree(this.requestData());
                }
            },
        });
        this.seed.onCatch = (error) => {
            this.error(true);
            this.message(error?.body?.detail || getTranslation("Unknown error when generating the pedigree"));
        };

        this.rankDir = observable("LR");  // LR = left-right, TD = top-down
        this.density = observable(50);
        this.scale = observable(100);
        this.border = 20;

        this.scale.subscribe(() => {
            const scaleElement = document.getElementById("scale_value_info");
            if (scaleElement) {
                scaleElement.style.display = "block";
                scaleElement.style.opacity = "1";
                setTimeout(() => {
                    const fadeEffect = setInterval(() => {
                        if (!scaleElement.style.opacity) {
                            scaleElement.style.opacity = "1";
                        }
                        if (parseFloat(scaleElement.style.opacity) > 0) {
                            scaleElement.style.opacity = (parseFloat(scaleElement.style.opacity) - 0.1).toString();
                        } else {
                            clearInterval(fadeEffect);
                            scaleElement.style.display = "none";
                        }
                    }, 50);
                }, 1000);
            }
        });

        this.density.subscribe(() => {
            const densityElement = document.getElementById("density_value_info");
            if (densityElement) {
                densityElement.style.display = "block";
                densityElement.style.opacity = "1";
                setTimeout(() => {
                    const fadeEffect = setInterval(() => {
                        if (!densityElement.style.opacity) {
                            densityElement.style.opacity = "1";
                        }
                        if (parseFloat(densityElement.style.opacity) > 0) {
                            densityElement.style.opacity = (parseFloat(densityElement.style.opacity) - 0.1).toString();
                        } else {
                            clearInterval(fadeEffect);
                            densityElement.style.display = "none";
                        }
                    }, 50);
                }, 1000);
            }
        });

        this.graph = computed(() => {

            // remove all old graphs
            this.graphRoot.selectAll("svg > *").remove();

            const seed = this.seed();
            if (seed?.pedigree) {

                const render = dagreD3.render();
                const graph = new dagreD3.graphlib.Graph().setGraph({
                    marginx: this.border,
                    marginy: this.border,
                    rankdir: this.rankDir(),
                    nodesep: this.density(),
                    ranksep: (Math.pow(this.density(), 2 * 1.06) + 1000) * 0.01,
                });
                const svg = this.graphRoot.select("svg");
                const inner = svg.append("g");

                svg.classed(seed.pedigree.kind, true);

                // add all the nodes
                const model = new this.kinds[seed.pedigree.kind].model(this);
                _.forEach(seed.pedigree.nodes, (node) => {
                    graph.setNode(String(model.nodeId(node)), {
                        "label": model.nodeLabel(seed.pedigree, node),
                        "class": model.nodeClass(seed.pedigree, node),
                    });
                });

                // define all the edges
                _.forEach(seed.pedigree.edges, (edge: Edge) => {
                    graph.setEdge(String(edge[0]), String(edge[1]), {
                        // possible curve options in https://github.com/d3/d3-shape#curves
                        curve: {
                            LR: d3.curveMonotoneX,
                            RL: d3.curveMonotoneX,
                            TB: d3.curveBasis,
                            BT: d3.curveBasis,
                        }[this.rankDir()],
                    });
                });

                // run the renderer
                render(inner, graph);

                // resize the svg element and the popup according to the graph (within certain limits)
                inner.attr("transform", "scale(" + this.scale() / 100 + ")");
                svg.attr("height", (graph.graph().height * this.scale() / 100) + 10);
                svg.attr("width", (graph.graph().width * (this.scale() / 100)));

                return svg;
            }

        });

    }

    public generateRequest = () => {
        this.requestData({ ...this.subject().request(), "depth": this.depth() });
    };

    public stepBack = () => {
        const step = this.history()[this.history().length - 2];
        this.subject(step[0]);
        this.subjectLabel(step[1]);
        this.generateRequest();
        this.history.splice(this.history().length - 2, 2);
    };

    public print = () => {
        window.print();
    };

    public scaleToFit = () => {
        if (this.graph()) {

            if (this.scale() !== 100) {
                this.scale(100);

            } else {

                // reset to 100 % to know if it is bigger
                this.scale(100);

                // calculate the scaling factor for bot dimensions
                const boxSize = this.graphRoot.node().getBoundingClientRect();
                // @ts-expect-error: getBBox is nor typed
                const svgSize = this.graphRoot.select("svg").node().getBBox();
                const boxHeight = boxSize.height - boxSize.top;
                const svnHeight = svgSize.height + (svgSize.y * 2);
                const boxWidth = boxSize.width - boxSize.left;
                const svnWidth = svgSize.width + (svgSize.x * 2);
                const heightScale = boxHeight / svnHeight;
                const widthScale = boxWidth / svnWidth;

                // set the lower of both scaling factor but never scale up
                if (heightScale >= widthScale && widthScale < 1) {
                    this.scale(Math.floor((widthScale * 100) / 5) * 5);
                } else if (widthScale >= heightScale && heightScale < 1) {
                    this.scale(Math.floor((heightScale * 100) / 5) * 5);
                }

            }
        }
    };

}


export const initColonyPedigree = (args: Arguments): void => {
    import("d3").then((d3) => {
        import("dagre-d3-es").then((dagreD3) => {
            applyBindings(new ColonyPedigree(args, d3, dagreD3));
        });
    });
};
