import * as ko from "knockout";

import {
    buttons,
    datepicker,
    DatepickerOptions,
} from "../../lib/flatpickr";
import { CheckExtended } from "../extensions/invalid";


interface PickerOptions {
    // The maximum selectable date. When set to null, there is no maximum.
    maxDate?: Date | string;
    // The minimum selectable date. When set to null, there is no minimum.
    minDate?: Date | string;
    // Enable the time picker.
    enableTime?: boolean;
    // Show the formatted date to the user, but store / load it as ISO8601.
    isoFormat?: boolean;
}

interface ElementAttributes {
    id?: string;
    name?: string;
    placeholder?: string;
}

interface Params {
    value: CheckExtended<ko.Observable<string>>;
    id?: string;
    name?: string;
    class?: string;
    placeholder?: string;
    clearButton?: boolean;
    css?: Record<string, string | ko.MaybeObservable<boolean>>;
    enabled?: ko.MaybeObservable<boolean>;
    options?: PickerOptions;
    valueUpdate?: "afterkeydown" | "input";
}


/**
 * Calendar Popup
 *
 * Provides datepicker (input field) using the jquery ui library.
 *
 * @property element - The associated date picker <input> field.
 *
 * @property class - Additional class name(s) that will be assigned to the date picker <input> field.
 *
 * @property css - JavaScript object with additional class name(s) as property, that will be assigned to
 *                 the date picker <input> field according to their values evaluate to true or false.
 *
 * @property enabled - if false, the date picker <input> field will be disabled (true by default).
 *
 * @property id - Specifies the id (attribute) of the date picker <input> field.
 *
 * @property name - Specifies the name (attribute) of the date picker <input> field.
 *
 * @property options - Additional options that will be passed to the date picker instance (@see PickerOptions).
 *
 * @property placeholder - Hint text that will be displayed in the date picker <input> field before user selects a value.
 *
 * @property clearButton - Show a button to clear the date picker <input> field.
 *
 * @property value - The associated date picker <input> field’s value.
 *
 * @property valueUpdate - Defines additional browser events knockout should use to detect changes besides the change event.
 *                        (e.g.: "input", "keyup", "keypress" or "afterkeydown")
 *
 * @example:
 *   <body>
 *      <ko-calendar-popup params="value: dateFrom,
 *                                 name: 'from_date',
 *                                 class: 'width_full',
 *                                 css: {invalid_data: dateFrom.isInvalid},
 *                                 options: {maxDate: 'today'}"></ko-calendar-popup>
 *   </body>
 */
class CalendarPopupViewModel {

    private readonly params: Params;
    private readonly elementAttributes: ElementAttributes;

    constructor(params: Params, componentElement: HTMLInputElement) {
        this.params = params;
        this.elementAttributes = {};

        if (params.id) {
            this.elementAttributes.id = params.id;
        }

        if (params.name) {
            this.elementAttributes.name = params.name;
        }

        if (params.placeholder) {
            this.elementAttributes.placeholder = params.placeholder;
        }

        const datepickerOptions: DatepickerOptions = {
            buttons: [
                buttons.today,
                ...(params.clearButton ? [buttons.clear] : []),
                buttons.close,
            ],
            allowInput: true,
            minDate: params?.options?.minDate,
            maxDate: params?.options?.maxDate,
            isoFormat: params?.options?.isoFormat,
            enableTime: params?.options?.enableTime,
        };

        setTimeout(() => {

            const picker = datepicker(componentElement, datepickerOptions);

            // follow changes to the bound value observable
            if (ko.isObservable(params.value)) {
                ko.computed(() => {
                    if (picker.setDate) {
                        picker.setDate(params.value());
                    }
                }, null, { disposeWhenNodeIsRemoved : componentElement });
            }

            // hide/destroy datepicker when corresponding node element is removed
            ko.utils.domNodeDisposal.addDisposeCallback(componentElement, () => {
                picker.destroy();
            });

        }, 0);

    }

}

export class CalendarPopupComponent {
    constructor() {
        return {
            viewModel: {
                createViewModel: (params: any, componentInfo: ko.components.ComponentInfo) => {
                    if (componentInfo.element.nodeName === "#comment") {
                        throw new Error("Only use this component through <ko-calendar-popup/> HTML tag.");
                    }
                    return new CalendarPopupViewModel(params, (componentInfo.element as HTMLElement).firstElementChild as HTMLInputElement);
                },
            },
            template: `
                <input data-bind="value: params.value,
                                  valueUpdate: params.valueUpdate,
                                  attr: elementAttributes,
                                  class: params.class || '',
                                  css: params.css || {},
                                  enable: params.enabled !== undefined ? params.enabled: true"
                       type="text" />
            `,
        };
    }
}
