/**
 * Show a popup to set the eartag for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    AnimalsService,
    CancelablePromise,
    GetAnimalEartagResponse,
    PupsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";
import {
    EmptyObject,
    isInvalidEartagPrefix,
    isInvalidEartagSuffix,
} from "../lib/utils";

import template from "./setEartag.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetEartagViewModel {
    private readonly dialog: HtmlDialog;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly animalPrefix: CheckExtended<Observable<string>>;
    private readonly animalSuffix: CheckExtended<Observable<string>>;
    private readonly animalNextFreeEartag: FetchBackendExtended<Observable<string>>;
    private readonly animalNextFreeEartagError: Observable<string>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly fetchInProgress = ko.observable(true);
    private readonly submitInProgress= ko.observable(false);
    public readonly seed: Observable<GetAnimalEartagResponse | undefined> = ko.observable();


    constructor(dialog: HtmlDialog, params: Params) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.animalPrefix = ko.observable()
            .extend({ normalize: _.trim })
            .extend({
                invalid: (v) => {
                    return !v || isInvalidEartagPrefix(v);
                },
            });

        this.animalSuffix = ko.observable()
            .extend({ normalize: _.trim })
            .extend({
                invalid: (v) => {
                    return !v || isInvalidEartagSuffix(v);
                },
            });

        this.animalNextFreeEartagError = ko.observable();

        // load next free suffix according to selected prefix
        // do not send the ajax call until config.PREFEARTAGSIZE characters are given
        this.animalNextFreeEartag = ko.observable().extend({
            fetchBackend: () => {
                this.animalNextFreeEartagError("");
                if (this.animalPrefix() && this.animalPrefix.isValid()) {
                    return AnimalsService.getNextFreeEartag({ eartagPrefix: this.animalPrefix() });
                }
            },
        });
        this.animalNextFreeEartag.onCatch = (error) => {
            this.animalNextFreeEartagError(error?.body?.detail);
        };

        let request: CancelablePromise<GetAnimalEartagResponse>;
        if (this.animalId) {
            request = AnimalsService.getAnimalEartag({ animalId: this.animalId });
        } else if (this.pupId) {
            request = PupsService.getPupEartag({ pupId: this.pupId });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then((response) => {
                this.seed(response);
                this.animalPrefix(response.prefix);
                this.animalSuffix(response.suffix);
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.fetchInProgress() ||
                     this.animalPrefix.isInvalid() ||
                     this.animalSuffix.isInvalid());
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public useNextFreeEartag = () => {
        if (this.animalNextFreeEartag()) {
            // extract next free suffix from next free eartag
            const suffix = this.animalNextFreeEartag().split("-")[1];
            this.animalSuffix(suffix);
        }
    };

    public cancel = () => this.dialog.close();

    public submit = () => {
        this.submitInProgress(true);

        let request: CancelablePromise<EmptyObject>;
        const params = {
            prefix: this.animalPrefix(),
            suffix: this.animalSuffix(),
        };

        if (this.animalId) {
            request = AnimalsService.setAnimalEartag({ animalId: this.animalId, ...params });
        } else if (this.pupId) {
            request = PupsService.setPupEartag({ pupId: this.pupId, ...params });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then(() => {
                this.dialog.close();
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
                notifications.showNotification(getTranslation("ID updated"), "success");
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };
}

export const showSetEartag = htmlDialogStarter(SetEartagViewModel, template, params => ({
    name: "SetEartag",
    width: 300,
    title: params.title,
    position: { anchor: params.eventTarget },
}));
