/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_applySpermQuickselectActions } from '../models/Body_applySpermQuickselectActions';
import type { CryopreservationData } from '../models/CryopreservationData';
import type { OocyteDonor } from '../models/OocyteDonor';
import type { QualityProperties } from '../models/QualityProperties';
import type { SpermDetails } from '../models/SpermDetails';
import type { SpermEvent } from '../models/SpermEvent';
import type { SpermQuickselectActionsResponse } from '../models/SpermQuickselectActionsResponse';
import type { SpermQuickselectSeed } from '../models/SpermQuickselectSeed';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SpermService {

    /**
     * Get Sperm Details
     * Get sperm details.
     * @returns SpermDetails Successful Response
     * @throws ApiError
     */
    public static getSpermDetails({
        spermId,
    }: {
        spermId: number,
    }): CancelablePromise<SpermDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sperm/{sperm_id}/details',
            path: {
                'sperm_id': spermId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sperm History
     * Get sperm history.
     * @returns SpermEvent Successful Response
     * @throws ApiError
     */
    public static getSpermHistory({
        spermId,
    }: {
        spermId: number,
    }): CancelablePromise<Array<SpermEvent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sperm/{sperm_id}/history',
            path: {
                'sperm_id': spermId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sperm Qualities
     * Get sperm quality options.
     * @returns QualityProperties Successful Response
     * @throws ApiError
     */
    public static getSpermQualitiesForSetting(): CancelablePromise<Array<QualityProperties>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sperm/qualities/for_setting',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Next Free Straw Labels
     * Get next free straw labels for cryopreserved sperm.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getNextFreeSpermStrawLabels({
        count,
    }: {
        count: number,
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sperm/straw_labels/for_setting',
            query: {
                'count': count,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Oocyte Donors By Strain
     * Get female animals of a certain strain for selection as oocyte donors.
     * @returns OocyteDonor Successful Response
     * @throws ApiError
     */
    public static getOocyteDonorsByStrainForSetting({
        strainId,
    }: {
        strainId: number,
    }): CancelablePromise<Array<OocyteDonor>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sperm/oocyte_donors_by_strain/{strain_id}/for_setting',
            path: {
                'strain_id': strainId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Sperm
     * Import sperm.
     * @returns number Successful Response
     * @throws ApiError
     */
    public static importSperm({
        volume,
        originId,
        ownerId,
        projectId,
        strainId,
        qualityRating,
        qualityProgressivePortion,
        cryopreserved = false,
        requestBody,
    }: {
        volume: number,
        originId?: (number | null),
        ownerId?: (number | null),
        projectId?: (number | null),
        strainId?: (number | null),
        qualityRating?: ('very_poor' | 'poor' | 'fair' | 'good' | 'very_good' | null),
        qualityProgressivePortion?: (number | null),
        cryopreserved?: boolean,
        requestBody?: (CryopreservationData | null),
    }): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sperm/import',
            query: {
                'volume': volume,
                'origin_id': originId,
                'owner_id': ownerId,
                'project_id': projectId,
                'strain_id': strainId,
                'quality_rating': qualityRating,
                'quality_progressive_portion': qualityProgressivePortion,
                'cryopreserved': cryopreserved,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Quickselect Seed
     * Get seed for sperm Quickselect.
     * @returns SpermQuickselectSeed Successful Response
     * @throws ApiError
     */
    public static getSpermQuickselectSeed({
        requestBody,
    }: {
        requestBody: Array<number>,
    }): CancelablePromise<SpermQuickselectSeed> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sperm/quickselect/seed',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Apply Quickselect
     * Apply Quickselect actions to selected sperm IDs.
     * @returns SpermQuickselectActionsResponse Successful Response
     * @throws ApiError
     */
    public static applySpermQuickselectActions({
        requestBody,
    }: {
        requestBody: Body_applySpermQuickselectActions,
    }): CancelablePromise<SpermQuickselectActionsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sperm/quickselect/apply',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
