/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_getWellplatePickerOptions } from '../models/Body_getWellplatePickerOptions';
import type { ExistentMicroWellPlateDialogOptions } from '../models/ExistentMicroWellPlateDialogOptions';
import type { MicroWellPlateFillDirection } from '../models/MicroWellPlateFillDirection';
import type { MicroWellPlateMode } from '../models/MicroWellPlateMode';
import type { MicroWellPlatePickerOptions } from '../models/MicroWellPlatePickerOptions';
import type { NewMicroWellPlateDialogOptions } from '../models/NewMicroWellPlateDialogOptions';
import type { WellplateExportSeed } from '../models/WellplateExportSeed';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WellplatesService {

    /**
     * Get Wellplate Export Seed Endpoint
     * Get the seed for exporting wellplates.
     * @returns WellplateExportSeed Successful Response
     * @throws ApiError
     */
    public static getWellplatesExportSeed(): CancelablePromise<WellplateExportSeed> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wellplates/export_seed',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dialog Options Endpoint
     * Get the options for the wellplate picker.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getWellplatesDialogSeed({
        wellplateId,
    }: {
        wellplateId?: (number | null),
    }): CancelablePromise<(ExistentMicroWellPlateDialogOptions | NewMicroWellPlateDialogOptions)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wellplates/dialog_seed',
            query: {
                'wellplate_id': wellplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Wellplate Endpoint
     * Add a wellplate.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addWellplate({
        formatId,
        code,
        reference,
        responsibleId,
        fillDirection,
        mode,
    }: {
        formatId: number,
        code: string,
        reference: string,
        responsibleId: number,
        fillDirection?: MicroWellPlateFillDirection,
        mode?: (MicroWellPlateMode | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wellplates/',
            query: {
                'format_id': formatId,
                'code': code,
                'reference': reference,
                'responsible_id': responsibleId,
                'fill_direction': fillDirection,
                'mode': mode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Wellplate Endpoint
     * Update a wellplate.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateWellplate({
        wellplateId,
        formatId,
        code,
        reference,
        responsibleId,
        fillDirection,
        mode,
    }: {
        wellplateId: number,
        formatId: number,
        code: string,
        reference: string,
        responsibleId: number,
        fillDirection?: MicroWellPlateFillDirection,
        mode?: (MicroWellPlateMode | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wellplates/{wellplate_id}',
            path: {
                'wellplate_id': wellplateId,
            },
            query: {
                'format_id': formatId,
                'code': code,
                'reference': reference,
                'responsible_id': responsibleId,
                'fill_direction': fillDirection,
                'mode': mode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Wellplate Endpoint
     * Delete a wellplate.
     *
     * Requirements for deletion:
     * - wellplate exists (for given wellplate_id) and
     * - wellplate has no related animals/pups and
     * - wellplate status is "active" or "ready"
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteWellplate({
        wellplateId,
    }: {
        wellplateId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wellplates/{wellplate_id}',
            path: {
                'wellplate_id': wellplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Used Wellplate Endpoint
     * Update a wellplate that has some animals assigned.
     *
     * In that case not all the fields can be modified.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUsedWellplate({
        wellplateId,
        responsibleId,
    }: {
        wellplateId: number,
        responsibleId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wellplates/{wellplate_id}/used',
            path: {
                'wellplate_id': wellplateId,
            },
            query: {
                'responsible_id': responsibleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Wellplate Ready Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setWellplateReady({
        wellplateId,
    }: {
        wellplateId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wellplates/{wellplate_id}:ready',
            path: {
                'wellplate_id': wellplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Wellplate Exported Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setWellplateExported({
        wellplateId,
    }: {
        wellplateId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wellplates/{wellplate_id}:exported',
            path: {
                'wellplate_id': wellplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Wellplate Active Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static reopenWellplate({
        wellplateId,
    }: {
        wellplateId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wellplates/{wellplate_id}:open',
            path: {
                'wellplate_id': wellplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Wellplate Closed Endpoint
     * Close a wellplate.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setWellplateClosed({
        wellplateId,
    }: {
        wellplateId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wellplates/{wellplate_id}:closed',
            path: {
                'wellplate_id': wellplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Invalidate Wellplate Position Endpoint
     * Invalidate a wellplate position.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static invalidateWellplatePosition({
        wellplateId,
        wellplatePosition,
    }: {
        wellplateId: number,
        wellplatePosition: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wellplates/{wellplate_id}/{wellplate_position}',
            path: {
                'wellplate_id': wellplateId,
                'wellplate_position': wellplatePosition,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Picker Options Endpoint
     * Get the options for the wellplate picker.
     * @returns MicroWellPlatePickerOptions Successful Response
     * @throws ApiError
     */
    public static getWellplatePickerOptions({
        wellplateId,
        requestBody,
    }: {
        wellplateId: number,
        requestBody: Body_getWellplatePickerOptions,
    }): CancelablePromise<MicroWellPlatePickerOptions> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wellplates/{wellplate_id}/picker_options',
            path: {
                'wellplate_id': wellplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
