/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_applyCageQuickselectActions } from '../models/Body_applyCageQuickselectActions';
import type { CageQuickselectActionsResponse } from '../models/CageQuickselectActionsResponse';
import type { CageQuickselectSeed } from '../models/CageQuickselectSeed';
import type { GetCageCategoryResponse } from '../models/GetCageCategoryResponse';
import type { GetCageLabelResponse } from '../models/GetCageLabelResponse';
import type { GetCageLocationResponse } from '../models/GetCageLocationResponse';
import type { GetCageNumberResponse } from '../models/GetCageNumberResponse';
import type { GetCageOwnerResponse } from '../models/GetCageOwnerResponse';
import type { GetCagePositionResponse } from '../models/GetCagePositionResponse';
import type { GetCageResponsibleResponse } from '../models/GetCageResponsibleResponse';
import type { NextCagenumberSuffix } from '../models/NextCagenumberSuffix';
import type { SetCageLocationResponse } from '../models/SetCageLocationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CagesService {

    /**
     * Get Next Free Cagenumber Suffix
     * Get next unused cagenumber suffix with the given prefix.
     * @returns NextCagenumberSuffix Successful Response
     * @throws ApiError
     */
    public static getNextFreeCagenumberSuffix({
        prefix,
    }: {
        prefix: string,
    }): CancelablePromise<NextCagenumberSuffix> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/next_free_cagenumber_suffix',
            query: {
                'prefix': prefix,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Quickselect Seed
     * Get seed for cage quickselect.
     * @returns CageQuickselectSeed Successful Response
     * @throws ApiError
     */
    public static getCageQuickselectSeed({
        requestBody,
    }: {
        requestBody: Array<number>,
    }): CancelablePromise<CageQuickselectSeed> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/quickselect/seed',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Apply Quickselect
     * Apply Quickselect actions for selected cage IDs.
     * @returns CageQuickselectActionsResponse Successful Response
     * @throws ApiError
     */
    public static applyCageQuickselectActions({
        requestBody,
    }: {
        requestBody: Body_applyCageQuickselectActions,
    }): CancelablePromise<CageQuickselectActionsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/quickselect/apply',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Category Endpoint
     * Get category for a cage.
     * @returns GetCageCategoryResponse Successful Response
     * @throws ApiError
     */
    public static getCageCategory({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<GetCageCategoryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/{cage_id}/category',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Category Endpoint
     * Set category for a cage.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setCageCategory({
        cageId,
        categoryId,
    }: {
        cageId: number,
        categoryId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/{cage_id}/category',
            path: {
                'cage_id': cageId,
            },
            query: {
                'category_id': categoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Incompatible Sanitary Status Locations Endpoint
     * Get incompatible sanitary status locations for cages based on given new cage or rack id.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getIncompatibleSanitaryStatusLocations({
        cageIds,
        newCageId,
        newRackId,
    }: {
        cageIds: Array<number>,
        newCageId?: (number | null),
        newRackId?: (number | null),
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/incompatible_sanitary_status_locations',
            query: {
                'cage_ids': cageIds,
                'new_cage_id': newCageId,
                'new_rack_id': newRackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Label Endpoint
     * Get label for a cage.
     * @returns GetCageLabelResponse Successful Response
     * @throws ApiError
     */
    public static getCageLabel({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<GetCageLabelResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/{cage_id}/label',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Label Endpoint
     * Set category for a cage.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setCageLabel({
        cageId,
        label = '',
    }: {
        cageId: number,
        label?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/{cage_id}/label',
            path: {
                'cage_id': cageId,
            },
            query: {
                'label': label,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Location Endpoint
     * Get location for a cage.
     * @returns GetCageLocationResponse Successful Response
     * @throws ApiError
     */
    public static getCageLocation({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<GetCageLocationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/{cage_id}/location',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Location Endpoint
     * Set category for a cage.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setCageLocation({
        cageId,
        rackId,
        cagePosition = '',
        confirmedSanitaryStatus = false,
    }: {
        cageId: number,
        rackId?: (number | null),
        cagePosition?: string,
        confirmedSanitaryStatus?: boolean,
    }): CancelablePromise<(SetCageLocationResponse | null)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/{cage_id}/location',
            path: {
                'cage_id': cageId,
            },
            query: {
                'rack_id': rackId,
                'cage_position': cagePosition,
                'confirmed_sanitary_status': confirmedSanitaryStatus,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Number Endpoint
     * Get number for a cage.
     * @returns GetCageNumberResponse Successful Response
     * @throws ApiError
     */
    public static getCageNumber({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<GetCageNumberResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/{cage_id}/number',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Number Endpoint
     * Set number for a cage.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setCageNumber({
        cageId,
        prefix,
        suffix,
    }: {
        cageId: number,
        prefix: string,
        suffix?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/{cage_id}/number',
            path: {
                'cage_id': cageId,
            },
            query: {
                'prefix': prefix,
                'suffix': suffix,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Owner Endpoint
     * Get owner for a cage.
     * @returns GetCageOwnerResponse Successful Response
     * @throws ApiError
     */
    public static getCageOwner({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<GetCageOwnerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/{cage_id}/owner',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Owner Endpoint
     * Set owner for a cage.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setCageOwner({
        cageId,
        ownerId,
    }: {
        cageId: number,
        ownerId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/{cage_id}/owner',
            path: {
                'cage_id': cageId,
            },
            query: {
                'owner_id': ownerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Position Endpoint
     * Get position for a cage.
     * @returns GetCagePositionResponse Successful Response
     * @throws ApiError
     */
    public static getCagePosition({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<GetCagePositionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/{cage_id}/position',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Position Endpoint
     * Set position for a cage.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setCagePosition({
        cageId,
        position,
    }: {
        cageId: number,
        position?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/{cage_id}/position',
            path: {
                'cage_id': cageId,
            },
            query: {
                'position': position,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Responsible Endpoint
     * Get responsible for a cage.
     * @returns GetCageResponsibleResponse Successful Response
     * @throws ApiError
     */
    public static getCageResponsible({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<GetCageResponsibleResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/{cage_id}/responsible',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Responsible Endpoint
     * Set responsible for a cage.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setCageResponsible({
        cageId,
        responsibleId,
    }: {
        cageId: number,
        responsibleId?: (number | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/{cage_id}/responsible',
            path: {
                'cage_id': cageId,
            },
            query: {
                'responsible_id': responsibleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
