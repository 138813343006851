/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuildingMapRack } from '../models/BuildingMapRack';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BuildingMapService {

    /**
     * Get Rack Details Endpoint
     * @returns BuildingMapRack Successful Response
     * @throws ApiError
     */
    public static getBuildingMapRackDetails({
        rackIds,
    }: {
        rackIds: Array<number>,
    }): CancelablePromise<Array<BuildingMapRack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/building_map/rack',
            query: {
                'rack_ids': rackIds,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move Cage Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static moveBuildingMapCage({
        cageId,
        targetRackId,
        targetRackPosition,
        confirmedSanitaryStatus = false,
    }: {
        cageId: number,
        targetRackId: number,
        targetRackPosition: string,
        confirmedSanitaryStatus?: boolean,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/building_map/move_cage',
            query: {
                'cage_id': cageId,
                'target_rack_id': targetRackId,
                'target_rack_position': targetRackPosition,
                'confirmed_sanitary_status': confirmedSanitaryStatus,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Swap Cages Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static swapBuildingMapCages({
        firstCageId,
        secondCageId,
    }: {
        firstCageId: number,
        secondCageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/building_map/swap_cages',
            query: {
                'first_cage_id': firstCageId,
                'second_cage_id': secondCageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move Tank Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static moveBuildingMapTank({
        tankId,
        targetRackId,
        targetRackPosition,
    }: {
        tankId: number,
        targetRackId: number,
        targetRackPosition: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/building_map/move_tank',
            query: {
                'tank_id': tankId,
                'target_rack_id': targetRackId,
                'target_rack_position': targetRackPosition,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Swap Tanks Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static swapBuildingMapTanks({
        firstTankId,
        secondTankId,
    }: {
        firstTankId: number,
        secondTankId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/building_map/swap_tanks',
            query: {
                'first_tank_id': firstTankId,
                'second_tank_id': secondTankId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
