import * as ko from "knockout";

import {
    IdLabelProperty,
    ListFilterDefinition,
    WellplatesService,
} from "../../backend/v1";
import {
    showMicrowellPlateDetails,
    showMicrowellPlateExport,
} from "../../dialogs";
import { writeException } from "../../lib/excepthook";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    SearchBox,
    SearchMode,
    setListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import {
    getUrl,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./wellplateListFilter.html";

interface WellplateListViewArguments extends ListViewArguments {
    export_args: any;
    search_mode: SearchMode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WellplateListFilters = (filter: ListFilterModel) => ({
    format_id: class extends ListFilterItem {
        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((v: IdLabelProperty) => v.label);
        }
    },

    status: class extends ListFilterItem {
        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((v: { name: string; label: string }) => v.label);
        }
    },

    code: ListFilterItem,

    reference: ListFilterItem,

    responsible_id: class extends ListFilterItem {
        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((v: {
                userid: number;
                fullname: string;
                status: "active" | "inactive";
            }) => v.fullname);
        }
    },

    page_size: ListFilterItem,
});

class WellplateList {
    private listView: ListView;
    private args: WellplateListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: WellplateListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: WellplateListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Wellplate filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("create-button", () => {
            showMicrowellPlateDetails({
                reloadCallback: () => this.reload(),
            });
        });

        this.listView.onMenuBoxClick("export-wellplate", () => {
            showMicrowellPlateExport({
                reloadCallback: () => this.reload(),
            });
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, {
                show_print: "true",
            }));
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.export_args,
            });
        });

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        // Table Body

        this.listView.onCellClick("td.clickable", (args) => {
            this.listView.highlightRow(args.rowId);

            showMicrowellPlateDetails({
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
                wellplateId: parseInt(args.rowId, 10),
            });
        });

        // set_wellplate_exported

        this.listView.onCellClick("td.action input.set_wellplate_ready", (args) => {
            args.element.classList.add("loading");
            WellplatesService.setWellplateReady({ wellplateId: parseInt(args.rowId, 10) })
                .then(() => {
                    this.reload(args.rowId);
                    notifications.showNotification(getTranslation("Wellplate marked ready"), "success");
                })
                .catch((error) => {
                    if (typeof error?.body?.detail == "string") {
                        notifications.showNotification(error.body.detail, "error");
                    } else {
                        notifications.showNotification(getTranslation("General error."), "error");
                        writeException(error);
                    }
                })
                .finally(() => args.element.classList.remove("loading"));
        });

        this.listView.onCellClick("td.action input.set_wellplate_exported", (args) => {
            args.element.classList.add("loading");
            WellplatesService.setWellplateExported({ wellplateId: parseInt(args.rowId, 10) })
                .then(() => {
                    this.reload(args.rowId);
                    notifications.showNotification(getTranslation("Wellplate closed"), "success");
                })
                .catch((error) => {
                    if (typeof error?.body?.detail == "string") {
                        notifications.showNotification(error.body.detail, "error");
                    } else {
                        notifications.showNotification(getTranslation("General error."), "error");
                        writeException(error);
                    }
                })
                .finally(() => args.element.classList.remove("loading"));
        });

        this.listView.onCellClick("td.action input.set_wellplate_closed", (args) => {
            args.element.classList.add("loading");
            WellplatesService.setWellplateClosed({ wellplateId: parseInt(args.rowId, 10) })
                .then(() => {
                    this.reload(args.rowId);
                    notifications.showNotification(getTranslation("Wellplate closed"), "success");
                })
                .catch((error) => {
                    if (typeof error?.body?.detail == "string") {
                        notifications.showNotification(error.body.detail, "error");
                    } else {
                        notifications.showNotification(getTranslation("General error."), "error");
                        writeException(error);
                    }
                })
                .finally(() => args.element.classList.remove("loading"));
        });

        this.listView.onCellClick("td.action input.delete_wellplate", (args) => {
            args.element.classList.add("loading");
            WellplatesService.deleteWellplate({ wellplateId: parseInt(args.rowId, 10) })
                .then(() => {
                    this.reload(args.rowId);
                    notifications.showNotification(getTranslation("Wellplate deleted"), "success");
                })
                .catch((error) => {
                    if (typeof error?.body?.detail == "string") {
                        notifications.showNotification(error.body.detail, "error");
                    } else {
                        notifications.showNotification(getTranslation("General error."), "error");
                        writeException(error);
                    }
                })
                .finally(() => args.element.classList.remove("loading"));
        });

        this.listView.onCellClick("td.action input.set_wellplate_active", (args) => {
            args.element.classList.add("loading");
            WellplatesService.reopenWellplate({ wellplateId: parseInt(args.rowId, 10) })
                .then(() => {
                    this.reload(args.rowId);
                    notifications.showNotification(getTranslation("Wellplate reopened"), "success");
                })
                .catch((error) => {
                    if (typeof error?.body?.detail == "string") {
                        notifications.showNotification(error.body.detail, "error");
                    } else {
                        notifications.showNotification(getTranslation("General error."), "error");
                        writeException(error);
                    }
                })
                .finally(() => args.element.classList.remove("loading"));
        });

        this.listView.onCellClick("td.used_position_count a.animal_list_link", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                animalid: JSON.parse(args.element.dataset.animalIds),
            });
        });

        this.listView.onCellClick("td.used_position_count a.pup_list_link", (args) => {
            mainMenu.openAndResetListFilter("get_pup_list", {
                pupid: JSON.parse(args.element.dataset.pupIds),
            });
        });

        // initialize the barcode searchbox
        const barcodeFilterElement = listViewElement.querySelector(".searchbox .barcode_filter");
        if (barcodeFilterElement) {
            new SearchBox({
                element: barcodeFilterElement.parentElement,
                searchCode: "barcode",
                searchMode: args.search_mode,
                onSubmitCallback: (value) => {
                    setListFilter(args.view_name, {
                        code: value,
                    });
                },
            });
        }
    }

    public reload = (rowId?: string) => {
        this.listView.reload({
            flashRowId: rowId,
        });
    };

    public handleRequestError = () => {
        notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
    };
}

export const initWellplateList = (args: WellplateListViewArguments): void => {
    new WellplateList(document.querySelector("div.listview"), args);
};
