/**
 * Show the sperm import pup-up.
 *
 * @param reloadCallback
 *        Function to call when data has been applied and popup is closed
 *        (e.g. to reload a list to display new data).
 *
 */

import * as ko from "knockout";

import {
    BasicUser,
    CryotanksService,
    IdNameProperty,
    OriginsService,
    ProjectForSetting,
    ProjectsService,
    QualityProperties,
    SpermService,
    StrainOption,
    StrainsService,
    UsersService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    checkDecimal,
    isInvalidCalendarDate,
    normalizeDate,
} from "../lib/utils";

import template from "./spermImport.html";
import "./transgenicImport.scss";

interface Params {
    reloadCallback?: () => void;
}

interface StrawLabel {
    label: ko.Observable<string>;
    origin: ko.Observable<"generated" | "progress" | "custom">;
    position: number;
}

class SpermImportViewModel {
    private dialog: HtmlDialog;
    private reloadRequired: ko.Observable<boolean>;

    private loadInProgress: ko.Observable<boolean>;
    private origins: ko.ObservableArray<IdNameProperty>;
    private owners: ko.ObservableArray<BasicUser>;
    private projects: ko.ObservableArray<ProjectForSetting>;
    private strains: ko.ObservableArray<StrainOption>;
    private spermQualityRatings: ko.ObservableArray<QualityProperties>;
    private cryotanks: ko.ObservableArray<IdNameProperty>;
    private volume: CheckExtended<ko.Observable<number>>;  // total volume of imported sperm
    private originId: CheckExtended<ko.Observable<number>>;
    private ownerId: CheckExtended<ko.Observable<number>>;
    private projectId: CheckExtended<ko.Observable<number>>;
    private strainId: ko.Observable<number>;
    private rating: ko.Observable<"very_poor" | "poor" | "fair" | "good" | "very_good">;
    private progressivePortion: CheckExtended<ko.Observable<number>>;
    private cryopreserved: ko.Observable<boolean>;
    private freezeDate: CheckExtended<ko.Observable<string>>;
    private strawCount: CheckExtended<ko.Observable<number>>;  // how many straws
    private spermVolumePerStraw: CheckExtended<ko.Observable<number>>;  // volume of sperm per straw
    private strawVolumeInput: ko.Observable<string>;  // sperm volumes of each straw as space separated "list"
    private strawLabels: CheckExtended<ko.ObservableArray<StrawLabel>>;  // labels for each straw, `strawLabels.length` === `strawCount` === `strawVolumeInput.length`
    private strawVolumes: CheckExtended<ko.PureComputed<number[]>>;  // content of `strawVolumeInput` as list of numbers
    private cryotankId: ko.Observable<number>;
    private cryotankPath: CheckExtended<ko.ObservableArray<string>>;
    private allValid: ko.PureComputed<boolean>;
    private submitInProgress: ko.Observable<boolean>;
    private importedSpermIds: ko.ObservableArray<number>;

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;
        this.reloadRequired = ko.observable(false);

        this.dialog.addOnClose(() => {
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        this.loadInProgress = ko.observable(true);
        this.origins = ko.observableArray();
        this.owners = ko.observableArray();
        this.projects = ko.observableArray();
        this.strains = ko.observableArray();
        this.spermQualityRatings = ko.observableArray();
        this.cryotanks = ko.observableArray();
        Promise.all([
            OriginsService.getOriginsForSetting().then((response) => this.origins(response)),
            UsersService.getActiveOwners().then((response) => this.owners(response)),
            ProjectsService.getProjectsForSetting().then((response) => this.projects(response)),
            StrainsService.getStrainsForSetting({
                status: "available",
                permittedUser: session.aliasId ? [session.aliasId] : undefined,
            }).then((response) => this.strains(response)),
            SpermService.getSpermQualitiesForSetting().then((response) => this.spermQualityRatings(response)),
            CryotanksService.getCryotanksForSetting().then((response) => this.cryotanks(response)),
        ]).catch(this.handleErrorResponse).finally(() => this.loadInProgress(false));

        this.volume = ko.observable().extend({
            invalid: (v) => {
                return !(v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0));
            },
        });
        this.originId = ko.observable().extend({
            invalid: (v) => !v,
        });
        this.ownerId = ko.observable(session.aliasId).extend({
            invalid: (v) => !v,
        });
        this.projectId = ko.observable().extend({
            invalid: (v) => session.pyratConf.TRANSGENIC_MANDATORY_PROJECT ? !v : false,
        });
        this.strainId = ko.observable();
        this.rating = ko.observable();
        this.progressivePortion = ko.observable().extend({
            invalid: (v) => {
                return !(v === undefined ||
                         v >= 0 && v <= 100 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0));
            },
        });

        this.cryopreserved = ko.observable(false);
        this.freezeDate = ko.observable().extend({
            normalize: normalizeDate,
            invalid: (v) => !(v ? !isInvalidCalendarDate(v) : true),
        });

        this.strawCount = ko.observable().extend({
            invalid: (v) => {
                return !((v === undefined ||
                          v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0)) &&
                         (!this.spermVolumePerStraw() || v > 0));
            },
        });
        this.spermVolumePerStraw = ko.observable().extend({
            invalid: (v) => {
                return !((v === undefined ||
                          v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0)) &&
                         (!this.strawCount() || v > 0));
            },
        });
        this.strawVolumeInput = ko.observable().extend({
            rateLimit: {
                timeout: 500,
                method: "notifyWhenChangesStop",
            },
        });
        this.strawLabels = ko.observableArray().extend({
            invalid: (v) => {
                const duplicateStrawLabels = v.map((label) => {
                    return String(label.label()).trim();
                }).filter((label, index, strawLabels) => {
                    return label.length && strawLabels.indexOf(label) !== index;
                });

                if (v?.some((label) => label.origin() === "progress")) {
                    return getTranslation("Please wait, ... straw labels still loading");
                }

                if (duplicateStrawLabels.length) {
                    return getTranslation("Duplicate straw labels") + ": " + duplicateStrawLabels.join(", ");
                }

                return false;
            },
        });
        this.strawVolumes = ko.pureComputed(() => {
            return (this.strawVolumeInput() || "").split(/[-/\\_;., ]/).filter((volume) => volume).map((volume) => parseInt(volume, 10));
        }).extend({
            invalid: (v) => {
                return !(v.length && v.every((volume) => volume > 0) && v.reduce((sum, volume) => sum + volume, 0) === this.volume());
            },
        });
        this.strawVolumes.subscribe((volumes) => {
            const labels = this.strawLabels();
            const customLabels = labels.filter((label) => label.origin() === "custom");
            let i;

            for (i = labels.length; i < volumes.length; i++) {
                labels.push({
                    label: ko.observable("..."),
                    origin: ko.observable("progress"),
                    position: i,
                });
            }
            for (i = labels.length; i > volumes.length; i--) {
                labels[i - 1] = {
                    label: ko.observable("..."),
                    origin: ko.observable("progress"),
                    position: i,
                };
            }
            this.strawLabels(labels);

            SpermService.getNextFreeSpermStrawLabels({ count: volumes.length }).then((response) => {
                const generatedLabels: Array<StrawLabel> = response.map((label: string, i: number) => {
                    return {
                        label: ko.observable(String(label)),
                        origin: ko.observable("generated"),
                        position: i,
                    };
                });

                // combine generated labels and custom labels
                let combinedLabels: Array<StrawLabel> = generatedLabels.concat(customLabels.filter((label) => generatedLabels.indexOf(label) === -1));
                // the custom labels overwrite the generated labels on duplicate positions
                combinedLabels = Object.values(combinedLabels.reduce((memo, label) => {
                    return { ...memo, [label.position]: label };
                }, {}));
                // sort by position
                this.strawLabels(combinedLabels.sort((a, b) => a.position - b.position));
            }).catch(this.handleErrorResponse);

            this.volume(volumes.reduce((sum, volume) => sum + volume, 0));
        });

        this.cryotankId = ko.observable();
        this.cryotankPath = ko.observableArray().extend({
            invalid: (v) => Boolean(this.cryotankId()) && !v.length,
        });
        this.cryotankId.subscribe(() => {
            this.cryotankPath([]);
        });

        this.allValid = ko.pureComputed(() => {
            if (this.volume.isInvalid()) {
                return false;
            }
            if (this.originId.isInvalid()) {
                return false;
            }
            if (this.ownerId.isInvalid()) {
                return false;
            }
            if (this.projectId.isInvalid()) {
                return false;
            }
            if (this.progressivePortion.isInvalid()) {
                return false;
            }
            if (this.cryopreserved()) {
                if (this.freezeDate.isInvalid()) {
                    return false;
                }
                if (this.strawLabels.isInvalid()) {
                    return false;
                }
                if (this.strawVolumes.isInvalid()) {
                    return false;
                }
                if (this.strawLabels().length != this.strawVolumes().length) {
                    return false;
                }
                if (this.cryotankPath.isInvalid()) {
                    return false;
                }
            }

            return true;
        });

        this.submitInProgress = ko.observable(false);
        this.importedSpermIds = ko.observableArray();
    }

    private fillStrawVolumes = () => {
        const volumes = [];
        let i;

        for (i = 0; i < this.strawCount(); i++) {
            volumes.push(this.spermVolumePerStraw());
        }

        this.strawVolumeInput(volumes.join(" "));
    };

    private toggleLabel = (label: string) => {
        const toggledStrawLabels = this.strawLabels().map((strawLabel) => {
            if (strawLabel.label() === label) {
                strawLabel.origin("custom");
            }
            return strawLabel;
        });

        this.strawLabels.notifySubscribers(toggledStrawLabels);
    };

    private serialize = () => {
        return {
            volume: this.volume() || null,
            originId: this.originId() || null,
            ownerId: this.ownerId() || null,
            projectId: this.projectId() || null,
            strainId: this.strainId() || null,
            qualityRating: this.rating() || null,
            qualityProgressivePortion: this.progressivePortion() || null,
            cryopreserved: this.cryopreserved(),
            requestBody: this.cryopreserved() ? {
                cryopreservation_freeze_date: this.freezeDate() || null,
                straws: this.strawVolumes() || null,
                straw_labels: this.strawLabels().map((label) => label.label()) || null,
                cryotank_id: this.cryotankId() || null,
                cryotank_address: this.cryotankPath().map((x) => {
                    return { label: x };
                }) || null,
            } : undefined,
        };
    };

    private submit = () => {
        this.submitInProgress(true);
        SpermService.importSperm(this.serialize()).then((response) => {
            this.submitInProgress(false);
            this.reloadRequired(true);
            this.importedSpermIds(response);
        }).catch((response) => {
            if (typeof response.body?.detail === "string") {
                notifications.showNotification(response.body.detail, "error");
            } else {
                notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
                writeException(response);
            }

            this.submitInProgress(false);
        });
    };

    private resetDialog = () => {
        this.volume(undefined);
        this.originId(1);
        this.ownerId(session.aliasId);
        this.projectId(null);
        this.strainId(null);
        this.rating(null);
        this.progressivePortion(undefined);

        // cryopreserved
        this.cryopreserved(false);
        this.freezeDate(null);
        this.strawCount(null);
        this.spermVolumePerStraw(null);
        this.strawVolumeInput(null);
        this.strawLabels([]);
        this.cryotankId(null);

        // result
        this.importedSpermIds([]);
    };

    private handleErrorResponse = (response: any) => {
        if (typeof response.body?.detail === "string") {
            notifications.showNotification(response.body.detail, "error");
        } else {
            notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
            writeException(response);
        }
    };

    private showSpermVolumesInList = () => {
        mainMenu.openAndResetListFilter("get_sperm_list", {
            sperm_id: this.importedSpermIds(),
        });
    };

}

export const showSpermImport = htmlDialogStarter(SpermImportViewModel, template, {
    name: "SpermImport",
    title: getTranslation("Import sperm"),
    width: 500,
    position: {
        inset: { top: 20 },
    },
    closeOthers: true,
});
