import { showTankDetails } from "../../dialogs";
import {
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    getUrl,
    printUrl,
} from "../../lib/utils";

import { TankKillingReportListFilterModel } from "./tankKillingReportListFilter";
import tankKillingReportListFilterTemplate from "./tankKillingReportListFilter.html";

interface Arguments extends ListViewArguments {
    export_arguments: any;
}

class TankKillingReportList {
    private listView: ListView;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        /* MenuBox buttons */
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);
        this.listView.onMenuBoxClick("remove-filter-button", () => resetListFilter(args.view_name));

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("export-to-excel-button", () => {
            showColumnSelect({
                mode: "export",
                viewName: args.view_name,
                exportArgs: args.export_arguments,
            });
        });

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: TankKillingReportListFilterModel,
                filterTemplate: tankKillingReportListFilterTemplate,
                title: getTranslation("Dead animals filter"),
            });
        });

        /* Table column links */
        this.listView.onCellClick("td.tank_id.clickable, td.tank_position.clickable", (args) => {
            this.listView.highlightRow(args.rowId);
            showTankDetails({
                tankId: Number(args.rowId),
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
            });
        });
    }
}

export const initTankKillingReportList = (args: Arguments): void => {
    new TankKillingReportList(document.querySelector("div.listview"), args);
};
