import {
    Subscription,
    Observable,
} from "knockout";
import * as ko from "knockout";

type Direction = "in" | "out" | "both";

declare module "knockout" {

    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace utils {

        export function gluedObservable<T>(otherValue: Observable<T> | T,
                                           defaultValue?: T,
                                           direction?: Direction,
                                           triggerOnly?: boolean,
                                           presetObservable?: undefined): Observable<T>;

        export function gluedObservable<T, P>(otherValue: Observable<T>,
                                              defaultValue?: T,
                                              direction?: Direction,
                                              triggerOnly?: boolean,
                                              presetObservable?: P): P;
    }
}

function safeIsObservable<T = any>(instance: any): instance is Observable<T> {
    let result;

    try {
        result = ko.isObservable(instance);
    } catch (err) {
        result = false;
    }

    return result;
}


/**
 * gluedObservable: Will return an observable that is glued to an other value/variable.
 *
 * @deprecated: Better don't use it. It makes the code hard to understand.
 *
 * @param otherValue: Other Observable to stick on.
 *
 * @param defaultValue: will be undefined if not passed
 *
 * @param direction: either "in", "out" or "both" to difine in which direction the observables should be updated
 * This is seen from the components perspective - so "in" only updates the components observable if the otherValue-observable changes.
 * If the components observable changes, the otherValue-observable will not be touched.
 *
 * @param triggerOnly: do not exchange values only call valueHasMutated() on the other observable.
 * This might be usefull to trigger a window resize etc.
 *
 * @param presetObservable: pass your own Observable (might be useful if you want a pureComputed or observableArray)
 */
ko.utils.gluedObservable = function <T, P = T>(otherValue: Observable<T>,
    defaultValue: any,
    direction: Direction,
    triggerOnly: boolean,
    presetObservable: P): Observable<T> | P {
    let mySubscription: Subscription;
    let otherSubscription: Subscription;

    function dispose() {
        if (mySubscription) mySubscription.dispose();
        if (otherSubscription) otherSubscription.dispose();
    }

    const newObservable = safeIsObservable(presetObservable) ? presetObservable : ko.observable();

    if (direction === undefined) direction = "both";

    if (["in", "out", "both"].indexOf(direction) === -1) {
        throw Error("direction must be \"in\", \"out\" or \"both\"");
    }

    if (!ko.isWriteableObservable(newObservable) && direction != "out") {
        throw Error("new observable is not writeable - it can only be used or direction \"out\"");
    }

    if (triggerOnly) {
        if (direction === "both") throw Error("triggered binding in both directions would lead to infinite loops");
        newObservable.extend({ notify: "always" });
    }

    if (!safeIsObservable(otherValue)) {
        // otherValue is no observable
        if (ko.isWriteableObservable(newObservable)) {
            // if newObservable is a computed, it may not be writeable
            newObservable(otherValue === undefined ? defaultValue : otherValue);
        }
    } else {
        // otherValue is observable
        if (direction === "both" || direction === "in") {
            otherSubscription = otherValue.subscribe(function (newValue) {
                if (!safeIsObservable(newObservable)) {
                    dispose();
                    return;
                }

                if (triggerOnly) newObservable.valueHasMutated();
                else newObservable(newValue);
            });

            newObservable(otherValue());
        }
        if (direction === "both" || direction === "out") {
            mySubscription = newObservable.subscribe(function (newValue) {
                if (!safeIsObservable(otherValue)) {
                    dispose();
                    return;
                }

                if (triggerOnly) otherValue.valueHasMutated();
                else otherValue(newValue);
            });

            otherValue(newObservable());
        }

        // set default value if undefined
        if (newObservable() === undefined && !triggerOnly && defaultValue !== undefined) {
            newObservable(defaultValue);
        }
    }

    return newObservable;
};
