/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_connectCage } from '../models/Body_connectCage';
import type { TecniplastNotifyCageMovementEvent } from '../models/TecniplastNotifyCageMovementEvent';
import type { TecniplastNotifyCagePreparedEvent } from '../models/TecniplastNotifyCagePreparedEvent';
import type { TecniplastUnknownCageLocation } from '../models/TecniplastUnknownCageLocation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TecniplastDvcService {

    /**
     * Tecniplast Get Unknown Cages
     * Get a list of unknown (empty) cages from the Tecniplast DVC.
     * @returns TecniplastUnknownCageLocation Successful Response
     * @throws ApiError
     */
    public static getUnknownCages(): CancelablePromise<Array<TecniplastUnknownCageLocation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cages/hardware/tecniplast_dvc/unknown_cages',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tecniplast Obtain Dvc Position
     * Obtain a position in the Tecniplast DVC for a cage.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static obtainDvcPosition({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cages/hardware/tecniplast_dvc/{cage_id}/obtain_dvc_position',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tecniplast Update Dvc Cage
     * Update the cage in the Tecniplast DVC.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateDvcCage({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cages/hardware/tecniplast_dvc/{cage_id}/update_dvc_cage',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tecniplast Connect Dvc Cage
     * Connect a cage to the Tecniplast DVC.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static connectCage({
        cageId,
        requestBody,
    }: {
        cageId: number,
        requestBody: Body_connectCage,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/hardware/tecniplast_dvc/{cage_id}',
            path: {
                'cage_id': cageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tecniplast Disconnect Dvc Cage
     * Disconnect a cage from the Tecniplast DVC.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static disconnectCage({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cages/hardware/tecniplast_dvc/{cage_id}',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tecniplast Turn Cage Led On
     * Turn the LED on for a cage in the Tecniplast DVC.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static turnCageLedOn({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/hardware/tecniplast_dvc/{cage_id}/led',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tecniplast Turn Cage Led Off
     * Turn the LED off for a cage in the Tecniplast DVC.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static turnCageLedOff({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cages/hardware/tecniplast_dvc/{cage_id}/led',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Tecniplast Hook
     * Receive a hook call from the Tecniplast DVC.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postTecniplastDvcHook({
        requestBody,
    }: {
        requestBody: (TecniplastNotifyCagePreparedEvent | TecniplastNotifyCageMovementEvent),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/hardware/tecniplast_dvc/hook',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
