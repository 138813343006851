import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    LicensesService,
    ListFilterDefinition,
} from "../../backend/v1";
import { FetchBackendExtended } from "../../knockout/extensions/fetchBackend";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./animalLicenseReportFilter.html";

import "./licenseReport.scss";


interface Arguments extends ListViewArguments {
    report_type: string;
    licence_assign_filter: any;
}

// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = () => ({
    licence_assign: class extends ListFilterItem {
        public licenseIdValue: Observable<number>;
        public classificationIdValue: Observable<number>;
        public assignDateFromValue: CheckExtended<Observable<string>>;
        public assignDateToValue: CheckExtended<Observable<string>>;
        public staticLicenses: { name: string; id: number }[];
        public possibleLicenses: PureComputed<{ name: string; id: number }[]>;
        public licenseText: PureComputed<string[]>;
        public possibleClassifications: FetchBackendExtended<Observable>;
        public classificationText: PureComputed<string[]>;

        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.licenseIdValue = ko.observable();
            this.classificationIdValue = ko.observable();
            this.assignDateFromValue = ko.observable().extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => this.assignDateToValue(), compareFromDate),
            });
            this.assignDateToValue = ko.observable().extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => this.assignDateFromValue(), compareToDate),
            });

            this.staticLicenses = [{ id: 0, name: getTranslation("None") }];
            this.possibleLicenses = ko.pureComputed(() => {
                return _.union(this.staticLicenses, seed.possible_values() && seed.possible_values().licence);
            });
            this.licenseText = ko.pureComputed(() => {
                if (seed.possible_values()) {
                    return _.map(seed.possible_values().licence, "name");
                }
            });

            this.possibleClassifications = ko.observable().extend({
                fetchBackend: () => {
                    if (seed.possible_values.inProgress && seed.possible_values.inProgress()) {
                        return;
                    }
                    if (this.licenseIdValue()) {
                        return LicensesService.getLicenseClassificationOptions({
                            licenseId: this.licenseIdValue(),
                            which: "for_filter",
                        });
                    }
                },
            });

            this.possibleClassifications.subscribeOnce(() => {
                _.defer(() => {
                    this.classificationIdValue((seed.current_value && seed.current_value.classification) || undefined);
                    this.classificationIdValue.valueHasMutated();
                });
            });

            this.classificationText = ko.pureComputed(() => {
                return _.map(this.possibleClassifications(), "name");
            });

            this.text = ko.pureComputed(() => {
                return _.flattenDeep([this.licenseText(), this.classificationText()]);
            });

            this.valid = () =>
                !this.possibleClassifications.inProgress() &&
                !seed.possible_values.inProgress() &&
                this.assignDateFromValue.isValid() &&
                this.assignDateToValue.isValid();
        }

        public deserialize = (newValue: any) => {
            // set dates before license, because otherwise they are overwritten by the subscribe callback of licenseIdValue
            this.assignDateFromValue(newValue ? newValue.assign_from : undefined);
            this.assignDateToValue(newValue ? newValue.assign_to : undefined);

            this.licenseIdValue(newValue ? newValue.licence : undefined);
            this.classificationIdValue(newValue ? newValue.classification : undefined);
        };

        public serialize = () => {
            const licenseAssign: any = {};

            if (this.licenseIdValue() || this.licenseIdValue() === 0) {
                licenseAssign.licence = this.licenseIdValue();
            }
            if (this.classificationIdValue() || this.classificationIdValue() === 0) {
                licenseAssign.classification = this.classificationIdValue();
            }
            if (this.assignDateFromValue()) {
                licenseAssign.assign_from = this.assignDateFromValue();
            }
            if (this.assignDateToValue()) {
                licenseAssign.assign_to = this.assignDateToValue();
            }

            return _.isEmpty(licenseAssign) ? undefined : licenseAssign;
        };
    },
});

class List {
    private listView: ListView;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        const showFilter = () =>
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Animal license report filter"),
            });

        // Open filter dialog on click on "No data" message
        this.listView.listViewElement.querySelector(".list-no-data")?.classList?.add("clickable");
        this.listView.listViewElement.querySelector(".list-no-data")?.addEventListener("click", showFilter);

        this.listView.onMenuBoxClick("list-filter-button", showFilter);
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);
        this.listView.onMenuBoxClick("report-summary", () => {
            resetListFilter(
                "license_report_summary",
                { licence_assign: args.licence_assign_filter },
                () => (location.href = getUrl("./animal_license_report")),
            );
        });
        this.listView.onMenuBoxClick("report-list", () => {
            resetListFilter(
                "license_report_animals",
                { licence_assign: args.licence_assign_filter },
                () => (location.href = getUrl("./animal_license_report_animals")),
            );
        });

        // apply button actions for given/valid licence assign filter arguments only
        if ("assign_from" in args.licence_assign_filter && "assign_to" in args.licence_assign_filter) {
            this.listView.onMenuBoxClick("list-print-button", () => {
                printUrl(getUrl(window.location.href, { show_print: "true" }));
            });

            this.listView.onMenuBoxClick("export-to-excel", () => {
                showColumnSelect({
                    viewName: args.view_name,
                    mode: "export",
                    exportArgs: args.licence_assign_filter,
                });
            });

            this.listView.onMenuBoxClick("eu-statistics-button", () => {
                window.open(
                    getUrl("./animal_license_report_export", {
                        view_name: args.view_name,
                        eu_report_type: "eu_statistics",
                    }),
                );
            });
            this.listView.onMenuBoxClick("eu-statistics-2021-button", () => {
                window.open(
                    getUrl("./animal_license_report_export", {
                        view_name: args.view_name,
                        eu_report_type: "eu_statistics_2021",
                    }),
                );
            });
            this.listView.onMenuBoxClick("eu-statistics-2021-with-sex-button", () => {
                window.open(
                    getUrl("./animal_license_report_export", {
                        view_name: args.view_name,
                        eu_report_type: "eu_statistics_2021_by_sex",
                    }),
                );
            });
            this.listView.onMenuBoxClick("t2-2021-button", () => {
                window.open(
                    getUrl("./animal_license_report_export", {
                        view_name: args.view_name,
                        eu_report_type: "t2_2021",
                    }),
                );
            });
        }

        // animal detail popup
        this.listView.onCellClick("td.eartag_or_id a", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                // pup
                openListDetailPopup(getUrl(cgiScript("pupdetail.py"), { animalid: args.rowId.substring(1) }));
            } else {
                // adult
                openListDetailPopup(getUrl(cgiScript("mousedetail.py"), { animalid: args.rowId }));
            }
        });

        this.listView.listViewElement.querySelectorAll("a.animal-link").forEach((e: HTMLLinkElement) => {
            e.addEventListener("click", () => {
                resetListFilter("license_report_animals", {
                    licence_assign: {
                        ...args.licence_assign_filter,
                        ...(e.dataset.licenseId ? { licence: parseInt(e.dataset.licenseId, 10) } : {}),
                        ...(e.dataset.classificationId ? { classification: parseInt(e.dataset.classificationId, 10) } : {}),
                    },
                    html_id: e.dataset.htmlIds,
                }, () => location.href = getUrl("./animal_license_report_animals", { page_start: 0 }));
            });
        });

        // cage detail popup
        this.listView.onCellClick("td.cagenumber a", (args) => {
            openListDetailPopup(getUrl(cgiScript("cagedetail.py"), { cageid: args.element.getAttribute("cageid") }));
        });
    }
}

export const initAnimalLicenseReport = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
