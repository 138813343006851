import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";

import {
    LicensesService,
    ListFilterDefinition,
} from "../../backend/v1";
import { FetchBackendExtended } from "../../knockout/extensions/fetchBackend";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListFilterMutationsItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { frames } from "../../lib/pyratTop";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./severityAssessmentResultsListFilter.html";


interface Arguments extends ListViewArguments {
    export_args: any;
}


const SeverityAssessmentResultsListFilters = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {
        owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        sex: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        animal_eartag: ListFilterItem,

        birth_id: ListFilterItem,

        cagenumber: ListFilterItem,

        sign_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        category_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        examination_date_from: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("examination_date_to"), compareFromDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        examination_date_to: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("examination_date_from"), compareToDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        strain_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name_with_id");
            }
        },

        mutation_grade: class extends ListFilterMutationsItem {
            strainNameOrIdFilter = ko.pureComputed(() => {
                return filter.allFilters().strain_id;
            });
        },

        license_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        classification_id: class extends ListFilterItem {
            private licenseId: PureComputed<number>;
            private possibleClassifications: FetchBackendExtended<ObservableArray>;
            private inProgress: Observable<boolean>;
            private disable: Observable<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.licenseId = ko.pureComputed(() => {
                    // for some reason params.allFilters().license_id.value() fires twice with the same value (if set)
                    // which would cause possibleClassifications async extender to fetch classification list twice.
                    // By serving license_id.value() through a pureComputed here, we avoid that because pureComputed
                    // does not fire a change/update multiple times if the value is still the same.
                    return filter.allFilters().license_id && filter.allFilters().license_id.value();
                });
                this.possibleClassifications = ko.observableArray();
                this.possibleClassifications.extend({
                    fetchBackend: () => {
                        if (this.licenseId()) {
                            return LicensesService.getLicenseClassificationOptions({
                                licenseId: this.licenseId(),
                                which: "for_filter",
                            });
                        }
                    },
                });
                this.possibleClassifications.subscribeOnce(() => {
                    value(seed.current_value);
                });

                this.inProgress = this.possibleClassifications.inProgress;
                this.disable = this.possibleClassifications.inProgress;

                // update search string in order to find select options (wordings) via filter search
                this.text = ko.pureComputed(() => {
                    return _.map(this.possibleClassifications(), "name");
                });

                this.valid = () => {
                    return !this.possibleClassifications.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        status: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        time_point_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        present: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        template_form: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        page_size: ListFilterItem,
    };
};


class SeverityAssessmentResultsList {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: SeverityAssessmentResultsListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Assessment results filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        // Table Body

        // links to detail popups

        this.listView.onCellClick("td.animal_eartag a", (args) => {
            if (args.element.hasAttribute("animalid")) {
                this.listView.highlightRow(args.rowId);
                frames.openListDetailPopup(
                    getUrl(cgiScript("mousedetail.py"), {
                        animalid: parseInt(args.element.getAttribute("animalid"), 10),
                    }),
                    () => this.listView.unHighlightRow(args.rowId),
                );
            }
            else if (args.element.hasAttribute("pupid")) {
                this.listView.highlightRow(args.rowId);
                frames.openListDetailPopup(
                    getUrl(cgiScript("pupdetail.py"), {
                        animalid: parseInt(args.element.getAttribute("pupid"), 10),
                    }),
                    () => this.listView.unHighlightRow(args.rowId),
                );
            }
        });

        this.listView.onCellClick("td.birth_id a, td.cagenumber a", (args) => {
            this.listView.highlightRow(args.rowId);
            frames.openListDetailPopup(
                getUrl(cgiScript("cagedetail.py"), {
                    cageid: parseInt(args.element.getAttribute("cageid"), 10),
                }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });
    }
}

export const initSeverityAssessmentResultsList = (args: Arguments): void => {
    new SeverityAssessmentResultsList(document.querySelector("div.listview"), args);
};
