import * as $ from "jquery";
import {
    ObservableArray,
    Observable,
    components,
} from "knockout";
import * as ko from "knockout";

import {
    LocationPickerCage,
    LocationsService,
} from "../../../backend/v1";
import { assert } from "../../../lib/assert";
import { writeException } from "../../../lib/excepthook";
import { getTranslation } from "../../../lib/localize";
import { frames } from "../../../lib/pyratTop";

import {
    LocationIdString,
    LocationItem,
} from "./locationPicker";

export type CageId = number;

export interface CageItem extends LocationPickerCage {
    prefix: string;
    suffix: string;
    selected: Observable<boolean>;
}

/**
 * Cage Picker Component
 *
 * @param location - ko.observable or object (dict) that holds the selected
 * location item which will be used to query all cages belonging to that location.
 * In case if object (dict) it has to have a type attribute of ('building', 'area',
 * 'room', 'rack') and corresponding [area/room/etc]_id If used with location-picker,
 * this should be the same variable as given to location-picker as params.selectedLocation
 *
 * @param showCageTrigger - ko.observable used to scroll selected cage into view.
 * To trigger, call showCageTrigger.valueHasMutated() or set any true-ish value to it.
 *
 * @param unselectCageTrigger - ko.observable used to unselect a maybe currently selected
 * cage item. To trigger, call unselectCageTrigger.valueHasMutated()
 *
 * @param rememberSelectionTrigger - ko.observable used to store the currently selected
 * cage so it can be restored later. To trigger, call rememberSelectionTrigger.valueHasMutated()
 *
 * @param restoreSelectionTrigger - ko.observable to reset the currently selected cage to
 * the previously remebered one, that was store using restoreSelectionTrigger. To trigger,
 * call restoreSelectionTrigger.valueHasMutated()
 *
 * @param parentLocation - full id string of a location (e.g. 1-2-3) picker shows only
 * cages of this location or its children; on selecting a parent of the parentLocation,
 * it shows only cages of parentLocation itthis. cages of siblings of parentLocation are
 * not included
 *
 * @param preselectCage - id of cage to pre-select
 *
 * @param selectedCage - ko.observable to hold the selected cage
 *
 */
export interface CagePickerParams {
    location: LocationItem;
    showCageTrigger?: Observable;
    unselectCageTrigger?: Observable;
    rememberSelectionTrigger?: Observable;
    restoreSelectionTrigger?: Observable;
    parentLocation?: LocationIdString;
    preselectCage?: CageId;
    selectedCage?: Observable<CageItem>;
}


class CagePickerViewModel {

    public pickerEl: HTMLElement;
    public isLoading: Observable<boolean>;
    public defaultCaption: string;
    public captionText: Observable<string>;
    public selectedLocation: Observable<LocationItem>;
    public lastSelectedLocation: LocationIdString;
    public cageList: ObservableArray<CageItem>;
    public rememberedCage: Observable<CageItem>;
    public selectedCage: Observable<CageItem>;
    public parentLocation: LocationIdString;
    public preselectCage: CageId;

    constructor(params: CagePickerParams, componentElement: ChildNode) {

        assert(ko.isObservable(params.selectedCage), "params.selectedCage is not a ko.observable");

        this.pickerEl = componentElement as HTMLElement;

        this.parentLocation = params.parentLocation;
        this.preselectCage = params.preselectCage;
        this.isLoading = ko.observable(false);
        this.defaultCaption = getTranslation("No cages found");
        this.captionText = ko.observable(this.defaultCaption);
        this.rememberedCage = ko.observable();
        this.selectedLocation = ko.isObservable(params.location) ? params.location : ko.observable(params.location);
        this.selectedCage = params.selectedCage;
        this.cageList = ko.observableArray();
        this.selectedLocation.subscribe((item) => {
            if (!item) {
                return;
            }
            if (this.lastSelectedLocation !== item.id) {
                this.lastSelectedLocation = item.id;
                this.getCages(item);
            }
        });

        if (ko.isObservable(params.showCageTrigger)) {
            // trigger with params.showCageTrigger.valueHasMutated()
            params.showCageTrigger.extend({ notify: "always" });
            params.showCageTrigger.subscribe(() => {
                this.showCage(params.selectedCage());
            });
        }

        if (ko.isObservable(params.unselectCageTrigger)) {
            // trigger with params.unselectCageTrigger.valueHasMutated()
            params.unselectCageTrigger.extend({ notify: "always" });
            params.unselectCageTrigger.subscribe(() => {
                this.unselectItem();
            });
        }

        if (ko.isObservable(params.rememberSelectionTrigger)) {
            // trigger with params.rememberSelectionTrigger.valueHasMutated()
            params.rememberSelectionTrigger.extend({ notify: "always" });
            params.rememberSelectionTrigger.subscribe(() => {
                this.rememberedCage(params.selectedCage());
            });
        }

        if (ko.isObservable(params.restoreSelectionTrigger)) {
            // trigger with params.restoreSelectionTrigger.valueHasMutated()
            params.restoreSelectionTrigger.extend({ notify: "always" });
            params.restoreSelectionTrigger.subscribe(() => {
                const rCage = this.rememberedCage();
                const sCage = params.selectedCage();

                if (rCage
                    && (!sCage
                        || rCage.cageid !== sCage.cageid)) {

                    this.selectItem(rCage);
                } else if (!rCage && sCage) {
                    this.unselectItem();
                }
            });
        }

    }

    public afterRender = () => {
        // call resizeDetailWindow after component is rendered
        window.top.setTimeout(frames.detailPopup.resize, 0);
    };

    public clickItem = (item: CageItem) => {
        if (item.cageid) {
            this.selectItem(item);
            this.showCage(item);
        }
    };

    // fetch location tree
    private getCages = (locationItem: LocationItem) => {

        if (this.isLoading()) {
            return;
        }

        this.isLoading(true);
        this.cageList([]);

        let locationId: string;
        if (this.parentLocation === undefined ||
            locationItem.id.split("-").length >= this.parentLocation.split("-").length) {
            locationId = locationItem.id;
        } else {
            locationId = this.parentLocation;
        }

        LocationsService.getPickerCages({ locationId })
            .then((cageList: CageItem[]) => {
                if (cageList.length === 0) {
                    this.captionText(this.defaultCaption);
                } else {
                    this.captionText("");
                    cageList.forEach((item) => {
                        const cagenumberSplit = item.cagenumber.split("-");
                        item.prefix = cagenumberSplit[0];
                        item.suffix = cagenumberSplit[1];
                        item.selected = ko.observable();
                        if (this.selectedCage() && item.cageid === this.selectedCage().cageid) {
                            this.selectItem(item);
                        } else if (!this.selectedCage() && item.cageid === this.preselectCage) {
                            this.selectItem(item);
                        }
                    });
                    this.cageList(cageList);
                    if (this.selectedCage()) {
                        this.showCage(this.selectedCage());
                    }
                }
            }).catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.captionText(reason.body?.detail);
                } else {
                    this.captionText(getTranslation("General error."));
                    writeException(reason);
                }
            }).finally(() => {
                this.isLoading(false);
            });
    };

    // scroll cage into view
    private showCage = (item: CageItem) => {
        // TODO: remove jQuery
        const pickerEl = $(this.pickerEl);
        if (!item) {
            pickerEl.animate({ scrollTop: 0, scrollLeft: 0 }, 200);
        } else {
            const itemEl = pickerEl.find(`[cageid=${item.cageid}]:visible`);
            if (itemEl.length > 0) {
                const elHeight = itemEl.outerHeight();
                const elWidth = itemEl.outerWidth();
                const pickerElHeight = pickerEl.height();
                const pickerElWidth = pickerEl.width();
                const elOffTop = itemEl.offset().top;
                const elOffLeft = itemEl.offset().left;
                const pickerElOffTop = pickerEl.offset().top;
                const pickerElOffLeft = pickerEl.offset().left;
                const pickerElScrollTop = pickerEl.scrollTop();
                const pickerElScrollLeft = pickerEl.scrollLeft();
                if (elOffTop + elHeight > pickerElOffTop + pickerElHeight) {
                    pickerEl.animate({ scrollTop: pickerElScrollTop + ((elOffTop + elHeight) - (pickerElOffTop + pickerElHeight)) }, 200);
                } else if (elOffTop < pickerElOffTop) {
                    pickerEl.animate({ scrollTop: pickerElScrollTop - ((pickerElOffTop + 3) - elOffTop) }, 200);
                }
                if (elOffLeft + elWidth > pickerElOffLeft + pickerElWidth) {
                    pickerEl.animate({ scrollLeft: pickerElScrollLeft + ((elOffLeft + elWidth) - (pickerElOffLeft + pickerElWidth)) }, 200);
                } else if (elOffLeft < pickerElOffLeft) {
                    pickerEl.animate({ scrollLeft: pickerElScrollLeft - ((pickerElOffLeft + 3) - elOffLeft) }, 200);
                }
            }
        }
    };

    private unselectItem = (doNotSetUndefined = false) => {
        if (this.selectedCage()) {
            this.selectedCage().selected(false);
        }
        if (!doNotSetUndefined) {
            this.selectedCage(undefined);
        }
    };

    private selectItem = (item: CageItem) => {
        this.unselectItem(true);
        item.selected(true);
        this.selectedCage(item);
    };
}

export class CagePickerComponent {

    constructor() {
        return {
            viewModel: {
                createViewModel: (params: CagePickerParams, componentInfo: components.ComponentInfo) => {
                    let viewModelElement: ChildNode;
                    const componentElement = componentInfo.element as HTMLElement;
                    if (componentElement.nodeName === "#comment") {
                        viewModelElement = componentElement.nextElementSibling;
                    } else {
                        viewModelElement = componentElement.firstChild;
                    }
                    return new CagePickerViewModel(params, viewModelElement);
                },
            },
            template: `
                <div class="cage-picker font-default"
                     data-bind="template: {afterRender: afterRender}">
                    <!-- ko foreach: cageList -->
                    <div class="cage"
                         data-bind="css: {selectable: cageid,
                                          selected: selected},
                                          attr: {cageid: cageid},
                                          click: $parent.clickItem">
                        <span class="prefix" data-bind="text: prefix"></span>
                        <span class="cage_separator">-</span>
                        <span class="suffix" data-bind="text: suffix"></span>
                    </div>
                    <!-- /ko -->
                    <div class="loading_circle" data-bind="visible: isLoading"></div>
                    <div class="caption" data-bind="hidden: isLoading() || cageList().length,
                                                    text: captionText"></div>
                </div>
            `,
        };
    }

}
