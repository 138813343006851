/**
 * Show a popup to set mutations for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    AjaxResponse,
    cgiScript,
    getFormData,
    getUrl,
} from "../lib/utils";

import template from "./setMutation.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget?: HTMLElement;
    title: string;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

interface MutationAssignment {
    mutationId: Observable<number>;
    mutationName: Observable<string>;
    oldGradeId: number;
    newGradeId: Observable<number>;
    newGradeName: Observable<string>;
    grades: ObservableArray<{
        id: number;
        name: string;
    }>;
    inserted: Observable<boolean>;
    changed: Observable<boolean>;
    deleted: Observable<boolean>;
}

interface Seed {
    mutations: {
        id: number;
        name: string;
        grades: {
            id: number;
            name: string;
        }[];
    }[];
    mutation_assignments: {
        mutation_id: number;
        mutation_name: string;
        mutation_grade_id: number;
        mutation_grade_name: string;
        grades: {
            id: number;
            name: string;
        }[];
    }[];
}

class SetMutationViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;
    public readonly mutationAssignments: ObservableArray<MutationAssignment>;
    public readonly availableMutations: PureComputed<Seed["mutations"]>;
    public readonly availableGrades: PureComputed<Seed["mutations"][0]["grades"]>;
    public readonly addMutationId: CheckExtended<Observable<number>>;
    public readonly addMutationGradeId: Observable<number>;
    public readonly errors: ko.ObservableArray<string>;
    public readonly canSubmit: PureComputed<boolean>;
    public readonly submitInProgress: Observable<boolean>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.mutationAssignments = ko.observableArray();

        this.availableMutations = ko.pureComputed(() => {
            if (session.pyratConf.KEEP_GRADE) {
                return this.seed().mutations;
            } else {
                // remove the already assigned mutations
                return _.filter(this.seed().mutations, (row) => {
                    return !_.find(this.mutationAssignments(), (assignment) => {
                        return assignment.mutationId() === row.id;
                    });
                });
            }
        });
        this.addMutationId = ko.observable().extend({
            invalid: (v) => {
                return !v;
            },
        });

        this.availableGrades = ko.pureComputed(() => {
            // show grade list depending on the selected mutation
            return _.find(this.seed().mutations, { id: this.addMutationId() })?.grades || [];
        });

        this.addMutationGradeId = ko.observable();

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.animalId) {
                    return fetch(getUrl(cgiScript("set_mutation.py"), { animal_id: this.animalId }), { signal });
                } else if (this.pupId) {
                    return fetch(getUrl(cgiScript("set_mutation.py"), { pup_id: this.pupId }), { signal });
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.mutationAssignments(_.map(
                    seed.mutation_assignments, (row) => { return this.getRowModel(row); },
                ));
            }
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });

        this.canSubmit = ko.pureComputed(() => {
            const anyChange = _.some(this.mutationAssignments(), function(row) {
                return row.inserted() || row.changed() || row.deleted();
            });

            return !this.submitInProgress()
                && anyChange;
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
    }

    private getRowModel = (row: Seed["mutation_assignments"][0]) => {
        const rowModel: MutationAssignment = {
            mutationId: ko.observable(row.mutation_id),
            mutationName: ko.observable(row.mutation_name),
            oldGradeId: row.mutation_grade_id,
            newGradeId: ko.observable(row.mutation_grade_id),
            newGradeName: ko.observable(row.mutation_grade_id ? row.mutation_grade_name : getTranslation("N/A")),
            grades: ko.observableArray(row.grades),
            inserted: ko.observable(false),
            changed: ko.observable(false),
            deleted: ko.observable(false),
        };

        rowModel.newGradeId.subscribe((v) => {
            if ((rowModel.oldGradeId || "") === (v || "")) {
                rowModel.changed(false);
            } else {
                rowModel.changed(true);
            }
        });

        return rowModel;
    };


    public canAddMutation = ko.pureComputed(() => {
        return !this.addMutationId.isInvalid();
    });

    public addMutation = () => {
        const selectedMutation = _.find(this.seed().mutations, { id: this.addMutationId() });
        if (selectedMutation) {
            const rowModel = this.getRowModel({
                mutation_id: this.addMutationId(),
                mutation_name: selectedMutation.name,
                mutation_grade_id: this.addMutationGradeId(),
                mutation_grade_name: _.find(selectedMutation.grades, { id: this.addMutationGradeId() })?.name,
                grades: selectedMutation.grades,
            });
            rowModel.inserted(true);
            this.mutationAssignments.push(rowModel);
        }
    };

    /**
     * Delete mutation assignment
     * (=> Existing assignments can be reassigned and therefore are not removed from DOM (strikeout row),
     *     whereas new added assignments will be removed in general.)
     */
    public deleteMutation = (item: MutationAssignment) => {
        if (!item.inserted() && !item.deleted()) {
            item.deleted(true);
        } else if (!item.inserted() && item.deleted()) {
            item.deleted(false);
        } else {
            this.mutationAssignments.remove(item);
        }
    };

    /**
     * Reactivate mutation assignment
     * (=> Available only for existing assignments (strikeout row))
     */
    public reactivateMutation = (item: MutationAssignment) => {
        item.deleted(false);
    };

    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const mutations: any = [];
        this.mutationAssignments().forEach((row) => {
            if (row.inserted()) {
                mutations.push({
                    action: "add_mutation",
                    mutation_id: row.mutationId(),
                    old_grade_id: row.oldGradeId,
                    new_grade_id: row.newGradeId(),
                });
            } else if (row.changed()) {
                mutations.push({
                    action: "update_mutation",
                    mutation_id: row.mutationId(),
                    old_grade_id: row.oldGradeId,
                    new_grade_id: row.newGradeId(),
                });
            } else if (row.deleted()) {
                mutations.push({
                    action: "delete_mutation",
                    mutation_id: row.mutationId(),
                    old_grade_id: row.oldGradeId,
                    new_grade_id: row.newGradeId(),
                });
            }
        });

        const formData = getFormData({
            mutation_assignments: JSON.stringify(mutations),
        });

        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        fetch(cgiScript("set_mutation.py"), {
            method: "POST",
            body: this.getFormData(),
        }).then(response => response.json()).then((response: AjaxResponse<any>) => {
            this.submitInProgress(false);
            if (response.success) {
                this.dialog.close();
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
                notifications.showNotification(response.message, "success");
            } else {
                this.errors.push(response.message);
            }
        }).catch(() => {
            this.submitInProgress(false);
            notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
        });
    };
}

export const showSetMutation = dialogStarter(SetMutationViewModel, template, params => ({
    name: "SetMutation",
    width: 400,
    handle: "right top",
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    title: params.title,
}));
