import {
    showComments,
    showSetLicense,
    showSetMedicalConditions,
    showSetNumberOfTankAnimals,
    showSetTankAgeLevel,
    showSetTankLabel,
    showTankAnimalImport,
    showTankDetails,
    showTankQuickselect,
} from "../../dialogs";
import {
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    SearchBox,
    SearchMode,
    setListFilter,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    getUrl,
    printUrl,
} from "../../lib/utils";
import { pyratFrontend } from "../../pyratFrontend";

import { TankListFilterModel } from "./tankListFilter";
import tankListFilterTemplate from "./tankListFilter.html";

interface Arguments extends ListViewArguments {
    search_mode: SearchMode;
}

class TankList {
    private listView: ListView;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        /* MenuBox buttons */
        this.listView.onMenuBoxClick("qs-button", () => this.showQuickSelect());
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);
        this.listView.onMenuBoxClick("remove-filter-button", () => resetListFilter(args.view_name));

        this.listView.onMenuBoxClick("import-button", () => showTankAnimalImport({
            reloadCallback: () => this.reloadList(),
        }));

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: TankListFilterModel,
                filterTemplate: tankListFilterTemplate,
                title: getTranslation("Tank filter"),
            });
        });

        /* Table column links */
        this.listView.onCellClick("td.tank_id.clickable, td.tank_position.clickable", (args) => {
            this.showTankDetails(args.rowId, Number(args.rowId));
        });

        this.listView.onCellClick("td.children_tank_ids a, td.parent_tank_ids a", (args) => {
            this.showTankDetails(args.rowId, parseInt(args.element.dataset.tankId, 10));
        });

        this.listView.onCellClick("td.alive_count.clickable", (args) => {
            this.listView.highlightRow(args.rowId);
            showSetNumberOfTankAnimals({
                tankId: Number(args.rowId),
                numberOfDead: null,
                sacrificeReasonId: null,
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
                reloadCallback: () => this.reloadList(args.rowId),
            });
        },
        );

        this.listView.onCellClick(
            "td.record_dead a.record-dead-animals, td.record_dead a.revive-animals",
            (args) => {
                this.listView.highlightRow(args.rowId);
                showSetNumberOfTankAnimals({
                    tankId: Number(args.rowId),
                    numberOfDead: parseInt(args.element.dataset.amount, 10) || undefined,
                    sacrificeReasonId: null,
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                    reloadCallback: () => this.reloadList(args.rowId),
                });
            },
        );

        this.listView.onCellClick("td.age_level_label.clickable", (args) => {
            this.listView.highlightRow(args.rowId);
            showSetTankAgeLevel({
                tankId: Number(args.rowId),
                eventTarget: args.element,
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
                reloadCallback: () => this.reloadList(args.rowId),
            });
        });

        this.listView.onCellClick("td.tank_label_rename.clickable", (args) => {
            this.listView.highlightRow(args.rowId);
            showSetTankLabel({
                tankId: Number(args.rowId),
                eventTarget: args.element,
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
                reloadCallback: () => this.reloadList(args.rowId),
            });
        });

        this.listView.onCellClick("td.directquick", (args) => {
            this.showQuickSelect(args.rowId);
        });

        this.listView.onCellClick(
            "td.location_building_name.clickable," +
            "td.location_area_name.clickable," +
            "td.location_room_name.clickable," +
            "td.location_rack_name.clickable," +
            "td.tank_type_label.clickable",
            (args) => {
                this.showQuickSelect(args.rowId, ["separate_animals_action", "move_tank_action", "set_tank_type_action"]);
            },
        );

        this.listView.onCellClick(
            "td.strain_name.clickable, td.strain_name_id.clickable, td.strain_name_with_id.clickable",
            (args) => { this.showQuickSelect(args.rowId, ["set_strain_action"]); },
        );

        this.listView.onCellClick("td.generation.clickable", (args) => {
            this.showQuickSelect(args.rowId, ["set_tank_generation_action"]);
        });

        this.listView.onCellClick("td.owner_fullname.clickable", (args) => {
            this.showQuickSelect(args.rowId, ["tank_export_to_scientist_action"]);
        });

        this.listView.onCellClick("td.responsible_fullname.clickable", (args) => {
            this.showQuickSelect(args.rowId, ["set_responsible_action"]);
        });

        this.listView.onCellClick("td.project_name.clickable", (args) => {
            this.showQuickSelect(args.rowId, ["add_projects_action", "remove_projects_action", "set_project_action"]);
        });

        this.listView.onCellClick("td.record_dead a.kill-animals-qs", (args) => {
            this.showQuickSelect(args.rowId, ["separate_animals_action", "set_license_action", "close_tank_action"]);
        });

        /* open QS with "set animal mutations" action */
        this.listView.onCellClick("td.show_add_missing_mutations a.tank-add-mutations", (args) => {
            this.showQuickSelect(args.rowId, ["set_mutations_action"]);
        });

        /* work requests */
        this.listView.onCellClick("a.tank-single-request", (args) => {
            this.listView.highlightRow(args.rowId);
            openListDetailPopup(
                getUrl(cgiScript("requestdetail.py"), { incidentid: args.element.getAttribute("incident_id") }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });

        this.listView.onCellClick("a.tank-multiple-request", (args) => {
            mainMenu.openAndResetListFilter("get_work_request_list", {
                id: String(args.element.getAttribute("incident_ids")).split(",").map((v) => parseInt(v, 10)),
                status_id_or_unresolved: -1,
            });
        });

        /* crossing id(s) columns */
        this.listView.onCellClick("td.crossing_ids a, td.latest_crossing_id a, td.release_crossing_ids a", (args) => {
            mainMenu.open("get_tank_crossing_list", { crossing_id: args.element.dataset.crossingIds });
        });

        /* open license assignment pop-up */
        this.listView.onCellClick(
            "td.classification_name.clickable," +
            "td.licence_title.clickable," +
            "td.licence_number.clickable," +
            "td.license_assign_history.clickable",
            (args) => {
                const tankId = Number(args.rowId);
                this.listView.highlightRow(args.rowId);
                showSetLicense({
                    subjects: { tank_ids: [tankId] },
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                    reloadCallback: () => this.reloadList(args.rowId),
                });
            },
        );

        this.listView.onCellClick(
            "td.medical_condition.clickable, td.medical_condition_with_event.clickable",
            (args) => {
                const tankId = Number(args.rowId);
                this.listView.highlightRow(args.rowId);
                showSetMedicalConditions({
                    tankId: tankId,
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                    reloadCallback: () => this.reloadList(args.rowId),
                });
            },
        );

        /* link to tank based pedigree */
        this.listView.onCellClick("td.pedigree_graph a", (args) => {
            mainMenu.open("get_colony_pedigree", {
                kind: "tank_pedigree",
                label: args.element.dataset.tankId,
            });
        });

        this.listView.onCellClick("td.comments.clickable", (args) => {
            const tankId = Number(args.rowId);
            this.listView.highlightRow(args.rowId);
            showComments({
                origin: "tank",
                subjects: { tank_id: tankId },
                onClose: () => this.listView.unHighlightRow(args.rowId),
                reSeedCallback: () => this.reloadList(args.rowId),
            });
        });

        // initialize the barcode searchbox
        const barcodeFilterElement = listViewElement.querySelector(".searchbox .barcode_filter");
        if (barcodeFilterElement && pyratFrontend.session.pyratConf.BARCODE) {
            new SearchBox({
                element: barcodeFilterElement.parentElement,
                searchCode: "barcode",
                searchMode: args.search_mode,
                onSubmitCallback: (value) => {
                    setListFilter(args.view_name, { neighbor_of_tank_id: value });
                },
            });
        }
    }

    public showQuickSelect = (rowId?: string, actions?: string[]) => {
        if (rowId) {
            this.listView.highlightRow(String(rowId));
            showTankQuickselect({
                tankIds: [Number(rowId)],
                actions: Array.isArray(actions) ? actions : [],
                closeCallback: () => this.listView.unHighlightRow(rowId),
                reloadCallback: () => this.reloadList(String(rowId)),
            });
        } else {
            if (!this.listView.getSelectedRowIds().length) {
                notifications.showModal(getTranslation("No tanks selected"));
            } else {
                showTankQuickselect({
                    actions: Array.isArray(actions) ? actions : [],
                    tankIds: this.listView.getSelectedRowIds().map((value) => parseInt(value, 10)),
                    reloadCallback: this.reloadList,
                });
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public reloadList = (rowId?: string) => {
        this.listView.reload({ flashRowId: rowId });
    };

    public showTankDetails = (highlightRowId: string, tankId: number, loadCallback?: () => void) => {
        this.listView.highlightRow(highlightRowId);

        showTankDetails({
            tankId: tankId,
            closeCallback: () => {
                this.listView.unHighlightRow(highlightRowId);
            },
            reloadCallback: () => {
                this.reloadList(highlightRowId);
            },
            loadCallback: loadCallback,
        });
    };
}

export const initTankList = (args: Arguments): void => {
    const tankList = new TankList(document.querySelector("div.listview"), args);

    // @ts-expect-error: required by procedure shortcuts
    window.tankList = tankList;
};
