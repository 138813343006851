/**
 * Show a pop-up to create or edit a work request class.
 *
 * @param workrequestClassId: Database ID of the work request class. Can be
 *                            `undefined` when a new work request class is
 *                            created.
 */

import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import { IdNameProperty } from "../backend/v1/";
import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    frames,
    notifications,
} from "../lib/pyratTop";
import {
    cgiScript,
    getFormData,
    AjaxResponse,
    getUrl,
} from "../lib/utils";

import template from "./workrequestClassDetails.html";

interface ResponsibleData {
    userid: number;
    fullname: string;
}

interface PredefinedField {
    field_type: string;
    label: string;
    default_required: boolean;
    exclude_behaviors: string[];
    required_field_type: string[];
}

interface InputType {
    id: string;
    label: string;
}

interface GeneralInputField {
    id?: number;  // for predefined fields, custom fields, and behavior field settings that are already in the database
    field_type?: string;  // for predefined fields and behavior fields
    label: string|CheckExtended<Observable<string>>;
    input_type?: Observable<string>;  // for custom fields
    edit_input_type?: boolean;  // for custom fields
    required: Observable<boolean>;
    edit_required: boolean;
    default_required?: boolean;  // for behavior fields
    available: Observable<boolean>;
    edit_available: boolean;
    default_available?: boolean;  // for behavior fields
    added: Observable<boolean>;
    can_toggle_add_delete: PureComputed<boolean>;
    toggle_add_delete?: (f: GeneralInputField) => void;  // for predefined fields and custom fields
    display: PureComputed<boolean>;
}

interface BehaviorFieldData {
    id: number;
    field_type: string;
    label: string;
    required: boolean;
    edit_required: boolean;
    default_required: boolean;
    available: boolean;
    edit_available: boolean;
    default_available: boolean;
}

interface BehaviorData {
    id: number;
    name: string;
    label: string;
    fields: BehaviorFieldData[];
}

interface PredefinedFieldData {
    id: number;
    field_type: string;
    required: boolean;
    available: boolean;
    used: boolean;
}

interface CustomFieldData {
    id: number;
    label: string;
    input_type: string;
    required: boolean;
    available: boolean;
    used: boolean;
}

interface Params {
    workrequestClassId?: number;
}

interface Seed {
    responsibles: ResponsibleData[];
    behaviors: BehaviorData[];
    possible_predefined_fields: PredefinedField[];
    input_types: InputType[];
    class_name: string;
    short_name: string;
    responsible_id: number;
    class_description: string;
    behavior_id: number;
    predefined_fields: PredefinedFieldData[];
    custom_fields: CustomFieldData[];
    procedures: IdNameProperty[];
    use_procedures: boolean;
    planned_procedures: IdNameProperty[];
}

interface ProcedureAssignmentRow {
    procedureId: ko.Observable<number>;
    procedureName: ko.Observable<string>;
    inserted: ko.Observable<boolean>;
    deleted: ko.Observable<boolean>;
    selected: ko.Observable<boolean>;
}


class WorkrequestClassDetailsViewModel {
    public dialog: KnockoutPopup;

    // state
    public workrequestClassId: Observable<number>;
    public responsibles: ObservableArray<ResponsibleData>;
    public behaviors: ObservableArray<BehaviorData>;
    public inputTypes: ObservableArray<InputType>;
    public className: CheckExtended<Observable<string>>;
    public shortName: CheckExtended<Observable<string>>;
    public responsibleId: CheckExtended<Observable<number>>;
    public classDescription: Observable<string>;
    public behavior: Observable<BehaviorData>;
    public showOnlyActiveFields: Observable<boolean>;
    public fields: ObservableArray<GeneralInputField>;
    private possiblePredefinedFields: ObservableArray<PredefinedField>;
    private predefinedFields: ObservableArray<PredefinedFieldData>;
    private customFields: ObservableArray<CustomFieldData>;
    public useProcedures: Observable<boolean>;
    public availableProcedures: ko.PureComputed<IdNameProperty[]>;
    public newProcedureId: CheckExtended<ko.Observable<number>>;
    public plannedProcedures: ko.ObservableArray<ProcedureAssignmentRow> = ko.observableArray();

    // internals
    public seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;
    public canSubmit: ko.PureComputed<boolean>;
    public submitInProgress: Observable<boolean> = ko.observable(false);
    public reloadRequired: Observable<boolean> = ko.observable(false);
    public selectedTab: ko.Observable<string> = ko.observable("additional_fields");

    constructor(params: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;
        this.workrequestClassId = ko.observable(params.workrequestClassId);

        if (this.workrequestClassId()) {
            dialog.setTitle(getTranslation("Work request class details"));
        } else {
            dialog.setTitle(getTranslation("Create new work request class"));
        }

        this.dialog.addOnClose(() => {
            if (this.reloadRequired()) {
                frames.reloadListIframe();
            }
        });

        this.responsibles = ko.observableArray();
        this.behaviors = ko.observableArray();
        this.possiblePredefinedFields = ko.observableArray();
        this.inputTypes = ko.observableArray();

        this.className = ko.observable().extend({
            trim: true,
            invalid: (v) => {
                return !v;
            },
        });
        this.shortName = ko.observable().extend({
            trim: true,
            invalid: (v) => {
                return !v;
            },
        });
        this.responsibleId = ko.observable().extend({
            invalid: (v) => {
                return !v;
            },
        });
        this.classDescription = ko.observable().extend({
            trim: true,
        });
        this.behavior = ko.observable();
        this.predefinedFields = ko.observableArray();
        this.customFields = ko.observableArray();
        this.fields = ko.observableArray();

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl(cgiScript("workrequest_class_details.py"), {
                    workrequest_class_id: this.workrequestClassId() || "",
                }), { signal });
            },
        });
        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.responsibles(seed.responsibles || []);
                this.behaviors(seed.behaviors || []);
                this.possiblePredefinedFields(seed.possible_predefined_fields || []);
                this.inputTypes(seed.input_types || []);

                this.className(seed.class_name || "");
                this.shortName(seed.short_name || "");
                this.responsibleId(seed.responsible_id);
                this.classDescription(seed.class_description || "");
                this.behavior(_.find(this.behaviors(), { id: seed.behavior_id }));
                this.predefinedFields(seed.predefined_fields || []);
                this.customFields(seed.custom_fields || []);
                this.useProcedures(seed.use_procedures);
                this.plannedProcedures(
                    _.map(seed.planned_procedures || [], (row) => {
                        return this.getProcedureRowModel(row);
                    }),
                );

                // put all additional fields together
                this.fields([]);
                this.behaviors().forEach((behavior) => {
                    behavior.fields.forEach((field) => {
                        this.fields.push({
                            id: field.id,
                            field_type: field.field_type,
                            label: field.label,
                            required: ko.observable(field.required),
                            edit_required: field.edit_required && session.userPermissions.requestsettings_update,
                            default_required: field.default_required,
                            available: ko.observable(field.available),
                            edit_available: field.edit_available && session.userPermissions.requestsettings_update,
                            default_available: field.default_available,
                            // user must set different behavior to have different behavior fields
                            added: ko.observable(true),
                            can_toggle_add_delete: ko.pureComputed(() => false),
                            display: ko.pureComputed(() => {
                                return Boolean(this.behavior() && this.behavior().id === behavior.id);
                            }),
                        });
                    });
                });

                this.possiblePredefinedFields().forEach((field) => {
                    const predefinedField = _.find(this.predefinedFields(), { field_type: field.field_type });
                    const added = ko.observable(Boolean(predefinedField));

                    this.fields.push({
                        id: predefinedField ? predefinedField.id : undefined,
                        field_type: field.field_type,
                        label: field.label,
                        required:       ko.observable(predefinedField ? predefinedField.required : field.default_required),
                        edit_required:  session.userPermissions.requestsettings_update,
                        available:      ko.observable(predefinedField ? predefinedField.available : true),
                        edit_available: session.userPermissions.requestsettings_update,
                        added: added,
                        can_toggle_add_delete: ko.pureComputed(() => {
                            if (!session.userPermissions.requestsettings_update) {
                                return false;
                            }

                            if (added()) {
                                // allow to delete only when not used and
                                // when no other added field depends on this field
                                return (!predefinedField || !predefinedField.used) &&
                                        _.every(this.possiblePredefinedFields(), (otherField) => {
                                            return otherField === field ||
                                                    !_.find(this.fields(), (f) => {
                                                        return f.field_type === otherField.field_type && f.added() && f.display();
                                                    }) ||
                                                    this.requiredFieldAdded(otherField, field.field_type);
                                        });
                            }

                            // allow to add only when it fits to the selected behavior and
                            // when required fields are added to the work request class
                            return this.fieldMatchesBehavior(field) && this.requiredFieldAdded(field);
                        }),
                        toggle_add_delete: (f) => {
                            f.added(!f.added());
                        },
                        display: ko.pureComputed(() => {
                            return this.fieldMatchesBehavior(field) &&
                                    this.requiredFieldAdded(field) &&
                                    (added() || session.userPermissions.requestsettings_update);
                        }),
                    });
                });

                this.customFields().forEach((field) => {
                    const added = ko.observable(true);

                    this.fields.push({
                        id: field.id,
                        label: ko.observable(field.label).extend({
                            trim: true,
                            invalid: (v) => {
                                if (added() && !v) {
                                    return getTranslation("Field name can't be empty");
                                }

                                return false;
                            },
                        }),
                        input_type:     ko.observable(field.input_type),
                        edit_input_type: session.userPermissions.requestsettings_update && !field.used,
                        required:       ko.observable(field.required),
                        edit_required:  session.userPermissions.requestsettings_update,
                        available:      ko.observable(field.available),
                        edit_available: session.userPermissions.requestsettings_update,
                        added: added,
                        can_toggle_add_delete: ko.pureComputed(() => {
                            // always allow to add back deleted fields
                            // allow to delete fields when they are not used
                            return !added() || !field.used;
                        }),
                        toggle_add_delete: (f) => {
                            f.added(!f.added());
                        },
                        display: ko.pureComputed(() => true),
                    });
                });

                if (session.userPermissions.requestsettings_update) {
                    this.addNewAddtitionalField();
                }
            }
        });

        this.showOnlyActiveFields = ko.observable(true);

        this.useProcedures = ko.observable();

        this.availableProcedures = ko.pureComputed(() => {
            // dynamically remove the already assigned procedures
            return _.filter(this.seed().procedures, (row) => {
                return !_.find(this.plannedProcedures(), (item) => {
                    return item.procedureId() === row.id;
                });
            });
        });

        this.newProcedureId = ko.observable().extend({
            invalid: (v) => {
                return !v;
            },
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(
                this.seed.inProgress() ||
                this.submitInProgress() ||
                this.className.isInvalid() ||
                this.shortName.isInvalid() ||
                this.responsibleId.isInvalid() ||
                _.some(this.fields(), (field) => {
                    return ko.isObservable(field.label) && field.label.isInvalid();
                })
            );
        });
    }

    private fieldMatchesBehavior = (field: PredefinedField) => {
        // when one of this applies:
        //   * no behavior selected
        //   * selected behavior does not already provide the field type
        return !this.behavior() || !_.includes(field.exclude_behaviors, this.behavior().name);
    };

    private requiredFieldAdded = (field: PredefinedField, exclude_field_type?: string) => {
        // when one of this applies:
        //   * field does not even require another field
        //   * at least one of the required fields is already added (and not matching `exclude_field_type`)
        //   * at least one of the required fields is provided by the selected behavior
        return !field.required_field_type.length ||
                _.some(this.fields(), (otherField) => {
                    return _.includes(field.required_field_type, otherField.field_type) &&
                            otherField.added() &&
                            otherField.display() &&
                            (!exclude_field_type || otherField.field_type !== exclude_field_type);
                }) ||
                this.behavior() && _.some(this.possiblePredefinedFields(), (otherField) => {
                    return _.includes(field.required_field_type, otherField.field_type) &&
                            _.includes(otherField.exclude_behaviors, this.behavior().name);
                });
    };

    private addNewAddtitionalField = () => {
        const newLabel = ko.observable().extend({
            invalid: (v) => {
                if (newField.added() && newField !== _.last(this.fields()) && !v) {
                    return getTranslation("Field name can't be empty");
                }

                return false;
            },
        });
        const newField: GeneralInputField = {
            label: newLabel,
            input_type:     ko.observable("text"),
            edit_input_type: session.userPermissions.requestsettings_update,
            required:       ko.observable(false),
            edit_required:  session.userPermissions.requestsettings_update,
            available:      ko.observable(true),
            edit_available: false,
            added:          ko.observable(true),
            can_toggle_add_delete: ko.pureComputed(() => {
                return Boolean(newLabel()) || newField !== _.last(this.fields());
            }),
            toggle_add_delete: (f) => {
                f.added(!f.added());
            },
            display: ko.pureComputed(() => true),
        };

        newLabel.subscribe((v) => {
            if (v && _.last(this.fields()) === newField) {
                newField.added(true);
                this.addNewAddtitionalField();
            }
        });

        this.fields.push(newField);
    };

    private getProcedureRowModel = (row: Seed["procedures"][0]) => {
        const rowModel: ProcedureAssignmentRow = {
            procedureId: ko.observable(row.id),
            procedureName: ko.observable(row.name),
            inserted: ko.observable(false),
            deleted: ko.observable(false),
            selected: ko.observable(false),
        };

        return rowModel;
    };

    public canAddProcedure = ko.pureComputed(() => {
        return !this.newProcedureId.isInvalid();
    });

    public addProcedure = () => {
        const item = this.getProcedureRowModel({
            id: this.newProcedureId(),
            name: _.find(this.seed().procedures, { id: this.newProcedureId() })?.name,
        });
        item.inserted(true);
        this.plannedProcedures.push(item);
    };

    public deleteProcedure = (item: ProcedureAssignmentRow) => {
        this.plannedProcedures.remove(item);
    };

    public selectProcedure = (item: ProcedureAssignmentRow, event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.altKey || event.ctrlKey) {
            item.selected(true);
        } else {
            // with single click, select _only_ this procedure
            _.forEach(this.plannedProcedures(), (item) => {
                item.selected(false);
            });
            item.selected(true);
        }
    };

    public moveSelectedProceduresUp = () => {
        for (let i = 0; i < this.plannedProcedures().length; i++) {
            const item = this.plannedProcedures()[i];
            if (item.selected()) {
                if (i > 0) {
                    const previousItem = this.plannedProcedures()[i - 1];
                    if (!previousItem.selected()) {
                        this.plannedProcedures.splice(i - 1, 2, item, previousItem);
                    }
                }
            }
        }
    };

    public moveSelectedProceduresDown = () => {
        for (let i = this.plannedProcedures().length - 1; i >= 0; i--) {
            const item = this.plannedProcedures()[i];
            if (item.selected()) {
                if (i < this.plannedProcedures().length - 1) {
                    const nextItem = this.plannedProcedures()[i + 1];
                    if (!nextItem.selected()) {
                        this.plannedProcedures.splice(i, 2, nextItem, item);
                    }
                }
            }
        }
    };

    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const predefinedFields: {id: string; field_type: string; required: "0"|"1"; available: "0"|"1"}[] = [];
        const customFields: {id: string; label: string; input_type: string; required: "0"|"1"; available: "0"|"1"}[] = [];

        this.fields().forEach((field) => {
            if (field.display() && field.added()) {
                if (field.field_type &&
                        (_.find(this.possiblePredefinedFields(), { field_type: field.field_type }) ||
                         field.required() !== field.default_required ||
                         field.available() !== field.default_available)) {
                    // real predefined field or behavior field with own setting
                    predefinedFields.push({
                        id: field.id?.toString() || "",
                        field_type: field.field_type,
                        required: field.required() ? "1" : "0",
                        available: field.available() ? "1" : "0",
                    });
                } else if (ko.isObservable(field.label) && field.label()) {
                    // custom field
                    customFields.push({
                        id: field.id?.toString() || "",
                        label: field.label(),
                        input_type: ko.unwrap(field.input_type),
                        required: field.required() ? "1" : "0",
                        available: field.available() ? "1" : "0",
                    });
                }
            }
        });

        return  getFormData({
            workrequest_class_id: this.workrequestClassId()?.toString() || "",
            class_name: this.className(),
            short_name: this.shortName(),
            responsible_id: this.responsibleId().toString(),
            class_description: this.classDescription() || "",
            behavior_id: this.behavior()?.id.toString() || "",
            predefined_fields: JSON.stringify(predefinedFields),
            custom_fields: JSON.stringify(customFields),
            use_procedures: this.useProcedures() ? 1 : 0,
            planned_procedure_ids: JSON.stringify(this.plannedProcedures().map((row) => {
                return row.procedureId();
            })),
        });
    };

    public submit = () => {
        this.submitInProgress(true);
        fetch(cgiScript("workrequest_class_details.py"), {
            method: "POST",
            body: this.getFormData(),
        }).then(response => response.json()).then((response: AjaxResponse<any>) => {
            this.submitInProgress(false);
            if (response.success) {
                if (this.workrequestClassId()) {
                    notifications.showNotification(getTranslation("Work request class successfully updated"), "success");
                } else {
                    notifications.showNotification(getTranslation("New work request class successfully added"), "success");
                    this.workrequestClassId(response.workrequest_class_id);
                }
                this.seed.forceReload();
                this.reloadRequired(true);
            } else {
                notifications.showNotification(response.message, "error");
            }
        }).catch(() => {
            this.submitInProgress(false);
            notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
        });
    };
}


export const showWorkrequestClassDetails = dialogStarter(WorkrequestClassDetailsViewModel, template, {
    name: "WorkrequestClassDetails",
    width: 600,
    height: "auto",
    anchor: {
        top: 20,
        right: 20,
    },
    closeOthers: true,
});
