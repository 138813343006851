/**
 * Show a popup to set the genetic background for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";

import {
    GetAnimalGeneticBackgroundResponse,
    AnimalsService,
    CancelablePromise,
    PupsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";
import { EmptyObject } from "../lib/utils";

import template from "./setGeneticBackground.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetGeneticBackgroundViewModel {
    private readonly dialog: HtmlDialog;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly geneticBackgroundId: Observable<number> = ko.observable();
    private readonly canSubmit: PureComputed<boolean>;
    private readonly fetchInProgress = ko.observable(true);
    private readonly submitInProgress= ko.observable(false);
    public readonly seed: Observable<GetAnimalGeneticBackgroundResponse | undefined> = ko.observable();

    constructor(dialog: HtmlDialog, params: Params) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.canSubmit = ko.pureComputed(() => !(this.submitInProgress() || this.fetchInProgress()));

        let request: CancelablePromise<GetAnimalGeneticBackgroundResponse>;
        if (this.animalId) {
            request = AnimalsService.getAnimalGeneticBackground({ animalId: this.animalId });
        } else if (this.pupId) {
            request = PupsService.getPupGeneticBackground({ pupId: this.pupId });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then((response) => {
                this.seed(response);
                this.geneticBackgroundId(response.genetic_background_id);
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    public submit = () => {
        this.submitInProgress(true);

        let request: CancelablePromise<EmptyObject>;
        if (this.animalId) {
            request = AnimalsService.setAnimalGeneticBackground({
                animalId: this.animalId,
                geneticBackgroundId: this.geneticBackgroundId(),
            });
        } else if (this.pupId) {
            request = PupsService.setPupGeneticBackground({
                pupId: this.pupId,
                geneticBackgroundId: this.geneticBackgroundId(),
            });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then(() => {
                this.dialog.close();
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
                notifications.showNotification(getTranslation("Genetic background updated"), "success");
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };
}

export const showSetGeneticBackground = htmlDialogStarter(SetGeneticBackgroundViewModel, template, params => ({
    name: "SetGeneticBackground",
    width: 400,
    title: params.title,
    position: { anchor: params.eventTarget },
}));
