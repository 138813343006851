import { TanksService } from "../../backend/v1";
import {
    showTankCrossingDetails,
    showTankDetails,
} from "../../dialogs";
import { writeException } from "../../lib/excepthook";
import {
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    SearchBox,
    setListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import {
    getUrl,
    printUrl,
} from "../../lib/utils";
import { pyratFrontend } from "../../pyratFrontend";

import { TankCrossingListFilterModel } from "./tankCrossingListFilter";
import tankCrossingListFilterTemplate from "./tankCrossingListFilter.html";

interface Arguments extends ListViewArguments {
    export_arguments: any;
}

class TankCrossingList {
    private listView: ListView;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        /* MenuBox buttons */
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);
        this.listView.onMenuBoxClick("remove-filter-button", () => resetListFilter(args.view_name));

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("export-to-excel-button", () => {
            showColumnSelect({
                mode: "export",
                viewName: args.view_name,
                exportArgs: args.export_arguments,
            });
        });

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: TankCrossingListFilterModel,
                filterTemplate: tankCrossingListFilterTemplate,
                title: getTranslation("Crossing filter"),
            });
        });

        /* Table column links */
        this.listView.onCellClick("td.crossing_id.clickable", (args) => {
            showTankCrossingDetails({
                crossingId: Number(args.rowId),
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
                reloadCallback: () => this.listView.reload({ flashRowId: args.rowId }),
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.children_tanks a, td.parent_tanks a", (args) => {
            this.listView.highlightRow(args.rowId);
            showTankDetails({
                tankId: parseInt(args.element.getAttribute("data-tank-id"), 10),
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
            });
        });

        this.listView.onCellClick("td.children_tanks_filter a, td.parent_tanks_filter a", (args) => {
            mainMenu.openAndResetListFilter("get_tank_list", {
                crossing_id: parseInt(args.rowId, 10),
                tank_id: String(args.element.getAttribute("data-tank-id"))
                    .split(",")
                    .map((item: any) => parseInt(item, 10)),
            });
        });

        this.listView.onCellClick("td.action input.delete_crossing", (args) => {
            args.element.classList.add("loading");
            TanksService.deleteCrossing({ crossingId: parseInt(args.rowId, 10) })
                .then(() => {
                    notifications.showNotification(getTranslation("Crossing deleted"), "success");
                    this.listView.reload({ flashRowId: args.rowId });
                })
                .catch((error) => {
                    writeException(error);
                    notifications.showNotification(
                        getTranslation("Action failed. The data could not be saved. Please try again."),
                        "error",
                    );
                })
                .finally(() => args.element.classList.remove("loading"));
        });

        this.listView.onCellClick("td.action input.discard_crossing", (args) => {
            args.element.classList.add("loading");
            TanksService.discardCrossing({ crossingId: parseInt(args.rowId, 10) })
                .then(() => {
                    notifications.showNotification(getTranslation("Crossing discarded"), "success");
                    this.listView.reload({ flashRowId: args.rowId });
                })
                .catch((error) => {
                    writeException(error);
                    notifications.showNotification(
                        getTranslation("Action failed. The data could not be saved. Please try again."),
                        "error",
                    );
                })
                .finally(() => args.element.classList.remove("loading"));
        });

        this.listView.onCellClick("td.action input.keep_crossing", (args) => {
            args.element.classList.add("loading");
            TanksService.keepCrossing({ crossingId: parseInt(args.rowId, 10) })
                .then(() => {
                    notifications.showNotification(getTranslation("Crossing kept"), "success");
                    this.listView.reload({ flashRowId: args.rowId });
                })
                .catch((error) => {
                    writeException(error);
                    notifications.showNotification(
                        getTranslation("Action failed. The data could not be saved. Please try again."),
                        "error",
                    );
                })
                .finally(() => args.element.classList.remove("loading"));
        });

        this.listView.onCellClick("td.repeat_crossing input.repeat_crossing", (args) => {
            args.element.classList.add("loading");
            notifications.showConfirm(
                getTranslation("Do you want to repeat crossing #<%-rowid%>?").replace("<%-rowid%>", args.rowId),
                () => {
                    TanksService.repeatCrossing({ crossingId: parseInt(args.rowId, 10) })
                        .then((response) => {
                            notifications.showNotification(getTranslation("Crossing repeated"), "success");
                            showTankCrossingDetails({
                                crossingId: response,
                                reloadCallback: () => this.listView.reload({ flashRowId: String(response) }),
                            });
                        })
                        .catch((error) => {
                            writeException(error);
                            notifications.showNotification(
                                getTranslation("Action failed. The data could not be saved. Please try again."),
                                "error",
                            );
                        })
                        .finally(() => args.element.classList.remove("loading"));
                },
            );
        });

        // initialize the barcode search box
        const barcodeFilterElement = listViewElement.querySelector(".searchbox .barcode_filter");
        if (barcodeFilterElement && pyratFrontend.session.pyratConf.BARCODE) {
            new SearchBox({
                element: barcodeFilterElement.parentElement,
                searchCode: "barcode",
                searchMode: "many",
                onSubmitCallback: (value) => {
                    setListFilter(args.view_name, { crossing_id: value });
                },
            });
        }
    }

}

export const initTankCrossingList = (args: Arguments): void => {
    new TankCrossingList(document.querySelector("div.listview"), args);
};
