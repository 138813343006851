/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_setPupGeneration } from '../models/Body_setPupGeneration';
import type { GetAnimalCohortIdResponse } from '../models/GetAnimalCohortIdResponse';
import type { GetAnimalEartagResponse } from '../models/GetAnimalEartagResponse';
import type { GetAnimalGenerationResponse } from '../models/GetAnimalGenerationResponse';
import type { GetAnimalGeneticBackgroundResponse } from '../models/GetAnimalGeneticBackgroundResponse';
import type { GetAnimalLabIdResponse } from '../models/GetAnimalLabIdResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PupsService {

    /**
     * Get Pup Cohort Id Endpoint
     * Get the cohort id for a pup.
     * @returns GetAnimalCohortIdResponse Successful Response
     * @throws ApiError
     */
    public static getPupCohortId({
        pupId,
    }: {
        pupId: number,
    }): CancelablePromise<GetAnimalCohortIdResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pups/{pup_id}/cohort_id',
            path: {
                'pup_id': pupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Pup Cohort Id Endpoint
     * Set the cohort id for a pup.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setPupCohortId({
        pupId,
        cohortId,
    }: {
        pupId: number,
        cohortId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pups/{pup_id}/cohort_id',
            path: {
                'pup_id': pupId,
            },
            query: {
                'cohort_id': cohortId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pup Eartag Endpoint
     * Get eartag for a pup.
     * @returns GetAnimalEartagResponse Successful Response
     * @throws ApiError
     */
    public static getPupEartag({
        pupId,
    }: {
        pupId: number,
    }): CancelablePromise<GetAnimalEartagResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pups/{pup_id}/eartag',
            path: {
                'pup_id': pupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Pup Eartag Endpoint
     * Set eartag for a pup.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setPupEartag({
        pupId,
        prefix,
        suffix,
    }: {
        pupId: number,
        prefix: string,
        suffix: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pups/{pup_id}/eartag',
            path: {
                'pup_id': pupId,
            },
            query: {
                'prefix': prefix,
                'suffix': suffix,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pup Generation Endpoint
     * Get generation for a pup.
     * @returns GetAnimalGenerationResponse Successful Response
     * @throws ApiError
     */
    public static getPupGeneration({
        pupId,
    }: {
        pupId: number,
    }): CancelablePromise<GetAnimalGenerationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pups/{pup_id}/generation',
            path: {
                'pup_id': pupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Pup Generation Endpoint
     * Set generation for a pup.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setPupGeneration({
        pupId,
        requestBody,
    }: {
        pupId: number,
        requestBody?: Body_setPupGeneration,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pups/{pup_id}/generation',
            path: {
                'pup_id': pupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pup Genetic Background Endpoint
     * Get genetic background for a pup.
     * @returns GetAnimalGeneticBackgroundResponse Successful Response
     * @throws ApiError
     */
    public static getPupGeneticBackground({
        pupId,
    }: {
        pupId: number,
    }): CancelablePromise<GetAnimalGeneticBackgroundResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pups/{pup_id}/genetic_background',
            path: {
                'pup_id': pupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Pup Genetic Background Endpoint
     * Set genetic background for a pup.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setPupGeneticBackground({
        pupId,
        geneticBackgroundId,
    }: {
        pupId: number,
        geneticBackgroundId?: (number | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pups/{pup_id}/genetic_background',
            path: {
                'pup_id': pupId,
            },
            query: {
                'genetic_background_id': geneticBackgroundId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pup Incompatible Sanitary Status Locations Endpoint
     * Get incompatible sanitary status locations for pups based on given new cage or rack id.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getPupIncompatibleSanitaryStatusLocations({
        pupIds,
        newCageId,
        newRackId,
    }: {
        pupIds: Array<number>,
        newCageId?: (number | null),
        newRackId?: (number | null),
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pups/incompatible_sanitary_status_locations',
            query: {
                'pup_ids': pupIds,
                'new_cage_id': newCageId,
                'new_rack_id': newRackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pup Lab Id Endpoint
     * Get lab id for a pup.
     * @returns GetAnimalLabIdResponse Successful Response
     * @throws ApiError
     */
    public static getPupLabId({
        pupId,
    }: {
        pupId: number,
    }): CancelablePromise<GetAnimalLabIdResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pups/{pup_id}/lab_id',
            path: {
                'pup_id': pupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Pup Lab Id Endpoint
     * Set lab id for a pup.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setPupLabId({
        pupId,
        labId,
    }: {
        pupId: number,
        labId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pups/{pup_id}/lab_id',
            path: {
                'pup_id': pupId,
            },
            query: {
                'lab_id': labId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
