import * as URI from "urijs";

import {
    resetListFilter,
    setListFilter,
} from "../listView";

/** Get the name of the currently active menu item */
export let currentItemName: string;
window.addEventListener("DOMContentLoaded", () => {
    const link: HTMLAnchorElement = document.querySelector("nav#main-menu a[data-is-active=true]");
    if (link) {
        currentItemName = link.dataset.name;
    }
});

/**
 * Open a menu item, optional with query parameters.
 *
 * This replaces former `mainMenu.subtabClick()` and `mainMenu.tabClick`() functions.
 *
 * @param itemName: Name of the menu item to open.
 * @param params: Optional query parameters to add to the URL.
 */
export const open = (itemName: string, params: { [key: string]: string | number | (string | number)[] } = {}) => {
    const link: HTMLAnchorElement = document.querySelector(`nav#main-menu a[data-name=${CSS.escape(itemName)}]`);
    const href = link?.href;
    if (href) {
        if (params) {
            window.location.href = URI(href).setSearch(params).toString();
        } else {
            window.location.href = href;
        }
    } else {
        throw `No menu item found for ${itemName}`;
    }
};

/**
 * Open a menu item and set new filter parameters for the view name of this menu item.
 *
 * Issues `navigate` but applies the filter parameters using the new filter back-end.
 *
 * @param itemName Name of the menu item to open.
 *
 * @param filterParams New filter parameters to apply.
 */
export const openAndSetListFilter = (itemName: string, filterParams: Record<string, any>): void => {
    const link: HTMLAnchorElement = document.querySelector(`nav#main-menu a[data-name=${CSS.escape(itemName)}]`);
    const viewName = link?.dataset?.listViewName;
    if (viewName) {
        setListFilter(viewName, filterParams, () => open(itemName, { page_start: 0 }));
    } else {
        throw `No menu item or list view name found for ${itemName}`;
    }
};

/**
 * Open a menu item and reset the filter parameters for the view name of this menu item.
 *
 * Issues `navigate` but applies the filter parameters using the new filter back-end.
 *
 * @param itemName Name of the menu item to open.
 *
 * @param filterParams New filter parameters to apply after reset or `null` for the default filter.
 */
export const openAndResetListFilter = (itemName: string, filterParams: Record<string, any> | null): void => {
    const link: HTMLAnchorElement = document.querySelector(`nav#main-menu a[data-name=${CSS.escape(itemName)}]`);
    const viewName = link?.dataset?.listViewName;
    if (viewName) {
        resetListFilter(viewName, filterParams, () => open(itemName, { page_start: 0 }));
    } else {
        throw `No menu item or list view name found for ${itemName}`;
    }
};
