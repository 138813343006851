import {
    Observable,
    pureComputed,
} from "knockout";

import { ListFilterDefinition } from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { mainMenu } from "../../lib/pyratTop";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./tankLicenseReportFilter.html";

import "./licenseReport.scss";

interface Arguments extends ListViewArguments {
    export_args: any;
}

// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = (filter: ListFilterModel) => ({
    assign_from: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("assign_to"), compareFromDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    assign_to: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("assign_from"), compareToDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    licence_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    classification_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            const licenseId = filter.allFilters().license_id;

            this.possibleValueArguments = pureComputed(() => {
                if (licenseId) {
                    return { license_id: licenseId.model.serialize() };
                }
            });

            this.text = pureComputed(() => {
                return seed.possible_values().map((item: any) => item.name);
            });
        }
    },
});

class List {
    private listView: ListView;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Tank license report filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        this.listView.onCellClick("td.clickable", (args) => {
            const dataset = args.element.querySelector("span").dataset;

            if (dataset.TankIds === undefined) {
                if (dataset.ClassificationId) {
                    mainMenu.openAndResetListFilter("get_tank_list", {
                        license_id: dataset.licenseId,
                        classification_id: dataset.classificationId,
                    });
                } else {
                    mainMenu.openAndResetListFilter("get_tank_list", {
                        license_id: dataset.licenseId,
                    });
                }
            } else if (dataset.TankIds) {
                mainMenu.openAndResetListFilter("get_tank_list", {
                    tank_id: dataset.tankIds,
                });
            }
        });
    }
}

export const initTankLicenseReport = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
