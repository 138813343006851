import {
    ListView,
    ListViewArguments,
    OrderBy,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import { getUrl } from "../../lib/utils";

class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: ListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("download-button", () => {
            const selectedRowIds = this.listView.getSelectedRowIds();
            if (selectedRowIds.length) {
                location.href = getUrl("./archive", { run_id: selectedRowIds });
            } else {
                notifications.showModal(getTranslation("Empty selection."));
            }
        });

        this.listView.onMenuBoxClick("back-button", () => {
            mainMenu.open("get_scheduled_reports_list");
        });
    }
}

export const initScheduledReportRuns = (args: ListViewArguments): void => {
    new List(document.querySelector("div.listview"), args);
};
