/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectBudgetResponse } from '../models/CreateProjectBudgetResponse';
import type { CreateProjectCategoryChange } from '../models/CreateProjectCategoryChange';
import type { DeleteProjectCategoryChange } from '../models/DeleteProjectCategoryChange';
import type { GetProjectDetailsResponse } from '../models/GetProjectDetailsResponse';
import type { ProjectForSetting } from '../models/ProjectForSetting';
import type { UpdateProjectCategoryChange } from '../models/UpdateProjectCategoryChange';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * Get Projects For Setting Endpoint
     * Get the list of projects for setting a project.
     * @returns ProjectForSetting Successful Response
     * @throws ApiError
     */
    public static getProjectsForSetting(): CancelablePromise<Array<ProjectForSetting>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/for_setting',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deactivate Project Endpoint
     * Deactivate a project.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deactivateProject({
        projectId,
    }: {
        projectId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{project_id}/active',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reactivate Project Endpoint
     * (Re)activate a project.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static activateProject({
        projectId,
    }: {
        projectId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects/{project_id}/active',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Project Endpoint
     * Delete a project.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteProject({
        projectId,
    }: {
        projectId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Project Endpoint
     * Update an existing project.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProject({
        projectId,
        name,
        ownerId,
        categoryId,
        expirationDate,
        budgetId,
        description,
    }: {
        projectId: number,
        name: string,
        ownerId: number,
        categoryId?: (number | null),
        expirationDate?: (string | null),
        budgetId?: (number | null),
        description?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            query: {
                'name': name,
                'owner_id': ownerId,
                'category_id': categoryId,
                'expiration_date': expirationDate,
                'budget_id': budgetId,
                'description': description,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Project Categories Endpoint
     * Update the project categories.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProjectCategories({
        requestBody,
    }: {
        requestBody: Array<(DeleteProjectCategoryChange | UpdateProjectCategoryChange | CreateProjectCategoryChange)>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects/categories',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Details Endpoint
     * Get project details.
     * @returns GetProjectDetailsResponse Successful Response
     * @throws ApiError
     */
    public static getProjectDetails({
        projectId,
    }: {
        projectId?: (number | null),
    }): CancelablePromise<GetProjectDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/details',
            query: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add User To Projec Endpont
     * Add a user to a project.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addUserToProject({
        projectId,
        userId,
    }: {
        projectId: number,
        userId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects/{project_id}/users',
            path: {
                'project_id': projectId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove User From Project Endpoint
     * Remove a user from a project.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeUserFromProject({
        projectId,
        userId,
    }: {
        projectId: number,
        userId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{project_id}/users/{user_id}',
            path: {
                'project_id': projectId,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Project Budget Endpoint
     * Create a new project budget.
     * @returns CreateProjectBudgetResponse Successful Response
     * @throws ApiError
     */
    public static createProjectBudget({
        name,
    }: {
        name: string,
    }): CancelablePromise<CreateProjectBudgetResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects/budgets',
            query: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Project Endpoint
     * Create a new project.
     *
     * :return: New project id.
     * @returns number Successful Response
     * @throws ApiError
     */
    public static createProject({
        name,
        ownerId,
        categoryId,
        expirationDate,
        budgetId,
        description,
    }: {
        name: string,
        ownerId: number,
        categoryId?: (number | null),
        expirationDate?: (string | null),
        budgetId?: (number | null),
        description?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects/',
            query: {
                'name': name,
                'owner_id': ownerId,
                'category_id': categoryId,
                'expiration_date': expirationDate,
                'budget_id': budgetId,
                'description': description,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
