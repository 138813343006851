import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";

import { ListFilterDefinition } from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    normalizeDate,
} from "../../lib/utils";


export const TankCrossingListFilterModel = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {
        crossing_id: ListFilterItem,
        description: ListFilterItem,
        parents_of_tank_id: ListFilterItem,
        children_of_tank_id: ListFilterItem,

        children_of_tank_id_level: class extends ListFilterItem {
            public disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                const childrenOfTankId = filter.allFilters().children_of_tank_id;

                this.disable = ko.pureComputed(() => {
                    return !(childrenOfTankId && childrenOfTankId.value());
                });

                this.serialize = () => {
                    const currentValue = value();

                    if (!currentValue && childrenOfTankId && childrenOfTankId.value()) {
                        return 2;  // default 2 generations
                    }
                    return currentValue !== "" ? currentValue : undefined;
                };
            }
        },

        classification_id: class extends ListFilterItem {
            public disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                const licenseId = filter.allFilters().license_id;

                this.possibleValueArguments = ko.pureComputed(() => {
                    if (licenseId) {
                        return { license_id: licenseId.model.serialize() };
                    }
                });

                this.disable = ko.pureComputed(() => {
                    return !(licenseId && licenseId.value());
                });

                this.text = ko.pureComputed(() => {
                    return seed.possible_values().map((item: any) => item.name);
                });
            }
        },

        date_of_raise_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("date_of_raise_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        date_of_raise_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("date_of_raise_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        date_of_record_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("date_of_record_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        date_of_record_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("date_of_record_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        date_of_set_up_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("date_of_set_up_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        date_of_set_up_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("date_of_set_up_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        license_id: class extends ListFilterItem {
            public readonly possibleValues: {id: number; name: string}[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
                this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(seed.possible_values);
            }
        },

        parents_of_tank_id_level: class extends ListFilterItem {
            public disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                const parentsOfTankId = filter.allFilters().parents_of_tank_id;

                this.disable = ko.pureComputed(() => {
                    return !(parentsOfTankId && parentsOfTankId.value());
                });

                this.serialize = () => {
                    const currentValue = value();

                    if (!currentValue && parentsOfTankId && parentsOfTankId.value()) {
                        return 2;  // default 2 generations
                    }
                    return currentValue !== "" ? currentValue : undefined;
                };
            }
        },

        responsible_id: class extends ListFilterItem {
            public possibleValues: { userid: number; fullname: string }[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.fullname);
                this.possibleValues = [{ userid: 0, fullname: getTranslation("None") }].concat(seed.possible_values);
            }
        },

        strain_name_or_id: class extends ListFilterItem {
            private readonly currentCustomValues: ObservableArray;
            private selectemValue: ObservableArray;

            public readonly possibleValues: {id: number; name_with_id: string}[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.currentCustomValues = ko.observableArray();
                this.selectemValue = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });

                this.possibleValues = [{ id: 0, name_with_id: getTranslation("None") }].concat(seed.possible_values);
                this.text = seed.possible_values?.map((item: any) => item.name_with_id);

                this.valid = ko.pureComputed(() => {
                    return this.selectemValue().every((option) => {
                        return !option.valid || option.valid();
                    });
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue)
                        ? newValue.filter((value) => { return typeof value === "string"; })
                        : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },
    };
};
