import * as ko from "knockout";

import {
    ColumnSelectUserView,
    ListViewService,
} from "../../backend/v1";
import { htmlDialogStarter } from "../../knockout/dialogStarter";
import { getTranslation } from "../localize";
import { HtmlDialog } from "../popups";
import {
    baseUrl,
    sendPostForm,
} from "../utils";

import template from "./columnSelect.html";

import "./columnSelect.scss";

interface SelectParams {
    viewName: string;
    mode?: "select";
}

interface ExportParams {
    viewName: string;
    mode: "export";
    exportArgs: any;
}

type Params = SelectParams | ExportParams;

interface Column {
    id: string;
    label: string;
    help?: string;
}

export class ColumnSelectModel {
    // parameters
    private readonly dialog: HtmlDialog;
    private readonly viewName: string;
    private readonly mode: "select" | "export";

    // interface elements
    private mandatoryColumnIds: string[] = [];
    private defaultColumnIds: string[] = [];
    private inProgress: ko.Observable<boolean> = ko.observable(false);
    private showUserViews: ko.Observable<boolean> = ko.observable(false);
    private userViews: ko.ObservableArray<ColumnSelectUserView> = ko.observableArray([]);
    private availableColumns: ko.ObservableArray<Column> = ko.observableArray();
    private chosenColumnIds: ko.ObservableArray<string> = ko.observableArray();
    private virtualSelectedAvailableColumnId: ko.Observable<string> = ko.observable();
    private virtualSelectedChosenColumnId: ko.Observable<string> = ko.observable();
    private selectedAvailableColumnIds: ko.ObservableArray<string> = ko.observableArray();
    private selectedChosenColumnIds: ko.ObservableArray<string> = ko.observableArray();
    private selectedUserViewId: ko.Observable<number | undefined> = ko.observable();
    private createUserViewName: ko.Observable<string> = ko.observable("");
    private deleteUserViewIds: ko.ObservableArray<number> = ko.observableArray();

    // for export mode
    private exportFilename: ko.Observable<string> | undefined;
    private exportArgs: Record<string, any> | undefined;

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;
        this.viewName = params.viewName;
        this.mode = params.mode || "select";

        if (params.mode === "export") {
            this.exportArgs = params.exportArgs;
            const today = new Date().toISOString().substring(0, 10);
            this.exportFilename = ko.observable(`${this.viewName}_${today}`);
        }

        this.selectedUserViewId.subscribe((userViewId) => {
            if (userViewId) {
                this.userViews().forEach((userView) => {
                    if (userView.id === userViewId) {
                        this.chosenColumnIds(userView.columns);
                    }
                });
            } else if (userViewId == 0) {
                this.chosenColumnIds(this.defaultColumnIds);
            }
        });

        this.inProgress(true);
        ListViewService.getColumnSelectParams({ viewName: this.viewName, mode: this.mode })
            .then((viewParams) => {
                this.mandatoryColumnIds = viewParams.mandatory_column_ids;
                this.defaultColumnIds = viewParams.default_column_ids;
                this.userViews(viewParams.user_views);
                Object.entries(viewParams.available_columns).forEach((column) => {
                    this.availableColumns.push({
                        id: column[0],
                        label: column[1].label,
                        help: column[1].help,
                    });
                });
                this.availableColumns.sort((a, b) => a.label.localeCompare(b.label));
                if (viewParams.selected_user_view_id) {
                    this.selectedUserViewId(viewParams.selected_user_view_id);
                } else {
                    this.chosenColumnIds(viewParams.default_column_ids);
                }
            })
            .finally(() => this.inProgress(false));

        document.addEventListener("keydown", (event) => {
            if (event.key === "ArrowUp") {
                if (event.shiftKey) {
                    this.extendSelectionUpwards();
                } else if (this.selectedChosenColumnIds().length > 0) {
                    this.moveSelectedChosenColumnsUp();
                }
                event.preventDefault();
            }
            if (event.key === "ArrowDown") {
                if (event.shiftKey) {
                    this.extendSelectionDownwards();
                } else if (this.selectedChosenColumnIds().length > 0) {
                    this.moveSelectedChosenColumnsDown();
                }
                event.preventDefault();
            }
            if (event.key === "ArrowRight" && this.selectedAvailableColumnIds().length > 0) {
                this.addSelectedColumns();
                event.preventDefault();
            }
            if (event.key === "ArrowLeft" && this.selectedChosenColumnIds().length > 0) {
                this.removeSelectedColumns();
                event.preventDefault();
            }
        });
    }

    private getUserViewName(userViewId: number): string {
        for (const userView of this.userViews()) {
            if (userView.id === userViewId) {
                return userView.name;
            }
        }
        return "";
    }

    public selectDefaultColumns = () => {
        this.selectedUserViewId(0);
    };

    public selectUserView = (userView: ColumnSelectUserView) => {
        this.selectedUserViewId(userView.id);
    };

    public selectAvailableColumn = (column: Column, event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.altKey || event.ctrlKey) {
            // with alt/ctrl pressed, select this column
            this.selectedAvailableColumnIds.push(column.id);
        } else if (event.shiftKey && this.virtualSelectedAvailableColumnId()) {
            // with shift pressed, select all columns between the virtual selection and the current selection
            const availableColumns = this.availableColumns().filter((c) => !this.chosenColumnIds().includes(c.id));
            const virtualIndex = availableColumns.findIndex((c) => c.id === this.virtualSelectedAvailableColumnId());
            const currentIndex = availableColumns.findIndex((c) => c.id === column.id);
            const minIndex = Math.min(virtualIndex, currentIndex);
            const maxIndex = Math.max(virtualIndex, currentIndex);
            const selectedIds = availableColumns.slice(minIndex, maxIndex + 1).map((c) => c.id);
            this.selectedAvailableColumnIds(selectedIds);
        } else {
            if (this.selectedAvailableColumnIds().includes(column.id)) {
                // second click on the same column adds it to the chosen columns
                this.addSelectedColumns();
            } else {
                // with single click select _only_ this column and place the virtual selection
                this.selectedAvailableColumnIds([column.id]);
                this.virtualSelectedAvailableColumnId(column.id);
                this.virtualSelectedChosenColumnId(undefined);
            }
        }
    };

    public clearAvailableColumnSelection = (model?: ColumnSelectModel, event?: MouseEvent) => {
        if (event?.shiftKey) {
            // shift key is used to expand the selection
            return;
        }
        if (this.selectedAvailableColumnIds().length > 0) {
            this.selectedAvailableColumnIds([]);
            this.virtualSelectedAvailableColumnId(undefined);
            this.virtualSelectedChosenColumnId(undefined);
        }
    };

    public selectChosenColumn = (column: Column, event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.altKey || event.ctrlKey) {
            this.selectedChosenColumnIds.push(column.id);
        } else if (event.shiftKey && this.virtualSelectedChosenColumnId()) {
            // with shift pressed, select all columns between the virtual selection and the current selection
            const chosenColumnIds = this.chosenColumnIds().filter((c) => !this.mandatoryColumnIds.includes(c));
            const virtualIndex = chosenColumnIds.findIndex((c) => c === this.virtualSelectedChosenColumnId());
            const currentIndex = chosenColumnIds.findIndex((c) => c === column.id);
            const minIndex = Math.min(virtualIndex, currentIndex);
            const maxIndex = Math.max(virtualIndex, currentIndex);
            const selectedIds = chosenColumnIds.slice(minIndex, maxIndex + 1);
            this.selectedChosenColumnIds(selectedIds);
        } else {
            if (this.selectedChosenColumnIds().includes(column.id)) {
                // second click on the same column removes it from the chosen columns
                this.removeSelectedColumns();
            } else {
                // with single click, select _only_ this column and place the virtual selection
                this.selectedChosenColumnIds([column.id]);
                this.virtualSelectedAvailableColumnId(undefined);
                this.virtualSelectedChosenColumnId(column.id);
            }
        }
    };

    public clearChosenColumnSelection = (model?: ColumnSelectModel, event?: MouseEvent) => {
        if (event?.shiftKey) {
            // shift key is used to expand the selection
            return;
        }
        if (this.selectedChosenColumnIds().length > 0) {
            this.selectedChosenColumnIds([]);
            this.virtualSelectedAvailableColumnId(undefined);
            this.virtualSelectedChosenColumnId(undefined);
        }
    };

    public addSelectedColumns = () => {
        let insertAtIndex = this.chosenColumnIds().findIndex((columnId) =>
            this.selectedChosenColumnIds().includes(columnId),
        );
        if (insertAtIndex === -1) {
            insertAtIndex = this.chosenColumnIds().length;
        } else {
            // we want to insert after the first selected column
            insertAtIndex++;
        }
        const columnsIdsToInsert = this.selectedAvailableColumnIds();
        const columnsToInsert = this.availableColumns().filter((column) => columnsIdsToInsert.includes(column.id));
        columnsToInsert.reverse();
        columnsToInsert.forEach((column) => {
            this.chosenColumnIds.splice(insertAtIndex, 0, column.id);
        });
        this.selectedAvailableColumnIds([]);
        this.selectedChosenColumnIds(columnsIdsToInsert);
        this.selectedUserViewId(undefined);
    };

    public removeSelectedColumns = () => {
        this.selectedAvailableColumnIds([]);
        this.selectedChosenColumnIds().forEach((columnId) => {
            if (!this.mandatoryColumnIds.includes(columnId)) {
                this.chosenColumnIds.remove(columnId);
                this.selectedAvailableColumnIds.push(columnId);
            }
        });
        this.selectedChosenColumnIds([]);
        this.selectedUserViewId(undefined);
    };

    public moveSelectedChosenColumnsUp = () => {
        const selectedColumnIds = this.selectedChosenColumnIds();
        const columnIds = this.chosenColumnIds();
        for (let i = 0; i < columnIds.length; i++) {
            const columnId = columnIds[i];
            if (selectedColumnIds.includes(columnId)) {
                if (i > 0) {
                    const previousColumnId = columnIds[i - 1];
                    if (!selectedColumnIds.includes(previousColumnId)) {
                        this.chosenColumnIds.splice(i - 1, 2, columnId, previousColumnId);
                    }
                }
            }
        }
        this.selectedUserViewId(undefined);
    };

    public moveSelectedChosenColumnsDown = () => {
        const selectedColumnIds = this.selectedChosenColumnIds();
        const columnIds = this.chosenColumnIds();
        for (let i = columnIds.length - 1; i >= 0; i--) {
            const columnId = columnIds[i];
            if (selectedColumnIds.includes(columnId)) {
                if (i < columnIds.length - 1) {
                    const nextColumnId = columnIds[i + 1];
                    if (!selectedColumnIds.includes(nextColumnId)) {
                        this.chosenColumnIds.splice(i, 2, nextColumnId, columnId);
                    }
                }
            }
        }
        this.selectedUserViewId(undefined);
    };

    // Select by using a virtual cursor while holding shift
    public extendSelectionUpwards = () => {
        if (this.virtualSelectedAvailableColumnId()) {
            // move virtual selection up, ignoring chosen columns
            const availableColumns = this.availableColumns().filter(
                (column) => !this.chosenColumnIds().includes(column.id),
            );
            const virtualSelectedColumnIndex = availableColumns.findIndex(
                (column) => column.id === this.virtualSelectedAvailableColumnId(),
            );
            if (virtualSelectedColumnIndex > 0) {
                const previousColumnId = availableColumns[virtualSelectedColumnIndex - 1].id;
                if (this.selectedAvailableColumnIds().includes(previousColumnId)) {
                    this.selectedAvailableColumnIds.remove(this.virtualSelectedAvailableColumnId());
                } else {
                    this.selectedAvailableColumnIds.push(previousColumnId);
                }
                this.virtualSelectedAvailableColumnId(previousColumnId);
            }
        }
        if (this.virtualSelectedChosenColumnId()) {
            // move virtual selection up, ignoring mandatory columns
            const chosenColumnIds = this.chosenColumnIds().filter(
                (columnId) => !this.mandatoryColumnIds.includes(columnId),
            );
            const virtualSelectedColumnIndex = chosenColumnIds.findIndex(
                (columnId) => columnId === this.virtualSelectedChosenColumnId(),
            );
            if (virtualSelectedColumnIndex > 0) {
                const previousColumnId = chosenColumnIds[virtualSelectedColumnIndex - 1];
                if (this.selectedChosenColumnIds().includes(previousColumnId)) {
                    this.selectedChosenColumnIds.remove(this.virtualSelectedChosenColumnId());
                } else {
                    this.selectedChosenColumnIds.push(previousColumnId);
                }
                this.virtualSelectedChosenColumnId(previousColumnId);
            }
        }
    };
    public extendSelectionDownwards = () => {
        if (this.virtualSelectedAvailableColumnId()) {
            // move virtual selection down, ignoring chosen columns
            const availableColumns = this.availableColumns().filter(
                (column) => !this.chosenColumnIds().includes(column.id),
            );
            const virtualSelectedColumnIndex = availableColumns.findIndex(
                (column) => column.id === this.virtualSelectedAvailableColumnId(),
            );
            if (virtualSelectedColumnIndex < availableColumns.length - 1) {
                const nextColumnId = availableColumns[virtualSelectedColumnIndex + 1].id;
                if (this.selectedAvailableColumnIds().includes(nextColumnId)) {
                    this.selectedAvailableColumnIds.remove(this.virtualSelectedAvailableColumnId());
                } else {
                    this.selectedAvailableColumnIds.push(nextColumnId);
                }
                this.virtualSelectedAvailableColumnId(nextColumnId);
            }
        }
        if (this.virtualSelectedChosenColumnId()) {
            // move virtual selection down, ignoring mandatory columns
            const chosenColumnIds = this.chosenColumnIds().filter(
                (columnId) => !this.mandatoryColumnIds.includes(columnId),
            );
            const virtualSelectedColumnIndex = chosenColumnIds.findIndex(
                (columnId) => columnId === this.virtualSelectedChosenColumnId(),
            );
            if (virtualSelectedColumnIndex < chosenColumnIds.length - 1) {
                const nextColumnId = chosenColumnIds[virtualSelectedColumnIndex + 1];
                if (this.selectedChosenColumnIds().includes(nextColumnId)) {
                    this.selectedChosenColumnIds.remove(this.virtualSelectedChosenColumnId());
                } else {
                    this.selectedChosenColumnIds.push(nextColumnId);
                }
                this.virtualSelectedChosenColumnId(nextColumnId);
            }
        }
    };

    /** Select by mouse click and drag while holding shift */
    public mouseOverAvailableColumn = (column: Column, event: MouseEvent) => {
        if (event.shiftKey && event.buttons === 1) {
            if (!this.selectedAvailableColumnIds().includes(column.id)) {
                this.selectedAvailableColumnIds.push(column.id);
            } else {
                this.selectedAvailableColumnIds.remove(column.id);
            }
            this.virtualSelectedAvailableColumnId(column.id);
            this.virtualSelectedChosenColumnId(undefined);
            event.preventDefault();
            event.stopPropagation();
        }
    };
    public mouseOverChosenColumn = (column: Column, event: MouseEvent) => {
        if (event.shiftKey && event.buttons === 1) {
            if (!this.selectedChosenColumnIds().includes(column.id)) {
                this.selectedChosenColumnIds.push(column.id);
            } else {
                this.selectedChosenColumnIds.remove(column.id);
            }
            this.virtualSelectedAvailableColumnId(undefined);
            this.virtualSelectedChosenColumnId(column.id);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    public addAllColumns = () => {
        this.availableColumns().forEach((column) => {
            if (!this.chosenColumnIds().includes(column.id)) {
                this.chosenColumnIds.push(column.id);
            }
        });
        this.selectedUserViewId(undefined);
    };

    public removeAllColumns = () => {
        this.chosenColumnIds(this.chosenColumnIds().filter((columnId) => this.mandatoryColumnIds.includes(columnId)));
        this.selectedUserViewId(undefined);
    };

    public applySelection = () => {
        this.inProgress(true);
        const userViewId = this.getUserViewName(this.selectedUserViewId()) ? this.selectedUserViewId() : null;
        ListViewService.setColumnSelection({
            viewName: this.viewName,
            requestBody: {
                selected_columns: this.chosenColumnIds(),
                for_export: false,
                selected_user_view_id: userViewId,
                create_user_view_name: this.createUserViewName(),
                delete_user_view_ids: this.deleteUserViewIds(),
            },
        })
            .then(() => {
                window.location.reload();
                this.dialog.close();
            })
            .finally(() => this.inProgress(false));
    };

    public exportSelection = () => {
        this.inProgress(true);
        const userViewId = this.getUserViewName(this.selectedUserViewId()) ? this.selectedUserViewId() : null;
        ListViewService.setColumnSelection({
            viewName: this.viewName,
            requestBody: {
                selected_columns: this.chosenColumnIds(),
                for_export: true,
                selected_user_view_id: userViewId,
                create_user_view_name: this.createUserViewName(),
                delete_user_view_ids: this.deleteUserViewIds(),
            },
        })
            .then(() => {
                sendPostForm(
                    baseUrl("frontend/list_view/" + encodeURIComponent(this.viewName) + "/csv_export"),
                    {
                        filename: this.exportFilename(),
                        export_args: JSON.stringify(this.exportArgs),
                    },
                    { target: "_blank" },
                );
                this.inProgress(false);
                this.dialog.close();
            })
            .catch(() => this.inProgress(false));
    };
}

export const showColumnSelect = htmlDialogStarter(ColumnSelectModel, template, (params) => ({
    name: "ColumnSelect",
    title: params.mode == "export" ? getTranslation("Export columns") : getTranslation("Select columns"),
    width: 620,
    position: { inset: { right: 25, top: 25 } },
}));
