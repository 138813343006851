import {
    observable,
    Observable,
    observableArray,
    ObservableArray,
    pureComputed,
    PureComputed,
    Subscribable,
} from "knockout";
import * as _ from "lodash";

import {
    IdNameProperty,
    LicensesService,
    ListFilterDefinition,
    StrainNameDetailsWithGroup,
    StrainsService,
} from "../../backend/v1";
import { FetchBackendExtended } from "../../knockout/extensions/fetchBackend";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListFilterMutationsItem,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    normalizeDate,
} from "../../lib/utils";


export const AnimalListFilters = (filter: ListFilterModel) => {
    // TODO: Add sufficient typing for all strain filters
    // use one observable for the active and inactive strain filter
    const ownerStrains: FetchBackendExtended<ObservableArray<StrainNameDetailsWithGroup>> = observableArray([]).extend({
        fetchBackend: () => {
            if (filter.allFilters().owner_id && filter.seed()) {
                return StrainsService.getStrainsByUsageForOwners({
                    ownerIds: filter.allFilters().owner_id.model.serialize(),
                    filterName: filter.seed().view_name === "animallist" ? "animal" : "pup",
                });
            }
        },
    });
    const ownerStrainsSeparated = pureComputed(() => {
        const strains = ownerStrains() || [];
        const separatedStrains: any = { active: [], inactive: [] };

        for (let i = 0, n = strains.length; i < n; i++) {
            const s = strains[i];

            if (s.group === "active") {
                separatedStrains.active.push(s);
            } else if (s.group === "inactive") {
                separatedStrains.inactive.push(s);
            }
        }

        return separatedStrains;
    });

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {
        owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "userfullname") as string[];
            }
        },

        owner_user_group_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        responsible_id: class extends ListFilterItem {
            private possibleValues: PureComputed<{ fullname: string; userid: number }[]>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValueArguments = pureComputed(() => {
                    if (filter.allFilters().owner_id) {
                        return {
                            owner_ids: filter.allFilters().owner_id.model.serialize(),
                        };
                    }
                });

                this.possibleValues = pureComputed(() => {
                    return _.union([{ userid: 0, fullname: getTranslation("None") }], seed.possible_values());
                });

                this.text = pureComputed(() => {
                    return _.map(seed.possible_values(), "fullname") as string[];
                });
            }
        },

        eartag: ListFilterItem,

        eartag_past: ListFilterItem,

        pupid: ListFilterItem,

        eartagmin: ListFilterItem,

        eartagmax: ListFilterItem,

        parent_eartag: ListFilterItem,

        unrelated_eartag: ListFilterItem,

        cohort_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.id);
            }
        },

        labid: ListFilterItem,

        labid_from: ListFilterItem,

        labid_to: ListFilterItem,

        comment: ListFilterItem,

        comment_date_from: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_to"), compareFromDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        comment_date_to: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_from"), compareToDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        reply_pending: ListFilterItem,

        cagetype: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "cagetype_i18n");
            }
        },

        cage_category_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        cagenumber: ListFilterItem,

        cagenumberfrom: ListFilterItem,

        cagenumberto: ListFilterItem,

        cage_label_like: ListFilterItem,

        cage_location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => this.initialized() === true || this.serialize() === seed.default_value;
            }
        },

        pregnancy: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        sex: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        birth_id: ListFilterItem,

        birth_date_from: class extends ListFilterItem {
            public value: CheckExtended<Observable<string>>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("birth_date_to"), compareFromDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        birth_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable<string>>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("birth_date_from"), compareToDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        age_months_from: ListFilterItem,

        age_months_to: ListFilterItem,

        age_weeks_from: ListFilterItem,

        age_weeks_to: ListFilterItem,

        age_days_from: ListFilterItem,

        age_days_to: ListFilterItem,

        is_fetus: ListFilterItem,

        species: class extends ListFilterItem {
            public readonly staticValues: { name: string; id: number }[];
            public readonly possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        gen_bg: class extends ListFilterItem {
            public readonly staticValues: { name: string; id: number }[];
            public readonly possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        strain_name_or_id: class extends ListFilterItem {
            private inProgress: Observable<boolean>;
            private disable: Subscribable<boolean>;
            private selectemValue: ObservableArray;
            private currentCustomValues: ObservableArray;
            private possibleValues: PureComputed;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = pureComputed(() => {
                    return _.map(ownerStrainsSeparated().active, "name_with_id");
                });

                this.inProgress = ownerStrains.inProgress;
                this.disable = ownerStrains.inProgress;
                this.selectemValue = observableArray();
                this.currentCustomValues = observableArray();

                this.selectemValue.subscribe((newValue) => {
                    const strainOfficialNameFilter = filter.allFilters().strain_official_name;
                    let newSelectedOfficialNames;

                    newValue.forEach((option) => {
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });

                    // @ts-expect-error: strainOfficialNameFilter model needs to implement the disable method
                    if (strainOfficialNameFilter && !strainOfficialNameFilter.model.disable()) {
                        newSelectedOfficialNames = _.chain(newValue)
                            .filter((strainData) => {
                                // leave out the custom values, their official names are not known in advance
                                return strainData.id !== strainData.name_with_id;
                            })
                            .map((strainData) => {
                                return strainData.official_name || null;
                            })
                            .uniq()
                            .value();
                        strainOfficialNameFilter.model.deserialize(
                            newSelectedOfficialNames.length ? newSelectedOfficialNames : undefined,
                        );
                    }
                });
                this.possibleValues = pureComputed(() => {
                    return ownerStrainsSeparated().active;
                });
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.valid = pureComputed(() => {
                    return this.selectemValue().every((option) => {
                        return !option.valid || option.valid();
                    });
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue)
                        ? newValue.filter((value) => {
                            return typeof value === "string";
                        })
                        : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },

        inactive_strain_name_or_id: class extends ListFilterItem {
            private inProgress: Observable<boolean>;
            private disable: Subscribable<boolean>;
            private selectemValue: ObservableArray;
            private readonly currentCustomValues: ObservableArray;
            private possibleValues: PureComputed;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = pureComputed(() => {
                    return _.map(ownerStrainsSeparated().inactive, "name_with_id");
                });

                this.inProgress = ownerStrains.inProgress;
                this.disable = ownerStrains.inProgress;
                this.selectemValue = observableArray();
                this.currentCustomValues = observableArray();

                this.selectemValue.subscribe((newValue) => {
                    const inactiveStrainOfficialNameFilter = filter.allFilters().inactive_strain_official_name;
                    let newSelectedOfficialNames;

                    newValue.forEach((option) => {
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });

                    // @ts-expect-error: strainOfficialNameFilter model needs to implement the disable method
                    if (inactiveStrainOfficialNameFilter && !inactiveStrainOfficialNameFilter.model.disable()) {
                        newSelectedOfficialNames = _.chain(newValue)
                            .filter((strainData) => {
                                // leave out the custom values, their official names are not known in advance
                                return strainData.id !== strainData.name_with_id;
                            })
                            .map((strainData) => {
                                return strainData.official_name || null;
                            })
                            .uniq()
                            .value();
                        inactiveStrainOfficialNameFilter.model.deserialize(
                            newSelectedOfficialNames.length ? newSelectedOfficialNames : undefined,
                        );
                    }
                });
                this.possibleValues = pureComputed(() => {
                    return ownerStrainsSeparated().inactive;
                });
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.valid = pureComputed(() => {
                    return this.selectemValue().every((option) => {
                        return !option.valid || option.valid();
                    });
                });
                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue)
                        ? newValue.filter((value) => {
                            return typeof value === "string";
                        })
                        : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },

        strain_official_name: class extends ListFilterItem {
            private inProgress: Observable<boolean>;
            private readonly disable: Subscribable<boolean>;
            private selectemValue: ObservableArray;
            private possibleValues: PureComputed;
            private staticValues: { name: string; id: null }[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = pureComputed(() => {
                    return _.map(_.filter(ownerStrainsSeparated().active, "official_name"), "official_name");
                });

                this.inProgress = ownerStrains.inProgress;
                this.disable = pureComputed(() => {
                    // disable the official name when some custom strain is selected;
                    // the official name is not known in advance therefore all official
                    // names must be accepted
                    const strainNameOrIdFilter = filter.allFilters().strain_name_or_id;

                    return (
                        strainNameOrIdFilter &&
                        // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                        _.some(strainNameOrIdFilter.model.selectemValue(), (strainData) => {
                            return strainData.id === strainData.name_with_id;
                        })
                    );
                });
                this.disable.subscribe((newValue) => {
                    const strainNameOrIdFilter = filter.allFilters().strain_name_or_id;

                    if (newValue) {
                        this.deserialize(undefined);
                    } else if (strainNameOrIdFilter) {
                        // restore the selected items
                        // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                        strainNameOrIdFilter.model.selectemValue.valueHasMutated();
                    }
                });
                this.selectemValue = observableArray();
                this.selectemValue.subscribe((newValue) => {
                    const strainNameOrIdFilter = filter.allFilters().strain_name_or_id;
                    let newSelectedStrainIds;

                    if (!this.disable() && strainNameOrIdFilter) {
                        newSelectedStrainIds = _.chain(ownerStrainsSeparated().active)
                            .filter((strainData) => {
                                const selectedByOfficialName = _.some(newValue, (officialNameData) => {
                                    return (
                                        (!strainData.official_name && !officialNameData.id) ||
                                        strainData.official_name === officialNameData.id
                                    );
                                });
                                const selectedByNameWithId = _.some(
                                    // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                                    strainNameOrIdFilter.model.selectemValue(),
                                    (strainNameData) => {
                                        return strainData.id === strainNameData.id;
                                    },
                                );
                                const missingInStrainSelect = _.every(
                                    // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                                    strainNameOrIdFilter.model.selectemValue(),
                                    (strainNameData) => {
                                        return (
                                            (strainData.official_name !== strainNameData.official_name &&
                                                (strainData.official_name || strainNameData.official_name)) ||
                                            strainNameData.id === strainNameData.name_with_id
                                        );
                                    },
                                );

                                return selectedByOfficialName && (selectedByNameWithId || missingInStrainSelect);
                            })
                            .concat(
                                // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                                _.filter(strainNameOrIdFilter.model.selectemValue(), (strainNameData) => {
                                    return strainNameData.id === strainNameData.name_with_id;
                                }),
                            )
                            .map("id")
                            .value();

                        strainNameOrIdFilter.model.deserialize(
                            newSelectedStrainIds.length ? newSelectedStrainIds : undefined,
                        );
                    }
                });
                this.staticValues = [{ id: null, name: getTranslation("None") }];
                this.possibleValues = pureComputed(() => {
                    const possibleValues = _.chain(ownerStrainsSeparated().active)
                        .filter("official_name")
                        .map((strainData) => {
                            return {
                                id: strainData.official_name,
                                name: strainData.official_name,
                            };
                        })
                        .uniqBy("id")
                        .sortBy((strainData) => {
                            return strainData.name.toLowerCase();
                        })
                        .value();

                    return this.staticValues.concat(possibleValues);
                });
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.valid = () => {
                    return !ownerStrains.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        inactive_strain_official_name: class extends ListFilterItem {
            private inProgress: Observable<boolean>;
            private readonly disable: Subscribable<boolean>;
            private selectemValue: ObservableArray;
            private possibleValues: PureComputed;
            private staticValues: { name: string; id: null }[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = pureComputed(() => {
                    return _.map(_.filter(ownerStrainsSeparated().inactive, "official_name"), "official_name");
                });

                this.inProgress = ownerStrains.inProgress;
                this.disable = pureComputed(() => {
                    // disable the official name when some custom strain is selected;
                    // the official name is not known in advance therefore all official
                    // names must be accepted
                    const inactiveStrainNameOrIdFilter = filter.allFilters().inactive_strain_name_or_id;

                    return (
                        inactiveStrainNameOrIdFilter &&
                        // @ts-expect-error: inactiveStrainNameOrIdFilter model needs to implement the selectemValue method
                        _.some(inactiveStrainNameOrIdFilter.model.selectemValue(), (strainData) => {
                            return strainData.id === strainData.name_with_id;
                        })
                    );
                });
                this.disable.subscribe((newValue) => {
                    const inactiveStrainNameOrIdFilter = filter.allFilters().inactive_strain_name_or_id;

                    if (newValue) {
                        this.deserialize(undefined);
                    } else if (inactiveStrainNameOrIdFilter) {
                        // restore the selected items
                        // @ts-expect-error: inactiveStrainNameOrIdFilter model needs to implement the selectemValue method
                        inactiveStrainNameOrIdFilter.model.selectemValue.valueHasMutated();
                    }
                });
                this.selectemValue = observableArray();
                this.selectemValue.subscribe((newValue) => {
                    const inactiveStrainNameOrIdFilter = filter.allFilters().inactive_strain_name_or_id;
                    let newSelectedStrainIds;

                    if (!this.disable() && inactiveStrainNameOrIdFilter) {
                        newSelectedStrainIds = _.chain(ownerStrainsSeparated().inactive)
                            .filter((strainData) => {
                                const selectedByOfficialName = _.some(newValue, (officialNameData) => {
                                    return (
                                        (!strainData.official_name && !officialNameData.id) ||
                                        strainData.official_name === officialNameData.id
                                    );
                                });
                                const selectedByNameWithId = _.some(
                                    // @ts-expect-error: inactiveStrainNameOrIdFilter model needs to implement the selectemValue method
                                    inactiveStrainNameOrIdFilter.model.selectemValue(),
                                    (strainNameData) => {
                                        return strainData.id === strainNameData.id;
                                    },
                                );
                                const missingInStrainSelect = _.every(
                                    // @ts-expect-error: inactiveStrainNameOrIdFilter model needs to implement the selectemValue method
                                    inactiveStrainNameOrIdFilter.model.selectemValue(),
                                    (strainNameData) => {
                                        return (
                                            (strainData.official_name !== strainNameData.official_name &&
                                                (strainData.official_name || strainNameData.official_name)) ||
                                            strainNameData.id === strainNameData.name_with_id
                                        );
                                    },
                                );

                                return selectedByOfficialName && (selectedByNameWithId || missingInStrainSelect);
                            })
                            .concat(
                                // @ts-expect-error: inactiveStrainNameOrIdFilter model needs to implement the selectemValue method
                                _.filter(inactiveStrainNameOrIdFilter.model.selectemValue(), (strainNameData) => {
                                    return strainNameData.id === strainNameData.name_with_id;
                                }),
                            )
                            .map("id")
                            .value();

                        inactiveStrainNameOrIdFilter.model.deserialize(
                            newSelectedStrainIds.length ? newSelectedStrainIds : undefined,
                        );
                    }
                });
                this.staticValues = [{ id: null, name: getTranslation("None") }];
                this.possibleValues = pureComputed(() => {
                    const possibleValues = _.chain(ownerStrainsSeparated().inactive)
                        .filter("official_name")
                        .map((strainData) => {
                            return {
                                id: strainData.official_name,
                                name: strainData.official_name,
                            };
                        })
                        .uniqBy("id")
                        .sortBy((strainData) => {
                            return strainData.name.toLowerCase();
                        })
                        .value();

                    return this.staticValues.concat(possibleValues);
                });
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.valid = () => {
                    return !ownerStrains.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        strain_genetic_background_id: class extends ListFilterItem {
            public readonly possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((value: IdNameProperty) => value.name);
                this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(seed.possible_values);
            }
        },

        pup_strain_id: class extends ListFilterItem {
            private inProgress: Observable<boolean>;
            private disable: Subscribable<boolean>;
            private readonly possibleValues: FetchBackendExtended<ObservableArray<StrainNameDetailsWithGroup>>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValues = observableArray();
                this.possibleValues.extend({
                    fetchBackend: () => {
                        if (filter.allFilters().owner_id) {
                            return StrainsService.getStrainsByUsageForOwners({
                                ownerIds: filter.allFilters().owner_id.model.serialize(),
                                pupStrains: true,
                            });
                        }
                    },
                });
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.inProgress = this.possibleValues.inProgress;
                this.disable = this.possibleValues.inProgress;

                this.text = pureComputed(() => {
                    return _.map(this.possibleValues(), "name_with_id");
                });

                this.valid = () => {
                    return !this.possibleValues.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        strain_severity_level_id: class extends ListFilterItem {
            public readonly possibleValues: { id: number; label: string }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValues = [
                    { id: 0, label: getTranslation("None") },
                    { id: -1, label: getTranslation("All with severity level") },
                ].concat(seed.possible_values);

                this.text = seed.possible_values?.map((option: any) => option.label);
            }
        },

        unbound: ListFilterItem,

        strain_ongoing_severity_assessment: ListFilterItem,

        has_strain_severity_assessment_sheets: ListFilterItem,

        has_classification_severity_assessment_sheets: ListFilterItem,

        severity_assessment_current_score_min: ListFilterItem,

        severity_assessment_current_score_max: ListFilterItem,

        severity_assessment_maximum_score_min: ListFilterItem,

        severity_assessment_maximum_score_max: ListFilterItem,

        wellplate_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        genetic_status: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        harmful_phenotype: ListFilterItem,

        mutation_grade: class extends ListFilterMutationsItem {
            strainNameOrIdFilter = pureComputed(() => {
                return filter.allFilters().strain_name_or_id;
            });
        },

        weight_min: ListFilterItem,

        weight_max: ListFilterItem,

        import_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("import_date_to"), compareFromDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        import_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("import_date_from"), compareToDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        wean_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("wean_date_to"), compareFromDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        wean_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("wean_date_from"), compareToDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        wean_location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.valid = () => {
                    return this.initialized() === true || this.serialize() === seed.default_value;
                };
            }
        },

        birth_location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.valid = () => {
                    return this.initialized() === true || this.serialize() === seed.default_value;
                };
            }
        },

        subgen: ListFilterItem,
        newgen: ListFilterItem,
        subgen2: ListFilterItem,
        newgen2: ListFilterItem,
        addgen: ListFilterItem,

        last_order_request_id: ListFilterItem,

        last_order_request_delivery_date_from: class extends ListFilterItem {
            public value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) =>
                        checkDateRangeField(
                            v,
                            () => filter.getValue("last_order_request_delivery_date_to"),
                            compareFromDate,
                        ),
                });
            }
            public valid = () => this.value.isValid();
        },

        last_order_request_delivery_date_to: class extends ListFilterItem {
            public value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) =>
                        checkDateRangeField(
                            v,
                            () => filter.getValue("last_order_request_delivery_date_from"),
                            compareToDate,
                        ),
                });
            }
            public valid = () => this.value.isValid();
        },

        origin: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = _.map(seed.possible_values, "name");
            }
        },

        medical_condition_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: PureComputed;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("Normal") }];
                this.possibleValues = pureComputed(() => {
                    if (
                        (filter.allFilters().medical_condition_date_from &&
                            filter.allFilters().medical_condition_date_from.model.serialize()) ||
                        (filter.allFilters().medical_condition_date_to &&
                            filter.allFilters().medical_condition_date_to.model.serialize())
                    ) {
                        return seed.possible_values;
                    }

                    return this.staticValues.concat(seed.possible_values);
                });
                this.text = _.map(seed.possible_values, "name");
            }
        },

        medical_condition_date_from: class extends ListFilterItem {
            public value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) =>
                        checkDateRangeField(v, () => filter.getValue("medical_condition_date_to"), compareFromDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        medical_condition_date_to: class extends ListFilterItem {
            public value: CheckExtended<Observable>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) =>
                        checkDateRangeField(v, () => filter.getValue("medical_condition_date_from"), compareToDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        project_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.concat(
                    _.map(seed.possible_values, "name"),
                    _.map(seed.possible_values, "owner_fullname"),
                );
            }
        },

        licence_type_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = _.map(seed.possible_values, "name");
            }
        },

        licence_id: class extends ListFilterItem {
            private staticLicenses: { name: string; id: number }[];
            private possibleLicenses: PureComputed<{ name: string; id: number }[]>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticLicenses = [{ id: 0, name: getTranslation("None") }];
                this.possibleLicenses = pureComputed(() => {
                    return _.union(this.staticLicenses, seed.possible_values());
                });

                this.text = _.map(seed.possible_values, "name");
            }
        },

        classification_id: class extends ListFilterItem {
            private licenseId: PureComputed<number>;
            private possibleClassifications: FetchBackendExtended<ObservableArray>;
            private inProgress: Observable<boolean>;
            private disable: Observable<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.licenseId = pureComputed(() => {
                    // for some reason params.allFilters().licence_id.value() fires twice with the same value (if set)
                    // which would cause possibleClassifications async extender to fetch classification list twice.
                    // By serving licence_id.value() through a pureComputed here, we avoid that because pureComputed
                    // does not fire a change/update multiple times if the value is still the same.
                    return filter.allFilters().licence_id && filter.allFilters().licence_id.value();
                });
                this.possibleClassifications = observableArray();
                this.possibleClassifications.extend({
                    fetchBackend: () => {
                        if (this.licenseId()) {
                            return LicensesService.getLicenseClassificationOptions({
                                licenseId: this.licenseId(),
                                which: "for_filter",
                            });
                        }
                    },
                });
                this.possibleClassifications.subscribeOnce(() => {
                    value(seed.current_value);
                });

                this.inProgress = this.possibleClassifications.inProgress;
                this.disable = this.possibleClassifications.inProgress;

                // update search string in order to find select options (wordings) via filter search
                this.text = pureComputed(() => {
                    return _.map(this.possibleClassifications(), "name");
                });

                this.valid = () => {
                    return !this.possibleClassifications.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        severity_level_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = _.map(seed.possible_values, "label");
            }
        },

        licence_assign: class extends ListFilterItem {
            public licenseIdValue: Observable<number>;
            public classificationIdValue: Observable<number>;
            public severityLevelIdValue: Observable<number>;
            public assignDateFromValue: CheckExtended<Observable<string>>;
            public assignDateToValue: CheckExtended<Observable<string>>;
            public staticLicenses: { name: string; id: number }[];
            public possibleLicenses: PureComputed<{ name: string; id: number }[]>;
            public licenseText: PureComputed<string[]>;
            public possibleClassifications: FetchBackendExtended<Observable>;
            public classificationText: PureComputed<string[]>;
            public severityLevelText: PureComputed<string[]>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.licenseIdValue = observable();
                this.classificationIdValue = observable();
                this.severityLevelIdValue = observable();
                this.assignDateFromValue = observable().extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => this.assignDateToValue(), compareFromDate),
                });
                this.assignDateToValue = observable().extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => this.assignDateFromValue(), compareToDate),
                });

                this.staticLicenses = [{ id: 0, name: getTranslation("None") }];
                this.possibleLicenses = pureComputed(() => {
                    return _.union(this.staticLicenses, seed.possible_values() && seed.possible_values().licence);
                });
                this.licenseText = pureComputed(() => {
                    if (seed.possible_values()) {
                        return _.map(seed.possible_values().licence, "name");
                    }
                });

                this.possibleClassifications = observable().extend({
                    fetchBackend: () => {
                        if (seed.possible_values.inProgress && seed.possible_values.inProgress()) {
                            return;
                        }
                        if (this.licenseIdValue()) {
                            return LicensesService.getLicenseClassificationOptions({
                                licenseId: this.licenseIdValue(),
                                which: "for_filter",
                            });
                        }
                    },
                });

                this.possibleClassifications.subscribeOnce(() => {
                    _.defer(() => {
                        this.classificationIdValue(
                            (seed.current_value && seed.current_value.classification) || undefined,
                        );
                        this.classificationIdValue.valueHasMutated();
                    });
                });

                this.classificationText = pureComputed(() => {
                    return _.map(this.possibleClassifications(), "name");
                });

                this.severityLevelText = pureComputed(() => {
                    if (seed.possible_values()) {
                        return _.map(seed.possible_values().severity_level_id, "label");
                    }
                });

                this.text = pureComputed(() => {
                    return _.flattenDeep([this.licenseText(), this.classificationText(), this.severityLevelText()]);
                });

                this.valid = () =>
                    !this.possibleClassifications.inProgress() &&
                    !seed.possible_values.inProgress() &&
                    this.assignDateFromValue.isValid() &&
                    this.assignDateToValue.isValid();
            }

            public deserialize = (newValue: any) => {
                // set dates before license, because otherwise they are overwritten by the subscribe callback of licenseIdValue
                this.assignDateFromValue(newValue ? newValue.assign_from : undefined);
                this.assignDateToValue(newValue ? newValue.assign_to : undefined);

                this.licenseIdValue(newValue ? newValue.licence : undefined);
                this.classificationIdValue(newValue ? newValue.classification : undefined);
                this.severityLevelIdValue(newValue ? newValue.severity_level_id : undefined);
            };

            public serialize = () => {
                const licenseAssign: any = {};

                if (this.licenseIdValue() || this.licenseIdValue() === 0) {
                    licenseAssign.licence = this.licenseIdValue();
                }
                if (this.classificationIdValue() || this.classificationIdValue() === 0) {
                    licenseAssign.classification = this.classificationIdValue();
                }
                if (this.severityLevelIdValue() !== undefined) {
                    licenseAssign.severity_level_id = this.severityLevelIdValue();
                }
                if (this.assignDateFromValue()) {
                    licenseAssign.assign_from = this.assignDateFromValue();
                }
                if (this.assignDateToValue()) {
                    licenseAssign.assign_to = this.assignDateToValue();
                }

                return _.isEmpty(licenseAssign) ? undefined : licenseAssign;
            };
        },

        procedure: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [
                    { id: -1, name: getTranslation("All with procedure") },
                    { id: 0, name: getTranslation("None") },
                ];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        procedure_date_from: class extends ListFilterItem {
            public value: CheckExtended<Observable<string>>;
            public disable: PureComputed<boolean>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("procedure_date_to"), compareFromDate),
                });
                this.disable = pureComputed(() => {
                    return filter.allFilters().procedure && filter.allFilters().procedure.model.serialize() === 0;
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        procedure_date_to: class extends ListFilterItem {
            value: CheckExtended<Observable<string>>;
            private disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("procedure_date_from"), compareToDate),
                });

                this.disable = pureComputed(() => {
                    return filter.allFilters().procedure && filter.allFilters().procedure.model.serialize() === 0;
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        last_procedure_date_from: class extends ListFilterItem {
            public value: CheckExtended<Observable<string>>;
            public disable: PureComputed<boolean>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("last_procedure_date_to"), compareFromDate),
                });
                this.disable = pureComputed(() => {
                    return filter.allFilters().procedure && filter.allFilters().procedure.model.serialize() === 0;
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        last_procedure_date_to: class extends ListFilterItem {
            value: CheckExtended<Observable<string>>;
            private disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("last_procedure_date_from"), compareToDate),
                });

                this.disable = pureComputed(() => {
                    return filter.allFilters().procedure && filter.allFilters().procedure.model.serialize() === 0;
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },
        procedure_comment: class extends ListFilterItem {
            private disable: PureComputed<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.disable = pureComputed(() => {
                    return filter.allFilters().procedure && filter.allFilters().procedure.model.serialize() === 0;
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
        },

        workrequest: ListFilterItem,

        state: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = _.map(seed.possible_values, "name");
            }
        },

        export_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable<string>>;
            private disable: PureComputed<boolean>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("export_date_to"), compareFromDate),
                });
                this.disable = pureComputed(() => {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "exported"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        export_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable<string>>;
            private disable: PureComputed<boolean>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("export_date_from"), compareToDate),
                });
                this.disable = pureComputed(() => {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "exported"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        facility: class extends ListFilterItem {
            private disable: PureComputed<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
                this.disable = pureComputed(function () {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "exported"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
        },

        sacrifice_reason_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            private disable: PureComputed<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.map(seed.possible_values, "name");

                this.disable = pureComputed(function () {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "sacrificed"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
        },

        sacrifice_method_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            private disable: PureComputed<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.map(seed.possible_values, "name");

                this.disable = pureComputed(function () {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "sacrificed"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
        },

        sacrifice_date_from: class extends ListFilterItem {
            private disable: PureComputed<boolean>;
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("sacrifice_date_to"), compareFromDate),
                });
                this.disable = pureComputed(function () {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "sacrificed"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
            public valid = () => this.value.isValid();
        },

        sacrifice_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable<string>>;
            private disable: PureComputed<boolean>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("sacrifice_date_from"), compareToDate),
                });
                this.disable = pureComputed(function () {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "sacrificed"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
            public valid = () => this.value.isValid();
        },

        sacrifice_comment: class extends ListFilterItem {
            disable: PureComputed<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.disable = pureComputed(() => {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "sacrificed"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
        },

        sacrifice_location: class extends ListFilterLocationItem {
            private disable: PureComputed<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => {
                    return this.initialized() === true || this.serialize() === seed.default_value;
                };
                this.disable = pureComputed(function () {
                    return (
                        filter.allFilters().state &&
                        _.indexOf(["all", "sacrificed"], filter.allFilters().state.model.serialize()) === -1
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
        },

        plugged: ListFilterItem,

        plug_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            private disable: PureComputed<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("plug_date_to"), compareFromDate),
                });
                this.disable = pureComputed(() => {
                    return filter.allFilters().plugged && filter.allFilters().plugged.model.serialize() === "no";
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        plug_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            private disable: PureComputed<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("plug_date_from"), compareToDate),
                });
                this.disable = pureComputed(() => {
                    return filter.allFilters().plugged && filter.allFilters().plugged.model.serialize() === "no";
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        stud_status_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        stud_active: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        stud_minplug: ListFilterItem,
        stud_maxplug: ListFilterItem,

        stud_minbreed: ListFilterItem,
        stud_maxbreed: ListFilterItem,

        stud_lastplug: ListFilterItem,

        stud_lastbreed: ListFilterItem,

        breeding_setup_strain_id: class extends ListFilterItem {
            private inProgress: Observable<boolean>;
            private disable: Subscribable<boolean>;
            private readonly possibleValues: FetchBackendExtended<ObservableArray<StrainNameDetailsWithGroup>>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValues = observableArray();
                this.possibleValues.extend({
                    fetchBackend: () => {
                        if (filter.allFilters().owner_id) {
                            return StrainsService.getStrainsByUsageForOwners({
                                ownerIds: filter.allFilters().owner_id.model.serialize(),
                                pupStrains: true,
                            });
                        }
                    },
                });
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.inProgress = this.possibleValues.inProgress;
                this.disable = this.possibleValues.inProgress;

                this.text = pureComputed(() => {
                    return _.map(this.possibleValues(), "name_with_id");
                });

                this.valid = () => {
                    return !this.possibleValues.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        breeding_setup_responsible_id: class extends ListFilterItem {
            private possibleValues: PureComputed<{ fullname: string; userid: number }[]>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValueArguments = pureComputed(() => {
                    if (filter.allFilters().owner_id) {
                        return {
                            owner_ids: filter.allFilters().owner_id.model.serialize(),
                        };
                    }
                });

                this.possibleValues = pureComputed(() => {
                    return _.union([{ userid: 0, fullname: getTranslation("None") }], seed.possible_values());
                });

                this.text = pureComputed(() => {
                    return _.map(seed.possible_values(), "fullname") as string[];
                });
            }
        },

        breeding_setup_license_id: class extends ListFilterItem {
            private staticLicenses: { name: string; id: number }[];
            private possibleLicenses: PureComputed<{ name: string; id: number }[]>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.possibleLicenses = seed.possible_values();
                this.text = _.map(seed.possible_values, "name");
            }
        },

        breeding_setup_classification_id: class extends ListFilterItem {
            private licenseId: PureComputed<number>;
            private possibleClassifications: FetchBackendExtended<ObservableArray>;
            private inProgress: Observable<boolean>;
            private disable: Observable<boolean>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.licenseId = pureComputed(() => {
                    return filter.allFilters().breeding_setup_license_id && filter.allFilters().breeding_setup_license_id.value();
                });
                this.possibleClassifications = observableArray();
                this.possibleClassifications.extend({
                    fetchBackend: () => {
                        if (this.licenseId()) {
                            return LicensesService.getLicenseClassificationOptions({
                                licenseId: this.licenseId(),
                                which: "for_filter",
                            });
                        }
                    },
                });
                this.possibleClassifications.subscribeOnce(() => {
                    value(seed.current_value);
                });

                this.inProgress = this.possibleClassifications.inProgress;
                this.disable = this.possibleClassifications.inProgress;

                // update search string in order to find select options (wordings) via filter search
                this.text = pureComputed(() => {
                    return _.map(this.possibleClassifications(), "name");
                });

                this.valid = () => {
                    return !this.possibleClassifications.inProgress() || this.serialize() === seed.default_value;
                };
            }
        },

        breeding_setup_project_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.concat(
                    _.map(seed.possible_values, "name"),
                    _.map(seed.possible_values, "owner_fullname"),
                );
            }
        },

        page_size: ListFilterItem,
    };
};
