import * as ko from "knockout";

import { ListFilterDefinition } from "../../backend/v1";
import {
    showCryotankBrowser,
    showCryotankDetails,
} from "../../dialogs";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { mainMenu } from "../../lib/pyratTop";
import {
    getUrl,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./cryotankListFilter.html";

interface CryotankListViewArguments extends ListViewArguments {
    export_args: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CryotankListFilters = (filter: ListFilterModel) => ({
    status: class extends ListFilterItem {
        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((v: { id: string; name: string }) => v.name);
        }
    },
});

class CryotankList {
    private listView: ListView;
    private args: CryotankListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: CryotankListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: CryotankListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Cryotank filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("create-button", () => {
            this.showCryotankDetails(undefined, undefined);
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, {
                show_print: "true",
            }));
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.export_args,
            });
        });

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        // Table Body

        this.listView.onCellClick("td.show_button a", (args) => {
            this.listView.highlightRow(args.rowId);

            showCryotankBrowser({
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
                cryotankId: parseInt(args.rowId, 10),
                allowEditPathSettings: true,
            });
        });

        this.listView.onCellClick("td.name a", (args) => {
            this.showCryotankDetails(args.rowId, parseInt(args.rowId, 10));
        });

        this.listView.onCellClick("td.embryo_count a", (args) => {
            mainMenu.openAndResetListFilter("get_embryo_list", {
                cryotank_id: args.rowId,
                state_id: parseInt(args.element.dataset.embryoCryopreservationStateId, 10),
            });
        });

        this.listView.onCellClick("td.sperm_volume a", (args) => {
            mainMenu.openAndResetListFilter("get_sperm_list", {
                cryotank_id: args.rowId,
                state_id: parseInt(args.element.dataset.spermCryopreservationStateId, 10),
            });
        });
    }

    public reload = (rowId?: string) => {
        this.listView.reload({
            flashRowId: rowId,
        });
    };

    public showCryotankDetails = (highlightRowId: string, cryotankId: number) => {
        this.listView.highlightRow(highlightRowId);

        showCryotankDetails({
            closeCallback: () => this.listView.unHighlightRow(highlightRowId),
            reloadCallback: () => {
                this.reload(highlightRowId);
            },
            cryotankId: cryotankId,
        });
    };
}

export const initCryotankList = (args: CryotankListViewArguments): void => {
    new CryotankList(document.querySelector("div.listview"), args);
};
