import { Observable } from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";
import { pyratFrontend } from "../../pyratFrontend";

import filterTemplate from "./exportReportListFilter.html";


import "./exportReportList.scss";


interface Arguments extends ListViewArguments {
    export_args: any;
}


const ExportReportListFilters = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {

        export_date_from: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("export_date_to"), compareFromDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        export_date_to: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("export_date_from"), compareToDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "fullname");
            }
        },

        species_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        institution_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },
    };
};


class ExportReportList {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            this.showFilter(args);
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        // Table Body

        this.listView.onCellClick("td.animals a", (args) => {
            pyratFrontend.top.mainMenu.openAndResetListFilter("get_animal_list", {
                animalid: _.map(String(args.element.getAttribute("data-animal-ids")).split(","), _.partial(parseInt, _, 10)),
            });
        });

        this.listView.onCellClick("td.pups a", (args) => {
            pyratFrontend.top.mainMenu.openAndResetListFilter("get_pup_list", {
                pupid: _.map(String(args.element.getAttribute("data-pup-ids")).split(","), _.partial(parseInt, _, 10)),
            });
        });
    }

    public showFilter = (args: Arguments) => {
        showListFilter({
            viewName: args.view_name,
            filterModels: ExportReportListFilters,
            filterTemplate: filterTemplate,
            title: getTranslation("Report filter"),
        });
    };
}

export const initExportReportList = (args: Arguments): void => {
    new ExportReportList(document.querySelector("div.listview"), args);
};
