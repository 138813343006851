import { Observable } from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { frames } from "../../lib/pyratTop";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";
import { pyratFrontend } from "../../pyratFrontend";

import filterTemplate from "./sacrificeReportListFilter.html";


interface Arguments extends ListViewArguments {
    export_args: any;
}


const SacrificeReportListFilters = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {

        sacrifice_date_from: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("sacrifice_date_to"), compareFromDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        sacrifice_date_to: class extends ListFilterItem<string> {
            private value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("sacrifice_date_from"), compareToDate),
                });
            }
            public valid = () => {
                return this.value.isValid();
            };
        },

        age_state: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "fullname");
            }
        },

        species: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        strain_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name_with_id");
            }
        },

        sacrifice_reason_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        sacrifice_method_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        sacrifice_comment: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
            }
        },

        cage_location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => {
                    return this.initialized() === true || this.serialize() === seed.default_value;
                };
            }
        },
    };
};


class SacrificeReportList {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            this.showFilter(args);
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        // Table Body

        this.listView.onCellClick("td.eartag_or_id a", (args) => {
            this.listView.highlightRow(args.rowId);
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                frames.openListDetailPopup(
                    getUrl(cgiScript("pupdetail.py"), {
                        animalid: parseInt(args.rowId.substring(1), 10),
                    }),
                    () => this.listView.unHighlightRow(args.rowId),
                );
            } else {
                frames.openListDetailPopup(
                    getUrl(cgiScript("mousedetail.py"), {
                        animalid: parseInt(args.rowId, 10),
                    }),
                    () => this.listView.unHighlightRow(args.rowId),
                );
            }
        });

        this.listView.onCellClick("td.cagenumber a", (args) => {
            this.listView.highlightRow(args.rowId);
            frames.openListDetailPopup(
                getUrl(cgiScript("cagedetail.py"), {
                    cageid: parseInt(args.element.getAttribute("cageid"), 10),
                }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });

        this.listView.onCellClick("td.last_order_request_id a", (args) => {
            const orderRequestId= parseInt((args.event.target as HTMLAnchorElement).dataset.order_id, 10);
            if (orderRequestId) {
                this.listView.highlightRow(args.rowId);
                pyratFrontend.dialogs.showOrderRequestDetails({
                    orderRequestId: orderRequestId,
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                });
            }
        });
    }

    public showFilter = (args: Arguments) => {
        showListFilter({
            viewName: args.view_name,
            filterModels: SacrificeReportListFilters,
            filterTemplate: filterTemplate,
            title: getTranslation("Report filter"),
        });
    };
}

export const initSacrificeReportList = (args: Arguments): void => {
    new SacrificeReportList(document.querySelector("div.listview"), args);
};
