import {
    applyBindings,
    observable,
    Observable,
    observableArray,
    ObservableArray,
    pureComputed,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    CreateProjectCategoryChange,
    DeleteProjectCategoryChange,
    ProjectsService,
    UpdateProjectCategoryChange,
} from "../backend/v1";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { notifications } from "../lib/pyratTop";

type ProjectCategoryChange = CreateProjectCategoryChange | DeleteProjectCategoryChange | UpdateProjectCategoryChange;

interface Arguments {
    categories: {
        id: number;
        name: string;
        available: boolean;
        deletable: boolean;
    }[];
    allow_create: boolean;
    allow_edit: boolean;
    max_length: number;
}

interface Row {
    id?: number;
    isNew: boolean;
    toggleDelete: ProjectCategoryList["toggleDelete"];
    deleted: Observable<boolean>;
    available: Observable<boolean>;
    deletable: boolean;
    category: CheckExtended<Observable<string>>;
    changed: Observable<boolean>;
}
class ProjectCategoryList {
    private readonly allowCreate: boolean;
    private readonly allowEdit: boolean;
    private readonly maxLength: number;
    private readonly hideUnavailable: Observable<boolean>;
    private readonly inProgress: Observable<boolean>;
    private readonly errorMessage: PureComputed<string | boolean>;
    private readonly categories: ObservableArray<Row>;
    private readonly projectCategoryChanges: PureComputed<ProjectCategoryChange[]>;

    constructor(args: Arguments) {
        this.allowCreate = args.allow_create;
        this.allowEdit = args.allow_edit;
        this.maxLength = args.max_length;

        this.hideUnavailable = observable(true);
        this.inProgress = observable(false);

        this.categories = observableArray(
            _.map(args.categories || [], (row) => {
                const categoryModel = {
                    id: row.id,
                    category: observable(row.name).extend({
                        trim: true,
                        invalid: (value) => {
                            if (!value) {
                                return getTranslation("Please enter a name");
                            }

                            return false;
                        },
                    }),
                    available: observable(row.available),
                    deletable: row.deletable,
                    isNew: false,
                    changed: observable(false),
                    deleted: observable(false),
                    toggleDelete: this.toggleDelete,
                };
                const handleChange = () => {
                    categoryModel.changed(true);
                    this.highlightSaveButton();
                };

                categoryModel.category.subscribe(handleChange);
                categoryModel.available.subscribe(handleChange);

                return categoryModel;
            }),
        );

        this.errorMessage = pureComputed(() => {
            return _.reduce(
                this.categories(),
                (msg, row) => {
                    if (!row.deleted() && row.changed()) {
                        row.category.notifySubscribers(row.category());
                        return msg || row.category.errorMessage();
                    }

                    return msg;
                },
                false,
            );
        });

        this.projectCategoryChanges = pureComputed(() => {
            return _.chain(this.categories())
                .filter((row) => {
                    return row.changed() || row.deleted();
                })
                .map((row) => {
                    if (row.deleted()) {
                        // DeleteProjectCategory
                        return {
                            id: row.id,
                            delete_category: true,
                        };
                    } else if (row.id) {
                        // UpdateProjectCategory
                        return {
                            id: row.id,
                            name: row.category(),
                            deleted: !row.available(),
                        };
                    } else {
                        // CreateProjectCategory
                        return {
                            name: row.category(),
                            deleted: !row.available(),
                        };
                    }
                })
                .value();
        });
    }

    public highlightSaveButton = () => {
        const saveButton = document.getElementById("save_button");

        saveButton.classList.add("blink_twice");
        saveButton.addEventListener("animationend", function () {
            this.classList.remove("blink_twice");
        });
    };

    toggleDelete = (row: Row) => {
        if (!row.id) {
            this.categories.remove(row);
        } else {
            row.deleted(!row.deleted());
            if (row.deleted()) {
                this.highlightSaveButton();
            }
        }
    };

    public addCategory = () => {
        this.categories.push({
            category: observable().extend({
                trim: true,
                invalid: (value) => {
                    if (!value) {
                        return getTranslation("Please enter a name");
                    }

                    return false;
                },
            }),
            available: observable(true),
            deletable: true,
            isNew: true,
            changed: observable(true),
            deleted: observable(false),
            toggleDelete: this.toggleDelete,
        });
        this.highlightSaveButton();
    };

    public saveChanges = () => {
        if (this.errorMessage()) {
            return;
        }
        this.inProgress(true);
        ProjectsService.updateProjectCategories({ requestBody: this.projectCategoryChanges() })
            .then(() => {
                this.reload();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
                    writeException(reason);
                }
            })
            .finally(() => {
                this.inProgress(false);
            });
    };

    public reload = () => {
        window.location.reload();
    };
}

export const initProjectCategoryList = (args: Arguments): void => {
    applyBindings(new ProjectCategoryList(args), document.getElementById("project_category_list"));
};
