import * as ko from "knockout";
import { Observable } from "knockout";

import { getTranslation } from "../../../lib/localize";

import {
    CagePickerParams,
    CageId,
    CageItem,
} from "./cagePicker";
import {
    LocationPickerParams,
    PreselectLocationItem,
    LocationIdString,
    LocationItem,
} from "./locationPicker";

/**
 * Location-Cage-Picker (inline) Component
 *
 * It ties together the location and the cage picker as one component.
 *
 * Params inherited from location-picker / cage-picker.
 *
 * @param selectedCageTitle - ko.observable that will be updated with a string
 * of the currently selected location and cage to be used as title attribute
 * for an element. value will be something like:
 * "Building: G\nArea: EG\nRoom: 22\nRack: AE22-R\nRack Row: 13\nCage: BTES-00006"
 */

export interface LocationCagePickerParams extends LocationPickerParams, CagePickerParams {
    selectedCageTitle?: Observable<string>;
    cleanupTrigger?: Observable;
}

class LocationCagePickerViewModel {

    private pickerClassName: string;
    private _withinPopup: boolean;
    private parentLocation: LocationIdString;
    private selectedLocation: Observable<LocationItem>;
    private selectedCage: Observable<CageItem>;
    private preselectLocation: PreselectLocationItem;
    private preselectCage: CageId;
    private unselectLocationTrigger: Observable;
    private unselectCageTrigger: Observable;
    private cleanupTrigger: Observable;
    private selectedLocationTitle: Observable<string>;
    private selectedCageTitle: Observable<string>;
    private rememberSelectionTrigger: Observable;
    private restoreSelectionTrigger: Observable;
    private initialized: Observable<false | "loading" | true>;

    constructor(params: LocationCagePickerParams) {
        this.pickerClassName = params.pickerClassName;
        this._withinPopup = params._withinPopup;
        this.parentLocation = params.parentLocation;
        this.selectedLocation = params.selectedLocation;
        this.selectedCage = params.selectedCage;
        this.preselectLocation = params.preselectLocation;
        this.preselectCage = params.preselectCage;
        this.unselectLocationTrigger = params.unselectLocationTrigger;
        this.unselectCageTrigger = params.unselectCageTrigger;
        this.cleanupTrigger = ko.isObservable(params.cleanupTrigger) ? params.cleanupTrigger : ko.observable();
        this.selectedLocationTitle = ko.isObservable(params.selectedLocationTitle) ? params.selectedLocationTitle : ko.observable();
        this.selectedCageTitle = ko.isObservable(params.selectedCageTitle) ? params.selectedCageTitle : ko.observable();
        this.rememberSelectionTrigger = ko.isObservable(params.rememberSelectionTrigger) ? params.rememberSelectionTrigger : ko.observable();
        this.restoreSelectionTrigger = ko.isObservable(params.restoreSelectionTrigger) ? params.restoreSelectionTrigger : ko.observable();
        this.initialized = ko.isObservable(params.initialized) ? params.initialized : ko.observable();
        this.selectedCage.subscribe(() => {
            let title = "";
            const selectedCage = this.selectedCage();
            if (selectedCage instanceof Object) {
                title = this.selectedLocationTitle();
                title += "\n" + getTranslation("Cage") + ": " + selectedCage.cagenumber;
            }
            this.selectedCageTitle(title);
        });
    }
}

export class LocationCagePickerComponent {

    constructor() {
        return {
            viewModel: LocationCagePickerViewModel,
            template: `
                <div class="location-cage-picker">
                    <ko-location-picker params="pickerClassName: pickerClassName,
                                                cleanLocationsTrigger: cleanupTrigger,
                                                unselectLocationTrigger: unselectLocationTrigger,
                                                selectedLocationTitle: selectedLocationTitle,
                                                selectType: 'cage',
                                                parentLocation: parentLocation,
                                                preselectLocation: preselectLocation,
                                                selectedLocation: selectedLocation,
                                                _withinPopup: _withinPopup,
                                                rememberSelectionTrigger: rememberSelectionTrigger,
                                                restoreSelectionTrigger: restoreSelectionTrigger,
                                                initialized: initialized"></ko-location-picker>
                    <ko-cage-picker params="pickerClassName: pickerClassName,
                                            location: selectedLocation,
                                            parentLocation: parentLocation,
                                            preselectCage: preselectCage,
                                            selectedCage: selectedCage,
                                            unselectCageTrigger: unselectCageTrigger,
                                            showCageTrigger: cleanupTrigger,
                                            rememberSelectionTrigger: rememberSelectionTrigger,
                                            restoreSelectionTrigger: restoreSelectionTrigger"></ko-cage-picker>
                </div>
            `,
        };
    }

}
