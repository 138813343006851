/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdNameProperty } from '../models/IdNameProperty';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MutationsService {

    /**
     * Deactivate Mutation Endpoint
     * Deactivate a mutation.
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static deactivateMutation({
        mutationId,
    }: {
        mutationId: number,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/mutations/{mutation_id}/active',
            path: {
                'mutation_id': mutationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Activate Mutation Endpoint
     * Activate a mutation.
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static activateMutation({
        mutationId,
    }: {
        mutationId: number,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/mutations/{mutation_id}/active',
            path: {
                'mutation_id': mutationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Mutation Grades
     * Get grade list according to the specified mutation id.
     * @returns IdNameProperty Successful Response
     * @throws ApiError
     */
    public static getMutationGrades({
        mutationId,
    }: {
        mutationId: number,
    }): CancelablePromise<Array<IdNameProperty>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/mutations/{mutation_id}/grades',
            path: {
                'mutation_id': mutationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
