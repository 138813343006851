import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
} from "knockout";

import {
    ApiError,
    CancelablePromise as BackendV1OpenApiTypeScriptCodeGenPromise,
} from "../../backend/v1";
import { ArbitraryValue } from "../../lib/utils";

type ClientFunction<T> = () => BackendV1OpenApiTypeScriptCodeGenPromise<T> | ArbitraryValue<T>;


declare module "knockout" {

    // noinspection JSUnusedGlobalSymbols
    export interface ExtendersOptions<T> {
        fetchBackend?: ClientFunction<T>;
    }

}

interface ExtenderObservables {
    inProgress?: Observable<boolean>;
    lastSuccess?: Observable<Date | undefined>;
    forceReload?: () => void;
    onCatch?: (e: ApiError) => void;
}

export type FetchBackendExtended<T> = T & ExtenderObservables;


/** Extender to fetch data from a backend and fill an observable.
 *
 * The given function will should return a Promise, to update the observables value.
 *
 * Only if the status code is 2xx the value gets updated! To handle errors, use the
 * onCatch function, called with the error object in all other cases, where status
 * and body are available to distinguish different errors.
 *
 * The extended observable will have the following additional properties:
 *
 *     inProgress:
 *          An observable that will be set to true while waiting for a new value
 *          to indicate loading status of the target observable.
 *
 *     lastSuccess:
 *          An observable, that contains the Date() of the last successful
 *          evaluation (e.g. to show how recent data is) or undefined until that.
 *
 *     forceReload:
 *          A function to call to force data reload.
 *
 *     onCatch:
 *          A function to call if the promise threw an error. In this case, the value
 *          gets not updated.
 *
 * Example usage with imported type and client function the backend:
 *
 *     ```typescript
 *
 *     import * as ko from "knockout";
 *     import {FetchBackendExtended} from "../knockout/extensions/backendClient";
 *
 *     interface SubjectData {
 *         …
 *     }
 *
 *     import {LicensesService} from "../backend/v1";
 *     import {LicenseOption} from "../backend/v1";
 *     class ModelWithBackendClient {
 *
 *         private availableLicenses: FetchBackendExtended<ko.ObservableArray<LicenseOption>>;
 *
 *         constructor() {
 *
 *             this.speciesId = ko.observable(1);
 *
 *             // It's recommended to declare the observable and extend on a different line,
 *             // so typescript can infer the observable type.
 *             this.availableLicenses = ko.observableArray();
 *             this.availableLicenses.extend({
 *                 fetchBackend: () => LicensesService.getLicenseOptions({
 *                     speciesId: this.speciesId(),
 *                 }),
 *             });
 *
 *         }
 *     }
 *     ```
 *
 * */
ko.extenders.fetchBackend = <T>(
    target: FetchBackendExtended<Observable<T>> | FetchBackendExtended<ObservableArray<T>>,
    fn: ClientFunction<T>,
) => {

    let currentPromise: Promise<T> | undefined;
    const trigger = ko.observable().extend({ notify: "always" });

    target.inProgress = ko.observable(false);
    target.lastSuccess = ko.observable(undefined);
    target.forceReload = () => {
        trigger.valueHasMutated();
    };

    ko.computed(() => {


        // allow to force reevaluation by changing this value
        trigger();

        target.inProgress(true);
        const promise = fn();
        currentPromise = promise;

        // Promise from an openapi-typescript-codegen client for Backend-v1
        if (promise instanceof BackendV1OpenApiTypeScriptCodeGenPromise) {
            promise
                .then((data) => {
                    if (currentPromise === promise) {
                        target(data);
                        target.lastSuccess(new Date());
                    }
                })
                .catch((e) => {
                    if (currentPromise === promise) {
                        if (typeof target.onCatch === "function") {
                            target.onCatch(e);
                        } else {
                            throw e;
                        }
                    }
                })
                .finally(() => {
                    if (currentPromise === promise) {
                        target.inProgress(false);
                    }
                });
        }

        else if (promise instanceof ArbitraryValue) {
            promise.then((value) => {
                target(value);
                target.lastSuccess(new Date());
                target.inProgress(false);
            });
        }

        // To avoid making a query, the function can also return empty.
        else if (!promise) {
            target.inProgress(false);
        }

        // Unexpected type returned from the function
        else {
            throw new Error("Unexpected type for fetchBackend extender.");
        }
    });

    return target;

};
