/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnouncementDetails } from '../models/AnnouncementDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnnouncementsService {

    /**
     * Get Announcement
     * Get an announcement by ID.
     *
     * :param announcement_id: The database ID of the announcement.
     * @returns AnnouncementDetails Successful Response
     * @throws ApiError
     */
    public static getAnnouncement({
        announcementId,
    }: {
        announcementId: number,
    }): CancelablePromise<AnnouncementDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/announcements/{announcement_id}',
            path: {
                'announcement_id': announcementId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Announcement Endpoint
     * Update an announcement by ID.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateAnnouncement({
        announcementId,
        requestBody,
        resetUserStatus = false,
    }: {
        announcementId: number,
        requestBody: AnnouncementDetails,
        resetUserStatus?: boolean,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/announcements/{announcement_id}',
            path: {
                'announcement_id': announcementId,
            },
            query: {
                'reset_user_status': resetUserStatus,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Announcement Enpoint
     * Delete an announcement by ID.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAnnouncement({
        announcementId,
    }: {
        announcementId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/announcements/{announcement_id}',
            path: {
                'announcement_id': announcementId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Announcement Endpoint
     * Create a new announcement.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createAnnouncement({
        requestBody,
    }: {
        requestBody: AnnouncementDetails,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/announcements/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
