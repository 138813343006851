import * as $ from "jquery";
import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    components,
} from "knockout";

import {
    LocationsService,
    LocationPickerTankPosition,
} from "../../../backend/v1";
import { assert } from "../../../lib/assert";
import { writeException } from "../../../lib/excepthook";
import { getTranslation } from "../../../lib/localize";

import {
    LocationIdString,
    LocationItem,
} from "./locationPicker";


export type TankId = number;

export interface TankPosition extends LocationPickerTankPosition {
    selected: Observable<boolean>;
}

/**
 * Tank picker.
 *
 * @param location - ko.observable or object (dict) that holds the selected location
 * item which will be used to query all tank belonging to that location. In case if
 * object (dict) it has to have a type attribute of ('building', 'area', 'room', 'rack')
 * and corresponding [area/room/etc]_id If used with location-picker, this should be
 * the same variable as given to location-picker as params.selectedLocation
 *
 * @param showTankTrigger - ko.observable used to scroll selected tank into
 * view. To trigger, call showTankTrigger.valueHasMutated() or set any true-ish value
 * to it.
 *
 * @param unselectTankTrigger - ko.observable used to unselect a maybe
 * currently selected tank item. To trigger, call unselectTankTrigger.valueHasMutated()
 *
 * @param rememberSelectionTrigger - ko.observable used to store the currently
 * selected tank so it can be restored later. To trigger, call
 * rememberSelectionTrigger.valueHasMutated()
 *
 * @param restoreSelectionTrigger - ko.observable to reset the currently
 * selected tank to the previously remembered one, that was store using restoreSelectionTrigger.
 * To trigger, call restoreSelectionTrigger.valueHasMutated()
 *
 * @param parentLocation - full id string of a location (e.g. 1-2-3) picker
 * shows only tanks of this location or its children; on selecting a parent of the
 * parentLocation, it shows only tanks of parentLocation itself; tanks of siblings of
 * parentLocation are not included
 *
 * @param preselectTank - id of tank to pre-select
 *
 * @param selectedPosition - ko.observable to hold the selected tank
 *
 * @param captionText - Text to show if no tank could be found or is selected.
 * Default: "No tanks found.".
 *
 */
interface TankPickerParams {
    location: LocationItem;
    showTankTrigger?: Observable;
    unselectTankTrigger?: Observable;
    rememberSelectionTrigger?: Observable;
    restoreSelectionTrigger?: Observable;
    parentLocation?: LocationIdString;
    preselectTank?: TankId;
    selectedPosition?: Observable<TankPosition>;
    captionText?: string;
}

class TankPickerViewModel {

    public defaultCaption: string;
    public pickerEl: HTMLElement;
    public isLoading: Observable<boolean>;
    public captionText: Observable<string>;
    public rememberedTank: Observable<TankPosition>;
    public lastSelectedLocation: LocationIdString;
    public selectedLocation: Observable<LocationItem>;
    public positionList: ObservableArray<TankPosition>;
    public selectedPosition: Observable<TankPosition>;
    public preselectTank: TankId | string;
    public parentLocation: LocationIdString;

    constructor(params: TankPickerParams, componentElement: HTMLElement) {

        assert(ko.isObservable(params.selectedPosition), "params.selectedPosition is not a ko.observable");

        this.defaultCaption = params.captionText || getTranslation("No tank to show.");

        // keep reference to our HTML element before jQuery dialog rips us apart
        // will be set in the afterRender function
        this.pickerEl = componentElement;

        this.selectedPosition = params.selectedPosition;
        this.parentLocation = params.parentLocation;
        this.preselectTank = params.preselectTank;
        this.isLoading = ko.observable(false);
        this.captionText = ko.observable(this.defaultCaption);
        this.rememberedTank = ko.observable();
        this.selectedLocation = ko.isObservable(params.location) ? params.location : ko.observable(params.location);
        this.positionList = ko.observableArray();
        this.selectedLocation.subscribe((item) => {
            if (!item) {
                return;
            }
            if (this.lastSelectedLocation !== item.id) {
                this.lastSelectedLocation = item.id;
                this.getPositions(item);
            }
        });

        if (ko.isObservable(params.showTankTrigger)) {
            // trigger with params.showTankTrigger.valueHasMutated()
            params.showTankTrigger.extend({ notify: "always" });
            params.showTankTrigger.subscribe(() => {
                this.showPosition(params.selectedPosition());
            });
        }

        if (ko.isObservable(params.unselectTankTrigger)) {
            // trigger with params.unselectTankTrigger.valueHasMutated()
            params.unselectTankTrigger.extend({ notify: "always" });
            params.unselectTankTrigger.subscribe(() => {
                this.unselectItem();
            });
        }

        if (ko.isObservable(params.rememberSelectionTrigger)) {
            // trigger with params.rememberSelectionTrigger.valueHasMutated()
            params.rememberSelectionTrigger.extend({ notify: "always" });
            params.rememberSelectionTrigger.subscribe(() => {
                this.rememberedTank(params.selectedPosition());
            });
        }

        if (ko.isObservable(params.restoreSelectionTrigger)) {
            // trigger with params.restoreSelectionTrigger.valueHasMutated()
            params.restoreSelectionTrigger.extend({ notify: "always" });
            params.restoreSelectionTrigger.subscribe(() => {
                const rTank = this.rememberedTank();
                const sTank = params.selectedPosition();

                if (rTank
                    && (!sTank
                        || rTank.location_reference !== sTank.location_reference)) {

                    this.selectItem(rTank);
                } else if (!rTank && sTank) {
                    this.unselectItem();
                }
            });
        }

        // initially fetch positions
        if (this.selectedLocation()) {
            this.getPositions(this.selectedLocation());
        }

    }

    public clickItem = (item: TankPosition) => {
        if (item.location_reference) {
            this.selectItem(item);
            this.showPosition(item);
        }
    };

    // fetch location tree
    private getPositions = (locationItem: LocationItem) => {

        if (this.isLoading()) {
            return;
        }

        this.isLoading(true);
        this.positionList([]);

        let locationId: string;
        if (
            this.parentLocation === undefined ||
            locationItem.id.split("-").length >= this.parentLocation.split("-").length
        ) {
            locationId = locationItem.id;
        } else {
            locationId = this.parentLocation;
        }

        LocationsService.getPickerTanks({ locationId })
            .then((tankList: TankPosition[]) => {
                this.captionText("");
                tankList.forEach((item) => {
                    item.selected = ko.observable();
                    if (
                        this.selectedPosition() &&
                        item.location_reference === this.selectedPosition().location_reference
                    ) {
                        this.selectItem(item);
                    } else if (!this.selectedPosition() && item.location_reference === this.preselectTank) {
                        this.selectItem(item);
                    }
                });
                this.positionList(tankList);
                if (this.selectedPosition()) {
                    this.showPosition(this.selectedPosition());
                }
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.captionText(reason.body?.detail);
                } else {
                    this.captionText(getTranslation("General error."));
                    writeException(reason);
                }
            })
            .finally(() => {
                this.isLoading(false);
            });
    };

    // scroll tank into view
    private showPosition = (item: TankPosition) => {
        // TODO: remove jQuery
        const pickerEl = $(this.pickerEl);
        if (!item) {
            pickerEl.animate({ scrollTop: 0, scrollLeft: 0 }, 200);
        } else {
            const itemEl = pickerEl.find(`[location_reference=${item.location_reference}]:visible`);
            if (itemEl.length > 0) {
                const elHeight = itemEl.outerHeight();
                const elWidth = itemEl.outerWidth();
                const pickerElHeight = pickerEl.height();
                const pickerElWidth = pickerEl.width();
                const elOffTop = itemEl.offset().top;
                const elOffLeft = itemEl.offset().left;
                const pickerElOffTop = pickerEl.offset().top;
                const pickerElOffLeft = pickerEl.offset().left;
                const pickerElScrollTop = pickerEl.scrollTop();
                const pickerElScrollLeft = pickerEl.scrollLeft();
                if (elOffTop + elHeight > pickerElOffTop + pickerElHeight) {
                    pickerEl.animate({
                        scrollTop: pickerElScrollTop
                            + ((elOffTop + elHeight)
                                - (pickerElOffTop + pickerElHeight)),
                    }, 200);
                } else if (elOffTop < pickerElOffTop) {
                    pickerEl.animate({
                        scrollTop: pickerElScrollTop
                            - ((pickerElOffTop + 3) - elOffTop),
                    }, 200);
                }
                if (elOffLeft + elWidth > pickerElOffLeft + pickerElWidth) {
                    pickerEl.animate({
                        scrollLeft: pickerElScrollLeft
                            + ((elOffLeft + elWidth)
                                - (pickerElOffLeft + pickerElWidth)),
                    }, 200);
                } else if (elOffLeft < pickerElOffLeft) {
                    pickerEl.animate({
                        scrollLeft: pickerElScrollLeft
                            - ((pickerElOffLeft + 3) - elOffLeft),
                    }, 200);
                }
            }
        }
    };

    private unselectItem = (doNotSetUndefined = false) => {
        if (this.selectedPosition()) {
            this.selectedPosition().selected(false);
        }
        if (!doNotSetUndefined) {
            this.selectedPosition(undefined);
        }
    };

    private selectItem = (item: TankPosition) => {
        this.unselectItem(true);
        item.selected(true);
        this.selectedPosition(item);
    };
}

export class TankPickerComponent {

    constructor() {
        return {
            viewModel: {
                createViewModel: (params: TankPickerParams, componentInfo: components.ComponentInfo) => {
                    let viewModelElement: ChildNode;
                    const componentElement = componentInfo.element as HTMLElement;
                    if (componentElement.nodeName === "#comment") {
                        viewModelElement = componentElement.nextElementSibling;
                    } else {
                        viewModelElement = componentElement.firstChild;
                    }
                    return new TankPickerViewModel(params, viewModelElement as HTMLElement);
                },
            },
            template: `
                <div class="tank-picker">
                    <!-- ko foreach: positionList -->
                    <div class="tank"
                         data-bind="css: {selectable: location_reference,
                                          selected: selected,
                                          in_use: in_use},
                                    attr: {location_reference: location_reference},
                                    click: $parent.clickItem">
                        <span class="tank_position" data-bind="text: tank_position || getTranslation('Free slots')"></span>
                    </div>
                    <!-- /ko -->
                    <div class="loading_circle" data-bind="visible: isLoading"></div>
                    <div class="caption" data-bind="hidden: isLoading() || positionList().length,
                                                    text: captionText"></div>
                </div>
            `,
        };
    }

}
