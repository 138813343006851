/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BreedingPerformanceDetails } from '../models/BreedingPerformanceDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {

    /**
     * Get Breeding Performance Details
     * Get data for breeding performance details dialog.
     * @returns BreedingPerformanceDetails Successful Response
     * @throws ApiError
     */
    public static getBreedingPerformanceDetails({
        animalId,
        birthDateFrom,
        birthDateTo,
    }: {
        animalId: number,
        birthDateFrom: (string | null),
        birthDateTo: (string | null),
    }): CancelablePromise<BreedingPerformanceDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reports/breeding_performance_details',
            query: {
                'animal_id': animalId,
                'birth_date_from': birthDateFrom,
                'birth_date_to': birthDateTo,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Colony Pedigree
     * Get colony pedigree.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getColonyPedigree({
        kind,
        label,
        nearby,
        depth = 4,
    }: {
        kind: 'animal_pedigree' | 'tank_pedigree' | 'strain_pedigree',
        label: string,
        nearby?: ('half_siblings_and_children' | 'full_siblings_and_children' | 'siblings_and_children' | null),
        depth?: number,
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reports/colony_pedigree',
            query: {
                'kind': kind,
                'label': label,
                'nearby': nearby,
                'depth': depth,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
