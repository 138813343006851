/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_applyEmbryoQuickselectActions } from '../models/Body_applyEmbryoQuickselectActions';
import type { Body_getEmbryoQuickselectSeed } from '../models/Body_getEmbryoQuickselectSeed';
import type { CryopreservationData } from '../models/CryopreservationData';
import type { EmbryoAgeData } from '../models/EmbryoAgeData';
import type { EmbryoDetails } from '../models/EmbryoDetails';
import type { EmbryoEvent } from '../models/EmbryoEvent';
import type { EmbryoQuickselectActionsResponse } from '../models/EmbryoQuickselectActionsResponse';
import type { EmbryoQuickselectSeed } from '../models/EmbryoQuickselectSeed';
import type { FosterMother } from '../models/FosterMother';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmbryosService {

    /**
     * Get Embryo Details
     * Get details of an embryo group.
     * @returns EmbryoDetails Successful Response
     * @throws ApiError
     */
    public static getEmbryoDetails({
        embryoGroupKey,
    }: {
        embryoGroupKey: string,
    }): CancelablePromise<EmbryoDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/embryos/{embryo_group_key}/details',
            path: {
                'embryo_group_key': embryoGroupKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Embryo History
     * Get history of an embryo group.
     * @returns EmbryoEvent Successful Response
     * @throws ApiError
     */
    public static getEmbryoHistory({
        embryoGroupKey,
    }: {
        embryoGroupKey: string,
    }): CancelablePromise<Array<EmbryoEvent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/embryos/{embryo_group_key}/history',
            path: {
                'embryo_group_key': embryoGroupKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Carnegie Stages
     * Get possible carnegie stages (age levels, cell stages) for embryos.
     * @returns EmbryoAgeData Successful Response
     * @throws ApiError
     */
    public static getCarnegieStagesForSetting(): CancelablePromise<Array<EmbryoAgeData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/embryos/carnegie_stages/for_setting',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Next Free Straw Labels
     * Get next free straw labels for cryopreserved embryos.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getNextFreeEmbryoStrawLabels({
        count,
    }: {
        count: number,
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/embryos/straw_labels/for_setting',
            query: {
                'count': count,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Foster Mothers By Strain Id
     * Get female animals of the given strain to use them as foster mothers for embryos.
     * @returns FosterMother Successful Response
     * @throws ApiError
     */
    public static getFosterMothersByStrainId({
        strainId,
        amount = 10,
    }: {
        strainId: number,
        amount?: number,
    }): CancelablePromise<Array<FosterMother>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/embryos/foster_mothers_by_strain_id/{strain_id}',
            path: {
                'strain_id': strainId,
            },
            query: {
                'amount': amount,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Foster Mother Suffixes
     * Get missing suffixes to assign new eartags to foster mothers on embryo transfer.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getFosterMotherSuffixes({
        eartags,
    }: {
        eartags: Array<string>,
    }): CancelablePromise<Record<string, Array<string>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/embryos/foster_mother_suffixes',
            query: {
                'eartags': eartags,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Embryos
     * Import embryos.
     * @returns number Successful Response
     * @throws ApiError
     */
    public static importEmbryos({
        embryoCount,
        originId,
        ownerId,
        projectId,
        strainId,
        carnegieStageId,
        cryopreserved = false,
        requestBody,
    }: {
        embryoCount: number,
        originId?: (number | null),
        ownerId?: (number | null),
        projectId?: (number | null),
        strainId?: (number | null),
        carnegieStageId?: (number | null),
        cryopreserved?: boolean,
        requestBody?: (CryopreservationData | null),
    }): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/embryos/import',
            query: {
                'embryo_count': embryoCount,
                'origin_id': originId,
                'owner_id': ownerId,
                'project_id': projectId,
                'strain_id': strainId,
                'carnegie_stage_id': carnegieStageId,
                'cryopreserved': cryopreserved,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Quickselect Seed
     * Get seed for embryo Quickselect.
     * @returns EmbryoQuickselectSeed Successful Response
     * @throws ApiError
     */
    public static getEmbryoQuickselectSeed({
        requestBody,
    }: {
        requestBody?: Body_getEmbryoQuickselectSeed,
    }): CancelablePromise<EmbryoQuickselectSeed> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/embryos/quickselect/seed',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Apply Quickselect
     * Apply Quickselect actions to selected embryo IDs.
     * @returns EmbryoQuickselectActionsResponse Successful Response
     * @throws ApiError
     */
    public static applyEmbryoQuickselectActions({
        requestBody,
    }: {
        requestBody: Body_applyEmbryoQuickselectActions,
    }): CancelablePromise<EmbryoQuickselectActionsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/embryos/quickselect/apply',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
