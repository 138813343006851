import { Observable } from "knockout";

import { ListFilterDefinition } from "../../backend/v1";
import { showSetUserStatus } from "../../dialogs";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { actionLog } from "../../lib/pyratTop";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./userListFilter.html";


interface Arguments extends ListViewArguments {
    export_args: any;
}

// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = (filter: ListFilterModel) => ({
    user_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    user_group_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    auth_type: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    level: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.label);
        }
    },

    status: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    price_category_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    user_location: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values;
        }
    },

    institute: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values;
        }
    },

    laboratory: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values;
        }
    },

    description: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
        }
    },

    valid_from: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("valid_to"), compareFromDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    valid_to: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("valid_from"), compareToDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    min_version_date: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("max_version_date"), compareFromDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    max_version_date: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("min_version_date"), compareToDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    permitted_user_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    page_size: ListFilterItem,
});

class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("User filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("create-new-user", () => {
            openListDetailPopup(getUrl(cgiScript("userdetail.py"), { create: 1 }));
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.export_args,
            });
        });

        this.listView.onCellClick("td.username a, td.fullname a", (args) => {
            this.listView.highlightRow(args.rowId);
            openListDetailPopup(getUrl(cgiScript("userdetail.py"), { userid: args.rowId }), () => {
                this.listView.unHighlightRow(args.rowId);
            });
        });
        this.listView.onCellClick("td.status.clickable a", (args) => {
            if (args.element.dataset.setStatus) {
                showSetUserStatus({
                    userId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.listView.reload({ flashRowId: args.rowId });
                        actionLog.reload();
                    },
                });
            } else {
                openListDetailPopup(
                    getUrl(cgiScript("userdetail.py"), { highlight_validity: 1, userid: args.rowId }),
                    () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                );
            }
        });
    }
}

export const initUserList = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
