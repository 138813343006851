import * as dialogs from "./dialogs";
import * as browserStorage from "./lib/browserStorage";
import * as charts from "./lib/charts";
import * as flatpickr from "./lib/flatpickr";
import * as listview from "./lib/listView";
import * as popups from "./lib/popups";
import * as postLinks from "./lib/postLinks";
import {
    setSessionValues,
    session,
} from "./lib/pyratSession";
import * as top from "./lib/pyratTop";
import { type initSentry } from "./lib/sentry";
import * as sites from "./sites";


export const pyratFrontend = {

    // Top context functions
    top,

    // Session value access
    session,
    setSessionValues,
    initSentry: async (...args: Parameters<typeof initSentry>) => {
        const { initSentry } = await import("./lib/sentry");
        return await initSentry(...args);
    },

    // Functions
    browserStorage,
    charts,
    dialogs,
    flatpickr,
    listview,
    popups,
    sites,
    virtualPostRequest: postLinks.virtualPostRequest,
};
