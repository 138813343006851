/**
 * Show the sperm details pop-up.
 *
 * @param spermId
 *        The database ID of the sperm.
 *
 * @param reloadCallback
 *        Function to call when data has been applied and popup is closed
 *        (e.g. to reload a list to display new data).
 *
 * @param closeCallback
 *        Function to call whenever the popup is closed, whether data was
 *        applied or not (e.g. to unhighlight a row in listview table).
 *
 */

import * as ko from "knockout";

import {
    CommentWidgetSeed,
    CommentsService,
    DocumentWidgetSeed,
    DocumentsService,
    IdLabelProperty,
    SpermDetails,
    SpermEvent,
    SpermService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import {
    frames,
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    cgiScript,
    getUrl,
} from "../lib/utils";

import template from "./spermDetails.html";
import "/scss/popup_details_common_styles.scss";

interface Params {
    spermId: number;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

class SpermDetailsViewModel {
    private dialog: HtmlDialog;
    private tab: ko.Observable<"history" | "comments" | "documents">;
    private reloadRequired: ko.Observable<boolean>;
    private loadInProgress: ko.Observable<boolean>;
    private spermDetails: ko.Observable<SpermDetails>;
    private spermEvents: ko.ObservableArray<SpermEvent>;
    private spermComments: ko.Observable<CommentWidgetSeed>;
    private spermDocuments: ko.Observable<DocumentWidgetSeed>;
    private scenery: ko.PureComputed<{
        loading: boolean;
    } | {
        error: string;
    } | {
        meta: SpermDetails;
        history: Array<SpermEvent>;
        comments: CommentWidgetSeed;
        documents: DocumentWidgetSeed;
    }>;
    private dialogTitle: ko.PureComputed<string>;

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;

        this.tab = ko.observable("history");
        this.reloadRequired = ko.observable(false);

        this.dialog.addOnClose(() => {
            if (params.closeCallback) {
                params.closeCallback();
            }

            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        this.loadInProgress = ko.observable(false);
        this.spermDetails = ko.observable();
        this.spermEvents = ko.observableArray();
        this.spermComments = ko.observable();
        this.spermDocuments = ko.observable();

        if (params.spermId) {
            this.loadInProgress(true);
            SpermService.getSpermDetails({ spermId: params.spermId }).then((response) => {
                this.spermDetails(response);

                // if the details loaded successfully it means the user has permission to see the sperm details
                // and the rest of the data can be fetched too
                Promise.all([
                    SpermService.getSpermHistory({ spermId: params.spermId }).then((response) => this.spermEvents(response)),
                    CommentsService.getCommentWidgetSeed({
                        requestBody: {
                            subjects: { sperm_id: params.spermId },
                        },
                    }).then((response: CommentWidgetSeed) => this.spermComments(response)),
                    DocumentsService.getDocumentWidgetSeed({
                        requestBody: {
                            sperm_id: [params.spermId],
                        },
                    }).then((response) => this.spermDocuments(response)),
                ]).catch(this.handleErrorResponse).finally(() => this.loadInProgress(false));
            }).catch((response) => {
                this.handleErrorResponse(response);
                this.loadInProgress(false);
            });
        }

        this.scenery = ko.pureComputed(() => {
            if (this.loadInProgress()) {
                return { loading: true };
            }

            if (this.spermDetails() && this.spermEvents() && this.spermComments() && this.spermDocuments()) {
                return {
                    meta: this.spermDetails(),
                    history: this.spermEvents(),
                    comments: this.spermComments(),
                    documents: this.spermDocuments(),
                };
            }

            return { error: getTranslation("General unexpected error occurred.") };
        });

        this.dialogTitle = ko.pureComputed(() => {
            let spermData;

            // @ts-expect-error: Property 'meta' does not exist on type '{ loading: boolean; }'
            if (this.scenery().meta) {
                spermData = this.spermDetails();

                return spermData.volume + " µl " + getTranslation("Sperm") + ", " + spermData.state_label;
            }

            return getTranslation("Sperm details");
        });
        this.dialogTitle.subscribe((dialogTitle) => {
            this.dialog.setTitle(dialogTitle);
        });

    }

    private historyMetaLabel = (key: string) => {
        const labels = {
            sperm_donor_animals: getTranslation("Sperm donors"),
            oocyte_donor_animals: getTranslation("Oocyte donors"),
            revitalization_embryos: getTranslation("Embryos"),
            owner_fullname: getTranslation("Owner"),
            project_label: getTranslation("Project"),
            strain_name: getTranslation("Line / Strain"),
            origin_name: getTranslation("Origin"),
            export_institution_name: getTranslation("Facility"),
            cryotank_content_address: getTranslation("Position / Label"),
            freeze_date: getTranslation("Freezing date"),
            quality_rating_label: getTranslation("Rating"),
            quality_progressive: getTranslation("Progressive"),
            state_label: getTranslation("State"),
        };

        // @ts-expect-error: Element implicitly has an 'any' type
        return Object.hasOwn(labels, key) && labels[key] || key;
    };

    private historyMetaValue = (key: string, value: any) => {
        const element = document.createElement("span");

        if (key === "cryotank_content_address") {
            element.innerText = value.map((cryotankAddressBreadcrumb: IdLabelProperty) => cryotankAddressBreadcrumb.label).join(" > ");

        } else if (key === "sperm_donor_animals" || key === "oocyte_donor_animals") {
            value.forEach((animal: { id: number; eartag: string }) => {
                const link = document.createElement("a");

                link.innerText = animal.eartag;
                link.classList.add("subtle-link");
                link.title = getTranslation("View details for: %s").replace("%s", animal.eartag);
                link.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("mousedetail.py"), {
                        animalid: animal.id,
                    }, {
                        absoluteUrl: true,
                    }));
                });
                element.appendChild(link);
            });

        } else if (key === "revitalization_embryos") {
            value.forEach((embryo: { groupkey: string; count: number }) => {
                const link = document.createElement("a");
                const icon = document.createElement("span");

                link.innerText = String(embryo.count);
                link.classList.add("subtle-link");
                link.title = getTranslation("Show in embryo list");
                link.addEventListener("click", () => {
                    mainMenu.openAndResetListFilter("get_embryo_list", {
                        embryo_groupkey: embryo.groupkey,
                    });
                });
                icon.classList.add("icon_button");
                icon.classList.add("only_icon_img");
                icon.classList.add("icon_filter");
                link.insertBefore(icon, link.firstChild);
                element.appendChild(link);
            });

        } else if (key === "quality_progressive") {
            element.innerText = value + "%";

        } else {
            element.innerText = value;
        }

        return element;
    };

    private reseedComments = (seed: CommentWidgetSeed) => {
        this.spermComments(seed);
        this.reloadRequired(true);
    };

    private handleErrorResponse = (response: any) => {
        if (typeof response.body?.detail === "string") {
            notifications.showNotification(response.body.detail, "error");
        } else {
            notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
            writeException(response);
        }
    };

}

export const showSpermDetails = htmlDialogStarter(SpermDetailsViewModel, template, {
    name: "SpermDetails",
    width: 600,
    position: {
        inset: { top: 20, right: 20 },
    },
    closeOthers: true,
});
