import * as ko from "knockout";
import {
    Computed,
    observable,
    Observable,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    CommentWidgetSeed,
    DocumentWidgetSeed,
    IdLabelProperty,
    TankDetailCrossing,
    TanksService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { mainMenu } from "../lib/pyratTop";

import { showTankCrossingDetails } from "./tankCrossingDetails";
import template from "./tankDetails.html";

import "./tankDetails.scss";
import "/scss/popup_details_common_styles.scss";

type Tab = "summary" | "history" | "comments" | "documents" | "procedures" | "crossings";

interface TankDetailParams {
    tankId: number;
    withNeighbors?: boolean;
    tab?: Tab;
    reloadCallback?: () => void;
    reloadRequired?: boolean;
    closeCallback?: () => void;
    loadCallback?: () => void;
}

type TankHistoryChanges = { [key: string]: any };

interface TankMeta {
    tank_id: number[];
    tank_type: string[];
    tank_position: string[];
    classification_id: number[];
    license_assign_history: {
        classification_id: number;
        classification_name: string;
        licence_number: string;
    }[][];
    // TODO: add missing fields
}

interface TankData {
    meta: TankMeta;
    history: {
        changes: TankHistoryChanges;
        // TODO: add missing fields
    }[];
    comments: CommentWidgetSeed;
    documents: DocumentWidgetSeed;
    procedures: {
        available_procedures: { id: number; name: string }[];
    };
    crossings: TankDetailCrossing[];
    neighbors: IdLabelProperty[];
}

/**
 * Popup dialog for tank details
 */
class TankDetailsViewModel {

    private readonly dialog: HtmlDialog;

    /* params */
    public tankId: Observable<number>;
    public subTankId: Observable<number>;
    public withNeighbors = ko.observable(true);

    /* observables */
    public tab: Observable<Tab>;
    public reloadRequired: Observable<boolean>;
    public fetchInProgress: Observable<boolean> = observable(false);
    public error: Observable<string> = observable("");
    public tankData: Observable<TankData> = ko.observable();
    public shownTank: Computed<TankMeta>;
    public availableClassifications: PureComputed<{
        classification_id: number;
        classification_name: string;
        license_number: string;
    }[]>;
    public initialClassificationId: PureComputed<number>;

    private fetchData = () => {
        this.fetchInProgress(true);
        TanksService
            .getTankDetails({
                tankId: this.tankId(),
                withNeighbors: this.withNeighbors(),
            })
            .then((tankDetails) => {
                // @ts-expect-error: The type is not defined properly yet.
                this.tankData(tankDetails);
                if (!this.withNeighbors()) {
                    this.subTankId(this.tankId());
                }
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.error(reason.body.detail);
                } else {
                    this.error(getTranslation("General unexpected error occurred."));
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));
    };

    public forceReload = () => {
        this.fetchData();
        this.reloadRequired(true);
    };

    public historyMetaLabel = (key: string) => {
        switch (key) {
        case "actual_date_string":
            return getTranslation("Date");
        case "date_of_birth_string":
            return getTranslation("Date of birth");
        case "date_of_release_string":
            return getTranslation("Date of release");
        case "number_of_male_difference":
            return getTranslation("Number of male");
        case "number_of_female_difference":
            return getTranslation("Number of female");
        case "number_of_unknown_difference":
            return getTranslation("Number of unknown");
        case "responsible_fullname":
            return getTranslation("Responsible");
        case "owner_fullname":
            return getTranslation("Owner");
        case "tank_type_label":
            return getTranslation("Type");
        case "tank_label":
            return getTranslation("Label");
        case "location_label":
            return getTranslation("Location");
        case "species_name":
            return getTranslation("Species");
        case "strain_name_with_id":
            return getTranslation("Line / Strain");
        case "medical_condition":
            return getTranslation("Condition");
        case "mutation_name":
            return getTranslation("Mutation");
        case "mutation_grade_name":
            return undefined;
        case "origin_name":
            return getTranslation("Origin");
        case "generation":
            return getTranslation("Generation");
        case "procedure_date":
            return getTranslation("Procedure date");
        case "procedure_name":
            return getTranslation("Procedure");
        case "project_label":
            return getTranslation("Project");
        case "age_level_label":
            return getTranslation("Age Level");
        case "export_institution_name":
            return getTranslation("Facility");
        case "status_label":
            return getTranslation("Status");
        case "sacrifice_reason_name":
            return getTranslation("Reason");
        case "sacrifice_method_name":
            return getTranslation("Method");
        case "license_1_number":
            return getTranslation("Number");
        case "license_2_classification":
            return getTranslation("Classification");
        case "license_3_severity_level":
            return getTranslation("Severity level");
        case "license_4_assign_position":
            return getTranslation("Position");
        case "assignment_animals":
            return getTranslation("Number of animals");
        }
        return key;
    };

    /**
     * Resolve the new-value of the given key in a set of changes.
     *
     * @param changes All changes (fo reference).
     * @param key Key of the change to resolve.
     * @param value Actual new-value of the change.
     */
    public historyMetaValue = (changes: TankHistoryChanges, key: string, value: any) => {

        const element = document.createElement("span");

        // Set styles on Element
        element.style.wordBreak = "break-all";

        if (key === "mutation_name") {
            if (changes.mutation_grade_name) {
                element.innerText = _.template("<%- mutation_name %> (<%- mutation_grade_name %>)")(changes);
            } else {
                element.innerText = value;
            }

        } else if (key === "number_of_male_difference"
            || key === "number_of_female_difference"
            || key === "number_of_unknown_difference") {
            element.innerText = (value > 0 ? "+" : "") + value;
        } else if (key === "assignment_animals") {
            if (value.live !== value.total) {
                element.innerText = _.template("<%- total %> (<%- live %> <%- live_label %> + <%- dead %> <%- dead_label %>)")(_.extend({
                    live_label: getTranslation("live"),
                    dead_label: getTranslation("dead"),
                }, value));
            } else {
                element.innerText = value.total;
            }
        } else {
            element.innerText = value;
        }

        return element;
    };

    public reseedComments = (seed: CommentWidgetSeed) => {
        const tankData = this.tankData();
        if (tankData) {
            tankData.comments = seed;
        }
        this.reloadRequired(true);
    };

    public reseedDocuments = () => {
        this.reloadRequired(true);
    };

    public uniqueJoinArray = (a: any[]): string => {
        return _.without(_.uniq(a), null).join(", ");
    };

    public sumNumberArray = (a: number[]): number => {
        return _.reduce(a, function (memo, num) {
            return memo + num;
        }, 0);
    };

    /* show tanks involved in crossing */
    public showTanksInvolvedInCrossing = (data: TankDetailCrossing) => {
        mainMenu.openAndResetListFilter("get_tank_list", { crossing_id: [data.crossing_id] });
    };

    public showCrossingDetails = (crossingId: number, item: any, event: MouseEvent) => {
        const model = showTankCrossingDetails({
            crossingId: crossingId,
            positioningElement: event.target as HTMLElement,
            reloadCallback: () => this.forceReload(),
        });
        this.dialog.addOnClose(() => {
            model.dialog.close();
        });
    };

    constructor(dialog: HtmlDialog, {
        tankId,
        reloadCallback,
        closeCallback,
        loadCallback,
        withNeighbors = true,
        reloadRequired = false,
        tab = "summary",
    }: TankDetailParams) {

        this.dialog = dialog;
        this.tankId = ko.observable(tankId);
        this.subTankId = ko.observable();

        this.withNeighbors = ko.observable(withNeighbors);
        this.tab = ko.observable(tab);
        this.reloadRequired = ko.observable(reloadRequired);

        this.dialog.addOnClose(() => {
            if (typeof closeCallback === "function") {
                closeCallback();
            }

            // reload list view when dialog is closed
            if (this.reloadRequired() && typeof reloadCallback === "function") {
                reloadCallback();
            }
        });

        this.tankData.subscribeOnce(() => {
            if (typeof loadCallback === "function") {
                loadCallback();
            }
        });

        this.subTankId.subscribe((subTankId) => {
            const reloadRequired = this.reloadRequired();
            if (subTankId && (subTankId !== this.tankId() || this.withNeighbors())) {
                // User switched to "single tank" (no withNeighbors) view
                this.reloadRequired(false);
                showTankDetails({
                    tankId: subTankId,
                    reloadCallback: reloadCallback,
                    reloadRequired: reloadRequired,
                    closeCallback: closeCallback,
                    // loadCallback should not be used again, it is used only for the original load
                    withNeighbors: false,
                    tab: this.tab(),
                });
            } else if (!subTankId && !this.withNeighbors()) {
                // User switched to "all tanks in position" view
                this.reloadRequired(false);
                showTankDetails({
                    tankId: this.tankId(),
                    reloadCallback: reloadCallback,
                    reloadRequired: reloadRequired,
                    closeCallback: closeCallback,
                    // loadCallback should not be used again, it is used only for the original load
                    withNeighbors: true,
                    tab: this.tab(),
                });
            }
        });

        this.shownTank = ko.pureComputed(() => {
            return _.get(this.tankData(), ["meta"]);
        });

        this.dialog.setTitle(getTranslation("Tank") + " " + this.tankId());
        this.shownTank.subscribe((shownTank) => {
            let template;
            let tankPositions;
            let experimentTank;

            if (shownTank) {
                tankPositions = this.uniqueJoinArray(shownTank.tank_position);
                experimentTank = _.includes(shownTank.tank_type, "experiment");

                if (tankPositions) {
                    template = _.template("<%- label_for_position %> <%- tank_positions %> (<%- label_for_tank_ids %> <%- tank_ids %>)");
                } else {
                    template = _.template("<%- label_for_tank_ids %> <%- tank_ids %>");
                }

                this.dialog.setTitle(template({
                    "label_for_position": getTranslation("Position"),
                    "tank_positions": tankPositions,
                    "label_for_tank_ids": experimentTank ? getTranslation("Experiment tank") : getTranslation("Tank"),
                    "tank_ids": this.uniqueJoinArray(shownTank.tank_id),
                }));
            }
        });

        // for procedure tab
        this.availableClassifications = ko.pureComputed(() => _.map(_.filter(this.shownTank()?.license_assign_history[0], (tankLicense) => {
            return _.every(this.shownTank().license_assign_history, (otherTank) => {
                return _.some(otherTank, (otherTankLicense) => {
                    return otherTankLicense.classification_id === tankLicense.classification_id;
                });
            });
        }), (commonClassification) => {
            return {
                classification_id: commonClassification.classification_id,
                classification_name: commonClassification.classification_name,
                license_number: commonClassification.licence_number,
            };
        }));
        this.initialClassificationId = ko.pureComputed(() => _.every(this.shownTank()?.classification_id, (classificationId) => classificationId === this.shownTank().classification_id[0]) ?
            this.shownTank()?.classification_id[0] : undefined);

        // load tank detail data
        this.fetchData();
    }
}

// dialog starter
export const showTankDetails = htmlDialogStarter(TankDetailsViewModel, template, {
    name: "TankDetails",
    width: 600,
    position: {
        inset: { top: 20, right: 20 },
    },
    closeOthers: true,
});
