/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_reactivateSession } from '../models/Body_reactivateSession';
import type { UserAlias } from '../models/UserAlias';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SessionService {

    /**
     * Get Session Time
     * Get the time left until the session ends.
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getSessionTime({
        sessionid,
    }: {
        sessionid: string,
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session/session_time',
            query: {
                'sessionid': sessionid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reactivate Session
     * Try to resurrect the session (re-open an expired session by giving the credentials).
     * @returns any Successful Response
     * @throws ApiError
     */
    public static reactivateSession({
        sessionid,
        requestBody,
    }: {
        sessionid: string,
        requestBody: Body_reactivateSession,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session/reactivate_session',
            query: {
                'sessionid': sessionid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Aliases
     * Get the aliases of the user.
     * @returns UserAlias Successful Response
     * @throws ApiError
     */
    public static getAliases(): CancelablePromise<Array<UserAlias>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session/aliases',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
