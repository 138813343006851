import * as ko from "knockout";
import * as _ from "lodash";

import { DocumentWidgetSeed } from "../backend/v1";
import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    notifications,
    frames,
} from "../lib/pyratTop";
import {
    cgiScript,
    getFormData,
    getUrl,
    AjaxResponse,
} from "../lib/utils";


import template from "./mutationDetails.html";


interface Params {
    mutationId: number | null;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface NewMutationSeed {
    mutation_types: {
        name: string;
        id: string;
    }[];
}

interface MutationDetailSeed extends NewMutationSeed {
    mutation_details: {
        id: number;
        name: string;
        official_name: string;
        type_id: number;
        genetic_characterisation: string;
        literature: string;
        health_effect: string;
        status: "active" | "inactive";
    };
    mutation_strains: {
        id: number;
        name_with_id: string;
        active: boolean;
    }[];
    document_widget_seed: DocumentWidgetSeed;
}

class MutationDetailsViewModel {

    private readonly params;
    private readonly dialog;
    private readonly createAllowed: boolean;
    private readonly updateAllowed: boolean;

    private readonly seed: ko.Observable<FetchExtended<AjaxResponse<NewMutationSeed | MutationDetailSeed>>>;
    private readonly mutationName: CheckExtended<ko.Observable<string>>;
    private readonly officialName: ko.Observable<string>;
    private readonly mutationTypeId: ko.Observable<number>;
    private readonly geneticCharacterization: ko.Observable<string>;
    private readonly literature: ko.Observable<string>;
    private readonly healthEffect: ko.Observable<string>;
    private readonly mutationStatus: ko.Observable<MutationDetailSeed["mutation_details"]["status"]>;
    private readonly newDocumentIds: ko.ObservableArray<number>;

    private readonly reloadRequired: ko.Observable<boolean> = ko.observable(false);
    private readonly applyInProgress: ko.Observable<boolean>;
    private readonly errors: ko.ObservableArray<string>;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.params = params;
        this.dialog = dialog;

        this.createAllowed = session.userPermissions.mutation_create;
        this.updateAllowed = session.userPermissions.mutation_update;

        if (params.mutationId) {
            dialog.setTitle(getTranslation("Mutation details"));
        } else {
            dialog.setTitle(getTranslation("Create new mutation"));
        }

        dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        /* initial data */
        this.seed = ko.observable().extend({
            fetch: (signal) => {

                const request: { [key: string]: any } = {
                    "get_mutation_types": true,
                };

                if (this.params.mutationId) {
                    request["get_mutation_details"] = this.params.mutationId;
                    request["get_mutation_strains"] = this.params.mutationId;
                    request["get_document_widget_seed"] = this.params.mutationId;
                }

                const form = getFormData({ request: JSON.stringify(request) });
                return fetch(cgiScript("mutation_detail.py"), { method: "POST", body: form, signal });
            },
        });

        /* content */
        this.mutationName = ko.observable("").extend({
            normalize: (x) => {
                return x && _.trim(x);
            },
            invalid: (v) => {
                return !v;
            },
        });

        this.officialName = ko.observable("");
        this.mutationTypeId = ko.observable();
        this.geneticCharacterization = ko.observable("");
        this.literature = ko.observable("");
        this.healthEffect = ko.observable("");
        this.mutationStatus = ko.observable();
        this.newDocumentIds = ko.observableArray();

        if (this.params.mutationId) {
            this.seed.subscribeOnce((seed: AjaxResponse<MutationDetailSeed>) => {
                if (seed.success) {
                    setTimeout(() => {
                        this.mutationName(seed.mutation_details.name);
                        this.officialName(seed.mutation_details.official_name);
                        this.mutationTypeId(seed.mutation_details.type_id);
                        this.geneticCharacterization(seed.mutation_details.genetic_characterisation);
                        this.literature(seed.mutation_details.literature);
                        this.healthEffect(seed.mutation_details.health_effect);
                        this.mutationStatus(seed.mutation_details.status);
                    }, 0);
                }
            });
        }

        /* internals */
        this.applyInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
    }

    private showStrainDetail = (strainId: number) => {
        frames.detailPopup.open(getUrl(
            cgiScript("edit_strain.py"), { strainid: strainId }, { absoluteUrl: true }),
        );
    };

    private canEdit = () => {
        if (!this.params.mutationId && this.createAllowed) {
            return true;
        } else if (this.params.mutationId && this.updateAllowed) {
            return true;
        }
        return false;
    };

    private canSubmit = () => {
        if (!this.canEdit()) {
            return false;
        } else if (this.applyInProgress()) {
            return false;
        } else if (this.mutationName.isInvalid()) {
            return false;
        }
        return true;
    };

    private cancel = () => {
        this.dialog.close();
    };

    private createMutation = () => {
        const request = {
            "create_mutation": {
                "mutation_name": this.mutationName(),
                "official_name": this.officialName(),
                "mutation_type_id": this.mutationTypeId() || null,
                "genetic_characterization": this.geneticCharacterization(),
                "literature": this.literature(),
                "health_effect": this.healthEffect(),
                "new_document_ids": this.newDocumentIds(),
            },
        };
        this.applyInProgress(true);
        this.errors([]);
        const form = getFormData({ request: JSON.stringify(request) });
        fetch(cgiScript("mutation_detail.py"), { method: "POST", body: form })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    // close popup and show success message
                    this.reloadRequired(true);
                    this.dialog.close();
                    notifications.showNotification(getTranslation("Mutation created"), "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again.")))
            .finally(() => this.applyInProgress(false));
    };

    private updateMutation = () => {
        const request = {
            "update_mutation": {
                "mutation_id": this.params.mutationId,
                "mutation_name": this.mutationName(),
                "official_name": this.officialName(),
                "mutation_type_id": this.mutationTypeId() || null,
                "genetic_characterization": this.geneticCharacterization(),
                "literature": this.literature(),
                "health_effect": this.healthEffect(),
                "status": this.mutationStatus(),
                "new_document_ids": this.newDocumentIds(),
            },
        };
        this.applyInProgress(true);
        this.errors([]);
        const form = getFormData({ request: JSON.stringify(request) });
        fetch(cgiScript("mutation_detail.py"), { method: "POST", body: form })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    // close popup and show success message
                    this.reloadRequired(true);
                    this.dialog.close();
                    notifications.showNotification(getTranslation("Mutation updated"), "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again.")))
            .finally(() => this.applyInProgress(false));
    };

}


export const showMutationDetails = dialogStarter(MutationDetailsViewModel, template, {
    name: "MutationDetails",
    width: 500,
    anchor: { top: 20, right: 20 },
    closeOthers: true,
});
