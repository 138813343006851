/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_setColumnSelection } from '../models/Body_setColumnSelection';
import type { ColumnSelectParams } from '../models/ColumnSelectParams';
import type { ListFilterGetRequest } from '../models/ListFilterGetRequest';
import type { ListFilterGetResponse } from '../models/ListFilterGetResponse';
import type { ListFilterPreferenceSetRequest } from '../models/ListFilterPreferenceSetRequest';
import type { ListFilterPresetSetRequest } from '../models/ListFilterPresetSetRequest';
import type { ListFilterRemoveRequest } from '../models/ListFilterRemoveRequest';
import type { ListFilterResetRequest } from '../models/ListFilterResetRequest';
import type { ListFilterSetRequest } from '../models/ListFilterSetRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ListViewService {

    /**
     * Get Column Select Params
     * Return the parameters for the Listview view_name.
     * @returns ColumnSelectParams Successful Response
     * @throws ApiError
     */
    public static getColumnSelectParams({
        viewName,
        mode = 'select',
    }: {
        viewName: string,
        mode?: 'select' | 'export',
    }): CancelablePromise<ColumnSelectParams> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/list_view/{view_name}/column_select',
            path: {
                'view_name': viewName,
            },
            query: {
                'mode': mode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Column Selection
     * Set the selected columns for the Listview view_name.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setColumnSelection({
        viewName,
        requestBody,
    }: {
        viewName: string,
        requestBody: Body_setColumnSelection,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/list_view/{view_name}/column_select',
            path: {
                'view_name': viewName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Filter Parameters
     * Get list filter "seed" parameters.
     * @returns ListFilterGetResponse Successful Response
     * @throws ApiError
     */
    public static getFilterParameters({
        viewName,
        requestBody,
    }: {
        viewName: string,
        requestBody: ListFilterGetRequest,
    }): CancelablePromise<ListFilterGetResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/list_view/{view_name}/filter/parameters',
            path: {
                'view_name': viewName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Filter
     * Set list filter.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setFilter({
        viewName,
        requestBody,
    }: {
        viewName: string,
        requestBody: ListFilterSetRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/list_view/{view_name}/filter/set',
            path: {
                'view_name': viewName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Filter
     * Reset list filter.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetFilter({
        viewName,
        requestBody,
    }: {
        viewName: string,
        requestBody: ListFilterResetRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/list_view/{view_name}/filter/reset',
            path: {
                'view_name': viewName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Execute Filter Request
     * Set list filter preferences.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setFilterPreferences({
        viewName,
        requestBody,
    }: {
        viewName: string,
        requestBody: ListFilterPreferenceSetRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/list_view/{view_name}/filter/set_preferences',
            path: {
                'view_name': viewName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Filter Preset
     * Set list filter preset.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setFilterPreset({
        viewName,
        requestBody,
    }: {
        viewName: string,
        requestBody: ListFilterPresetSetRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/list_view/{view_name}/filter/set_preset',
            path: {
                'view_name': viewName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Filter Preset
     * Remove filter preset.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeFilterPreset({
        viewName,
        requestBody,
    }: {
        viewName: string,
        requestBody: ListFilterRemoveRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/list_view/{view_name}/filter/remove_preset',
            path: {
                'view_name': viewName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Possible Filter Values
     * Get possible filter values.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getPossibleFilterValues({
        viewName,
        itemName,
        requestBody,
    }: {
        viewName: string,
        itemName: string,
        requestBody: (Record<string, any> | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/list_view/{view_name}/filter/possible_values/{item_name}',
            path: {
                'view_name': viewName,
                'item_name': itemName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
