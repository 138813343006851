import * as ko from "knockout";
import {
    MaybeSubscribable,
    Observable,
    ObservableArray,
    PureComputed,
    Subscribable,
} from "knockout";
import * as _ from "lodash";
import { cloneDeep } from "lodash";

import {
    ListFilterDefinition,
    ListFilterGetResponse,
    ListFilterPreset,
    ListViewService,
    StrainsService,
} from "../../backend/v1";
import {
    LocationItem,
    PreselectLocationItem,
} from "../../knockout/components/locationPicker/locationPicker";
import { htmlDialogStarter } from "../../knockout/dialogStarter";
import { FetchBackendExtended } from "../../knockout/extensions/fetchBackend";
import { CheckExtended } from "../../knockout/extensions/invalid";
import { getTranslation } from "../localize";
import { HtmlDialog } from "../popups";
import {
    getUrl,
    showLoading,
} from "../utils";

import template from "./listFilter.html";
import "./listFilter.scss";

export class ListFilterItem<ValueType = any> {
    public text: MaybeSubscribable<string | string[]>;
    public possibleValueArguments: MaybeSubscribable;
    public deserialize: (value: any) => any;
    public serialize: () => any;
    public valid: () => boolean;

    public onBlur: () => void;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    constructor(value: Observable<ValueType>, seed: ListFilterDefinition) {
        // pass
    }
}

/** Extended filter item used by location filters. */
export class ListFilterLocationItem extends ListFilterItem {
    public initialized: Observable<boolean | "loading">;
    public inProgress: PureComputed<boolean>;
    public possibleLocations: Observable<LocationItem[]>;
    public readonly preselectLocation: PreselectLocationItem;
    public readonly selectedLocation: Observable<LocationItem>;
    public hidePopupTrigger: Observable;
    public cleanLocationsTrigger: Observable;
    public selectLocationTrigger: Observable;

    constructor(value: Observable, seed: ListFilterDefinition) {
        super(value, seed);
        this.initialized = ko.observable(false);
        this.inProgress = ko.pureComputed(() => {
            return this.initialized() === "loading";
        });
        this.preselectLocation = seed.current_value;

        this.possibleLocations = ko.observable();
        this.text = ko.pureComputed(() => {
            return _.map(this.possibleLocations(), "name");
        });

        // @ts-expect-error: The Location picker seems to accept a PreselectLocationItem as a value
        this.selectedLocation = ko.observable(this.preselectLocation);

        this.hidePopupTrigger = ko.observable();
        this.cleanLocationsTrigger = ko.observable();
        this.selectLocationTrigger = ko.observable();

        this.onBlur = () => {
            this.hidePopupTrigger.valueHasMutated();
        };

        this.deserialize = (newValue) => {
            let newLocation;

            if (newValue === undefined) {
                this.cleanLocationsTrigger.valueHasMutated();
            } else {
                newLocation = _.find(this.possibleLocations(), (possibleLocation) => {
                    return possibleLocation.type === newValue.type && possibleLocation.db_id === newValue.id;
                });
                this.selectLocationTrigger(newLocation);
            }
        };

        this.serialize = () => {
            if (this.selectedLocation() === undefined) {
                return undefined;
            }

            return {
                type: this.selectedLocation().type,
                id: this.selectedLocation().db_id,
            };
        };
    }
}

/** Extended filter item used by mutation filters. */
export abstract class ListFilterMutationsItem extends ListFilterItem {
    public abstract strainNameOrIdFilter: Subscribable<FilterItemDefinition>;
    public mutationGrades: ObservableArray<{
        mutationValue: Observable<number>;
        gradeValue: Observable<number>;
    }>;
    public joinMutationGrades: Observable<boolean>;
    public possibleValues: PureComputed<{
        grades: {
            id: number;
            name: string;
        }[];
        mutations: {
            id: number;
            name: string;
            strain_ids: number[];
        }[];
        join_mutation: {
            id: "" | "any";
            name: string;
        }[];
    }>;
    public staticMutations: {
        name: string;
        id: number;
    }[];
    public strainMutations: PureComputed<
        {
            id: number;
            name: string;
            strain_ids: number[];
        }[]
    >;
    public selectedStrainIds: PureComputed<number[]>;
    public selectedCustomStrainIds: FetchBackendExtended<ObservableArray<number>>;
    public possibleMutations: PureComputed<
        {
            name: string;
            id: number;
        }[]
    >;
    public staticGrades: {
        name: string;
        id: number;
    }[];
    public possibleGrades: PureComputed<
        {
            name: string;
            id: number;
        }[]
    >;
    public inProgress: PureComputed;

    constructor(value: Observable, seed: ListFilterDefinition) {
        super(value, seed);
        this.mutationGrades = ko.observableArray().extend({ deferred: true });
        this.joinMutationGrades = ko.observable(false);

        // in most filters the mutation options depend on the strains that are selected in another filter field
        this.selectedCustomStrainIds = ko.observableArray().extend({
            fetchBackend: () => {
                const strainNameOrIdFilter = ko.unwrap(this.strainNameOrIdFilter);
                let selectedStrainNamesOrIds;
                let strainNames;
                let possibleStrainIds;

                if (
                    strainNameOrIdFilter &&
                    strainNameOrIdFilter.model.valid() &&
                    strainNameOrIdFilter.model.serialize()
                ) {
                    selectedStrainNamesOrIds = strainNameOrIdFilter.model.serialize();

                    if (!Array.isArray(selectedStrainNamesOrIds)) {
                        selectedStrainNamesOrIds = [selectedStrainNamesOrIds];
                    }

                    strainNames = selectedStrainNamesOrIds.filter((value) => {
                        return typeof value === "string";
                    });

                    if (strainNames.length) {
                        // @ts-expect-error: The foreign filter item must implement the `possibleValues` property
                        possibleStrainIds = strainNameOrIdFilter.model?.possibleValues()?.map((option) => {
                            return option.id;
                        });

                        if (possibleStrainIds?.length) {
                            return StrainsService.getStrainIdsByName({
                                requestBody: {
                                    strain_names: strainNames,
                                    possible_strain_ids: possibleStrainIds,
                                },
                            });
                        }
                    }
                }
            },
        });

        this.selectedStrainIds = ko.pureComputed(() => {
            const strainNameOrIdFilter = ko.unwrap(this.strainNameOrIdFilter);
            let selectedStrainNamesOrIds = [];

            if (strainNameOrIdFilter && strainNameOrIdFilter.model.serialize()) {
                selectedStrainNamesOrIds = strainNameOrIdFilter.model.serialize();

                if (!Array.isArray(selectedStrainNamesOrIds)) {
                    selectedStrainNamesOrIds = [selectedStrainNamesOrIds];
                }
            }

            return selectedStrainNamesOrIds
                .filter((value) => {
                    return typeof value === "number";
                })
                .concat(this.selectedCustomStrainIds() || []);
        });

        this.possibleValues = ko.pureComputed(() => {
            return ko.unwrap(seed.possible_values) || {};
        });

        this.staticMutations = [{ id: 0, name: getTranslation("None") }];
        this.strainMutations = ko.pureComputed(() => {
            const mutations = this.possibleValues().mutations || [];
            const selectedStrainIds = this.selectedStrainIds() || [];

            if (selectedStrainIds && selectedStrainIds.length) {
                // display mutations of the selected strains
                return mutations.filter((mutation) => {
                    // find intersection of selected strain ids and those attached to possible mutations ([0 => None])
                    return (mutation.strain_ids || [0]).filter((id) => {
                        return selectedStrainIds.indexOf(id) !== -1;
                    }).length;
                });
            }

            // display all available mutations
            return mutations;
        });
        this.possibleMutations = ko.pureComputed(() => {
            return this.staticMutations.concat(this.strainMutations());
        });

        this.staticGrades = [{ id: 0, name: getTranslation("N/A") }];
        this.possibleGrades = ko.pureComputed(() => {
            return this.staticGrades.concat(this.possibleValues().grades || []);
        });

        this.text = ko.pureComputed(() => {
            const possibleMutationNames = _.map(this.possibleMutations(), "name");
            const possibleGradeNames = _.map(this.possibleValues().grades, "name");

            return possibleMutationNames.concat(possibleGradeNames);
        });

        this.inProgress = ko.pureComputed(() => {
            return (
                this.selectedCustomStrainIds.inProgress() ||
                (seed.possible_values.inProgress && seed.possible_values.inProgress())
            );
        });

        this.valid = () => {
            return !this.inProgress() || this.serialize() === seed.default_value;
        };
    }

    public createFilterRow = (item?: { grade_id: number; mutation_id: number }) => {
        const mutationValue = ko.observable();
        const gradeValue = ko.observable();

        if (item instanceof Object && item.mutation_id >= 0) {
            mutationValue(item.mutation_id);
        }

        if (item instanceof Object && item.grade_id >= 0) {
            gradeValue(item.grade_id);
        }

        mutationValue.subscribe(this.checkFilterRows);
        gradeValue.subscribe(this.checkFilterRows);

        return { mutationValue: mutationValue, gradeValue: gradeValue };
    };

    public removeEmptyFilterRow = () => {
        const mutationGrades = this.mutationGrades();
        const firstEmptyFilterIndex = mutationGrades.findIndex((item) => {
            return isNaN(item.mutationValue()) && isNaN(item.gradeValue());
        });

        mutationGrades.splice(firstEmptyFilterIndex, 1);
        this.mutationGrades(mutationGrades);
    };

    public checkFilterRows = () => {
        const emptyMutationGrades = this.mutationGrades().filter((item) => {
            return isNaN(item.mutationValue()) && isNaN(item.gradeValue());
        });

        if (!emptyMutationGrades.length) {
            this.mutationGrades.push(this.createFilterRow());
        } else if (emptyMutationGrades.length >= 2) {
            this.removeEmptyFilterRow();
        }
    };

    public serialize = () => {
        let lastIndex = 0;
        const knownCombinations: {
            mutation_id: number | `mutation_id_${number}`;
            grade_id: number | `grade_id_${number}`;
        }[] = [];
        const result = _.transform(
            this.mutationGrades(),
            (result: any, mutationGrade) => {
                let isNew;

                if (mutationGrade.mutationValue() >= 0 || mutationGrade.gradeValue() >= 0) {
                    // do not add duplicates
                    isNew = _.every(knownCombinations, (otherMutationGrade) => {
                        return (
                            otherMutationGrade.mutation_id !== mutationGrade.mutationValue() ||
                            otherMutationGrade.grade_id !== mutationGrade.gradeValue()
                        );
                    });

                    if (isNew) {
                        if (mutationGrade.mutationValue() >= 0) {
                            result["mutation_id_" + lastIndex] = mutationGrade.mutationValue();
                        }

                        if (mutationGrade.gradeValue() >= 0) {
                            result["grade_id_" + lastIndex] = mutationGrade.gradeValue();
                        }

                        knownCombinations.push({
                            mutation_id: result["mutation_id_" + lastIndex],
                            grade_id: result["grade_id_" + lastIndex],
                        });
                        lastIndex += 1;
                    }
                }
            },
            {},
        );

        if (_.isEmpty(result)) {
            return undefined;
        }

        if (this.joinMutationGrades()) {
            result["join_values"] = this.joinMutationGrades();
        }

        return result;
    };

    public deserialize = (current_value: any) => {
        let mutationGrades;

        if (current_value && !_.isEmpty(current_value)) {
            mutationGrades = _.transform(
                current_value,
                (result, value: number, key: string) => {
                    let index;

                    if (key.startsWith("mutation_id_")) {
                        index = parseInt(key.slice("mutation_id_".length), 10);
                        while (result.length < index + 1) {
                            result.push({});
                        }

                        result[index].mutation_id = value;
                    } else if (key.startsWith("grade_id_")) {
                        index = parseInt(key.slice("grade_id_".length), 10);
                        while (result.length < index + 1) {
                            result.push({});
                        }

                        result[index].grade_id = value;
                    }
                },
                [],
            );

            this.mutationGrades(mutationGrades.map(this.createFilterRow).concat(this.createFilterRow()));
            this.joinMutationGrades(current_value.join_values);
        } else {
            this.mutationGrades([this.createFilterRow()]);
            this.joinMutationGrades(undefined);
        }
    };
}

export class FilterItemDefinition {
    public value: Observable;
    public seed: ListFilterDefinition;
    public model: ListFilterItem;
    private isVisible: Subscribable<boolean>;

    constructor(
        viewName: string,
        itemName: string,
        model: typeof ListFilterItem,
        itemParams: ListFilterDefinition,
        isVisible: Subscribable<boolean>,
    ) {
        this.value = ko.observable();
        this.seed = itemParams;
        this.isVisible = isVisible;

        if (itemParams.lazy_load === true) {
            this.seed.possible_values = ko.observable();
        }

        this.model = new model(this.value, this.seed);

        // JSON encoded request parameters, used to throttle requests
        const possibleValueRequestArguments = ko
            .pureComputed(() => JSON.stringify(ko.utils.unwrapObservable(this.model.possibleValueArguments) || {}))
            .extend({ rateLimit: { timeout: 250, method: "notifyWhenChangesStop" } });

        if (itemParams.lazy_load === true) {
            this.seed.possible_values
                .extend({
                    fetchBackend: () =>
                        ListViewService.getPossibleFilterValues({
                            viewName,
                            itemName,
                            requestBody: JSON.parse(possibleValueRequestArguments()),
                        }),
                })
                .subscribeOnce(() => {
                    _.defer(() => {
                        this.setCurrentValue();
                    });
                });
        }

        if (!_.isFunction(this.model.deserialize)) {
            // provide default deserialize method
            this.model.deserialize = (value) => {
                return this.value(value);
            };
        }

        if (!_.isFunction(this.model.serialize)) {
            // provide default serialize method
            this.model.serialize = ko
                .pureComputed(() => {
                    return this.value() === "" ? undefined : this.value();
                })
                .extend({ deferred: true });
        }

        if (!_.isFunction(this.model.valid)) {
            // provide default validator method
            this.model.valid = ko
                .pureComputed(() => {
                    if (ko.isObservable(this.seed.possible_values)) {
                        const possibleValues = this.seed.possible_values as FetchBackendExtended<Observable>;
                        if (possibleValues.inProgress()) {
                            return false;
                        }
                    }
                    if (ko.isObservable((this.value as any).isValid)) {
                        if (!(this.value as CheckExtended<Observable>).isValid()) {
                            return false;
                        }
                    }
                    return true;
                })
                .extend({ deferred: true });
        }
    }

    public setCurrentValue = () => {
        this.model.deserialize(cloneDeep(this.seed.current_value));
    };
}

class PreferencesEditor {
    public active: Observable<boolean>;
    private listFilter: ListFilterModel;
    private readonly inProgress: Observable<boolean>;
    private filterItems: PureComputed<
        {
            names: string[];
            label: string;
            unit: string;
            isFavorite: Observable<boolean>;
            toggleFavorite: () => void;
            isHidden: Observable<boolean>;
            toggleHidden: () => void;
            isModified: PureComputed<boolean>;
        }[]
    >;

    constructor(listFilter: ListFilterModel) {
        this.listFilter = listFilter;
        this.active = ko.observable(false).extend({ deferred: true });

        this.filterItems = ko
            .pureComputed(() => {
                return _.chain(listFilter.allFilters())
                    .pickBy((itemDeclaration) => {
                        return itemDeclaration.seed.show_in_preferences;
                    })
                    .mapValues((itemDeclaration, itemName) => {
                        const isFavorite = ko
                            .observable(itemDeclaration.seed.favorite || false)
                            .extend({ deferred: true });
                        const isHidden = ko.observable(itemDeclaration.seed.hidden || false).extend({ deferred: true });

                        return {
                            names: _.union([itemName], itemDeclaration.seed.preferences_for || []),
                            label: _.isObject(itemDeclaration.seed.label)
                                ? itemDeclaration.seed.label[itemName]
                                : itemDeclaration.seed.label,
                            unit: itemDeclaration.seed.unit,
                            isFavorite: isFavorite,
                            toggleFavorite: () => {
                                isFavorite(!isFavorite());
                            },
                            isHidden: isHidden,
                            toggleHidden: () => {
                                isHidden(!isHidden());
                            },
                            isModified: ko
                                .pureComputed(() => {
                                    return (
                                        (itemDeclaration.seed.hidden || false) !== isHidden() ||
                                        (itemDeclaration.seed.favorite || false) !== isFavorite()
                                    );
                                })
                                .extend({ deferred: true }),
                        };
                    })
                    .sortBy("label")
                    .value();
            })
            .extend({ deferred: true });

        // save modified filters and reload
        this.inProgress = ko.observable(false).extend({ deferred: true });
    }
    public save = () => {
        this.inProgress(true);
        ListViewService.setFilterPreferences({
            viewName: this.listFilter.viewName,
            requestBody: {
                set: _.chain(this.filterItems())
                    .pickBy((item) => {
                        return item.isModified() || item.isFavorite();
                    })
                    .map((item) => {
                        return _.map(item.names, (name) => {
                            return { name: name, favorite: item.isFavorite(), hidden: item.isHidden() };
                        });
                    })
                    .flattenDeep()
                    .value(),
            },
        })
            .then(() => {
                this.active(false);

                // reload filter configuration and re-instantiate filter items after retrieving data
                this.listFilter.filterInitializationInProgress(true);
                this.listFilter.seed.forceReload();
            })
            .finally(() => this.inProgress(false));
    };
}

type PresetManagerItem = ListFilterPreset & { inProgress: Observable<boolean> };

class PresetManager {
    public active: Observable<boolean>;
    public definedFilters: PureComputed<Record<string, FilterItemDefinition>>;
    private listFilter: ListFilterModel;
    private readonly newPresetName: Observable<string>;
    private availablePresets: PureComputed<PresetManagerItem[]>;
    private readonly inProgress: Observable<boolean>;
    constructor(listFilter: ListFilterModel) {
        this.listFilter = listFilter;

        this.active = ko.observable(false).extend({ deferred: true });

        listFilter.selectedCategory.subscribe(() => {
            this.active(false);
        });

        this.newPresetName = ko.observable().extend({
            invalid: (v) => {
                return !(v && v.length > 2);
            },
            deferred: true,
        });

        // list of all presets in the server configuration
        this.availablePresets = ko
            .pureComputed(() => {
                return _.chain(listFilter.seed() && listFilter.seed().presets)
                    .map((item) => ({
                        inProgress: ko.observable(false).extend({ deferred: true }),
                        ...item,
                    }))
                    .sortBy("name")
                    .value();
            })
            .extend({ deferred: true });

        // list of filters that are not in the default filter's state
        this.definedFilters = ko
            .pureComputed(() => {
                return _.pickBy(listFilter.allFilters(), (itemDeclaration) => {
                    const value = itemDeclaration.model.serialize();
                    const defaultValue = itemDeclaration.seed.default_value;
                    if (_.isArray(value)) {
                        // compare arrays
                        return !_.isEqual(value, (_.isArray(defaultValue) ? defaultValue : [defaultValue]));
                    }
                    return value !== defaultValue;
                });
            })
            .extend({ deferred: true });

        // save new preset and reload
        this.inProgress = ko.observable(false).extend({ deferred: true });
    }
    public save = () => {
        this.inProgress(true);
        ListViewService.setFilterPreset({
            viewName: this.listFilter.viewName,
            requestBody: {
                set: {
                    name: this.newPresetName(),
                    filters: _.mapValues(this.definedFilters(), (itemDeclaration) => {
                        const value = itemDeclaration.model.serialize();
                        return value === undefined ? null : value;
                    }),
                },
            },
        })
            .then(() => {
                this.listFilter.seed.forceReload();
                this.active(false);
                this.listFilter.dialogReloadListOnClose(true);
            })
            .finally(() => this.inProgress(false));
    };

    public load = (reset: boolean, item: PresetManagerItem) => {
        item.inProgress(true);
        setTimeout(() => {
            if (reset) {
                this.listFilter.reset();
            }
            this.newPresetName(item.name);
            _.forEach(item.filters, (filterValue, filterName) => {
                if (_.has(this.listFilter.allFilters(), filterName)) {
                    this.listFilter.allFilters()[filterName].model.deserialize(filterValue);
                }
            });
            this.listFilter.itemTextFilter("");
            this.listFilter.selectCategoryByValue(this.listFilter.specialCategories.modified);
            item.inProgress(false);
        }, 0);
    };

    public loadWithReset = (item: PresetManagerItem) => this.load(true, item);
    public loadWithoutReset = (item: PresetManagerItem) => this.load(false, item);

    public remove = (item: PresetManagerItem) => {
        item.inProgress(true);
        ListViewService.removeFilterPreset({
            viewName: this.listFilter.viewName,
            requestBody: {
                remove: item.name,
            },
        })
            .then(() => {
                this.listFilter.seed.forceReload();
                this.listFilter.dialogReloadListOnClose(true);
            })
            .finally(() => item.inProgress(false));
    };
}

type ListFilterCategory = { label: string; value: string | undefined };

export class ListFilterModel {
    public viewName: string;
    public filterTemplateNodes: HTMLElement[];
    public dialogHeight: Observable<number>;
    public filterInitializationInProgress: Observable<boolean>;
    public dialogReloadListOnClose = ko.observable(false);
    public allFilters: Observable<Record<string, FilterItemDefinition>>;
    public seed: FetchBackendExtended<Observable<ListFilterGetResponse>>;
    public itemTextFilter: Observable<string>;
    public selectedCategory: Observable<ListFilterCategory>;
    public specialCategories: Record<string, typeof undefined>;
    private dialog: HtmlDialog;
    private readonly submitInProgress: Observable<boolean>;
    private readonly availableCategories: PureComputed<ListFilterCategory[]>;
    private allFiltersValid: PureComputed<boolean>;
    private readonly modifiedFilters: PureComputed<Record<string, FilterItemDefinition>>;
    private readonly visibleFilters: PureComputed<Record<string, FilterItemDefinition>>;
    private preferencesEditor: PreferencesEditor;
    private presetManager: PresetManager;
    private onSubmit: () => void;

    constructor(
        dialog: HtmlDialog,
        params: {
            viewName: string;
            filterModels: (filter: ListFilterModel) => {
                [name: string]: new (value: ko.Observable, seed: ListFilterDefinition) => ListFilterItem;
            };
            filterTemplate: string;
            title?: string;
            onSubmit?: () => void;
        },
    ) {
        this.dialog = dialog;
        this.viewName = params.viewName;
        this.onSubmit = params.onSubmit;

        const templateContainer = document.createElement("div");
        templateContainer.innerHTML = params.filterTemplate;
        this.filterTemplateNodes = [templateContainer.childNodes[0] as HTMLElement];
        this.dialogHeight = ko.observable(540);

        // ensure required observables are available when the viewModel is passed around
        this.filterInitializationInProgress = ko.observable(true);
        this.submitInProgress = ko.observable(false);
        this.allFilters = ko.observable({});
        const filterModels = params.filterModels(this);

        // filter configuration
        this.seed = ko.observable().extend({
            deferred: true,
            fetchBackend: () =>
                ListViewService.getFilterParameters({
                    viewName: this.viewName,
                    requestBody: {
                        get: Object.keys(filterModels),
                    },
                }),
        });

        // helper to limit the amount of filters to show
        this.itemTextFilter = ko
            .observable("")
            .extend({ rateLimit: { timeout: 100, method: "notifyWhenChangesStop" } });
        this.selectedCategory = ko.observable(undefined).extend({ deferred: true });

        // list of magic categories to show next to the basic categories
        this.specialCategories = {
            all: (): typeof undefined => undefined,
            favorites: (): typeof undefined => undefined,
            modified: (): typeof undefined => undefined,
            presets: (): typeof undefined => undefined,
        };

        // list of all categories we have
        this.availableCategories = ko
            .pureComputed(() => {
                const categoryLabels = {
                    animal: getTranslation("Animal"),
                    breeding_setup: getTranslation("Defaults for breeding"),
                    cage: getTranslation("Cage"),
                    history: getTranslation("History"),
                    tank: getTranslation("Tank"),
                    work_request: getTranslation("Work request"),
                    cryotank: getTranslation("Cryotank"),
                    embryo: getTranslation("Embryo"),
                    sperm: getTranslation("Sperm"),
                    severity_assessment: getTranslation("Severity assessment"),
                    stud: getTranslation("Stud males"),
                    attributes: getTranslation("Attributes"),
                    permissions: getTranslation("Permissions"),
                    license_assignment: getTranslation("License assignment"),
                };

                const filters = Object.values(this.seed()?.filters || []);
                const filterCategories = filters.flatMap((fd) => fd.category);
                const uniqueCategories = [...new Set(filterCategories)];
                const categories = uniqueCategories
                    .map((category: keyof typeof categoryLabels) => ({
                        value: category,
                        label: categoryLabels?.[category] || category,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label));

                return [
                    { value: this.specialCategories.favorites, label: getTranslation("Favorites") },
                    { value: this.specialCategories.all, label: getTranslation("All") },
                    ...categories,
                    { value: this.specialCategories.modified, label: getTranslation("Modified") },
                    { value: this.specialCategories.presets, label: getTranslation("Saved") },
                ];
            })
            .extend({ deferred: true });

        // list of all filters with a valid configuration
        this.seed.subscribe((newValue) => {
            // if seed is not set yet - do nothing
            if (!newValue || !newValue.filters) return;

            // only instantiate filter items if not already done
            if (this.filterInitializationInProgress()) {
                // first collect all filter instances privately
                const filterInstances: Record<string, FilterItemDefinition> = {};

                _.forEach(_.keys(newValue.filters), (itemName) => {
                    if (!(itemName in filterModels)) return;

                    // instantiate the filter item
                    filterInstances[itemName] = new FilterItemDefinition(
                        this.viewName,
                        itemName,
                        filterModels[itemName],
                        newValue.filters[itemName],
                        ko.pureComputed(() => _.includes(this.visibleFilters(), filterInstances[itemName])),
                    );
                });

                // announce that filters are initailized
                this.filterInitializationInProgress(false);

                // assign all instances at once
                this.allFilters(filterInstances);
            }

            // set filters current values
            _.forEach(_.values(this.allFilters()), (filterInstance) => {
                filterInstance.setCurrentValue();
            });
        });

        // test if the validators o all filters are true
        this.allFiltersValid = ko
            .pureComputed(() => {
                return _.every(
                    _.map(this.allFilters(), (itemDeclaration) => {
                        return itemDeclaration.model.valid();
                    }),
                );
            })
            .extend({ deferred: true });

        // list of filters that are not in the currently active filter's state
        this.modifiedFilters = ko
            .pureComputed(() => {
                return _.pickBy(this.allFilters(), (itemDeclaration) => {
                    return itemDeclaration.model.serialize() !== itemDeclaration.seed.current_value;
                });
            })
            .extend({ deferred: true });

        // filters to show in the frontend, based on category and text search
        this.visibleFilters = ko
            .pureComputed(() => {
                return _.chain(this.allFilters())
                    .pickBy((itemDeclaration) => {
                        // keep only items not marked as hidden
                        return !itemDeclaration.seed.hidden;
                    })
                    .pickBy((itemDeclaration) => {
                        // keep all items for the special 'all' category
                        if (this.selectedCategory().value === this.specialCategories.all) {
                            return true;
                        }

                        // keep only favorite items for the special favorite category
                        else if (
                            this.selectedCategory().value === this.specialCategories.favorites &&
                            !this.preferencesEditor.active()
                        ) {
                            return Boolean(itemDeclaration.seed.favorite);
                        }

                        // keep only defined items for the special modified category
                        else if (this.selectedCategory().value === this.specialCategories.modified) {
                            const definedFilters = this.presetManager.definedFilters();
                            if (_.includes(definedFilters, itemDeclaration)) {
                                // filter item is defined
                                return true;
                            }
                            if (itemDeclaration.seed.preferences_for) {
                                if (_.includes(definedFilters, this.allFilters()?.[itemDeclaration.seed.preferences_for])) {
                                    // related filter item is defined
                                    return true;
                                }
                            }
                            return false;
                        }

                        // keep all items for the special 'presets' category in active mode
                        if (
                            this.selectedCategory().value === this.specialCategories.presets &&
                            this.presetManager.active()
                        ) {
                            return itemDeclaration.seed.show_in_presets;
                        }

                        // keep only items of the selected category otherwise
                        return _.includes(itemDeclaration.seed.category || [], this.selectedCategory().value);
                    })
                    .pickBy((itemDeclaration) => {
                        const textOf = (val: any): string => {
                            if (_.isObject(val)) {
                                return _.map(_.values(val), textOf).join(" | ");
                            } else if (_.isArray(val)) {
                                _.map(val, textOf).join(" | ");
                            } else {
                                return val;
                            }
                        };

                        // if there is no search string just skip
                        if (!this.itemTextFilter().length) {
                            return true;
                        }

                        // keep only items including the search string
                        const searchText = _.filter(
                            [
                                textOf(itemDeclaration.seed.label),
                                textOf(itemDeclaration.seed.unit),
                                textOf(itemDeclaration.seed.help),
                                textOf(ko.utils.unwrapObservable(itemDeclaration.model.text)),
                            ],
                            _.identity,
                        )
                            .join(" | ")
                            .toLowerCase();
                        return searchText.indexOf(this.itemTextFilter().toLowerCase()) !== -1;
                    })
                    .value();
            })
            .extend({ deferred: true });

        // compare the object properly
        this.visibleFilters.equalityComparer = _.isEqual;

        // notify previous visible filter models on update to hide possible dependencies (e.g. location picker dialog)
        this.visibleFilters.subscribeChanged((currentFilters, previousFilters) => {
            _.forEach(previousFilters, (hiddenItem) => {
                if (_.isFunction(hiddenItem.model.onBlur)) {
                    hiddenItem.model.onBlur();
                }
            });
        });

        this.availableCategories.subscribe((availableCategories) => {
            const visibleFavorites = _.chain(this.allFilters())
                .pickBy((itemDeclaration) => {
                    // keep only items marked as favorite but not hidden
                    return itemDeclaration.seed.favorite && !itemDeclaration.seed.hidden;
                })
                .values()
                .value().length;

            if (
                _.isEqual(
                    this.selectedCategory(),
                    _.find(availableCategories, { value: this.specialCategories.presets }),
                ) &&
                this.seed() &&
                this.seed().presets.length
            ) {
                // stay in saved/presets category after load when the user added or removed a preset
                // except when the last preset was removed
                this.selectCategoryByValue(this.specialCategories.presets);
            } else if (visibleFavorites) {
                // preselect favorites category if the user has defined some
                this.selectCategoryByValue(this.specialCategories.favorites);
            } else {
                this.selectCategoryByValue(this.specialCategories.all);
            }
        });

        // select all special category when entering a search string
        this.itemTextFilter.subscribe((v) => {
            if (v && v.length) {
                this.selectCategoryByValue(this.specialCategories.all);
            }
        });

        this.dialog.addOnClose(() => {
            if (this.dialogReloadListOnClose()) {
                window.location.reload();
            }
        });

        // the model for the preferences editor
        this.preferencesEditor = new PreferencesEditor(this);

        // the model for the preset Manager
        this.presetManager = new PresetManager(this);
    }

    public selectCategoryByValue = (value: ListFilterCategory["value"]) => {
        this.selectedCategory(_.find(this.availableCategories(), { value: value }));
    };

    public getValue<ValueType = any>(v: string): ValueType {
        const filterDefinition = this.allFilters()[v];
        return filterDefinition ? filterDefinition.model.serialize() : undefined;
    }

    // reset all filters to their default_value
    public reset = () => {
        _.forEach(this.allFilters(), (itemDeclaration) => {
            itemDeclaration.model.deserialize(itemDeclaration.seed.default_value);
        });
    };

    // send the filter value to the server
    public submit = () => {

        if (!this.allFiltersValid() || this.submitInProgress()) {
            return;
        }

        const filters = _.mapValues(this.modifiedFilters(), (itemDeclaration) => {
            // Keys with undefined values are removed by stringify. This means if serialize()
            // returns undefined, the filter gets removed (this is the defined behavior of
            // an omitted value key). To set a value to undefined (python None), serialize
            // must return null.
            return itemDeclaration.model.serialize();
        });

        this.submitInProgress(true);
        ListViewService.setFilter({
            viewName: this.viewName,
            requestBody: {
                set: _.values(
                    _.mapValues(filters, (itemValue, itemName) => {
                        return { name: itemName, value: itemValue };
                    }),
                ),
            },
        }).then(() => {
            if (typeof this.onSubmit === "function") {
                this.onSubmit();
            } else {
                window.location.href = getUrl(window.location.href, { }, { clearParams: true, clearHash: true });
            }
        });
    };
}

export const showListFilter = htmlDialogStarter(ListFilterModel, template, (params) => ({
    name: "ColumnSelect",
    title: params.title || getTranslation("List filter"),
    width: 840,
    position: { inset: { left: 25, top: 25 } },
}));

export const setListFilter = (
    viewName: string,
    params: Record<string, any>,
    callBack = (): any => (window.location.href = getUrl(window.location.href, {}, { clearParams: true, clearHash: true })),
) => {
    showLoading(
        ListViewService.setFilter({
            viewName,
            requestBody: {
                set: Object.keys(params).map((key) =>
                    params[key] ? { name: key, value: params[key] } : { name: key },
                ),
            },
        }),
    ).then(callBack);
};
export const resetListFilter = (
    viewName: string,
    params: null | Record<string, any> = null,
    callBack = (): any => (window.location.href = getUrl(window.location.href, {}, { clearParams: true, clearHash: true })),
) => {
    showLoading(
        ListViewService.resetFilter({
            viewName,
            requestBody: {
                reset: params,
            },
        }),
    ).then(callBack);
};
