import * as ko from "knockout";
import * as _ from "lodash";

import {
    SacrificeMethodChange,
    SacrificeMethodsService,
} from "../backend/v1";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";


interface Arguments {
    sacrifice_methods: {
        id: number;
        name: string;
        available: boolean;
        deletable: boolean;
    }[];
}

interface Row {
    id?: number;
    name: CheckExtended<ko.Observable<string>>;
    available: ko.Observable<boolean>;
    deletable: boolean;
    toggleDelete: SacrificeMethodList["toggleDelete"];
    inserted: ko.Observable<boolean>;
    deleted: ko.Observable<boolean>;
    changed: ko.Observable<boolean>;
}

class SacrificeMethodList {
    public readonly allowEdit: boolean = session.userPermissions.sacrifice_method_update;
    public readonly hideUnavailable: ko.Observable<boolean>;

    public readonly sacrificeMethods: ko.ObservableArray<Row>;
    public readonly sacrificeMethodChanges: ko.PureComputed<SacrificeMethodChange[]>;
    public readonly canSubmit: ko.PureComputed<boolean>;
    public readonly submitInProgress: ko.Observable<boolean> = ko.observable(false);
    public readonly errorMessage: ko.PureComputed<string | boolean>;

    constructor(args: Arguments) {

        this.hideUnavailable = ko.observable(true);

        this.sacrificeMethods = ko.observableArray(
            _.map(args.sacrifice_methods || [], (row) => {
                const rowModel = {
                    id: row.id,
                    name: ko.observable(row.name).extend({
                        trim: true,
                        invalid: (v) => {
                            if (!v) {
                                return getTranslation("Please enter a name");
                            }

                            return false;
                        },
                    }),
                    available: ko.observable(row.available),
                    deletable: row.deletable,
                    toggleDelete: this.toggleDelete,
                    inserted: ko.observable(false),
                    changed: ko.observable(false),
                    deleted: ko.observable(false),
                };
                const handleChange = () => {
                    rowModel.changed(true);
                    this.highlightSaveButton();
                };

                rowModel.name.subscribe(handleChange);
                rowModel.available.subscribe(handleChange);

                return rowModel;
            }),
        );

        this.errorMessage = ko.pureComputed(() => {
            return _.reduce(
                this.sacrificeMethods(),
                (msg, row) => {
                    if (!row.deleted() && (row.inserted() || row.changed())) {
                        return msg || row.name.errorMessage();
                    }
                    return msg;
                },
                false,
            );
        });

        this.sacrificeMethodChanges = ko.pureComputed(() => {
            return _.chain(this.sacrificeMethods())
                .filter((row) => {
                    return row.inserted() || row.changed() || row.deleted();
                })
                .map((row) => {
                    if (row.deleted()) {
                        // delete sacrifice method
                        return {
                            action: "delete" as SacrificeMethodChange["action"],
                            id: row.id,
                        };
                    } else if (row.changed() && row.id) {
                        // update sacrifice method
                        return {
                            action: "update" as SacrificeMethodChange["action"],
                            id: row.id,
                            name: row.name(),
                            available: row.available(),
                        };
                    } else if (row.inserted()) {
                        // create sacrifice method
                        return {
                            action: "create" as SacrificeMethodChange["action"],
                            name: row.name(),
                            available: row.available(),
                        };
                    }
                })
                .value();
        });

        this.canSubmit = ko.computed(() => {
            const anyChange = _.some(this.sacrificeMethods(), function(row) {
                return row.inserted() || row.changed() || row.deleted();
            });
            const anyInvalid = _.some(this.sacrificeMethods(), function(row) {
                return row.name.isInvalid();
            });

            return !this.submitInProgress()
                && !anyInvalid
                && anyChange;
        });
    }

    public highlightSaveButton = () => {
        const saveButton = document.getElementById("save_button");

        saveButton.classList.add("blink_twice");
        saveButton.addEventListener("animationend", function () {
            this.classList.remove("blink_twice");
        });
    };

    toggleDelete = (row: Row) => {
        if (!row.id) {
            this.sacrificeMethods.remove(row);
        } else {
            row.deleted(!row.deleted());
            if (row.deleted()) {
                this.highlightSaveButton();
            }
        }
    };

    public addSacrificeMethod = () => {
        this.sacrificeMethods.push({
            name: ko.observable().extend({
                trim: true,
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please enter a name");
                    }
                    return false;
                },
            }),
            available: ko.observable(true),
            deletable: true,
            inserted: ko.observable(true),
            changed: ko.observable(true),
            deleted: ko.observable(false),
            toggleDelete: this.toggleDelete,
        });
        this.highlightSaveButton();
    };

    public submit = () => {
        this.submitInProgress(true);
        SacrificeMethodsService.updateSacrificeMethods({ requestBody: this.sacrificeMethodChanges() })
            .then(() => {
                notifications.showNotification(getTranslation("Changes saved"), "success");
                this.reload();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
                    writeException(reason);
                }
            })
            .finally(() => {
                this.submitInProgress(false);
            });
    };

    public reload = () => {
        window.location.reload();
    };
}

export const initSacrificeMethodList = (args: Arguments): void => {
    ko.applyBindings(new SacrificeMethodList(args), document.getElementById("sacrifice_method_list"));
};
