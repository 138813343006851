import * as ko from "knockout";
import * as _ from "lodash";

import {
    IdNameProperty,
    ListFilterDefinition,
} from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    frames,
    mainMenu,
} from "../../lib/pyratTop";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./transgenicTransferListFilter.html";

interface TransgenicTransferListViewArguments extends ListViewArguments {
    export_args: any;
}

interface StrainOption {
    id: number;
    name?: string;
    name_id?: number;
    name_with_id: string;
    official_name?: string;
}

interface ProjectOption extends IdNameProperty {
    group?: "active" | "inactive" | "expired";
    owner_fullname?: string;
}

const TransgenicTransferListFilters = (filter: ListFilterModel) => ({
    strain_name_or_id: class extends ListFilterItem {
        private readonly selectemValue: ko.ObservableArray<StrainOption & {
            id: number | string;
            valid?: ko.Observable<boolean>;
        }>;
        private readonly currentCustomValues: ko.ObservableArray<string>;

        constructor(value: ko.Observable<(number | string)[]>, seed: ListFilterDefinition) {
            super(value, seed);

            this.selectemValue = ko.observableArray();
            this.currentCustomValues = ko.observableArray();

            this.selectemValue.subscribe((newValue) => {
                newValue.forEach((option) => {
                    // @ts-expect-error: says that `option.id` can only be `number` and the checks are only if it was `string` (which it can be)
                    if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                        option.valid(false);
                    }
                });
            });
            this.text = seed.possible_values?.map((v: StrainOption) => v.name_with_id);

            this.deserialize = (newValue) => {
                const customValues = Array.isArray(newValue) ? newValue.filter((value) => typeof value === "string") : [];

                if (customValues.length) {
                    this.currentCustomValues(customValues);
                }
                value(newValue);
            };
        }

        public valid = () => {
            return this.selectemValue().every((option) => !option.valid || option.valid());
        };

    },

    owner_id: class extends ListFilterItem {
        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
        }
    },

    project_id: class extends ListFilterItem {
        private readonly staticValues: Array<IdNameProperty>;
        private readonly possibleValues: ProjectOption[];

        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.staticValues = [{ id: 0, name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values || []);
            this.text = [].concat(...(seed.possible_values?.map((v: ProjectOption) => [v.name, v.owner_fullname]) || []));
        }
    },

    origin_id: class extends ListFilterItem {
        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
        }
    },

    transfer_date_from: class extends ListFilterItem {
        private value: CheckExtended<ko.Observable<string>>;

        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("transfer_date_to"), compareFromDate),
            });
        }

        public valid = () => {
            return this.value.isValid();
        };
    },

    transfer_date_to: class extends ListFilterItem {
        private value: CheckExtended<ko.Observable<string>>;

        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("transfer_date_from"), compareToDate),
            });
        }

        public valid = () => {
            return this.value.isValid();
        };
    },

    transfer_last_modified: class extends ListFilterItem {
        private value: CheckExtended<ko.Observable<string>>;

        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
            });
        }

        public valid = () => {
            return this.value.isValid();
        };
    },
});

class TransgenicTransferList {
    private listView: ListView;
    private args: TransgenicTransferListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: TransgenicTransferListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: TransgenicTransferListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Transfer filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, {
                show_print: "true",
            }));
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.export_args,
            });
        });

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        // Table Body

        this.listView.onCellClick("td.transfer_date a, td.strain_name a", (args) => {
            mainMenu.openAndResetListFilter("get_embryo_list", { transgenic_transfer_group: args.rowId });
        });

        this.listView.onCellClick("td.transgenic_transfer_history_events a", (args) => {
            mainMenu.openAndResetListFilter("get_embryo_list", {
                transgenic_transfer_group: args.rowId,
                related_event_type: [parseInt(args.element.dataset.eventId, 10)],
            });
        });

        this.listView.onCellClick("td.embryo_transfer_count a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", { transgenic_transfer_group_foster: args.rowId });
        });

        this.listView.onCellClick("td.transgenic_transfer_offspring_pups a", (args) => {
            mainMenu.openAndResetListFilter("get_pup_list", {
                transgenic_transfer_group: args.rowId,
                state: "live",
            });
        });

        this.listView.onCellClick("td.transgenic_transfer_offspring_animals a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                transgenic_transfer_group: args.rowId,
                state: "live",
            });
        });

        this.listView.onCellClick("td.transgenic_transfer_genetic_status_animals a", (args) => {
            const geneticStatus = args.element.dataset.geneticStatus;

            mainMenu.openAndResetListFilter("get_animal_list", {
                transgenic_transfer_group: args.rowId,
                state: "live",
                genetic_status: geneticStatus === "altered" ? 1 : geneticStatus === "not_altered" ? 0 : null,
            });
        });


        this.listView.onCellClick("td.transfer_foster_mothers a", (args) => {
            this.showAnimalDetails(args.rowId, parseInt(args.element.dataset.animalId, 10));
        });

        this.listView.onCellClick("td.donor_mothers a", (args) => {
            this.showAnimalDetails(args.rowId, parseInt(args.element.dataset.animalId, 10));
        });
    }

    public showAnimalDetails = (highlightRowId: string, animalId: number) => {
        this.listView.highlightRow(highlightRowId);

        frames.openListDetailPopup(getUrl(cgiScript("mousedetail.py"), {
            animalid: animalId,
        }), () => this.listView.unHighlightRow(highlightRowId));
    };
}

export const initTransgenicTransferList = (args: TransgenicTransferListViewArguments): void => {
    new TransgenicTransferList(document.querySelector("div.listview"), args);
};
