import { Observable } from "knockout";

import {
    ListFilterDefinition,
    MutationsService,
} from "../../backend/v1";
import { showMutationDetails } from "../../dialogs";
import {
    ListFilterItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { notifications } from "../../lib/pyratTop";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    getUrl,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./mutationListFilter.html";

interface Arguments extends ListViewArguments {
    export_args: any;
}

const ListFilters = () => ({
    status: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    strain_used: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    name: ListFilterItem,

    official_name: ListFilterItem,

    type_id: class extends ListFilterItem {
        staticValues = [{ id: 0, name: getTranslation("None") }];
        possibleValues: { name: string; id: number }[];
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.possibleValues = this.staticValues.concat(seed.possible_values);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    page_size: ListFilterItem,
});

class List {
    private listView: ListView;
    private args: Arguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Mutation filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("create-new-mutation", () => {
            showMutationDetails({
                mutationId: null,
                reloadCallback: this.listView.reload,
            });
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.export_args,
            });
        });

        // links to detail popups
        this.listView.onCellClick("td.name.clickable", (args) => {
            const mutationId = parseInt(args.rowId, 10);
            if (mutationId) {
                showMutationDetails({
                    mutationId: mutationId,
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                    reloadCallback: () => this.listView.reload({ flashRowId: args.rowId }),
                });
                this.listView.highlightRow(args.rowId);
            }
        });

        this.listView.onCellClick("td.official_name.clickable", (args) => {
            const mutationId = parseInt(args.rowId, 10);
            if (mutationId) {
                showMutationDetails({
                    mutationId: mutationId,
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                    reloadCallback: () => this.listView.reload({ flashRowId: args.rowId }),
                });
                this.listView.highlightRow(args.rowId);
            }
        });

        this.listView.onCellClick("td.action .mutation-deactivate", (args) => {
            MutationsService.deactivateMutation({
                mutationId: parseInt(args.rowId, 10),
            })
                .then(() => notifications.showNotification(getTranslation("Mutation updated"), "success"))
                .catch(() =>
                    notifications.showNotification(
                        getTranslation("Action failed. The data could not be saved. Please try again."),
                        "error",
                    ),
                )
                .finally(() => this.listView.reload());
        });

        this.listView.onCellClick("td.action .mutation-activate", (args) => {
            MutationsService.activateMutation({
                mutationId: parseInt(args.rowId, 10),
            })
                .then(() => notifications.showNotification(getTranslation("Mutation updated"), "success"))
                .catch(() =>
                    notifications.showNotification(
                        getTranslation("Action failed. The data could not be saved. Please try again."),
                        "error",
                    ),
                )
                .finally(() => this.listView.reload());
        });

        this.listView.onCellClick("td.associated_strains a", (args) => {
            openListDetailPopup(
                getUrl(cgiScript("edit_strain.py"), {
                    strainid: args.element.dataset.strainId,
                }),
            );
        });
    }
}

export const initMutationList = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
