import { Observable } from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    getUrl,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./severityAssessmentReportListFilter.html";



interface Arguments extends ListViewArguments {
    export_args: any;
    filter_only: boolean;
}


const SeverityAssessmentReportListFilters = () => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {

        strain_id: class extends ListFilterItem {
            private value: CheckExtended<Observable<number>>;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        // because of speed issues,
                        // one or more strains must be selected in the filter
                        // to start the report (it cannot be 'all')
                        return !v;
                    },
                });
                this.text = _.map(seed.possible_values, "name_with_id");
            }
        },

        nothing_abnormal_detected: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },
    };
};


class SeverityAssessmentReportList {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
            { useColumnSelector: !args.filter_only },
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            this.showFilter(args);
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        if (!args.filter_only) {
            this.listView.onMenuBoxClick("export-to-excel", () => {
                showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
            });

            this.listView.onMenuBoxClick("print-button", () => {
                printUrl(getUrl(window.location.href, { show_print: "true" }));
            });
        }

        // Table Body

        const noDataText = listViewElement.querySelector(".list-no-data");
        if (noDataText) {
            noDataText.classList.add("clickable");
            noDataText.addEventListener("click", () => {
                this.showFilter(args);
            });
        }
    }

    public showFilter = (args: Arguments) => {
        showListFilter({
            viewName: args.view_name,
            filterModels: SeverityAssessmentReportListFilters,
            filterTemplate: filterTemplate,
            title: getTranslation("Report filter"),
        });
    };
}

export const initSeverityAssessmentReportList = (args: Arguments): void => {
    new SeverityAssessmentReportList(document.querySelector("div.listview"), args);
};
