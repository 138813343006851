/**
 * Show a pop-up to set licenses for given subjects.

 * @param subjects: Object with database ID(s) of animals, pups and/or tanks.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed.
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not.
 */

import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";

import {
    LicenseClassificationOption,
    LicenseOption,
    LicensesService,
} from "../backend/v1";
import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { setSessionItem } from "../lib/browserStorage";
import {
    TranslationTemplates,
    getTranslation,
} from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    cgiScript,
    getFormData,
    getUrl,
    AjaxResponse,
    getFormattedCurrentDate,
    isDateLowerThanDate,
    isInvalidCalendarDate,
} from "../lib/utils";

import template from "./setLicense.html";


interface License {
    id: number;
    name: string;
}

interface Classification {
    id: number;
    name: string;
    licence_id: number;
    severity_level_id: number;
}

interface SeverityLevel {
    id: number;
    label: string;
    severity: number;
    available: number;
}

interface SeverityLevelOption {
    id: number;
    name: string;
    isDelimiter?: boolean;
}

interface Subject {
    id: number;
    subject: "animal_id" | "pup_id" | "tank_id";
    sacrificed: boolean;
}

interface SubjectSummary {
    caption: string;
    labels: string[];
}

interface Seed {
    allow_add_license_assignment: boolean;
    allow_delete_license_assignment: boolean;
    allow_set_severity_level: boolean;
    common_species_id: number | null;
    license_assignments_by_date: string;
    severity_levels: string;
    strain_ids: number[];
    subject_list: Subject[];
    subject_summary: SubjectSummary[];
}

interface Task {
    action: string;
    assign_date: string;
    position: number;
    classification_id: number;
    severity_level_id: number | null;
}

interface Params {
    subjects: {
        animal_ids?: number[];
        pup_ids?: number[];
        tank_ids?: number[];
    };
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

interface LicenseAssignmentParams {
    common: boolean;
    assignDate: string;
    position?: number;
    maxCount?: number;
    minCount?: number;
    severityLevelId?: number;
    isInitial?: boolean;
    license?: License;
    classification?: Classification;
    severityLevels?: SeverityLevel[];
}

interface LicenseAssignmentDateGroupItem {
    assign_date: string;
    assignments: {
        common: boolean;
        max_count: number;
        min_count: number;
        position: number;
        severity_level_id: number;
        licence_id: number;
        licence_number: string;
        classification_id: number;
        classification_name: string;
        classification_severity_level_id: number;
        classification_description: string;
    }[];
}

interface LicenseAssignmentDateGroupParams {
    assignDate: string;
}

/**
 * Get available severity level options grouped by status (availability)
 * @param classificationValue: Severity level id of the classification, will get null as id and "*" appended to name
 * @param currentValue: Id of currently selected severity level (added to result even if marked as "unavailable")
 * @param severityLevels: Array of severity level options (for use in drop-down fields)
 */
function groupSeverityLevelsOptions(classificationValue: number,
    currentValue: number,
    severityLevels: SeverityLevel[]): SeverityLevelOption[] {
    const activeLevels: SeverityLevelOption[] = [];
    const inactiveLevels: SeverityLevelOption[] = [];
    const result: SeverityLevelOption[] = [];

    severityLevels.forEach((item) => {
        const option: SeverityLevelOption = { id: item.id, name: item.label };

        if (!item.available && item.severity) {
            option.name += " (" + item.severity + ")";
        }

        if (item.id === classificationValue) {
            if (session.pyratConf.SEVERITY_LEVEL_USE_CLASSIFICATION_DEFAULT) {
                option.id = 0;  // the classification default will be posted as null
            }
            option.name += " *";
        }

        if (item.available) {
            activeLevels.push(option);
        } else if (item.id === currentValue || item.id === classificationValue) {
            inactiveLevels.push(option);  // show inactive levels when currently pre-selected or part of classification
        }
    });

    if (!session.pyratConf.SEVERITY_LEVEL_USE_CLASSIFICATION_DEFAULT) {
        result.push({ "id": 0, "name": getTranslation("None") });
    }

    if (activeLevels) {
        result.push(...activeLevels);
    }

    if (activeLevels.length && inactiveLevels.length) {
        result.push({ id: undefined, name: "---", isDelimiter: true });
    }

    if (inactiveLevels) {
        result.push(...inactiveLevels);
    }

    return result;
}

/**
 * Class for licenses that were assigned at the same date
 */
class LicenseAssignmentDateGroup {
    public readonly assignDate: string;
    public readonly assignments: ObservableArray<LicenseAssignment>;  // all assignments grouped by date, sorted by position (ASC)
    public readonly maxSwitchPos: PureComputed<number>;
    public readonly minSwitchPos: PureComputed<number>;
    public readonly maxAssignmentsLen: PureComputed<number>;

    constructor(params: LicenseAssignmentDateGroupParams) {

        this.assignDate = params.assignDate;
        this.assignments = ko.observableArray().extend({ rateLimit: 50 });

        /**
         * Calculate maximal amount of assignments an animal has at assignDate
         * (by examining all placeholders for uncommon assignments)
         */
        this.maxAssignmentsLen = ko.pureComputed(() => {
            return this.assignments()
                .map(item => { return (!item.common && item.maxCount) ? item.maxCount : 1; })
                .reduce((total, currentValue) => total + currentValue, 0);
        }).extend({ rateLimit: 50 });

        /**
         * Lowest position where position changing is allowed
         * (depends on license assignments being common amongst all selected animals)
         */
        this.minSwitchPos = ko.pureComputed(() => {
            const positions = this.assignments()
                .map((item) => { if (item.common) return item.position(); })
                .filter(Number);

            if (positions.length) {
                return positions[0];
            }
            return this.maxAssignmentsLen() + 1;
        });

        /**
         * Highest position where position changing is allowed
         */
        this.maxSwitchPos = ko.pureComputed(() => {
            const assignments = this.assignments();
            if (assignments.length && assignments[assignments.length - 1].common) {
                return assignments[assignments.length - 1].position();
            }
            return this.maxAssignmentsLen() + 1;
        });
    }
}

/**
 * Class for assignments collection all animals have in common.
 */
class LicenseAssignmentList {
    public readonly assignmentDates: ObservableArray<LicenseAssignmentDateGroup>;
    public readonly assignmentDatesDict: PureComputed<{[key: string]: LicenseAssignmentDateGroup}>;
    public readonly displayAssignments: PureComputed<LicenseAssignment[]>;
    public readonly hasPosition: PureComputed<boolean>;

    constructor() {
        this.assignmentDates = ko.observableArray();  // groups of licenses assigned at the same date, sorted by date descending
        this.assignmentDatesDict = ko.pureComputed(() => {
            // assignmentDates as dictionary for faster access by date
            return Object.assign({}, ...this.assignmentDates().map((item) => (
                { [item.assignDate]: item }
            )));
        });

        // assignments as flat list to display in the pop-up
        // sorted descending by assign date and position
        this.displayAssignments = ko.pureComputed(() => {
            const display: LicenseAssignment[] = [];

            this.assignmentDates().forEach((dateGroup) => {
                for (let i = dateGroup.assignments().length - 1; i >= 0; i--) {
                    display.push(dateGroup.assignments()[i]);
                }
            });

            return display;
        });

        // show or hide header for position columns
        this.hasPosition = ko.pureComputed(() => {
            ko.utils.arrayFirst(this.assignmentDates(), (dateGroup) => {
                return dateGroup.maxSwitchPos() - dateGroup.minSwitchPos() > 0;
            });
            return false;
        });
    }

    /**
     * Add new assignment to list
     */
    public addAssignment = (assignment: LicenseAssignment) => {
        let dateGroup;

        const assignmentDatesDict = this.assignmentDatesDict();
        if (!Object.prototype.hasOwnProperty.call(assignmentDatesDict, assignment.assignDate)) {
            dateGroup = new LicenseAssignmentDateGroup({ assignDate: assignment.assignDate });
        } else {
            dateGroup = assignmentDatesDict[assignment.assignDate];
        }

        assignment.assignList = this;
        assignment.assignDateGroup = dateGroup;
        if (assignment.common && !assignment.position()) {
            assignment.position(dateGroup.maxAssignmentsLen() + 1);
        }
        dateGroup.assignments.push(assignment);

        if (!Object.prototype.hasOwnProperty.call(assignmentDatesDict, assignment.assignDate)) {
            this.addDateGroup(dateGroup);
        }
    };

    /**
     * Remove assignment from UI
     */
    public removeDomElement = (element: HTMLElement) => {
        if (element.nodeType === 1) {
            element.remove();
        }
    };

    /**
     * Add empty group for assignDate(s) not in list
     */
    private addDateGroup = (newGroup: LicenseAssignmentDateGroup) => {
        let pos;

        ko.utils.arrayFirst(this.assignmentDates(), function (dateGroup, idx) {
            if (isDateLowerThanDate(dateGroup.assignDate, newGroup.assignDate)) {
                pos = idx;
                return true;
            }
        });

        if (pos === undefined) {
            this.assignmentDates.push(newGroup);
        } else {
            this.assignmentDates.splice(pos, 0, newGroup);
        }
    };
}

/**
 * Class for license assignment
 */
class LicenseAssignment {
    public assignList: LicenseAssignmentList;
    public assignDateGroup: LicenseAssignmentDateGroup;

    public readonly maxCount: number;
    public readonly minCount: number;
    public readonly assignDate: string;
    public readonly common: boolean;
    public readonly isInitial: boolean;
    public readonly allowDelete: boolean;
    public readonly license: License;
    public readonly classification: Classification;
    public readonly severityLevelOptions: SeverityLevelOption[];
    public readonly action: PureComputed<string[]>;
    public readonly actionClass: PureComputed<string>;
    public readonly position: Observable<number>;
    public readonly severityLevelId: Observable<number>;
    public readonly remove: Observable<boolean>;

    private readonly initialPosition: number;
    private readonly initialSeverityLevelId: number;

    constructor(params: LicenseAssignmentParams) {

        this.assignList = null;
        this.assignDateGroup = null;

        this.common = params.common || false;
        this.isInitial = params.isInitial || false;
        this.allowDelete = !this.isInitial || false;
        this.assignDate = params.assignDate;

        /* only for uncommon assignments */
        this.maxCount = params.maxCount || 0;
        this.minCount = params.minCount || 0;

        /* only for common assignments */
        this.initialPosition = null;
        this.initialSeverityLevelId = 0;
        this.severityLevelOptions = [];
        this.license = params.license;
        this.classification = params.classification;

        this.position = ko.observable(params.position);
        this.severityLevelId = ko.observable(0);
        this.remove = ko.observable(false);

        if (params.position !== undefined) {
            this.initialPosition = params.position;
        }

        if (params.severityLevelId && (session.pyratConf.SEVERITY_LEVEL_USE_CLASSIFICATION_DEFAULT ?
            params.severityLevelId !== this.classification.severity_level_id : true)) {
            this.initialSeverityLevelId = params.severityLevelId;
            this.severityLevelId(params.severityLevelId);
        }

        if (this.common) {
            this.severityLevelOptions = groupSeverityLevelsOptions(
                this.classification.severity_level_id, this.initialSeverityLevelId, params.severityLevels,
            );
        }

        // compute assignments action
        this.action = ko.pureComputed(() => {
            const actionList = [];

            if (this.isInitial) {
                if (this.remove()) {
                    actionList.push("delete");
                } else {
                    if (this.severityLevelId() !== this.initialSeverityLevelId) {
                        actionList.push("severity");
                    }
                    if (this.position() !== this.initialPosition) {
                        actionList.push("position");
                    }
                }
            } else {
                actionList.push("insert");
            }

            return actionList;
        });

        // helper for the UI: set action(s) as class names
        this.actionClass = ko.pureComputed(() => {
            if (this.action().includes("insert") || this.action().includes("severity") || this.action().includes("position")) {
                return "changed_row";
            } else if (this.action().includes("delete")) {
                return "deleted_row";
            }
        });

    }

    public moveUpAssignment = () => {
        const pos = this.position();

        if (pos < this.assignDateGroup.maxSwitchPos()) {
            const assignments = this.assignDateGroup.assignments();
            const idx = assignments.indexOf(this);

            this.position(pos + 1);
            assignments[idx + 1].position(pos);

            assignments[idx] = assignments[idx + 1];
            assignments[idx + 1] = this;
            this.assignDateGroup.assignments.valueHasMutated();
        }
    };

    public moveDownAssignment = () => {
        const pos = this.position();

        if (pos > this.assignDateGroup.minSwitchPos()) {
            const assignments = this.assignDateGroup.assignments();
            const idx = assignments.indexOf(this);

            this.position(pos - 1);
            assignments[idx - 1].position(pos);

            assignments[idx] = assignments[idx - 1];
            assignments[idx - 1] = this;
            this.assignDateGroup.assignments.valueHasMutated();
        }
    };

    /**
     * Delete license assignment
     * (=> Existing assignments can be reassigned and therefore are not removed from DOM (strikeout row),
     *     whereas new added assignments will be removed in general.)
     */
    public deleteAssignment = () => {
        if (this.isInitial) {
            this.remove(true);
        } else {
            const dateGroup = this.assignDateGroup;
            const idx = dateGroup.assignments().indexOf(this);

            dateGroup.assignments.splice(idx, 1);
            if (dateGroup.assignments().length) {
                // give new position numbers to the subsequent assignments
                for (let i = idx; i < dateGroup.assignments().length; i++) {
                    const assignment = dateGroup.assignments()[i];
                    if (assignment.common) {
                        assignment.position(assignment.position() - 1);
                    }
                }
            } else {
                this.assignList.assignmentDates.remove(this.assignDateGroup);  // remove empty date group
            }
        }
    };

    /**
     * Reactivate license assignment
     * (=> Available only for existing assignments (strikeout row))
     */
    public reactivateAssignment = () => {
        if (this.isInitial) {
            this.remove(false);
        }
    };
}

/**
 * Model for new assignment, that the user can add to list of assignments
 */
class NewLicenseAssignment {
    public availableLicenses: FetchBackendExtended<ObservableArray<LicenseOption>>;
    public availableClassifications: FetchBackendExtended<ObservableArray<LicenseClassificationOption>>;
    public availableSeverityLevels: PureComputed<SeverityLevelOption[]>;

    public readonly newLicenseId: CheckExtended<Observable<number>>;
    public readonly newClassificationId: CheckExtended<Observable<number>>;
    public readonly newAssignDate: CheckExtended<Observable<string>>;
    public readonly newSeverityLevelId: Observable<number>;
    public readonly error: PureComputed<string | undefined>;
    public readonly valid: PureComputed<boolean>;

    private readonly licenseAssignmentList: LicenseAssignmentList;
    private readonly severityLevels: SeverityLevel[];
    private readonly newLicense: PureComputed<License>;
    private readonly newClassification: PureComputed<Classification>;

    constructor(
        speciesId: number | null,
        strainIds: number[],
        severityLevels: SeverityLevel[],
        licenseAssignmentList: LicenseAssignmentList,
    ) {
        this.licenseAssignmentList = licenseAssignmentList;
        this.severityLevels = severityLevels;

        this.availableLicenses = ko.observableArray().extend({
            fetchBackend: () => LicensesService.getLicenseOptions({
                speciesId: speciesId,
                strainId: strainIds,
                addDelimiter: true,
            }),
        });

        this.newLicenseId = ko.observable().extend({
            invalid: (v) => {
                return !v && getTranslation("No license selected");
            },
        });

        /* License object computed based on selected license id */
        this.newLicense = ko.pureComputed(() => {
            if (this.availableLicenses()?.length) {
                return this.availableLicenses().filter(({ id }) => id === this.newLicenseId())[0];
            }
        });

        this.availableClassifications = ko.observableArray().extend({
            fetchBackend: () => {
                if (speciesId && this.newLicenseId()) {
                    return LicensesService.getLicenseClassificationOptions({
                        licenseId: this.newLicenseId(),
                        speciesId: speciesId,
                        strainId: strainIds,
                        addDelimiter: true,
                    });
                }
            },
        });

        this.newClassificationId = ko.observable().extend({
            invalid: (v) => {
                return !v && getTranslation("No license classification selected");
            },
        });

        /* Classification object computed based on selected classification id */
        this.newClassification = ko.pureComputed(() => {
            if (this.availableClassifications()?.length) {
                return this.availableClassifications().filter(({ id }) => id === this.newClassificationId())[0];
            }
        });

        this.newAssignDate = ko.observable(getFormattedCurrentDate()).extend({
            invalid: function (v) {
                if (v && isInvalidCalendarDate(v)) {
                    return getTranslation("Invalid date");
                } else if (v && isDateLowerThanDate(getFormattedCurrentDate(), v)){
                    return getTranslation("Can't assign license in the future");
                }

                return false;
            },
        });

        this.availableSeverityLevels = ko.pureComputed(() => {
            return groupSeverityLevelsOptions(
                this.newClassification()?.severity_level_id, this.newClassification()?.severity_level_id,
                severityLevels);
        });

        /* set selected severity level to the severity level of the classification (it has id = 0) */
        this.availableSeverityLevels.subscribe(() => {
            if (session.pyratConf.SEVERITY_LEVEL_USE_CLASSIFICATION_DEFAULT) {
                setTimeout(() => {this.newSeverityLevelId(0); }, 0);
            }
        });

        this.newSeverityLevelId = ko.observable();

        this.error = ko.pureComputed(() => {
            return this.newLicenseId.errorMessage()
                || this.newClassificationId.errorMessage()
                || this.newAssignDate.errorMessage();
        });

        this.valid = ko.pureComputed(() => {
            return !(this.newLicenseId.isInvalid()
                || this.newClassificationId.isInvalid()
                || this.newAssignDate.isInvalid());
        });
    }

    /**
     * Available licenses are splitted in two sections, normal and overused license
     * which is graphically represented by the "delimiter" option contained in available licenses
     */
    public renderDisabledOptions = (option: HTMLOptionElement, item: {id: string; name: string; disabled: boolean}) => {
        option.disabled = Boolean(item && item.disabled);

        if (option.disabled) {
            option.classList.add("delimiter");
        } else {
            option.classList.remove("delimiter");
        }
    };

    /**
     * Add new assignment to list of assignments
     */
    public addAssignment = () => {
        this.licenseAssignmentList.addAssignment(new LicenseAssignment({
            common: true,
            assignDate: this.newAssignDate(),
            license: this.newLicense(),
            severityLevels: this.severityLevels,
            classification: this.newClassification(),
            severityLevelId: this.newSeverityLevelId(),
        }));
    };

    /**
     * Add new assignment and delete the current license
     */
    public replaceCurrentLicenseAssignment = () => {
        if (this.licenseAssignmentList.displayAssignments().length > 0) {
            // delete the first in list = the current license assignment
            this.licenseAssignmentList.displayAssignments()[0].deleteAssignment();
        }
        this.addAssignment();
    };

}

class ViewModel {
    public licenseDenom: string;
    public allowLicenseAdd: boolean;
    public allowLicenseDelete: boolean;
    public allowSetSeverityLevel: boolean;
    public newAssignment: NewLicenseAssignment;
    public replaceCurrentLicenseEnabled: Observable<boolean>;
    public replaceCurrentLicenseChecked: Observable<boolean>;
    public subjectSummaryList: SubjectSummary[];
    public readonly dialog: KnockoutPopup;
    public readonly assignments: ObservableArray<{rateLimit: number}>;  // Array of all assignments made at this date, sorted ascending by position
    public readonly error: PureComputed<string>;
    public readonly submitButtonValue: PureComputed<string>;
    public readonly submitButtonEnabled: PureComputed<boolean>;
    public readonly assignmentList: LicenseAssignmentList;
    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;

    private subjectList: Subject[];
    private severityLevels: SeverityLevel[];
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;
    private readonly reloadRequired: Observable<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly licenceOveruseConfirmRequested: Observable<boolean>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.licenseDenom = TranslationTemplates.license({ cap: true, trans: true });
        this.newAssignment = null;
        this.replaceCurrentLicenseEnabled = ko.observable(false);
        this.replaceCurrentLicenseChecked = ko.observable(false);
        this.allowLicenseAdd = false;
        this.allowLicenseDelete = false;
        this.allowSetSeverityLevel = false;
        this.subjectList = [];
        this.severityLevels = [];
        this.assignmentList = new LicenseAssignmentList();
        this.reloadRequired = ko.observable(false);
        this.submitInProgress = ko.observable(false);
        this.licenceOveruseConfirmRequested = ko.observable(false);  // requested confirmation of licence overuse?

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl(cgiScript("set_license.py"), {
                    action: "get_license_dialog_options",
                    subjects: JSON.stringify({
                        animal_id: params.subjects.animal_ids || undefined,
                        pup_id: params.subjects.pup_ids || undefined,
                        tank_id: params.subjects.tank_ids || undefined,
                    }),
                }), { signal });
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.allowLicenseAdd = seed.allow_add_license_assignment;
                this.allowLicenseDelete = seed.allow_delete_license_assignment;
                this.allowSetSeverityLevel = seed.allow_set_severity_level;
                this.severityLevels = JSON.parse(seed.severity_levels);
                this.subjectList = seed.subject_list;
                this.subjectSummaryList = seed.subject_summary;

                this.newAssignment = new NewLicenseAssignment(
                    seed.common_species_id,
                    seed.strain_ids,
                    this.severityLevels,
                    this.assignmentList,
                );

                const licenseAssignmentsByDate: LicenseAssignmentDateGroupItem[] = JSON.parse(seed.license_assignments_by_date);
                let allCommon = true;
                if (Array.isArray(licenseAssignmentsByDate)) {
                    licenseAssignmentsByDate.forEach((assignDateGroup) => {
                        assignDateGroup.assignments.forEach((item) => {
                            if (item.common === true) {
                                this.assignmentList.addAssignment(new LicenseAssignment({
                                    common: true,
                                    isInitial: true,
                                    position: item.position,
                                    assignDate: assignDateGroup.assign_date,
                                    severityLevelId: item.severity_level_id,
                                    severityLevels: this.severityLevels,
                                    license: {
                                        id: item.licence_id,
                                        name: item.licence_number,
                                    },
                                    classification: {
                                        id: item.classification_id,
                                        name: item.classification_name,
                                        licence_id: item.licence_id,
                                        severity_level_id: item.classification_severity_level_id,
                                    },
                                }));
                            } else {
                                this.assignmentList.addAssignment(new LicenseAssignment({
                                    common: false,
                                    isInitial: true,
                                    maxCount: item.max_count,
                                    minCount: item.min_count,
                                    assignDate: assignDateGroup.assign_date,
                                }));
                                allCommon = false;
                            }
                        });
                    });

                    if (licenseAssignmentsByDate.length > 0 && allCommon && this.allowLicenseDelete) {
                        this.replaceCurrentLicenseEnabled(true);
                        this.replaceCurrentLicenseChecked(session.pyratConf.LICENSE_ASSIGN_PRESELECT_REPLACE_EXISTING);
                    }
                }
                dialog.reposition();
            }
        });

        this.error = ko.pureComputed(() => {
            if (this.newAssignment) {
                return this.newAssignment.error();
            }
        });

        /* Change value of submit button depending on requested confirmation */
        this.submitButtonValue = ko.pureComputed(() => {
            return this.licenceOveruseConfirmRequested()
                ? getTranslation("Apply and confirm overuse")
                : getTranslation("Apply");
        });

        this.submitButtonEnabled = ko.pureComputed(() => {
            return !this.submitInProgress()
                && this.getFormData().get("tasks").toString().length > 0;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadRequired() && typeof this.reloadCallback === "function") {
                this.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.reloadRequired(true);
        this.submitInProgress(true);

        fetch(cgiScript("set_license.py"), { method: "POST", body: this.getFormData() })
            .then(response => response.json())
            .then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success == true) {
                    setSessionItem(this.getSessionKey(this.subjectList), "historydiv")
                        .then(() => {
                            this.dialog.close();
                            notifications.showNotification(response.message, "success");
                        });
                } else {
                    if (response.confirm === "confirm_licence_overuse") {
                        this.licenceOveruseConfirmRequested(true);
                    }
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error",
                );
            });
    };

    /**
     * Get session item key for given animalId
     */
    private getSessionKey = (subjects: Subject[]) => {
        if (subjects.find(({ subject }) => subject === "animal_id" || subject === "pup_id")) {
            return "animal_detail.id_" + subjects[0].id + ".tab";
        }
    };

    private getSubjectsByType = (subjects: Subject[]) => {
        const subjectsByType: any = {};

        subjects.forEach((item) => {
            if (!Object.prototype.hasOwnProperty.call(subjectsByType, item.subject)) {
                subjectsByType[item.subject] = [item.id];
            } else {
                subjectsByType[item.subject].push(item.id);
            }
        });

        return subjectsByType;
    };

    private getFormData = () => {

        const tasks = [];
        const liveTasks: Task[] = [];
        const sacrificedTasks: Task[] = [];
        const sacrificedAnimals = this.subjectList.filter((subject: Subject) => { return subject.sacrificed; });
        const liveAnimalsAndOthers = this.subjectList.filter((subject: Subject) => {
            // handle tanks like live animals (license assignments count only for live animals in tanks)
            return subject.subject === "tank_id" || !subject.sacrificed;
        });

        this.assignmentList.displayAssignments()
            .filter(({ common }) => common === true)
            .forEach((assignment) => {
                const severityLevelId = assignment.severityLevelId();
                assignment.action().forEach((action) => {
                    liveTasks.push({
                        action: action,
                        assign_date: assignment.assignDate,
                        position: assignment.position(),
                        classification_id: assignment.classification.id,
                        severity_level_id: severityLevelId || null,
                    });
                    /* freeze severity levels for sacrificed animals */
                    sacrificedTasks.push({
                        action: action,
                        assign_date: assignment.assignDate,
                        position: assignment.position(),
                        classification_id: assignment.classification.id,
                        severity_level_id:
                            (session.pyratConf.SEVERITY_LEVEL_USE_CLASSIFICATION_DEFAULT) ?
                                (severityLevelId || assignment.classification.severity_level_id || null) :
                                (severityLevelId || null),
                    });
                });
            });

        if (liveTasks.length && liveAnimalsAndOthers.length) {
            tasks.push({ tasks: liveTasks, subjects: this.getSubjectsByType(liveAnimalsAndOthers) });
        }

        if (sacrificedTasks.length && sacrificedAnimals.length) {
            tasks.push({ tasks: sacrificedTasks, subjects: this.getSubjectsByType(sacrificedAnimals) });
        }

        return getFormData({
            tasks: JSON.stringify(tasks),
            confirmed_licence_overuse: this.licenceOveruseConfirmRequested() ? "1" : "0",
        });
    };
}

export const showSetLicense = dialogStarter(ViewModel, template, {
    name: "SetLicense",
    width: 700,
    escalate: false,
    closeOthers: true,
    title: getTranslation("Set license"),
});
