import * as ko from "knockout";
import {
    Computed,
    Observable,
} from "knockout";
import * as _ from "lodash";

import { AnimalsService } from "../backend/v1";
import {
    LocationItem,
    PreselectLocationItem,
} from "../knockout/components/locationPicker/locationPicker";
import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { KnockoutPopup } from "../lib/popups";
import {
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    cgiScript,
    getFormData,
    AjaxResponse,
    ConfirmableAjaxResponse,
    isInvalidEartagPrefix,
    isInvalidEartagSuffix,
} from "../lib/utils";

import template from "./litterActions.html";


interface Params {
    birthId: number;
    show: "wean" | "assign_eartags";
    title: string;
    eventTarget?: HTMLElement;
}

interface Seed {
    cage_id: number;
    show_assign_eartags: boolean;
    prefixes: string[];
    wean_prefix: string;
    next_suffix: string;
    show_wean_action: boolean;
    show_warn_too_young_message: boolean;
    pups_alive_with_eartag: string[];
    pups_alive_without_eartag: string[];
    percage_setting: number;
    location_selectable: boolean;
    parent_rack_id: number;
    cage_categories: {
        id: number;
        name: string;
    }[];
    number_of_males: number;
    number_of_females: number;
}

interface SubmitData {
    action?: string;
    bid: number;
    cageid: number;
    wean_cage_fillmethod?: string;
    males_percage?: number;
    females_percage?: number;
    cage_category_id?: number;
    rack_id?: number;
    cage_position?: string;
    confirm_sanitary_status?: number;
    assign_tags?: number;
    prefix?: string;
    next_suffix?: string;
}

class LitterActionsViewModel {

    private readonly dialog: KnockoutPopup;

    // params
    public birthId: number;
    public show: "wean" | "assign_eartags";

    // state
    public continueTooYoung: Observable<boolean>;
    public assignEartagsVisible: Computed<boolean>;
    public weanVisible: Computed<boolean>;

    public cageId: Observable<number>;
    public pupsWithEartags: Observable<string[]>;
    public pupsWithoutEartags: Observable<string[]>;
    public prefixSelect: Observable<string>;
    public prefix: CheckExtended<Observable<string>>;
    public suffix: CheckExtended<FetchBackendExtended<Observable<string>>>;
    public weanCageFillMethod: Observable<string>;
    public malesPerCage: Observable<number>;
    public femalesPerCage: Observable<number>;
    public numberOfMales: Observable<number>;
    public numberOfFemales: Observable<number>;
    public malesDistribution: Computed<string>;
    public femalesDistribution: Computed<string>;
    public cageCategoryId: CheckExtended<Observable<number>>;

    public locationInputVisible: Observable<boolean>;
    public preselectLocation: Observable<PreselectLocationItem>;
    public selectedLocationTitle: Observable<string>;
    public selectedLocation: Observable<LocationItem>;
    public cagePosition: Observable<string>;
    public confirmSanitaryStatus: Observable<number>;

    public suffixError: Observable<string>;
    public submitError: Observable<string>;
    public submitInProgress: Observable<boolean>;
    public seed: FetchExtended<Observable<AjaxResponse<Seed>>>;

    constructor({ birthId, show }: Params,
        dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.birthId = birthId;
        this.show = show;

        this.continueTooYoung = ko.observable(false);

        this.cageId = ko.observable();
        this.pupsWithEartags = ko.observable();
        this.pupsWithoutEartags = ko.observable();

        this.assignEartagsVisible = ko.pureComputed(() => {
            return !!(this.show === "assign_eartags" || this.pupsWithoutEartags());
        });

        this.weanVisible = ko.pureComputed(() => {
            return (this.show === "wean");
        });

        this.prefixSelect = ko.observable();
        this.prefix = ko.observable().extend({
            invalid: (v) => {
                if (this.assignEartagsVisible()) {
                    return !v || isInvalidEartagPrefix(v);
                }
                return false;
            },
        });

        // copy selected prefix from dropdown into input field
        this.prefixSelect.subscribe((v) => {
            this.prefix(v);
        });

        // load next free suffix depending on the selected prefix
        this.suffixError = ko.observable();
        this.suffix = ko.observable().extend({
            fetchBackend: () => {
                this.suffixError("");
                if (this.assignEartagsVisible() && this.prefix.isValid()) {
                    return AnimalsService.getDefaultWeanSuffix({ eartagPrefix: this.prefix() });
                }
            },
            invalid: (v) => {
                if (this.assignEartagsVisible()) {
                    return !v || isInvalidEartagSuffix(v);
                }
                return false;
            },
        });
        this.suffix.onCatch = (error) => { this.suffixError(error?.body?.detail); };


        this.weanCageFillMethod = ko.observable("wean_cage_fillevenly");
        this.malesPerCage = ko.observable();
        this.femalesPerCage = ko.observable();
        this.numberOfMales = ko.observable();
        this.numberOfFemales = ko.observable();

        this.malesDistribution = ko.computed(() => {
            const distrib = this.computeAnimalDistribution(this.numberOfMales(),
                this.malesPerCage(),
                (this.weanCageFillMethod() === "wean_cage_fillcompletely"));
            if (distrib.length) {
                return _.join(distrib, " / ");
            }
            return "-";
        });

        this.femalesDistribution = ko.computed(() => {
            const distrib = this.computeAnimalDistribution(this.numberOfFemales(),
                this.femalesPerCage(),
                (this.weanCageFillMethod() === "wean_cage_fillcompletely"));
            if (distrib.length) {
                return _.join(distrib, " / ");
            }
            return "-";
        });

        this.cageCategoryId = ko.observable().extend({
            invalid: (v) => {
                if (this.weanVisible()) {
                    return !v;
                }
                return false;
            },
        });

        this.locationInputVisible = ko.observable();
        this.preselectLocation = ko.observable();
        this.selectedLocationTitle = ko.observable();
        this.selectedLocation = ko.observable();
        this.cagePosition = ko.observable();
        this.confirmSanitaryStatus = ko.observable(0);

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(cgiScript("litter_action.py"), {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify({
                            action: "get_dialog_options",
                            birth_id: this.birthId,
                            show: this.show,
                        }),
                    }),
                    signal,
                });
            },
        });

        this.seed.subscribe((response) => {
            if (response && response.success) {
                this.prefixSelect(response.wean_prefix);
                this.prefix(response.wean_prefix);
                this.suffix(response.next_suffix);

                this.femalesPerCage(response.percage_setting);
                this.malesPerCage(response.percage_setting);
                this.numberOfFemales(response.number_of_females);
                this.numberOfMales(response.number_of_males);

                this.cageId(response.cage_id);
                if (response.pups_alive_with_eartag?.length) {
                    this.pupsWithEartags(response.pups_alive_with_eartag);
                }
                if (response.pups_alive_without_eartag?.length) {
                    this.pupsWithoutEartags(response.pups_alive_without_eartag);
                }

                this.locationInputVisible(response.location_selectable);
                if (response.parent_rack_id) {
                    this.preselectLocation({ type: "rack", id: response.parent_rack_id });
                }
            }
        });

        // internals
        this.submitError = ko.observable();
        this.submitInProgress = ko.observable(false);
    }

    /**
     * Compute how the weaned animals will be distributed to the new cages.
     *
     * Rules:
     * If parameter cageFillCompletely is True, the cages are filled completely if possible.
     * Otherwise the animals are distributed as evenly as possible.
     */
    private computeAnimalDistribution = (animalCount: number, percage: number, cageFillCompletely: boolean) => {
        const result: any[] = [];
        let remainingAnimals;
        let i;
        if (animalCount < 1 || percage < 1) {
            return result;
        }
        const cageCount = Math.floor(animalCount / percage) + (animalCount % percage ? 1 : 0);
        if (cageFillCompletely) {
            remainingAnimals = animalCount - (cageCount - 1) * percage;
            for (i = 0; i < cageCount - 1; i++) {
                result.push(percage);
            }
            result.push(remainingAnimals);
        } else {
            const minPercage = Math.floor(animalCount / cageCount);
            remainingAnimals = animalCount % cageCount;
            for (i = 0; i < cageCount; i++) {
                result.push(minPercage + (remainingAnimals ? 1 : 0));
                if (remainingAnimals) {
                    remainingAnimals -= 1;
                }
            }
        }
        return result;
    };

    public cancel = () => {
        this.dialog.close();
    };

    public canSubmit = ko.pureComputed(() => {
        return !(this.seed.inProgress() ||
            this.submitInProgress() ||
            this.prefix.isInvalid() ||
            this.suffix.isInvalid() ||
            this.cageCategoryId.isInvalid());
    });

    private getRequestData = () => {
        const requestData: SubmitData = {
            bid: this.birthId,
            cageid: this.cageId(),
        };

        if (this.weanVisible()) {
            requestData["action"] = "wean";
            requestData["wean_cage_fillmethod"] = this.weanCageFillMethod();
            requestData["males_percage"] = this.malesPerCage();
            requestData["females_percage"] = this.femalesPerCage();
            requestData["cage_category_id"] = this.cageCategoryId();

            if (this.locationInputVisible()) {
                requestData["rack_id"] = this.selectedLocation() ? this.selectedLocation().rack_id : undefined;
                requestData["cage_position"] = this.cagePosition();
                requestData["confirm_sanitary_status"] = this.confirmSanitaryStatus();
            }

            if (this.assignEartagsVisible()) {
                requestData["assign_tags"] = 1;
                requestData["prefix"] = this.prefix();
                requestData["next_suffix"] = this.suffix();
            }

        } else if (this.assignEartagsVisible()) {
            requestData["action"] = "assign_eartags";
            requestData["prefix"] = this.prefix();
            requestData["next_suffix"] = this.suffix();
        }

        return requestData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.submitError("");

        const form = getFormData({ request: JSON.stringify(this.getRequestData()) });
        fetch(cgiScript("litter_action.py"), { method: "POST", body: form })
            .then(response => response.json())
            .then((response: ConfirmableAjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success)  {
                    if (response.where === "animal") {
                        const filterParams: {eartag: string; wr_class_id_after_weaning?: number} = {
                            eartag: response.eartags.join(","),
                        };

                        if (response.wr_class_id_after_weaning) {
                            // open new work request after weaning
                            filterParams["wr_class_id_after_weaning"] = response.wr_class_id_after_weaning;
                        }
                        mainMenu.openAndResetListFilter("get_animal_list", filterParams);
                        this.dialog.close();
                    } else if (response.where === "pup") {
                        mainMenu.openAndResetListFilter("get_pup_list", { eartag: response.eartags.join(",") });
                        this.dialog.close();
                    } else {
                        document.location.reload();
                    }
                } else if (response.confirm && response.confirm === "confirm_sanitary_status") {
                    notifications.showConfirm(
                        response.message,
                        () => {
                            this.confirmSanitaryStatus(1);
                            // post again, this time with confirmed sanitary status
                            this.submit();
                        },
                        {
                            onCancel: () => {
                                this.submitInProgress(false);
                            },
                        },
                    );
                } else {
                    // validation errors
                    this.submitInProgress(false);
                    this.submitError(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.dialog.close();
            });
    };
}

export const showLitterActions = dialogStarter(LitterActionsViewModel, template, params => ({
    name: "LitterActions",
    width: 400,
    handle: "right top",
    anchor: params.eventTarget,
    escalate: false,
    title: params.title,
    closeOthers: true,
}));
