/**
 * Show a pop-up to export a microwellplate.
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import {
    observable,
    Observable,
    observableArray,
    pureComputed,
} from "knockout";

import {
    WellplateExportFormat,
    WellplateExportSeed,
    WellplatesService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import {
    baseUrl,
    getUrl,
    handleBackendError,
} from "../lib/utils";

import template from "./microwellPlateExport.html";

interface MicrowellPlateExportParams {
    wellplateId?: number;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

class MicrowellPlateExportViewModel {
    readonly seedInProgress = observable(true);
    readonly availableExportFormats = observableArray<WellplateExportFormat>([]);
    readonly setAsExported = observable(false);
    readonly exportFormat = observable<WellplateExportFormat>("standard_csv");
    readonly exportInProgress = observable(false);
    readonly reloadRequired = observable(false);
    readonly exportableWellplates = observableArray<
        WellplateExportSeed["exportable_wellplates"][0] & {
            selected: Observable<boolean>;
        }
    >([]);


    public readonly canExport = pureComputed(() => {
        if (!this.exportFormat()) {
            return false;
        }
        if (!this.exportableWellplates().some((w) => w.selected())) {
            return false;
        }
        return true;
    });

    constructor(readonly dialog: HtmlDialog, params: MicrowellPlateExportParams) {
        this.exportFormat.extend({ localStorage: "well_plate_export_format" });

        WellplatesService.getWellplatesExportSeed()
            .then((seed) => {
                this.availableExportFormats(seed.export_formats);
                this.exportableWellplates([]);
                for (const wellplate of seed.exportable_wellplates) {
                    this.exportableWellplates.push({
                        selected: observable(true),
                        ...wellplate,
                    });
                }
            })
            .catch(handleBackendError())
            .finally(() => this.seedInProgress(false));

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (params.closeCallback) {
                params.closeCallback();
            }
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });
    }

    public invertWellplateSelection = () => {
        this.exportableWellplates().forEach((w) => {
            w.selected(!w.selected());
        });
    };

    public runExport = () => {
        this.exportInProgress(true);
        if (this.setAsExported()) {
            this.reloadRequired(true);
        }

        const selectedWellplateIds = this.exportableWellplates()
            .filter((w) => w.selected())
            .map((w) => w.id);

        if (this.exportFormat() === "standard_csv") {
            window.open(
                getUrl(baseUrl("frontend/wellplates/export_as_standard_csv"), {
                    wellplate_id: selectedWellplateIds,
                    set_exported: this.setAsExported() ? "true" : "false",
                }),
            );
        } else if (this.exportFormat() === "transnetyx_xlsx") {
            window.open(
                getUrl(baseUrl("frontend/wellplates/export_as_transnetyx_xlsx"), {
                    wellplate_id: selectedWellplateIds,
                    set_exported: this.setAsExported() ? "true" : "false",
                }),
            );
        } else {
            throw new Error("Unknown export format");
        }
        this.exportInProgress(false);
        this.dialog.close();
    };
}

export const showMicrowellPlateExport = htmlDialogStarter(MicrowellPlateExportViewModel, template, (params) => ({
    name: "MicrowellPlateExport",
    width: 450,
    closeOthers: true,
    position: { inset: { top: 120 } },
    title: params.wellplateId ? getTranslation("Wellplate") : getTranslation("Export wellplate"),
}));
