import dialogPolyfill from "dialog-polyfill";

import { SessionService } from "../backend/v1";

import { getTranslation } from "./localize";
import { session } from "./pyratSession";
import {
    getUrl,
    escapeHtml,
    baseUrl,
} from "./utils";

// import required for Safari < 15.4 (released 2022-03, required until 2024-03)

import "./sessionPoll.scss";

export default class SessionPoll {

    private readonly minPollSeconds = 60; // seconds
    private readonly maxPollSeconds = 60 * 60 * 24 * 21; // seconds
    private readonly pollBeforeTimeIsUp = 10; // seconds
    private readonly timeGrantedOnAjaxFail = 60; // seconds
    private readonly sessionTimeout;  // seconds
    private readonly fieldset: HTMLFieldSetElement;
    private readonly passwordInput: HTMLInputElement;
    private readonly errorMsg: HTMLParagraphElement;
    private readonly dialogElement;
    private failedPolls = 0;
    private windowTimeout: number;

    constructor(sessionTimeout: number) {

        this.sessionTimeout = sessionTimeout;

        this.dialogElement = document.createElement("dialog");
        dialogPolyfill.registerDialog(this.dialogElement);

        const header: HTMLHeadingElement = document.createElement("h1");
        header.textContent = getTranslation("Session expired");
        this.dialogElement.append(header);

        const message: HTMLParagraphElement = document.createElement("p");
        message.textContent = getTranslation("Please enter your password to reactivate your session");
        this.dialogElement.append(message);

        const form = document.createElement("form");
        form.addEventListener("submit", (e) => {
            e.preventDefault();
            this.reactivate();
        });
        this.dialogElement.append(form);

        this.fieldset = document.createElement("fieldset");
        this.fieldset.classList.add("tw-grid");
        this.fieldset.classList.add("tw-grid-cols-[auto,auto]");
        this.fieldset.classList.add("tw-gap-2");
        this.fieldset.classList.add("tw-my-2");
        form.append(this.fieldset);

        const usernameLabel: HTMLLabelElement = document.createElement("label");
        usernameLabel.textContent = getTranslation("Username");
        usernameLabel.htmlFor = "session_username_input";
        this.fieldset.append(usernameLabel);

        const usernameInput: HTMLInputElement = document.createElement("input");
        usernameInput.id = "session_username_input";
        usernameInput.type = "text";
        usernameInput.value = session.userName;
        usernameInput.disabled = true;
        usernameInput.title = getTranslation("You can't reactivate a session with another username");
        this.fieldset.append(usernameInput);

        const passwordLabel: HTMLLabelElement = document.createElement("label");
        passwordLabel.textContent = getTranslation("Password");
        passwordLabel.htmlFor = "session_password_input";
        this.fieldset.append(passwordLabel);

        this.passwordInput = document.createElement("input");
        this.passwordInput.id = "session_password_input";
        this.passwordInput.type = "password";
        this.passwordInput.addEventListener("input", () => {
            submitButton.disabled = this.passwordInput.value.length < 3;
        });
        this.fieldset.append(this.passwordInput);

        const buttonRow: HTMLDivElement = document.createElement("div");
        buttonRow.classList.add("tw-col-span-2");
        buttonRow.classList.add("tw-flex");
        buttonRow.classList.add("tw-justify-between");
        buttonRow.classList.add("tw-items-center");
        this.fieldset.append(buttonRow);

        this.errorMsg = document.createElement("p");
        this.errorMsg.classList.add("tw-text-red-600");
        buttonRow.append(this.errorMsg);

        const submitButton = document.createElement("input");
        submitButton.type = "submit";
        submitButton.value = getTranslation("Reactivate session");
        submitButton.disabled = this.passwordInput.value.length < 3;

        buttonRow.append(submitButton);

        const wrongUserRow = document.createElement("div");
        wrongUserRow.classList.add("tw-text-center");
        wrongUserRow.classList.add("tw-p-2");
        this.dialogElement.append(wrongUserRow);

        const wrongUser: HTMLParagraphElement = document.createElement("p");
        wrongUser.textContent = getTranslation("You are not %(username)s?").replace("%(username)s", escapeHtml(session.userName));

        const wrongUserLink: HTMLAnchorElement = document.createElement("a");
        wrongUserLink.href = baseUrl("frontend/session/login");
        wrongUserLink.textContent = getTranslation("Back to Login");
        wrongUserLink.classList.add("tw-m-2");
        wrongUserLink.classList.add("subtle-link");
        wrongUser.append(wrongUserLink);

        wrongUserRow.append(wrongUser);

        document.body.append(this.dialogElement);

        this.setPollTimeout(this.getRepollSeconds(sessionTimeout));

    }

    private showModal = (): void => {
        this.dialogElement.showModal();
        this.fieldset.disabled = false;
        this.passwordInput.focus();
    };

    private hideModal = (): void => {
        this.dialogElement.close();
        this.fieldset.disabled = true;
    };

    private getRepollSeconds = (seconds: number) => {
        const res = seconds - this.pollBeforeTimeIsUp;
        if (res > this.maxPollSeconds) {
            return this.maxPollSeconds;
        }
        if (res < this.minPollSeconds) {
            return this.minPollSeconds;
        } else {
            return res;
        }
    };

    private poll = () => {
        // ask login.py how much time is left for the current session.
        SessionService.getSessionTime({ sessionid: session.sessionId })
            .then((sessionTimeLeft) => {
                // get_session_time response.session_time_left should be the amount
                // of time left for the session.
                this.failedPolls = 0;

                if (sessionTimeLeft > this.pollBeforeTimeIsUp) {
                    this.setPollTimeout(this.getRepollSeconds(sessionTimeLeft));
                } else  {
                    this.showModal();
                }
            })
            .catch((reason) => {
                if (reason.status === 400) {
                    // unable to open the session for reactivation
                    window.top.location.href = getUrl(baseUrl("frontend/session/logout"), {
                        session: session.sessionId,
                        error: "open_session_exception",
                    });
                } else {
                    // try to poll again, later
                    this.failedPolls++;
                    if (this.failedPolls < 4) {
                        this.setPollTimeout(this.timeGrantedOnAjaxFail);
                    }
                    // else: permanent problem: quit trying to poll
                    return false;
                }
            });

    };

    private setPollTimeout = (seconds: number) => {
        if (this.windowTimeout) {
            window.clearTimeout(this.windowTimeout);
        }
        if (seconds > 0 && seconds <= 2147483.647) {
            // Maximum value for setTimeout is limited to 24.855 days.
            // see: https://web.archive.org/web/20210310183717/https://catonmat.net/settimeout-setinterval
            this.windowTimeout = window.setTimeout(this.poll, seconds * 1000);
        }
    };

    private reactivate = () => {
        if (this.passwordInput.value) {
            this.fieldset.disabled = true;
            this.passwordInput.blur();
            this.errorMsg.textContent = "";

            // get and clear the password
            const password = this.passwordInput.value;
            this.passwordInput.value = "";

            SessionService.reactivateSession({
                sessionid: session.sessionId,
                requestBody: {
                    password: password,
                },
            })
                .then(() => {
                    this.setPollTimeout(this.getRepollSeconds(this.sessionTimeout));
                    this.hideModal();
                })
                .catch((error) => {
                    if (error.status == 403) {
                        // show the error message
                        if (typeof error?.body?.detail == "string") {
                            this.errorMsg.textContent = error.body.detail;
                            this.errorMsg.style.display = "block";
                        }
                        // the reactivation failed, let's try again
                        this.fieldset.disabled = false;
                        this.passwordInput.focus();
                    } else {
                        // unable to open the session for reactivation
                        window.top.location.href = getUrl(baseUrl("frontend/session/logout"), {
                            session: session.sessionId,
                            error: "open_session_exception",
                        });
                    }

                });
        } else {
            this.passwordInput.focus();
        }
    };
}
