import { Observable } from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import { showAnnouncementDetails } from "../../dialogs";
import {
    ListFilterItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";

import filterTemplate from "./announcementListFilter.html";

// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = () => ({
    type: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "value") as string[];
        }
    },

    status: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "value") as string[];
        }
    },
});

class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: ListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox actions
        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Announcement filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        // Custom actions
        this.listView.onMenuBoxClick("add-button", () => {
            showAnnouncementDetails({
                announcementId: null,
                reloadCallback: this.listView.reload,
            });
        });
        this.listView.onCellClick("td.type.clickable, td.status.clickable, td.title.clickable", (args) => {
            const announcementId = parseInt(args.rowId, 10);
            if (announcementId) {
                showAnnouncementDetails({
                    announcementId: announcementId,
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                    reloadCallback: () => this.listView.reload({ flashRowId: args.rowId }),
                });
                this.listView.highlightRow(args.rowId);
            }
        });
    }
}

export const initAnnouncementList = (args: ListViewArguments): void => {
    new List(document.querySelector("div.listview"), args);
};
