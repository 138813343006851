/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_setAnimalGeneration } from '../models/Body_setAnimalGeneration';
import type { GetAnimalCohortIdResponse } from '../models/GetAnimalCohortIdResponse';
import type { GetAnimalEartagResponse } from '../models/GetAnimalEartagResponse';
import type { GetAnimalGenerationResponse } from '../models/GetAnimalGenerationResponse';
import type { GetAnimalGeneticBackgroundResponse } from '../models/GetAnimalGeneticBackgroundResponse';
import type { GetAnimalLabIdResponse } from '../models/GetAnimalLabIdResponse';
import type { GetAnimalRFIDResponse } from '../models/GetAnimalRFIDResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnimalsService {

    /**
     * Get Animal Cohort Id Endpoint
     * Get the cohort id for an animal.
     * @returns GetAnimalCohortIdResponse Successful Response
     * @throws ApiError
     */
    public static getAnimalCohortId({
        animalId,
    }: {
        animalId: number,
    }): CancelablePromise<GetAnimalCohortIdResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/{animal_id}/cohort_id',
            path: {
                'animal_id': animalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Animal Cohort Id Endpoint
     * Set the cohort id for an animal.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setAnimalCohortId({
        animalId,
        cohortId,
    }: {
        animalId: number,
        cohortId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/animals/{animal_id}/cohort_id',
            path: {
                'animal_id': animalId,
            },
            query: {
                'cohort_id': cohortId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Animal Eartag Endpoint
     * Get eartag for an animal.
     * @returns GetAnimalEartagResponse Successful Response
     * @throws ApiError
     */
    public static getAnimalEartag({
        animalId,
    }: {
        animalId: number,
    }): CancelablePromise<GetAnimalEartagResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/{animal_id}/eartag',
            path: {
                'animal_id': animalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Animal Eartag Endpoint
     * Set eartag for an animal.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setAnimalEartag({
        animalId,
        prefix,
        suffix,
    }: {
        animalId: number,
        prefix: string,
        suffix: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/animals/{animal_id}/eartag',
            path: {
                'animal_id': animalId,
            },
            query: {
                'prefix': prefix,
                'suffix': suffix,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Animal Generation Endpoint
     * Get generation for an animal.
     * @returns GetAnimalGenerationResponse Successful Response
     * @throws ApiError
     */
    public static getAnimalGeneration({
        animalId,
    }: {
        animalId: number,
    }): CancelablePromise<GetAnimalGenerationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/{animal_id}/generation',
            path: {
                'animal_id': animalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Animal Generation Endpoint
     * Set generation for an animal.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setAnimalGeneration({
        animalId,
        requestBody,
    }: {
        animalId: number,
        requestBody?: Body_setAnimalGeneration,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/animals/{animal_id}/generation',
            path: {
                'animal_id': animalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Animal Genetic Background Endpoint
     * Get genetic background for an animal.
     * @returns GetAnimalGeneticBackgroundResponse Successful Response
     * @throws ApiError
     */
    public static getAnimalGeneticBackground({
        animalId,
    }: {
        animalId: number,
    }): CancelablePromise<GetAnimalGeneticBackgroundResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/{animal_id}/genetic_background',
            path: {
                'animal_id': animalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Animal Genetic Background Endpoint
     * Set genetic background for an animal.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setAnimalGeneticBackground({
        animalId,
        geneticBackgroundId,
    }: {
        animalId: number,
        geneticBackgroundId?: (number | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/animals/{animal_id}/genetic_background',
            path: {
                'animal_id': animalId,
            },
            query: {
                'genetic_background_id': geneticBackgroundId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Animal Incompatible Sanitary Status Locations Endpoint
     * Get incompatible sanitary status locations for animals based on given new cage or rack id.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getAnimalIncompatibleSanitaryStatusLocations({
        animalIds,
        newCageId,
        newRackId,
    }: {
        animalIds: Array<number>,
        newCageId?: (number | null),
        newRackId?: (number | null),
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/incompatible_sanitary_status_locations',
            query: {
                'animal_ids': animalIds,
                'new_cage_id': newCageId,
                'new_rack_id': newRackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Animal Lab Id Endpoint
     * Get lab id for an animal.
     * @returns GetAnimalLabIdResponse Successful Response
     * @throws ApiError
     */
    public static getAnimalLabId({
        animalId,
    }: {
        animalId: number,
    }): CancelablePromise<GetAnimalLabIdResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/{animal_id}/lab_id',
            path: {
                'animal_id': animalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Animal Lab Id Endpoint
     * Set lab id for an animal.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setAnimalLabId({
        animalId,
        labId,
    }: {
        animalId: number,
        labId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/animals/{animal_id}/lab_id',
            path: {
                'animal_id': animalId,
            },
            query: {
                'lab_id': labId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Animal Rfid Endpoint
     * Get RFID for an animal.
     * @returns GetAnimalRFIDResponse Successful Response
     * @throws ApiError
     */
    public static getAnimalRfId({
        animalId,
    }: {
        animalId: number,
    }): CancelablePromise<GetAnimalRFIDResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/{animal_id}/rfid',
            path: {
                'animal_id': animalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Animal Rfid Endpoint
     * Set RFID for an animal.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setAnimalRfId({
        animalId,
        rfid,
    }: {
        animalId: number,
        rfid: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/animals/{animal_id}/rfid',
            path: {
                'animal_id': animalId,
            },
            query: {
                'rfid': rfid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Next Free Eartag
     * Get next unused eartag with the given prefix
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getNextFreeEartag({
        eartagPrefix,
    }: {
        eartagPrefix: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/next_free_eartag',
            query: {
                'eartag_prefix': eartagPrefix,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Default Wean Suffix
     * Get next unused eartag with the given prefix.
     *
     * Replaces the former "ajax_wean_get_suffix.py" script.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getDefaultWeanSuffix({
        eartagPrefix,
    }: {
        eartagPrefix: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animals/default_wean_suffix',
            query: {
                'eartag_prefix': eartagPrefix,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
