import * as ko from "knockout";
import {
    observable,
    Observable,
} from "knockout";

import { TanksService } from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";

import template from "./setTankLabel.html";

interface Params {
    tankId: number;
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetTankLabelViewModel {

    private readonly dialog: HtmlDialog;

    // params
    public tankId: number;
    public closeCallback: () => void;
    public reloadCallback: () => void;

    // state
    public tankLabel: Observable<string> = ko.observable("");
    public fetchInProgress: Observable<boolean> = observable(false);
    public updateInProgress: Observable<boolean> = ko.observable(false);

    constructor(dialog: HtmlDialog, params: Params) {

        this.dialog = dialog;
        this.tankId = params.tankId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.dialog.setTitle(getTranslation("Set tank label for Tank ID %s.").replace("%s", String(this.tankId)));

        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });

        this.fetchInProgress(true);
        TanksService
            .getTankLabel({ tankId: this.tankId })
            .then((label) => this.tankLabel(label))
            .catch((reason) => writeException(reason))
            .finally(() => this.fetchInProgress(false));
    }

    public applyLabel = () => {
        this.updateInProgress(true);
        TanksService
            .updateTankLabel({
                tankId: this.tankId,
                label: this.tankLabel(),
            })
            .then(() => {
                this.dialog.close();
                // reload tank list after submit
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
                notifications.showNotification(getTranslation("Label changed"), "success");
            })
            .catch((reason) => {
                this.dialog.close();
                writeException(reason);
            })
            .finally(() => this.updateInProgress(false));
    };

}

export const showSetTankLabel = htmlDialogStarter(SetTankLabelViewModel, template, params => ({
    name: "SetTankLabel",
    width: 300,
    position: { anchor: params.eventTarget },
    closeOthers: true,
}));
