import {
    Computed,
    Subscribable,
    Observable,
    ObservableArray,
} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";

import {
    CageQuickselectActionsResponse,
    CagesService,
    ListedCage,
} from "../backend/v1";
import { NewComment } from "../knockout/components/commentWidget";
import {
    LocationItem,
    PreselectLocationItem,
} from "../knockout/components/locationPicker/locationPicker";
import { dialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { showColumnSelect } from "../lib/listView";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    frames,
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    cgiScript,
    getFormData,
    getUrl,
} from "../lib/utils";

import template from "./cageQuickselect.html";
import "/scss/quick_select.scss";

// Obtain type from backend service
type SeedType = Awaited<ReturnType<typeof CagesService.getCageQuickselectSeed>>;
type ExecuteResponseType = Awaited<ReturnType<typeof CagesService.applyCageQuickselectActions>>;

interface ContextRow extends ListedCage {
    selected: ko.Observable<boolean>;
}

interface Params {
    cageIds: number[];
    viewName: "cagelist" | "stockcagelist";
    actions?: string[];
    reloadCallback?: () => void;
}

abstract class Action {

    public qs: CageQuickselectViewModel;
    public requireConclusion = true;
    public selected: Subscribable<boolean>;
    public enabled: Subscribable<boolean>;
    public valid: Subscribable<boolean>;
    public possible: Subscribable<boolean>;
    public errors: ObservableArray<string>;

    protected constructor(qs: CageQuickselectViewModel) {
        this.qs = qs;
        this.selected = ko.observable(false);
        this.enabled = ko.observable(true);
        this.valid = ko.observable(true);
        this.errors = ko.observableArray([]);
        this.possible = ko.observable(true);
    }

    public serialize = () => {
        return {};
    };
}


const actionModels: {
    [key in keyof SeedType["content"]]: (
        qs: CageQuickselectViewModel,
        seed: SeedType["content"][key],
    ) => Action;
} = {

    add_to_selection_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public extendCageSelection: Observable<boolean>;

        constructor() {
            super(qs);

            this.extendCageSelection = ko.observable(false);
        }

        public serialize = () => {
            return {
                extend_selection: this.extendCageSelection(),
            };
        };
    }),

    show_in_animal_list_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public requireConclusion = false;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    show_in_pup_list_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public requireConclusion = false;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    convert_to_experiment_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        constructor() {
            super(qs);

            // all selected cages must be stock cages
            this.enabled = ko.pureComputed(() => {
                return _.every(qs.context(), (c) => {
                    return c.cagetype === "Stock";
                });
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    set_location_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public preselectLocation: PreselectLocationItem;
        public selectedLocation: CheckExtended<Observable<LocationItem>>;
        public afterApply: () => void;
        public rackId: Computed<number>;
        public cagePositions: Observable<string>;

        public confirmSanitaryStatus: CheckExtended<Observable<boolean>>;
        public showConfirmSanitaryStatus: Computed<boolean>;
        public incompatibleSanitaryStatus: ObservableArray<string> = ko.observableArray([]);

        constructor() {
            super(qs);

            this.preselectLocation = { type: "rack", id: seed.select_rack_id };

            this.selectedLocation = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        if (session.pyratConf.MANDATORY_LOCATION && !v) {
                            return true;
                        }
                    }
                    return false;
                },
            });

            this.rackId = ko.computed(() => {
                return this.selectedLocation() ? this.selectedLocation().rack_id : undefined;
            });

            this.cagePositions = ko.observable();

            this.cagePositions.subscribe(() => {
                this.selected(true);
            });

            this.confirmSanitaryStatus = ko.observable(false)
                .extend({
                    invalid: (value) => {
                        if (value === false
                            && this.selected()
                            && this.incompatibleSanitaryStatus().length > 0
                        ) {
                            if (session.userPermissions.animal_set_lower_sanitary_status) {
                                return getTranslation("Incompatible sanitary status. Problematic locations:")
                                    + " " + this.incompatibleSanitaryStatus().join(", ") + ". "
                                    + getTranslation("Move anyway?");
                            } else {
                                return getTranslation("Can't move!") + " "
                                    + getTranslation("Incompatible sanitary status.");
                            }
                        }

                        return false;
                    },
                });

            // show confirm checkbox for existing incompatible sanitary status location(s) and user permission
            this.showConfirmSanitaryStatus = ko.computed(() => {
                return this.incompatibleSanitaryStatus().length > 0
                    && session.userPermissions.animal_set_lower_sanitary_status;
            });

            // check related action checkbox and potential incompatible sanitary status after applying location picker
            this.afterApply = () => {
                this.selected(true);
                this.confirmSanitaryStatus(false);
                this.incompatibleSanitaryStatus([]);

                if (this.rackId()) {
                    CagesService
                        .getIncompatibleSanitaryStatusLocations({
                            cageIds: this.qs.context()?.map(({ cageid }) => cageid),
                            newRackId: this.rackId(),
                        })
                        .then((response) => this.incompatibleSanitaryStatus(response));
                }
            };

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !(this.selectedLocation.isInvalid()
                         || this.confirmSanitaryStatus.isInvalid());
            });
        }

        public serialize = () => {
            return {
                rack_id: this.rackId(),
                cage_positions: this.cagePositions(),
                confirm_sanitary_status: this.confirmSanitaryStatus(),
            };
        };
    }),

    set_owner_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public ownerId: CheckExtended<Observable<number>>;

        constructor() {
            super(qs);

            this.ownerId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.ownerId.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !this.ownerId.isInvalid();
            });
        }

        public serialize = () => {
            return {
                owner_id: this.ownerId(),
            };
        };
    }),

    set_responsible_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public responsibleId: Observable<number>;

        constructor() {
            super(qs);

            // all selected cages must be of the same owner
            this.enabled = ko.pureComputed(() => {
                return _.every(qs.context(), (c) => {
                    return qs.context()[0].owner_userid === c.owner_userid;
                });
            });

            this.responsibleId = ko.observable();
            this.responsibleId.subscribe(() => {
                this.selected(true);
            });
        }

        public serialize = () => {
            return {
                responsible_id: this.responsibleId(),
            };
        };
    }),

    add_projects_action: (qs, seed) => new class extends Action {

        public seed = seed;
        public projectIds: CheckExtended<ObservableArray<number>>;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                return session.pyratConf.MULTIPLE_PROJECTS;
            });

            this.projectIds = ko.observableArray().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !(v && v.length);
                    }
                    return false;
                },
            });

            this.projectIds.subscribe((v) => {
                if (v && v.length) {
                    this.selected(true);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !this.projectIds.isInvalid();
            });
        }

        public serialize = () => ({
            project_ids: this.projectIds(),
        });

    },

    remove_projects_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public projectIds: CheckExtended<ObservableArray<number>>;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                return session.pyratConf.MULTIPLE_PROJECTS;
            });

            this.projectIds = ko.observableArray().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !(v && v.length);
                    }
                    return false;
                },
            });

            this.projectIds.subscribe((v) => {
                if (v && v.length) {
                    this.selected(true);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !this.projectIds.isInvalid();
            });

            this.serialize = () => ({
                project_ids: this.projectIds(),
            });
        }

    }),

    set_project_action: (qs, seed) => new(class extends Action {

        public seed = seed;
        public projectId: Observable<number>;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                return !session.pyratConf.MULTIPLE_PROJECTS;
            });

            this.projectId = ko.observable();
            this.projectId.subscribe(() => {
                this.selected(true);
            });

            this.serialize = () => ({
                project_id: this.projectId(),
            });
        }

    }),

    set_category_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public cageCategoryId: Observable<number>;

        constructor() {
            super(qs);

            this.cageCategoryId = ko.observable();
        }

        public serialize = () => {
            return {
                cage_category_id: this.cageCategoryId(),
            };
        };
    }),

    add_comment_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public comment: Observable<NewComment>;

        constructor() {
            super(qs);

            this.comment = ko.observable();
            this.comment.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected()
                    && !this.comment()?.comment?.length
                    && !this.comment()?.attributes?.length
                ) {
                    this.errors.push(getTranslation("The comment field is empty"));
                    return false;
                }

                return true;
            });

        }

        public serialize = () => {
            return this.comment();
        };
    }),

    set_color_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public selectedColor: Observable<string>;

        constructor() {
            super(qs);
            this.selectedColor = ko.observable();
        }

        public changeColorCallback = () => {
            this.selected(true);
        };

        public serialize = () => {
            return { color_key: this.selectedColor() || null };
        };

    }),

    set_user_color_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public selectedColor: Observable<string>;

        constructor() {
            super(qs);
            this.selectedColor = ko.observable();
        }

        public changeColorCallback = () => {
            this.selected(true);
        };

        public serialize = () => {
            return { user_color_key: this.selectedColor() || null };
        };

    }),

    set_cage_label_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public cageLabel: Observable<string>;

        constructor() {
            super(qs);

            this.cageLabel = ko.observable();
            this.cageLabel.subscribe(() => {
                this.selected(true);
            });
        }

        public serialize = () => {
            return {
                cage_label: this.cageLabel(),
            };
        };
    }),

    set_cage_label_range_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public cageLabelFrom: Observable<string>;
        public cageLabelTo: Observable<string>;

        constructor() {
            super(qs);

            this.cageLabelFrom = ko.observable();
            this.cageLabelFrom.subscribe(() => {
                this.selected(true);
            });

            this.cageLabelTo = ko.observable();
            this.cageLabelTo.subscribe(() => {
                this.selected(true);
            });

        }

        public serialize = () => {
            return {
                cage_label_from: this.cageLabelFrom(),
                cage_label_to: this.cageLabelTo(),
            };
        };
    }),

    close_empty_cage_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        constructor() {
            super(qs);

            // all selected cages must be empty
            this.enabled = ko.pureComputed(() => {
                return _.every(qs.context(), (c) => {
                    return c.status === "empty";
                });
            });
        }
    }),

    print_cage_cards_action: (qs, seed) => new class extends Action {

        public seed = seed;

        public printer: Observable<string>;
        public maxCagesPrintWarning: Computed<string>;

        constructor() {
            super(qs);

            this.printer = ko.observable(seed.default_printer || undefined);
            this.printer.subscribe(() => {
                this.selected(true);
            });

            this.maxCagesPrintWarning = ko.computed(() => {
                if (this.selected() && qs.context().length > parseInt(session.pyratConf.MAX_CAGES_PRINT, 10)) {
                    return getTranslation("Too many cards to be printed at once. Only the first %s cards are printed.")
                        .replace("%s", session.pyratConf.MAX_CAGES_PRINT);
                }
            });
        }

        public serialize = () => ({
            printer: this.printer(),
        });

    },

    mark_printed_action: (qs) => new (class extends Action {

        constructor() {
            super(qs);
        }
    }),

    export_to_excel_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    archive_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                let isEnabled = false;
                const finalCageStates = ["closed", "empty"];

                // enable archive action checkbox
                // -> all selected cages need to be in final state (closed, empty)
                if (qs.context().length) {
                    isEnabled = qs.context().every((cage) => {
                        return finalCageStates.includes(cage.status)
                            && cage.final_state_period >= session.pyratConf.ARCHIVE_FINAL_STATE_PERIOD_MIN;
                    });
                }

                return isEnabled;
            });

        }
    }),

    restore_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);
        }
    }),

};


class CageQuickselectViewModel {

    public conclusion: ObservableArray<{ text: string; click: () => void }>;
    public applyInProgress: Observable<boolean>;

    public errors: ObservableArray<string>;
    public readonly context: ko.ObservableArray<ContextRow> = ko.observableArray();
    public readonly actions: ko.Observable<{[actionKey: string]: Action}> = ko.observable();

    private readonly params: Params;
    private readonly dialog: KnockoutPopup;
    private fetchInProgress: Observable<boolean> = ko.observable(false);
    public reloadRequired: Observable<boolean> = ko.observable(false);
    private scenery: ko.PureComputed<{ loading: boolean } |
        { conclusion: CageQuickselectViewModel } |
        { context: CageQuickselectViewModel; actions: CageQuickselectViewModel } |
        { error: boolean }>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.params = params;
        this.dialog = dialog;

        this.dialog.addOnClose(() => {
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        // load quick select content data
        this.getQuickselectSeed();

        this.dialog.setTitle(
            getTranslation("Quick Select") + " (" +
            getTranslation("%d cages selected").replace("%d", String(this.params.cageIds.length)) + ")",
        );

        // result handling

        this.conclusion = ko.observableArray([]);
        this.errors = ko.observableArray([]);
        this.applyInProgress = ko.observable(false);

        // routing
        this.scenery = ko.pureComputed(() => {

            if (this.fetchInProgress()) {
                return { loading: true };
            }

            if (this.conclusion().length) {
                return { conclusion: this };
            }

            if (this.context()?.length && this.actions() && Object.keys(this.actions()).length) {
                return { context: this, actions: this };
            }

            return { error: true };
        });

    }

    public close = () => {
        this.dialog.close();
    };

    public canApply = () => {

        if (this.applyInProgress()) {
            return false;
        } else if (this.context().length < 1) {
            return false;
        } else if (!_.some(_.invokeMap(this.actions(), "selected"))) {
            // check if any action is selected
            return false;
        }

        // check if all selected actions are valid
        return _.every(this.actions(), (val) => {
            return val.selected() && val.valid ? Boolean(val.valid()) : true;
        });

    };

    public applyQuickselect = () => {
        this.errors.removeAll();

        this.applyInProgress(true);
        this.reloadRequired(true);
        Object.values(this.actions()).forEach((action) => {
            action.errors.removeAll();
        });

        CagesService
            .applyCageQuickselectActions({
                requestBody: {
                    context: { cage_ids: this.params.cageIds },
                    content: _
                        .chain(this.actions())
                        .pickBy((action) => action.selected())
                        .mapValues((action) => action.serialize())
                        .value(),
                },
            })
            .then((response) => {
                const cageIds = response.context.cage_ids;
                const autoClose = !_.some(response?.content, (data, action: keyof CageQuickselectActionsResponse["content"]) => {
                    return this.actions()[action] && this.actions()[action].requireConclusion;
                });

                if (response.content.show_in_animal_list_action) {
                    // no conclusion needed, this action redirects immediately to the animal list
                    this.reloadRequired(false);
                    mainMenu.open("get_animal_list");

                } else if (response.content.show_in_pup_list_action) {
                    this.reloadRequired(false);
                    mainMenu.open("get_pup_list");

                } else if (response.content.export_to_excel_action && cageIds) {
                    this.reloadRequired(false);
                    showColumnSelect({
                        mode: "export",
                        viewName: this.params.viewName,
                        exportArgs: {
                            cageid: cageIds,
                            page_start: 0,
                            page_size: cageIds.length,
                        },
                    });
                }

                if (autoClose) {
                    // no action requires a conclusion, so we immediately close it
                    this.close();
                } else {
                    this.conclude(response);
                }
            })
            .catch((response) => {
                const content = response.body?.detail?.content;

                if (content) {
                    Object.keys(content).forEach((actionKey) => {
                        if (this.actions()[actionKey] && this.actions()[actionKey].errors) {
                            content[actionKey].forEach((message: string) => {
                                this.actions()[actionKey].errors.push(message);
                            });
                        } else {
                            content[actionKey].forEach((message: string) => {
                                notifications.showNotification(message, "error");
                            });
                        }
                    });
                } else {
                    notifications.showNotification(getTranslation("General quickselect error."), "error");
                    writeException(response);
                }
            })
            .finally(() => this.applyInProgress(false));
    };

    private getQuickselectSeed = () => {

        this.fetchInProgress(true);
        CagesService
            .getCageQuickselectSeed({
                requestBody: this.params.cageIds,
            })
            .then((response) => {
                const actions: { [key in keyof typeof actionModels]?: ReturnType<typeof actionModels[key]> } = {};

                Object.keys(response.content)
                    .forEach((actionName: keyof SeedType["content"]) => {
                        if (actionName in actionModels) {
                            // @ts-expect-error: The typing does not map the action name to its.
                            actions[actionName] = actionModels[actionName](this, response.content[actionName]);
                        }
                    });
                this.actions(actions);

                this.context(
                    response.context?.map((contextRow) => ({
                        ...contextRow,
                        selected: ko.observable(this.params.cageIds.includes(contextRow.cageid)),
                    })),
                );

            })
            .catch((response) => {
                if (response.body?.detail) {
                    notifications.showNotification(response.body?.detail, "error");
                } else {
                    notifications.showNotification(
                        getTranslation("Error while loading the data. Please try again."), "error",
                    );
                    writeException(response);
                }
            })
            .finally(() => this.fetchInProgress(false));
    };

    private conclude = (value: ExecuteResponseType) => {

        // clear old results
        this.conclusion.removeAll();

        const cageIds = value?.context?.cage_ids;
        const addToSelectionRequest = value?.content?.add_to_selection_action;
        const printRequest = value?.content?.print_cage_cards_action;

        if (addToSelectionRequest) {
            this.conclusion.push({
                text: getTranslation("Immediately open a new request"),
                click: () => {
                    frames.detailPopup.open(
                        getUrl(cgiScript("new_request.py"), {}, { absoluteUrl: true }),
                    );
                },
            });
        }

        if (cageIds) {
            this.conclusion.push({
                text: getTranslation("Show cages") + " (" + String(cageIds.length) + ").",
                click: () => {
                    this.reloadRequired(false);
                    mainMenu.openAndResetListFilter(
                        this.params.viewName === "cagelist" ? "get_cage_list" : "get_stock_cage_list",
                        { cageid: cageIds },
                    );
                },
            });

            if (printRequest) {
                this.conclusion.push({
                    text: getTranslation("Print") + ": " + getTranslation("Show in new window / tab"),
                    click: () => this.openPdfInBrowser(cageIds),
                });
                this.openPdfInBrowser(cageIds);
            } else {
                this.conclusion.push({
                    text: getTranslation("Print cage cards"),
                    click: () => {
                        this.checkCagesAndPrinter(cageIds);
                        this.reloadRequired(false);
                        this.close();
                    },
                });

                this.conclusion.push({
                    text: getTranslation("Print cage cards and mark them as printed"),
                    click: () => {
                        this.checkCagesAndPrinter(cageIds);
                        this.markCageCardsAsPrinted();
                        this.reloadRequired(false);
                        this.close();
                    },
                });
            }
        }

        this.conclusion.push({
            text: getTranslation("Open Quick Select to perform another task"),
            click: () => {
                this.conclusion.removeAll();
                this.getQuickselectSeed();
            },
        });
    };

    private checkCagesAndPrinter = (cageIds: number[]) => {
        fetch(cgiScript("print_cages.py"), {
            method: "POST",
            body: getFormData({ cageids: cageIds.join(","), check: 1 }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success || notifications.showConfirm(response.message, () => true)) {
                    if (Object.hasOwn(response, "select_a_printer")) {
                        this.printCageCards(cageIds, response.select_a_printer);
                    } else {
                        // open PDF preview file in browser
                        this.openPdfInBrowser(cageIds);
                    }
                }
            })
            .catch(() => notifications.showNotification(
                getTranslation("Can't print. Internal error has ocurred."),
            ));
    };

    private openPdfInBrowser = (cageIds: number[]) => {
        window.open(getUrl(cgiScript("papercard.py"), {
            "kind": "cage_card",
            "subjects": cageIds.slice(0, parseInt(session.pyratConf.MAX_CAGES_PRINT, 10)).join(","),
        }));
    };

    private printCageCards = (cageIds: number[], selectPrinter: boolean) => {
        if (selectPrinter) {
            // pyrat_conf is set up to use printer_chooser
            frames.openListAjaxPopup({
                method: "GET",
                url: cgiScript("print_cages.py"),
                data: {
                    cageids: cageIds.join(","),
                    select_a_printer: "1",
                },
                title: getTranslation("Choose printer"),
            });
        } else {
            // printer is already selected or just one printer is set up
            fetch(getUrl(cgiScript("print_cages.py"), { cageids: cageIds.join(",") }))
                .then((response) => response.json())
                .then((response) => {
                    if (response.success) {
                        notifications.showNotification(getTranslation("Cards printed"), "success");
                    } else {
                        notifications.showNotification(getTranslation("Can't print. Internal error has ocurred."), "error");
                    }
                })
                .catch(() => notifications.showNotification(
                    getTranslation("Can't print. Internal error has ocurred."),
                ));
        }
    };

    private markCageCardsAsPrinted = () => {
        // select "mark printed" action (=> deselect all other actions)
        Object.keys(this.actions()).forEach((name) => {
            this.actions()[name].selected(name === "mark_printed_action");
        });
        this.applyQuickselect();
    };
}


// dialog starter
export const showCageQuickselect = dialogStarter(CageQuickselectViewModel, template, {
    name: "CageQuickselect",
    width: 600,
    anchor: {
        top: 5,
        right: 5,
    },
    closeOthers: true,
    title: getTranslation("Quick Select"),
});
