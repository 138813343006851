import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
    Subscribable,
} from "knockout";
import * as _ from "lodash";

import {
    ListFilterDefinition,
    StrainOption,
    StrainsService,
} from "../../backend/v1";
import {
    showBlockStrainDialog,
    showComments,
    showMutationDetails,
} from "../../dialogs";
import { FetchBackendExtended } from "../../knockout/extensions/fetchBackend";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    FilterItemDefinition,
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { mainMenu } from "../../lib/pyratTop";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./strainListFilter.html";

interface Arguments extends ListViewArguments {
    list_args: any;
}

const ListFilters = (filter: ListFilterModel) => {
    // ensure Strains will be fetched once after all involved filters are initialized and got their initial values set
    const triggerFetchStrainsDeferred = ko
        .pureComputed(() => {
            return !!(
                filter.filterInitializationInProgress() === false &&
                filter.allFilters().status &&
                filter.allFilters().used &&
                // no user/permitted user filter in strain db list
                // filter.seed() might be undefined when closing filter popup
                ((filter.seed() && filter.viewName === "strain_db_list") || // filter.seed() might be undefined when closing filter popup
                    (filter.seed() &&
                        filter.viewName === "strainlist" &&
                        filter.allFilters().owner_user_id &&
                        filter.allFilters().permitted_user))
            );
        })
        .extend({ deferred: true });

    // having one observable to fetch possible strains for name_with_id and official_name filter
    const filterableStrains: FetchBackendExtended<Observable<StrainOption[]>> = ko.observable([]).extend({
        fetchBackend: () => {
            if (triggerFetchStrainsDeferred()) {
                const status = filter.getValue("status");
                const used = filter.getValue("used");
                const owner_user_id = filter.getValue("owner_user_id");
                const permitted_user = filter.getValue("permitted_user");
                return StrainsService.getStrainsForFilter({
                    ...(status ? { status: status } : {}),
                    ...(used ? { used: used } : {}),
                    ...(filter.viewName === "strainlist"
                        ? // no user/permitted user filter in strain db list
                        {
                            ...(owner_user_id ? { ownerUserId: owner_user_id } : {}),
                            ...(permitted_user ? { permittedUser: [permitted_user] } : {}),
                        }
                        : {}),
                });
            }
        },
    });

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {
        id: ListFilterItem, // Filter for "MySQL ID"

        status: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        used: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        cryopreserved: ListFilterItem,

        owner_user_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.owner_fullname);
            }
        },

        owner_user_group_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        permitted_user: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        name_or_id: class extends ListFilterItem {
            inProgress: Observable<boolean>;
            disable: Observable<boolean>;
            selectemValue: ObservableArray;
            currentCustomValues: ObservableArray;
            possibleValues: PureComputed<StrainOption[]>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.inProgress = filterableStrains.inProgress;
                this.disable = filterableStrains.inProgress;
                this.selectemValue = ko.observableArray();
                this.currentCustomValues = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });
                this.possibleValues = ko.pureComputed(() => {
                    return filterableStrains();
                });
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.valid = ko.pureComputed(() => {
                    return (
                        !this.inProgress() &&
                        this.selectemValue().every(function (option) {
                            return !option.valid || option.valid();
                        })
                    );
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue)
                        ? newValue.filter((value) => {
                            return typeof value === "string";
                        })
                        : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },

        official_name_or_id: class extends ListFilterItem {
            inProgress: Observable<boolean>;
            disable: Observable<boolean>;
            selectemValue: ObservableArray;
            currentCustomValues: ObservableArray;
            possibleValues: PureComputed<StrainOption[]>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.inProgress = filterableStrains.inProgress;
                this.disable = filterableStrains.inProgress;
                this.selectemValue = ko.observableArray();
                this.currentCustomValues = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        if (option.id === option.official_name && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });
                this.possibleValues = ko.pureComputed(() => {
                    return filterableStrains()
                        .filter((option) => {
                            return option.official_name;
                        })
                        .sort((a, b) => {
                            const nameA = a.official_name.toLowerCase();
                            const nameB = b.official_name.toLowerCase();

                            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                        });
                });
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.valid = ko.pureComputed(() => {
                    return (
                        !this.inProgress() &&
                        this.selectemValue().every((option) => {
                            return !option.valid || option.valid();
                        })
                    );
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue)
                        ? newValue.filter((value) => {
                            return typeof value === "string";
                        })
                        : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },

        label: ListFilterItem,

        genetically_altered: ListFilterItem,

        creator_user_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.creator_fullname);
            }
        },

        created_from: class extends ListFilterItem {
            public value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("created_to"), compareFromDate),
                });

                this.valid = () => {
                    return this.value.isValid();
                };
            }
        },

        created_to: class extends ListFilterItem {
            public value: CheckExtended<Observable<string>>;
            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);
                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("created_from"), compareToDate),
                });

                this.valid = () => {
                    return this.value.isValid();
                };
            }
        },

        species_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: { name: string; id: number }[];
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        genetic_background_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: any;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [
                    { id: 0, name: getTranslation("None") },
                    { id: -1, name: getTranslation("Any") },
                ];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        mutation_id: class extends ListFilterItem {
            public strainNameOrIdFilter: Subscribable<FilterItemDefinition>;
            public selectedStrainIds: PureComputed<number[]>;
            public selectedCustomStrainIds: FetchBackendExtended<ObservableArray<number>>;
            public strainMutations: PureComputed<
                {
                    id: number;
                    name: string;
                    strain_ids: number[];
                }[]
            >;
            public staticValues: { name: string; id: number }[];
            public possibleValues: PureComputed<{ name: string; id: number }[]>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.strainNameOrIdFilter = ko.pureComputed(() => {
                    return filter.allFilters().name_or_id;
                });

                // mutation options depend on the selected strain
                this.selectedCustomStrainIds = ko.observableArray().extend({
                    fetchBackend: () => {
                        const strainNameOrIdFilter = ko.unwrap(this.strainNameOrIdFilter);
                        let selectedStrainNamesOrIds;
                        let strainNames;
                        let possibleStrainIds;

                        if (
                            strainNameOrIdFilter &&
                            strainNameOrIdFilter.model.valid() &&
                            strainNameOrIdFilter.model.serialize()
                        ) {
                            selectedStrainNamesOrIds = strainNameOrIdFilter.model.serialize();

                            if (!Array.isArray(selectedStrainNamesOrIds)) {
                                selectedStrainNamesOrIds = [selectedStrainNamesOrIds];
                            }

                            strainNames = selectedStrainNamesOrIds.filter((value) => {
                                return typeof value === "string";
                            });

                            if (strainNames.length) {
                                // @ts-expect-error: The foreign filter item must implement the `possibleValues` property
                                possibleStrainIds = strainNameOrIdFilter.model?.possibleValues()?.map((option) => {
                                    return option.id;
                                });

                                if (possibleStrainIds?.length) {
                                    return StrainsService.getStrainIdsByName({
                                        requestBody: {
                                            strain_names: strainNames,
                                            possible_strain_ids: possibleStrainIds,
                                        },
                                    });
                                }
                            }
                        }
                    },
                });
                this.selectedStrainIds = ko.pureComputed(() => {
                    const strainNameOrIdFilter = ko.unwrap(this.strainNameOrIdFilter);
                    let selectedStrainNamesOrIds = [];

                    if (strainNameOrIdFilter && strainNameOrIdFilter.model.serialize()) {
                        selectedStrainNamesOrIds = strainNameOrIdFilter.model.serialize();

                        if (!Array.isArray(selectedStrainNamesOrIds)) {
                            selectedStrainNamesOrIds = [selectedStrainNamesOrIds];
                        }
                    }

                    return selectedStrainNamesOrIds
                        .filter((value) => {
                            return typeof value === "number";
                        })
                        .concat(this.selectedCustomStrainIds() || []);
                });
                this.strainMutations = ko.pureComputed(() => {
                    const mutations = seed.possible_values || [];
                    const selectedStrainIds = this.selectedStrainIds() || [];

                    if (selectedStrainIds && selectedStrainIds.length) {
                        // display mutations of the selected strains
                        return mutations.filter((mutation: any) => {
                            // find intersection of selected strain ids and those attached to possible mutations ([0 => None])
                            return (mutation.strain_ids || [0]).filter((id: number) => {
                                return selectedStrainIds.indexOf(id) !== -1;
                            }).length;
                        });
                    }

                    // display all available mutations
                    return mutations;
                });

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = ko.pureComputed(() => {
                    return this.staticValues.concat(this.strainMutations());
                });

                this.text = ko.pureComputed(() => {
                    return _.map(this.strainMutations(), "name");
                });
            }
        },

        severity_level_id: class extends ListFilterItem {
            staticValues: { id: number; label: string }[];
            possibleValues: any;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [
                    { id: 0, label: getTranslation("None") },
                    { id: -1, label: getTranslation("All with severity level") },
                ];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        ongoing_severity_assessment: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        project_id: class extends ListFilterItem {
            public staticValues: { name: string; id: number }[];
            public possibleValues: any;
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values);
                this.text = seed.possible_values
                    .map((item: any) => item.name)
                    .concat(seed.possible_values.map((item: any) => item.owner_fullname));
            }
        },

        location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => this.initialized() === true || this.serialize() === seed.default_value;
            }
        },

        strain_db_value: ListFilterItem,

        allow_order: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        comment: ListFilterItem,

        comment_date_from: class extends ListFilterItem {
            public value: CheckExtended<Observable<string>>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_to"), compareFromDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        comment_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable<string>>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_from"), compareToDate),
                });
            }
            public valid = () => this.value.isValid();
        },

        reply_pending: ListFilterItem,

        show_in_strain_db: ListFilterItem,

        share_unbound_with_all: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        documents_complete: ListFilterItem,

        page_size: ListFilterItem,
    };
};

class List {
    private listView: ListView;
    private args: Arguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
            { useColumnSelector: args.view_name !== "strain_db_list" },
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Line / Strain filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("create-new-strain", () => {
            openListDetailPopup(getUrl(cgiScript("new_strain.py")));
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.list_args,
            });
        });

        /* List item actions */

        this.listView.onCellClick("td a.detail, td input.detail", (args) => {
            if (this.args.view_name === "strainlist") {
                openListDetailPopup(
                    getUrl(cgiScript("edit_strain.py"), {
                        strainid: parseInt(args.rowId, 10),
                    }), () => this.listView.unHighlightRow(args.rowId),
                );
                this.listView.highlightRow(args.rowId);
            } else if (this.args.view_name === "strain_db_list") {
                openListDetailPopup(
                    getUrl(cgiScript("edit_strain_db.py"), {
                        strain_db_lookup: 1,
                        strain_id: parseInt(args.rowId, 10),
                    }), () => this.listView.unHighlightRow(args.rowId),
                );
                this.listView.highlightRow(args.rowId);
            }
        });

        this.listView.onCellClick("td a.strain_db_detail, td input.strain_db_detail", (args) => {
            openListDetailPopup(
                getUrl(cgiScript("edit_strain_db.py"), {
                    strain_id: args.rowId,
                    strain_db_lookup: this.args.view_name === "strain_db_list" ? 1 : 0,
                }), () => this.listView.unHighlightRow(args.rowId),
            );
            this.listView.highlightRow(args.rowId);
        });

        /* link to strain based pedigree */
        this.listView.onCellClick("td.pedigree_graph a", (args) => {
            mainMenu.open("get_colony_pedigree", {
                kind: "strain_pedigree",
                label: args.element.dataset.strainId,
            });
        });

        this.listView.onCellClick("td input.strain_block", (args) => {
            showBlockStrainDialog({ strainId: parseInt(args.rowId, 10) });
        });

        this.listView.onCellClick("td input.strain_unblock", (args) => {
            args.element.classList.add("loading");
            StrainsService.unblockStrain({ strainId: parseInt(args.rowId, 10) }).then(() => {
                this.listView.reload({ flashRowId: args.rowId });
            });
        });

        this.listView.onCellClick("td.cryopreserved_embryos a", (args) => {
            mainMenu.openAndResetListFilter("get_embryo_list", {
                strain_name_or_id: Number(args.rowId),
                state_id: parseInt(args.element.dataset.embryoCryopreservationStateId, 10),
            });
        });

        this.listView.onCellClick("td.cryopreserved_sperm a", (args) => {
            mainMenu.openAndResetListFilter("get_sperm_list", {
                strain_name_or_id: Number(args.rowId),
                state_id: parseInt(args.element.dataset.spermCryopreservationStateId, 10),
            });
        });

        this.listView.onCellClick("td.num_animals_live a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                state: "live",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_females_live a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                sex: "f",
                state: "live",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_males_live a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                sex: "m",
                state: "live",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_animals_exported a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                state: "exported",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_females_exported a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                sex: "f",
                state: "exported",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_males_exported a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                sex: "m",
                state: "exported",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_animals_sacrificed a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                state: "sacrificed",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_females_sacrificed a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                sex: "f",
                state: "sacrificed",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_males_sacrificed a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                sex: "m",
                state: "sacrificed",
                strain_name_or_id: Number(args.rowId),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick("td.num_pups a", (args) => {
            mainMenu.openAndResetListFilter("get_pup_list", {
                strain_name_or_id: Number(args.rowId),
                ...this.getUsedFilterParams("pups"),
                ...this.getLocationFilterParams("cage_location"),
            });
        });

        this.listView.onCellClick(
            "td.num_cages_total a",
            (args) => {
                mainMenu.openAndResetListFilter("get_cage_list", {
                    status: "open",
                    strain: Number(args.rowId),
                    ...this.getLocationFilterParams("location"),
                });
            },
        );

        this.listView.onCellClick(
            "td.num_cages_breeding a",
            (args) => {
                mainMenu.openAndResetListFilter("get_cage_list", {
                    cagetype: "Breeding",
                    status: "open",
                    strain: Number(args.rowId),
                    ...this.getLocationFilterParams("location"),
                });
            },
        );

        this.listView.onCellClick(
            "td.num_cages_experiment a",
            (args) => {
                mainMenu.openAndResetListFilter("get_cage_list", {
                    cagetype: "Experiment",
                    status: "open",
                    strain: Number(args.rowId),
                    ...this.getLocationFilterParams("location"),
                });
            },
        );

        this.listView.onCellClick(
            "td.num_cages_stock a",
            (args) => {
                mainMenu.openAndResetListFilter("get_cage_list", {
                    cagetype: "Stock",
                    status: "open",
                    strain: Number(args.rowId),
                    ...this.getLocationFilterParams("location"),
                });
            },
        );

        this.listView.onCellClick("td.num_animals_in_severity_assessment a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                strain_name_or_id: Number(args.rowId),
                has_strain_severity_assessment_sheets: 1,
            });
        });

        this.listView.onCellClick("td.num_females_in_severity_assessment a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                sex: "f",
                strain_name_or_id: Number(args.rowId),
                has_strain_severity_assessment_sheets: 1,
            });
        });

        this.listView.onCellClick("td.num_males_in_severity_assessment a", (args) => {
            mainMenu.openAndResetListFilter("get_animal_list", {
                sex: "m",
                strain_name_or_id: Number(args.rowId),
                has_strain_severity_assessment_sheets: 1,
            });
        });

        this.listView.onCellClick("td.num_pups_in_severity_assessment a", (args) => {
            mainMenu.openAndResetListFilter("get_pup_list", {
                strain_name_or_id: Number(args.rowId),
                has_strain_severity_assessment_sheets: 1,
            });
        });

        this.listView.onCellClick("td.num_pups_female_in_severity_assessment a", (args) => {
            mainMenu.openAndResetListFilter("get_pup_list", {
                sex: "f",
                strain_name_or_id: Number(args.rowId),
                has_strain_severity_assessment_sheets: 1,
            });
        });

        this.listView.onCellClick("td.num_pups_male_in_severity_assessment a", (args) => {
            mainMenu.openAndResetListFilter("get_pup_list", {
                sex: "m",
                strain_name_or_id: Number(args.rowId),
                has_strain_severity_assessment_sheets: 1,
            });
        });

        this.listView.onCellClick("td.mutations a", (args) => {
            showMutationDetails({
                mutationId: parseInt(args.element.dataset.mutationId, 10),
            });
        });

        this.listView.onCellClick("td.comments.clickable", (args) => {
            showComments({
                origin: "strain",
                subjects: { strain_id: parseInt(args.rowId, 10) },
                onClose: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reSeedCallback: () => {
                    this.listView.reload({ flashRowId: args.rowId });
                },
            });
            this.listView.highlightRow(args.rowId);
        });
    }

    private getLocationFilterParams = (locationFilterKey: string) => {
        if ("location" in this.args.list_args) {
            return { [locationFilterKey]: this.args.list_args.location };
        } else {
            return {};
        }
    };

    private getUsedFilterParams = (forSubject: "pups" | "animals" | "cages") => {
        const strainUsedArg = this.args.list_args.used || "__all__";

        if (forSubject === "pups" || forSubject === "animals") {
            if (strainUsedArg === "live") {
                return {
                    state: "live",
                };
            }
            if (strainUsedArg === "dead") {
                // Animal list only support a single state filter value,
                // we choose "sacrificed" over "exported" here.
                return {
                    state: "sacrificed",
                };
            }
            if (strainUsedArg === "unused") {
                return {
                    state: "all",
                };
            }
            if (strainUsedArg === "__all__") {
                return {
                    state: "all",
                };
            }
        } else if (forSubject === "cages") {
            if (strainUsedArg === "live") {
                return {
                    state: "open",
                };
            }
            if (strainUsedArg === "dead") {
                return {
                    state: "closed",
                };
            }
            if (strainUsedArg === "unused") {
                return {
                    state: undefined,
                };
            }
            if (strainUsedArg === "__all__") {
                return {
                    state: undefined,
                };
            }
        }

        return {};
    };
}

export const initStrainList = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
