import * as ko from "knockout";
import {
    Subscription,
    PureComputed,
    Subscribable,
    Computed,
    ObservableArray,
    components,
    Observable,
} from "knockout";

import { assert } from "../../lib/assert";

import template from "./selectem.html";
import "./selectem.scss";

interface OriginalOption {
    label?: string;
    meta?: string;
    group?: string;
    selected?: Observable<boolean>;
    valid?: Observable<boolean>;
}

export type Option<T> = T & OriginalOption;

interface SelectemOption {
    original: OriginalOption;
    isCustom: boolean;
    selected: Observable<boolean>;
    valid: Observable<boolean>;
    scrollTo: Observable;
    next: SelectemOption;
    prev: SelectemOption;
    selectedSubscription?: Subscription;
    matchFilter?: PureComputed<boolean>;
    group?: SelectemGroup;
}

type noGroup = any;

interface SelectemGroup {
    id: noGroup | string;
    name: string;
    label?: string;
    notObservedOptions: SelectemOption[];
    options: ObservableArray<SelectemOption>;
    expanded: Observable<boolean>;
    matchFilter?: PureComputed<boolean>;
    matchedOptions?: PureComputed<SelectemOption[]>;
    matchedOptionsLabel?: PureComputed<string>;
    allSelected?: PureComputed<boolean>;
}

interface SelectemData {
    options: SelectemOption[];
    groups: SelectemGroup[];
}

interface SelectemParams {
    options: ObservableArray<SelectemOption>;
    selectType: "multiple" | "single";
    groupKey: keyof OriginalOption;
    groupOrder: string[];
    groupLabels: { [key: string]: string | null };
    labelKey: keyof OriginalOption;
    metaKey: keyof OriginalOption;
    selectedKey: keyof OriginalOption;
    allowCustom: boolean;
    customValues: ObservableArray<string>;
    value: ObservableArray<SelectemOption>;
    serializeKey: keyof OriginalOption;
    serialize: Observable;
    deserialize: Observable;
    disabled: Observable<boolean>;
    caption: string;
    isExpanded: Observable<boolean>;
    dummyName: null | string;
    displayStyle: "inline" | "overlay";
}

// Global counter of selectem instances, to generate unique html IDs.
let instanceCount = 0;

/**
 * Selectem Model
 *
 * @param options - array or observableArray of objects to use as options to select from
 *
 * @param selectType - multiple or single selection - default is 'single'
 *
 * @param groupKey - options attribute to use as group key - null to not group options
 *
 * @param groupOrder - array of group names - null will order alphabetically
 *
 * @param groupLabels - object with group name as key and translation as value (to translate
 * "no group" use key "__nogroup__") - groupLabels of null will use group name as it is
 * whereas a value of null will not show that group header
 *
 * @param labelKey - options attribute to use as label value
 *
 * @param metaKey - options attribute to use as meta value - null will use no meta
 *
 * @param selectedKey - options attribute to use to (un)select options from outside or to
 * preselect - defaults is "selected"
 *
 * @param allowCustom - should it be possible to add custom options
 *
 * @param customValues - array of strings that will be added as custom options
 *
 * @param value - pass in an observableArray that will be updated with selected options
 *
 * @param serializeKey - objects attribute to use for (de-)serialize selectem
 * (might be 'id') - default is null so (de-)serialize can't be used
 *
 * @param serialize - if params.serializeKey is not null will return params.serializeKey
 * of selected options or undefined
 *
 * @param deserialize - observable to pass in values of params.serializeKey attributes of
 * options that will be selected if params.serializeKey is not null (all values will be
 * treated as string)
 *
 * @param disabled - observable with boolean value to disable the component
 *
 * @param caption - placeholder text to be shown if nothing is selected (null will show
 * no caption) - default is null
 *
 * @param isExpanded - pass an observable that will be updated with the current expanded
 * state of selectem (might be used instead of onclick)
 *
 * @param dummyName - if string is passed and params.serializeKey is set, we'll create a
 * dummy select element with that name that will be part of a surrounding form thus will
 * be respected on form POST or $(form).serialize(), null does noting - default is null
 *
 * @param displayStyle - 'inline' if the options list should be rendered as inline element
 * and push away other elements or 'overlay' to render options above other elements (like
 * native select) - default is 'inline'
 */
class SelectemViewModel {

    noGroupIdentifier: noGroup = new Object("no group");  // will return a unique String Object
    initialScoopLimit = 100;
    scoopIncrease = 100;

    private splitWords = (str: string): string[] => {
        return (str || "")
            .split(/\s+/)
            .filter(word => {
                // filter multiple, trailing or leading spaces
                return !!word;
            })
            .map(word => word.toLowerCase());
    };
    private getGroupClosures = (grp: SelectemGroup) => {
        // create closure functions to keep a reference to the group (grp)
        return {
            matchedOptions: () => {
                const groupOptions = grp.options();
                const matchedOptions = [];
                let option;
                let i;
                let n;

                for (i = 0, n = groupOptions.length; i < n; i++) {
                    option = groupOptions[i];
                    if (option.matchFilter()) {
                        matchedOptions.push(option);
                    }
                }

                return matchedOptions;
            },
            matchedOptionsLabel: () => {
                const matchLen = grp.matchedOptions().length;
                const allLen = grp.options().length;

                return matchLen === allLen ?
                    String(matchLen) :
                    matchLen + "/" + allLen;
            },
            allSelected: () => {
                const groupMatchedOptions = grp.matchedOptions();
                let i;
                let n;

                for (i = 0, n = groupMatchedOptions.length; i < n; i++) {
                    if (!groupMatchedOptions[i].selected()) {
                        return false;
                    }
                }

                return true;
            },
        };
    };

    private optionSelectedSubscriber = (option: SelectemOption, value: boolean) => {

        this.hasFilterFocus(true);

        // remove custom option if it gets unselected
        if (!value && option.isCustom) {
            option.selectedSubscription.dispose();
            this.customOptions.remove(option);
        }

        // deselect all other options if select type is "single"
        if (value && this.selectedOptions && this.selectType === "single") {
            this.selectedOptions().forEach(selectedOption => {
                if (selectedOption !== option) {
                    selectedOption.selected(false);
                }
            });

            // contract (un-expand) the component
            if (this.expanded) {
                this.expanded(false);
            }
        }

        this.highlightedOption(option);
    };

    private loadOptions = () => {
        // README
        // be very, very aware of not consuming any other observable in here
        // the whole component would re-initialize unexpectedly
        const data: SelectemData = {
            options: [],
            groups: [],
        };
        const passedOptions = this.passedOptions() || [];

        // ensure we do not update if this.deserialize updates
        const preselectValues = this.deserializeValues.peek();
        const seenPreselectValues: SelectemOption[] = [];

        this.disposables.forEach(disposable => {
            disposable.dispose();
        });

        for (let i = 0, n = passedOptions.length; i < n; i++) {
            const originalOption = passedOptions[i];
            assert(this.labelKey in originalOption, "option lacks label attribute");
            const originalOptionSelected = originalOption[this.selectedKey];
            const originalOptionValid = originalOption.valid;
            const option: SelectemOption = {
                original: originalOption,
                isCustom: false,
                selected: ko.isObservable(originalOptionSelected) ? originalOptionSelected : ko.observable(!!originalOptionSelected),
                valid: ko.isObservable(originalOptionValid) ? originalOptionValid : ko.observable(true),
                scrollTo: ko.observable(),
                next: null,
                prev: null,
            };

            if (this.serializeKey !== null && this.serializeKey in originalOption) {
                const originalOptionValue: any = originalOption[this.serializeKey];
                if (preselectValues.indexOf(originalOptionValue) !== -1) {
                    if (seenPreselectValues.indexOf(originalOptionValue) === -1) {
                        // Preselect is skipped if another option of the same value was already selected.
                        // Happens when the same option is in different groups and we don't want to preselect them all.
                        option.selected(true);
                        seenPreselectValues.push(originalOptionValue);
                    }
                }
            }

            option.selectedSubscription = option.selected.subscribe((v) => this.optionSelectedSubscriber(option, v));
            this.disposables.push(option.selectedSubscription);

            const originalOptionGroup = originalOption[this.groupKey];
            let groupIdentifier: noGroup | string;
            if (this.groupKey !== null && originalOptionGroup && typeof originalOptionGroup === "string") {
                groupIdentifier = originalOptionGroup;
            } else {
                groupIdentifier = this.noGroupIdentifier;
            }

            let group = data.groups.find(grp => grp.id === groupIdentifier);

            if (group === undefined) {
                group = {
                    id: groupIdentifier,
                    name: groupIdentifier === this.noGroupIdentifier ? "__nogroup__" : groupIdentifier,
                    notObservedOptions: [],
                    options: ko.observableArray(),
                    expanded: ko.observable(true),
                };

                if (this.groupLabels !== null && (typeof this.groupLabels[group.name] === "string" || this.groupLabels[group.name] === null)) {
                    group.label = this.groupLabels[group.name];
                } else {
                    group.label = group.name;
                }
                const groupClosures = this.getGroupClosures(group);

                // assign closures
                group.matchedOptions = ko.pureComputed(groupClosures.matchedOptions).extend({ deferred: true });
                group.matchedOptionsLabel = ko.pureComputed(groupClosures.matchedOptionsLabel).extend({ deferred: true });
                group.allSelected = ko.pureComputed(groupClosures.allSelected).extend({ deferred: true });

                data.groups.push(group);
            }

            option.group = group;
            option.matchFilter = ko.pureComputed(((closuredOption) => {
                const groupLabelWords = this.splitWords(closuredOption.group.label);
                const lcMeta = (this.metaKey !== null && String(closuredOption.original[this.metaKey]) || "").toLowerCase();
                const lcLabel = (String(closuredOption.original[this.labelKey]) || "").toLowerCase();

                return () => {
                    const filterWords = this.filterWords().slice();  // get a shallow copy to not modify the actual observable value
                    const groupLabelWordsLength = groupLabelWords.length;
                    let i;
                    let n;
                    let word;
                    let idx;
                    let allGroupLabelWordsFound;

                    // index of the first group label word within filter words
                    if (groupLabelWordsLength) {
                        idx = filterWords.indexOf(groupLabelWords[0]);
                    } else {
                        idx = -1;
                    }

                    // first check if group label matches
                    if (idx !== -1) {
                        allGroupLabelWordsFound = true;

                        for (i = 1; i < groupLabelWordsLength; i++) {
                            // ensure filter words contain all group label words in the right order
                            if (filterWords.indexOf(groupLabelWords[i]) !== idx + i) {
                                // word not found or at wrong position
                                allGroupLabelWordsFound = false;
                                break;
                            }
                        }

                        if (allGroupLabelWordsFound) {
                            // remove all group label words from filter words so that they do not need
                            // to match options label or meta
                            filterWords.splice(idx, groupLabelWordsLength);
                        }
                    }

                    // now check for the option label and meta
                    for (i = 0, n = filterWords.length; i < n; i++) {
                        word = filterWords[i];

                        if (lcLabel.indexOf(word) !== -1) continue;
                        if (lcMeta && lcMeta.indexOf(word) !== -1) continue;
                        return false;
                    }

                    return true;
                };
            })(option)).extend({ deferred: true });

            data.options.push(option);
            group.notObservedOptions.push(option);
        }

        for (let i = 0, n = data.groups.length; i < n; i++) {
            data.groups[i].options(data.groups[i].notObservedOptions);
            delete data.groups[i].notObservedOptions;
        }

        data.groups.sort((a, b) => {

            // order by passed groupOrder
            if (this.groupOrder !== null) {
                const aIdx = this.groupOrder.indexOf(a.name);
                const bIdx = this.groupOrder.indexOf(b.name);

                if (aIdx < bIdx) return -1;
                if (aIdx > bIdx) return 1;
                return 0;
            }

            // ensure __nogroup__ with label null is always first
            const aLabel = a.label === null ? "" : a.label.toLowerCase();
            const bLabel = b.label === null ? "" : b.label.toLowerCase();

            // order by group label
            if (aLabel < bLabel) return -1;
            if (aLabel > bLabel) return 1;
            return 0;
        });

        return data;
    };

    private windowClickHandler = (ev: MouseEvent) => {
        const clickedSelf = this.selectemElement.contains(ev.target as HTMLElement);

        if (!clickedSelf) {
            this.expanded(false);
        }
    };

    private attachWindowClickHandler = () => {
        window.addEventListener("click", this.windowClickHandler, { capture: true });
        if (window != window.top) {
            window.top.addEventListener("click", this.windowClickHandler, { capture: true });
        }
    };

    private detachWindowClickHandler = () => {
        window.removeEventListener("click", this.windowClickHandler, { capture: true });
        if (window != window.top) {
            window.top.removeEventListener("click", this.windowClickHandler, { capture: true });
        }
    };

    private valueInCustomOptions = (value: any): boolean => {
        return value && this.customOptions().findIndex((option) => {
            return option.original[this.labelKey] === value;
        }) !== -1;
    };

    private addCustomOption = (textValue: string) => {
        assert(typeof textValue === "string", `Unexpected type for textValue: ${typeof textValue}`);
        if (!this.allowCustom) return;
        if (!textValue) return;
        if (this.valueInCustomOptions(textValue)) return;

        const option: SelectemOption = {
            isCustom: true,
            original: {},
            selected: ko.observable(false),
            valid: ko.observable(true),
            next: null,
            prev: null,
            scrollTo: ko.observable(),    // never used, but to ensure options always are shaped the same
        };

        // custom options do expose observables
        option.original.valid = option.valid;
        option.original.selected = option.selected;

        if (this.serializeKey) (option.original as any)[this.serializeKey] = textValue;
        (option.original as any)[this.labelKey] = textValue;
        option.selectedSubscription = option.selected.subscribe((v) => this.optionSelectedSubscriber(option, v));

        this.customOptions.push(option);

        // the selected subscriber is attached, it's added to customOptions, now set it's selected to true
        option.selected(true);
    };

    private deserializeValuesHandler = (deserializeValues: SelectemOption[]) => {
        const customOptions = this.customOptions();
        const options = this.data().options;
        let i;
        let n;
        let option;

        if (this.serializeKey === null) return;

        for (i = 0, n = customOptions.length; i < n; i++) {
            option = customOptions[i];
            const originalOptionValue: any = option?.original?.[this.serializeKey];
            if (typeof originalOptionValue !== "undefined") {
                if (deserializeValues.indexOf(originalOptionValue) === -1) option.selected(false);
            }
        }

        for (i = 0, n = options.length; i < n; i++) {
            option = options[i];
            const originalOptionValue: any = option?.original?.[this.serializeKey];
            if (typeof originalOptionValue !== "undefined") {
                if (deserializeValues.indexOf(originalOptionValue) === -1) option.selected(false);
                else option.selected(true);
            }
        }
    };

    private selectemElement: HTMLElement;
    private instanceNumber: number;

    public groupKey: keyof OriginalOption;
    public groupOrder: string[];
    public groupLabels: { [p: string]: string | null };
    public labelKey: keyof OriginalOption;
    public metaKey: keyof OriginalOption;
    public selectedKey: keyof OriginalOption;
    public selectType: "multiple" | "single";
    public allowCustom: boolean;
    public customValues: ObservableArray<string>;
    public serializeKey: keyof OriginalOption | null;
    public caption: string;
    public dummyName: string;
    public displayStyle: "inline" | "overlay";

    public disposables: Subscription[];
    public hasFilterFocus: Observable<boolean>;
    public highlightedOption: Observable<SelectemOption>;
    public customOptions: ObservableArray<SelectemOption>;

    public filter: Observable<string>;
    public filterInCustomOptions: PureComputed<boolean>;
    public filterWords: PureComputed<string[]>;
    public disabled: Observable<boolean>;
    public expanded: Observable<boolean>;
    public passedOptions: ObservableArray<OriginalOption>;
    public deserialize: Observable<SelectemOption[]>;
    public deserializeValues: PureComputed<SelectemOption[]>;
    public data: Computed<SelectemData>;
    public selectedOptions: PureComputed<SelectemOption[]>;
    public value: Subscribable<OriginalOption[]>;
    public serialize: PureComputed;
    public visibleOptionsCount: PureComputed<number>;

    public scoopLimit: Observable<number>;
    public smoothedScoopLimit: PureComputed<number>;
    public scoop: PureComputed<{ options: SelectemOption[]; group: SelectemGroup }[]>;

    public uniqueCheckboxName: string;
    public checkboxType: string;

    public getNextOption = () => {
        const highlightedOption = this.highlightedOption();


        if (highlightedOption && highlightedOption.next) {
            return highlightedOption.next;
        }

        const scoopValue = this.scoop();
        const firstScoopIndex = scoopValue.length ? 0 : null;
        const firstScoopEntry = firstScoopIndex !== null ? scoopValue[firstScoopIndex] : null;
        const firstOptionsIndex = firstScoopEntry !== null && firstScoopEntry.options.length ? 0 : null;
        return firstOptionsIndex !== null ? firstScoopEntry.options[firstOptionsIndex] : null;
    };

    public getPreviousOption = () => {
        const highlightedOption = this.highlightedOption();


        if (highlightedOption && highlightedOption.prev) {
            return highlightedOption.prev;
        }

        const scoopValue = this.scoop();
        const lastScoopIndex = scoopValue.length ? scoopValue.length - 1 : null;
        const lastScoopEntry = lastScoopIndex !== null ? scoopValue[lastScoopIndex] : null;
        const lastOptionsIndex = lastScoopEntry !== null && lastScoopEntry.options.length ? lastScoopEntry.options.length - 1 : null;
        return lastOptionsIndex !== null ? lastScoopEntry.options[lastOptionsIndex] : null;
    };

    public onComponentExpand = () => {
        if (this.disabled()) {
            return;
        }
        const newState = !this.expanded();

        this.expanded(newState);

        if (newState === false) {
            this.scoopLimit(this.initialScoopLimit);
        }
    };

    public onGroupExpand = (group: SelectemGroup, expandedValue: boolean) => {
        // toggle group expanded or set it to expandedValue
        group.expanded(expandedValue !== undefined ? expandedValue : !group.expanded());
    };

    public onGroupsScroll = (() => {
        let timeout: number = null;
        return (model: SelectemViewModel, ev: UIEvent) => {
            window.clearTimeout(timeout);
            timeout = window.setTimeout(() => {
                const element = ev.target as HTMLElement;
                const scrollPercent = ((element.scrollTop + element.clientHeight) / element.scrollHeight);

                if (scrollPercent > 0.85 && this.visibleOptionsCount() > this.scoopLimit()) {
                    this.scoopMore();
                }
            }, 50);
        };
    })();

    public onUnselectOption = (option: SelectemOption) => {
        if (this.disabled()) {
            return;
        }

        option.selected(false);
    };

    public toggleGroupSelect = (group: SelectemGroup) => {
        const currentState = group.allSelected();

        for (let i = 0, n = group.options().length; i < n; i++) {
            const option = group.options()[i];
            if (option.matchFilter()) {
                option.selected(!currentState);
            }
        }

        // return true to enable the checkbox to change state
        return true;
    };

    public scoopMore = () => {
        this.scoopLimit(this.scoopLimit() + this.scoopIncrease);
    };

    public onAddCustomFilter = () => {
        const filterValue = this.filter();
        this.addCustomOption(filterValue);
        this.filter(undefined);
    };

    // every event that's propagation is not stopped, will bubble up to the selectem element event listener
    public onFilterKeydown = (model: SelectemViewModel, ev: KeyboardEvent) => {
        let highlightedOption;

        // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
        switch (ev.key) {
        case "Spacebar":
        case " ": // Space
            // prevent space event from bubbling up if the filter already has some value
            // otherwise selectemElement would respond with expanding/collapsing the component
            ev.stopPropagation();
            highlightedOption = this.highlightedOption();
            if (highlightedOption) {
                highlightedOption.selected(!highlightedOption.selected());

                if (this.selectType === "single") {
                    this.selectemElement.focus();
                }

                // if an option is highlighted this "space" should only toggle the options selected observable
                // but should not be added to the filter input which would cause the become recomputed
                // returning false ensures that
                return false;
            }
            break;

        case "Enter":
            highlightedOption = this.highlightedOption();
            if (highlightedOption) {
                highlightedOption.selected(true);
                if (this.selectType === "single") {
                    this.selectemElement.focus();
                    ev.stopPropagation();
                }
            } else if (this.filter() && this.allowCustom) {
                this.onAddCustomFilter();
                ev.stopPropagation();
                return false;
            }
            break;

        case "Del":
        case "Delete":
        case "Backspace":
            // the backspace/delete key event handler of the selectem element will prevent default action
            // which would mean for the filter input element, that you cannot remove characters
            // so we do nothing here except stop bubbling
            ev.stopPropagation();
        }

        // non-true return value would cause the input element to ignore keystrokes (you can't type into the input element)
        return true;
    };

    constructor(params: SelectemParams, selectemElement: HTMLElement) {

        // increase the counter with every instance
        instanceCount += 1;

        // get params
        this.selectemElement = selectemElement;
        this.instanceNumber = instanceCount;
        this.groupKey = "groupKey" in params ? params.groupKey : "group";
        this.groupOrder = "groupOrder" in params ? params.groupOrder : null;
        this.groupLabels = "groupLabels" in params ? params.groupLabels : null;
        this.labelKey = "labelKey" in params ? params.labelKey : "label";
        this.metaKey = "metaKey" in params ? params.metaKey : "meta";
        this.selectedKey = "selectedKey" in params ? params.selectedKey : "selected";
        this.selectType = "selectType" in params ? params.selectType : "single";
        this.allowCustom = "allowCustom" in params ? params.allowCustom : false;
        this.customValues = "customValues" in params ? params.customValues : null;
        this.serializeKey = "serializeKey" in params ? params.serializeKey : null;
        this.caption = "caption" in params ? params.caption : null;
        this.dummyName = "dummyName" in params ? params.dummyName : null;
        this.displayStyle = "displayStyle" in params ? params.displayStyle : "inline";

        this.disposables = []; // keep track of subscriptions
        this.hasFilterFocus = ko.observable(false);
        this.highlightedOption = ko.observable(null).extend({ deferred: true });
        this.customOptions = ko.observableArray();

        if (this.allowCustom && ko.isObservable(this.customValues)) {
            this.customValues.subscribe((newValue) => {
                if (newValue && newValue.length) {
                    newValue.forEach(this.addCustomOption);
                    this.customValues([]);
                }
            });
            this.customValues.valueHasMutated();
        }

        this.filter = ko.observable("");
        this.filterInCustomOptions = ko.pureComputed(() => {
            return this.valueInCustomOptions(this.filter());
        }).extend({ deferred: true });
        this.filterWords = ko.pureComputed(() => {
            return this.splitWords(this.filter());
        }).extend({ rateLimit: { timeout: 200, method: "notifyWhenChangesStop" } });
        this.filterWords.subscribe(() => {
            this.highlightedOption(null);
        });
        this.disabled = ko.utils.gluedObservable(params.disabled, false, "in");
        this.disabled.subscribe((value) => {
            if (value) {
                this.expanded(false);
            }
        });
        this.passedOptions = ko.utils.gluedObservable(params.options, [], "in", false, ko.observableArray());
        this.deserialize = ko.utils.gluedObservable(params.deserialize, undefined, "in");
        this.deserializeValues = ko.pureComputed(() => {
            const currentValue = this.deserialize();
            if (this.serializeKey == null) return [];
            if (currentValue === undefined) return [];
            if (!Array.isArray(currentValue)) return [currentValue];
            return currentValue;
        });

        this.data = ko.computed(this.loadOptions).extend({ deferred: true });
        this.data.subscribe(() => {
            this.highlightedOption(null);
        });
        this.selectedOptions = ko.pureComputed(() => {
            return [].concat(
                this.customOptions().filter((option) => {
                    return option.selected();
                }),
                this.data().options.filter((option: SelectemOption) => {
                    return option.selected();
                }),
            );
        }).extend({ deferred: true });

        this.deserializeValues.subscribe(this.deserializeValuesHandler);
        if (this.deserializeValues().length) this.deserializeValuesHandler(this.deserializeValues());

        this.value = ko.utils.gluedObservable(params.value, [], "out", false,
            ko.pureComputed(() => {
                return this.selectedOptions().map((option) => {
                    return option.original;
                });
            }).extend({ deferred: true }),
        );

        this.serialize = ko.utils.gluedObservable(params.serialize, undefined, "out", false,
            ko.pureComputed(() => {

                if (this.serializeKey === null) return undefined;

                const result = this.value().map((option: OriginalOption) => {
                    return option[this.serializeKey];
                });

                if (result.length === 0) return undefined;
                if (this.selectType === "single") return result[0];
                return result;
            }).extend({ deferred: true }),
        );

        this.visibleOptionsCount = ko.pureComputed(() => {
            const groups = this.data().groups;
            let count = 0;
            let i;
            let n;
            let group;

            for (i = 0, n = groups.length; i < n; i++) {
                group = groups[i];
                count += group.expanded() ? group.matchedOptions().length : 0;
            }

            return count;
        }).extend({ deferred: true });
        this.visibleOptionsCount.subscribe((newValue) => {
            if (this.scoopLimit() > this.initialScoopLimit && this.scoopLimit() > newValue) {
                this.scoopLimit(Math.max(newValue, this.initialScoopLimit));
            }
        });
        this.scoopLimit = ko.observable(this.initialScoopLimit);
        this.smoothedScoopLimit = ko.pureComputed(((currentScoopLimit) => {
            // The purpose of this computed is to smooth out changes to the scoopLimit thus scoop does not
            // get triggered too often.
            // Assume the scoopLimit is 100 and the number of available options to select from is 5000. The user
            // scrolls to the end of the options list a coule of times. Each time the scoopLimit will be increased
            // thus scoop will return a new list of options until scoopLimit is reached. Lets assume, the scoopLimit
            // is 1000 now. Now the user enters a filter term. The number of available options is reduced to 300 now.
            // The scoopLimit will be updated to 300 so if the user changes the filter term to something that matches
            // 2000 options, the list will not be rendered to 1000 again (this was the scoopLimit before the first filter).
            // But Updating the scoopLimit will cause re-calculation of scoop. Even if it would result in the amount
            // of options.
            // This si where this smoothedScoopLimit comes into play. It will only return a new value if it would cause
            // the scoop to return a new amount of options.
            let localScoopLimit = currentScoopLimit;

            return () => {
                const newScoopLimit = this.scoopLimit();
                const visibleOptionsCount = this.visibleOptionsCount();

                if (newScoopLimit >= visibleOptionsCount) {
                    if (localScoopLimit >= visibleOptionsCount) {
                        // scoop would return the same amount of options - nothing to do
                    } else if (localScoopLimit !== newScoopLimit) {
                        localScoopLimit = newScoopLimit;
                    }
                } else if (localScoopLimit !== newScoopLimit) {
                    localScoopLimit = newScoopLimit;
                }

                return localScoopLimit;
            };
        })(this.scoopLimit())).extend({ deferred: true });
        this.scoop = ko.pureComputed(() => {
            const list = [];
            let count = 0;
            const dataGroups = this.data().groups;
            let prevOption = null;

            // "top" this is a label, it's used to "break" nested loops.
            // see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/label
            top:
            for (let grpIdx = 0, grpLen = dataGroups.length; grpIdx < grpLen; grpIdx++) {
                const group: SelectemGroup = dataGroups[grpIdx];
                // don't show group row for groups with no filter matching option
                if (group.matchedOptions().length === 0) {
                    continue;
                }
                const entry: { options: any[]; group: SelectemGroup } = { group: group, options: [] };
                list.push(entry);
                // if group is not expanded, don't care about options
                if (!group.expanded()) {
                    continue;
                }
                for (let optIdx = 0, optLen = group.matchedOptions().length; optIdx < optLen; optIdx++) {
                    const option = group.matchedOptions()[optIdx];
                    option.next = null;
                    option.prev = prevOption;
                    if (prevOption) {
                        prevOption.next = option;
                    }
                    entry.options.push(option);
                    count++;
                    prevOption = option;

                    if (count === this.smoothedScoopLimit()) {
                        break top;  // using label "top" to break both loops
                    }
                }
            }

            return list;
        }).extend({ deferred: true });
        this.expanded = ko.utils.gluedObservable(params.isExpanded, false, "out");
        this.expanded.subscribe((value) => {
            const selectedOption = this.selectedOptions().find((option) => {
                return !option.isCustom;
            });

            if (value) {
                this.highlightedOption(null);
                this.attachWindowClickHandler();
                // option elements are not rendered yet - use setTimeout to run code when they are
                window.setTimeout(() => {
                    this.hasFilterFocus(true);

                    if (selectedOption) {
                        this.onGroupExpand(selectedOption.group, true); // expand the group
                        selectedOption.scrollTo(true);
                    }
                }, 0);
            } else {
                this.detachWindowClickHandler();
                this.hasFilterFocus(false);
                this.filter(undefined);
            }
        });

        this.uniqueCheckboxName = "selectem" + Date.now();
        this.checkboxType = this.selectType === "single" ? "radio" : "checkbox";

        // every event that's propagation is not stopped by the filter input's event listener will bubble up to here
        selectemElement.addEventListener("keydown", (ev) => {
            if (this.disabled()) {
                ev.preventDefault();
                return false;
            }

            let prevOption: SelectemOption;
            let nextOption: SelectemOption;

            switch (ev.key) {
            case "Spacebar":
            case " ": // Space
            case "Enter":
                ev.stopPropagation();
                ev.preventDefault();
                if (this.expanded()) {
                    selectemElement.focus();
                    this.expanded(false);
                } else {
                    // submit the next parent form element
                    let parentElement = selectemElement.parentElement;
                    while (parentElement) {
                        if (parentElement.tagName.toUpperCase() === "FORM") {
                            const submitButton = parentElement.querySelector("input[type=submit],button[type=submit]") as HTMLInputElement;
                            if (submitButton) {
                                submitButton.click();
                            }
                            break;
                        } else {
                            parentElement = parentElement.parentElement;
                        }
                    }
                    if (!parentElement) {
                        // no parent form element found - expand the selectem
                        this.expanded(true);
                    }
                }
                break;

            case "Esc":
            case "Escape":
                if (this.expanded()) {
                    selectemElement.focus();
                    this.expanded(false);
                    ev.stopPropagation();
                }
                break;

            case "Down":
            case "ArrowDown":
                ev.stopPropagation();
                nextOption = this.getNextOption();
                if (nextOption) {
                    this.highlightedOption(nextOption);
                    if (this.expanded()) {
                        // if expanded, scroll to new highlighted option
                        nextOption.scrollTo(true);
                    } else {
                        // if contracted, deselect all selected options and select the new highlighted option
                        this.selectedOptions().forEach((option) => {
                            if (option !== nextOption) {
                                option.selected(false);
                            }
                        });
                        nextOption.selected(true);
                    }
                }
                // prevent the window from scrolling by using the arrow keys
                ev.preventDefault();
                break;

            case "Up":
            case "ArrowUp":
                ev.stopPropagation();
                prevOption = this.getPreviousOption();
                if (prevOption) {
                    this.highlightedOption(prevOption);
                    if (this.expanded()) {
                        // if expanded, scroll to new highlighted option
                        prevOption.scrollTo(true);
                    } else {
                        // if contracted, deselect all selected options and select the new highlighted option
                        this.selectedOptions().forEach((option) => {
                            if (option !== prevOption) {
                                option.selected(false);
                            }
                        });
                        prevOption.selected(true);
                    }
                }
                // prevent the window from scrolling by using the arrow keys
                ev.preventDefault();
                break;

            case "Del":
            case "Delete":
            case "Backspace":
                ev.stopPropagation();
                // backspace triggers in some browsers the history back action - we'd like to prevent that
                ev.preventDefault();
                if (!this.expanded()) {
                    this.selectedOptions().forEach((option) => {
                        option.selected(false);
                    });
                }
                break;
            }
        });
    }
}


export class SelectemComponent {

    constructor() {

        return {
            viewModel: {
                createViewModel: (params: any, componentInfo: components.ComponentInfo) => {

                    // ensure the component is used through <ko-selectem/> HTML element so we can use scoped styles
                    if (componentInfo.element.nodeName.toLowerCase() !== "ko-selectem") {
                        throw new Error("Only use the selectem component through <ko-selectem/> HTML tag.");
                    }

                    const componentElement = componentInfo.element as HTMLElement;
                    let parent = componentElement.parentElement;


                    for (let i = 0; i < 1000 && parent !== null; i++, parent = parent.parentElement) {
                        if (parent.nodeName.toLowerCase() === "label") {
                            // https://stackoverflow.com/questions/9004307/two-input-fields-inside-one-label
                            throw new Error("Do not wrap selectem with a label element. Selectem contains multiple input elements. The label element will correspond with the first input and make it impossible in Safari to get any other input element focussed.");
                        }
                    }

                    // set tabIndex so selectem element can get focus by <tab>ing through a form
                    if (componentElement.tabIndex === -1) {
                        componentElement.tabIndex = 0;
                    }


                    return new SelectemViewModel(params, componentElement);
                },
            },
            template,
        };
    }
}
