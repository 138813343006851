import * as ko from "knockout";
import * as _ from "lodash";

import {
    CommentWidgetSeed,
    DocumentWidgetSeed,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    AjaxResponse,
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    normalizeDate,
    getFormData,
} from "../lib/utils";

import licenseDetailTemplate from "./licenseDetails.html";
import addGuidanceTemplate from "./licenseDetailsAddGuidance.html";
import createBudgetTemplate from "./licenseDetailsCreateBudget.html";
import "./licenseDetails.scss";

interface Params {
    licenseId: number | null;
    onClose?: () => void;
    reloadCallback?: () => void;
}


interface LicenseType {
    id: number;
    name: string;
}

interface Project {
    id: number;
    status: string;
    project_label: string;
    owner_fullname: string;
    project_label_with_owner: string;
}

interface Budget {
    id: number;
    name: string;
}

interface Guidance {
    license_id: number;
    content: string;
    created: string;
    user_fullname: string;
    isDeleted?: ko.Observable<boolean>;
}

interface User {
    user_id: number;
    user_fullname: string;
}

interface Group {
    group_id: number;
    name: string;
}

interface PermittedUser {
    license_id: number;
    user_id: number;
    user_fullname: string;
    active_user_email: string;
}

interface Species {
    id: number;
    name: string;
    status: string;
}

interface SeverityLevel {
    id: number;
    label: string;
    severity: number;
    available: number;
}

interface SeverityAssessmentTemplate {
    id: number;
    name: string;
    available: number;
}

interface Strain {
    strain_id: number;
    strain_name_with_id: string;
    species_id: number;
    group: string;
    isNew?: ko.PureComputed<boolean>;
}

export interface ClassificationStrainAssignment extends Strain {
    classification_id: number;
    animal_count: number;
}

interface Procedure {
    procedure_id: number;
    procedure_name: string;
}

export interface ClassificationProcedureAssignment extends Procedure {
    classification_id: number;
    isNew?: ko.Subscribable<boolean>;
}

export interface Classification {
    classification_id: number;
    classification_name: string;
    classification_severity_level_id: number;
    classification_severity_assessment_template_id: number;
    classification_severity_assessment_permitted_score: number;
    classification_species_id: number;
    classification_strains: Strain[];
    classification_strain_assignments: ClassificationStrainAssignment[];
    classifications_total: number;
    classifications_used: number;
    classifications_available: number;
    classification_description: string;
    classification_procedures: ClassificationProcedureAssignment[];
    license_id: number;
}

interface EuYesNo {
    id: number;
    label: string;
}

interface EuUse {
    id: string;
    label: string;
}

interface EuPurpose {
    id: string;
    label: string;
    disabled?: ko.Subscribable<boolean>;
}

interface EuLegislation {
    id: string;
    label: string;
}

interface EuLegislativeRequirement {
    id: string;
    label: string;
}

interface HistoryRecord {
    event_actor_fullname: string;
    event_date: string;
    event_type_id: number;
    license_id: number;
    changes: any[][];
    event_type_label: string;
}

interface Seed {

    license_id: number;
    popup_title: string;
    max_length: {
        license_number?: number;
        license_title?: number;
        description?: number;
        government_id?: number;
        experiment_types?: number;
        guidance?: number;
        classification_name?: number;
        classifications_total?: number;
        other_purpose?: number;
        other_legislation?: number;
        license_type?: number;
    };
    current_user_is_leader: boolean;
    current_user_allow_edit: boolean;
    current_user_allow_edit_for_sign_off: boolean;
    current_user_allow_submit: boolean;
    current_user_allow_delete: boolean;
    current_user_allow_add_documents: boolean;
    is_submitted: boolean;

    // attributes
    comment_widget_seed: CommentWidgetSeed;
    document_widget_seed: DocumentWidgetSeed;
    license_types: LicenseType[];
    license_type_id: number;

    projects: Project[];
    project_id: number;
    budgets: Budget[];
    license_number: string;
    license_title: string;
    description: string;
    valid_from: string;
    valid_to: string;
    government_id: string;
    experiment_types: string;
    budget_id: number;
    guidance: Guidance[];

    // permissions:
    leader_options: User[];
    primaries: User[];
    individuals: User[];
    user_groups: Group[];
    all_leaders: number;
    all_avail: number;
    leaders: PermittedUser[];
    permitted_primaries: PermittedUser[];
    permitted_individuals: PermittedUser[];
    permitted_groups: Group[];

    // classifications
    species: Species[];
    severity_levels: SeverityLevel[];
    severity_assessment_templates: SeverityAssessmentTemplate[];
    strains: Strain[];
    procedures: Procedure[];
    classifications: Classification[];

    // eu statistics
    eu_yes_no: EuYesNo[];
    eu_uses: EuUse[];
    eu_purposes: EuPurpose[];
    eu_legislations: EuLegislation[];
    eu_legislative_requirements: EuLegislativeRequirement[];
    eu_other_purposes: string[];
    eu_other_legislations: string[];
    for_breeding: number;
    to_report: number;
    license_use_code: string;
    license_new_strain: number;
    license_purpose_code: string;
    license_other_purpose?: string;
    license_legislation_code?: string;
    license_other_legislation?: string;
    license_legislative_requirement_code?: string;

    // history
    history: HistoryRecord[];

    message: string;
}

class CreateBudgetModel {

    public licenseDetails;
    public dialog;
    public newBudgetName: ko.Observable<string>;

    constructor(dialog: HtmlDialog, licenseDetails: LicenseDetailsViewModel) {
        this.licenseDetails = licenseDetails;
        this.dialog = dialog;
        this.newBudgetName = ko.observable().extend({
            trim: true,
        });
    }

    public createBudget = () => {
        this.licenseDetails.asyncInProgress(true);
        fetch(cgiScript("license_list.py"), {
            method: "POST",
            body: getFormData({
                create_budget: this.newBudgetName(),
            }),
        }).then(response => response.json()).then(response => {
            this.licenseDetails.attributes.budgets(response.budgets || undefined);
            this.licenseDetails.attributes.budgetId(response.budget_id || undefined);
            if (!response.success) {
                notifications.showNotification(response.message);  // no 'error', only inform about name already existing
            }
            this.dialog.close();
        }).catch(() => {
            notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
        }).finally(() => {
            this.licenseDetails.asyncInProgress(false);
        });
    };
}

const showCreateBudget = htmlDialogStarter(CreateBudgetModel, createBudgetTemplate, {
    title: getTranslation("Create new budget"),
    width: 360,
    modal: true,
    position: {
        inset: {
            top: 210,
            right: 40,
        },
    },
});

class AddGuidanceModel {

    public licenseDetails;
    public dialog;
    public newGuidance: ko.Observable<string>;

    constructor(dialog: HtmlDialog, licenseDetails: LicenseDetailsViewModel) {
        this.licenseDetails = licenseDetails;
        this.dialog = dialog;
        this.newGuidance = ko.observable().extend({
            trim: true,
        });
    }

    public addGuidance = () => {
        this.licenseDetails.attributes.guidance.splice(0, 0, {
            created: undefined,
            license_id: undefined,
            user_fullname: undefined,
            content: this.newGuidance(),
            isDeleted: ko.observable(false),
        });
        this.dialog.close();
    };
}

const showAddGuidance = htmlDialogStarter(AddGuidanceModel, addGuidanceTemplate, {
    title: getTranslation("Add guidance"),
    width: 360,
    modal: true,
    position: {
        inset: {
            top: 210,
            right: 40,
        },
    },
});

class LicenseDetailAttributesModel {

    public licenseDetails: LicenseDetailsViewModel;
    public licenseTypes: ko.ObservableArray<LicenseType>;
    public licenseTypeId: ko.Observable<number>;
    public licenseNumber: ko.Observable<string>;
    public licenseTitle: ko.Observable<string>;
    public description: ko.Observable<string>;
    public projects: ko.ObservableArray<Project>;
    public projectId: ko.Observable<number>;
    public validFrom: CheckExtended<ko.Observable<string>>;
    public validTo: CheckExtended<ko.Observable<string>>;
    public governmentId: ko.Observable<string>;
    public experimentTypes: ko.Observable<string>;
    public budgets: ko.ObservableArray<Budget>;
    public budgetId: ko.Observable<number>;
    public showCreateBudget: () => void;
    public documentData: ko.Observable<DocumentWidgetSeed>;
    public newDocumentIds: ko.ObservableArray<number>;
    public documentAsyncInProgress: ko.Observable<boolean>;
    public guidance: ko.ObservableArray<Guidance>;
    public showAddGuidance: () => void;
    public requiredFields: ko.PureComputed<{ [key: string]: boolean }>;
    public errorMessage: ko.PureComputed<string>;

    constructor(licenseDetails: LicenseDetailsViewModel) {

        this.licenseDetails = licenseDetails;
        this.licenseTypes = ko.observableArray();
        this.licenseTypeId = ko.observable().extend({
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("License type"));
                } else {
                    return false;
                }
            },
        });

        this.licenseNumber = ko.observable().extend({
            trim: true,
            invalid: (value) => {
                // required for submitting license (status = "Granted"), not checked when saving license (status = "Draft")
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("Number"));
                } else {
                    return false;
                }
            },
        });
        this.licenseTitle = ko.observable().extend({
            trim: true,
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("Title"));
                } else {
                    return false;
                }
            },
        });
        this.description = ko.observable().extend({
            trim: true,
        });

        this.projects = ko.observableArray();
        this.projectId = ko.observable();

        this.validFrom = ko.observable().extend({
            normalize: normalizeDate,
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("Valid from"));
                } else {
                    return checkDateRangeField(value, () => {
                        return this.validTo();
                    }, compareFromDate, false, true, true);
                }
            },
        });
        this.validTo = ko.observable().extend({
            normalize: normalizeDate,
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("Valid to"));
                } else {
                    return checkDateRangeField(value, () => {
                        return this.validFrom();
                    }, compareToDate, false, true, true);
                }
            },
        });

        this.governmentId = ko.observable().extend({
            trim: true,
        });
        this.experimentTypes = ko.observable().extend({
            trim: true,
        });

        this.budgets = ko.observableArray();
        this.budgetId = ko.observable();
        this.showCreateBudget = _.partial(showCreateBudget, this.licenseDetails);

        this.documentData = ko.observable();
        this.newDocumentIds = ko.observableArray();
        this.documentAsyncInProgress = ko.observable(false);
        this.documentAsyncInProgress.subscribe((v) => {
            if (!v) {
                // reload list after the pop-up is closed
                licenseDetails.reloadRequired(true);
            }
        });

        this.guidance = ko.observableArray();
        this.showAddGuidance = _.partial(showAddGuidance, this.licenseDetails);

        this.requiredFields = ko.pureComputed(() => {
            return {
                licenseTypeId: true,
                licenseNumber: licenseDetails.haveSubmittedLicense(),
                licenseTitle: true,
                validFrom: true,
                validTo: true,
            };
        });

        this.errorMessage = ko.pureComputed(() => {
            return this.licenseDetails.getErrorMessage([
                "licenseTypeId",
                "licenseNumber",
                "licenseTitle",
            ], this)
                || this.licenseDetails.triedSave()
                && (this.validFrom.errorMessage() || this.validTo.errorMessage());
        }).extend({
            deferred: true,
        });
    }

    public load = (seed: Seed) => {

        // license attribute options
        this.licenseTypes(seed.license_types || undefined);
        this.projects(seed.projects || undefined);
        this.budgets(seed.budgets || undefined);

        // license attributes
        this.licenseTypeId(seed.license_type_id || null);
        this.licenseNumber(seed.license_number || null);
        this.licenseTitle(seed.license_title || null);
        this.description(seed.description || null);
        this.projectId(seed.project_id || null);
        this.validFrom(seed.valid_from || null);
        this.validTo(seed.valid_to || null);
        this.governmentId(seed.government_id || null);
        this.experimentTypes(seed.experiment_types || null);
        this.budgetId(seed.budget_id || null);
        this.documentData(seed.document_widget_seed);
        this.guidance(seed.guidance || []);

    };
}

class PermissionsOptionSelect<T> {

    public idKey: keyof T;
    public options: ko.PureComputed<T[]>;
    public selectedId: ko.Observable<T[keyof T]>;
    public preselectedOptions: ko.ObservableArray<T>;
    public selectedOptions: CheckExtended<ko.ObservableArray<T>>;
    public selectedIds: ko.PureComputed<T[keyof T][]>;

    constructor(options: ko.Observable<T[]>, idKey: keyof T) {

        this.idKey = idKey;
        this.options = ko.pureComputed(() => {
            return _.filter(options ? options() : [], (opt) => {
                return _.every(this.selectedOptions(), (selectedOpt) => {
                    return selectedOpt[idKey] !== opt[idKey];
                });
            });
        });
        this.selectedId = ko.observable();
        this.preselectedOptions = ko.observableArray();
        this.preselectedOptions.subscribe((v) => {
            this.selectedOptions(v.slice(0));
        });
        this.selectedOptions = ko.observableArray();
        this.selectedIds = ko.pureComputed(() => {
            // just return IDs of `selectedOptions`
            return _.map(this.selectedOptions(), (opt) => {
                return opt[idKey];
            });
        });
    }

    public unselectOption = (data: T) => {
        this.selectedOptions.remove(data);
    };

    public selectOption = () => {
        const selectedOption: T & { isNew?: ko.PureComputed<boolean> } = _.find(this.options(), (opt) => {
            return opt[this.idKey] === this.selectedId();
        });

        if (selectedOption) {
            if (!Object.prototype.hasOwnProperty.call(selectedOption, "isNew")) {
                selectedOption.isNew = ko.pureComputed(() => {
                    return _.every(this.preselectedOptions(), (preselectedOption) => {
                        return preselectedOption[this.idKey] !== selectedOption[this.idKey];
                    });
                });
            }
            this.selectedOptions.push(selectedOption);
        }
    };
}

class LicenseDetailPermissions {

    public restrictLeadership: ko.Observable<boolean>;
    public restrictPermissions: ko.Observable<boolean>;
    public allLeaders: ko.ObservableArray<User>;
    public allPrimaries: ko.ObservableArray<User>;
    public allIndividuals: ko.ObservableArray<User>;
    public allUserGroups: ko.ObservableArray<Group>;
    public leaders: PermissionsOptionSelect<User>;
    public primaries: PermissionsOptionSelect<User>;
    public individuals: PermissionsOptionSelect<User>;
    public userGroups: PermissionsOptionSelect<Group>;
    public errorMessage: ko.PureComputed<string | false>;

    constructor(licenseDetails: LicenseDetailsViewModel) {

        this.restrictLeadership = ko.observable();
        this.restrictPermissions = ko.observable();

        this.allLeaders = ko.observableArray();
        this.allPrimaries = ko.observableArray();
        this.allIndividuals = ko.observableArray();
        this.allUserGroups = ko.observableArray();

        this.leaders = new PermissionsOptionSelect(this.allLeaders, "user_id");
        this.leaders.selectedOptions.extend({
            invalid: (v) => {
                if (this.restrictLeadership() && !v.length) {
                    return getTranslation("Please add at least one project leader");
                }

                return false;
            },
        });
        this.leaders.unselectOption = (data) => {
            if (data.user_id === parseInt(String(session.userId), 10) &&
                !session.userPermissions.license_update) {
                notifications.showConfirm(
                    getTranslation("When you remove yourself from the list of project leaders, you will not be able to edit this license anymore after saving"),
                    () => {
                        this.leaders.selectedOptions.remove(data);
                    },
                );
            } else {
                this.leaders.selectedOptions.remove(data);
            }
        };

        this.primaries = new PermissionsOptionSelect(this.allPrimaries, "user_id");

        this.individuals = new PermissionsOptionSelect(this.allIndividuals, "user_id");

        this.userGroups = new PermissionsOptionSelect(this.allUserGroups, "group_id");

        this.errorMessage = ko.pureComputed(() => {
            if (licenseDetails.haveSubmittedLicense()) {
                this.leaders.selectedOptions.notifySubscribers(this.leaders.selectedOptions());
                return this.leaders.selectedOptions.errorMessage();
            }

            return false;
        }).extend({
            deferred: true,
        });

    }

    public load = (seed: Seed) => {

        // license permission options
        this.allLeaders(seed.leader_options || undefined);
        this.allPrimaries(seed.primaries || undefined);
        this.allIndividuals(seed.individuals || undefined);
        this.allUserGroups(seed.user_groups || undefined);
        this.leaders.selectedId(undefined);
        this.primaries.selectedId(undefined);
        this.individuals.selectedId(undefined);
        this.userGroups.selectedId(undefined);

        // license permission data
        this.restrictLeadership(!(seed.all_leaders || 0));
        this.restrictPermissions(!(seed.all_avail || 0));
        this.leaders.preselectedOptions(seed.leaders || []);
        this.primaries.preselectedOptions(seed.permitted_primaries || []);
        this.individuals.preselectedOptions(seed.permitted_individuals || []);
        this.userGroups.preselectedOptions(seed.permitted_groups || []);
    };
}

class LicenseClassificationDetails {

    public licenseDetails: LicenseDetailsViewModel;
    public classificationId: string | number;
    public newClassificationId: number;
    public editMode: ko.Observable<boolean>;
    public classificationName: CheckExtended<ko.Observable<string>>;
    public severityLevels: ko.PureComputed<SeverityLevel[]>;
    public severityLevelId: ko.Observable<number>;
    public severityAssessmentTemplateId: ko.Observable<number>;
    public severityAssessmentTemplates: ko.PureComputed<SeverityAssessmentTemplate[]>;
    public severityAssessmentPermittedScore: CheckExtended<ko.Observable<number>>;
    public speciesId: ko.Observable<number>;
    public species: ko.PureComputed<Species[]>;
    public classificationStrains: Strain[];
    public strainData: ko.Observable<Strain[]>;
    public selectedStrains: ko.ObservableArray<Strain>;
    public strains: ko.PureComputed<Strain[]>;
    public selectedStrainIds: ko.PureComputed<number[]>;
    public assignedStrains: ko.PureComputed<ClassificationStrainAssignment[]>;
    public classificationsUsed: ko.Observable<number>;
    public classificationsTotal: CheckExtended<ko.Observable<number>>;
    public classificationsAvailable: ko.PureComputed<number>;
    public classificationDescription: ko.Observable<string>;
    public classificationProcedures: ClassificationProcedureAssignment[];
    public procedureId: ko.Observable<number>;
    public procedures: ko.PureComputed<Procedure[]>;
    public selectedProcedures: ko.ObservableArray<ClassificationProcedureAssignment>;
    public selectedProcedureIds: ko.PureComputed<number[]>;
    public isNew: boolean;
    public isDeleted: ko.Observable<boolean>;
    public isEmpty: ko.PureComputed<boolean>;
    public isAssigned: boolean;
    public haveAvailableClassification: ko.PureComputed<boolean>;
    public requiredFields: ko.PureComputed<{ [key: string]: any }>;
    public errorMessage: ko.PureComputed<string>;

    constructor(licenseDetails: LicenseDetailsViewModel,
        data: Classification & { is_new?: boolean }) {

        this.licenseDetails = licenseDetails;
        this.classificationId = data.is_new ? "new_" + data.classification_id : data.classification_id;
        this.newClassificationId = data.is_new ? data.classification_id : undefined;

        this.editMode = ko.observable(!!data.is_new);

        this.classificationName = ko.observable(data.classification_name).extend({
            trim: true,
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("Name") + " (" + getTranslation("Classification") + ")");
                } else {
                    return false;
                }
            },
        });

        this.severityLevels = ko.pureComputed(() => {
            // noinspection UnnecessaryLocalVariableJS
            return _.filter(this.licenseDetails.classifications.allSeverityLevels(), (opt) => {
                return opt.available || opt.id === this.severityLevelId();
            }) as SeverityLevel[];
        });
        this.severityLevelId = ko.observable(data.classification_severity_level_id).extend({
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("Severity level"));
                } else {
                    return false;
                }
            },
        });

        this.severityAssessmentTemplates = ko.pureComputed(() => {
            return _.filter(this.licenseDetails.classifications.allSeverityAssessmentTemplates(), (opt) => {
                return opt.available || opt.id === this.severityAssessmentTemplateId();
            }) as SeverityAssessmentTemplate[];
        });
        this.severityAssessmentTemplateId = ko.observable(data.classification_severity_assessment_template_id);
        this.severityAssessmentTemplateId.subscribe(() => {
            this.severityAssessmentPermittedScore(undefined);
        });

        this.severityAssessmentPermittedScore = ko.observable(data.classification_severity_assessment_permitted_score).extend({
            invalid: (value) => {
                if (value !== undefined && value <= 0) {
                    return getTranslation("Value must be greater than 0") + ": "
                        + getTranslation("Permitted score");
                }

                if (value > 2147483647) {
                    return getTranslation("Value can't be greater than 2147483647") + ": "
                        + getTranslation("Permitted score");
                }

                return false;
            },
        });

        this.species = ko.pureComputed(() => {
            return _.filter(this.licenseDetails.classifications.allSpecies(), (opt) => {
                return opt["status"] === "active" || opt.id === this.speciesId();
            });
        });
        this.speciesId = ko.observable(data.classification_species_id).extend({
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("Species"));
                } else {
                    return false;
                }
            },
        });

        this.classificationStrains = data.classification_strains || [];
        this.strains = ko.pureComputed(() => {
            return _.filter(this.licenseDetails.classifications.staticStrains.concat(this.licenseDetails.classifications.allStrains()), (opt: Strain) => {
                return (!opt.species_id || opt.species_id === this.speciesId()) &&
                    _.every(this.selectedStrains(), (selectedOpt) => {
                        return selectedOpt.strain_id !== opt.strain_id;
                    });
            });
        });
        this.strainData = ko.observable();  // strain selected in the drop-down

        this.selectedStrains = ko.observableArray((data.classification_strains || []).slice(0)).extend({
            invalid: (v) => {
                const wrongSpecies = _.some(v, (opt) => {
                    return opt.strain_id && opt.species_id !== this.speciesId();
                });

                if (wrongSpecies) {
                    return getTranslation("The Line / Strain can't be assigned, because the species doesn't match");
                }

                return false;
            },
        });

        this.selectedStrainIds = ko.pureComputed(() => {
            return _.map(this.selectedStrains(), (opt) => {
                return opt.strain_id;
            });
        });
        this.assignedStrains = ko.pureComputed(() => {
            return _.filter(data.classification_strain_assignments, (opt) => {
                // strain must not be a selected strain
                return _.every(this.selectedStrains(), (selectedOpt) => {
                    return selectedOpt.strain_id !== opt.strain_id;
                });
            });
        });

        this.classificationsTotal = ko.observable(data.classifications_total).extend({
            invalid: (value) => {
                if (value && !Number.isInteger(value)) {
                    return getTranslation("Invalid number");
                }

                if (value && value < 0) {
                    return getTranslation("Value must be at least 0") + ": " + getTranslation("Number of animals");
                }

                if (value && value > 2147483647) {
                    return getTranslation("Value can't be greater than 2147483647") + ": " + getTranslation("Number of animals");
                }

                if (!session.pyratConf.LICENCE_OVERUSE_LIMIT && (value || 0) < this.classificationsUsed()) {
                    return getTranslation("The number of animals must not be lower than the number of used classifications");
                }

                return false;
            },
        });
        this.classificationsUsed = ko.observable(data.classifications_used || 0);
        this.classificationsAvailable = ko.pureComputed(() => {
            if (!this.classificationsTotal.errorMessage()) {
                return (this.classificationsTotal() || 0) - this.classificationsUsed();
            }
        });

        this.classificationDescription = ko.observable(data.classification_description).extend({
            trim: true,
        });

        this.classificationProcedures = data.classification_procedures || [];
        this.procedures = ko.pureComputed(() => {
            return _.filter(this.licenseDetails.classifications.allProcedures(), (opt) => {
                return _.every(this.selectedProcedures(), (selectedOpt) => {
                    return selectedOpt.procedure_id !== opt.procedure_id;
                });
            });
        });
        this.procedureId = ko.observable();  // procedure selected in the drop-down
        this.selectedProcedures = ko.observableArray((data.classification_procedures || []).slice(0));

        this.selectedProcedureIds = ko.pureComputed(() => {
            return _.map(this.selectedProcedures(), (opt) => {
                return opt.procedure_id;
            });
        });

        this.isNew = data.is_new;
        this.isDeleted = ko.observable(false);
        this.isEmpty = ko.pureComputed(() => {
            return this.isNew &&
                !this.classificationName() &&
                (!this.severityLevelId() ||
                    (this.severityLevels().length &&
                        this.severityLevelId() === this.severityLevels()[0].id)) &&
                (!this.severityAssessmentTemplateId() ||
                    (this.severityAssessmentTemplates().length &&
                        this.severityAssessmentTemplateId() === this.severityAssessmentTemplates()[0].id)) &&
                !this.severityAssessmentPermittedScore() &&
                (!this.speciesId() ||
                    (this.species().length &&
                        this.speciesId() === this.species()[0].id)) &&
                !this.selectedStrains().length &&
                !this.classificationsTotal() &&
                !this.classificationDescription() &&
                !this.selectedProcedures().length;
        });
        this.isAssigned = !!(data.classification_strain_assignments && data.classification_strain_assignments.length);
        this.haveAvailableClassification = ko.pureComputed(() => {
            return !this.isDeleted() && !this.isEmpty();
        });

        this.requiredFields = ko.pureComputed(() => {
            return {
                classificationName: this.haveAvailableClassification(),
                severityLevelId: this.haveAvailableClassification(),
                speciesId: this.haveAvailableClassification(),
                severityAssessmentPermittedScore: this.severityAssessmentTemplateId(),
            };
        });

        this.errorMessage = ko.pureComputed(() => {
            return this.licenseDetails.getErrorMessage([
                "classificationName",
                "severityLevelId",
                "severityAssessmentPermittedScore",
                "speciesId",
                "classificationsTotal",
                "selectedStrains",
            ], this);
        }).extend({
            deferred: true,
        });
    }

    public addStrain = () => {

        if (!this.strainData() || !this.strainData().length) {
            // nothing selected, do nothing
            return;
        }

        if (!this.strainData()[0].strain_id && this.strainData()[0].strain_id !== 0) {
            // 'All' entry was selected
            this.selectedStrains.removeAll();
        } else {
            // search assigned strains first
            const selectedOption = _.find([...this.assignedStrains(), ...this.strains()], (opt: Strain) => {
                return opt.strain_id === this.strainData()[0].strain_id;
            });

            if (selectedOption) {
                if (!Object.prototype.hasOwnProperty.call(selectedOption, "isNew")) {
                    // do not need to copy the object like for the procedures,
                    // because selectem seems to create copies of the options

                    selectedOption.isNew = ko.pureComputed(() => {
                        return _.every(this.classificationStrains, (preselectedOption) => {
                            return preselectedOption.strain_id !== selectedOption.strain_id;
                        });
                    });
                }
                this.selectedStrains.splice(0, 0, selectedOption);
            }
        }
    };

    public removeStrain = (data: Strain) => {
        this.selectedStrains.remove(data);
    };

    public addProcedure = () => {
        let selectedOption = _.find(this.procedures(), (opt) => {
            return opt.procedure_id === this.procedureId();
        }) as ClassificationProcedureAssignment;

        if (selectedOption) {
            if (!Object.prototype.hasOwnProperty.call(selectedOption, "isNew")) {
                // copy the object, because the options are shared amongst
                // all the classifications procedures and every classification
                // needs their own calculation whether a procedure was newly added
                selectedOption = { ...selectedOption };

                selectedOption.isNew = ko.pureComputed(() => {
                    return _.every(this.classificationProcedures, (preselectedOption) => {
                        return preselectedOption.procedure_id !== selectedOption.procedure_id;
                    });
                });
            }
            this.selectedProcedures.splice(0, 0, selectedOption);
        }
    };

    public removeProcedure = (data: ClassificationProcedureAssignment & { isNew: ko.Subscribable<boolean> }) => {
        this.selectedProcedures.remove(data);
    };

    public copyClassification = () => {
        this.licenseDetails.classifications.addClassification({
            classification_name: null,
            classification_procedures: this.classificationProcedures.slice(),
            classification_severity_level_id: this.severityLevelId(),
            classification_severity_assessment_template_id: this.severityAssessmentTemplateId(),
            classification_severity_assessment_permitted_score: this.severityAssessmentPermittedScore(),
            classification_species_id: this.speciesId(),
            classification_strain_assignments: [],
            classification_strains: this.classificationStrains.slice(),
            classifications_total: this.classificationsTotal(),
            classification_description: this.classificationDescription(),
        });
    };

    public toggleEdit = () => {
        this.editMode(!this.editMode());
    };

    public toggleDelete = () => {
        if (this.isEmpty()) {
            this.licenseDetails.classifications.classifications.remove(this);
        } else {
            this.isDeleted(!this.isDeleted());
        }
    };
}

class LicenseDetailClassifications {

    public licenseDetails: LicenseDetailsViewModel;
    public classifications: ko.ObservableArray<LicenseClassificationDetails>;
    public allSpecies: ko.ObservableArray<Species>;
    public allSeverityLevels: ko.ObservableArray<SeverityLevel>;
    public allSeverityAssessmentTemplates: ko.ObservableArray<SeverityAssessmentTemplate>;
    public allStrains: ko.ObservableArray<Strain>;
    public allProcedures: ko.ObservableArray<Procedure>;
    public staticStrains: Strain[];
    public enableAddClassification: ko.PureComputed<boolean>;
    public errorMessage: ko.PureComputed<string | false>;
    private newClassificationCounter: number;

    constructor(licenseDetails: LicenseDetailsViewModel) {

        this.licenseDetails = licenseDetails;

        this.newClassificationCounter = 1;
        this.classifications = ko.observableArray();

        this.allSpecies = ko.observableArray();
        this.allSeverityLevels = ko.observableArray();
        this.allSeverityAssessmentTemplates = ko.observableArray();
        this.allStrains = ko.observableArray();
        this.allProcedures = ko.observableArray();
        this.staticStrains = [
            {
                strain_id: undefined,
                species_id: undefined,
                group: undefined,
                strain_name_with_id: getTranslation("All"),
            },
            {
                strain_id: 0,
                species_id: undefined,
                group: undefined,
                strain_name_with_id: getTranslation("None"),
            },
        ];

        this.enableAddClassification = ko.pureComputed(() => {
            return _.every(this.classifications(), (classification) => {
                // there must be no empty classification
                return !classification.isEmpty();
            });
        });

        this.errorMessage = ko.pureComputed(() => {
            const availableClassifications = _.filter(this.classifications(), (c) => {
                return !(c.isDeleted() || c.isEmpty());
            });

            if (licenseDetails.haveSubmittedLicense() &&
                session.pyratConf.LICENSE_SUBMIT_REQUIRE_CLASSIFICATION &&
                !availableClassifications.length) {
                return getTranslation("Please add at least one classification");
            }

            return _.reduce(this.classifications(), (msg: string, c: LicenseClassificationDetails) => {
                return msg || c.errorMessage();
            }, "");
        }).extend({
            deferred: true,
        });
    }



    public addClassification = (data: Partial<Omit<Classification, "is_new" | "classification_id" | "license_id" | "classifications_available" | "classifications_used">> = {}) => {
        const newClassification = new LicenseClassificationDetails(this.licenseDetails, {
            license_id: undefined,
            classification_id: (this.newClassificationCounter++),
            classification_name: null,
            classification_procedures: [],
            classification_severity_level_id: null,
            classification_severity_assessment_template_id: null,
            classification_severity_assessment_permitted_score: null,
            classification_species_id: null,
            classification_strain_assignments: [],
            classification_strains: [],
            classifications_available: null,
            classifications_total: null,
            classifications_used: null,
            classification_description: null,
            is_new: true,
            ...data,
        });
        this.classifications.splice(0, 0, newClassification);
        window.top.document.querySelector(".classifications .unsaved")?.scrollIntoView({ behavior: "smooth" });
    };

    public load = (seed: Seed) => {

        // classification options
        this.allSpecies(seed.species || undefined);
        this.allSeverityLevels(seed.severity_levels || undefined);
        this.allSeverityAssessmentTemplates(seed.severity_assessment_templates || undefined);
        this.allStrains(seed.strains || []);
        this.allProcedures(seed.procedures || undefined);

        // classification data
        this.newClassificationCounter = 1;
        this.classifications(_.map(seed.classifications, (classification) => {
            return new LicenseClassificationDetails(this.licenseDetails, classification);
        }));
    };
}

class EuStatistics {

    public licenseDetails: LicenseDetailsViewModel;
    public euYesNo: ko.ObservableArray<EuYesNo>;
    public euUses: ko.ObservableArray<EuUse>;
    public euPurposes: ko.ObservableArray<EuPurpose>;
    public euLegislations: ko.ObservableArray<EuLegislation>;
    public euLegislativeRequirements: ko.ObservableArray<EuLegislativeRequirement>;
    public euOtherPurposes: ko.ObservableArray<string>;
    public euOtherLegislations: ko.ObservableArray<string>;
    public forBreeding: ko.Observable<number>;
    public toReport: ko.Observable<number>;
    public intendedUse: CheckExtended<ko.Observable<string>>;
    public createNewStrain: ko.Observable<number>;

    public purpose: CheckExtended<ko.Observable<string>>;
    public haveRegulatoryPurpose: ko.PureComputed<boolean>;
    public haveOtherPurpose: ko.PureComputed<boolean>;
    public otherPurpose: ko.Observable<string>;

    public legislation: ko.Observable<string>;
    public haveOtherLegislation: ko.PureComputed<boolean>;
    public otherLegislation: ko.Observable<string>;

    public legislativeRequirement: ko.Observable<string>;

    public euStatisticsRequired: ko.PureComputed<boolean>;
    public enabledFields: ko.PureComputed<{ [key: string]: boolean }>;
    public requiredFields: ko.PureComputed<{ [key: string]: boolean }>;
    public errorMessage: ko.PureComputed<string | false>;

    constructor(licenseDetails: LicenseDetailsViewModel) {

        this.licenseDetails = licenseDetails;
        this.euYesNo = ko.observableArray();
        this.euUses = ko.observableArray();
        this.euPurposes = ko.observableArray();
        this.euLegislations = ko.observableArray();
        this.euLegislativeRequirements = ko.observableArray();
        this.euOtherPurposes = ko.observableArray();
        this.euOtherLegislations = ko.observableArray();

        this.forBreeding = ko.observable();
        this.toReport = ko.observable();
        this.intendedUse = ko.observable().extend({
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("EU statistics: Use"));
                } else {
                    return false;
                }
            },
        });
        this.createNewStrain = ko.observable();
        this.createNewStrain.subscribe((newVal) => {
            ko.utils.arrayForEach(this.euPurposes() || [], (purpose) => {
                // when creating new strain, show only "Basic Research" and "Transl/Appl Research" purposes
                purpose.disabled(newVal && purpose.id.charAt(1) !== "B" && purpose.id.charAt(1) !== "T");
            });
            this.purpose.valueHasMutated();  // it needs to check again if a valid purpose is selected
        });
        this.purpose = ko.observable().extend({
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("EU statistics: Purpose"));
                } else if (this.euPurposes().some((item) => { return item.id === value && item.disabled(); })) {
                    return getTranslation("Please select a different purpose or specify to not create a new genetic Line / Strain");
                }

                return false;
            },
        });
        this.haveRegulatoryPurpose = ko.pureComputed(() => {
            return this.purpose() && this.purpose().charAt(1) === "R";
        });
        this.haveOtherPurpose = ko.pureComputed(() => {
            return _.includes(this.euOtherPurposes(), this.purpose());
        });
        this.otherPurpose = ko.observable().extend({
            trim: true,
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("EU statistics: Other purpose"));
                } else {
                    return false;
                }
            },
        });
        this.legislation = ko.observable().extend({
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("EU statistics: Testing by legislation"));
                } else {
                    return false;
                }
            },
        });
        this.haveOtherLegislation = ko.pureComputed(() => {
            return _.includes(this.euOtherLegislations(), this.legislation());
        });
        this.otherLegislation = ko.observable().extend({
            trim: true,
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("EU statistics: Other legal foundation"));
                } else {
                    return false;
                }
            },
        });
        this.legislativeRequirement = ko.observable().extend({
            invalid: (value) => {
                if (!value) {
                    return getTranslation("Please fill in: %s").replace("%s", getTranslation("EU statistics: Legislative requirements"));
                } else {
                    return false;
                }
            },
        });

        this.euStatisticsRequired = ko.pureComputed(() => {
            // when the license is to be reported and when at least some field was set
            return !!(
                this.toReport() &&
                (this.forBreeding() !== 0 ||
                    this.intendedUse() ||
                    this.createNewStrain() !== 0 ||
                    this.purpose()));  // other fields will never be set when purpose is not set
        });

        this.enabledFields = ko.pureComputed(() => {
            // the other fields are always enabled (only depending on licenseDetails.enableInput)
            return {
                otherPurpose: this.haveOtherPurpose(),
                legislation: this.haveRegulatoryPurpose(),
                otherLegislation: this.haveRegulatoryPurpose() && this.haveOtherLegislation(),
                legislativeRequirement: this.haveRegulatoryPurpose(),
            };
        });
        this.requiredFields = ko.pureComputed(() => {
            return {
                intendedUse: session.pyratConf.LICENSE_SET_INTENDED_USE,
                purpose: true,
                otherPurpose: this.enabledFields().otherPurpose,
                legislation: this.enabledFields().legislation,
                otherLegislation: this.enabledFields().otherLegislation,
                legislativeRequirement: this.enabledFields().legislativeRequirement,
            };
        });

        this.errorMessage = ko.pureComputed(() => {
            // need to call `getErrorMessage` regardless of `euStatisticsRequired`
            // in order to get all error messages/markings updated
            const msg = this.licenseDetails.getErrorMessage([
                "intendedUse",
                "purpose",
                "otherPurpose",
                "legislation",
                "otherLegislation",
                "legislativeRequirement",
            ], this);

            if (this.euStatisticsRequired()) {
                return msg;
            }

            return false;
        }).extend({
            deferred: true,
        });
    }

    public stylePurposes = (opt: HTMLElement, item: EuPurpose) => {
        // mark the "Other" purposes in different color (they require further input in a text field)
        if (item && _.includes(this.euOtherPurposes(), item.id)) {
            opt.className = "other_option";
        }

        // disable all purposes that are not selectable
        ko.applyBindingsToNode(opt, { disable: item ? item.disabled : false }, item);
    };

    public styleLegislation = (opt: HTMLElement, item: EuLegislation) => {
        // mark the "Other" legislations in different color (they require further input in a text field)
        if (item && _.includes(this.euOtherLegislations(), item.id)) {
            opt.className = "other_option";
        }
    };

    public load = (seed: Seed) => {

        // EU statistical data options
        this.euYesNo(seed.eu_yes_no || undefined);
        this.euUses(seed.eu_uses || undefined);
        this.euPurposes(seed.eu_purposes ? seed.eu_purposes.map((purpose) => {
            purpose.disabled = ko.observable(false);
            return purpose;
        }) : undefined);
        this.euLegislations(seed.eu_legislations || undefined);
        this.euLegislativeRequirements(seed.eu_legislative_requirements || undefined);
        this.euOtherPurposes(seed.eu_other_purposes || undefined);
        this.euOtherLegislations(seed.eu_other_legislations || undefined);

        // EU statistical data
        this.forBreeding(seed.for_breeding || 0);
        // licenses without any EU statistical data are always in the EU statistics report
        this.toReport(seed.to_report || (seed.to_report === 0 ? 0 : 1));
        this.intendedUse(seed.license_use_code || null);
        this.createNewStrain(seed.license_new_strain || 0);
        this.purpose(seed.license_purpose_code || null);
        this.otherPurpose(seed.license_other_purpose || null);
        this.legislation(seed.license_legislation_code || null);
        this.otherLegislation(seed.license_other_legislation || null);
        this.legislativeRequirement(seed.license_legislative_requirement_code || null);
    };
}

class LicenseDetailsViewModel {

    public params: Params;
    public dialog: HtmlDialog;
    public highlightEventTypeIds: number[];
    public licenseId: ko.Observable<number>;
    public visibleTab: ko.Observable<string>;
    public reloadRequired: ko.Observable<boolean>;
    public asyncInProgress: ko.Observable<boolean>;
    public triedSave: ko.Observable<boolean>;
    public triedSubmit: ko.Observable<boolean>;
    public maxLength: ko.Observable<Seed["max_length"]>;
    public haveSubmittedLicense: ko.PureComputed<boolean>;
    public enableInput: ko.PureComputed<boolean>;
    public enableButtons: ko.PureComputed<boolean>;
    public seed: FetchExtended<ko.Observable<AjaxResponse<Seed>>>;
    public currentUserIsLeader: ko.Observable<boolean>;
    public allowEdit: ko.Observable<boolean>;
    public allowEditForSignOff: ko.Observable<boolean>;
    public allowSubmit: ko.Observable<boolean>;
    public allowDelete: ko.Observable<boolean>;
    public allowAddDocuments: ko.Observable<boolean>;
    public isSubmitted: ko.Subscribable<boolean>;
    public canSetGovernmentId: ko.PureComputed<boolean>;
    public commentData: ko.Observable<CommentWidgetSeed>;
    public attributes: LicenseDetailAttributesModel;
    public permissions: LicenseDetailPermissions;
    public classifications: LicenseDetailClassifications;
    public euStatistics: EuStatistics;
    public historyData: ko.ObservableArray<HistoryRecord>;
    public tabs: {
        visible: ko.MaybeSubscribable<boolean>;
        errorMessage?: ko.Subscribable<string | false>;
        label: string;
        key: string;
    }[];
    public errorMessage: ko.PureComputed<string | false>;

    constructor(dialog: HtmlDialog, params: Params) {

        this.params = params;
        this.dialog = dialog;
        this.highlightEventTypeIds = [
            102,  // see SubmitEvent.event_type_id (@license_event.py)
            104,  // see GrantEvent.event_type_id (@license_event.py)
        ];

        this.licenseId = ko.observable(params.licenseId);
        this.asyncInProgress = ko.observable(false);
        this.reloadRequired = ko.observable(false);
        this.triedSave = ko.observable(false);
        this.triedSubmit = ko.observable(false);
        this.visibleTab = ko.observable("attributes");  // start out with 'Attributes' tab when details are opened

        this.dialog.addOnClose(() => {
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        if (typeof params.onClose === "function") {
            this.dialog.addOnClose(params.onClose);
        }

        this.maxLength = ko.observable({});

        this.haveSubmittedLicense = ko.pureComputed(() => {
            // do we check the required fields for a submitted license or just for a draft
            return this.triedSubmit() || this.isSubmitted();
        });

        this.enableInput = ko.pureComputed(() => {
            return this.allowEdit() &&
                !this.asyncInProgress() &&
                !this.attributes.documentAsyncInProgress();
        });

        this.enableButtons = ko.pureComputed(() => {
            return !this.asyncInProgress() &&
                !this.attributes.documentAsyncInProgress();
        });

        this.attributes = new LicenseDetailAttributesModel(this);
        this.permissions = new LicenseDetailPermissions(this);
        this.classifications = new LicenseDetailClassifications(this);
        this.euStatistics = new EuStatistics(this);
        this.commentData = ko.observable();
        this.historyData = ko.observableArray();

        this.seed = ko.observable().extend({
            deferred: true,
            fetch: (signal) => {
                return fetch(cgiScript("license_list.py"), {
                    method: "POST",
                    body: getFormData({
                        get_details: String(this.licenseId()),
                    }),
                    signal,
                });
            },
        });
        this.seed.subscribe((data) => {
            if (data.success) {

                this.licenseId(data.license_id);
                this.triedSave(false);
                this.triedSubmit(false);

                // dialog properties
                this.dialog.setTitle(data.popup_title);
                this.maxLength(data.max_length || {});

                // permissions of current session user
                this.currentUserIsLeader(data.current_user_is_leader || false);
                this.allowEdit(data.current_user_allow_edit || false);
                this.allowEditForSignOff(data.current_user_allow_edit_for_sign_off || false);
                this.allowSubmit(data.current_user_allow_submit || false);
                this.allowDelete(data.current_user_allow_delete || false);
                this.allowAddDocuments(data.current_user_allow_add_documents || false);

                this.isSubmitted(data.is_submitted || false);

                this.attributes.load(data);
                this.permissions.load(data);
                this.classifications.load(data);
                this.euStatistics.load(data);

                // comments
                this.commentData(data.comment_widget_seed || undefined);

                // history
                this.historyData(data.history || undefined);

            } else {
                // error, probably the user cannot view the license,
                // let page be reloaded in order to get the license out of the list
                this.reloadRequired(true);
            }

            if (data.message) {
                notifications.showNotification(data.message, data.success ? "success" : "error");
            }
        });

        // permissions of current session user
        this.currentUserIsLeader = ko.observable();
        this.allowEdit = ko.observable();
        this.allowEditForSignOff = ko.observable();
        this.allowSubmit = ko.observable();
        this.allowDelete = ko.observable();
        this.allowAddDocuments = ko.observable();
        this.isSubmitted = ko.observable();
        this.canSetGovernmentId = ko.pureComputed( () => !this.licenseId() || session.userPermissions.license_set_government_id);

        // tab definitions

        this.tabs = [
            {
                key: "attributes",
                label: getTranslation("Attributes"),
                errorMessage: this.attributes.errorMessage,
                visible: true,
            }, {
                key: "permissions",
                label: getTranslation("Permissions"),
                errorMessage: this.permissions.errorMessage,
                visible: true,
            }, {
                key: "classifications",
                label: getTranslation("Classifications"),
                errorMessage: this.classifications.errorMessage,
                visible: true,
            }, {
                key: "eu_statistics",
                label: getTranslation("EU statistical data"),
                errorMessage: this.euStatistics.errorMessage,
                visible: true,
            }, {
                key: "comments",
                label: getTranslation("Comments"),
                visible: ko.pureComputed(() => {
                    return this.licenseId() && session.pyratConf.IACUC_LICENCE_QC_AND_GUIDANCE;
                }),
            }, {
                key: "history",
                label: getTranslation("History"),
                visible: ko.pureComputed(() => !!this.licenseId()),
            },
        ];

        // buttons in pop-up footer

        this.errorMessage = ko.pureComputed(() => {
            const tabsWithErrors: string[] = [];
            let errorMessage: string | false = false;

            if (this.enableInput()) {
                errorMessage = _.reduce(this.tabs, (msg, tab) => {
                    const tabVisible = ko.isObservable(tab.visible) ? tab.visible() : tab.visible;

                    if (tabVisible && tab.errorMessage && tab.errorMessage()) {
                        if (!msg && this.visibleTab() === tab.key) {
                            return tab.errorMessage();
                        }

                        tabsWithErrors.push(tab.label);
                    }

                    return msg;
                }, false);

                if (!errorMessage && tabsWithErrors.length) {
                    errorMessage = getTranslation("Errors in tabs: %s").replace("%s", tabsWithErrors.join(", "));
                }
            }

            return errorMessage;
        }).extend({
            deferred: true,
        });

    }

    /**
     * Get error message of fields
     *
     * @param checkFields - in which order to check the fields and bring up error messages
     * @param model - data model where fields are stored
     * @returns - firest error message of the fields when they are required
     */
    public getErrorMessage = (checkFields: string[], model: any): string => {
        const showMessage = (field: string) => {
            // show empty errors, or if the value is not empty show other error messages!
            return (this.triedSave() && model.requiredFields()[field])
                || model[field]() || model[field]() === 0;
        };

        _.forEach(checkFields, (field) => {
            // need to notify subscribers first so that error messages are
            // generated for fields that have not been modified yet
            if (showMessage(field)) {
                model[field].notifySubscribers(model[field]());
            }
        });

        return _.reduce(checkFields, (msg, field) => {
            if (showMessage(field)) {
                return msg || model[field].errorMessage();
            }

            return msg;
        }, false);
    };

    public reseedComments = (seed: CommentWidgetSeed) => {
        this.commentData(seed);
        this.reloadRequired(true);
    };

    public historyMetaLabel = (key: string) => {
        const labels: { [key: string]: string } = {
            actual_date: getTranslation("Date"),
            "status": getTranslation("Status"),
            global_status: getTranslation("Status"),
            license_number: getTranslation("Number"),
            license_title: getTranslation("Title"),
            license_type: getTranslation("License type"),
            description: getTranslation("Description"),
            experiment_types: getTranslation("Experiment types"),
            government_id: getTranslation("Government ID"),
            valid_from: getTranslation("Valid from"),
            valid_to: getTranslation("Valid to"),
            project_label: getTranslation("Project"),
            budget: getTranslation("Budget"),
            document_details: getTranslation("Document"),
            guidance: getTranslation("Guidance and conditions"),
            all_leaders: getTranslation("Project leaders"),
            all_avail: getTranslation("Permitted users"),
            user_fullname: getTranslation("User"),
            group_name: getTranslation("User group"),
            classification_name_current: getTranslation("Current name"),
            classification_name: getTranslation("Name"),
            severity_level: getTranslation("Severity level"),
            severity_assessment_template: getTranslation("Severity assessment template"),
            severity_assessment_permitted_score: getTranslation("Permitted score"),
            species: getTranslation("Species"),
            classifications_total: getTranslation("Number of animals"),
            procedure_name: getTranslation("Procedure"),
            strain_name_with_id: getTranslation("Line / Strain"),
            sign_off_step: getTranslation("Step"),
        };

        return (_.has(labels, key) && labels[key]) || key;
    };

    public historyChangeLabelOrNone = (key: string) => {
        return _.includes([
            "license_type",
            "project_label",
            "budget",
            "severity_level",
            "severity_assessment_template",
            "species",
            "strain_name_with_id",
        ], key);
    };

    public historyChangeBoolean = (key: string) => {
        return _.includes([
            "all_leaders",
            "all_avail",
        ], key);
    };

    public historyChangeNormal = (key: string) => {
        return !this.historyChangeLabelOrNone(key) &&
            !this.historyChangeBoolean(key) &&
            key !== "document_details";
    };

    public closePopup = () => {
        this.dialog.close();
    };

    public sendData = (form: FormData, successCallback: (response: any) => void) => {
        this.asyncInProgress(true);
        fetch(cgiScript("license_list.py"), { method: "POST", body: form })
            .then(response => response.json())
            .then((response) => {
                this.asyncInProgress(false);
                if (response.success) {
                    successCallback(response);
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
            }).finally(() => {
                this.asyncInProgress(false);
            });
    };

    public deleteLicense = () => {
        notifications.showConfirm(
            getTranslation("Are you sure you want to delete this license?"),
            () => {
                this.sendData(getFormData({
                    delete_license: String(this.licenseId()),
                }), () => {
                    this.reloadRequired(true);
                    this.closePopup();
                    notifications.showNotification(getTranslation("License deleted"), "success");
                });
            },
        );
    };

    public saveLicense = () => {
        this.triedSave(true);
        this.triedSubmit(false);

        if (!this.errorMessage()) {

            const form = getFormData();
            if (this.allowEdit()) {
                form.append("save_license", this.serialize(false));
            } else if (this.allowEditForSignOff()) {
                form.append("save_guidance_as_sign_off_user", this.serializeGuidance());
            }

            this.sendData(form, (response) => {
                this.reloadRequired(true);
                this.licenseId(response.license_id);
                this.seed.forceReload();
                notifications.showNotification(getTranslation("Changes saved"), "success");
            });
        }
    };

    public submitLicense = () => {
        notifications.showConfirm(
            getTranslation("Are you sure you want to submit this license?"),
            () => {
                this.triedSave(true);
                this.triedSubmit(true);

                if (!this.errorMessage()) {
                    this.sendData(getFormData({
                        save_license: this.serialize(true),
                    }), () => {
                        this.reloadRequired(true);
                        this.closePopup();
                        notifications.showNotification(getTranslation("Changes saved"), "success");
                    });
                }
            },
        );
    };

    private serialize = (forSubmit: boolean): string => {
        const data: { [key: string]: any } = {
            license_type_id: this.attributes.licenseTypeId(),
            license_number: this.attributes.licenseNumber() || null,
            license_title: this.attributes.licenseTitle(),
            description: this.attributes.description() || null,
            project_id: this.attributes.projectId(),
            valid_from: this.attributes.validFrom(),
            valid_to: this.attributes.validTo(),
            experiment_types: this.attributes.experimentTypes() || null,
            budget_id: this.attributes.budgetId(),
            document_ids: this.attributes.newDocumentIds(),
            all_leaders: !this.permissions.restrictLeadership(),
            all_avail: !this.permissions.restrictPermissions(),
            leaders: this.permissions.restrictLeadership() ? this.permissions.leaders.selectedIds() : [],
            classifications: _.chain(this.classifications.classifications())
                .filter((classification) => {
                    return !classification.isEmpty() && (!classification.isNew || !classification.isDeleted());
                })
                .sortBy(function (classification) {
                    return !classification.isNew;
                })
                .sortBy(function (classification) {
                    return classification.newClassificationId;
                })
                .map((classification) => {
                    if (classification.isDeleted()) {
                        return {
                            classification_id: classification.classificationId,
                            deleted: true,
                        };
                    } else {
                        return {
                            classification_id: classification.isNew ? undefined : classification.classificationId,
                            classification_name: classification.classificationName(),
                            classification_severity_level_id: classification.severityLevelId(),
                            classification_severity_assessment_template_id: classification.severityAssessmentTemplateId(),
                            classification_severity_assessment_permitted_score: classification.severityAssessmentPermittedScore(),
                            classification_species_id: classification.speciesId(),
                            classification_strains: classification.selectedStrainIds(),
                            classifications_total: classification.classificationsTotal() || 0,
                            classification_description: classification.classificationDescription() || null,
                            classification_procedures: classification.selectedProcedureIds(),
                        };

                    }
                })
                .value(),
            for_breeding: this.euStatistics.forBreeding(),
            to_report: this.euStatistics.toReport(),
            license_new_strain: this.euStatistics.createNewStrain(),
            license_purpose_code: this.euStatistics.purpose(),
            license_other_purpose: this.euStatistics.enabledFields().otherPurpose ?
                this.euStatistics.otherPurpose() : null,
            license_legislation_code: this.euStatistics.enabledFields().legislation ?
                this.euStatistics.legislation() : null,
            license_other_legislation: this.euStatistics.enabledFields().otherLegislation ?
                this.euStatistics.otherLegislation() : null,
            license_legislative_requirement_code: this.euStatistics.enabledFields().legislativeRequirement ?
                this.euStatistics.legislativeRequirement() : null,
        };

        if (this.licenseId()) {
            data.license_id = this.licenseId();  // license will be created new when ID is not specified
        }

        if (forSubmit) {
            data.global_status = "submitted";
        }

        if (this.canSetGovernmentId()) {
            data.government_id = this.attributes.governmentId() || null;
        }

        if (session.pyratConf.IACUC_LICENCE_QC_AND_GUIDANCE) {
            data.guidance = _.chain(this.attributes.guidance()).filter((guidance) => {
                return Object.prototype.hasOwnProperty.call(guidance, "isDeleted") && !guidance.isDeleted();
            }).reverse().map((guidance) => {
                return guidance.content;
            }).value();
        }

        if (session.pyratConf.IACUC_LICENCE_PRIMARY_USER_ASSIGNMENT) {
            data.permitted_primaries = this.permissions.restrictPermissions() ? this.permissions.primaries.selectedIds() : [];
        }

        if (session.pyratConf.IACUC_LICENCE_INDIVIDUAL_USER_ASSIGNMENT) {
            data.permitted_individuals = this.permissions.restrictPermissions() ? this.permissions.individuals.selectedIds() : [];
        }

        if (session.pyratConf.IACUC_LICENCE_GROUPS_ASSIGNMENT) {
            data.permitted_groups = this.permissions.restrictPermissions() ? this.permissions.userGroups.selectedIds() : [];
        }

        if (session.pyratConf.LICENSE_SET_INTENDED_USE) {
            data.license_use_code = this.euStatistics.intendedUse();
        }

        return JSON.stringify(data, (k, v) => {
            return v === undefined ? null : v;
        });
    };

    private serializeGuidance = (): string => {
        const data: { [key: string]: any } = {
            license_id: this.licenseId(),
        };

        if (session.pyratConf.IACUC_LICENCE_QC_AND_GUIDANCE) {
            data.guidance = _.chain(this.attributes.guidance()).filter((guidance) => {
                return Object.prototype.hasOwnProperty.call(guidance, "isDeleted") && !guidance.isDeleted();
            }).reverse().map((guidance) => {
                return guidance.content;
            }).value();
        }

        return JSON.stringify(data, (k, v) => {
            return v === undefined ? null : v;
        });
    };
}

export const showLicenseDetails = htmlDialogStarter(LicenseDetailsViewModel, licenseDetailTemplate, {
    name: "LicenseDetails",
    width: 900,
    height: "auto",
    position: {
        inset: {
            top: 20,
            right: 20,
        },
    },
    closeOthers: true,
});
