/**
 * Show a popup to set the generation for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";

import {
    AnimalsService,
    CancelablePromise,
    GetAnimalGenerationResponse,
    PupsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";
import { EmptyObject } from "../lib/utils";

import template from "./setGeneration.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetGenerationViewModel {
    private readonly dialog: HtmlDialog;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly subGeneration1: Observable<string>;
    private readonly newGeneration1: Observable<string>;
    private readonly subGeneration2: Observable<string>;
    private readonly newGeneration2: Observable<string>;
    private readonly additionalGeneration: Observable<string>;
    private readonly generationValid: Observable<boolean>;
    private readonly generationErrorMessages: ObservableArray<string>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly fetchInProgress = ko.observable(true);
    private readonly submitInProgress= ko.observable(false);

    constructor(dialog: HtmlDialog, params: Params) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.subGeneration1 = ko.observable();
        this.newGeneration1 = ko.observable();
        this.subGeneration2 = ko.observable();
        this.newGeneration2 = ko.observable();
        this.additionalGeneration = ko.observable();
        this.generationValid = ko.observable(true);
        this.generationErrorMessages = ko.observableArray([]);

        let request: CancelablePromise<GetAnimalGenerationResponse>;
        if (this.animalId) {
            request = AnimalsService.getAnimalGeneration({ animalId: this.animalId });
        } else if (this.pupId) {
            request = PupsService.getPupGeneration({ pupId: this.pupId });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then((response) => {
                this.newGeneration1(response.generation);
                this.subGeneration1(response.sub_generation);
                this.additionalGeneration(response.additional_generation);
                this.newGeneration2(response.generation_2);
                this.subGeneration2(response.sub_generation_2);
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() || this.fetchInProgress())
                && this.generationValid();
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => this.dialog.close();

    public submit = () => {
        this.submitInProgress(true);
        this.generationErrorMessages([]);

        let request: CancelablePromise<EmptyObject>;
        const requestBody = {
            generation: this.newGeneration1(),
            sub_generation: this.subGeneration1(),
            additional_generation: this.additionalGeneration(),
            generation_2: this.newGeneration2(),
            sub_generation_2: this.subGeneration2(),
        };

        if (this.animalId) {
            request = AnimalsService.setAnimalGeneration({
                animalId: this.animalId,
                requestBody: requestBody,
            });
        } else if (this.pupId) {
            request = PupsService.setPupGeneration({
                pupId: this.pupId,
                requestBody: requestBody,
            });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then(() => {
                this.dialog.close();
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
                notifications.showNotification(getTranslation("Generation updated"), "success");
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };
}

export const showSetGeneration = htmlDialogStarter(SetGenerationViewModel, template, params => ({
    name: "SetGeneration",
    width: 400,
    title: params.title,
    position: { anchor: params.eventTarget },
}));
