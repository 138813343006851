import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import {
    isNumber,
    isUndefined,
} from "lodash";

import {
    IdNameProperty,
    ListFilterDefinition,
} from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListFilterMutationsItem,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    normalizeDate,
} from "../../lib/utils";

export const TankListFilterModel = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {
        comment: ListFilterItem,
        crossing_id: ListFilterItem,
        page_size: ListFilterItem,
        release_tank_id: ListFilterItem,
        neighbor_of_tank_id: ListFilterItem,
        reply_pending: ListFilterItem,
        reprint_required: ListFilterItem,
        sacrifice_comment: ListFilterItem,
        tank_id: ListFilterItem,
        tank_label: ListFilterItem,
        tank_position: ListFilterItem,
        generation: ListFilterItem,

        age_level: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.label);
            }
        },

        /* Youngest animal in days range (integers only) */
        animal_age_days_min_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        return !(
                            ((isNumber(v) && String(v).match(/^\d+$/)) || isUndefined(v))
                            && (v || 0) >= 0
                        );
                    },
                });
            }

            public valid = () => this.value.isValid();
        },

        animal_age_days_min_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        return !(
                            ((isNumber(v) && String(v).match(/^\d+$/)) || isUndefined(v))
                            && (v || 0) >= 0
                        );
                    },
                });
            }

            public valid = () => this.value.isValid();
        },

        /* Youngest animal in months range (integers only) */
        animal_age_months_min_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        return !(
                            ((isNumber(v) && String(v).match(/^\d+$/)) || isUndefined(v))
                            && (v || 0) >= 0
                        );
                    },
                });
            }

            public valid = () => this.value.isValid();
        },

        animal_age_months_min_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        return !(
                            ((isNumber(v) && String(v).match(/^\d+$/)) || isUndefined(v))
                            && (v || 0) >= 0
                        );
                    },
                });
            }

            public valid = () => this.value.isValid();
        },


        /* number of animals (integers only) */
        min_alive_count: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        return !(
                            ((isNumber(v) && String(v).match(/^\d+$/)) || isUndefined(v))
                            && (v || 0) >= 0
                        );
                    },
                });
            }

            public valid = () => this.value.isValid();
        },

        max_alive_count: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        return !(
                            ((isNumber(v) && String(v).match(/^\d+$/)) || isUndefined(v))
                            && (v || 0) >= 0
                        );
                    },
                });
            }

            public valid = () => this.value.isValid();
        },

        /* average death rate in animals per day*/
        animal_average_death_rate_min: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        return !((isNumber(v) || isUndefined(v)) && (v || 0) >= 0);
                    },
                });
            }

            public valid = () => this.value.isValid();
        },

        animal_average_death_rate_max: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => {
                        return !((isNumber(v) || isUndefined(v)) && (v || 0) >= 0);
                    },
                });
            }

            public valid = () => this.value.isValid();
        },

        assign_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("assign_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        assign_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("assign_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        birth_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("birth_date_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        birth_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("birth_date_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        comment_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        comment_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        crossing_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("crossing_date_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        crossing_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("crossing_date_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        export_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable<string>>;
            private disable: PureComputed<boolean>;

            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("export_date_to"), compareFromDate),
                });
                this.disable = ko.pureComputed(() => {
                    return (
                        filter.allFilters().status?.model.serialize() &&
                        filter.allFilters().status.model.serialize() !== "exported"
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        export_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable<string>>;
            private disable: PureComputed<boolean>;

            constructor(value: Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("export_date_from"), compareToDate),
                });
                this.disable = ko.pureComputed(() => {
                    return (
                        filter.allFilters().status?.model.serialize() &&
                        filter.allFilters().status.model.serialize() !== "exported"
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        export_institution_id: class extends ListFilterItem {
            private disable: PureComputed<boolean>;

            constructor(value: Observable<number>, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
                this.disable = ko.pureComputed(() => {
                    return (
                        filter.allFilters().status?.model.serialize() &&
                        filter.allFilters().status.model.serialize() !== "exported"
                    );
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
        },

        license_id: class extends ListFilterItem {
            public readonly possibleValues: {id: number; name: string}[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
                this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(seed.possible_values);
            }
        },

        medical_condition_id: class extends ListFilterItem {
            public readonly possibleValues: {id: number; name: string}[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
                this.possibleValues = [{ id: 0, name: getTranslation("Normal") }].concat(seed.possible_values);
            }
        },

        min_last_modified_date: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("max_last_modified_date"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        max_last_modified_date: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("min_last_modified_date"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        mutation_grade: class extends ListFilterMutationsItem {
            strainNameOrIdFilter = ko.pureComputed(() => {
                return filter.allFilters().strain_name_or_id;
            });
        },

        owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        origin_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        procedure_id: class extends ListFilterItem {
            public readonly possibleValues: {id: number; name: string}[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((item: any) => item.name);
                this.possibleValues = [
                    { id: -1, name: getTranslation("All with procedure") },
                    { id: 0, name: getTranslation("None") },
                ].concat(seed.possible_values);
            }
        },

        procedure_comment: class extends ListFilterItem {
            private disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.disable = ko.pureComputed( () => {
                    return filter.allFilters().procedure_id
                        && filter.allFilters().procedure_id.model.serialize() === 0;
                });

                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }
        },

        procedure_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            private disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("procedure_date_to"), compareFromDate),
                });

                this.disable = ko.pureComputed( () => {
                    return filter.allFilters().procedure_id
                        && filter.allFilters().procedure_id.model.serialize() === 0;
                });

                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }

            public valid = () => this.value.isValid();
        },

        procedure_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;
            private disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("procedure_date_from"), compareToDate),
                });

                this.disable = ko.pureComputed( () => {
                    return filter.allFilters().procedure_id
                        && filter.allFilters().procedure_id.model.serialize() === 0;
                });

                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }

            public valid = () => this.value.isValid();
        },

        project_id: class extends ListFilterItem {
            public readonly possibleValues: {id: number; name: string}[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(seed.possible_values);
                this.text = seed.possible_values
                    .map((item: any) => item.name)
                    .concat(seed.possible_values.map((item: any) => item.owner_fullname));
            }
        },

        release_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("release_date_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        release_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("release_date_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        responsible_id: class extends ListFilterItem {
            public possibleValues: PureComputed<{ userid: number; fullname: string }[]>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValueArguments = ko.pureComputed(() => {
                    const ownerId = filter.allFilters().owner_id;
                    if (ownerId) {
                        return { owner_id: ownerId.model.serialize() };
                    }
                });

                this.possibleValues = ko.pureComputed(() => {
                    return [{ userid: 0, fullname: getTranslation("None") }].concat(seed.possible_values() || []);
                });

                this.text = ko.pureComputed(() => {
                    return seed.possible_values()?.map((item: any) => item.fullname);
                });
            }
        },

        classification_id: class extends ListFilterItem {
            public disable: PureComputed<boolean>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValueArguments = ko.pureComputed(() => {
                    const licenseId = filter.allFilters().license_id;
                    if (licenseId) {
                        return { license_id: licenseId.model.serialize() };
                    }
                });

                this.disable = ko.pureComputed(() => {
                    return !(seed.possible_values() && seed.possible_values().length);
                });

                this.text = ko.pureComputed(() => {
                    return seed.possible_values()?.map((item: any) => item.name);
                });
            }
        },

        sacrifice_reason_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
            }
        },

        severity_level_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.label);
            }
        },

        species_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.label);
            }
        },

        status: ListFilterItem,

        strain_name_or_id: class extends ListFilterItem {
            private readonly currentCustomValues: ObservableArray;
            private selectemValue: ObservableArray;
            private possibleValues: PureComputed;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.currentCustomValues = ko.observableArray();
                this.selectemValue = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });

                this.possibleValueArguments = ko.pureComputed(() => {
                    const ownerId = filter.allFilters().owner_id;
                    if (ownerId) {
                        return { owner_id: ownerId.model.serialize() };
                    }
                });

                this.possibleValues = ko.pureComputed(() => {
                    return [{ id: 0, name_with_id: getTranslation("None") }].concat(seed.possible_values() || []);
                });

                this.text = ko.pureComputed(() => {
                    return seed.possible_values()?.map((item: any) => item.name_with_id);
                });

                this.valid = ko.pureComputed(() => {
                    return this.selectemValue().every((option) => {
                        return !option.valid || option.valid();
                    });
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue)
                        ? newValue.filter((value) => { return typeof value === "string"; })
                        : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },
        tank_location: class extends ListFilterLocationItem {
            public selectType: string[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.selectType = ["building", "area", "room", "rack"];
                this.valid = () => this.initialized() === true || this.serialize() === seed.default_value;
            }
        },

        tank_type: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.label);
            }
        },
    };
};
