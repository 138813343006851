import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    IdNameProperty,
    ListFilterDefinition,
} from "../../backend/v1";
import { showSetWeight } from "../../dialogs";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListFilterMutationsItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    SearchMode,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { session } from "../../lib/pyratSession";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
} from "../../lib/utils";
import { pyratFrontend } from "../../pyratFrontend";

import filterTemplate from "./unboundAnimalListFilter.html";


interface Arguments extends ListViewArguments {
    animal_id_to_show?: number;
    search_mode: SearchMode;
}

// noinspection JSPotentiallyInvalidUsageOfThis
const UnboundAnimalListFilters = (filter: ListFilterModel) => ({
    cagenumber: ListFilterItem,

    cagenumberfrom: ListFilterItem,

    cagenumberto: ListFilterItem,

    cage_label_like: ListFilterItem,

    eartag: ListFilterItem,

    eartagmin: ListFilterItem,

    eartagmax: ListFilterItem,

    eartag_past: ListFilterItem,

    addgen: ListFilterItem,

    subgen: ListFilterItem,

    newgen: ListFilterItem,

    subgen2: ListFilterItem,

    newgen2: ListFilterItem,

    labid: ListFilterItem,

    labid_from: ListFilterItem,

    labid_to: ListFilterItem,

    min_unbound_days: ListFilterItem,

    max_unbound_days: ListFilterItem,
    sex: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    page_size: ListFilterItem,

    birth_date_from: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;

        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("birth_date_to"), compareFromDate),
            });
        }

        public valid = () => this.value.isValid();
    },

    birth_date_to: class extends ListFilterItem {
        private value: CheckExtended<Observable<string>>;

        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("birth_date_from"), compareToDate),
            });
        }

        public valid = () => this.value.isValid();
    },

    cage_location: class extends ListFilterLocationItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.valid = () => this.initialized() === true || this.serialize() === seed.default_value;
        }
    },

    gen_bg: class extends ListFilterItem {
        public readonly staticValues: { name: string; id: number }[];
        public readonly possibleValues: { name: string; id: number }[];

        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.staticValues = [{ id: 0, name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    strain_name_or_id: class extends ListFilterItem {
        private possibleValues: { name: string; id: number }[];

        constructor(value: ko.Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(
                seed.possible_values
                    .filter((option: any) => {
                        return option.strain_name_with_id && option.state === "live";
                    })
                    .map((option: any) => {
                        return { id: option.strain_id, name: option.strain_name_with_id };
                    }),
            );
            this.text = this.possibleValues.map((option) => {
                return option.name;
            });
        }
    },

    inactive_strain_name_or_id: class extends ListFilterItem {
        private possibleValues: { name: string; id: number }[];

        constructor(value: ko.Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(
                seed.possible_values
                    .filter((option: any) => {
                        return option.strain_name_with_id && ["exported", "sacrificed"].includes(option.state);
                    })
                    .map((option: any) => {
                        return { id: option.strain_id, name: option.strain_name_with_id };
                    }),
            );
            this.text = this.possibleValues.map((option) => {
                return option.name;
            });
        }
    },

    strain_official_name: class extends ListFilterItem {
        private possibleValues: { name: string; id: number }[];

        constructor(value: ko.Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(
                seed.possible_values
                    .filter((option: any) => {
                        return option.strain_official_name && option.state === "live";
                    })
                    .map((option: any) => {
                        return { id: option.strain_official_name, name: option.strain_official_name };
                    }),
            );
            this.text = this.possibleValues.map((option) => {
                return option.name;
            });
        }
    },

    inactive_strain_official_name: class extends ListFilterItem {
        private possibleValues: { name: string; id: number }[];

        constructor(value: ko.Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(
                seed.possible_values
                    .filter((option: any) => {
                        return option.strain_official_name && ["exported", "sacrificed"].includes(option.state);
                    })
                    .map((option: any) => {
                        return { id: option.strain_official_name, name: option.strain_official_name };
                    }),
            );

            this.text = this.possibleValues.map((option) => {
                return option.name;
            });
        }
    },

    strain_genetic_background_id: class extends ListFilterItem {
        public readonly possibleValues: { name: string; id: number }[];
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((value: IdNameProperty) => value.name);
            this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(seed.possible_values);
        }
    },

    mutation_grade: class extends ListFilterMutationsItem {
        strainNameOrIdFilter = ko.pureComputed(() => {
            return filter.allFilters().strain_name_or_id;
        });
    },

    owner_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "userfullname") as string[];
        }
    },

    owner_user_group_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    responsible_id: class extends ListFilterItem {
        private possibleValues: PureComputed<{ fullname: string; userid: number }[]>;

        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.possibleValueArguments = ko.pureComputed(() => {
                if (filter.allFilters().owner_id) {
                    return {
                        owner_ids: filter.allFilters().owner_id.model.serialize(),
                    };
                }
            });

            this.possibleValues = ko.pureComputed(() => {
                return _.union([{ userid: 0, fullname: getTranslation("None") }], seed.possible_values());
            });

            this.text = ko.pureComputed(() => {
                return _.map(seed.possible_values(), "fullname") as string[];
            });
        }
    },

    species: class extends ListFilterItem {
        public readonly staticValues: { name: string; id: number }[];
        public readonly possibleValues: { name: string; id: number }[];

        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.staticValues = [{ id: 0, name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    state: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = _.map(seed.possible_values, "name");
        }
    },
});

class UnboundAnimalList {
    private listView: ListView;
    private args: Arguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: UnboundAnimalListFilters,
                filterTemplate: filterTemplate,
                title: args.view_name === "puplist" ? getTranslation("Pup filter") : getTranslation("Animal filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("qs-button", this.showQuickSelect);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("add-animals-request-button", () => {
            if (this.listView.getSelectedRowIds().length) {
                openListDetailPopup(
                    getUrl(cgiScript("new_request.py"), {
                        clearmice: "true",
                        clearcages: "true",
                        cleartanks: "true",
                        require_wr_behavior: "exp_to_scientist",
                        select_animals: this.listView.getSelectedRowIdsString(),
                    }),
                );
            } else {
                notifications.showModal(getTranslation("No animals selected") + ".");
            }
        });
        this.listView.onMenuBoxClick("add-pups-request-button", () => {
            if (this.listView.getSelectedRowIds().length) {
                openListDetailPopup(
                    getUrl(cgiScript("new_request.py"), {
                        clearmice: "true",
                        clearcages: "true",
                        cleartanks: "true",
                        require_wr_behavior: "exp_to_scientist",
                        select_pups: this.listView.getSelectedRowIdsString().replaceAll("p", ""),
                    }),
                );
            } else {
                notifications.showModal(getTranslation("No pups selected") + ".");
            }
        });

        this.listView.onCellClick("td.eartag_or_id a", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                openListDetailPopup(getUrl(cgiScript("pupdetail.py"), { animalid: args.rowId.substring(1) }), () =>
                    this.listView.unHighlightRow(args.rowId),
                );
            } else {
                openListDetailPopup(getUrl(cgiScript("mousedetail.py"), { animalid: args.rowId }), () =>
                    this.listView.unHighlightRow(args.rowId),
                );
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick(".animal-single-request", (args) => {
            openListDetailPopup(
                getUrl(cgiScript("requestdetail.py"), { incidentid: args.element.getAttribute("incidentid") }),
                () => this.listView.unHighlightRow(args.rowId),
            );
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick(
            "td.requests a.animal-multiple-request, td.requests_long a.animal-multiple-request",
            (args) => {
                mainMenu.openAndResetListFilter("get_work_request_list", {
                    id: String(args.element.getAttribute("incidentids")).split(",").map((v) => parseInt(v, 10)),
                    status_id_or_unresolved: -1,
                });
            },
        );

        this.listView.onCellClick("td.weight.clickable", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetWeight({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reload(args.rowId);
                    },
                });
            } else {
                showSetWeight({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reload(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });
    }

    public showQuickSelect = () => {
        if (!this.listView.getSelectedRowIds().length) {
            notifications.showModal(getTranslation("No animals selected."));
            return;
        }
        pyratFrontend.top.frames.openQuickSelect(cgiScript("quickselect_animal.py"), {
            sessionid: session.sessionId,
            animals: this.listView.getSelectedRowIdsString(),
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public reload = (rowId?: string) => {
        this.listView.reload({ flashRowId: rowId });
    };
}

export const initUnboundAnimalList = (args: Arguments): void => {
    new UnboundAnimalList(document.querySelector("div.listview"), args);
};
