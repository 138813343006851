/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalSelectionValidationResult } from '../models/GlobalSelectionValidationResult';
import type { WorkRequestAdditionalField } from '../models/WorkRequestAdditionalField';
import type { WorkRequestClassPlannedProcedures } from '../models/WorkRequestClassPlannedProcedures';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RequestsService {

    /**
     * Validate Global Work Request Selection
     * Validate animals/pups/cages in global selection for work request
     *
     * Return a warning and error messages for animals/pups and for cages, if necessary.
     * @returns GlobalSelectionValidationResult Successful Response
     * @throws ApiError
     */
    public static validateGlobalWorkRequestSelection({
        incidentClassId,
    }: {
        incidentClassId: number,
    }): CancelablePromise<GlobalSelectionValidationResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/requests/work_request/validate_global_select',
            query: {
                'incident_class_id': incidentClassId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Work Request Additional Fields
     * Get the additional input fields for a work request class.
     * @returns WorkRequestAdditionalField Successful Response
     * @throws ApiError
     */
    public static getWorkRequestAdditionalFields({
        classId,
    }: {
        classId: number,
    }): CancelablePromise<Array<WorkRequestAdditionalField>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/requests/work_request/additional_fields',
            query: {
                'class_id': classId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Work Request Class Planned Procedures
     * Get the planned procedures for a work request class.
     * @returns WorkRequestClassPlannedProcedures Successful Response
     * @throws ApiError
     */
    public static getWorkRequestClassPlannedProcedures({
        workRequestClassId,
    }: {
        workRequestClassId: number,
    }): CancelablePromise<WorkRequestClassPlannedProcedures> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/requests/work_request_class/planned_procedures',
            query: {
                'work_request_class_id': workRequestClassId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
