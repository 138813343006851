import { showScheduledReportDetails } from "../../dialogs";
import {
    ListView,
    ListViewArguments,
    OrderBy,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { notifications } from "../../lib/pyratTop";
import { getUrl } from "../../lib/utils";

class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: ListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("schedule-report-button", () => {
            showScheduledReportDetails({ reportId: null });
        });

        this.listView.onMenuBoxClick("download-button", () => {
            const selectedRowIds = this.listView.getSelectedRowIds();
            if (selectedRowIds.length) {
                location.href = getUrl("./archive", { report_id: selectedRowIds });
            } else {
                notifications.showModal(getTranslation("Empty selection."));
            }
        });
        this.listView.onCellClick("td.show_report_runs input", (args) => {
            location.href = getUrl("./scheduled_report_runs", { report_id: args.rowId });
        });

        this.listView.onCellClick("td.update_report input", (args) => {
            showScheduledReportDetails({ reportId: parseInt(args.rowId, 10) });
        });
    }
}

export const initScheduledReportsList = (args: ListViewArguments): void => {
    new List(document.querySelector("div.listview"), args);
};
