/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SaveSettingsRequestData } from '../models/SaveSettingsRequestData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SystemStatusService {

    /**
     * Create Status Record
     * Create status record.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createSystemStatusRecord(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/system_status/record',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Status Administration Settings
     * Save status administration settings.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static saveSystemStatusSettings({
        requestBody,
    }: {
        requestBody: SaveSettingsRequestData,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/system_status/settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
