import { getTranslation } from "../localize";
import { session } from "../pyratSession";
import { getUrl } from "../utils";

import { showColumnSelect } from "./columnSelect";
import {
    resetListFilter,
    setListFilter,
} from "./listFilter";
import {
    OrderBy,
    OrderItem,
} from "./orderBy";
import { TouchSelect } from "./touchSelect";

export interface ListViewArguments {
    current_order: OrderItem[];
    default_order_column: string | string[];
    view_name: string;
}

export class ListView {
    public readonly listViewElement: HTMLDivElement;
    private readonly viewName: string;
    public readonly touchSelect?: TouchSelect;

    constructor(
        listViewElement: HTMLDivElement,
        viewName: string,
        orderBy: OrderBy,
        { useListFilter = true, useColumnSelector = true, useTouchSelect = true } = {},
    ) {
        this.listViewElement = listViewElement;
        this.viewName = viewName;

        listViewElement.querySelectorAll("a.order-by").forEach((element: HTMLAnchorElement) =>
            element.addEventListener("click", (event) => {
                event.preventDefault();
                const colName = element.dataset.columnName;

                if (useListFilter) {
                    setListFilter(this.viewName, {
                        order_by: orderBy.toggle(colName),
                    });
                } else {
                    window.location.href = getUrl(window.location.href, {
                        sessionid: session.sessionId,
                        order_by: orderBy.toggle(colName),
                    });
                }
            }),
        );

        listViewElement.querySelectorAll("a.order-by-remove").forEach((element: HTMLAnchorElement) =>
            element.addEventListener("click", (event) => {
                event.preventDefault();
                const colName = element.dataset.columnName;

                if (useListFilter) {
                    setListFilter(this.viewName, {
                        order_by: orderBy.remove(colName),
                    });
                } else {
                    window.location.href = getUrl(window.location.href, {
                        sessionid: session.sessionId,
                        order_by: orderBy.remove(colName),
                    });
                }
            }),
        );

        if (useColumnSelector) {
            listViewElement.querySelector("div.column-select-icon")?.addEventListener("click", () => {
                showColumnSelect({ viewName });
            });
        }

        if (useTouchSelect) {
            this.touchSelect = new TouchSelect({
                container: this.listViewElement.querySelector("table.standard-table:last-child > tbody"),
                onSelectionChanged: (rows) => {
                    const countElement = this.listViewElement.querySelector(".quickselect-count") as HTMLSpanElement;
                    if (countElement) {
                        countElement.textContent = getTranslation("%d selected.").replace("%d", String(rows.size));
                        if (rows.size) {
                            countElement.style.display = "inline";
                        } else {
                            countElement.style.display = "none";
                        }
                    }
                },
            });

            this.listViewElement
                .querySelector("input[type=checkbox].quickselect-check-all")
                ?.addEventListener("click", (event) => {
                    this.touchSelect.toggleAll((event.target as HTMLInputElement).checked);
                });
        }

        if (window.location.hash) {
            const uriParams = new URLSearchParams(window.location.hash.substring(1));
            const scrollTop = uriParams.get("scrollTop");
            if (scrollTop) {
                this.listViewElement.querySelector(".listview-table")?.scrollTo(0, Number(scrollTop));
            }
            const flashRowId = uriParams.get("flashRowId");
            if (flashRowId) {
                this.flashRow(flashRowId);
            }
        }
    }

    public flashRow = (rowId: string, scrollIntoView = true) => {
        const row = this.getRowElement(rowId);
        if (row) {
            if (scrollIntoView) {
                const oldScrollMargin = row.style.scrollMargin;
                row.style.scrollMargin = "50px";
                row.scrollIntoView({ block: "nearest" });
                row.style.scrollMargin = oldScrollMargin;
            }
            row.classList.add("flash-row");
            setTimeout(() => row.classList.remove("flash-row"), 1000);
        }
    };

    public reload = ({ flashRowId = "", maintainScrollPosition = true } = {}) => {
        const uriParams = new URLSearchParams();
        if (maintainScrollPosition) {
            const scrollTop = this.listViewElement.querySelector(".listview-table")?.scrollTop;
            if (scrollTop) {
                uriParams.set("scrollTop", String(scrollTop));
            }
        }
        if (flashRowId) {
            uriParams.set("flashRowId", String(flashRowId));
        }
        window.location.hash = uriParams.toString();
        window.location.reload();
    };

    /**
     * Return the list of quickselected rowIds as a jquery list.
     */
    public getSelectedRowIds = (): string[] => {
        return Array.from(
            this.listViewElement.querySelectorAll("table input[type=checkbox][name=quickselect_id]:checked"),
        ).map((element) => {
            return element.closest("tr").dataset.rowId;
        });
    };

    /**
     * Return the list of quickselected rowIds as a comma-separated string.
     */
    public getSelectedRowIdsString = (): string => {
        return this.getSelectedRowIds().join(",");
    };

    /**
     * Highlight the row with the given rowid, e.g. while a popup is open.
     *
     * Remove highlight from all other rows so that there will only be a
     * single highlighted row at a time.
     */
    public highlightRow = (rowId: string | number) => {
        if (rowId) {
            this.getRowElement(rowId)?.classList?.add("selected-row");
        }
    };

    /**
     * Remove the highlight from all or a given row.
     */
    public unHighlightRow = (rowId: string | number) => {
        this.getRowElement(rowId)?.classList?.remove("selected-row");
    };

    /**
     * Remove the highlight from all rows.
     */
    public unHighlightAllRows = () => {
        this.listViewElement.querySelectorAll(".selected-row").forEach((e) => e.classList.remove("selected-row"));
    };

    public onMenuBoxClick = (name: string | string[], callback: (event: MouseEvent) => void) => {
        if (typeof name === "string") {
            name = [name];
        }
        for (const n of name) {
            this.listViewElement.querySelectorAll(`.menubox li[data-name=${CSS.escape(n)}]`).forEach((element) => {
                element.addEventListener("click", (event: MouseEvent) => {
                    event.stopPropagation();
                    callback(event);
                });
            });
        }
    };

    /**
     * Handler for click on a filter preset in the menu of the filter button
     */
    public applyFilterPreset = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if ("filterArgs" in target.dataset) {
            resetListFilter(this.viewName, JSON.parse(target.dataset.filterArgs));
        } else {
            const link = target.querySelector("a[data-filter-args]") as HTMLAnchorElement;
            if (link) {
                resetListFilter(this.viewName, JSON.parse(link.dataset.filterArgs));
            }
        }
    };

    /**
     * Add listener for clicks on a table column cell.
     *
     * @param selector A string to select a column or elements inside a column to
     * trigger the action.
     *
     * @param callback Invoked with an object containing the details of the row.
     */
    public onCellClick = (
        selector: string,
        callback: (args: {
            event: MouseEvent;
            element: HTMLElement;
            row: HTMLTableRowElement;
            rowId: string;
            title: string;
        }) => boolean | void,
    ) => {
        document.querySelectorAll(selector).forEach((element: HTMLElement) => {
            element.addEventListener("click", (event) => {
                const row = (event.target as HTMLElement).closest("tr[data-row-id]") as HTMLTableRowElement;
                if (
                    callback({
                        event: event,
                        element: element,
                        row: row,
                        rowId: row?.dataset?.rowId,
                        title:
                            element.getAttribute("title") || element.querySelector("a[title]")?.getAttribute("title"),
                    })
                ) {
                    event.preventDefault();
                }
            });
        });
    };

    /**
     * Given a rowid, return the matching listview-table <tr> element.
     */
    private getRowElement = (rowId: string | number) => {
        return this.listViewElement.querySelector(
            `table tr[data-row-id=${CSS.escape(String(rowId))}]`,
        ) as HTMLTableRowElement | null;
    };
}
