import * as ko from "knockout";
import * as _ from "lodash";

import {
    CommonLocationForLocationPicker,
    IdNameProperty,
    ListFilterDefinition,
    StrainNameDetailsWithGroup,
    StrainsBySexForOwners,
    StrainsService,
} from "../../backend/v1";
import {
    showCageQuickselect,
    showComments,
    showOrderRequestDetails,
    showSetCageCategory,
    showSetCageLabel,
    showSetCageLocation,
    showSetCageNumber,
    showSetCageOwner,
    showSetCagePosition,
    showSetCageResponsible,
    showSetProjects,
    showSetSexMoveSacrifice,
} from "../../dialogs";
import { FetchExtended } from "../../knockout/extensions/fetch";
import { FetchBackendExtended } from "../../knockout/extensions/fetchBackend";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    SearchBox,
    SearchMode,
    resetListFilter,
    setListFilter,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { session } from "../../lib/pyratSession";
import {
    frames,
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getFormData,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./cageListFilter.html";


interface CageListViewArguments extends ListViewArguments {
    search_mode?: SearchMode;
    cage_id_to_show?: number;
}

interface ResponsibleOption {
    userid: number;
    fullname: string;
    username?: string;
}

interface CageCategoryOption extends IdNameProperty {
    description?: string;
    status?: "active" | "inactive" | "default";
}

interface StrainOption {
    id: number;
    name?: string;
    name_id?: number;
    name_with_id: string;
    official_name?: string;
}

interface ProjectOption extends IdNameProperty {
    group?: "active" | "inactive" | "expired";
    owner_fullname?: string;
}

interface LicenseOption extends IdNameProperty {
    valid_from?: string;
    license_type_id?: number;
    budget_name?: string;
    group?: "available" | "exceeded" | "expired";
}

const CageListFilters = (filter: ListFilterModel) => {
    const ownerPupStrains: FetchExtended<ko.ObservableArray<StrainNameDetailsWithGroup>> = ko.observableArray([]).extend({
        fetchBackend: () => StrainsService.getStrainsByUsageForOwners({ ownerIds: filter.getValue("owner"), pupStrains: true }),
    });
    const ownerStrainsBySex: FetchBackendExtended<ko.Observable<StrainsBySexForOwners>> = ko.observable().extend({
        fetchBackend: () => StrainsService.getStrainsBySexForOwners({ ownerIds: filter.getValue("owner") }),
    });

    return {
        owner: class extends ListFilterItem {
            constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((v: {
                    userid: "__all_for_alias__" | number;
                    userfullname: string;
                    group: "active" | "inactive";
                }) => v.userfullname);
            }
        },

        owner_user_group_id: class extends ListFilterItem {
            constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
            }
        },

        responsible: class extends ListFilterItem {
            private readonly staticValues: { userid: number; fullname: string }[];
            private readonly possibleValues: ko.PureComputed<ResponsibleOption[]>;

            constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
                super(value, seed);

                this.possibleValueArguments = ko.pureComputed(() => {
                    const ownerIds = filter.getValue("owner");

                    if (ownerIds) {
                        return {
                            owner_ids: ownerIds,
                        };
                    }
                });
                this.staticValues = [{ userid: 0, fullname: getTranslation("None") }];
                this.possibleValues = ko.pureComputed(() => this.staticValues.concat(seed.possible_values() || []));
                this.text = ko.pureComputed(() => (seed.possible_values() || []).map((v: ResponsibleOption) => v.fullname));
            }
        },

        cagetype: class extends ListFilterItem {
            constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((v: { cagetype: string; cagetype_i18n: string }) => v.cagetype_i18n);
            }
        },

        cage_category: class extends ListFilterItem {
            constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((v: CageCategoryOption) => v.name);
            }
        },

        cagenumber: ListFilterItem,
        cagenumber_past: ListFilterItem,
        cagenumber_from: ListFilterItem,
        cagenumber_to: ListFilterItem,

        status: class extends ListFilterItem {
            constructor(value: ko.Observable<"open" | "empty" | "open_or_empty" | "closed">, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((v: { id: "open" | "empty" | "open_or_empty" | "closed"; name: string }) => v.name);
            }
        },

        cage_label_like: ListFilterItem,
        cage_label_from: ListFilterItem,
        cage_label_to: ListFilterItem,

        comment: ListFilterItem,

        comment_date_from: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_to"), compareFromDate),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        comment_date_to: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_from"), compareToDate),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        reply_pending: ListFilterItem,

        strain: class extends ListFilterItem {
            private readonly staticValues: { id: number; name_with_id: string }[];
            private readonly possibleValues: ko.PureComputed<StrainOption[]>;
            private readonly inProgress: ko.Observable<boolean>;
            private readonly disable: ko.Observable<boolean>;
            private readonly selectemValue: ko.ObservableArray<StrainOption & {
                id: number | string;
                valid?: ko.Observable<boolean>;
            }>;
            private readonly currentCustomValues: ko.ObservableArray<string>;

            constructor(value: ko.Observable<(number | string)[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.inProgress = ownerStrainsBySex.inProgress;
                this.disable = this.inProgress;
                this.selectemValue = ko.observableArray();
                this.currentCustomValues = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        // @ts-expect-error: says that `option.id` can only be `number` and the checks are only if it was `string` (which it can be)
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });
                this.staticValues = [{ id: 0, name_with_id: getTranslation("None") }];
                this.possibleValues = ko.pureComputed(() => this.staticValues.concat(ownerStrainsBySex()?.strains || []));
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.text = ownerStrainsBySex()?.strains?.map((v) => v.name_with_id);
                this.valid = ko.pureComputed(() => {
                    return (!this.inProgress() || this.serialize() === seed.default_value) &&
                            this.selectemValue().every((option) => !option.valid || option.valid());
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue) ? newValue.filter((value) => typeof value === "string") : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },

        fstrain: class extends ListFilterItem {
            private readonly staticValues: { id: number; name_with_id: string }[];
            private readonly possibleValues: ko.PureComputed<StrainOption[]>;
            private readonly inProgress: ko.Observable<boolean>;
            private readonly disable: ko.Observable<boolean>;
            private readonly selectemValue: ko.ObservableArray<StrainOption & {
                id: number | string;
                valid?: ko.Observable<boolean>;
            }>;
            private readonly currentCustomValues: ko.ObservableArray<string>;

            constructor(value: ko.Observable<(number | string)[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.inProgress = ownerStrainsBySex.inProgress;
                this.disable = this.inProgress;
                this.selectemValue = ko.observableArray();
                this.currentCustomValues = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        // @ts-expect-error: says that `option.id` can only be `number` and the checks are only if it was `string` (which it can be)
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });
                this.staticValues = [{ id: 0, name_with_id: getTranslation("None") }];
                this.possibleValues = ko.pureComputed(() => this.staticValues.concat(ownerStrainsBySex()?.female_strains || []));
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.text = ownerStrainsBySex()?.female_strains?.map((v) => v.name_with_id);
                this.valid = ko.pureComputed(() => {
                    return (!this.inProgress() || this.serialize() === seed.default_value) &&
                            this.selectemValue().every((option) => !option.valid || option.valid());
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue) ? newValue.filter((value) => typeof value === "string") : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },

        mstrain: class extends ListFilterItem {
            private readonly staticValues: { id: number; name_with_id: string }[];
            private readonly possibleValues: ko.PureComputed<StrainOption[]>;
            private readonly inProgress: ko.Observable<boolean>;
            private readonly disable: ko.Observable<boolean>;
            private readonly selectemValue: ko.ObservableArray<StrainOption & {
                id: number | string;
                valid?: ko.Observable<boolean>;
            }>;
            private readonly currentCustomValues: ko.ObservableArray<string>;

            constructor(value: ko.Observable<(number | string)[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.inProgress = ownerStrainsBySex.inProgress;
                this.disable = this.inProgress;
                this.selectemValue = ko.observableArray();
                this.currentCustomValues = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        // @ts-expect-error: says that `option.id` can only be `number` and the checks are only if it was `string` (which it can be)
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });
                this.staticValues = [{ id: 0, name_with_id: getTranslation("None") }];
                this.possibleValues = ko.pureComputed(() => this.staticValues.concat(ownerStrainsBySex()?.male_strains || []));
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.text = ownerStrainsBySex()?.male_strains?.map((v) => v.name_with_id);
                this.valid = ko.pureComputed(() => {
                    return (!this.inProgress() || this.serialize() === seed.default_value) &&
                            this.selectemValue().every((option) => !option.valid || option.valid());
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue) ? newValue.filter((value) => typeof value === "string") : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },

        pstrain: class extends ListFilterItem {
            private readonly staticValues: { id: number; name_with_id: string }[];
            private readonly possibleValues: ko.PureComputed<StrainOption[]>;
            private readonly inProgress: ko.Observable<boolean>;
            private readonly disable: ko.Observable<boolean>;
            private readonly selectemValue: ko.ObservableArray<StrainOption & {
                id: number | string;
                valid?: ko.Observable<boolean>;
            }>;
            private readonly currentCustomValues: ko.ObservableArray<string>;

            constructor(value: ko.Observable<(number | string)[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.inProgress = ownerPupStrains.inProgress;
                this.disable = this.inProgress;
                this.selectemValue = ko.observableArray();
                this.currentCustomValues = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        // @ts-expect-error: says that `option.id` can only be `number` and the checks are only if it was `string` (which it can be)
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });
                this.staticValues = [{ id: 0, name_with_id: getTranslation("None") }];
                this.possibleValues = ko.pureComputed(() => this.staticValues.concat(ownerPupStrains() || []));
                this.possibleValues.subscribeOnce(() => {
                    value(seed.current_value);
                });
                this.text = ownerPupStrains()?.map((v: StrainOption) => v.name_with_id);
                this.valid = ko.pureComputed(() => {
                    return (!this.inProgress() || this.serialize() === seed.default_value) &&
                            this.selectemValue().every((option) => !option.valid || option.valid());
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue) ? newValue.filter((value) => typeof value === "string") : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },

        strain_ongoing_severity_assessment: ListFilterItem,

        has_strain_severity_assessment_sheets: ListFilterItem,

        has_classification_severity_assessment_sheets: ListFilterItem,

        location: class extends ListFilterLocationItem {
            constructor(value: ko.Observable<CommonLocationForLocationPicker>, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => {
                    return this.initialized() === true || this.serialize() === seed.default_value;
                };
            }
        },

        last_order_request_id: ListFilterItem,

        project_id: class extends ListFilterItem {
            private readonly staticValues: Array<IdNameProperty>;
            private readonly possibleValues: ProjectOption[];

            constructor(value: ko.Observable<number[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values || []);
                this.text = [].concat(...(seed.possible_values?.map((v: ProjectOption) => [v.name, v.owner_fullname]) || []));
            }
        },

        animal_project_id: class extends ListFilterItem {
            private readonly staticValues: Array<IdNameProperty>;
            private readonly possibleValues: ProjectOption[];

            constructor(value: ko.Observable<number[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values || []);
                this.text = [].concat(...(seed.possible_values?.map((v: ProjectOption) => [v.name, v.owner_fullname]) || []));
            }
        },

        animal_cohort_id: class extends ListFilterItem {
            constructor(value: ko.Observable<string[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.text = seed.possible_values?.map((v: { id: string }) => v.id);
            }
        },

        license_id: class extends ListFilterItem {
            /* Filter cages that contain animals with given license id(s) */
            private readonly staticValues: Array<IdNameProperty>;
            private readonly possibleValues: LicenseOption[];

            constructor(value: ko.Observable<number[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values || []);
                this.text = seed.possible_values?.map((v: LicenseOption) => v.name);
            }
        },

        animal_license_id: class extends ListFilterItem {
            /* Filter cages that contain animals with given license id(s) */
            private readonly staticValues: Array<IdNameProperty>;
            private readonly possibleValues: LicenseOption[];

            constructor(value: ko.Observable<number[]>, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values || []);
                this.text = seed.possible_values?.map((v: LicenseOption) => v.name);
            }
        },

        min_females: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },
        max_females: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        min_males: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },
        max_males: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        min_total: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },
        max_total: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        min_pregnant: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },
        max_pregnant: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        min_age_weeks: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },
        max_age_weeks: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        min_isolation_days: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },
        max_isolation_days: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        species_id: class extends ListFilterItem {
            private readonly staticValues: Array<IdNameProperty>;
            private readonly possibleValues: Array<IdNameProperty>;

            constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
                super(value, seed);

                this.staticValues = [{ id: 0, name: getTranslation("None") }];
                this.possibleValues = this.staticValues.concat(seed.possible_values || []);
                this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
            }
        },

        plugged: ListFilterItem,

        plug_date_from: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;
            private disable: ko.PureComputed<boolean>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("plug_date_to"), compareFromDate),
                });

                this.disable = ko.pureComputed(() => {
                    return filter.getValue("plugged") === "no";
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        plug_date_to: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;
            private disable: ko.PureComputed<boolean>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("plug_date_from"), compareToDate),
                });

                this.disable = ko.pureComputed(() => {
                    return filter.getValue("plugged") === "no";
                });
                this.disable.subscribe((disable) => {
                    if (disable) {
                        this.deserialize(undefined);
                    }
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        contains_pups: ListFilterItem,

        contains_embryo_transfers: ListFilterItem,

        last_modified: class extends ListFilterItem {
            private value: CheckExtended<ko.Observable<string>>;

            constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
                });
            }

            public valid = () => {
                return this.value.isValid();
            };
        },

        reprint: ListFilterItem,

        page_size: ListFilterItem,
    };
};

class CageList {
    private listView: ListView;
    private args: CageListViewArguments;
    private requireReload = false;

    constructor(listViewElement: HTMLDivElement, args: CageListViewArguments) {

        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: CageListFilters,
                filterTemplate: filterTemplate,
                title: args.view_name === "stockcagelist" ? getTranslation("Cage summary filter") : getTranslation("Cage filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("print-cards-button", this.printCageCards);

        this.listView.onMenuBoxClick("qs-button", this.showQuickSelect);

        this.listView.onMenuBoxClick("aqs-button", this.showAnimalQuickSelect);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, {
                show_print: "true",
            }));
        });

        // Table Body

        this.listView.onCellClick("td.cagenumber a", (args) => {
            this.showCageDetails(args.rowId, parseInt(args.rowId, 10));
        });

        this.listView.onCellClick("td.rename_icon a", (args) => {
            showSetCageNumber({
                cageId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.male_information a.animal-details, td.female_information a.animal-details", (args) => {
            this.showAnimalDetails(args.rowId, parseInt(args.element.parentElement.dataset.animalId, 10));
        });

        this.listView.onCellClick("td.animal_information a.animal-details", (args) => {
            this.showAnimalDetails(args.rowId, parseInt(args.element.parentElement.parentElement.dataset.animalId, 10));
        });

        this.listView.onCellClick("td.animal_information a.animal-move", (args) => {
            showSetSexMoveSacrifice({
                animalId: parseInt(args.element.parentElement.parentElement.dataset.animalId, 10),
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.building.clickable, td.area.clickable, td.room.clickable, td.rack.clickable", (args) => {
            showSetCageLocation({
                cageId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.cage_position.clickable, td.cage_position_icon a", (args) => {
            showSetCagePosition({
                cageId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.cagelabel.clickable, td.cagelabel_icon a", (args) => {
            showSetCageLabel({
                cageId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.category.clickable", (args) => {
            showSetCageCategory({
                cageId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.comments.clickable, td.comments_with_event.clickable", (args) => {
            showComments({
                origin: "cage",
                subjects: { cage_id: parseInt(args.rowId, 10) },
                reSeedCallback: () => this.requireReload = true,
                onClose: () => this.requireReload
                    ? this.reload(args.rowId)
                    : this.listView.unHighlightRow(args.rowId),
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.owner_fullname.clickable", (args) => {
            showSetCageOwner({
                cageId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.responsible_fullname.clickable", (args) => {
            showSetCageResponsible({
                cageId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.project_name.clickable", (args) => {
            showSetProjects({
                cageId: parseInt(args.rowId, 10),
                selectType: session.pyratConf.MULTIPLE_PROJECTS ? "multiple" : "single",
                eventTarget: args.element,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.last_order_request_id a", (args) => {
            showOrderRequestDetails({
                orderRequestId: parseInt(args.element.dataset.orderId, 10),
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("a.cage-single-request", (args) => {
            this.showWorkRequestDetails(args.rowId, parseInt(args.element.dataset.workRequestId, 10));
        });

        this.listView.onCellClick("a.cage-multiple-request", (args) => {
            mainMenu.openAndResetListFilter("get_work_request_list", {
                id: args.element.dataset.workRequestIds,
                status_id_or_unresolved: -1,
            });
        });

        // initialize the barcode searchboxes
        const barcodeFilterElement = listViewElement.querySelector(".searchbox .barcode_filter");
        if (barcodeFilterElement && session.pyratConf.BARCODE) {
            new SearchBox({
                element: barcodeFilterElement.parentElement,
                searchCode: "barcode",
                searchMode: args.search_mode,
                onSubmitCallback: (value, searchMode) => {
                    setListFilter(
                        args.view_name,
                        {
                            cagenumber: value,
                        },
                        () => {
                            window.location.href = getUrl(
                                window.location.href,
                                {
                                    search_mode: searchMode,
                                    show_cage_detail: searchMode === "single" ? 1 : 0,
                                },
                                {
                                    clearParams: true,
                                    clearHash: true,
                                },
                            );
                        },
                    );
                },
            });
        }
        const hardwareIdFilterElement = listViewElement.querySelector(".searchbox .hardware_id_filter");
        if (hardwareIdFilterElement) {
            new SearchBox({
                element: hardwareIdFilterElement.parentElement,
                searchCode: "barcode",
                searchMode: args.search_mode,
                onSubmitCallback: (value, searchMode) => {
                    setListFilter(
                        args.view_name,
                        {
                            hardware_integration_identifier: value.split(",").map((v) => v.trim()),
                        },
                        () => {
                            window.location.href = getUrl(
                                window.location.href,
                                {
                                    search_mode: searchMode,
                                    show_cage_detail: searchMode === "single" ? 1 : 0,
                                },
                                {
                                    clearParams: true,
                                    clearHash: true,
                                },
                            );
                        },
                    );
                },
            });
        }
    }

    public reload = (rowId?: string) => {
        this.listView.reload({
            flashRowId: rowId,
        });
    };

    public printCageCards = (event: MouseEvent) => {
        if (!this.listView.getSelectedRowIds().length) {
            notifications.showModal(getTranslation("No cages selected."));
        } else {
            fetch(cgiScript("print_cages.py"), {
                method: "POST",
                body: getFormData({
                    cageids: this.listView.getSelectedRowIdsString(),
                    check: 1,
                }),
            }).then((response) => response.json()).then((response) => {
                const showPrintDialog = () => {
                    if (Object.hasOwn(response, "select_a_printer")) {
                        // pyrat_conf is set up to use printer_chooser
                        if (response.select_a_printer) {
                            frames.openListAjaxPopup({
                                method: "GET",
                                url: cgiScript("print_cages.py"),
                                data: {
                                    cageids: this.listView.getSelectedRowIdsString(),
                                    select_a_printer: "1",
                                },
                                title: getTranslation("Choose printer"),
                                anchor: (<HTMLElement>event.target),
                            });
                        } else {
                            // printer is already selected or just one printer is set up
                            fetch(getUrl(cgiScript("print_cages.py"), {
                                cageids: this.listView.getSelectedRowIdsString(),
                            })).then((response) => response.json()).then((response) => {
                                if (response.success) {
                                    notifications.showNotification(getTranslation("Cards printed"), "success");
                                } else {
                                    notifications.showNotification(getTranslation("Can't print. Internal error has ocurred."), "error");
                                }
                            }).catch(this.handleRequestError);
                        }
                    } else {
                        // open PDF preview file in browser
                        const url = getUrl(cgiScript("print_cages.py"), {
                            cageids: this.listView.getSelectedRowIdsString(),
                        });
                        setTimeout(() => window.open(url, "_blank"));
                    }

                    this.listView.touchSelect.toggleAll(false);  // clear cage selection (checkboxes in list).
                };

                if (response.success) {
                    showPrintDialog();
                } else {
                    notifications.showConfirm(response.message, showPrintDialog);
                }
            }).catch(this.handleRequestError);
        }
    };

    public showQuickSelect = () => {
        if (!this.listView.getSelectedRowIds().length) {
            notifications.showModal(getTranslation("No cages selected."));
        } else {
            showCageQuickselect({
                actions: [],  // means all actions
                cageIds: this.listView.getSelectedRowIds()?.map((v) => parseInt(v, 10)) || [],
                viewName: this.args.view_name === "stockcagelist" ? "stockcagelist" : "cagelist",
                reloadCallback: this.reload,
            });
        }
    };

    public showAnimalQuickSelect = () => {
        if (!this.listView.getSelectedRowIds().length) {
            notifications.showModal(getTranslation("No cages selected."));
        } else {
            frames.openQuickSelect(cgiScript("quickselect_animal.py"), {
                sessionid: session.sessionId,
                cages: this.listView.getSelectedRowIdsString(),
            });
        }
    };

    public showCageDetails = (highlightRowId: string, cageId: number) => {
        this.listView.highlightRow(highlightRowId);

        frames.openListDetailPopup(getUrl(cgiScript("cagedetail.py"), {
            cageid: cageId,
        }), () => this.listView.unHighlightRow(highlightRowId));
    };

    public showAnimalDetails = (highlightRowId: string, animalId: number) => {
        this.listView.highlightRow(highlightRowId);

        frames.openListDetailPopup(getUrl(cgiScript("mousedetail.py"), {
            animalid: animalId,
        }), () => this.listView.unHighlightRow(highlightRowId));
    };

    public showWorkRequestDetails = (highlightRowId: string, workRequestId: number) => {
        this.listView.highlightRow(highlightRowId);

        frames.openListDetailPopup(getUrl(cgiScript("requestdetail.py"), {
            incidentid: workRequestId,
        }), () => this.listView.unHighlightRow(highlightRowId));
    };

    public handleRequestError = () => {
        notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
    };
}

export const initCageList = (args: CageListViewArguments): void => {
    const cageList = new CageList(document.querySelector("div.listview"), args);

    // @ts-expect-error: required by procedure shortcuts
    window.cageList = cageList;

    if (args.cage_id_to_show) {
        // open the cagedetails if a single barcode was requested
        cageList.showCageDetails(String(args.cage_id_to_show), args.cage_id_to_show);
    }
};
