/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SeverityLevelData } from '../models/SeverityLevelData';
import type { SeverityLevelDetailsResponse } from '../models/SeverityLevelDetailsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SeverityLevelsService {

    /**
     * Get Severity Level Details Endpoint
     * Get details for a severity level
     * @returns SeverityLevelDetailsResponse Successful Response
     * @throws ApiError
     */
    public static getSeverityLevelDetails({
        severityLevelId,
    }: {
        severityLevelId?: (number | null),
    }): CancelablePromise<SeverityLevelDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/severity_levels/details',
            query: {
                'severity_level_id': severityLevelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Severity Level Endpoint
     * Create a new severity level
     * @returns number Successful Response
     * @throws ApiError
     */
    public static createSeverityLevel({
        requestBody,
    }: {
        requestBody: SeverityLevelData,
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/severity_levels/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Severity Level Endpoint
     * Update an existing severity level
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateSeverityLevel({
        severityLevelId,
        requestBody,
    }: {
        severityLevelId: number,
        requestBody: SeverityLevelData,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/severity_levels/{severity_level_id}',
            path: {
                'severity_level_id': severityLevelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Severity Level Endpoint
     * Delete an existing severity level
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteSeverityLevel({
        severityLevelId,
    }: {
        severityLevelId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/severity_levels/{severity_level_id}',
            path: {
                'severity_level_id': severityLevelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
