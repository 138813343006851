/**
 * Show a pop-up for animal related breeding performance details.
 *
 * @param animalId: Database ID of the animal (parent) for which to show breeding performance details.
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 */

import * as ko from "knockout";

import {
    BreedingPerformanceDetails,
    ReportsService,
} from "../backend/v1";
import { dialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import {
    frames,
    notifications,
} from "../lib/pyratTop";
import {
    cgiScript,
    printElement,
    getUrl,
    baseUrl,
    sendPostForm,
} from "../lib/utils";

import template from "./breedingPerformanceDetails.html";


interface Params {
    animalId: number;
    birthDateTo?: string;
    birthDateFrom?: string;
    closeCallback?: () => void;
}

class BreedingPerformanceDetailsViewModel {

    private readonly dialog: KnockoutPopup;
    private readonly closeCallback: () => void;

    public readonly animalId: number;
    public readonly birthDateFrom: string;
    public readonly birthDateTo: string;

    private seed: ko.Observable<BreedingPerformanceDetails>;
    private loadInProgress: ko.Observable<boolean>;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;
        this.animalId = params.animalId;
        this.birthDateFrom = params.birthDateFrom;
        this.birthDateTo = params.birthDateTo;
        this.closeCallback = params.closeCallback;

        /**
         * Set dialog title.
         */
        if (this.birthDateFrom && this.birthDateTo) {
            this.dialog.setTitle(this.dialog.params.title + " (" + this.birthDateFrom + " - "
                + this.birthDateTo + ")");
        } else if (this.birthDateFrom) {
            this.dialog.setTitle(this.dialog.params.title + " (>= " + this.birthDateFrom + ")");
        } else if (this.birthDateTo) {
            this.dialog.setTitle(this.dialog.params.title + " (<=" + this.birthDateTo + ")");
        }

        this.seed = ko.observable();
        this.loadInProgress = ko.observable(true);

        /**
         * Get and handle data for popup.
         */

        if (this.animalId) {
            this.loadInProgress(true);
            ReportsService.getBreedingPerformanceDetails({
                animalId: this.animalId,
                birthDateFrom: this.birthDateFrom || "",
                birthDateTo: this.birthDateTo || "",
            })
                .then((data: BreedingPerformanceDetails) => {
                    this.seed(data);
                })
                .catch((reason) => {
                    notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
                    writeException(reason);
                })
                .finally(() => {
                    this.loadInProgress(false);
                });
        }

        /**
         * Add callback that is called after popup is closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    /**
     * Export animal breeding performance data as csv/xls file
     */
    public exportDetails = () => {
        sendPostForm(
            baseUrl("frontend/reports/breeding_performance_details_csv"),
            {
                animal_id: String(this.animalId),
                birth_date_from: this.birthDateFrom || "",
                birth_date_to: this.birthDateTo || "",
            },
            { target: "_blank" },
        );
    };

    public printDetails = () => {
        const container = this.dialog.element;
        printElement(container, { obtainStylesheet: true });
    };

    public showAnimalDetails = (animalId: number) => {
        frames.detailPopup.open(
            getUrl(cgiScript("mousedetail.py"), { animalid: animalId }, { absoluteUrl: true }),
        );
    };

    public showCageDetails = (cageId: number) => {
        frames.detailPopup.open(
            getUrl(cgiScript("cagedetail.py"), { cageid: cageId }, { absoluteUrl: true }),
        );
    };
}

export const showBreedingPerformanceDetails = dialogStarter(BreedingPerformanceDetailsViewModel, template, {
    name: "BreedingPerformanceDetails",
    width: 590,
    closeOthers: true,
    anchor: { top: 120, left: undefined },
    title: getTranslation("Breeding performance"),
});
