import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
} from "knockout";
import * as _ from "lodash";

import {
    ListFilterDefinition,
    ProjectsService,
} from "../../backend/v1";
import { showProjectDetails } from "../../dialogs";
import { CheckExtended } from "../../knockout/extensions/invalid";
import { writeException } from "../../lib/excepthook";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { notifications } from "../../lib/pyratTop";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./projectListFilter.html";


// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = (filter: ListFilterModel) => ({
    status: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    expired: ListFilterItem,

    min_expiration_date: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("max_expiration_date"), compareFromDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    max_expiration_date: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("min_expiration_date"), compareToDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    name: class extends ListFilterItem {
        public currentCustomValues: ObservableArray<string>;
        public possibleValues: ko.PureComputed<{ id: any; name: any }[]>;

        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.currentCustomValues = ko.observableArray();
            this.possibleValues = ko.pureComputed(() => {
                return _.map(seed.possible_values, (projectName) => {
                    return {
                        id: projectName,
                        name: projectName,
                    };
                });
            });
            this.text = seed.possible_values;
            this.deserialize = (newValue) => {
                if (typeof newValue === "string" && seed.possible_values.includes(newValue) === false) {
                    this.currentCustomValues.push(newValue);
                }
                value(newValue);
            };
        }
    },

    category_id: class extends ListFilterItem {
        public staticValues: { name: string; id: number }[];
        public possibleValues: { name: string; id: number }[];
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.staticValues = [{ id: 0, name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    owner_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    permitted_user_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    comment: ListFilterItem,

    page_size: ListFilterItem,
});

class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: ListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Project filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("create-new-project", () => {
            showProjectDetails({
                projectId: null,
                reloadCallback: this.listView.reload,
            });
        });

        this.listView.onCellClick("td.name.clickable", (args) => {
            const projectId = parseInt(args.rowId, 10);
            if (projectId) {
                showProjectDetails({
                    projectId: projectId,
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                    reloadCallback: () => this.listView.reload({ flashRowId: args.rowId }),
                });
                this.listView.highlightRow(args.rowId);
            }
        });

        this.listView.onCellClick("td.action .project-deactivate", (args) => {
            const button = args.element as HTMLInputElement;
            button.disabled = true;
            button.classList.add("loading");
            ProjectsService.deactivateProject({ projectId: parseInt(args.rowId, 10) })
                .then(() => {
                    location.reload();
                })
                .catch((reason) => {
                    if (typeof reason.body?.detail == "string") {
                        notifications.showNotification(reason.body.detail, "error");
                    } else {
                        notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
                        writeException(reason);
                        throw reason;
                    }
                    button.disabled = false;
                })
                .finally(() => {
                    button.classList.remove("loading");
                });
        });

        this.listView.onCellClick("td.action .project-activate", (args) => {
            const button = args.element as HTMLInputElement;
            button.disabled = true;
            button.classList.add("loading");
            ProjectsService.activateProject({ projectId: parseInt(args.rowId, 10) })
                .then(() => {
                    location.reload();
                })
                .catch((reason) => {
                    if (typeof reason.body?.detail == "string") {
                        notifications.showNotification(reason.body.detail, "error");
                    } else {
                        notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
                        writeException(reason);
                        throw reason;
                    }
                    button.disabled = false;
                })
                .finally(() => {
                    button.classList.remove("loading");
                });
        });

        this.listView.onCellClick("td.action .project-delete", (args) => {
            notifications.showConfirm(
                getTranslation("Are you sure you want to delete this project?"),
                () => {
                    const button = args.element as HTMLInputElement;
                    button.disabled = true;
                    button.classList.add("loading");
                    ProjectsService.deleteProject({ projectId: parseInt(args.rowId, 10) })
                        .then(() => {
                            location.reload();
                        })
                        .catch((reason) => {
                            if (typeof reason.body?.detail == "string") {
                                notifications.showNotification(reason.body.detail, "error");
                            } else {
                                notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
                                writeException(reason);
                                throw reason;
                            }
                            button.disabled = false;
                        })
                        .finally(() => {
                            button.classList.remove("loading");
                        });
                },
            );
            return false;
        });
    }
}

export const initProjectList = (args: ListViewArguments): void => {
    new List(document.querySelector("div.listview"), args);
};
