/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiService {

    /**
     * Enable Client Id
     * Enable a api client by id.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static enableClient({
        clientId,
    }: {
        clientId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/client/{client_id}/enabled',
            path: {
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Disable Client Id
     * Disable a api client by id.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static disableClient({
        clientId,
    }: {
        clientId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client/{client_id}/enabled',
            path: {
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Enable User Id
     * Enable a api user by id.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static enableUser({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/{user_id}/enabled',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Disable User Id
     * Disable a api user by id.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static disableUser({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/user/{user_id}/enabled',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Id
     * Delete a api user by id.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteUser({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
