import dialogPolyfill from "dialog-polyfill";

import {
    ActionLogEvent,
    ActionLogService,
} from "../../backend/v1";
import {
    showOrderRequestDetails,
    showProjectDetails,
    showTankDetails,
} from "../../dialogs";
import { getTranslation } from "../localize";
import {
    baseUrl,
    cgiScript,
    getUrl,
    printElement,
} from "../utils";

import {
    frames,
    mainMenu,
} from "./index";


// import required for Safari < 15.4 (released 2022-03, required until 2024-03)

class ActionLog {

    private dialog: HTMLDialogElement;
    private container: HTMLDivElement;
    private lastRowDate: string;

    constructor() {
        this.dialog = document.createElement("dialog");
        dialogPolyfill.registerDialog(this.dialog);
        this.dialog.classList.add("tw-m-2");
        this.dialog.classList.add("tw-p-0");
        this.dialog.classList.add("tw-min-w-[320px]");
        this.dialog.classList.add("tw-max-w-full");
        this.dialog.classList.add("tw-z-20");
        this.dialog.classList.add("tw-border-solid");
        this.dialog.classList.add("tw-rounded");
        this.dialog.classList.add("tw-border-2");
        this.dialog.classList.add("tw-border-base-color-layout");
        this.dialog.classList.add("tw-me-auto");
        this.dialog.style.height = "calc(100vh - 1.25rem)";
        this.dialog.style.transitionProperty = "margin-left,width";
        this.dialog.style.transitionDuration = ".3s";

        this.container = document.createElement("div");
        this.container.classList.add("tw-flex");
        this.container.classList.add("tw-flex-col");
        this.container.classList.add("tw-h-full");
        this.container.classList.add("tw-m-0");
        this.container.classList.add("tw-p-0");
        this.dialog.append(this.container);

        window.addEventListener("load", () => {
            document.body.append(this.dialog);
        });
    }

    private closeOnClickHandler = (event: MouseEvent) => {
        if (!this.dialog.contains(event.target as Node)) {
            this.close();
        }
    };

    public open() {
        setTimeout(() => document.addEventListener("click", this.closeOnClickHandler), 0);
        this.dialog.style.marginLeft = "-100%";
        this.dialog.show();
        this.dialog.style.marginLeft = "";
        this.reload();
    }

    public close() {
        document.removeEventListener("click", this.closeOnClickHandler);
        this.dialog.style.marginLeft = "-100%";
        setTimeout(() => {
            this.dialog.close();
            this.dialog.style.marginLeft = "";
        }, 300);
    }

    public clearSelection() {
        ActionLogService.clearGlobalSelection().then(() => this.reload());
    }

    public print() {
        printElement(this.container, { obtainStylesheet: true });
    }

    public export() {
        window.open(getUrl(baseUrl("frontend/action_log/csv")), "_blank");
    }

    public reload() {
        this.container.innerHTML = "";

        const loadingElement = document.createElement("div");
        loadingElement.classList.add("loading_circle");
        this.container.append(loadingElement);

        ActionLogService.getActionLog().then((actionLog) => {

            this.container.innerHTML = "";
            this.lastRowDate = "";

            const header = document.createElement("header");
            this.container.append(header);
            header.classList.add("tw-gap-2");
            header.classList.add("tw-flex");
            header.classList.add("tw-justify-between");
            header.classList.add("tw-m-2");
            header.classList.add("tw-p-2");
            header.classList.add("tw-border");
            header.classList.add("tw-border-shade-color-82");
            header.classList.add("tw-shadow-sm");
            header.classList.add("tw-bg-shade-color-90");
            header.classList.add("print:tw-hidden");

            const leftButtons = document.createElement("div");
            leftButtons.classList.add("tw-gap-2");
            leftButtons.classList.add("tw-flex");
            header.append(leftButtons);

            const rightButtons = document.createElement("div");
            rightButtons.classList.add("tw-gap-2");
            rightButtons.classList.add("tw-flex");
            header.append(rightButtons);

            const closeButton = document.createElement("input");
            closeButton.type = "button";
            closeButton.value = getTranslation("Close");
            closeButton.addEventListener("click", () => this.close());
            leftButtons.append(closeButton);

            const printButton = document.createElement("input");
            printButton.type = "button";
            printButton.value = getTranslation("Print");
            printButton.addEventListener("click", () => this.print());
            rightButtons.append(printButton);

            const exportButton = document.createElement("input");
            exportButton.type = "button";
            exportButton.value = getTranslation("Export");
            exportButton.addEventListener("click", () => this.export());
            rightButtons.append(exportButton);

            const eventContainer = document.createElement("div");
            eventContainer.classList.add("tw-overflow-y-auto");
            eventContainer.classList.add("print:tw-overflow-y-visible");
            eventContainer.classList.add("tw-px-4");
            eventContainer.classList.add("tw-mb-4");
            eventContainer.classList.add("tw-grid");
            eventContainer.classList.add("tw-gap-1");
            eventContainer.classList.add("tw-grid-cols-[min-content_min-content_auto]");

            if (actionLog.selection.animal_ids.length ||
                actionLog.selection.pup_ids.length ||
                actionLog.selection.cage_ids.length ||
                Object.keys(actionLog.selection.tanks).length) {
                const clearButtton = document.createElement("input");
                clearButtton.type = "button";
                clearButtton.classList.add("tw-float-right");
                clearButtton.value = getTranslation("Clear selection");
                clearButtton.addEventListener("click", () => this.clearSelection());
                header.append(clearButtton);
            }

            if (actionLog.event_list.length) {
                actionLog.event_list.forEach((event) => {
                    this.addEvent(eventContainer, event);
                });
            } else {
                const noEvents = document.createElement("p");
                noEvents.innerText = getTranslation("The action log is empty.");
                noEvents.classList.add("tw-col-span-full");
                eventContainer.append(noEvents);
            }
            this.container.append(eventContainer);

        });

    }

    private addEvent(container: HTMLDivElement, event: ActionLogEvent) {

        const eventSpacer = document.createElement("div");
        eventSpacer.classList.add("tw-col-span-full");
        container.append(eventSpacer);

        // Event meta data

        if (event.formatted_event_date !== this.lastRowDate) {
            const newDateSpacer = document.createElement("div");
            newDateSpacer.classList.add("tw-col-span-full");
            container.append(newDateSpacer);
        }

        const date = document.createElement("div");
        if (event.formatted_event_date !== this.lastRowDate) {
            date.classList.add("tw-font-bold");
            date.classList.add("tw-text-basic-color-white");
            date.classList.add("tw-p-1");
            date.classList.add("tw-rounded");
            date.classList.add("tw-w-min");
            date.classList.add("tw-bg-main-color-medium");
            date.classList.add("tw-no-underline");
            date.classList.add("tw-col-span-full");
            date.innerText = event.formatted_event_date;
            container.append(date);
        }

        const time = document.createElement("div");
        time.classList.add("tw-font-bold");
        time.classList.add("tw-text-main-color-medium");
        time.innerText = event.formatted_event_time;
        container.append(time);

        const title = document.createElement("div");
        title.classList.add("tw-col-span-2");
        title.classList.add("tw-font-bold");
        title.classList.add("tw-text-main-color-medium");
        title.innerText = event.title;
        container.append(title);

        this.lastRowDate = event.formatted_event_date;

        // Event relations

        const filterSymbol = document.createElement("span");
        filterSymbol.classList.add("tw-font-icon");
        filterSymbol.innerText = "≚";

        if (event.cages) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            if (event.cages.length > 1) {
                label.innerText = getTranslation("Cages") + ` (${event.cages.length})`;
            } else {
                label.innerText = getTranslation("Cage");
            }
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            event.cages.forEach((cage, index) => {
                if (index > 2) {return;}
                const link = document.createElement("a");
                link.classList.add("subtle-link");
                link.classList.add("tw-inline-block");
                link.classList.add("tw-whitespace-nowrap");
                link.classList.add("tw-mx-0.5");
                link.innerText = cage.cage_number;
                link.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("cagedetail.py"), {
                        cageid: cage.cage_id,
                    }));
                    this.close();
                });
                value.append(link);
            });
            if (event.cages.length > 3) {
                const ellipsis = document.createElement("span");
                ellipsis.innerText = "...";
                ellipsis.classList.add("tw-mx-1");
                value.append(ellipsis);
            }

            const listLink = document.createElement("a");
            listLink.classList.add("subtle-link");
            listLink.classList.add("tw-inline-block");
            listLink.classList.add("tw-whitespace-nowrap");
            listLink.classList.add("!tw-m-0.5");
            listLink.innerText = getTranslation("Cage list");
            listLink.prepend(filterSymbol.cloneNode(true));
            listLink.addEventListener("click", () => mainMenu.openAndResetListFilter("get_cage_list", {
                cageid: event.cages.map((c) => c.cage_id),
            }));
            value.append(listLink);

            container.append(value);

        }

        if (event.new_cages?.length) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            if (event.new_cages.length > 1) {
                label.innerText = getTranslation("New cages") + ` (${event.new_cages.length})`;
            } else {
                label.innerText = getTranslation("New cage");
            }
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            event.new_cages.forEach((cage, index) => {
                if (index > 2) {return;}
                const link = document.createElement("a");
                link.classList.add("subtle-link");
                link.classList.add("tw-inline-block");
                link.classList.add("tw-whitespace-nowrap");
                link.classList.add("tw-mx-0.5");
                link.innerText = cage.cage_number;
                link.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("cagedetail.py"), {
                        cageid: cage.cage_id,
                    }));
                    this.close();
                });
                value.append(link);
            });
            if (event.new_cages.length > 3) {
                const ellipsis = document.createElement("span");
                ellipsis.innerText = "...";
                ellipsis.classList.add("tw-mx-1");
                value.append(ellipsis);
            }

            const listLink = document.createElement("a");
            listLink.classList.add("subtle-link");
            listLink.classList.add("tw-inline-block");
            listLink.classList.add("tw-whitespace-nowrap");
            listLink.classList.add("!tw-m-0.5");
            listLink.innerText = getTranslation("Cage list");
            listLink.prepend(filterSymbol.cloneNode(true));
            listLink.addEventListener("click", () => mainMenu.openAndResetListFilter("get_cage_list", {
                cageid: event.new_cages.map((c) => c.cage_id),
            }));
            value.append(listLink);

            container.append(value);

        }

        if (event.animals?.length) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            if (event.animals.length > 1) {
                label.innerText = getTranslation("Animals") + ` (${event.animals.length})`;
            } else {
                label.innerText = getTranslation("Animal");
            }
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            event.animals.forEach((animal, index) => {
                if (index > 2) {return;}
                const link = document.createElement("a");
                link.classList.add("subtle-link");
                link.classList.add("tw-inline-block");
                link.classList.add("tw-whitespace-nowrap");
                link.classList.add("tw-mx-0.5");
                link.innerText = animal.eartag;
                link.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("mousedetail.py"), {
                        animalid: animal.animal_id,
                    }));
                    this.close();
                });
                value.append(link);
            });
            if (event.animals.length > 3) {
                const ellipsis = document.createElement("span");
                ellipsis.innerText = "...";
                ellipsis.classList.add("tw-mx-1");
                value.append(ellipsis);
            }

            const listLink = document.createElement("a");
            listLink.classList.add("subtle-link");
            listLink.classList.add("tw-inline-block");
            listLink.classList.add("tw-whitespace-nowrap");
            listLink.classList.add("!tw-m-0.5");
            listLink.innerText = getTranslation("Animal list");
            listLink.prepend(filterSymbol.cloneNode(true));
            listLink.addEventListener("click", () => mainMenu.openAndResetListFilter("get_animal_list", {
                animalid: event.animals.map((a) => a.animal_id),
            }));
            value.append(listLink);

            container.append(value);

        }

        if (event.pups?.length) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            if (event.pups.length > 1) {
                label.innerText = getTranslation("Pups") + ` (${event.pups.length})`;
            } else {
                label.innerText = getTranslation("Pup");
            }
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            event.pups.forEach((pup, index) => {
                if (index > 2) {return;}
                const link = document.createElement("a");
                link.classList.add("subtle-link");
                link.classList.add("tw-inline-block");
                link.classList.add("tw-whitespace-nowrap");
                link.classList.add("tw-mx-0.5");
                link.innerText = pup.eartag;
                link.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("pupdetail.py"), {
                        animalid: pup.pup_id,
                    }));
                    this.close();
                });
                value.append(link);
            });
            if (event.pups.length > 3) {
                const ellipsis = document.createElement("span");
                ellipsis.innerText = "...";
                ellipsis.classList.add("tw-mx-1");
                value.append(ellipsis);
            }

            const listLink = document.createElement("a");
            listLink.classList.add("subtle-link");
            listLink.classList.add("tw-inline-block");
            listLink.classList.add("tw-whitespace-nowrap");
            listLink.classList.add("!tw-m-0.5");
            listLink.innerText = getTranslation("Pup list");
            listLink.prepend(filterSymbol.cloneNode(true));
            listLink.addEventListener("click", () => mainMenu.openAndResetListFilter("get_pup_list", {
                pupid: event.pups.map((p) => p.pup_id),
            }));
            value.append(listLink);

            container.append(value);

        }

        if (event.stud_males?.length) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            if (event.stud_males.length > 1) {
                label.innerText = getTranslation("Stud males") + ` (${event.stud_males.length})`;
            } else {
                label.innerText = getTranslation("Stud male");
            }
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            event.stud_males.forEach((studMale, index) => {
                if (index > 2) {return;}
                const link = document.createElement("a");
                link.classList.add("subtle-link");
                link.classList.add("tw-inline-block");
                link.classList.add("tw-whitespace-nowrap");
                link.classList.add("tw-mx-0.5");
                link.innerText = studMale.eartag;
                link.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("mousedetail.py"), {
                        animalid: studMale.animal_id,
                    }));
                    this.close();
                });
                value.append(link);
            });
            if (event.stud_males.length > 3) {
                const ellipsis = document.createElement("span");
                ellipsis.innerText = "...";
                ellipsis.classList.add("tw-mx-1");
                value.append(ellipsis);
            }

            container.append(value);

        }

        if (event.tanks?.length) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            if (event.tanks.length > 1) {
                label.innerText = getTranslation("Tanks") + ` (${event.tanks.length})`;
            } else {
                label.innerText = getTranslation("Tank");
            }
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            event.tanks.forEach((tank, index) => {
                if (index > 2) {return;}
                const link = document.createElement("a");
                link.classList.add("subtle-link");
                link.classList.add("tw-inline-block");
                link.classList.add("tw-whitespace-nowrap");
                link.classList.add("tw-mx-0.5");
                link.innerText = `${tank.tank_id}`;
                link.addEventListener("click", () => {
                    showTankDetails({
                        tankId: tank.tank_id,
                    });
                    this.close();
                });
                value.append(link);
            });
            if (event.tanks.length > 3) {
                const ellipsis = document.createElement("span");
                ellipsis.innerText = "...";
                ellipsis.classList.add("tw-mx-1");
                value.append(ellipsis);
            }

            const listLink = document.createElement("a");
            listLink.classList.add("subtle-link");
            listLink.classList.add("tw-inline-block");
            listLink.classList.add("tw-whitespace-nowrap");
            listLink.classList.add("!tw-m-0.5");
            listLink.innerText = getTranslation("Tank list");
            listLink.prepend(filterSymbol.cloneNode(true));
            listLink.href = getUrl(baseUrl("/frontend/redirect/frontend:v1:get_tank_list"), {
                "tank_id": event.tanks.map((t) => t.tank_id).join(","),
            });
            value.append(listLink);

            container.append(value);

        }

        if (event.new_project) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            label.innerText = getTranslation("Project");
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            const newProject = event.new_project;
            const link = document.createElement("a");
            link.classList.add("subtle-link");
            link.classList.add("tw-inline-block");
            link.classList.add("tw-whitespace-nowrap");
            link.classList.add("tw-mx-0.5");
            link.classList.add("cage-link");
            link.innerText = newProject.name;
            link.addEventListener("click", () => {
                showProjectDetails({
                    projectId: newProject.id,
                });
                this.close();
            });
            value.append(link);

            container.append(value);

        }

        if (event.order_requests?.length) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            if (event.order_requests.length > 1) {
                label.innerText = getTranslation("Order requests") + ` (${event.order_requests.length})`;
            } else {
                label.innerText = getTranslation("Order request");
            }
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            event.order_requests.forEach((orderRequest, index) => {
                if (index > 2) {return;}
                const link = document.createElement("a");
                link.classList.add("subtle-link");
                link.classList.add("tw-inline-block");
                link.classList.add("tw-whitespace-nowrap");
                link.classList.add("tw-mx-0.5");
                link.classList.add("cage-link");
                link.innerText = `#${orderRequest.order_request_id}`;
                link.addEventListener("click", () => {
                    showOrderRequestDetails({
                        orderRequestId: orderRequest.order_request_id,
                    });
                    this.close();
                });
                value.append(link);
            });
            if (event.order_requests.length > 3) {
                const ellipsis = document.createElement("span");
                ellipsis.innerText = "...";
                ellipsis.classList.add("tw-mx-1");
                value.append(ellipsis);
            }

            const listLink = document.createElement("a");
            listLink.classList.add("subtle-link");
            listLink.classList.add("tw-inline-block");
            listLink.classList.add("tw-whitespace-nowrap");
            listLink.classList.add("!tw-m-0.5");
            listLink.innerText = getTranslation("Order request list");
            listLink.prepend(filterSymbol.cloneNode(true));
            listLink.href = getUrl(baseUrl("/frontend/redirect/frontend:v1:get_order_request_list"), {
                "id": event.order_requests.map((o) => o.order_request_id).join(","),
            });
            value.append(listLink);

            container.append(value);

        }

        if (event.service_requests?.length) {

            const label = document.createElement("div");
            label.classList.add("tw-whitespace-nowrap");
            label.classList.add("tw-col-start-2");
            if (event.service_requests.length > 1) {
                label.innerText = getTranslation("Service requests") + ` (${event.service_requests.length})`;
            } else {
                label.innerText = getTranslation("Service request");
            }
            container.append(label);

            const value = document.createElement("div");
            value.classList.add("tw-max-w-[300px]");
            event.service_requests.forEach((serviceRequest, index) => {
                if (index > 2) {return;}
                const link = document.createElement("a");
                link.classList.add("subtle-link");
                link.classList.add("tw-inline-block");
                link.classList.add("tw-whitespace-nowrap");
                link.classList.add("tw-mx-0.5");
                link.classList.add("cage-link");
                link.innerText = `#${serviceRequest.service_request_id}`;
                link.addEventListener("click", () => {
                    frames.detailPopup.open(getUrl(cgiScript("service_request_detail.py"), {
                        request_id: serviceRequest.service_request_id,
                    }));
                    this.close();
                });
                value.append(link);
            });
            if (event.service_requests.length > 3) {
                const ellipsis = document.createElement("span");
                ellipsis.innerText = "...";
                ellipsis.classList.add("tw-mx-1");
                value.append(ellipsis);
            }

            const listLink = document.createElement("a");
            listLink.classList.add("subtle-link");
            listLink.classList.add("tw-inline-block");
            listLink.classList.add("tw-whitespace-nowrap");
            listLink.classList.add("!tw-m-0.5");
            listLink.innerText = getTranslation("Service request list");
            listLink.prepend(filterSymbol.cloneNode(true));
            listLink.href = getUrl(baseUrl("/frontend/redirect/frontend:v1:get_service_request_list"), {
                "id": event.service_requests.map((s) => s.service_request_id).join(","),
            });
            value.append(listLink);

            container.append(value);

        }

    }
}

export const actionLog = new ActionLog();
