import * as _ from "lodash";
import * as URI from "urijs";

import { ApiError } from "../backend/v1";

import { assert } from "./assert";
import { EartagUtils } from "./eartagUtils";
import { writeException } from "./excepthook";
import {
    parseDate,
    formatDate,
} from "./flatpickr";
import { getTranslation } from "./localize";
import { session } from "./pyratSession";
import { notifications } from "./pyratTop";

export { raiseTestException } from "./excepthook";
export { EartagUtils };

/**
 * Return true, if the given element has a DOM parent element with the given query selector.
 * This can be used e.g. to figure out if a clicked element is inside some container.
 *
 * > hasParentWithMatchingSelector(event.target, '.container')
 * true
 *
 * @param target Element to start search from.
 * @param selector Selector required on the parent element.
 */
export function hasParentWithMatchingSelector(target: Element, selector: string): boolean {
    return [...document.querySelectorAll(selector)].some(el =>
        el !== target && el.contains(target),
    );
}

interface ElProperties {
    [key: string]: any;
    classList?: string[];
}


/** Helper to simplify creation of HTML elements.
 *
 * Later we want to use JSX for this, but until this is in place, we can use this helper function.
 * **/
export function el<K extends keyof HTMLElementTagNameMap>(tagName: K,
    properties: ElProperties = {},
    ...nodes: (Node | string)[]): HTMLElementTagNameMap[K] {
    const element = document.createElement(tagName);

    for (const qualifiedName of Object.keys(properties)) {
        if (qualifiedName === "classList") {
            element.classList.add(...properties["classList"]);
        } else {
            element.setAttribute(qualifiedName, properties[qualifiedName]);
        }
    }

    element.append(...nodes);
    return element;
}


/** Get the type of the values of a `Record` similar to `keyof`.
 */
export type ValueOf<T> = T[keyof T];


/** Add index signature to an interface.
 *
 * See https://github.com/microsoft/TypeScript/issues/15300#issuecomment-927445653 for reasoning.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type IndexSignature<O extends object> = {
  [P in keyof O]: O[P];
};


/** Show the browser loading indicator while the given promise is pending.
 *
 * @param promise
 */
export const showLoading = <T>(promise: T): T => {
    try {
        // @ts-expect-error: window.navigation and NavigateEvent are in draft
        window.navigation.addEventListener(
            "navigate",
            // @ts-expect-error: window.navigation and NavigateEvent are in draft
            (e: NavigateEvent) => {
                e.intercept({
                    scroll: "manual",
                    handler: () => promise,
                });
            },
            { once: true },
        );
    } catch {
        return promise;
    }

    return new Promise((resolve, reject) => {
        // @ts-expect-error: window.navigation and NavigateEvent are in draft
        window.navigation.navigate(location.href).finished.then(() => resolve(promise), reject);
    }) as T;
};

/**
 * Prepend the cgi url to the givens script path.
 *
 * Also mark the file as cgi, to make it easier to find cgi references later.
 */
export function cgiScript(path: string): string {
    return window.baseUrl + "cgi-bin/" + path;
}


/**
 * Prepend the baseUrl to the givens script path.
 */
export function baseUrl(path: string): string {
    return window.baseUrl + path;
}


/**
 * Create a URL string with optional GET parameters.
 *
 * If params contains any pre-existing name-value pair (duplicate keys), it's value will be replaced.
 * Non-existing (unique) name-value pairs will be appended to query string.
 *
 * > utils.url('tank_detail.py')
 * "tank_detail.py&sessionid=…"
 *
 * > utils.url('tank_detail.py', {a: 1})
 * "tank_detail.py?a=1&sessionid=…"
 *
 * > utils.url('tank_detail.py?a=1', {a: 2})
 * "tank_detail.py?a=1&a=2&sessionid=…"
 *
 * > utils.url('tank_detail.py', {a: 1, a: 2})
 * "tank_detail.py?a=2&sessionid=…"
 *
 * > utils.url('tank_detail.py', {a: [1, 2]})
 * "tank_detail.py?a=1&a=2&sessionid=…"
 *
 * > utils.url('tank_detail.py?a=1', {b: 2})
 * "tank_detail.py?a=1&b=2&sessionid=…"
 *
 * > utils.url('tank_detail.py', {b: 2}, {addSession: false})
 * "tank_detail.py?b=2"
 *
 * > utils.url('tank_detail.py', {a: 1}, {absoluteUrl: true})
 * "http://127.0.0.1/pyrat/cgi-bin/tank_detail.py?a=1&sessionid=…"
 *
 * If required, we may create a similar function fur relative URLs later based on:
 *  - the relateurl package
 *    (https://github.com/stevenvachon/relateurl)
 *  - URL.relative(), if the WHATWG proposal gets accepted
 *    (https://github.com/whatwg/url/issues/421)
 *
 * @param path - Absolute or relative url to access.
 * @param params - Mapping of key value pairs to add to the query part (GET parameter).
 * @param absoluteUrl - If true, force creation of an absolute url.
 * @param clearParams - If true, do not obtain the current query parameters from the path.
 * @param clearHash - If true, do not obtain the current hash from the path.
 * @param addSession - If true (default), add the window.sessionid as sessionid parameter.
 */
export function getUrl(
    path: string,
    params: { [key: string]: string | number | (string | number)[] } = {},
    {
        absoluteUrl = false,
        clearParams = false,
        clearHash = false,
        addSession = true,
    } = {},
): string {
    // See http://medialize.github.io/URI.js/docs.html for options.
    const uri = URI(path);
    if (clearParams) {
        uri.removeSearch(/.*/);
    }
    if (clearHash) {
        uri.hash("");
    }
    if (addSession) {
        uri.setSearch({ sessionid: session.sessionId });
    }
    uri.setSearch(params);
    if (absoluteUrl) {
        return uri.absoluteTo(window.location.href).toString();
    } else if (!uri.is("relative")) {
        return uri.relativeTo(window.location.href).toString();
    } else {
        return uri.toString();
    }
}

/** Start a new FormData object with the given param values appended.
 *
 * > utils.getFormData({a: 1, b: 2})
 * FormData object like: a=1&b=2&sessionid=…
 *
 * > utils.getFormData({a: 1, b: 2}, {addSession: false})
 * FormData object like: a=1&b=2
 *
 * > utils.getFormData({a: [1, 2], b: 3})
 * FormData object like: a=1&a=2&b=3&sessionid=…
 *
 * @param params -  Mapping of key value pairs to add to the form.
 * @param addSession - If true, add the window.sessionid as sessionid parameter.
 */
export function getFormData(
    params: { [key: string]: string | number | (string | number)[] } = {},
    {
        addSession = true,
    } = {},
): FormData {
    const form = new FormData();
    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((v) => form.append(key, v.toString()));
        } else {
            form.set(key, value.toString());
        }
    });
    if (addSession) {
        form.set("sessionid", session?.sessionId);
    }
    return form;
}


/**
 * Open a page in the current window, using POST in a virtual form element.
 *
 * @param path - The URL to open.
 * @param data - The Data to send in the POST request.
 * @param target - The target window to open the URL in.
 */
export const sendPostForm = (path: string, data: { [key: string]: string | string[] | number | number[] }, { target = "_self" } = {}): void => {

    // add the session id to the data
    data.sessionid = session.sessionId;

    // prepare the form data
    const form = document.createElement("form");
    for (const name in data) {
        if (Object.prototype.hasOwnProperty.call(data, name)) {
            const value = data[name];
            if (Array.isArray(value)) {
                for (const arrayValue of value) {
                    const input = document.createElement("input");
                    input.setAttribute("name", name);
                    input.value = String(arrayValue);
                    form.append(input);
                }
            } else if (typeof value === "string" || typeof value === "number") {
                const input = document.createElement("input");
                input.setAttribute("name", name);
                input.value = String(value);
                form.append(input);
            } else {
                throw new Error("Invalid value type");
            }
        }
    }

    // set the form attributes
    form.setAttribute("action", getUrl(path, {}, { addSession: false }));
    form.setAttribute("target", target);
    form.setAttribute("method", "post");
    form.style.display = "none";

    // old browsers require the form to be inside the body
    document.querySelector("body").append(form);

    form.submit();
    form.remove();
};

/**
 * The is a generic to wrap around data returned from `ajax.send_json_response`
 * in conjunction with `ajax.error`, `ajax.success` and `ajax.confirm` on the python server side.
 */
type errorMessage<T> = { success: false; message: string } & T;
type confirmMessage<T> = { success: false; message: string; confirm: string } & T;

export type AjaxResponse<T> = ({ success: true } & T) | errorMessage<T>;
export type ConfirmableAjaxResponse<T> = ({ success: true } & T) | errorMessage<T> | confirmMessage<T>;

/** Helper to describe an actually empty object **/
// eslint-disable-next-line @typescript-eslint/ban-types
export type EmptyObject = {};


/** Promise that transports an arbitrary value.
 *
 * This is useful to transport a value through a promise chain without
 * having to wrap it in an object.
 * */
export class ArbitraryValue<T> implements Promise<T> {
    readonly [Symbol.toStringTag]!: string;
    private _promise: Promise<T>;

    constructor(value: T) {
        this._promise = new Promise((resolve) => resolve(value));
    }
    public then<TResult1 = T, TResult2 = never>(
        onFulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null,
        onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null,
    ): Promise<TResult1 | TResult2> {
        return this._promise.then(onFulfilled, onRejected);
    }

    public catch<TResult = never>(
        onRejected?: ((reason: any) => TResult | PromiseLike<TResult>) | null,
    ): Promise<T | TResult> {
        return this._promise.catch(onRejected);
    }

    public finally(onFinally?: (() => void) | null): Promise<T> {
        return this._promise.finally(onFinally);
    }
}

// Helper type alias for the type of the elements in an array or tuple.
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

/**
 * Generate a Fetch-Response Promise object with a predefined body.
 *
 * For example, to avoid making a request where we know the result will
 * be empty, but a Promise<Response> is required as return type.
 *
 * @param body: String of the response body.
 */
export function staticFetchResponse(body: string): Promise<Response> {
    return Promise.resolve(new Response(body));
}

/**
 * Verify that the string str represents a positive decimal number
 *
 * If digitsInt is specified, the digits to the left of the decimal
 * point are checked to not be longer than digitsInt.
 * If digitsFrac is specified, the digits to the right of the decimal
 * point are checked to not be longer than digitsFrac.
 * Return false, if str is a decimal number within the given limits.
 * Return error message, if there is no valid decimal number in str.
 */
export function checkDecimal(str: string,
    decimalSymbol: string,
    digitsInt: number,
    digitsFrac: number): false | string {

    const split = (str || "").toString().split(decimalSymbol);
    const ints = split[0];
    const fractions = split[1];
    let isNumber: boolean;

    if (split.length === 1) {
        isNumber = /^[0-9]+$/g.test(str);
    } else if (split.length === 2) {
        if (ints === "") {
            isNumber = /^[0-9]+$/g.test(fractions);
        } else {
            isNumber = /^[0-9]+$/g.test(ints) && /^[0-9]+$/g.test(fractions);
        }
    } else {
        isNumber = false;
    }

    if (!isNumber) {
        return getTranslation("Not a decimal");
    }

    if (digitsInt !== undefined) {
        const pos = ints.search(/[1-9]/);
        if (pos >= 0 && ints.length - pos > digitsInt) {
            return getTranslation("Too many digits in the integral part, allowed") + ": " + digitsInt;
        }
    }
    if (split.length === 2 && digitsFrac !== undefined) {
        const pos = fractions.search(/[1-9](?!.*[1-9])/);
        if (pos + 1 > digitsFrac) {
            return getTranslation("Too many digits in the fractional part, allowed") + ": " + digitsFrac;
        }
    }
    return false;
}

/**
 * Create a dictionary from a list for faster access by an index
 *
 * If the key is defined, the value belonging to key
 * parameter in each object will be the key in the resulting
 * dictionary and the object itself will be the corresponding value
 * for the dictionary key.
 *
 * If the key is undefined the list items will be used as
 * keys which all have true as dictionary value.
 *
 * Return the created dictionary.
 */
export function createIndex<T>(list: { [key: string]: T }[], key: string): { [key: string]: T };
export function createIndex(list: string[], key: undefined): { [key: string]: boolean };
export function createIndex(list: any[], key: undefined | string): { [key: string]: boolean } {
    const res = {};
    list.forEach((item) => {
        if (key) {
            // @ts-expect-error: TypeScript is unable to get the correct type from the overload.
            res[item[key]] = item;
        } else {
            // @ts-expect-error: TypeScript is unable to get the correct type from the overload.
            res[item] = true;
        }
    });
    return res;
}

/**
 * Distribute a number of animals across a number of cages
 *
 * Specify either the number of cages or a per_cage number which
 * determines the number of necessary cages indirectly (per_cage and
 * cages must not be specified at the same time).
 *
 * Return a list of integers where each list element holds the number
 * of animals in one cage (the length of the list equals the amount of
 * cages).
 */
export function distributeAnimals(animals: number, per_cage: number, cages: number): number[] {
    if (animals < 1) {
        return [];
    }

    if (cages && per_cage) {
        throw "please supply only one of percage or cages";
    }
    if (per_cage) {
        cages = Math.floor(animals / per_cage) + ((animals % per_cage) ? 1 : 0);
    } else if (cages) {
        if (cages < 1) {
            throw "cages must be >= 1";
        }
    }

    const basePercage = Math.floor(animals / cages);
    const animalsLeftover = animals % cages;
    const cageDist = [];

    for (let i = 0; i < cages; i++) {
        const remainingCages = cages - i;
        if (remainingCages > animalsLeftover) {
            cageDist.push(basePercage);
        } else {
            cageDist.push(basePercage + 1);
        }
    }

    return cageDist;
}

/**
 * Return list of all keys in obj.
 */
export function getKeysOfObject<T>(obj: T): (keyof T)[] {
    const keys: (keyof T)[] = [];
    for (const property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            keys.push(property);
        }
    }
    return keys;
}

/**
 * Check the eartag prefix.
 * Return True if the prefix is invalid, False if valid.
 */
export function isInvalidEartagPrefix(prefix: string): boolean {
    const prefixRegExp = /^([a-zA-Z0-9]+)$/; // alphanumeric
    return !(prefix.length === session.pyratConf.PREFEARTAGSIZE && prefixRegExp.test(prefix));
}

/**
 * Check the eartag suffix.
 * Return True if the suffix is invalid, False if valid.
 */
export function isInvalidEartagSuffix(suffix: string): boolean {
    const suffixRegExp = /^([a-zA-Z]*[0-9]*)$/;
    const eartagUtils = new EartagUtils();
    return !(suffix.length === eartagUtils.suffixEartagSize && suffixRegExp.test(suffix));
}

/**
 * Check the cage prefix.
 * Return True if the prefix is invalid, False if valid.
 */
export function isInvalidCagePrefix(prefix: string): boolean {
    return isInvalidEartagPrefix(prefix); // same rule for cages as for eartags
}

/**
 * Check the cage suffix.
 * Return True if the suffix is invalid, False if valid.
 */
export function isInvalidCageSuffix(suffix: string): boolean {
    const suffixRegExp = /^([0-9]*)$/;
    return !(suffix.length === session.pyratConf.CAGESUFSIZE && suffixRegExp.test(suffix));
}

// Re-export date functions for easy access
export { formatDate, parseDate } from "./flatpickr";

/**
 * Format the given iso date string, using the configured user locale.
 *
 * @param value: Time as ISO8601 string.
 */
export function formatIsoDate(value: string): string {
    const date = new Date(value);
    return formatDate(date);
}

/**
 * Format the given iso date string, using the configured user locale.
 *
 * @param value: Time as number of milliseconds since January 1, 1970, 00:00:00 UTC.
 * Be aware that this is not for seconds, what is the default value for Unix timestamps.
 * Multiply it by 1000 to get milliseconds if needed.
 */
export function formatTimestampDate(value: number): string {
    const date = new Date(value);
    return formatDate(date);
}


/**
 * Check if value represents global datepicker format
 * and given year matches sane boundaries.
 */
export function isInvalidCalendarDate(value: string): boolean {
    let isInvalid = true;
    const minYear = 2001;
    const maxYear = (new Date()).getFullYear() + 20;

    try {
        const dateParsed = parseDate(value);

        if (dateParsed && formatDate(dateParsed) === value) {
            // check if date fits signed 32bit integer
            // see: http://en.wikipedia.org/w/index.php?title=Y2K38
            isInvalid = !(dateParsed.getFullYear() >= minYear &&
                dateParsed.getFullYear() <= maxYear &&
                dateParsed < new Date(2147483647 * 1000));
        }
    } catch (e) {
        // pass
    }

    return isInvalid;
}

/**
 * Check if date1 is lower than date2 using global datepicker format.
 */
export function isDateLowerThanDate(date1: string, date2: string): boolean {
    assert(typeof date1 === "string", "date1 must be string not " + typeof date1);
    assert(typeof date2 === "string", "date2 must be string not " + typeof date2);
    return parseDate(date1) < parseDate(date2);
}

/**
 * Compare if the second date value is lower than the first date value.
 *
 * @returns: Error message when the date range is not correct (`toDateValue` is before `v`),
 *           otherwise false.
 */
export function compareFromDate(v: string, toDateValue: string, forbidSameDate: boolean): false | string {
    if (isDateLowerThanDate(toDateValue, v)) {
        return getTranslation("The start date cannot be after the end date");
    }

    if (forbidSameDate && !isDateLowerThanDate(v, toDateValue)) {
        return getTranslation("Dates must be different");
    }

    return false;
}

/**
 * Compare if the first date value is lower than the second date value
 *
 * @returns: Error message when the date range is not correct (`v` is before `fromDateValue`), otherwise false.
 */
export function compareToDate(v: string, fromDateValue: string, forbidSameDate: boolean): false | string {
    if (isDateLowerThanDate(v, fromDateValue)) {
        return getTranslation("The start date cannot be after the end date");
    }

    if (forbidSameDate && !isDateLowerThanDate(fromDateValue, v)) {
        return getTranslation("Dates must be different");
    }

    return false;
}

/**
 * Check an input field that is part of a date range input field pair
 *
 * @param v: Date value that must be checked for errors
 *
 * @param siblingValueFunc: Receive date value in the other
 * field of the date range input field pair as String;
 * `siblingValueFunc` can be a ko.observable
 *
 * @param compareFunc: Receive true value when the start
 * date is greater than the end date; when the start
 * date input field is verified, utils.compareFromDate should be passed,
 * when the end date input field is verified, utils.compareToDate should
 * be passed
 *
 * @param fillBothFields: If true, either both of the fields must have a valid
 *  date value or they must both be empty.
 *
 * @param mandatory: If true, both fields must have a valid date value.
 * @param forbidSameDate: If true, the two input fields must not have the same date.
 * @returns: Error message when something is wrong, or false when `v` is valid.
 */
export function checkDateRangeField(
    v: string,
    siblingValueFunc: () => string,
    compareFunc: (arg0: string, arg1: string, arg2: boolean) => false | string,
    fillBothFields = false,
    mandatory = false,
    forbidSameDate = false,
): false | string {
    const siblingValue = typeof siblingValueFunc === "function" ? siblingValueFunc() : siblingValueFunc;

    if (!v && !fillBothFields && !mandatory) {
        // empty values are okay when not mandatory or both input fields must be filled
        return false;
    }

    if ((!v && mandatory) || (v && isInvalidCalendarDate(v))) {
        return getTranslation("Invalid date");
    }

    if (siblingValue && !isInvalidCalendarDate(siblingValue)) {
        // only when the sibling value is valid, compare `v` with it
        if (!v && fillBothFields) {
            return getTranslation("Invalid date");
        }

        return compareFunc(v, siblingValue, forbidSameDate);
    }

    return false;
}

/**
 * Normalize a date value, format according to current dateFormat
 *
 * @param v: Date value that must be normalized.
 * @returns: formatted date, when `v` is a valid date, otherwise just `v`.
 */
export function normalizeDate(v: string): string {
    try {
        return formatDate(parseDate(v));
    } catch (ignore) {
        // ignore exceptions
    }
    return v;
}


/**
 * Add the given number of days to the given date
 *
 * @param date: The date value.
 * @param numDays: The number of days.
 * @returns: The result date.
 */
export function addDays(date: Date, numDays: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + numDays);
    return result;
}

/**
 * Add the given number of working days to the given date (skip Saturdays and Sundays).
 *
 * @param date: The date value.
 * @param numDays: The number of days.
 * @returns: The result date.
 */
export function addWorkDays(date: Date, numDays: number): Date {
    const result = new Date(date);

    result.setDate(result.getDate() + numDays);

    while (_.includes(session.localesConf.weekendDays, result.getDay())) {
        result.setDate(result.getDate() + 1);
    }

    return result;
}

/**
 * Add the given number of weeks to the given date
 *
 * @param date: The date value.
 * @param numWeeks: The number of weeks.
 * @returns: The result date.
 */
export function addWeeks(date: Date, numWeeks: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + numWeeks * 7);
    return result;
}

/**
 * Add the given number of months to the given date
 *
 * @param date: The date value.
 * @param numMonths: The number of months.
 * @returns: The result date.
 */
export function addMonths(date: Date, numMonths: number): Date {
    const result = new Date(date);

    result.setMonth(result.getMonth() + numMonths);
    if (result.getDate() !== date.getDate()) {
        result.setDate(0);  // last day of previous month
    }

    return result;
}

/*
 * Get the window object of any element.
 */
export function getElementWindow(element: Element): Window {
    const document = element.ownerDocument;
    // @ts-expect-error: The parentWindow property is only supported in Internet Explorer.
    return document.defaultView || document.parentWindow;
}

/*
 * Get the position of the element window (frame) in relation
 * to the topmost window.
 */
export function getWindowViewportPosition(element: HTMLElement): { left: number; top: number } {
    const elementWindow = getElementWindow(element);
    let currentWindow = elementWindow;
    let left = 0;
    let top = 0;

    // do not compare window elements using === or !==
    while (elementWindow.top != currentWindow) {
        left = left + currentWindow.frameElement.getBoundingClientRect().left;
        top = top + currentWindow.frameElement.getBoundingClientRect().top;
        currentWindow = getElementWindow(currentWindow.frameElement);
    }

    return { left: left, top: top };
}

interface ElementViewportPosition {
    left: number;
    top: number;
    width: number;
    height: number;
}

/*
 * Get the position of the given element in relation
 * to the topmost window.
 */
export function getElementViewportPosition(element: HTMLElement): ElementViewportPosition {
    const position = getWindowViewportPosition(element);
    return {
        left: position.left + element.getBoundingClientRect().left,
        top: position.top + element.getBoundingClientRect().top,
        width: element.offsetWidth,
        height: element.offsetHeight,
    };
}

/*
 * Reload the given window (windowObject) and append the given params to url,
 * or update value if param is already in url.
 *
 * @param absoluteUrl - If true, force creation of an absolute url.
 * @param clearParams - If true, do not obtain the query parameters from current url.
 */
export function reloadWindow(
    windowObject: Window,
    params?: { [key: string]: string | number },
    { absoluteUrl = true, clearParams = false } = {},
): void {
    windowObject.location.href = getUrl(windowObject.location.href, params, { absoluteUrl, clearParams });
}

export interface ReadableByteSize {
    "string": string;
    value: number;
    valuePartInt: number;
    valuePartFrac: number;
    unit: string;
}

/**
 * Convert a number of bytes to a human readable size description.
 *
 * Same function exists as `localize.Locale.format_byte_size` in python.
 *
 * @param bytes: Number of bytes - mandatory
 * @param precision: How many digits after decimal delimiter - optional, default: 1
 * @param iec: Use IEC prefixes (power of 1024) - optional, default: true
 * @param fillPrecision: Fill with zeros to reach precision - optional, default: false
 * @returns: Example:
 *      utils.getReadableByteSizeObject(123456) =>
 *      {
 *          string: "120,6 KiB",    # fully formatted string - ready to use
 *          value: 120.6,           # raw rounded value
 *          valuePartInt: 120,      # the integer part of value
 *          valuePartFrac: 6,       # the fraction part of value
 *          unit: "KiB"             # unit string
 *      }
 */
export function getReadableByteSizeObject(bytes: number,
    precision: number | string = 1,
    iec = true,
    fillPrecision = false): ReadableByteSize {
    if (typeof precision === "string") {
        precision = parseInt(precision, 10);
    }

    const units = iec ? ["B", "KiB", "MiB", "GiB", "TiB"] : ["B", "kB", "MB", "GB", "TB"];
    const div = iec ? 1024 : 1000;

    let i = 0;
    for (; i < units.length && bytes >= div; i++) {
        bytes /= div;
    }

    const unit = units[i];
    const bytesFixed = bytes.toFixed(precision);
    const bytesFixedLocale = bytesFixed.replace(".", session.localesConf.decimalSymbol);
    const value = parseFloat(bytesFixed);
    const splitValue = bytesFixed.split(".", 2);
    const valuePartInt = parseInt(splitValue[0], 10);
    const valuePartFrac = parseInt(splitValue[1] || "0", 10);

    return {
        string: fillPrecision ?
            `${bytesFixedLocale} ${unit}` :
            `${valuePartInt}${valuePartFrac ? session.localesConf.decimalSymbol + valuePartFrac : ""} ${unit}`,
        value: value,
        valuePartInt: valuePartInt,
        valuePartFrac: valuePartFrac,
        unit: unit,
    };
}

/**
 * Get current date for datepicker
 * (based on dateFormat {String} definition in global datepicker configuration)
 *
 * @returns: Current date without time.
 */
export function getFormattedCurrentDate(): string {
    return formatDate(new Date());
}

/**
 * Compare two strings that consists of a mix of letters and numbers.
 *
 * This is intended to be used as argument for Array.sort().
 *
 * Example:
 *   < ['A2a9', 'A2b9', 'A2a7', 'A2a10'].sort(utils.naturalCompare)
 *   > ["A2a7", "A2a9", "A2a10", "A2b9"]
 *
 * @param a: Left String.
 * @param b: Right String.
 * @returns: Offset as positive or negative number.
 */
export function naturalCompare(a: string, b: string): number {
    const ax: any[] = [];
    const bx: any[] = [];

    a.replace(/(\d+)|(\D+)/g, (_: string, $1, $2) => {
        ax.push([$1 || Infinity, $2 || ""]);
        return undefined;
    });
    b.replace(/(\d+)|(\D+)/g, function (_: string, $1, $2) {
        bx.push([$1 || Infinity, $2 || ""]);
        return undefined;
    });

    let an;
    let bn;
    let nn;
    while (ax.length && bx.length) {
        an = ax.shift();
        bn = bx.shift();
        nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if (nn) return nn;
    }

    return ax.length - bx.length;
}


/** Once call the given function, as soon as the document is "ready" or immediately if it already is.
 *
 * This means, the document has finished loading and the document has been parsed
 * but sub-resources such as images, stylesheets and frames may be still loading.
 * The Document Object Model (DOM) is now safe to manipulate.
 *
 * This is a replacement of giving a function as the first argument to jQuery.
 * -> https://api.jquery.com/jquery/#jQuery3
 *
 * @param d: Document to wait for.
 * @param fn: Function to call.
 */
export function onceDocumentLoaded(d: Document, fn: () => void): void {
    if (d.readyState !== "loading") {
        fn();
    } else {
        d.addEventListener("DOMContentLoaded", fn, { once: true });
    }
}


/** Once call the given function, as soon as the document is "complete" or immediately if it already is.
 *
 * This means, the document and all sub-resources have finished loading.
 *
 * @param d: Document to wait for.
 * @param fn: Function to call.
 */
export function onceDocumentComplete(d: Document, fn: () => void): void {
    if (d.readyState === "complete") {
        fn();
    } else {
        d.addEventListener("load", fn, { once: true });
    }
}


/** Encode text before inserting into HTML element content.
 *
 * Escape the following characters with HTML entity encoding to prevent switching into any execution
 * context (e.g. script, style, or event handlers) using hex entities. In addition to the 5 characters
 * significant in XML (&, <, >, ", '), the forward slash is included as it helps to end an HTML entity.
 * (see https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html)
 *
 * @param text: Content being parsed for escaping relevant characters.
 * @returns Encoded text.
 */
export function escapeHtml(text: string): string {
    return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#x27;")
        .replace(/\//g, "&#x2F;");
}


/**  Convert Hex Color to rgba with opacity.
 *
 * - https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
 *
 * @param hexCode: Hex color to convert.
 * @param opacity: Opacity (0..100) to apply.
 */
export function hexToRGBA(hexCode: string, opacity: number): string {
    let hex = hexCode.replace("#", "");
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity / 100})`;
}


/** Open an HTMLElement in a new window and open the print dialog.
 *
 * @param e: HTMLElement to print.
 * @param title: Title of the print dialog and heading in the page.
 * @param obtainStylesheet: If true, the element document stylesheets are copied into the print popup window.
 */
export function printElement(e: HTMLElement, { title = document.title, obtainStylesheet = false } = {}): void {
    const printWindow = window.open("", "PRINT");

    printWindow.document.write(`
        <html lang="en">
            <head>
                <title>${title}</title>
            </head>
            <body>
                <h1>${title}</h1>
                ${e.innerHTML}
            </body>
        </html>
    `);

    if (obtainStylesheet) {
        for (const element of e.ownerDocument.head.children) {
            if (element.getAttribute("rel") === "stylesheet") {
                printWindow.document.head.appendChild(element.cloneNode(true));
            }
        }
    }
    printWindow.document.close();
    // delay to ensure that stylesheets are loaded properly
    setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }, 1000);
}

/** Open the given URL for printing.
 *
 * @param url: The url to print.
 */
export function printUrl(url: string) {
    const printWindow = window.open(url, "PRINT");
    if (printWindow) {
        printWindow.addEventListener("load", () => {
            printWindow.focus();
            // delay to ensure that stylesheets are loaded properly
            setTimeout(() => {
                printWindow.print();
                printWindow.close();
            }, 1000);
        }, { once: true });
    }
}


/**
 * Open a new popup in the center of the screen.
 *
 * Example:
 *   centeredPopup({url: 'http://www.xtf.dk', title: 'xtf', w: 900, h: 500});
 */
export const centeredPopup = (url: string, title: string, w: number, h: number): WindowProxy => {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const left = (window.screen.width / 2) - (w / 2) + dualScreenLeft;
    const top = (window.screen.height / 2) - (h / 2) + dualScreenTop;
    const popoupWindow = window.open(url, title, `
        toolbar=no,
        menubar=no,
        width=${w},
        height=${h},
        top=${top},
        left=${left}
    `);
    if (popoupWindow) {
        return popoupWindow;
    } else {
        // The popup was not allowed to open, we try to open a new tab.
        return window.open(url, title);
    }
};



/**
 * Copies the given text to the clipboard.
 *
 * @param {string} text - The text to be copied to the clipboard.
 * @returns {void}
 */
export const copyToClipboard = (text: string) => {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text);
    }
    // Virtual Element for older browsers that don't support the Clipboard API or are not in a secure context
    else {
        const temporaryElement = document.createElement("input");
        temporaryElement.value = text;
        document.body.appendChild(temporaryElement);
        temporaryElement.select();
        temporaryElement.setSelectionRange(0, text.length); // For mobile devices
        document.execCommand("copy");
        document.body.removeChild(temporaryElement);
    }
};

/** Generic function to handle errors from the backend.
 *
 * @param callback - Function to call with the error message.
 * @param message - Default error message to show if the backend does not provide one.
 */
export const handleBackendError =
    ({
        callback = (error) => notifications.showNotification(error, "error"),
        message = getTranslation("General error."),
    }: {
        callback?: (error: string) => void;
        message?: string;
    } = {}) =>
        (error: ApiError) => {
            if (typeof error?.body?.detail == "string") {
                // The backend gave a detailed error message.
                callback(error.body.detail);
            } else {
                // The backend returned an error, but no detailed message.
                // This should not happen, but we can still show a general
                // error message and escalate the issue.
                callback(message);
                writeException(error);
                throw error;
            }
        };
