import {
    observable,
    Observable,
    unwrap,
} from "knockout";
import { isEqual } from "lodash";

import {
    baseUrl,
    getUrl,
} from "../../lib/utils";


interface BackgroundTaskStatusParams {
    taskId: string;
    onFinalState?: (state: "succeeded" | "failed") => void;
}

interface Status {
    id: string;
    state: "waiting" | "started" | "succeeded" | "failed";
    started_at: string | undefined;  // datetime
    ended_at: string | undefined;  // datetime
    completed_count: number | undefined;
    total_count: number | undefined;
}

class BackgroundTaskStatusViewModel {

    private readonly taskId: string;
    public readonly status: Observable<Status | undefined>;
    private readonly inProgress: Observable<boolean>;

    constructor(params: BackgroundTaskStatusParams) {

        this.taskId = unwrap(params.taskId);
        this.status = observable();
        this.inProgress = observable(true);

        const statusSource = new EventSource(getUrl(baseUrl("frontend/background_task/" + this.taskId)));
        statusSource.onmessage = e => {
            const newValue = JSON.parse(e.data);
            if (!isEqual(newValue, this.status())) {
                this.status(newValue);
            }
        };

        this.status.subscribe((status) => {
            if (status.state == "started" || status.state == "waiting") {
                this.inProgress(true);
            } else if (status.state == "succeeded" || status.state == "failed") {
                statusSource.close();
                this.inProgress(false);
                if (typeof params.onFinalState === "function") {
                    params.onFinalState(status.state);
                }
            }
        });

    }

}


export class BackgroundTaskStatusComponent {

    constructor() {

        return {
            viewModel: BackgroundTaskStatusViewModel,
            template: `
                <span>
                    <span data-bind="visible: inProgress()"
                          style="margin: 0 20px; line-height: 24px;">
                        <span class="two_bubbles_loader_container"
                              style="position: relative">
                            <div class="two_bubbles_loader"></div>
                        </span>
                    </span>
                    <span class="icon_button icon_type_confirm only_icon_img"
                          data-bind="visible: !status(),
                                     attr: {title: getTranslation('unknown')}"></span>
                    <span class="icon_button icon_type_success only_icon_img"
                          data-bind="visible: status() && status().state == 'succeeded',
                                     attr: {title: getTranslation('succeeded')}"></span>
                    <span class="icon_button icon_type_error only_icon_img"
                          data-bind="visible: status() && status().state == 'failed',
                                     attr: {title: getTranslation('failed')}"></span>
                    <!-- ko if: status() && status().total_count && status().completed_count -->
                        <span data-bind="text: _.template(getTranslation('<%- completed_count %> of <%- total_count %> completed'))(status())">
                        </span>
                    <!-- /ko -->
                </span>
            `,
        };
    }
}
