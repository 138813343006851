import {
    observable,
    Observable,
    observableArray,
    ObservableArray,
} from "knockout";

import { TanksService } from "../backend/v1/";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";

import template from "./setTankAgeLevel.html";

import "./tankDetails.scss";

interface AgeLevelOption {
    label: string;
    name: string;
}

interface TankAgeLevelParams {
    tankId: number;
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetTankAgeLevelViewModel {

    private readonly dialog: HtmlDialog;

    /* params */
    public tankId: number;
    public closeCallback: () => void;
    public reloadCallback: () => void;

    /* observables */
    public ageLevel: Observable<string> = observable();
    public readonly error: Observable<string> = observable("");
    public readonly fetchInProgress: Observable<boolean> = observable(false);
    public readonly submitInProgress: Observable<boolean> = observable(false);
    public readonly ageLevelOptions: ObservableArray<AgeLevelOption> = observableArray();

    public submit = () => {
        this.error("");
        this.submitInProgress(true);

        TanksService
            .updateAgeLevel({
                tankId: this.tankId,
                ageLevel: this.ageLevel() || null,
            })
            .then(() => {
                this.dialog.close();
                // reload tank list after submit
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
                notifications.showNotification(getTranslation("Age level changed"), "success");
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.error(reason.body.detail);
                } else {
                    this.error(getTranslation("General error."));
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };

    constructor(dialog: HtmlDialog, params: TankAgeLevelParams) {

        /* params */
        this.dialog = dialog;
        this.tankId = params.tankId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        /* dialog */
        this.dialog.setTitle(getTranslation("Set age level"));

        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });

        // load dialog content
        this.fetchInProgress(true);
        TanksService.getAgeLevels()
            .then((ageLevels) => this.ageLevelOptions(ageLevels))
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.error(reason.body?.detail);
                } else {
                    this.error(getTranslation("General error."));
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));
    }
}


/**
 * Pop dialog for setting the age level ('adult', 'juvenile', 'larva') of all animals in the given cage
 */
export const showSetTankAgeLevel = htmlDialogStarter(SetTankAgeLevelViewModel, template, params => ({
    name: "SetTankAgeLevel",
    width: 400,
    position: { anchor: params.eventTarget },
    closeOthers: true,
}));
