/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_createCompetence } from '../models/Body_createCompetence';
import type { Body_updateCompetence } from '../models/Body_updateCompetence';
import type { Body_updateCompetentUserAssignment } from '../models/Body_updateCompetentUserAssignment';
import type { CompetenceAvailableProcedure } from '../models/CompetenceAvailableProcedure';
import type { CompetenceAvailableSpecies } from '../models/CompetenceAvailableSpecies';
import type { CompetenceAvailableUser } from '../models/CompetenceAvailableUser';
import type { CompetenceDetails } from '../models/CompetenceDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TrainingRecordsService {

    /**
     * Get Available Procedures
     * @returns CompetenceAvailableProcedure Successful Response
     * @throws ApiError
     */
    public static getAvailableProcedures(): CancelablePromise<Array<CompetenceAvailableProcedure>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/training_records/available_procedures',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Available Species
     * @returns CompetenceAvailableSpecies Successful Response
     * @throws ApiError
     */
    public static getAvailableSpecies(): CancelablePromise<Array<CompetenceAvailableSpecies>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/training_records/available_species',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Available Users
     * @returns CompetenceAvailableUser Successful Response
     * @throws ApiError
     */
    public static getAvailableUsers(): CancelablePromise<Array<CompetenceAvailableUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/training_records/available_users',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Competence Detail
     * @returns CompetenceDetails Successful Response
     * @throws ApiError
     */
    public static getCompetenceDetails({
        competenceId,
    }: {
        competenceId: number,
    }): CancelablePromise<CompetenceDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/training_records/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Competence
     * @returns number Successful Response
     * @throws ApiError
     */
    public static updateCompetence({
        competenceId,
        requestBody,
    }: {
        competenceId: (number | null),
        requestBody: Body_updateCompetence,
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/training_records/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Competence
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCompetence({
        competenceId,
    }: {
        competenceId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/training_records/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Competence
     * @returns number Successful Response
     * @throws ApiError
     */
    public static createCompetence({
        requestBody,
    }: {
        requestBody: Body_createCompetence,
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/training_records/competence',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Competent User
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCompetentUserAssignment({
        assignmentId,
        requestBody,
    }: {
        assignmentId: number,
        requestBody?: Body_updateCompetentUserAssignment,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/training_records/assignment/{assignment_id}',
            path: {
                'assignment_id': assignmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
