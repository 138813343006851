import * as ko from "knockout";
import {
    Computed,
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    LicenseClassificationOption,
    LicenseClassificationOptionDelimiter,
    LicenseOption,
    LicensesService,
    ListedCrossing,
    TanksService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import {
    TranslationTemplates,
    getTranslation,
} from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    cgiScript,
    formatIsoDate,
    getUrl,
    isInvalidCalendarDate,
} from "../lib/utils";

import { showTankAnimalImport } from "./tankAnimalImport";
import template from "./tankCrossingDetails.html";


import "/scss/popup_details_common_styles.scss";

interface TankCrossingDetailParams {
    crossingId: number;
    positioningElement?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface PrinterType {
    value?: string;
    label: string;
}

interface Strain {
    id: number;
    name_with_id: string;
    species_id: number;
}

interface DetailData {
    crossing_detail: ListedCrossing;
    tank_strains: Strain[];
}

class TankCrossingDetailsViewModel {

    public readonly dialog: HtmlDialog;
    public licenseDenom: string;

    public noLabelPrinter: PrinterType = { label: getTranslation("Do not print a label.") };
    public newWindowPrinter: PrinterType = { label: getTranslation("Show in new window / tab") };

    public reloadRequired: ko.Observable<boolean> = ko.observable(false);
    public errors: ObservableArray<string>;
    public fetchInProgress: Observable<boolean> = ko.observable(false);
    public submitInProgress: Observable<boolean>;
    public anythingInProgress: PureComputed<boolean>;

    public crossingId: Observable<number>;
    public selectedStrain: ObservableArray<Strain>;
    public strainId: CheckExtended<Observable<number | undefined>>;
    public responsibleId: CheckExtended<Observable<number | undefined>>;
    public licenseId: Observable<number>;
    public classificationId: CheckExtended<Observable<number>>;
    public description: Observable<string>;
    public printer: Observable<PrinterType>;
    public dateOfRecord: CheckExtended<Observable<string | undefined>>;
    public crossingTanks: CheckExtended<Observable<number | undefined>>;
    public raisedTanks: CheckExtended<Observable<number | undefined>>;
    public firstNumber: CheckExtended<Observable<number>>;
    public availableLicenses: FetchBackendExtended<ObservableArray<LicenseOption>>;
    public availableClassifications: FetchBackendExtended<ObservableArray<LicenseClassificationOption|LicenseClassificationOptionDelimiter>>;

    public detailData: Observable<DetailData> = ko.observable();
    public crossingData: Observable<ListedCrossing> = ko.observable();
    public availableLabelTargets: Computed<PrinterType[]>;

    public crossingStatus = () => {
        return this.crossingData()?.status;
    };

    public isInStatus = (...args: string[]) => {
        return _.includes(args, this.crossingStatus());
    };

    public isCompleted = () => {
        return this.crossingData()?.completed;
    };

    public canSetUp = () => {
        return this.strainId.isValid()
            && this.responsibleId.isValid()
            && this.classificationId.isValid()
            && this.crossingTanks.isValid()
            && this.raisedTanks.isValid()
            && (this.printer() === this.noLabelPrinter || this.firstNumber.isValid());
    };

    public canRaise = () => {
        return this.strainId.isValid()
            && this.responsibleId.isValid()
            && this.classificationId.isValid()
            && this.crossingTanks.isValid()
            && this.raisedTanks.isValid();
    };

    public canUpdate = () => {
        return this.strainId.isValid()
            && this.classificationId.isValid()
            && this.dateOfRecord.isValid()
            && this.responsibleId.isValid()
            && this.crossingTanks.isValid()
            && this.raisedTanks.isValid();
    };

    public canDiscard = () => {
        return this.strainId.isValid()
            && this.responsibleId.isValid()
            && this.classificationId.isValid()
            && this.crossingTanks.isValid()
            && this.raisedTanks.isValid();
    };

    public updateCrossing = (
        action: "discard" | "set_up" | undefined,
        successCallback: () => void,
    ) => {
        this.errors.removeAll();
        this.submitInProgress(true);

        TanksService
            .updateCrossing({
                action: action,
                crossingId: this.crossingId(),
                crossingTanks: this.crossingTanks(),
                responsibleId: this.responsibleId(),
                strainId: this.strainId(),
                classificationId: this.classificationId(),
                raisedTanks: this.raisedTanks(),
                recordDate: session.userPermissions.tank_crossing_modify_date ? this.dateOfRecord() : undefined,
                description: this.description(),
            })
            .then(() => {
                this.reloadRequired(true);
                if (typeof successCallback === "function") {
                    successCallback();
                } else {
                    this.dialog.close();
                }
                notifications.showNotification(getTranslation("Crossing updated"), "success");
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errors.push(reason.body.detail);
                } else {
                    this.errors.push(getTranslation("General unexpected error occurred."));
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };

    public openLabelInWindow = () => {
        window.open(getUrl(cgiScript("papercard.py"), {
            kind: "tank_crossing_card",
            subjects: this.crossingId(),
            offset: this.firstNumber() - 1,
        }));
        this.dialog.close();
    };

    public sendLabelToPrinter = () => {
        this.submitInProgress(true);

        TanksService
            .printCrossingLabel({
                crossingId: this.crossingId(),
                printer: this.printer().value,
                offset: this.firstNumber() - 1,
            })
            .then(() => {
                this.dialog.close();
                notifications.showNotification(getTranslation("Tank labels were sent to the printer."), "success");
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errors.push(reason.body.detail);
                } else {
                    this.errors.push(getTranslation("General unexpected error occurred."));
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };

    public setUpCrossing = () => {
        if (this.printer() === this.noLabelPrinter) {
            this.updateCrossing("set_up", undefined);
        } else if (this.printer() === this.newWindowPrinter) {
            this.updateCrossing("set_up", this.openLabelInWindow);
        } else {
            this.updateCrossing("set_up", this.sendLabelToPrinter);
        }
    };

    public raiseCrossing = () => {
        showTankAnimalImport({
            crossingId: this.crossingId(), reloadCallback: () => {
                window.location.reload();
            },
        });
    };

    constructor(dialog: HtmlDialog, params: TankCrossingDetailParams) {

        this.dialog = dialog;
        this.licenseDenom = TranslationTemplates.license({ cap: true, trans: true });

        dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        /* params */
        this.crossingId = ko.observable(params.crossingId);
        this.crossingId.extend({ notify: "always" });
        this.submitInProgress = ko.observable(false);
        this.anythingInProgress = ko.pureComputed(() => {
            return this.fetchInProgress()
                || this.submitInProgress()
                || this.availableLicenses.inProgress()
                || this.availableClassifications.inProgress();
        });
        this.errors = ko.observableArray([]);

        this.selectedStrain = ko.observableArray();
        this.strainId = ko.observable(undefined).extend(
            {
                invalid: value => !_.identity(value),
            },
        );
        this.responsibleId = ko.observable(undefined).extend({
            invalid: value => !_.identity(value),
        },
        );
        this.licenseId = ko.observable();
        this.classificationId = ko.observable().extend({
            invalid: value => {
                if (this.licenseId() && !value) {
                    return getTranslation("Please select a classification");
                }

                return false;
            },
        });
        this.description = ko.observable("");
        this.printer = ko.observable(this.noLabelPrinter);

        this.dateOfRecord = ko.observable().extend({
            invalid: v => !(v && !isInvalidCalendarDate(v)),
        });

        this.crossingTanks = ko.observable().extend({
            normalize: v => v && parseInt(v, 10),
            invalid: v => !(v > 0),
        });

        this.raisedTanks = ko.observable().extend({
            normalize: v => v ? parseInt(v, 10) : null,
            invalid: v => !(_.isNaN(v) || v > -1),
        });
        this.firstNumber = ko.observable(1).extend({
            normalize: v => v && parseInt(String(v), 10),
            invalid: v => !(v > 0),
        });

        this.availableLicenses = ko.observableArray().extend({
            fetchBackend: () => {
                if (this.strainId() && this.selectedStrain()?.[0]?.species_id) {
                    return LicensesService.getLicenseOptions({
                        speciesId: this.selectedStrain()[0].species_id,
                        strainId: [this.strainId()],
                    });
                }

            },
        });
        this.availableClassifications = ko.observableArray().extend({
            fetchBackend: () => {
                if (this.licenseId() && this.strainId() && this.selectedStrain()?.[0]?.species_id) {
                    return LicensesService.getLicenseClassificationOptions({
                        licenseId: this.licenseId(),
                        speciesId: this.selectedStrain()[0].species_id,
                        strainId: [this.strainId()],
                    });
                }
            },
        });

        this.availableLabelTargets = ko.pureComputed(() => {
            return _.union(
                [this.noLabelPrinter, this.newWindowPrinter],
                _.map(
                    _.result(this.detailData(), "printers", []),
                    (printerName) => {
                        return {
                            value: printerName,
                            label: _.template(getTranslation("Print using '<%- arguments[0] %>'."))(printerName),
                        };
                    },
                ),
            );
        });

        if (this.crossingId()) {
            this.fetchInProgress(true);
            TanksService
                .getCrossingDetailData({ crossingId: this.crossingId() })
                .then((result) => {
                    const crossingData = result.crossing_detail;
                    const parentResponsibleIds = _.map(_.get(crossingData, ["tanks", "parents"]), "responsible_id");

                    let responsibleId: number = crossingData.responsible_id;
                    // if responsibleId is null, check for parent responsible ids being equal and preset if true
                    if (!responsibleId && _.uniq(parentResponsibleIds).length === 1) {
                        responsibleId = _.head(parentResponsibleIds);
                    }

                    this.detailData(result);
                    this.crossingData(crossingData);

                    _.defer(() => {
                        this.responsibleId(responsibleId);
                        this.printer(_.result(result, "crossing_default_printer"));
                        this.classificationId(_.result(crossingData, "classification_id"));
                        this.dateOfRecord(formatIsoDate(_.result(crossingData, "date_of_record")));
                        this.description(_.result(crossingData, "description"));
                        this.licenseId(_.result(crossingData, "license_id"));
                        this.raisedTanks(_.result(crossingData, "really_raised_tanks"));
                        this.strainId(_.result(crossingData, "strain_id"));

                        if (_.result(crossingData, "crossing_tanks")) {
                            // number of crossing tanks already known
                            this.crossingTanks(_.result(crossingData, "crossing_tanks"));
                        } else {
                            // number of crossing is calculated as the number of parents divided by two
                            this.crossingTanks(Math.floor(
                                _.reduce(_.get(crossingData, ["tanks", "parents"]), (m, t) => {
                                    return m + t.number_of_male + t.number_of_female + t.number_of_unknown;
                                }, 0) / 2),
                            );
                        }
                    });
                })
                .catch((reason) => {
                    this.errors.push(getTranslation("General unexpected error occurred."));
                    writeException(reason);
                })
                .finally(() => this.fetchInProgress(false));
        }
    }
}

// dialog starter
export const showTankCrossingDetails = htmlDialogStarter(TankCrossingDetailsViewModel, template, params => ({
    name: "TankCrossingDetails",
    title: _.template(getTranslation("Crossing #<%- crossingId %>"))(params),
    width: 400,
    position: {
        anchor: params.positioningElement,
        dialogHandle: "left middle",
    },
    closeOthers: true,
}));
