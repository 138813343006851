import {
    observable,
    Observable,
    PureComputed,
    pureComputed,
} from "knockout";
import {
    includes,
    range,
    trim,
} from "lodash";

import {
    SeverityLevelDetailsCurrentData,
    SeverityLevelDetailsStaticData,
    SeverityLevelsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";

import template from "./severityLevelDetails.html";

interface Params {
    severityLevelId: number | null;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SeverityLevelDetailsViewModel {
    dialog: HtmlDialog;
    severityLevelId: number | null;

    errorMessage: Observable<string> = observable("");
    fetchInProgress: Observable<boolean> = observable(true);
    reloadRequired: Observable<boolean> = observable(false);
    submitInProgress: Observable<boolean> = observable(false);
    staticData: Observable<SeverityLevelDetailsStaticData> = observable();
    currentData: Observable<SeverityLevelDetailsCurrentData> = observable();
    showLabelError = observable(false);
    severityPositionOptions: PureComputed<number[]>;
    euStatisticsCode: Observable<SeverityLevelDetailsCurrentData["eu_code"]> = observable();
    isAvailable = observable(false);
    isInUse = observable(false);
    severityPosition = observable(0);

    severityLabel: CheckExtended<Observable<string>> = observable("").extend({
        normalize: (v) => {
            return v && trim(v);
        },
        invalid: (value) => {
            if (!value) {
                return getTranslation("Please specify a severity level");
            }

            // show error message for label from now on (after first input change)
            this.showLabelError(true);

            if (value.length > this.staticData().max_label_length) {
                return getTranslation("Severity level can't be longer than %d characters").replace(
                    "%d",
                    String(this.staticData().max_label_length),
                );
            }

            if (includes(this.staticData().used_labels, value)) {
                return getTranslation("Severity level exists already");
            }

            return false;
        },
    });

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;
        this.severityLevelId = params.severityLevelId;

        dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        SeverityLevelsService.getSeverityLevelDetails({ severityLevelId: this.severityLevelId })
            .then((data) => {
                this.staticData(data.static_data);
                this.currentData(data.current_data);
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errorMessage(reason.body.detail);
                } else {
                    this.errorMessage(getTranslation("General error."));
                    writeException(reason);
                }
            })
            .finally(() => {
                this.fetchInProgress(false);
            });

        this.currentData.subscribe((data) => {
            this.severityLabel(data.label || "");
            this.euStatisticsCode(data.eu_code);
            this.isAvailable(data.available || false);
            this.isInUse(this.currentData().is_in_use || false);
            this.severityPosition(this.currentData().severity || 0);
        });

        if (!this.severityLevelId) {
            dialog.setTitle(getTranslation("Create new severity level"));
        } else {
            this.staticData.subscribe((staticData) => {
                if (staticData.allow_edit) {
                    dialog.setTitle(getTranslation("Edit severity level"));
                } else {
                    dialog.setTitle(getTranslation("View severity level"));
                }
            });
        }

        this.severityPositionOptions = pureComputed(() => {
            let result;

            if (this.isAvailable()) {
                if (this.currentData().available && this.severityLevelId) {
                    result = range(
                        this.staticData().highest_available_position !== null
                            ? this.staticData().highest_available_position + 1
                            : 1,
                    );
                } else {
                    // newly created severity levels and unavailable severity levels
                    // that are made available can have the next higher position of
                    // available severity levels to choose from
                    result = range(
                        this.staticData().highest_available_position !== null
                            ? this.staticData().highest_available_position + 2
                            : 1,
                    );
                }

                if (this.severityPosition() >= result.length) {
                    // when changing from unavailable to available, the position
                    // might be set out of range; must be set to the highest option
                    this.severityPosition(result.length - 1);
                }
            } else {
                // if a severity level is unavailable, it can have all severity
                // positions that other unavailable severity levels have;
                // at minimum as many positions as for the available severity
                // levels
                result = range(
                    this.staticData().highest_unavailable_position !== null
                        ? this.staticData().highest_unavailable_position + 1
                        : 1,
                );
            }

            return result;
        });
    }

    public submitLevel = () => {
        this.errorMessage("");
        this.submitInProgress(true);
        if (!this.severityLabel.errorMessage()) {
            if (this.severityLevelId) {
                // update existing severity level
                SeverityLevelsService.updateSeverityLevel({
                    severityLevelId: this.severityLevelId,
                    requestBody: {
                        label: this.severityLabel(),
                        eu_code: this.euStatisticsCode() || null,
                        available: !!this.isAvailable(),
                        severity: this.severityPosition(),
                    },
                })
                    .then(() => {
                        this.reloadRequired(true);
                        this.dialog.close();
                    })
                    .catch((reason) => {
                        if (typeof reason.body?.detail == "string") {
                            this.errorMessage(reason.body.detail);
                        } else {
                            this.errorMessage(getTranslation("General error."));
                            writeException(reason);
                        }
                    })
                    .finally(() => {
                        this.submitInProgress(false);
                    });
            } else {
                SeverityLevelsService.createSeverityLevel({
                    requestBody: {
                        label: this.severityLabel(),
                        eu_code: this.euStatisticsCode() || null,
                        available: !!this.isAvailable(),
                        severity: this.severityPosition(),
                    },
                })
                    .then(() => {
                        this.reloadRequired(true);
                        this.dialog.close();
                    })
                    .catch((reason) => {
                        if (typeof reason.body?.detail == "string") {
                            this.errorMessage(reason.body.detail);
                        } else {
                            this.errorMessage(getTranslation("General error."));
                            writeException(reason);
                        }
                    })
                    .finally(() => {
                        this.submitInProgress(false);
                    });
            }
        }
    };

    public deleteLevel = () => {
        if (!this.isInUse()) {
            this.errorMessage("");
            this.submitInProgress(true);
            SeverityLevelsService.deleteSeverityLevel({ severityLevelId: this.severityLevelId })
                .then(() => {
                    this.reloadRequired(true);
                    this.dialog.close();
                })
                .catch((reason) => {
                    if (typeof reason.body?.detail == "string") {
                        this.errorMessage(reason.body.detail);
                    } else {
                        this.errorMessage(getTranslation("General error."));
                        writeException(reason);
                    }
                })
                .finally(() => {
                    this.submitInProgress(false);
                });
        }
    };
}

export const showSeverityLevelDetails = htmlDialogStarter(SeverityLevelDetailsViewModel, template, {
    name: "SeverityLevelDetails",
    width: 455,
    closeOthers: true,
});
