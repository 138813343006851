import * as ko from "knockout";
import {
    Computed,
    Observable,
    components,
} from "knockout";

import { LocationPickerLocation } from "../../../backend/v1";
import { getTranslation } from "../../../lib/localize";
import { getElementWindow } from "../../../lib/utils";

import {
    LocationItem,
    SelectType,
    LocationIdString,
    PreselectLocationItem,
    LocationPickerParams,
} from "./locationPicker";

/**
 * Location Picker Popup Component
 *
 * Wrapping location-picker in popup dialog using dialog bindingHandler.
 * All params of location-picker will be passed through - read picker doc string.
 *
 * @param inputClassName - Additional class name(s) that will be assigned to
 * the input element that triggers the popup to open.
 *
 * @param inputId - String that should be used as id attribute on the input element
 * that triggers the popup to open. This can be usefull if used in forms with label elements.
 *
 * @param hidePopupTrigger - ko.observable used to hide the popup.
 *
 * @param afterApply - Function that will be executed when location picker
 * selection is applied/confirmed.
 */
interface LocationPickerPopupParams extends LocationPickerParams {
    inputClassName?: string;
    inputId?: string;
    hidePopupTrigger?: Observable;
    afterApply?: () => void;
}

class LocationPickerPopupViewModel {

    public dialogArgs: {
        width: string;
        title: string;
        close: () => void;
        height: string;
    };
    public dialogVisible: Observable<boolean>;
    public dialogPosition: HTMLElement;
    public initialized: Observable<false | "loading" | true>;
    public cleanLocationsTrigger: Observable;
    public unselectLocationTrigger: Observable;
    public restoreSelectionTrigger: Observable;
    public rememberSelectionTrigger: Observable;
    public pickerClassName: string;
    public inputClassName: string;
    public inputId: string;
    public isConfirmed: boolean;
    public preselectLocation: PreselectLocationItem;
    public selectType: undefined | SelectType | SelectType[];
    public withNoLocation: undefined | false;
    public parentLocation: LocationIdString;
    public selectedLocation: Observable<LocationItem>;
    public selectedLocationTitle: Observable<string>;
    public disabledPicker: boolean;
    public disabledAttr: Observable<string | false>;
    public invalidationFunction: (arg0: LocationItem) => (false | string);
    public possibleLocations: Observable<LocationPickerLocation[]>;
    public selectLocationTrigger: Observable<LocationItem>;
    public selectedLocationText: Computed<string>;
    public confirmable: Computed<boolean>;
    public afterApply: () => void;

    constructor(params: LocationPickerPopupParams, componentElement: HTMLElement) {
        this.dialogArgs = {
            title:
                params.selectType === "building" ? getTranslation("Select building") :
                params.selectType === "area" ? getTranslation("Select area") :
                params.selectType === "room" ? getTranslation("Select room") :
                params.selectType === "rack" ? getTranslation("Select rack") :
                params.selectType === "transfer" ? getTranslation("Select transfer destination") :
                getTranslation("Select location"),
            height: "auto",
            width: "auto",
            close: () => this.closeCallback(componentElement),
        };
        this.dialogVisible = ko.observable(false);
        this.dialogPosition = componentElement;
        this.initialized = ko.observable(false);
        this.cleanLocationsTrigger = ko.observable();
        this.unselectLocationTrigger = ko.observable();
        this.rememberSelectionTrigger = ko.observable();
        this.restoreSelectionTrigger = ko.observable();
        this.pickerClassName = params.pickerClassName;
        this.inputClassName = params.inputClassName;
        this.inputId = params.inputId;
        this.isConfirmed = false;
        this.preselectLocation = params.preselectLocation;
        this.selectType = params.selectType;
        this.withNoLocation = params.withNoLocation;
        this.parentLocation = params.parentLocation;
        this.selectedLocation = ko.observable();
        this.selectedLocationTitle = ko.observable();
        this.disabledPicker = params.disabled;
        this.disabledAttr = ko.observable(this.disabledPicker ? "disabled" : false);
        this.invalidationFunction = params.invalidationFunction;
        this.possibleLocations = params.possibleLocations;
        this.selectLocationTrigger = params.selectLocationTrigger;
        this.selectedLocationText = ko.pureComputed(() => {
            if (this.initialized() === "loading") {
                return getTranslation("Loading");
            }
            if (this.selectedLocation()) {
                return this.selectedLocation().full_name;
            }
            if (this.disabledPicker) {
                return getTranslation("No location set");
            }
            return getTranslation("Click here to set location");
        });

        this.confirmable = ko.pureComputed(() => {
            return this.selectedLocation() instanceof Object;
        });

        this.selectedLocation.subscribe((value) => {
            if (ko.isObservable(params.selectedLocation)) {
                params.selectedLocation(value);
            }
        });

        this.selectedLocationTitle.subscribe((value) => {
            if (ko.isObservable(params.selectedLocationTitle)) {
                params.selectedLocationTitle(value);
            }
        });

        this.dialogVisible.subscribe((newValue) => {
            // jQuery UI triggers a focus event on the opener element
            // (usualy that textarea element that shows the location string).
            // That element listens on the focusin event to show the
            // location picker.
            // We can avoid this by disabling the opener element - so jQuery UI
            // won't trigger it's focus event.
            // https://bugs.webkit.org/show_bug.cgi?id=47182
            this.disabledAttr(newValue ? "disabled" : false);
            window.setTimeout(() => {
                // Firefox < ~85 needs this to be wrapped in setTimeout(),
                // other Browser need it to be outside.
                // TODO: Removed after 2022-04.
                this.disabledAttr(newValue ? "disabled" : false);
            }, 0);
        });

        this.selectedLocationTitle.subscribe((value) => {
            if (ko.isObservable(params.selectedLocationTitle)) {
                params.selectedLocationTitle(value);
            }
        });

        if (ko.isObservable(params.initialized)) {
            params.initialized(this.initialized());
            this.initialized.subscribe((value) => {
                params.initialized(value);
            });
        }

        if (ko.isObservable(params.unselectLocationTrigger)) {
            // trigger with params.unselectLocationTrigger.valueHasMutated()
            params.unselectLocationTrigger.extend({ notify: "always" });
            params.unselectLocationTrigger.subscribe(() => {
                this.unselectLocationTrigger.valueHasMutated();
            });
        }

        if (ko.isObservable(params.cleanLocationsTrigger)) {
            // trigger with params.cleanLocationsTrigger.valueHasMutated()
            params.cleanLocationsTrigger.extend({ notify: "always" });
            params.cleanLocationsTrigger.subscribe(() => {
                this.cleanLocationsTrigger.valueHasMutated();
            });
        }

        if (ko.isObservable(params.hidePopupTrigger)) {
            params.hidePopupTrigger.extend({ notify: "always" });
            params.hidePopupTrigger.subscribe(() => {
                this.hidePopup();
            });
        }

        this.afterApply = () => {
            if (typeof params.afterApply === "function") {
                params.afterApply();
            }
        };

        // close location picker when closing parent dialog
        getElementWindow(componentElement)
            // @ts-expect-error: window has a .jQuery method
            .jQuery(componentElement)
            .closest(".ui-dialog")
            .on("dialogclose", () => {
                this.hidePopup();
            });
    }

    public showPopup = () => {
        if (this.disabledPicker) {
            return;
        }
        if (!this.dialogVisible()) {
            this.isConfirmed = false;
            this.dialogVisible(true);
        }
        this.cleanLocationsTrigger.valueHasMutated();
        this.rememberSelectionTrigger.valueHasMutated();
    };

    public hidePopup = () => {
        if (this.dialogVisible()) {
            this.dialogVisible(false);
        }
    };

    public confirmSelect = () => {
        this.isConfirmed = true;
        this.afterApply();
        this.hidePopup();
    };

    private closeCallback = (componentElement: HTMLElement) => {
        if (!this.isConfirmed) {
            this.restoreSelectionTrigger.valueHasMutated();
        }
        //lose focus of cage location (textarea), so location-picker-popup shows up on next click
        const blurEvent = document.createEvent("HTMLEvents");
        blurEvent.initEvent("blur", true, false);
        componentElement.dispatchEvent(blurEvent);
    };
}

export class LocationPickerPopupComponent {

    constructor() {
        return {
            viewModel: {
                createViewModel: (params: LocationPickerPopupParams, componentInfo: components.ComponentInfo) => {
                    let viewModelElement: ChildNode;
                    const componentElement = componentInfo.element as HTMLElement;
                    if (componentElement.nodeName === "#comment") {
                        viewModelElement = componentElement.nextElementSibling;
                    } else {
                        viewModelElement = componentElement.firstChild;
                    }
                    return new LocationPickerPopupViewModel(params, viewModelElement as HTMLElement);
                },
            },
            template: `
                <textarea class="location-picker-popup-input"
                          readonly="readonly"
                          data-bind="event: disabledPicker ? {} : {focusin: showPopup},
                                     value: selectedLocationText,
                                     attr: {title: selectedLocationTitle,
                                            id: inputId,
                                            disabled: disabledAttr},
                                     css: inputClassName"></textarea>
                <div class="location-picker-popup popup_frame"
                     data-bind="dialog: dialogArgs,
                                dialogVisible: dialogVisible,
                                dialogPosition: dialogPosition">
                    <ko-location-picker params="selectedLocation: selectedLocation,
                                                initialized: initialized,
                                                cleanLocationsTrigger: cleanLocationsTrigger,
                                                unselectLocationTrigger: unselectLocationTrigger,
                                                _withinPopup: true,
                                                pickerClassName: pickerClassName,
                                                selectType: selectType,
                                                withNoLocation: withNoLocation,
                                                parentLocation: parentLocation,
                                                disabled: disabledPicker,
                                                preselectLocation: preselectLocation,
                                                invalidationFunction: invalidationFunction,
                                                rememberSelectionTrigger: rememberSelectionTrigger,
                                                restoreSelectionTrigger: restoreSelectionTrigger,
                                                selectedLocationTitle: selectedLocationTitle,
                                                possibleLocations: possibleLocations,
                                                selectLocationTrigger: selectLocationTrigger"></ko-location-picker>
                    <div class="popup_footer">
                        <input type="reset"
                               data-bind="value: getTranslation('Cancel'),
                                          click: hidePopup"/>
                        <input type="submit"
                               class="confirm"
                               data-bind="value: getTranslation('Apply'),
                                          click: confirmSelect,
                                          enable: confirmable"/>
                    </div>
                </div>
            `,
        };
    }

}
