/**
 * Display a cryotank picker.
 *
 * @param cryotankId
 *        The ID of the cryotank to show.
 *
 * @param cryotankPath
 *        Observable to which to write the selected cryotank path.
 *
 * @param allowSelectAnyPath
 *        Whether it is possible to select the nodes, including nodes that have
 *        children, not restricting to the straws only (e.g. in filters to choose
 *        a parent cryotank node).
 *        Exactly one of `allowSelectAnyPath` or `allowAddContent` should be enabled.
 *
 * @param allowAddContent
 *        Whether new content shall be added to the cryotank (e.g. in QS or import).
 *        Exactly one of `allowSelectAnyPath` or `allowAddContent` should be enabled.
 *
 * @param htmlId
 *        HTML `id` attribute of the picker input field.
 *
 * @param disable
 *        Whether to disable the input field and not open the cryotank browser
 *        on click.
 *
 */

import * as ko from "knockout";

import { showCryotankBrowser } from "../../dialogs";
import { CheckExtended } from "../extensions/invalid";

import template from "./cryotankPicker.html";

interface CryotankPickerParams {
    cryotankId: ko.Observable<number>;
    cryotankPath: CheckExtended<ko.ObservableArray<string>>;
    htmlId?: string;
    disable?: ko.Observable<boolean>;
}

interface FilterCryotankContentPickerParams extends CryotankPickerParams {
    allowSelectAnyPath: true;
}

interface PlaceCryotankContentPickerParams extends CryotankPickerParams {
    allowAddContent: true;
}

class CryotankPickerViewModel {
    private cryotankId: ko.Observable<number>;
    private cryotankPath: CheckExtended<ko.ObservableArray<string>>;
    private allowSelectAnyPath: boolean;
    private allowAddContent: boolean;
    private htmlId: string;
    private disable: ko.Observable<boolean> | false;

    constructor(params: FilterCryotankContentPickerParams | PlaceCryotankContentPickerParams) {
        this.cryotankId = params.cryotankId;
        this.cryotankPath = params.cryotankPath;
        // @ts-expect-error:  Property 'allowSelectAnyPath' does not exist on type 'PlaceCryotankContentPickerParams'
        this.allowSelectAnyPath = params.allowSelectAnyPath || false;
        // @ts-expect-error: Property 'allowAddContent' does not exist on type 'FilterCryotankContentPickerParams'
        this.allowAddContent = params.allowAddContent || false;
        this.htmlId = params.htmlId;
        this.disable = params.disable || false;
    }

    private showCryotankPicker = () => {
        showCryotankBrowser({
            cryotankId: this.cryotankId(),
            cryotankPath: this.cryotankPath,
            allowSelectAnyPath: this.allowSelectAnyPath,
            allowAddContent: this.allowAddContent,
        });
    };
}

export class CryotankPickerComponent {
    constructor() {
        return {
            viewModel: CryotankPickerViewModel,
            template,
        };
    }
}
