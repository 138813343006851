import {
    Computed,
    ObservableArray,
    Observable,
} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";

import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    cgiScript,
    getFormData,
    getUrl,
    AjaxResponse,
    checkDecimal,
} from "../lib/utils";

interface Arguments {
    filter_billing_periods: {
        id: number;
        label: string;
    }[];
    filter_billing_period_id: number;
    prices: {
        id: number | undefined;
        price: number | undefined;
        editable: boolean;
        catalog_item_id: number;
        catalog_item_label: string;
        catalog_item_available: boolean;
        catalog_name: string;
        price_category_id: number;
        price_category_name: string;
        price_category_active: boolean;
    }[];
}

interface PriceRow {
    catalog_item_id: number;
    catalog_item_available: boolean;
    catalog_item_label: string;
    catalog_name: string;
    price_category_cells: ObservableArray<PriceCell>;
    changed?: Computed<boolean>;
}

interface PriceCell {
    id: number;
    price: CheckExtended<Observable<number>>;
    price_category_id: number;
    price_category_name: string;
    price_category_active: boolean;
    editable: boolean;
    changed?: Observable<boolean>;
}


interface SubmitData {
    update_prices: {
        id: number | undefined;
        price: number;
        price_category_id: number;
        billing_period_id: number;
        catalog_item_id: number;
        changed: boolean;
    }[];
}

class PriceExternalOrderList {

    public seed: Arguments;
    public prices: ObservableArray<PriceRow>;
    public hideUnavailable: Observable<boolean>;
    public filterBillingPeriodId: Observable<number>;
    public error: Observable<string>;
    public submitInProgress: Observable<boolean>;
    public canSubmit: Computed<boolean>;

    constructor(args: Arguments) {

        this.seed = args;

        this.prices = ko.observableArray(this.getRowModel());
        this.hideUnavailable = ko.observable(true);
        this.filterBillingPeriodId = ko.observable(this.seed.filter_billing_period_id);
        this.error = ko.observable();
        this.submitInProgress = ko.observable(false);
        this.canSubmit = ko.computed(() => {
            const anyChange = _.some(this.prices(), (row) => {
                return row.changed();
            });

            const anyInvalid = _.some(this.prices(), function(row) {
                return _.some(row.price_category_cells(), (cell) => {
                    return cell.price.isInvalid();
                });
            });

            return !this.submitInProgress()
                && !anyInvalid
                && anyChange;
        });
    }

    public highlightSaveButton = () => {
        const saveButton = document.getElementById("save_button");

        saveButton.classList.add("blink_twice");
        saveButton.addEventListener("animationend", function () {
            this.classList.remove("blink_twice");
        });
    };

    private getRowModel = () => {
        const rows: PriceRow[] = [];

        _.each(this.seed.prices, (data: Arguments["prices"][0]) => {

            // create the cell
            const cellModel: PriceCell = {
                "id": data.id ? data.id : undefined,
                "price": ko.observable(data.price),
                "price_category_id": data.price_category_id,
                "price_category_name": data.price_category_name,
                "price_category_active": data.price_category_active,
                "editable": data.editable,
                "changed": ko.observable(false),
            };

            cellModel.price.extend({
                invalid: (v) => {
                    return !!(v && checkDecimal(v.toString(), session.localesConf.decimalSymbol, 10, session.pyratConf.BUDGETING_DISPLAY_PRECISION));
                },
            });

            cellModel.price.subscribe(() => {
                cellModel.changed(true);
                this.highlightSaveButton();
            });

            // create the row or append to already created one
            const tableRow = _.find(rows, { "catalog_item_id": data.catalog_item_id });
            if (tableRow) {
                tableRow.price_category_cells.push(cellModel);
            } else {
                const rowModel: PriceRow = {
                    "catalog_item_id": data.catalog_item_id,
                    "catalog_item_label": data.catalog_item_label,
                    "catalog_item_available": data.catalog_item_available,
                    "catalog_name": data.catalog_name,
                    "price_category_cells": ko.observableArray([cellModel]),
                    "changed": ko.pureComputed(() => {
                        return _.some(rowModel.price_category_cells(), (cell) => {
                            return cell.changed();
                        });
                    }),
                };
                rows.push(rowModel);
            }
        });

        return rows;
    };

    public cancel = () => {
        location.href = getUrl(cgiScript("price_external_order_list.py"));
    };

    public filterList = () => {
        location.href = getUrl(cgiScript("price_external_order_list.py"), { billing_period_id: this.filterBillingPeriodId() });
    };

    private getRequestData = () => {

        const priceItems: SubmitData["update_prices"] = [];
        _.each(this.prices(), (row) => {
            _.each(row.price_category_cells(), (cell) => {
                if (cell.changed()) {
                    priceItems.push({
                        "id": cell.id,
                        "price": cell.price(),
                        "price_category_id": cell.price_category_id,
                        "billing_period_id": this.filterBillingPeriodId(),
                        "catalog_item_id": row.catalog_item_id,
                        "changed": cell.changed(),
                    });
                }
            });
        });


        const requestData: SubmitData = {
            update_prices: priceItems,
        };
        return requestData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.error("");

        fetch(cgiScript("price_external_order_list.py"), {
            method: "POST",
            body: getFormData({
                request: JSON.stringify(this.getRequestData()),
            }),
        })
            .then((response) => response.json())
            .then((response: AjaxResponse<any>) => {
                if (response.success) {
                    window.location.reload();
                    notifications.showNotification(getTranslation("Changes saved"), "success");
                } else {
                    // validation errors
                    this.submitInProgress(false);
                    this.error(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.error(getTranslation("Action failed. The data could not be saved. Please try again."));
            });
    };

}


export const initPriceExternalOrderList = (args: Arguments): void => {
    ko.applyBindings(new PriceExternalOrderList(args));
};
