import * as $ from "jquery";
import * as ko from "knockout";
import {
    ObservableArray,
    Observable,
    components,
} from "knockout";
import * as _ from "lodash";

import {
    LocationPickerLocation,
    LocationsService,
} from "../../../backend/v1";
import { getTranslation } from "../../../lib/localize";
import {
    frames,
    notifications,
} from "../../../lib/pyratTop";
import "./locationPicker.scss";

export type LocationType = "building" | "area" | "room" | "rack"; // | "cage" | "tank"
export type LocationIdString = string;  // e.g. 1-2-3

export interface PreselectLocationItem {
    id: number;  // `db_id: LocationIdString` for the rest of the code
    type: LocationType;
}

export interface LocationItem extends LocationPickerLocation {
    active: Observable<boolean>;
    expandable?: Observable<boolean>;
    expanded?: Observable<boolean>;
    selected?: Observable<boolean>;
    locationId: {
        building_id?: number;
        area_id?: number;
        room_id?: number;
        rack_id?: number;
    };
}

const typeIdMap: {[key: string]: "building_id" | "area_id" | "room_id" | "rack_id"} = {
    "building": "building_id",
    "area": "area_id",
    "room": "room_id",
    "rack": "rack_id",
};

export type SelectType = "building" | "area" | "room" | "rack" | "transfer";

/**
 * Location Picker Inline Component
 *
 * @property cleanLocationsTrigger - ko.observable used to trigger the cleaning
 * of locations (contract all building, reset rack rows dropdowns, expand path
 * to may selected location).
 * To trigger, call cleanLocationsTrigger.valueHasMutated() or set any true-ish
 * value to it. (can be the same observable as unselectLocationTrigger if you want
 * to trigger both actions at once)
 *
 * @property initialized - ko.observable that will be updated with the current
 * init state. Will be one of (false, 'loading', true)
 *
 * @property pickerClassName - Additional class name(s) that will be assigned to
 * the picker element (the one that lets you select the location)
 *
 * @property preselectLocation - Object/dict having 'type' and 'id' of location item that
 * should be preselected 'type' has to be one of ('building', 'area', 'room', 'rack', 'cage')
 *
 * @property selectedLocation - ko.observable to hold the selected location item
 *
 * @property selectedLocationTitle - ko.observable that will be updated with a
 * string of the currently selected location to be used as title attribute for
 * an element. value will be something like:
 * "Building: G\nArea: EG\nRoom: 22\nRack: AE22-R\nRack Row: 13"
 *
 * @property selectType - one of (undefined, 'building', 'area', 'room', 'rack',
 * 'transfer') or Array/List of possible types eg: ['building', 'area'] which
 * type of location should be selected 'transfer' must not be combined with the
 * other types without a rack anyway selecting the rack row is always optional,
 * even when it is specified as the selectType, it is enough to select a rack with
 * no defined row, default: undefined (all locations can be selected, same as
 * ['building', 'area', 'room'])
 *
 * @property withNoLocation - If false, the 'No location set' entry and its children
 * shall not be included in the tree.
 *
 * @property parentLocation - full id string of a location (e.g. 1-2-3) picker shows
 * only this one location and its children (and of course its parents, for orientation),
 * all other branches of the location tree are suppressed, useful i.e. when a transfer
 * destination is given
 *
 * @property unselectLocationTrigger - ko.observable used to unselect a maybe currently
 * selected location item. To trigger, call unselectLocationTrigger.valueHasMutated()
 * (can be the same observable as cleanLocationsTrigger if you want to trigger both
 * actions at once)
 *
 * @property disabled - if true, the location picker field will be disabled
 *
 * @property rememberSelectionTrigger - ko.observable used to store the currently
 * selected location so it can be restored later. To trigger, call
 * rememberSelectionTrigger.valueHasMutated()
 *
 * @property restoreSelectionTrigger - ko.observable to reset the currently selected
 * location to the previously remebered one, that was store using restoreSelectionTrigger.
 * To trigger, call restoreSelectionTrigger.valueHasMutated()
 *
 * @property invalidationFunction - Function that will be called each time a selectable item is clicked.
 * It gets passed the item Object that was clicked.
 * It should return any false-ish value if item is accepted. It will be selected than.
 * It should return any true-ish value if item is not acceptable - will stay unselected than.
 * If return value is a string, it will be shown to the user to explain why that item is a bad choice.
 *
 * @property possibleLocations - ko.observable used to store the locations shown in the location picker
 *
 * @property selectLocationTrigger - ko.observable used to select a new location
 *
 * @property _withinPopup (do not use) - see the leading underscore? It's private.
 * Used by the location-picker-popup to tell the location-picker, it's wrapped within popup binding.
 */
export interface LocationPickerParams {
    cleanLocationsTrigger?: Observable;
    initialized?: Observable<false | "loading" | true>;
    pickerClassName?: string;
    preselectLocation?: PreselectLocationItem;
    selectedLocation?: Observable<LocationItem>;
    selectedLocationTitle?: Observable<string>;
    selectType?: undefined | SelectType | SelectType[];
    withNoLocation?: undefined | false;
    parentLocation?: LocationIdString;
    unselectLocationTrigger?: Observable;
    disabled?: boolean;
    rememberSelectionTrigger?: Observable;
    restoreSelectionTrigger?: Observable;
    invalidationFunction?: (arg0: LocationItem) => false | string;
    possibleLocations?: Observable<LocationPickerLocation[]>;
    selectLocationTrigger?: Observable<LocationItem>;
    _withinPopup?: boolean;
}

class LocationPickerViewModel {

    public pickerEl: ChildNode;
    public buildings: LocationItem[];
    public areas: { [key: number]: LocationItem[] };
    public rooms: { [key: number]: LocationItem[] };
    public racks: { [key: number]: LocationItem[] };
    public selectType: undefined | SelectType | SelectType[];
    public withNoLocation: false;
    public parentLocation: LocationIdString;
    public preselectLocation: PreselectLocationItem;
    public locationList: ObservableArray<LocationItem>;
    public possibleLocations: Observable<LocationPickerLocation[]>;
    public initialized: Observable<false | "loading" | true>;
    public selectedLocation: Observable<LocationItem | undefined>;

    // these both functions are used to highlight a location item if you
    public selectedLocationTitle: Observable<string>;
    public disabledPicker: boolean;
    public rememberedLocation: Observable<LocationItem>;
    public pickerClassName: string;
    public invalidationFunction: (arg0: LocationItem) => (false | string);

    constructor(params: LocationPickerParams, componentElement: ChildNode) {

        this.buildings = [];
        this.areas = {};
        this.rooms = {};
        this.racks = {};

        // keep reference to our HTML element before jQuery dialog rips us apart
        // will be set in afterRender function which is called - guess what - after component is rendered
        this.pickerEl = componentElement;

        this.selectType = params.selectType;
        this.withNoLocation = params.withNoLocation;
        this.parentLocation = params.parentLocation;
        this.preselectLocation = params.preselectLocation;
        this.possibleLocations = params.possibleLocations;
        this.invalidationFunction = params.invalidationFunction;
        this.locationList = ko.observableArray();
        this.initialized = ko.observable(false); // false -> not yet initialized, 'loading' -> init in progress, true -> init done
        this.selectedLocation = ko.observable();
        this.selectedLocationTitle = ko.observable("");
        this.disabledPicker = params.disabled;
        this.rememberedLocation = ko.observable();
        this.pickerClassName = (params.disabled ? "disabled " : "") + (params.pickerClassName || "");

        this.selectedLocation.subscribe((value) => {
            let s = "";

            if (value instanceof Object) {
                if (value.building_id >= 0) {
                    s += getTranslation("Building") + ": " + value.building_name;
                }
                if (value.area_id) {
                    s += "\n" + getTranslation("Area") + ": " + value.area_name;
                }
                if (value.room_id) {
                    s += "\n" + getTranslation("Room") + ": " + value.room_name;
                }
                if (value.rack_id) {
                    s += "\n" + getTranslation("Rack") + ": " + value.rack_name;
                }
            }
            this.selectedLocationTitle(s);

            if (ko.isObservable(params.selectedLocation)) {
                params.selectedLocation(value);
            }
        });

        this.selectedLocationTitle.subscribe((value) => {
            if (ko.isObservable(params.selectedLocationTitle)) {
                params.selectedLocationTitle(value);
            }
        });

        if (ko.isObservable(params.selectLocationTrigger)) {
            params.selectLocationTrigger.subscribe((item) => {
                this.selectItem(item);
            });
        }

        if (ko.isObservable(params.initialized)) {
            params.initialized(this.initialized());
            this.initialized.subscribe((value) => {
                params.initialized(value);
            });
        }

        if (ko.isObservable(params.unselectLocationTrigger)) {
            // trigger with params.unselectLocationTrigger.valueHasMutated()
            params.unselectLocationTrigger.extend({ notify: "always" });
            params.unselectLocationTrigger.subscribe(() => {
                this.unselectItem();
            });
        }

        if (ko.isObservable(params.cleanLocationsTrigger)) {
            // trigger with params.cleanLocationsTrigger.valueHasMutated()
            params.cleanLocationsTrigger.extend({ notify: "always" });
            params.cleanLocationsTrigger.subscribe(() => {
                this.cleanLocations();
            });
        }

        if (ko.isObservable(params.rememberSelectionTrigger)) {
            // trigger with params.rememberSelectionTrigger.valueHasMutated()
            params.rememberSelectionTrigger.extend({ notify: "always" });
            params.rememberSelectionTrigger.subscribe(() => {
                this.rememberedLocation(this.selectedLocation());
            });
        }

        if (ko.isObservable(params.restoreSelectionTrigger)) {
            // trigger with params.restoreSelectionTrigger.valueHasMutated()
            params.restoreSelectionTrigger.extend({ notify: "always" });
            params.restoreSelectionTrigger.subscribe(() => {
                const rLoc = this.rememberedLocation();
                const sLoc = this.selectedLocation();
                if (rLoc
                    && (!sLoc
                        || rLoc.type !== sLoc.type
                        || rLoc[typeIdMap[rLoc.type]] !== sLoc[typeIdMap[sLoc.type]])) {

                    this.selectItem(rLoc);
                } else if (!rLoc && sLoc) {
                    this.unselectItem();
                }
            });
        }

        if ((params.preselectLocation instanceof Object && params.preselectLocation.type && _.isNumber(params.preselectLocation.id))
            || !params._withinPopup
            || (ko.isObservable(params.possibleLocations) && params.possibleLocations() === undefined)) {

            this.getLocations();
        }
    }

    // hover somewhere over the location cell
    public activate = (item: LocationItem) => {
        if (this.disabledPicker) {
            return;
        }
        item.active(true);
    };

    public deactivate = (item: LocationItem) => {
        item.active(false);
    };

    public afterRender = () => {
        // call resizeDetailWindow after component is rendered
        window.top.setTimeout(frames.detailPopup.resize, 0);
    };

    public clickItem = (item: LocationItem, ev: MouseEvent) => {
        const target = ev.target as HTMLElement;

        if (this.disabledPicker) {
            return;
        }

        if (!target.classList.contains("expander") && item.select_in_picker) {
            this.selectItem(item);
        }

        if (item.expanded()) {
            this.contractLocationList(item);
        } else {
            this.expandLocationList(item);
        }

        this.scrollIntoView(item);
    };

    private getItemFromList = (list: LocationItem[], id: number) => {
        const filteredList = ko.utils.arrayFilter(list, (entry) => {
            return entry.db_id === id;
        });
        if (filteredList.length > 0) {
            return filteredList[0];
        }
    };

    private groupLocations = (data: LocationPickerLocation[]): LocationItem[] => {
        data.forEach((item: LocationItem) => {
            const locationIdName = typeIdMap[item.type];

            item.expanded = ko.observable(false);
            item.selected = ko.observable(false);
            item.expandable = ko.observable(false);
            item.active = ko.observable(false);
            item.locationId = {};
            item.locationId[locationIdName] = item[locationIdName];

            if (this.preselectLocation && this.preselectLocation.type === item.type && this.preselectLocation.id === item.db_id) {
                this.selectItem(item);
            }

            if (item.type === "building") {
                this.buildings.push(item);
            } else if (item.type === "area") {
                if (!this.areas[item.building_id]) {
                    this.areas[item.building_id] = [];
                }
                this.areas[item.building_id].push(item);
            } else if (item.type === "room") {
                if (!this.rooms[item.area_id]) {
                    this.rooms[item.area_id] = [];
                }
                this.rooms[item.area_id].push(item);
            } else if (item.type === "rack") {
                if (!this.racks[item.room_id]) {
                    this.racks[item.room_id] = [];
                }
                this.racks[item.room_id].push(item);
            }
        });
        return data as LocationItem[];
    };

    private contractLocationList = (item: LocationItem) => {
        const idField = typeIdMap[item.type];

        if (!item || !idField) {
            return;
        }
        item.expanded(false);
        this.locationList(ko.utils.arrayFilter(this.locationList(), (x) => {
            if (item.type !== x.type && item[idField] === x[idField]) {
                x.expanded(false);
                return false;
            }
            return true;
        }));
    };

    private addExpandableInformation = (itemList: LocationItem[]) => {
        ko.utils.arrayForEach(itemList, (item) => {
            if ((item.type === "building"
                && ((this.areas[item.building_id] instanceof Array
                    && this.areas[item.building_id].length > 0)
                    || (item.building_id === 0
                        && this.racks[item.building_id] instanceof Array
                        && this.racks[item.building_id].length > 0)))
                || (item.type === "area"
                    && this.rooms[item.area_id] instanceof Array
                    && this.rooms[item.area_id].length > 0)
                || (item.type === "room"
                    && this.racks[item.room_id] instanceof Array
                    && this.racks[item.room_id].length > 0)) {

                item.expandable(true);
            }

        });
    };

    private expandLocationList = (item: LocationItem | "root") => {
        let splitPos = _.indexOf(this.locationList(), item) + 1;
        let expansion;

        if (item !== "root"
            && (!item
                || typeof item !== "object"
                || item.expanded())) {

            return;
        }

        if (item === "root") {
            expansion = this.buildings;
            splitPos = 0;
        } else if (item.type === "building") {
            expansion = this.areas[item.building_id] || this.racks[item.building_id];
        } else if (item.type === "area") {
            expansion = this.rooms[item.area_id];
        } else if (item.type === "room") {
            expansion = this.racks[item.room_id];
        }

        if (expansion instanceof Array && expansion.length > 0) {
            if (item !== "root") {
                item.expanded(true);
            }
            this.addExpandableInformation(expansion);
            this.locationList([].concat(this.locationList.slice(0, splitPos),
                expansion,
                this.locationList.slice(splitPos)));

            if (expansion.length === 1 && !expansion[0].select_in_picker) {

                this.expandLocationList(expansion[0]);
            }
        }
    };

    private scrollIntoView = (item: LocationItem) => {
        // TODO: remove jQuery
        const pickerEl = $(this.pickerEl);
        const locationIdName = typeIdMap[item.type];
        const itemEl = pickerEl.find(`[${locationIdName}=${item[locationIdName]}]:visible`);
        if (itemEl.length > 0) {
            const itemElHeight = itemEl.outerHeight();
            const pickerElHeight = pickerEl.height();
            const itemElOffTop = itemEl.offset().top;
            const pickerElOffTop = pickerEl.offset().top;
            const pickerElScroll = pickerEl.scrollTop();
            if (itemElOffTop < pickerElOffTop) {
                pickerEl.animate({ scrollTop: pickerElScroll - ((pickerElOffTop + 3) - itemElOffTop) }, 200);
            } else if (itemElOffTop + itemElHeight > pickerElOffTop + pickerElHeight) {
                pickerEl.animate({ scrollTop: pickerElScroll + ((itemElOffTop + itemElHeight) - (pickerElOffTop + pickerElHeight)) }, 200);
            }
        }
    };

    // expand location tree to show specific location item
    private showLocation = (item: LocationItem) => {
        if (!item || typeof item !== "object") {
            return;
        }

        if (typeof item.building_id === "number") {
            this.expandLocationList(this.getItemFromList(this.buildings, item.building_id));
            if (typeof item.area_id === "number") {
                this.expandLocationList(this.getItemFromList(this.areas[item.building_id], item.area_id));
                if (typeof item.room_id === "number") {
                    this.expandLocationList(this.getItemFromList(this.rooms[item.area_id], item.room_id));
                }
            }
        }

        this.scrollIntoView(item);
    };

    // fetch location tree
    private getLocations = () => {

        if (this.initialized() !== false) {
            return;
        }

        this.initialized("loading");

        LocationsService.getPickerLocations({
            pickerType: Array.isArray(this.selectType) ? this.selectType : [this.selectType],
            withNoLocation: this.withNoLocation,
            parentLocation: this.parentLocation,
            ...(this.preselectLocation instanceof Object && this.preselectLocation.type && _.isNumber(this.preselectLocation.id) ? {
                preselectType: this.preselectLocation.type,
                preselectId: this.preselectLocation.id,
            } : {}),
        })
            .then((data) => {
                this.groupLocations(data);
                this.expandLocationList("root");
                this.showLocation(this.selectedLocation());

                if (ko.isObservable(this.possibleLocations)) {
                    this.possibleLocations(data);
                }

                this.initialized(true);
            });
    };

    private cleanLocations = () => {
        if (this.initialized() !== true) {
            this.getLocations();
        }
        ko.utils.arrayForEach(this.buildings, (item) => {
            if (item.expanded()) {
                this.contractLocationList(item);
            }
        });
        this.showLocation(this.selectedLocation());
    };

    private unselectItem = (doNotSetUndefined = false) => {
        if (this.selectedLocation()) {
            this.selectedLocation().selected(false);
        }
        if (!doNotSetUndefined) {
            this.selectedLocation(undefined);
        }
    };

    private selectItem = (item: LocationItem) => {
        let invalid;

        if (typeof (this.invalidationFunction) === "function") {
            invalid = this.invalidationFunction(item);
            if (invalid) {
                if (typeof invalid === "string") {
                    notifications.showModal(invalid);
                }
                return;
            }
        }
        this.unselectItem(true);
        item.selected(true);
        this.selectedLocation(item);
    };
}

export class LocationPickerComponent {

    constructor() {
        return {
            viewModel: {
                createViewModel: (params: LocationPickerParams, componentInfo: components.ComponentInfo) => {
                    let viewModelElement: ChildNode;
                    const componentElement = componentInfo.element as HTMLElement;
                    if (componentElement.nodeName === "#comment") {
                        viewModelElement = componentElement.nextElementSibling;
                    } else {
                        viewModelElement = componentElement.firstChild;
                    }
                    return new LocationPickerViewModel(params, viewModelElement);
                },
            },
            template: `
                <div class="location-picker"
                     data-bind="template: {afterRender: afterRender},
                                css: pickerClassName">
                    <table data-bind="if: locationList().length">
                        <tbody data-bind="foreach: locationList">
                        <tr data-bind="css: {expandable: expandable,
                                             expanded: expanded,
                                             selected: selected,
                                             building: 'building' === type,
                                             area: 'area' === type,
                                             room: 'room' === type,
                                             rack: 'rack' === type},
                                             attr: locationId">
                            <td class="location-cell"
                                data-bind="click: $parent.clickItem,
                                           event: {mouseenter: $parent.activate,
                                                   mouseleave: $parent.deactivate}">
                                <div class="selectable expander">&#9656;</div>
                                <div class="selectable location"
                                     data-bind="css: {active: active,
                                                      selected: selected}">
                                    <span data-bind="text: name"></span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="caption loading_circle"
                         style="margin: 20px auto auto;"
                         data-bind="hidden: locationList().length">
                    </div>
                </div>
            `,
        };
    }

}
