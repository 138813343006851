// This file is loaded via the templater on all applicable pages.

import * as $ from "jquery";
import "jquery-ui/ui/widgets/dialog.js";
import * as ko from "knockout";
import * as _ from "lodash";

import "./knockout/components";
import "./knockout/extensions";
import "./lib/charts";
import "./lib/matrixCode";
import * as pyratBackend from "./backend";
import {
    assert,
    AssertionError,
} from "./lib/assert";
import { ExceptHook } from "./lib/excepthook";
import { registerFrameHistoryTracker } from "./lib/frameHistory";
import {
    getTranslation,
    translated,
} from "./lib/localize";
import { notifications } from "./lib/pyratTop";
import * as utils from "./lib/utils";
import { pyratFrontend } from "./pyratFrontend";

// import required for Safari < 15.4 (released 2022-03, required until 2024-03)
import "dialog-polyfill/dialog-polyfill.css";

declare global {

    // values declared in the legacy javascript world but used in typescript code base
    interface Window {
        baseUrl: string;
        mainMenu?: any;
        pyratTranslation: {[id: string]: string};
    }

    // values declared in here to be used in legacy javascript
    interface Window {
        pyratFrontend: typeof pyratFrontend;
        pyratBackend: typeof pyratBackend;
    }

}

pyratBackend.v1.OpenAPI.BASE = window.top.baseUrl + "backend/v1";
pyratBackend.v1.OpenAPI.TOKEN = async () => pyratFrontend.session.sessionId;


// Initialize top window instances
if (window == window.top) {

    // The DOMContentLoaded event fires when the initial HTML document has been completely
    // loaded and parsed, without waiting for stylesheets, images, and subframes to finish loading.
    window.addEventListener("DOMContentLoaded", () => {

        // Register tracker for frame urls, used for various "back" buttons.
        registerFrameHistoryTracker(window);

    }, false);

}

// Extend the window object to make our functions for legacy javascript.
Object.assign(window, {

    // make libraries available globally
    $,
    jQuery: $,
    ko,
    _,

    // export global functions
    utils,
    assert,
    AssertionError,
    getTranslation,
    translated,


});

// Add frontend functions only once.
if (!window.pyratFrontend) {
    Object.assign(window, {
        pyratFrontend,
        pyratBackend,
    });
}

// The DOMContentLoaded event fires when the initial HTML document has been completely
// loaded and parsed, without waiting for stylesheets, images, and subframes to finish loading.
window.addEventListener("DOMContentLoaded", () => {

    // register exception tracker
    const exceptHook = new ExceptHook();
    exceptHook.registerOnErrorHandler();
    exceptHook.registerInteractionTracker();

    // register notification cleanup tracker
    // TODO: move this to the notifications constructor, once we remove all the frames
    notifications.registerNotificationMessageCleaner(window);

}, false);


// The load event is fired when the whole page has loaded, including all dependent resources
// such as stylesheets and images.
window.addEventListener("load", () => {

    // On iPad, scale the whole page and all frames a bit down.
    if (navigator.userAgent.match(/iPad/i) != null) {
        // @ts-expect-error: .zoom is non-standar
        document.querySelector("body").style.zoom = "80%";
    }

}, false);
