/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SacrificeReasonCreate } from '../models/SacrificeReasonCreate';
import type { SacrificeReasonDelete } from '../models/SacrificeReasonDelete';
import type { SacrificeReasonUpdate } from '../models/SacrificeReasonUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SacrificeReasonsService {

    /**
     * Update Sacrifice Reasons
     * Change data of multiple sacrifice reasons at once and roll back on error.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateSacrificeReasons({
        requestBody,
    }: {
        requestBody: Array<(SacrificeReasonCreate | SacrificeReasonUpdate | SacrificeReasonDelete)>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sacrifice_reasons/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
