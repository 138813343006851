/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CryotankCellContent } from '../models/CryotankCellContent';
import type { CryotankDetails } from '../models/CryotankDetails';
import type { CryotankLayoutNode } from '../models/CryotankLayoutNode';
import type { CryotankPathData } from '../models/CryotankPathData';
import type { DisplayHintProperties } from '../models/DisplayHintProperties';
import type { IdNameProperty } from '../models/IdNameProperty';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CryotanksService {

    /**
     * Get Cryotanks For Setting
     * Get cryotanks to specify where to place cryopreserved content.
     * @returns IdNameProperty Successful Response
     * @throws ApiError
     */
    public static getCryotanksForSetting(): CancelablePromise<Array<IdNameProperty>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cryotanks/for_setting',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Cryotank Details
     * Get cryotank details.
     * @returns CryotankDetails Successful Response
     * @throws ApiError
     */
    public static getCryotankDetails({
        cryotankId,
    }: {
        cryotankId: number,
    }): CancelablePromise<CryotankDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cryotanks/{cryotank_id}/details',
            path: {
                'cryotank_id': cryotankId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Container Details
     * Get data of a container inside a cryotank to display in the cryotank browser.
     * @returns CryotankPathData Successful Response
     * @throws ApiError
     */
    public static getContainerDetails({
        cryotankId,
        labelPath,
    }: {
        cryotankId: number,
        labelPath?: Array<string>,
    }): CancelablePromise<CryotankPathData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cryotanks/{cryotank_id}/container_details',
            path: {
                'cryotank_id': cryotankId,
            },
            query: {
                'label_path': labelPath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Straw Details
     * Get content data of a straw inside a cryotank.
     * @returns CryotankCellContent Successful Response
     * @throws ApiError
     */
    public static getStrawDetails({
        cryotankId,
        labelPath,
    }: {
        cryotankId: number,
        labelPath: Array<string>,
    }): CancelablePromise<CryotankCellContent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cryotanks/{cryotank_id}/straw_details',
            path: {
                'cryotank_id': cryotankId,
            },
            query: {
                'label_path': labelPath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Straw Label
     * Get straw label of a known cryotank content (a frozen embryo group or sperm volume)
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getStrawLabel({
        cryotankContentId,
    }: {
        cryotankContentId: number,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cryotanks/cryotank_content/{cryotank_content_id}/straw_label',
            path: {
                'cryotank_content_id': cryotankContentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Straw Label
     * Set straw label of a known cryotank content (a frozen embryo group or sperm volume)
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setStrawLabel({
        cryotankContentId,
        strawLabel,
    }: {
        cryotankContentId: number,
        strawLabel: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cryotanks/cryotank_content/{cryotank_content_id}',
            path: {
                'cryotank_content_id': cryotankContentId,
            },
            query: {
                'straw_label': strawLabel,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Display Hints For Setting
     * Get possible display hint that control the rendering of the cryotank nodes.
     * @returns DisplayHintProperties Successful Response
     * @throws ApiError
     */
    public static getCryotankNodeDisplayHintsForSetting(): CancelablePromise<Array<DisplayHintProperties>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cryotanks/display_hints/for_setting',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Cryotank
     * Create new cryotank.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createCryotank({
        name,
        active,
        requestBody,
        description,
    }: {
        name: string,
        active: boolean,
        requestBody: Array<CryotankLayoutNode>,
        description?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cryotanks',
            query: {
                'name': name,
                'active': active,
                'description': description,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Cryotank
     * Update data of existing cryotank.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCryotank({
        cryotankId,
        name,
        active,
        requestBody,
        description,
    }: {
        cryotankId: number,
        name: string,
        active: boolean,
        requestBody: Array<CryotankLayoutNode>,
        description?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cryotanks/{cryotank_id}',
            path: {
                'cryotank_id': cryotankId,
            },
            query: {
                'name': name,
                'active': active,
                'description': description,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Cryotank
     * Delete a cryotank.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCryotank({
        cryotankId,
    }: {
        cryotankId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cryotanks/{cryotank_id}',
            path: {
                'cryotank_id': cryotankId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Cryotank Path Color
     * Update the color of one node inside the cryotank.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCryotankPathColor({
        cryotankId,
        requestBody,
        color,
    }: {
        cryotankId: number,
        requestBody: Array<string>,
        color?: ('white' | 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'cyan' | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cryotanks/{cryotank_id}/path_color',
            path: {
                'cryotank_id': cryotankId,
            },
            query: {
                'color': color,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
