import { Observable } from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import {
    ListFilterItem,
    ListFilterLocationItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { frames } from "../../lib/pyratTop";
import {
    cgiScript,
    getUrl,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./infertilityReportListFilter.html";


interface Arguments extends ListViewArguments {
    export_args: any;
}


const InfertilityReportListFilters = () => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {

        owner_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "fullname");
            }
        },

        strain_id: class extends ListFilterItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = _.map(seed.possible_values, "name");
            }
        },

        location: class extends ListFilterLocationItem {
            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.valid = () => {
                    return this.initialized() === true || this.serialize() === seed.default_value;
                };
            }
        },
    };
};


class InfertilityReportList {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            this.showFilter(args);
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        // Table Body

        this.listView.onCellClick("td.eartag a", (args) => {
            this.listView.highlightRow(args.rowId);
            frames.openListDetailPopup(
                getUrl(cgiScript("mousedetail.py"), {
                    animalid: parseInt(args.rowId, 10),
                }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });

        this.listView.onCellClick("td.cage_number a", (args) => {
            this.listView.highlightRow(args.rowId);
            frames.openListDetailPopup(
                getUrl(cgiScript("cagedetail.py"), {
                    cageid: parseInt(args.element.getAttribute("cageid"), 10),
                }),
                () => this.listView.unHighlightRow(args.rowId),
            );
        });
    }

    public showFilter = (args: Arguments) => {
        showListFilter({
            viewName: args.view_name,
            filterModels: InfertilityReportListFilters,
            filterTemplate: filterTemplate,
            title: getTranslation("Report filter"),
        });
    };
}

export const initInfertilityReportList = (args: Arguments): void => {
    new InfertilityReportList(document.querySelector("div.listview"), args);
};
