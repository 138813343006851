import { Observable } from "knockout";

import { ListFilterDefinition } from "../../backend/v1";
import { showSeverityLevelDetails } from "../../dialogs";
import {
    ListFilterItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    getUrl,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./severityLevelListFilter.html";

interface Arguments extends ListViewArguments {
    export_args: any;
}

const ListFilters = () => ({
    available: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },

    is_in_use: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = seed.possible_values?.map((item: any) => item.name);
        }
    },
});

class List {
    private listView: ListView;
    private args: Arguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Severity levels filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.export_args,
            });
        });

        this.listView.onMenuBoxClick("create-new-severity-level", () => {
            showSeverityLevelDetails({
                severityLevelId: null,
                reloadCallback: this.listView.reload,
            });
        });

        this.listView.onCellClick(
            `td.severity.clickable,
             td.label.clickable,
             td.eu_code.clickable,
             td.available.clickable,
             td.is_in_use.clickable`,
            (args) => {
                const severityLevelId = parseInt(args.rowId, 10);
                if (severityLevelId) {
                    showSeverityLevelDetails({
                        severityLevelId: severityLevelId,
                        closeCallback: () => this.listView.unHighlightRow(args.rowId),
                        reloadCallback: () => this.listView.reload({ flashRowId: args.rowId }),
                    });
                    this.listView.highlightRow(args.rowId);
                }
            },
        );
    }
}

export const initSeverityLevelList = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
