import {
    Computed,
    ObservableArray,
    Observable,
} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";

import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    cgiScript,
    getUrl,
    getFormData,
    AjaxResponse,
} from "../lib/utils";

interface Arguments {
    origins: {
        id: number;
        name: string;
    }[];
    catalogs: {
        id: number;
        name: string;
        origin_id: number;
        available: boolean;
        deletable: boolean;
    }[];
}

interface Catalog {
    id: Observable<number>;
    name: CheckExtended<Observable<string>>;
    origin_id: CheckExtended<Observable<number>>;
    available: Observable<boolean>;
    deletable?: Observable<boolean>;
    changed?: Observable<boolean>;
    deleted?: Observable<boolean>;
}

interface SubmitData {
    update_catalogs: {
        id: number | undefined;
        name: string;
        origin_id: number;
        available: boolean;
    }[];
}

class OrderRequestCatalogList {

    public seed: Arguments;
    public catalogs: ObservableArray<Catalog>;
    public readonlyMode: boolean;
    public hideUnavailable: Observable<boolean>;
    public error: Observable<string>;
    public submitInProgress: Observable<boolean>;
    public canSubmit: Computed<boolean>;

    constructor(args: Arguments) {

        this.seed = args;

        this.catalogs = ko.observableArray(_.map(args.catalogs, (row) => {
            return this.getRowModel(row);
        }));
        this.readonlyMode = !session.userPermissions.order_request_catalog_update;
        this.hideUnavailable = ko.observable(true);
        this.error = ko.observable();
        this.submitInProgress = ko.observable(false);
        this.canSubmit = ko.computed(() => {
            const anyChange = _.some(this.catalogs(), function(row) {
                return row.changed() || row.deleted();
            });
            const anyInvalid = _.some(this.catalogs(), function(row) {
                return row.name.isInvalid() || row.origin_id.isInvalid();
            });

            return !this.submitInProgress()
                && !anyInvalid
                && anyChange;
        });
    }

    public highlightSaveButton = () => {
        const saveButton = document.getElementById("save_button");

        saveButton.classList.add("blink_twice");
        saveButton.addEventListener("animationend", function () {
            this.classList.remove("blink_twice");
        });
    };

    private getRowModel = (row?: Arguments["catalogs"][0]) => {
        const rowModel: Catalog = {
            "id": ko.observable(),
            "name": ko.observable().extend({ invalid: (v) => { return !v; } }),
            "origin_id": ko.observable().extend({ invalid: (v) => { return !v; } }),
            "available": ko.observable(),
            "deletable": ko.observable(),
            "changed": ko.observable(false),
            "deleted": ko.observable(false),
        };

        if (row) {
            // existing catalog
            rowModel.id(row.id);
            rowModel.name(row.name);
            rowModel.origin_id(row.origin_id);
            rowModel.available(row.available);
            rowModel.deletable(row.deletable);

            const handleChange = () => {
                rowModel.changed(true);
                this.highlightSaveButton();
            };

            rowModel.name.subscribe(handleChange);
            rowModel.origin_id.subscribe(handleChange);
            rowModel.available.subscribe(handleChange);

        } else {
            // new catalog
            rowModel.available(true);
            rowModel.changed(true);
        }

        return rowModel;
    };

    public cancel = () => {
        location.href = getUrl(cgiScript("order_request_catalog_list.py"));
    };

    public showCatalogItemList = () => {
        location.href = getUrl(cgiScript("order_request_catalog_item_list.py"));
    };

    public addItem = () => {
        this.catalogs.push(this.getRowModel());
        this.highlightSaveButton();
    };

    public removeItem = (item: Catalog) => {
        if (item.id() && !item.deleted()) {
            item.deleted(true);
            this.highlightSaveButton();
        } else if (item.id() && item.deleted()) {
            item.deleted(false);
        } else {
            this.catalogs.remove(item);
        }
    };

    private getRequestData = () => {
        const requestData: SubmitData = {
            update_catalogs: _.map(_.filter(this.catalogs(), (row) => {
                return row.changed() || row.deleted();
            }), function (row) {
                return {
                    "id": row.id(),
                    "name": row.name(),
                    "origin_id": row.origin_id(),
                    "available": row.available(),
                    "changed": row.changed(),
                    "deleted": row.deleted(),
                };
            }),
        };
        return requestData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.error("");

        fetch(cgiScript("order_request_catalog_list.py"), {
            method: "POST",
            body: getFormData({
                request: JSON.stringify(this.getRequestData()),
            }),
        })
            .then((response) => response.json())
            .then((response: AjaxResponse<any>) => {
                if (response.success) {
                    window.location.reload();
                    notifications.showNotification(getTranslation("Changes saved"), "success");
                } else {
                    // validation errors
                    this.submitInProgress(false);
                    this.error(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.error(getTranslation("Action failed. The data could not be saved. Please try again."));
            });
    };

}


export const initOrderRequestCatalogList = (args: Arguments): void => {
    ko.applyBindings(new OrderRequestCatalogList(args));
};
