interface TouchSelectOptions {
    container: HTMLTableSectionElement;  // Table <body> element
    onSelectRow?: (row: HTMLTableRowElement) => void;
    onUnselectRow?: (row: HTMLTableRowElement) => void;
    onSelectionChanged: (rows: Set<HTMLTableRowElement>) => void;
}

export class TouchSelect {

    private container: HTMLTableSectionElement;
    private state?: { select: boolean; startRow: any; x: number; y: number; rows: any[] };
    public onSelectRow: (row: HTMLTableRowElement) => void;
    public onUnselectRow: (row: HTMLTableRowElement) => void;
    public onSelectionChanged: (rows: Set<HTMLTableRowElement>) => void;
    public selectedRows: Set<HTMLTableRowElement> = new Set([]);

    constructor({ container, onSelectRow, onUnselectRow, onSelectionChanged }: TouchSelectOptions) {

        // store arguments
        this.container = container;
        this.onSelectRow = onSelectRow;
        this.onUnselectRow = onUnselectRow;
        this.onSelectionChanged = onSelectionChanged;

        document.querySelectorAll(".touchselect, .touchselect *").forEach((element) => {
            // setup touch tracking
            element.addEventListener("touchstart", (event: TouchEvent) => {
                const touch = event.touches.item(0);
                if (!this.onStart(touch.clientX, touch.clientY)) {
                    event.preventDefault();
                }
            });
            element.addEventListener("touchmove", (event: TouchEvent) => {
                const touch = event.touches.item(0);
                if (!this.onMove(touch.clientX, touch.clientY)) {
                    event.preventDefault();
                }
                return false;
            });
            document.addEventListener("touchend", (event: MouseEvent) => {
                if (this.state) {
                    this.state = undefined;
                    event.preventDefault();
                }
            });

            // setup mouse tracking
            element.addEventListener("mousedown", (event: MouseEvent) => {
                if (!this.onStart(event.clientX, event.clientY)) {
                    event.preventDefault();
                }
            });
            element.addEventListener("mousemove", (event: MouseEvent) => {
                if (this.state) {
                    if (!this.onMove(event.clientX, event.clientY)) {
                        event.preventDefault();
                    }
                }
            });
            document.addEventListener("mouseup", (event: MouseEvent) => {
                if (this.state) {
                    this.state = undefined;
                    event.preventDefault();
                }
            });

            // disable normal click events
            element.addEventListener("click", (event: MouseEvent) => {
                event.preventDefault();
                return false;
            });
        });
    }

    public isSelected(row: HTMLTableRowElement): boolean {
        return (row.querySelector(".touchselect input[type=checkbox]") as HTMLInputElement)?.checked;
    }

    /**
     * Toggle all rows to the given select state.
     */
    public toggleAll = (select: boolean) => {
        this.container.querySelectorAll(":scope > tr").forEach((r: HTMLTableRowElement) => this.toggleRow(r, select));
    };

    /**
     * Search through the parents of element until a direct child of container is found.
     * Stop searching after maxDepth.
     */
    private getTouchedRow = (element: HTMLElement): HTMLTableRowElement | undefined => {
        const maxDepth = 6;
        let current = element;
        let i;

        if (!element) {
            return undefined;
        }

        for (i = 0; i < maxDepth; i++) {
            if (!current) {
                return undefined;
            }
            if (current.parentElement === this.container) {
                if (current instanceof HTMLTableRowElement) {
                    return current;
                }
            }
            current = current.parentElement;
        }

        return undefined;
    };

    /**
     * Set the select state of a row in the DOM.
     */
    private applySelection(row: HTMLTableRowElement, select: boolean) {
        if (select) {
            row.classList.add("selected-row");
            (row.querySelector(".touchselect input[type=checkbox]") as HTMLInputElement).checked = true;
            this.onSelectRow?.call(undefined, row);
            this.selectedRows.add(row);
        } else {
            row.classList.remove("selected-row");
            (row.querySelector(".touchselect input[type=checkbox]") as HTMLInputElement).checked = false;
            this.onUnselectRow?.call(undefined, row);
            this.selectedRows.delete(row);
        }
        this.onSelectionChanged?.call(undefined, this.selectedRows);
    }

    /**
     * Turn row into the given select state, keeping the old state in a dataset.
     */
    private toggleRow = (row: HTMLTableRowElement, select: boolean) => {
        row.dataset.touchSelectToggleState = JSON.stringify(this.isSelected(row));
        this.applySelection(row, select);
    };

    /**
     * Return to the old select state of row, opposite operation of toggleRow. Fallback to given state.
     */
    private unToggleRow = (row: HTMLTableRowElement, select: boolean) => {
        if (row.dataset?.touchSelectToggleState) {
            const wasSelected = JSON.parse(row.dataset.touchSelectToggleState);
            this.applySelection(row, wasSelected);
            row.dataset.touchSelectToggleState = undefined;
        } else {
            // unable to restore old state, so just set the new state
            this.applySelection(row, select);
        }
    };

    /**
     * Find siblings starting at and not including e0 to (including) e1 and return them as a list.
     */
    private rowsBetween = (e0: HTMLTableRowElement, e1: HTMLTableRowElement) => {
        let current;
        const elements: HTMLTableRowElement[] = [];

        if (e0 === e1) {
            return [];
        }

        const e0y = e0.getBoundingClientRect().bottom;
        const e1y = e1.getBoundingClientRect().bottom;
        if (e0y < e1y) {
            current = e0.nextElementSibling;
            while (current && current !== e1.nextElementSibling) {
                if (current instanceof HTMLTableRowElement) {
                    elements.push(current);
                }
                current = current.nextElementSibling;
            }
        } else {
            current = e0.previousElementSibling;
            while (current && current !== e1.previousElementSibling) {
                if (current instanceof HTMLTableRowElement) {
                    elements.push(current);
                }
                current = current.previousElementSibling;
            }
        }

        return elements;
    };

    private onStart = (x: number, y: number) => {
        if (this.state) {
            // touch event already started
            return true;
        }

        const element = document.elementFromPoint(x, y);
        if (!element) {
            return true;
        }
        const row = this.getTouchedRow(element as HTMLElement);
        if (!row) {
            return true;
        }
        const select = !this.isSelected(row);
        this.state = {
            startRow: row, //
            x: x,
            y: y,
            select: select,
            rows: [],
        };
        this.toggleRow(row, select);
    };

    private onMove = (x: number, y: number) => {
        if (!this.state) {
            // no touch event started
            return true;
        }

        // find the current element using only the vertical touch position, to
        // allow for less accurate gestures
        const element = document.elementFromPoint(this.state.x, y);
        if (!element) {
            return true;
        }
        const row = this.getTouchedRow(element as HTMLElement);
        if (!row) {
            return false;
        }

        // update the selected elements
        const affectedRows = this.rowsBetween(this.state.startRow, row);

        if (affectedRows[0] !== this.state.rows[0]) {
            // direction changed -> deselect all
            this.state.rows.forEach((r) => {
                this.unToggleRow(r, this.state.select);
            });
            affectedRows.forEach((r) => {
                this.toggleRow(r, this.state.select);
            });
        } else {
            if (affectedRows.length < this.state.rows.length) {
                // moving towards e0 -> deselect surplus rows
                for (let i = affectedRows.length; i < this.state.rows.length; i++) {
                    this.unToggleRow(this.state.rows[i], this.state.select);
                }
            } else {
                // moving away from e0 -> select additional rows
                for (let i = this.state.rows.length; i < affectedRows.length; i++) {
                    this.toggleRow(affectedRows[i], this.state.select);
                }
            }
        }
        this.state.rows = affectedRows;

        return false;
    };
}
