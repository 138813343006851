import * as ko from "knockout";
import { Observable } from "knockout";
import * as _ from "lodash";

import {
    CageHardwareService,
    CageHardwareStatusResponse,
    GalileiCageTalkerService,
    TecniplastDvcService,
    TecniplastUnknownCageLocation,
} from "../../backend/v1";
import { getTranslation } from "../../lib/localize";
import { notifications } from "../../lib/pyratTop";
import { naturalCompare } from "../../lib/utils";
import { FetchBackendExtended } from "../extensions/fetchBackend";

import template from "./cageHardwareWidget.html";


import "./cageHardwareWidget.scss";

interface CageHardwareWidgetParams {
    cageNumber: string;  // CageNumber of the cage.
    cageId: number;  // Database ID of the cage.
}


class TecniplastModel {
    public widget: CageHardwareWidgetViewModel;
    public cage: CageHardwareStatusResponse["provider"]["tecniplast"];
    public obtainTecniplastDVCPositionInProgress: Observable<boolean>;
    public updateTecniplastDVCCageInProgress: Observable<boolean>;
    public disconnectCageInProgress: Observable<boolean>;
    public connectCageInProgress: Observable<boolean>;
    public turnLabelLEDOnInProgress: Observable<boolean>;
    public turnLabelLEDOffInProgress: Observable<boolean>;
    public connectCageVisible: Observable<boolean>;
    public connectCagePositions: FetchBackendExtended<Observable<TecniplastUnknownCageLocation[]>>;
    public connectCageDialogPosition: Observable<HTMLElement | undefined>;

    constructor(widget: CageHardwareWidgetViewModel, seed: CageHardwareStatusResponse["provider"]["tecniplast"]) {
        this.widget = widget;
        this.cage = seed;

        this.obtainTecniplastDVCPositionInProgress = ko.observable(false);
        this.updateTecniplastDVCCageInProgress = ko.observable(false);
        this.disconnectCageInProgress = ko.observable(false);
        this.connectCageInProgress = ko.observable(false);
        this.turnLabelLEDOnInProgress = ko.observable(false);
        this.turnLabelLEDOffInProgress = ko.observable(false);

        this.connectCageVisible = ko.observable(false);
        this.widget.seed.subscribe(() => {
            this.connectCageVisible(false);
        });

        this.connectCagePositions = ko.observable().extend({
            fetchBackend: () => {
                if (this.connectCageVisible()) {
                    return TecniplastDvcService.getUnknownCages();
                }
            },
        });

        this.connectCageDialogPosition = ko.observable();
        this.connectCagePositions.subscribe(() => {
            const timeout = 30000;
            setTimeout(() => {
                // never automatic refresh more often then timeout says
                if (this.connectCagePositions.lastSuccess() < new Date(new Date().getTime() - timeout)) {
                    this.connectCagePositions.forceReload();
                }
            }, timeout);
        });
    }

    public collectPositionGroups = (positions: TecniplastUnknownCageLocation["positions"]) => {
        const columns = _.uniq(_.map(positions, (p) => {
            return p.slice(1);
        })).sort(naturalCompare).reverse();
        const rows = _.uniq(_.map(positions, (p) => {
            return p.slice(0, 1);
        })).sort(naturalCompare);

        return _.map(columns, (c) => {
            return _.map(rows, (r) => {
                if (_.includes(positions, r + c)) return r + c;
                return undefined;
            });
        });
    };

    public connectCageShow = (item: CageHardwareWidgetViewModel, event: MouseEvent) => {
        const target = event.target as HTMLElement;
        this.connectCageVisible(true);
        this.connectCageDialogPosition(target);
        this.connectCagePositions.subscribeOnce(_.partial(_.defer, this.connectCageDialogPosition, target));
    };

    public connectCage = (location: TecniplastUnknownCageLocation, position: string) => {
        this.connectCageInProgress(true);
        this.connectCageVisible(false);
        TecniplastDvcService.connectCage({
            cageId: this.widget.cageId,
            requestBody: {
                rack_id: location.amsRackId,
                position: position,
            },
        })
            .then(() => {
                notifications.showNotification(
                    _.template(getTranslation("Connected cage <%- cageNumber %>."))({
                        cageNumber: this.widget.cageNumber,
                    }),
                    "success",
                );
            })
            .catch(() => {
                notifications.showNotification(
                    _.template(getTranslation("Connecting cage <%- cageNumber %> failed."))({
                        cageNumber: this.widget.cageNumber,
                    }),
                    "error",
                );
            })
            .finally(() => {
                this.connectCageInProgress(false);
                this.widget.seed.forceReload();
            });
    };

    public obtainTecniplastDVCPosition = () => {
        this.obtainTecniplastDVCPositionInProgress(true);
        TecniplastDvcService.obtainDvcPosition({ cageId: this.widget.cageId })
            .then(() => {
                notifications.showNotification(
                    _.template(getTranslation("Obtained the position of cage <%- cageNumber %>."))({
                        cageNumber: this.widget.cageNumber,
                    }),
                    "success",
                );
            })
            .catch(() => {
                notifications.showNotification(
                    _.template(getTranslation("Obtaining the position of cage <%- cageNumber %> failed."))({
                        cageNumber: this.widget.cageNumber,
                    }),
                    "error",
                );
            })
            .finally(() => {
                this.obtainTecniplastDVCPositionInProgress(false);
                this.widget.seed.forceReload();
            });
    };

    public updateTecniplastDVCCage = () => {
        this.updateTecniplastDVCCageInProgress(true);
        TecniplastDvcService.updateDvcCage({ cageId: this.widget.cageId })
            .then(() => {
                notifications.showNotification(
                    _.template(getTranslation("Updated cage <%- cageNumber %>."))({
                        cageNumber: this.widget.cageNumber,
                    }),
                    "success",
                );
            })
            .catch(() => {
                notifications.showNotification(
                    _.template(getTranslation("Updating cage <%- cageNumber %> failed."))({
                        cageNumber: this.widget.cageNumber,
                    }),
                    "error",
                );
            })
            .finally(() => {
                this.updateTecniplastDVCCageInProgress(false);
            });
    };

    public disconnectCage = () => {
        this.disconnectCageInProgress(true);
        TecniplastDvcService.disconnectCage({ cageId: this.widget.cageId })
            .then(() => {
                notifications.showNotification(
                    _.template(getTranslation("Disconnected cage <%- cageNumber %>."))({
                        cageNumber: this.widget.cageNumber,
                    }),
                    "success",
                );
            })
            .catch(() => {
                notifications.showNotification(
                    _.template(getTranslation("Disconnecting cage <%- cageNumber %> failed."))({
                        cageNumber: this.widget.cageNumber,
                    }),
                    "error",
                );
            })
            .finally(() => {
                this.disconnectCageInProgress(false);
                this.widget.seed.forceReload();
            });
    };

    public turnLabelLEDOn = () => {
        this.turnLabelLEDOnInProgress(true);
        TecniplastDvcService.turnCageLedOn({ cageId: this.widget.cageId })
            .then(() => {
                this.widget.seed.forceReload();
            })
            .finally(() => {
                this.turnLabelLEDOnInProgress(false);
            });
    };

    public turnLabelLEDOff = () => {
        this.turnLabelLEDOffInProgress(true);
        TecniplastDvcService.turnCageLedOff({ cageId: this.widget.cageId })
            .then(() => {
                this.widget.seed.forceReload();
            })
            .finally(() => {
                this.turnLabelLEDOffInProgress(false);
            });
    };
}


class GalileiModel {
    public widget: CageHardwareWidgetViewModel;
    public cage: CageHardwareStatusResponse["provider"]["galilei"];
    public freeLabelId: Observable<string>;
    public pairLabelInProgress: Observable<boolean>;
    public unpairLabelInProgress: Observable<boolean>;
    public turnLabelLEDOnInProgress: Observable<boolean>;
    public turnLabelLEDOffInProgress: Observable<boolean>;
    public updateLabelInProgress: Observable<boolean>;

    constructor(widget: CageHardwareWidgetViewModel, seed: CageHardwareStatusResponse["provider"]["galilei"]) {
        this.widget = widget;
        this.cage = seed;
        this.freeLabelId = ko.observable("");
        this.pairLabelInProgress = ko.observable(false);
        this.unpairLabelInProgress = ko.observable(false);
        this.turnLabelLEDOnInProgress = ko.observable(false);
        this.turnLabelLEDOffInProgress = ko.observable(false);
        this.updateLabelInProgress = ko.observable(false);
    }

    public pairLabel = () => {
        this.pairLabelInProgress(true);
        GalileiCageTalkerService.pairLabel({
            cageId: this.widget.cageId,
            requestBody: { label_id: this.freeLabelId() },
        })
            .then(() => {
                const message = getTranslation("Paired cage <%- cageNumber %>.");
                notifications.showNotification(_.template(message)({ cageNumber: this.widget.cageNumber }), "success");
            })
            .catch(() => {
                const message = getTranslation("Pairing cage <%- cageNumber %> failed.");
                notifications.showNotification(_.template(message)({ cageNumber: this.widget.cageNumber }), "error");
            })
            .finally(() => {
                this.widget.seed.forceReload();
                this.pairLabelInProgress(false);
            });
    };

    public unpairLabel = () => {
        this.unpairLabelInProgress(true);
        GalileiCageTalkerService.unpairLabel({ cageId: this.widget.cageId })
            .then(() => {
                const message = getTranslation("Unpaired cage <%- cageNumber %>.");
                notifications.showNotification(_.template(message)({ cageNumber: this.widget.cageNumber }), "success");
            })
            .catch(() => {
                const message = getTranslation("Unpairing cage <%- cageNumber %> failed.");
                notifications.showNotification(_.template(message)({ cageNumber: this.widget.cageNumber }), "error");
            })
            .finally(() => {
                this.widget.seed.forceReload();
                this.unpairLabelInProgress(false);
            });
    };

    public turnLabelLEDOn = () => {
        this.turnLabelLEDOnInProgress(true);
        GalileiCageTalkerService.turnLabelLedOn({ cageId: this.widget.cageId })
            .then(() => {
                this.widget.seed.forceReload();
            })
            .finally(() => {
                this.turnLabelLEDOnInProgress(false);
            });
    };

    public turnLabelLEDOff = () => {
        this.turnLabelLEDOffInProgress(true);
        GalileiCageTalkerService.turnLabelLedOff({ cageId: this.widget.cageId })
            .then(() => {
                this.widget.seed.forceReload();
            })
            .finally(() => {
                this.turnLabelLEDOffInProgress(false);
            });
    };

    public updateLabel = () => {
        this.updateLabelInProgress(true);
        GalileiCageTalkerService.updateLabel({ cageId: this.widget.cageId }).finally(() => {
            this.updateLabelInProgress(false);
        });
    };
}

class CageHardwareWidgetViewModel {

    public cageNumber: string;
    public cageId: number;
    public error: Observable<string | undefined>;
    public tecniplast: Observable<TecniplastModel | undefined>;
    public galilei: Observable<GalileiModel | undefined>;
    public seed: FetchBackendExtended<Observable<CageHardwareStatusResponse> | undefined>;

    constructor({ cageNumber, cageId }: CageHardwareWidgetParams) {
        this.cageNumber = cageNumber;
        this.cageId = cageId;

        this.error = ko.observable();
        this.seed = ko.observable().extend({
            fetchBackend: () => CageHardwareService.getCageHardwareStatus({ cageId }),
        });

        this.seed.onCatch = (error) => {
            if (error?.body?.detail) {
                this.error(error.body.detail);
            } else {
                this.error(getTranslation("An error occurred while loading the cage hardware status."));
            }
        };

        // initialize the configured hardware models
        this.tecniplast = ko.observable(undefined);
        this.galilei = ko.observable(undefined);
        this.seed.subscribe((seed) => {

            // reset error
            this.error(undefined);

            // tecniplast dvc
            if (seed?.provider?.tecniplast) {
                this.tecniplast(new TecniplastModel(this, seed.provider.tecniplast));
            } else {
                this.tecniplast(undefined);
            }

            // galilei cage talker
            if (seed?.provider?.galilei) {
                this.galilei(new GalileiModel(this, seed.provider.galilei));
            } else {
                this.galilei(undefined);
            }

        });

    }

}

export class CageHardwareWidgetComponent {

    constructor() {

        return {
            viewModel: CageHardwareWidgetViewModel,
            template,
        };
    }
}
