import {
    MaybeObservable,
    Observable,
    observable,
    ObservableArray,
    observableArray,
    pureComputed,
    PureComputed,
    toJS,
    utils,
} from "knockout";
import * as _ from "lodash";

import {
    BasicUser,
    LocationDetailSeed,
    LocationsService,
    UsersService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";

import template from "./locationDetails.html";

import "./locationDetails.scss";


interface Arguments {
    locationId: string;
    reloadCallback?: () => void;
}

interface ObservablePosition {
    position: Observable<string>;
    height: Observable<number>;
    width: Observable<number>;
}

class LocationDetailViewModel {
    public readonly dialog: HtmlDialog;
    public readonly locationId: string;
    public readonly errorMessage: Observable<string> = observable("");
    public readonly reloadRequired: Observable<boolean> = observable(false);
    public readonly fetchInProgress: Observable<boolean> = observable(true);
    public readonly updateInProgress: Observable<boolean> = observable(false);
    public readonly location: Observable<LocationDetailSeed["location_details"]> = observable();
    public readonly printerList: Observable<LocationDetailSeed["printers"]> = observableArray();
    public readonly maxNameLength: Observable<LocationDetailSeed["max_name_length"]> = observable();
    public readonly statuses: Observable<LocationDetailSeed["statuses"]> = observableArray();
    public readonly sanitaryStatuses: Observable<LocationDetailSeed["sanitary_statuses"]> = observableArray();
    public readonly documentWidgetSeed: Observable<LocationDetailSeed["documents_seed"]> = observable();
    public readonly contactList: Observable<BasicUser[]> = observableArray();

    public readonly locationName: CheckExtended<Observable<string>>;
    public readonly locationAddress: Observable<string>;
    public readonly locationStatus: Observable<string> = observable();
    public readonly sanitaryStatus: Observable<number> = observable();
    public readonly printer: Observable<string> = observable();
    public readonly contactId: Observable<number> = observable();
    public readonly cageCapacity: CheckExtended<Observable<number>>;
    public readonly rowCount: CheckExtended<Observable<number>>;
    public readonly columnCount: CheckExtended<Observable<number>>;
    public readonly isTransferDestination: Observable<boolean> = observable(false);
    public readonly positions: ObservableArray<ObservableArray<ObservablePosition>>;
    public readonly startingPoint: Observable<"bottom_left" | "top_left" | "top_right" | "bottom_right">;
    public readonly countingOrder: Observable<"even" | "meandering">;
    public readonly countingDirection: Observable<"vertical" | "horizontal">;
    public readonly firstPosition: CheckExtended<Observable<string>>;
    public readonly secondPosition: Observable<string>;
    public readonly lastPosition: CheckExtended<Observable<string>>;
    public readonly canGenerate: PureComputed<boolean>;
    public readonly selectedPositionCell: Observable<ObservablePosition>;
    public readonly selectedPositionInputInFocus: Observable<boolean>;
    public readonly duplicatePositions: PureComputed<Observable<string>[]>;
    public readonly positionsCount: PureComputed<number>;

    public readonly copyFromRackId: Observable<string>;
    public readonly copyFromRackInProgress: Observable<boolean> = observable(false);
    public readonly generationInProgress: Observable<boolean> = observable(false);
    public readonly canApply: PureComputed<boolean>;

    constructor(dialog: HtmlDialog, { locationId, reloadCallback }: Arguments) {
        this.dialog = dialog;
        this.locationId = locationId;
        dialog.addOnClose(() => {
            if (this.reloadRequired() && typeof reloadCallback === "function") {
                reloadCallback();
            }
        });

        this.locationName = observable().extend({
            trim: true,
            invalid: (name) => !!(name && name.length > this.maxNameLength()),
        });

        this.locationAddress = observable().extend({
            trim: true,
        });

        this.cageCapacity = observable().extend({
            trim: true,
            invalid: (number) => !(/^[0-9]+$/g.test(number) && number < 2147483647),
        });

        this.rowCount = observable().extend({
            trim: true,
            invalid: (number) => !(/^[0-9]+$/g.test(number) && number < 2147483647),
        });

        this.columnCount = observable().extend({
            trim: true,
            invalid: (number) => !(/^[0-9]+$/g.test(number) && number < 2147483647),
        });

        this.positions = observableArray([]).extend({ deferred: true });

        this.startingPoint = observable().extend({
            localStorage: "location_editor.generator.starting_point",
        });

        this.countingOrder = observable().extend({
            localStorage: "location_editor.generator.counting_order",
        });

        this.countingDirection = observable().extend({
            localStorage: "location_editor.generator.counting_direction",
        });

        this.firstPosition = observable().extend({
            invalid: (v) => {
                return !(v && v.length);
            },
        });
        this.secondPosition = observable();
        this.lastPosition = observable().extend({
            invalid: (v) => {
                return !(v && v.length);
            },
        });

        LocationsService.getLocationDetails({ locationId })
            .then((seed) => {
                this.location(seed.location_details);
                this.printerList(seed.printers);
                this.maxNameLength(seed.max_name_length);
                this.statuses(seed.statuses);
                this.sanitaryStatuses(seed.sanitary_statuses);
                this.documentWidgetSeed(seed.documents_seed);

                this.locationName(seed.location_details.name);
                this.locationAddress(seed.location_details.address);
                this.locationStatus(seed.location_details.status);
                this.sanitaryStatus(seed.location_details.original_sanitary_status);
                this.contactId(seed.location_details.contact_id);
                this.cageCapacity(seed.location_details.cage_capacity);
                this.isTransferDestination(!!seed.location_details.transfer_destination);
                this.startingPoint(undefined);
                this.countingOrder(undefined);
                this.countingDirection(undefined);
                this.firstPosition(undefined);
                this.secondPosition(undefined);
                this.lastPosition(undefined);

                this.positions([]);
                if (seed.location_details.type === "rack") {
                    _.forEach(seed.location_details.positions, (row, rowNumber) => {
                        _.forEach(row, (cell, columnNumber) => {
                            this.insertPositionCell(rowNumber, columnNumber, cell);
                        });
                    });
                }

                if (seed.location_details.type === "building") {
                    dialog.setTitle(_.template(getTranslation("Edit building <%- name %>"))(seed.location_details));
                } else if (seed.location_details.type === "area") {
                    dialog.setTitle(_.template(getTranslation("Edit area <%- name %>"))(seed.location_details));
                } else if (seed.location_details.type === "room") {
                    dialog.setTitle(_.template(getTranslation("Edit room <%- name %>"))(seed.location_details));
                } else if (seed.location_details.type === "rack") {
                    dialog.setTitle(_.template(getTranslation("Edit rack <%- name %>"))(seed.location_details));
                }
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errorMessage(reason.body.detail);
                } else {
                    this.errorMessage(getTranslation("An error occurred. Please try again."));
                    writeException(reason);
                    throw reason;
                }
            })
            .finally(() => {
                this.fetchInProgress(false);
            });

        UsersService.getUsersForSetting({ level: ["admin", "staff", "scientist"] })
            .then((users) => {
                this.contactList(users);
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errorMessage(reason.body.detail);
                } else {
                    this.errorMessage(getTranslation("An error occurred. Please try again."));
                    writeException(reason);
                    throw reason;
                }
            });

        this.canGenerate = pureComputed(() => {
            return (
                !this.generationInProgress() &&
                this.firstPosition.isValid() &&
                this.lastPosition.isValid() &&
                (this.rowCount.isValid() || this.columnCount.isValid())
            );
        });

        this.selectedPositionCell = observable();
        this.selectedPositionInputInFocus = observable(false);
        this.selectedPositionCell.subscribe((v) => {
            if (v) {
                this.selectedPositionInputInFocus(true);
            }
        });

        this.duplicatePositions = pureComputed(() => {
            const allPositions = _.compact(_.map(_.flattenDeep(toJS(this.positions())), "position"));
            return _.uniq(
                _.filter(allPositions, (p) => {
                    return allPositions.indexOf(p) !== allPositions.lastIndexOf(p);
                }),
            );
        });

        this.positionsCount = pureComputed(() => {
            return _.reduce(
                this.positions(),
                (m, p) => {
                    return m + p().length;
                },
                0,
            );
        });

        this.positionsCount.subscribe(() => {
            this.selectedPositionCell(undefined);
        });

        this.copyFromRackId = observable().extend({ sessionStorage: "location_editor.copy_from_rack_id" });

        this.printerList.subscribe(() => {
            setTimeout(() => {
                this.printer(this.location().printer);
            }, 0);
        });

        this.canApply = pureComputed(() => {
            if (this.locationName.isInvalid()) {
                return false;
            }

            if (this.location().type === "rack") {
                if (this.cageCapacity.isInvalid()) {
                    return false;
                }

                if (this.duplicatePositions().length) {
                    return false;
                }
            }

            return true;
        });
    }

    public selectedPositionInputKeyDown = (data: any, event: KeyboardEvent) => {
        const currentRow = this.positions()[this.positionCellRow(this.selectedPositionCell())];
        let nextCell;

        if (currentRow) {
            nextCell = currentRow()[this.positionCellColumn(this.selectedPositionCell()) + 1];
        }

        if (event.key === "Enter") {
            if (nextCell) {
                this.selectedPositionCell(nextCell);
            } else {
                this.addAfterSelectedPositionCell();
            }
        }
        if (event.key === "Backspace" && this.selectedPositionCell().position().length === 0) {
            this.removeSelectedPositionCell();
        } else {
            return true;
        }
    };

    public positionCellRow = (cell: ObservablePosition) => {
        let i;
        const positions = this.positions();
        for (i = 0; i < positions.length; i++) {
            if (_.includes(positions[i](), cell)) {
                return i;
            }
        }
    };

    public positionCellColumn = (cell: ObservablePosition) => {
        return this.positions()[this.positionCellRow(cell)].indexOf(cell);
    };

    public nextRowSize = (cell: ObservablePosition) => {
        return _.size(utils.unwrapObservable(this.positions()[this.positionCellRow(cell) + 1]));
    };

    public removeSelectedPositionCell = () => {
        const currentRow = this.positions()[this.positionCellRow(this.selectedPositionCell())];
        let previousCell;
        let rowNumber;

        if (currentRow) {
            previousCell = currentRow()[this.positionCellColumn(this.selectedPositionCell()) - 1];
        }

        currentRow.remove(this.selectedPositionCell());

        for (rowNumber = this.positions().length - 1; rowNumber > 0; rowNumber--) {
            if (this.positions()[rowNumber]().length === 0) {
                this.positions.splice(rowNumber, 1);
            } else break;
        }

        this.selectedPositionCell(previousCell);
    };

    public insertPositionCell = (
        row: number,
        column: number,
        parameters?: {
            position?: MaybeObservable<string>;
            height?: MaybeObservable<number>;
            width?: MaybeObservable<number>;
        },
    ) => {
        if (!parameters) {
            parameters = {};
        }

        let currentRowNumber;
        const newPosition = {
            position: observable(utils.unwrapObservable(parameters.position) || ""),
            height: observable(utils.unwrapObservable(parameters.height) || 2),
            width: observable(utils.unwrapObservable(parameters.width) || 2),
        };

        if (row < 0) {
            this.positions.push(observableArray([]));
            currentRowNumber = this.positions().length - 1;
        } else if (row > this.positions().length - 1) {
            _.forEach(_.range(this.positions().length - 1, row), () => {
                this.positions.push(observableArray([]));
            });
            currentRowNumber = this.positions().length - 1;
        } else currentRowNumber = row;

        if (column < 0) {
            this.positions()[currentRowNumber].push(newPosition);
        } else if (column === 0) {
            this.positions()[currentRowNumber].unshift(newPosition);
        } else if (column > this.positions()[currentRowNumber]().length) {
            this.positions()[currentRowNumber].push(newPosition);
        } else {
            this.positions()[currentRowNumber].splice(column, 0, newPosition);
        }

        return newPosition;
    };

    public createEmptyLayout = () => {
        const newPosition = this.insertPositionCell(-1, -1);
        setTimeout(() => {
            this.selectedPositionCell(newPosition);
        }, 0);
    };

    public addBelowSelectedPositionCell = () => {
        const newPosition = this.insertPositionCell(
            this.positionCellRow(this.selectedPositionCell()) + this.selectedPositionCell().height(),
            this.positionCellColumn(this.selectedPositionCell()),
            {
                height: this.selectedPositionCell().height,
                width: this.selectedPositionCell().width,
            },
        );
        setTimeout(() => {
            this.selectedPositionCell(newPosition);
        }, 0);
    };

    public addAfterSelectedPositionCell = () => {
        const newPosition = this.insertPositionCell(
            this.positionCellRow(this.selectedPositionCell()),
            this.positionCellColumn(this.selectedPositionCell()) + 1,
            {
                height: this.selectedPositionCell().height,
                width: this.selectedPositionCell().width,
            },
        );
        setTimeout(() => {
            this.selectedPositionCell(newPosition);
        }, 0);
    };

    public addBeforeSelectedPositionCell = () => {
        const newPosition = this.insertPositionCell(
            this.positionCellRow(this.selectedPositionCell()),
            this.positionCellColumn(this.selectedPositionCell()),
            {
                height: this.selectedPositionCell().height,
                width: this.selectedPositionCell().width,
            },
        );
        setTimeout(() => {
            this.selectedPositionCell(newPosition);
        }, 0);
    };

    public copyFromRack = () => {
        this.copyFromRackInProgress(true);
        this.errorMessage(undefined);
        LocationsService.getLocationDetails({ locationId: this.copyFromRackId() })
            .then((response) => {
                _.forEach(response.location_details.positions, (row, rowNumber) => {
                    _.forEach(row, (cell, columnNumber) => {
                        this.insertPositionCell(rowNumber, columnNumber, cell);
                    });
                });
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errorMessage(reason.body.detail);
                } else {
                    this.errorMessage(getTranslation("An error occurred. Please try again."));
                    writeException(reason);
                    throw reason;
                }
            })
            .finally(() => {
                this.copyFromRackInProgress(false);
            });
    };
    public generateLayout = () => {
        this.generationInProgress(true);
        this.errorMessage(undefined);
        LocationsService.generateRackPositions({
            startingPoint: this.startingPoint(),
            countingOrder: this.countingOrder(),
            countingDirection: this.countingDirection(),
            firstPosition: this.firstPosition(),
            secondPosition: _.trim(this.secondPosition()).length ? this.secondPosition() : null,
            lastPosition: this.lastPosition(),
            rowCount: parseInt(String(this.rowCount()), 10) || null,
            columnCount: parseInt(String(this.columnCount()), 10) || null,
        })
            .then((response) => {
                _.forEach(response, (row, rowNumber) => {
                    _.forEach(row, (generatedPosition) => {
                        this.insertPositionCell(rowNumber * 2, -1, { position: generatedPosition });
                    });
                });
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errorMessage(reason.body.detail);
                } else {
                    this.errorMessage(getTranslation("An error occurred. Please try again."));
                    writeException(reason);
                    throw reason;
                }
            })

            .finally(() => {
                this.generationInProgress(false);
            });
    };

    public applyChanges = () => {
        const requestBody: Parameters<typeof LocationsService.updateLocationDetails>[0]["requestBody"] = {
            available: this.locationStatus() === "available",
            name: this.locationName(),
            contact_id: this.contactId(),
        };

        if (this.location().type === "room") {
            requestBody.sanitary_status = parseInt(String(this.sanitaryStatus()), 10);
            requestBody.printer = this.printer();
        }

        if (this.location().type === "rack") {
            requestBody.sanitary_status = parseInt(String(this.sanitaryStatus()), 10);
            requestBody.cage_capacity = parseInt(String(this.cageCapacity()), 10) || 0;
            // @ts-expect-error: Knockout toJS will unwrap all observables, but the type is not correct
            requestBody.positions = toJS(this.positions());
        }

        if (this.location().type !== "rack") {
            requestBody.transfer_destination = this.isTransferDestination();
            requestBody.address = this.locationAddress();
        }
        this.updateInProgress(true);
        this.errorMessage(undefined);
        LocationsService.updateLocationDetails({
            locationId: this.locationId,
            requestBody,
        })
            .then(() => {
                this.reloadRequired(true);
                this.dialog.close();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errorMessage(reason.body.detail);
                } else {
                    this.errorMessage(getTranslation("An error occurred. Please try again."));
                }
            })
            .finally(() => {
                this.updateInProgress(false);
            });
    };

    public deleteLocation = () => {
        this.updateInProgress(true);
        this.errorMessage(undefined);
        LocationsService.deleteLocation({ locationId: this.locationId })
            .then(() => {
                this.reloadRequired(true);
                this.dialog.close();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errorMessage(reason.body.detail);
                } else {
                    this.errorMessage(getTranslation("An error occurred. Please try again."));
                    writeException(reason);
                    throw reason;
                }
            })
            .finally(() => {
                this.updateInProgress(false);
            });
    };

    public closePopup = () => {
        this.dialog.close();
    };
}

export const showLocationDetails = htmlDialogStarter(LocationDetailViewModel, template, () => ({
    name: "LocationDetails",
    width: 700,
    title: getTranslation("Edit location"),
    position: {
        inset: {
            top: 20,
            right: 20,
        },
    },
    closeOthers: true,
}));
