import {
    ListView,
    ListViewArguments,
    OrderBy,
    showColumnSelect,
} from "../../lib/listView";
import { mainMenu } from "../../lib/pyratTop";

interface Arguments extends ListViewArguments {
    export_arguments: any;
}

class TankStrainList {
    private listView: ListView;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("export-to-excel-button", () => {
            showColumnSelect({
                mode: "export",
                viewName: args.view_name,
                exportArgs: args.export_arguments,
            });
        });

        ["adult", "juvenile", "larva"].forEach((ageLevel) => {
            this.listView.onCellClick("td.num_" + ageLevel + " a", (args) => {
                mainMenu.openAndResetListFilter("get_tank_list", {
                    status: "open",
                    age_level: ageLevel,
                    strain_id: args.element.getAttribute("data-strain-id"),
                });
            });
        });
    }
}

export const initTankStrainList = (args: Arguments): void => {
    new TankStrainList(document.querySelector("div.listview"), args);
};
