/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_pairLabel } from '../models/Body_pairLabel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GalileiCageTalkerService {

    /**
     * Galilei Post Label
     * Pair a label with a Galilei CageTalker.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static pairLabel({
        cageId,
        requestBody,
    }: {
        cageId: number,
        requestBody: Body_pairLabel,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/hardware/galilei_cage_talker/{cage_id}',
            path: {
                'cage_id': cageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Galilei Unpair Label
     * Unpair a label with a Galilei CageTalker.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static unpairLabel({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cages/hardware/galilei_cage_talker/{cage_id}',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Galilei Update Cage Label
     * Update the label on a Galilei CageTalker.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateLabel({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cages/hardware/galilei_cage_talker/{cage_id}',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Galilei Turn Label Led On
     * Turn the LED on a Galilei CageTalker label on.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static turnLabelLedOn({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cages/hardware/galilei_cage_talker/{cage_id}/led',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Galilei Turn Label Led Off
     * Turn the LED on a Galilei CageTalker label off.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static turnLabelLedOff({
        cageId,
    }: {
        cageId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cages/hardware/galilei_cage_talker/{cage_id}/led',
            path: {
                'cage_id': cageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
