/**
 * Show the embryo QuickSelect pop-up.
 *
 * @param embryoGroupKeys
 *        Unique hashes of the embryo groups on which the actions will be applied.
 *        Either `embryoGroupKeys` or `embryoIds` need to be defined.
 *
 * @param embryoIds
 *        Database IDs of embryos on which the actions will be performed.
 *        Either `embryoGroupKeys` or `embryoIds` need to be defined.
 *
 * @param reloadCallback
 *        Function to call when data has been applied and popup is closed
 *        (e.g. to reload a list to display new data).
 *
 */

import * as ko from "knockout";

import {
    AddCommentSchema,
    BlindCommentWidgetSeed,
    EmbryoQuickselectActionsResponse,
    EmbryoQuickselectContextRow,
    EmbryosService,
    FosterMother,
    IdNameProperty,
    Owner,
    ProjectForSetting,
    StrainNameDetails,
    StrainOption,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { showColumnSelect } from "../lib/listView";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    frames,
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    cgiScript,
    checkDecimal,
    isInvalidCalendarDate,
    normalizeDate,
} from "../lib/utils";

import template from "./embryoQuickselect.html";
import "/scss/quick_select.scss";

interface Params {
    embryoGroupKeys?: Array<string>;
    embryoIds?: Array<number>;
    reloadCallback?: () => void;
}

interface ContextRow extends EmbryoQuickselectContextRow {
    selected: ko.Observable<boolean>;
    selectedEmbryoCount: CheckExtended<ko.Observable<number>>;
    possibleEmbryoIds: ko.PureComputed<Array<number>>;
    selectedEmbryoCountDisabled: boolean;
}

interface StrawLabel {
    label: ko.Observable<string>;
    origin: ko.Observable<"generated" | "progress" | "custom">;
    position: number;
}

interface FosterMotherForDisplay {
    animalId: number;
    eartag: string;
    plugdateDisplay: string;
    strainNameWithId: string;
    showStrainNameWithId: boolean;
    newEartagPrefix: CheckExtended<ko.Observable<string>>;
    newEartagSuffix: CheckExtended<ko.Observable<string>>;
    labid: ko.Observable<string>;
    left: CheckExtended<ko.Observable<number>>;
    right: CheckExtended<ko.Observable<number>>;
    subGeneration1: ko.Observable<string>;
    newGeneration1: ko.Observable<string>;
    subGeneration2: ko.Observable<string>;
    newGeneration2: ko.Observable<string>;
    additionalGeneration: ko.Observable<string>;
    generationValid: ko.Observable<boolean>;
    comment: ko.Observable<string>;
}

abstract class QuickselectAction {

    private qs: EmbryoQuickselectViewModel;
    private actionKey: ko.PureComputed<string>;
    public selected: ko.Subscribable<boolean>;
    public enabled: ko.Subscribable<boolean>;
    public possibleStateIds: Array<number>;
    public possible: ko.Subscribable<boolean>;
    public valid: ko.Subscribable<boolean>;
    public errors: ko.ObservableArray<string>;
    public applicable: ko.PureComputed<boolean>;

    public requireConclusion = true;

    protected constructor(qs: EmbryoQuickselectViewModel) {
        this.qs = qs;
        this.actionKey = ko.pureComputed(() => {
            return Object.keys(this.qs.actions() || {}).find((actionKey) => {
                return this.qs.actions()[actionKey] === this;
            });
        });

        this.selected = ko.observable(false);
        this.enabled = ko.observable(true);
        this.possibleStateIds = undefined;  // `undefined` means the action is allowed for all embryo states
        this.possible = ko.pureComputed(() => {
            if (!this.possibleStateIds) {
                return true;
            }

            return qs.selectedContext().every((embryoGroup) => {
                return this.possibleStateIds.includes(embryoGroup.state_id);
            });
        });
        this.valid = ko.observable(true);
        this.errors = ko.observableArray();

        this.applicable = ko.pureComputed(() => this.enabled() && this.possible() && this.selected());
    }

    public serialize = () => ({});
}

const actionModels: { [actionKey: string]: (qs: EmbryoQuickselectViewModel, seed: { [key: string]: any }) => QuickselectAction } = {
    /**
     *                                      enabled                         possible_states
     * discard_action                       true                            incubated, cryopreserved
     * adjust_amount_action                 selectedContext.length === 1    incubated, cryopreserved
     * cryopreserve_assemble_action         true                            incubated
     * cryopreserve_move_action             true                            cryopreserved
     * cryopreserve_set_date_action         true                            cryopreserved
     * cryopreserve_thaw_action             true                            cryopreserved
     * cryopreserve_set_thaw_date_action    all embryos in selectedContext have a thaw date
     *                                                                      incubated
     * microinject_action                   true                            incubated
     * set_project_action                   true                            <all>
     * set_strain_action                    true                            incubated, transferred
     * add_comment_action                   true                            <all>
     * add_document_action                  true                            <all>
     * export_to_institution_action         true                            incubated, cryopreserved
     * export_to_scientist_action           true                            incubated, cryopreserved
     * export_to_xls_action                 true                            <all>
     * transfer_to_foster_mother_action     true                            incubated
     *
     */

    discard_action: (qs, seed) => new (class extends QuickselectAction {
        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
                seed.embryo_cryopreservation_state_id,
            ];
        }
    }),

    adjust_amount_action: (qs, seed) => new (class extends QuickselectAction {
        private amount: CheckExtended<ko.Observable<number>>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
                seed.embryo_cryopreservation_state_id,
            ];

            this.amount = ko.observable().extend({
                invalid: (v) => {
                    return !(v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0));
                },
            });
            this.amount.subscribe(() => {
                this.selected(true);
            });

            qs.selectedContext.subscribe((selectedContext) => {
                let actionSelected;

                // enable only for one embryo group at once
                if (selectedContext.length === 1) {
                    actionSelected = this.selected();
                    this.amount(selectedContext[0]?.embryo_count);
                    this.selected(actionSelected);  // set to previous value again
                    this.enabled(true);
                } else {
                    this.amount(undefined);
                    this.selected(false);
                    this.enabled(false);
                }
            });
            qs.selectedContext.notifySubscribers(qs.selectedContext());

            this.valid = ko.computed(() => {
                const otherActionSelected = Object.values(qs.actions() || {}).some((action) => {
                    return action !== this && action.applicable();
                });

                this.errors.removeAll();

                if (otherActionSelected) {
                    if (this.applicable()) {
                        this.errors.push(getTranslation("This cannot be combined with other actions"));
                    }

                    return false;
                }

                if (this.amount.isInvalid()) {
                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ amount: this.amount() });
    }),

    cryopreserve_assemble_action: (qs, seed) => new (class extends QuickselectAction {
        private strawCount: CheckExtended<ko.Observable<number>>;  // how many straws
        private embryosPerStraw: CheckExtended<ko.Observable<number>>;  // how many embryos in each straw
        private strawCountInput: ko.Observable<string>;  // embryo amounts of each straw as space separated "list"
        private straws: CheckExtended<ko.PureComputed<Array<number>>>;  // content of `strawCountInput` as list of numbers
        private strawLabels: CheckExtended<ko.ObservableArray<StrawLabel>>;  // labels for each straw, `strawLabels.length` === `strawCount`

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
            ];


            this.strawCount = ko.observable().extend({
                invalid: (v) => {
                    return !((v === undefined ||
                              v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0)) &&
                             (!this.embryosPerStraw() || v > 0));
                },
            });
            this.embryosPerStraw = ko.observable().extend({
                invalid: (v) => {
                    return !((v === undefined ||
                              v > 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0)) &&
                             (!this.strawCount() || v > 0));
                },
            });

            this.strawCountInput = ko.observable().extend({
                rateLimit: {
                    timeout: 500,
                    method: "notifyWhenChangesStop",
                },
            });
            this.strawCountInput.subscribe(() => {
                this.selected(true);
            });

            this.straws = ko.pureComputed(() => {
                return (this.strawCountInput() || "").split(/[-/\\_;., ]/).filter((amount) => amount).map((amount) => parseInt(amount, 10));
            }).extend({
                invalid: (v) => {
                    if (!(v.length &&
                          v.every((amount) => amount > 0) &&
                          v.reduce((sum, amount) => sum + amount, 0) === qs.selectedEmbryoAmount())) {
                        return getTranslation("Count of embryos in straws and action don't match");
                    }

                    return false;
                },
            });
            this.straws.subscribe((amounts) => {
                const labels = this.strawLabels();
                const customLabels = labels.filter((label) => label.origin() === "custom");
                let i;

                for (i = labels.length; i < amounts.length; i++) {
                    labels.push({
                        label: ko.observable("..."),
                        origin: ko.observable("progress"),
                        position: i,
                    });
                }
                for (i = labels.length; i > amounts.length; i--) {
                    labels[i - 1] = {
                        label: ko.observable("..."),
                        origin: ko.observable("progress"),
                        position: i,
                    };
                }
                this.strawLabels(labels);

                EmbryosService.getNextFreeEmbryoStrawLabels({ count: amounts.length }).then((response) => {
                    const generatedLabels: Array<StrawLabel> = response.map((label: string, i: number) => {
                        return {
                            label: ko.observable(String(label)),
                            origin: ko.observable("generated"),
                            position: i,
                        };
                    });

                    // combine generated labels and custom labels
                    let combinedLabels: Array<StrawLabel> = generatedLabels.concat(customLabels.filter((label) => generatedLabels.indexOf(label) === -1));
                    // the custom labels overwrite the generated labels on duplicate positions
                    combinedLabels = Object.values(combinedLabels.reduce((memo, label) => {
                        return { ...memo, [label.position]: label };
                    }, {}));
                    // sort by position
                    this.strawLabels(combinedLabels.sort((a, b) => a.position - b.position));
                }).catch(qs.handleErrorResponse);
            });

            this.strawLabels =  ko.observableArray().extend({
                invalid: (v) => {
                    const duplicateStrawLabels = v.map((label) => {
                        return String(label.label()).trim();
                    }).filter((label, index, strawLabels) => {
                        return label.length && strawLabels.indexOf(label) !== index;
                    });

                    if (v?.some((label) => label.origin() === "progress")) {
                        return getTranslation("Please wait, ... straw labels still loading");
                    }

                    if (duplicateStrawLabels.length) {
                        return getTranslation("Duplicate straw labels") + ": " + duplicateStrawLabels.join(", ");
                    }

                    return false;
                },
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.straws.isInvalid()) {
                    if (this.applicable() && this.straws.errorMessage()) {
                        this.errors.push(this.straws.errorMessage());
                    }

                    return false;
                }

                if (this.strawLabels.isInvalid()) {
                    if (this.applicable() && this.strawLabels.errorMessage()) {
                        this.errors.push(this.strawLabels.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        private fillStraws = () => {
            const amounts = [];
            let i;

            for (i = 0; i < this.strawCount(); i++) {
                amounts.push(this.embryosPerStraw());
            }
            this.strawCountInput(amounts.join(" "));
        };

        private toggleLabel = (label: string) => {
            const toggledStrawLabels = this.strawLabels().map((strawLabel) => {
                if (strawLabel.label() === label) {
                    strawLabel.origin("custom");
                }
                return strawLabel;
            });

            this.strawLabels.notifySubscribers(toggledStrawLabels);
        };

        public serialize = () => ({
            straws: this.straws(),
            straw_labels: this.strawLabels().map((label) => label.label()),
        });
    }),

    cryopreserve_move_action: (qs, seed) => new (class extends QuickselectAction {
        private cryotankId: CheckExtended<ko.Observable<number>>;
        private possibleCryotanks: Array<IdNameProperty>;
        private cryotankPath: CheckExtended<ko.ObservableArray<string>>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_cryopreservation_state_id,
            ];

            this.cryotankId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select a cryotank");
                    }

                    return false;
                },
            });
            this.possibleCryotanks = seed.cryotanks;

            this.cryotankPath = ko.observableArray().extend({
                invalid: (v) => {
                    if (this.cryotankId() && !v.length) {
                        return getTranslation("No position in the cryotank selected");
                    }

                    return false;
                },
            });

            this.cryotankId.subscribe(() => {
                this.selected(true);
                this.cryotankPath([]);
            });
            this.cryotankPath.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.cryotankId.isInvalid()) {
                    if (this.applicable() && this.cryotankId.errorMessage()) {
                        this.errors.push(this.cryotankId.errorMessage());
                    }

                    return false;
                }

                if (this.cryotankPath.isInvalid()) {
                    if (this.applicable() && this.cryotankPath.errorMessage()) {
                        this.errors.push(this.cryotankPath.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({
            cryotank_id: this.cryotankId() || null,
            cryotank_address: this.cryotankPath().map((x) => {
                return { label: x };
            }) || null,
        });
    }),

    cryopreserve_set_date_action: (qs, seed) => new (class extends QuickselectAction {
        private freezeDate: CheckExtended<ko.Observable<string>>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_cryopreservation_state_id,
            ];

            this.freezeDate = ko.observable().extend({
                normalize: normalizeDate,
                invalid: (v) => !(!v || !isInvalidCalendarDate(v)),
            });
            this.freezeDate.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.pureComputed(() => {
                return this.freezeDate.isValid();
            });
        }

        public serialize = () => ({ cryopreservation_freeze_date: this.freezeDate() || null });
    }),

    cryopreserve_thaw_action: (qs, seed) => new (class extends QuickselectAction {
        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_cryopreservation_state_id,
            ];
        }
    }),

    cryopreserve_set_thaw_date_action: (qs, seed) => new (class extends QuickselectAction {
        private thawDate: CheckExtended<ko.Observable<string>>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
            ];

            this.enabled = ko.pureComputed(() => {
                // embryos must have been thawed before
                return qs.selectedContext().every((embryoGroup) => {
                    return embryoGroup.cryopreservation_thaw_date;
                });
            });

            this.thawDate = ko.observable().extend({
                normalize: normalizeDate,
                invalid: (v) => !(!v || !isInvalidCalendarDate(v)),
            });
            this.thawDate.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.pureComputed(() => {
                return this.thawDate.isValid();
            });
        }

        public serialize = () => ({ cryopreservation_thaw_date: this.thawDate() || null });
    }),

    microinject_action: (qs, seed) => new (class extends QuickselectAction {
        private microinjectStrainId: CheckExtended<ko.Observable<number>>;
        private possibleStrains: Array<StrainOption>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
            ];

            this.microinjectStrainId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select a Line / Strain");
                    }

                    return false;
                },
            });
            this.microinjectStrainId.subscribe(() => {
                this.selected(true);
            });

            this.possibleStrains = seed.active_strains;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.microinjectStrainId.isInvalid()) {
                    if (this.applicable() && this.microinjectStrainId.errorMessage()) {
                        this.errors.push(this.microinjectStrainId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ strain_id: this.microinjectStrainId() });
    }),

    set_project_action: (qs, seed) => new (class extends QuickselectAction {
        private projectId: CheckExtended<ko.Observable<number>>;
        private possibleProjects: Array<ProjectForSetting>;

        constructor() {
            super(qs);

            this.projectId = ko.observable().extend({
                invalid: (v) => {
                    if (!v && session.pyratConf.TRANSGENIC_MANDATORY_PROJECT) {
                        return getTranslation("Please select a project");
                    }

                    return false;
                },
            });
            this.projectId.subscribe(() => {
                this.selected(true);
            });

            this.possibleProjects = seed.active_projects;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.projectId.isInvalid()) {
                    if (this.applicable() && this.projectId.errorMessage()) {
                        this.errors.push(this.projectId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ project_id: this.projectId() });
    }),

    set_strain_action: (qs, seed) => new (class extends QuickselectAction {
        private strainId: CheckExtended<ko.Observable<number>>;
        private possibleStrains: Array<StrainOption>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
                seed.embryo_transfer_state_id,
            ];

            this.strainId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select a Line / Strain");
                    }

                    return false;
                },
            });
            this.strainId.subscribe(() => {
                this.selected(true);
            });

            this.possibleStrains = seed.active_strains;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.strainId.isInvalid()) {
                    if (this.applicable() && this.strainId.errorMessage()) {
                        this.errors.push(this.strainId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ strain_id: this.strainId() });
    }),

    add_comment_action: (qs, seed) => new (class extends QuickselectAction {
        private comment: ko.Observable<AddCommentSchema>;
        private commentWidgetSeed: BlindCommentWidgetSeed;

        constructor() {
            super(qs);

            this.comment = ko.observable();
            this.comment.subscribe(() => {
                this.selected(true);
            });

            this.commentWidgetSeed = seed.comment_widget_data;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (!this.comment()?.comment?.length && !this.comment()?.attributes?.length) {
                    if (this.applicable()) {
                        this.errors.push(getTranslation("The comment field is empty"));
                    }

                    return false;
                }

                return true;
            });

        }

        public serialize = () => this.comment();
    }),

    add_document_action: (qs) => new (class extends QuickselectAction {
        private newDocumentIds: ko.ObservableArray<number | { message: string }>;
        private pendingAction: ko.Observable<boolean>;

        constructor() {
            super(qs);

            this.newDocumentIds = ko.observableArray();
            this.newDocumentIds.subscribe((newValue) => {
                this.selected(Boolean(newValue.length));
            });

            this.pendingAction = ko.observable(false);

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.pendingAction() || !this.newDocumentIds().length) {
                    return false;
                }

                return !this.newDocumentIds().some((documentId) => {
                    if (typeof documentId === "object") {
                        if (this.applicable()) {
                            this.errors.push(documentId.message);
                        }

                        return true;
                    }

                    return false;
                });
            });
        }

        public serialize = () => ({ document_ids: this.newDocumentIds() });
    }),

    export_to_institution_action: (qs, seed) => new (class extends QuickselectAction {
        private institutionId: CheckExtended<ko.Observable<number>>;
        private possibleInstitutions: Array<IdNameProperty>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
                seed.embryo_cryopreservation_state_id,
            ];

            this.institutionId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select a facility");
                    }

                    return false;
                },
            });
            this.institutionId.subscribe(() => {
                this.selected(true);
            });

            this.possibleInstitutions = seed.available_institutions;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.institutionId.isInvalid()) {
                    if (this.applicable() && this.institutionId.errorMessage()) {
                        this.errors.push(this.institutionId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ institution_id: this.institutionId() });
    }),

    export_to_scientist_action: (qs, seed) => new (class extends QuickselectAction {
        private ownerId: CheckExtended<ko.Observable<number>>;
        private possibleOwners: Array<Owner>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
                seed.embryo_cryopreservation_state_id,
            ];

            this.ownerId = ko.observable().extend({
                invalid: (v) => {
                    if (!v) {
                        return getTranslation("Please select an owner");
                    }

                    return false;
                },
            });
            this.ownerId.subscribe(() => {
                this.selected(true);
            });

            this.possibleOwners = seed.active_owners;

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.ownerId.isInvalid()) {
                    if (this.applicable() && this.ownerId.errorMessage()) {
                        this.errors.push(this.ownerId.errorMessage());
                    }

                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({ owner_id: this.ownerId() });
    }),

    export_to_xls_action: (qs) => new (class extends QuickselectAction {
        public requireConclusion = false;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {
                const otherActionSelected = Object.values(qs.actions() || {}).some((action) => {
                    return action !== this && action.applicable();
                });

                this.errors.removeAll();

                if (otherActionSelected) {
                    if (this.applicable()) {
                        this.errors.push(getTranslation("This cannot be combined with other actions"));
                    }

                    return false;
                }

                return true;
            });
        }
    }),

    transfer_to_foster_mother_action: (qs, seed) => new (class extends QuickselectAction {
        private fosterMothers: ko.ObservableArray<FosterMotherForDisplay>;
        private showFosterMotherStrainSelect: boolean;
        private fosterMotherCount: CheckExtended<ko.Observable<number>>;
        private fosterMotherStrainId: ko.Observable<number>;
        private availableFosterMotherStrains: ko.ObservableArray<StrainNameDetails>;
        private usedFosterMothers: ko.PureComputed<Array<FosterMotherForDisplay>>;
        private generateSuffixesInProgress: ko.Observable<boolean>;
        private disableGenerateSuffixes: ko.PureComputed<boolean>;
        private remainingEmbryosCount: ko.PureComputed<number>;
        private errorMessage: ko.Observable<string>;

        constructor() {
            super(qs);

            this.possibleStateIds = [
                seed.embryo_incubation_state_id,
            ];

            this.fosterMothers = ko.observableArray(this.getFosterMothers(seed.available_foster_mothers));

            this.showFosterMotherStrainSelect = !seed.available_foster_mothers.length;
            this.fosterMotherCount = ko.observable(10).extend({
                invalid: (v) => {
                    return !(v > 0 && !checkDecimal(String(v), session.localesConf.decimalSymbol, undefined, 0));
                },
            });
            this.fosterMotherStrainId = ko.observable(seed.last_transfer_strain_id);
            this.availableFosterMotherStrains = ko.observableArray(seed.available_foster_mother_strains);

            this.usedFosterMothers = ko.pureComputed(() => {
                return this.fosterMothers().filter((fosterMother) => fosterMother.left() || fosterMother.right());
            });
            this.generateSuffixesInProgress = ko.observable(false);
            this.disableGenerateSuffixes = ko.pureComputed(() => {
                return this.generateSuffixesInProgress() ||
                        this.usedFosterMothers().every((fosterMother) => {
                            return fosterMother.newEartagPrefix.isValid() && fosterMother.newEartagSuffix.isValid();
                        }) ||
                        this.usedFosterMothers().some((fosterMother) => {
                            return !fosterMother.newEartagPrefix() && fosterMother.newEartagSuffix();
                        });
            });

            this.remainingEmbryosCount = ko.pureComputed(() => {
                return qs.selectedEmbryoAmount() - this.fosterMothers().reduce((transferredEmbryos, fosterMother) => {
                    return transferredEmbryos + (fosterMother.left() || 0) + (fosterMother.right() || 0);
                }, 0);
            });

            this.errorMessage = ko.observable();
            this.valid = ko.computed(() => {
                const usedFosterMothers = this.usedFosterMothers();
                const haveInvalidFields = this.fosterMothers().some((fosterMother) => {
                    return fosterMother.left.isInvalid() || fosterMother.right.isInvalid();
                });
                const haveIncompleteNewEartags = usedFosterMothers.some((fosterMother) => {
                    return fosterMother.newEartagPrefix.isInvalid() || fosterMother.newEartagSuffix.isInvalid();
                });

                this.errorMessage(undefined);

                if (!usedFosterMothers.length || haveInvalidFields) {
                    this.errorMessage(getTranslation("Enter transfers"));
                    return false;
                }

                if (usedFosterMothers.some((fosterMother) => !fosterMother.generationValid())) {
                    this.errorMessage(getTranslation("Invalid generation"));
                    return false;
                }

                if (haveIncompleteNewEartags) {
                    this.errorMessage(getTranslation("Generate suffixes"));
                    return false;
                }

                return !qs.applyInProgress();
            });
        }

        private getFosterMothers = (fosterMothers: Array<FosterMother>) => {
            const strainIdIndex = fosterMothers.map((fosterMother) => fosterMother.strain_id);

            return fosterMothers.sort((a, b) => {
                return strainIdIndex.indexOf(a.strain_id) - strainIdIndex.indexOf(b.strain_id);
            }).map((fosterMother, idx, allFosterMothers) => {
                const left = ko.observable().extend({
                    invalid: (v) => {
                        return !(v === undefined ||
                                 v === 0 ||
                                 v > 0 && this.remainingEmbryosCount() >= 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0));
                    },
                });
                const right = ko.observable().extend({
                    invalid: (v) => {
                        return !(v === undefined ||
                                 v === 0 ||
                                 v > 0 && this.remainingEmbryosCount() >= 0 && !checkDecimal(v, session.localesConf.decimalSymbol, undefined, 0));
                    },
                });
                const newEartagPrefix: CheckExtended<ko.Observable<string>> = ko.observable().extend({
                    invalid: (v) => {
                        return !v && Boolean(newEartagSuffix() && (left() || right()));
                    },
                });
                const newEartagSuffix: CheckExtended<ko.Observable<string>> = ko.observable().extend({
                    invalid: (v) => {
                        return !v && Boolean(newEartagPrefix() && (left() || right()));
                    },
                });

                return {
                    animalId: fosterMother.animal_id,
                    eartag: fosterMother.eartag,
                    plugdateDisplay: fosterMother.plugdate && fosterMother.plugdate_text ?
                        (fosterMother.plugdate + " (" + fosterMother.plugdate_text + ")") : "-",
                    strainNameWithId: fosterMother.strain_name_with_id,
                    showStrainNameWithId: !idx || fosterMother.strain_id !== allFosterMothers[idx - 1].strain_id,
                    newEartagPrefix: newEartagPrefix,
                    newEartagSuffix: newEartagSuffix,
                    labid: ko.observable(fosterMother.labid),
                    left: left,
                    right: right,
                    subGeneration1: ko.observable(fosterMother.breeding_setup_sub_generation_1),
                    newGeneration1: ko.observable(fosterMother.breeding_setup_new_generation_1),
                    subGeneration2: ko.observable(fosterMother.breeding_setup_sub_generation_2),
                    newGeneration2: ko.observable(fosterMother.breeding_setup_new_generation_2),
                    additionalGeneration: ko.observable(fosterMother.breeding_setup_additional_generation),
                    generationValid: ko.observable(true),
                    comment: ko.observable(),
                };
            });
        };

        private searchFosterMothers = () => {
            EmbryosService.getFosterMothersByStrainId({
                strainId: this.fosterMotherStrainId(),
                amount: this.fosterMotherCount(),
            }).then((response) => this.fosterMothers(this.getFosterMothers(response))).catch(qs.handleErrorResponse);
        };

        private generateSuffixes = () => {
            this.generateSuffixesInProgress(true);
            EmbryosService.getFosterMotherSuffixes({
                eartags: this.usedFosterMothers().filter((fosterMother) => {
                    return fosterMother.newEartagPrefix() && !fosterMother.newEartagSuffix();
                }).map((fosterMother) => {
                    return fosterMother.newEartagPrefix() + "-" + (fosterMother.newEartagSuffix() || "");
                }),
            }).then((response) => {
                this.usedFosterMothers().filter((fosterMother) => {
                    return fosterMother.newEartagPrefix() && !fosterMother.newEartagSuffix();
                }).forEach((fosterMother) => {
                    fosterMother.newEartagSuffix(response[fosterMother.newEartagPrefix()].pop());
                });
            }).catch((response) => {
                if (typeof (response.body?.message) === "string") {
                    notifications.showNotification(response.body.message, "error");
                } else {
                    qs.handleErrorResponse(response);
                }
            }).finally(() => this.generateSuffixesInProgress(false));
        };

        private submit = () => {
            qs.applyQuickselect();
        };

        public serialize = () => {
            return {
                foster_mothers: this.usedFosterMothers().map((fosterMother) => ({
                    animal_id: fosterMother.animalId,
                    new_eartag_prefix: fosterMother.newEartagPrefix() || undefined,
                    new_eartag_suffix: fosterMother.newEartagSuffix() || undefined,
                    labid: fosterMother.labid() || undefined,
                    left: fosterMother.left() || 0,
                    right: fosterMother.right() || 0,
                    sub_generation_1: fosterMother.subGeneration1() || undefined,
                    new_generation_1: fosterMother.newGeneration1() || undefined,
                    sub_generation_2: fosterMother.subGeneration2() || undefined,
                    new_generation_2: fosterMother.newGeneration2() || undefined,
                    additional_generation: fosterMother.additionalGeneration() || undefined,
                    comment: fosterMother.comment() || undefined,
                })),
            };
        };
    }),

};

class EmbryoQuickselectViewModel {
    private dialog: HtmlDialog;
    private reloadRequired: ko.Observable<boolean>;
    private loadInProgress: ko.Observable<boolean>;
    private embryoCryopreservationStateId: number;
    public readonly context: ko.ObservableArray<ContextRow>;
    public readonly actions: ko.Observable<{[actionKey: string]: QuickselectAction}>;
    public readonly selectedContext: ko.PureComputed<Array<ContextRow>>;
    public readonly selectedEmbryoAmount: ko.PureComputed<number>;  // sum of selected embryos in all groups in the context
    private dialogTitle: ko.PureComputed<string>;
    private scenery: ko.PureComputed<{
        loading: boolean;
    } | {
        conclusion: EmbryoQuickselectViewModel;
    } | {
        context: EmbryoQuickselectViewModel;
        actions: EmbryoQuickselectViewModel;
    } | {
        transfer_to_foster_mother: QuickselectAction;
    } | {
        error: boolean;
    }>;
    private anyActionSelected: ko.PureComputed<boolean>;
    private canApply: ko.PureComputed<boolean>;
    public readonly applyInProgress: ko.Observable<boolean>;
    private conclusion: ko.ObservableArray<{ text: string; click: () => void; warning?: boolean }>;

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;
        this.reloadRequired = ko.observable(false);

        this.dialog.addOnClose(() => {
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        this.loadInProgress = ko.observable(true);
        this.context = ko.observableArray();
        this.actions = ko.observable();
        EmbryosService.getEmbryoQuickselectSeed({
            requestBody: {
                embryo_group_keys: params.embryoGroupKeys,
                embryo_ids: params.embryoIds,
            },
        }).then((response) => {
            this.embryoCryopreservationStateId = response.embryo_cryopreservation_state_id;

            this.context(response.context?.map((embryoGroup) => {
                const possibleEmbryoIds = ko.pureComputed(() => {
                    if (params.embryoIds) {
                        return embryoGroup.embryo_group_ids.filter((embryoId) => params.embryoIds.includes(embryoId));
                    }

                    return embryoGroup.embryo_group_ids;
                });

                return {
                    ...embryoGroup,
                    selected: ko.observable(
                        params.embryoGroupKeys ?
                            params.embryoGroupKeys.includes(embryoGroup.embryo_groupkey) :
                            Boolean(possibleEmbryoIds().length),
                    ),
                    selectedEmbryoCount: ko.observable(possibleEmbryoIds().length).extend({
                        invalid: (v) => {
                            return !(v >= 0 &&
                                     v <= possibleEmbryoIds().length &&
                                     !checkDecimal(String(v), session.localesConf.decimalSymbol, undefined, 0));
                        },
                    }),
                    possibleEmbryoIds: possibleEmbryoIds,
                    selectedEmbryoCountDisabled: embryoGroup.state_id === this.embryoCryopreservationStateId,
                };
            }));

            this.actions(Object.keys(response.actions).reduce((memo, actionKey) => {
                if (Object.hasOwn(actionModels, actionKey)) {
                    return {
                        ...memo,
                        [actionKey]: actionModels[actionKey](this, response.actions[actionKey]),
                    };
                }

                return memo;
            }, {}));
        }).catch(this.handleErrorResponse).finally(() => this.loadInProgress(false));

        this.selectedContext = ko.pureComputed(() => {
            return this.context().filter((embryoGroup) => embryoGroup.selected());
        });

        this.selectedEmbryoAmount = ko.pureComputed(() => this.selectedContext().reduce((sum, embryoGroup) => {
            if (embryoGroup.selectedEmbryoCount.isValid()) {
                return sum + embryoGroup.selectedEmbryoCount();
            }

            return sum;
        }, 0));

        this.dialogTitle = ko.pureComputed(() => {
            const transferToFosterMotherAction = this.actions()?.transfer_to_foster_mother_action;

            if (transferToFosterMotherAction?.selected()) {
                return getTranslation("Transfer to foster mothers") +
                        " (" + this.selectedEmbryoAmount() + " " + getTranslation("embryos selected") + ")";
            }

            if (this.loadInProgress()) {
                return getTranslation("Quick Select");
            }

            return getTranslation("Quick Select") +
                    " (" + this.selectedEmbryoAmount() + " " + getTranslation("embryos selected") + ")";
        });
        this.dialogTitle.subscribe((dialogTitle) => {
            this.dialog.setTitle(dialogTitle);
        });

        this.scenery = ko.pureComputed(() => {
            const transferToFosterMotherAction = this.actions()?.transfer_to_foster_mother_action;

            if (this.loadInProgress()) {
                return { loading: true };
            }

            if (this.conclusion().length) {
                return { conclusion: this };
            }

            if (transferToFosterMotherAction?.selected()) {
                return { transfer_to_foster_mother: transferToFosterMotherAction };
            }

            if (this.context()?.length && this.actions() && Object.keys(this.actions()).length) {
                return {
                    context: this,
                    actions: this,
                };
            }

            return { error: true };
        });

        this.anyActionSelected = ko.pureComputed(() => Object.values(this.actions()).some((action) => action.applicable()));

        this.canApply = ko.pureComputed(() => {
            if (this.applyInProgress()) {
                return false;
            }

            if (this.selectedContext().some((embryoGroup) => embryoGroup.selectedEmbryoCount.isInvalid())) {
                return false;
            }

            if (this.selectedEmbryoAmount() < 1) {
                return false;
            }

            // any possible action selected at all?
            if (!this.anyActionSelected()) {
                return false;
            }

            // check if all possible selected actions are valid
            return Object.values(this.actions()).every((action) => {
                return !action.applicable() || !action.valid || Boolean(action.valid());
            });
        });

        this.applyInProgress = ko.observable(false);

        this.conclusion = ko.observableArray();
    }

    private toggleSelection = () => {
        this.context().forEach((embryoGroup) => {
            embryoGroup.selected(!embryoGroup.selected());
        });
    };

    public handleErrorResponse = (response: any) => {
        if (typeof response.body?.detail === "string") {
            notifications.showNotification(response.body.detail, "error");
        } else {
            notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
            writeException(response);
        }
    };

    public applyQuickselect = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);
        Object.values(this.actions()).forEach((action) => {
            action.errors.removeAll();
        });

        EmbryosService.applyEmbryoQuickselectActions({
            requestBody: {
                context: {
                    embryo_ids: this.selectedContext().map((embryoGroup) => embryoGroup.possibleEmbryoIds().slice(0, embryoGroup.selectedEmbryoCount())).flat(),
                },
                content: Object.keys(this.actions()).reduce((memo, actionKey) => {
                    const action = this.actions()[actionKey];

                    if (action.applicable()) {
                        return {
                            ...memo,
                            [actionKey]: action.serialize(),
                        };
                    }

                    return memo;
                }, {}),
            },
        }).then((response) => {
            this.conclude(response);
        }).catch((response) => {
            const content = response.body?.detail?.content;

            if (content) {
                Object.keys(content).forEach((actionKey) => {
                    if (this.actions()[actionKey] && this.actions()[actionKey].errors) {
                        content[actionKey].forEach((message: string) => {
                            this.actions()[actionKey].errors.push(message);
                        });
                    } else {
                        content[actionKey].forEach((message: string) => {
                            notifications.showNotification(message, "error");
                        });
                    }
                });
            } else {
                notifications.showNotification(getTranslation("General quickselect error."), "error");
                writeException(response);
            }
        }).finally(() => this.applyInProgress(false));
    };

    private conclude = (value: EmbryoQuickselectActionsResponse) => {
        const cryopreserveAssembleAction = value?.content?.cryopreserve_assemble_action;
        const exportAction = value?.content?.export_to_xls_action;
        const transferToFosterMotherAction = value?.content?.transfer_to_foster_mother_action;
        const embryoIds = value?.context?.embryo_ids;
        const thawedEmbryoIds = cryopreserveAssembleAction?.thawed_embryo_ids;
        const fosterMotherIds = transferToFosterMotherAction?.foster_mother_ids;
        const canClose = Object.keys(value?.content).every((actionKey) => {
            const action = this.actions()[actionKey];

            return !action?.applicable() || !action?.requireConclusion;
        });

        if (canClose) {
            if (exportAction) {
                this.reloadRequired(false);
                showColumnSelect({
                    mode: "export",
                    viewName: "embryolist",
                    exportArgs: {
                        embryo_id: exportAction.embryo_ids,
                        page_start: 0,
                        page_size: exportAction.embryo_ids.length,
                    },
                });
            }

            // no conclusion was needed, so we close it
            this.dialog.close();
        } else {
            this.conclusion.removeAll();

            if (embryoIds?.length) {
                this.conclusion.push({
                    text: getTranslation("Show group of modified embryos") + " (" + embryoIds.length + ")",
                    click: () => {
                        mainMenu.openAndResetListFilter("get_embryo_list", {
                            embryo_id: embryoIds,
                        });
                    },
                });
            }

            if (fosterMotherIds?.length) {
                this.conclusion.push({
                    text: getTranslation("Show foster mothers") + " (" + fosterMotherIds.length + ")",
                    click: () => {
                        mainMenu.openAndResetListFilter("get_animal_list", {
                            animalid: fosterMotherIds,
                        });
                    },
                });

                this.conclusion.push({
                    text: getTranslation("Open Quick Select for the foster animals to perform another task") +
                            " (" + fosterMotherIds.length + ")",
                    click: () => {
                        frames.openQuickSelect(cgiScript("quickselect_animal.py"), {
                            sessionid: session.sessionId,
                            animals: fosterMotherIds.join(","),
                            show_action: [
                                "move_to_existing_cage",
                                "move_to_new_cage",
                                "workrequest",
                                "export_animals"].join(","),
                        });
                    },
                });
            }

            if (cryopreserveAssembleAction) {
                this.conclusion.push({
                    text: getTranslation("Show all unsettled embryos"),
                    click: () => {
                        mainMenu.openAndResetListFilter("get_embryo_list", {
                            state_id: this.embryoCryopreservationStateId,
                            cryotank_id: 0,
                        });
                    },
                });

                if (thawedEmbryoIds?.length) {
                    this.conclusion.push({
                        text: getTranslation("Some of the embryos were frozen, prior to the cryopreservation. Their thaw date was reset. Show these embryos.") +
                                " (" + thawedEmbryoIds.length + ")",
                        click: () => {
                            mainMenu.openAndResetListFilter("get_embryo_list", {
                                embryo_id: thawedEmbryoIds,
                            });
                        },
                        warning: true,
                    });
                }
            }
        }
    };

}

export const showEmbryoQuickselect = htmlDialogStarter(EmbryoQuickselectViewModel, template, {
    name: "EmbryoQuickselect",
    title: getTranslation("Quick Select"),
    width: 720,
    position: {
        inset: { top: 20, right: 20 },
    },
    closeOthers: true,
});
