/**
 * Compute dates for repeated instances (procedures, orders, work requests, ...)
 *
 * @param startDate
 *        date of first instance from which all repetition dates will be
 *        calculated, must contain a valid date
 *
 * @param repetitionDatesDescription
 *        short explanation displayed above the list of all the (calculated)
 *        repetition dates
 *
 * @param repetitionDatesNameAttribute
 *        value of the `name` attribute of the input fields with the
 *        (calculated) repetition dates
 *
 * @param repetitionDates
 *        editable repetition dates that can be sent to the server
 *
 * @param isInvalidDateCallback
 *        function that returns true or an error message when a repetiton date
 *        is invalid (besides the usual `isInvalidCalendarDate` check)
 *
 */

import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    formatDate,
    parseDate,
} from "../../lib/flatpickr";
import { getTranslation } from "../../lib/localize";
import {
    addDays,
    addWeeks,
    addWorkDays,
    isInvalidCalendarDate,
} from "../../lib/utils";
import { CheckExtended } from "../extensions/invalid";


import template from "./repeatedInstances.html";

interface RepeatedInstancesParams {
    startDate: Observable<string>;
    repetitionDatesDescription: string;
    repetitionDatesNameAttribute?: PureComputed<string>;
    repetitionDates: ObservableArray<CheckExtended<Observable<string>>>;
    maxDate?: "today";
    isInvalidDateCallback?: (v: string) => boolean | string;
}

class RepeatedInstancesViewModel {

    public repeatInterval: Observable<string>;
    public dailyChoice: Observable<string>;
    public weeklyChoice: Observable<string>;
    public numberOfRepetitions: CheckExtended<Observable<number>>;
    public daysOffset: CheckExtended<Observable<number>>;
    public weeksOffset: CheckExtended<Observable<number>>;
    public repetitionDatesDescription: string;
    public repetitionDatesNameAttribute: PureComputed<string>;
    public repetitionDates: ObservableArray<CheckExtended<Observable<string>>>;
    public maxDate: "today" | undefined;

    private calculatedRepetitionDates: PureComputed<string[]>;
    private isInvalidDateCallback: (v: string) => boolean | string;
    private readonly startDate: Observable<string>;

    constructor({
        startDate,
        repetitionDatesDescription,
        repetitionDatesNameAttribute,
        repetitionDates,
        maxDate,
        isInvalidDateCallback,
    }: RepeatedInstancesParams) {

        this.maxDate = maxDate;
        this.startDate = startDate;
        this.isInvalidDateCallback = isInvalidDateCallback;
        this.repeatInterval = ko.observable("daily");
        this.dailyChoice = ko.observable("every_day");
        this.weeklyChoice = ko.observable("every_week");

        this.numberOfRepetitions = ko.observable().extend({
            invalid: (v) => {
                if (_.isUndefined(v)) {
                    return true;
                }
                if (!(_.isNumber(v) && String(v).match(/^\d+$/))) {
                    return getTranslation("Invalid number");
                }
                if (v <= 0 || v > 50) {
                    return getTranslation("Please enter a number between 1 and %s").replace("%s", "50");
                }
                return false;
            },
        });

        this.daysOffset = ko.observable().extend({
            invalid: (v) => {
                if (this.repeatInterval() === "daily" &&
                        this.dailyChoice() === "every_x_day" &&
                        (_.isUndefined(v) || !(String(v).match(/^\d+$/)) || v <= 0)) {
                    return getTranslation("Invalid number");
                }
                return false;
            },
        });

        this.weeksOffset = ko.observable().extend({
            invalid: (v) => {
                if (this.repeatInterval() === "weekly" &&
                        this.weeklyChoice() === "every_x_week" &&
                        (_.isUndefined(v) || !(String(v).match(/^\d+$/)) || v <= 0)) {
                    return getTranslation("Invalid number");
                }
                return false;
            },
        });

        this.repetitionDatesDescription = repetitionDatesDescription;
        this.repetitionDatesNameAttribute = repetitionDatesNameAttribute;
        this.repetitionDates = repetitionDates;

        this.calculatedRepetitionDates = ko.pureComputed(() => {
            const startDate = this.startDate() ? parseDate(this.startDate()) : undefined;
            let interval: string;
            let offset: number;
            let lastDate: Date;
            const result: string[] = [];

            // validate
            if (!startDate ||
                    this.numberOfRepetitions.isInvalid() ||
                    this.daysOffset.isInvalid() ||
                    this.weeksOffset.isInvalid()) {
                return result;
            }

            if (this.repeatInterval() === "daily") {
                if (this.dailyChoice() === "every_day") {
                    interval = "every_day";
                    offset = 1;
                } else if (this.dailyChoice() === "every_weekday") {
                    interval = "every_weekday";
                    offset = 1;
                } else if (this.dailyChoice() === "every_x_day") {
                    interval = "every_day";
                    offset = this.daysOffset();
                }
            } else if (this.repeatInterval() === "weekly") {
                if (this.weeklyChoice() === "every_week") {
                    interval = "every_week";
                    offset = 1;
                } else if (this.weeklyChoice() === "every_x_week") {
                    interval = "every_week";
                    offset = this.weeksOffset();
                }
            } else if (this.repeatInterval() === "custom") {
                interval = "custom";
                offset = 0;
            }

            _.times(this.numberOfRepetitions(), (i: number) => {
                if (i === 0) {
                    lastDate = startDate;
                } else if (interval === "every_day") {
                    lastDate = addDays(lastDate, offset);
                } else if (interval === "every_weekday") {
                    lastDate = addWorkDays(lastDate, offset);
                } else if (interval === "every_week") {
                    lastDate = addWeeks(lastDate, offset);
                } else if (interval === "custom") {
                    lastDate = undefined;
                }

                result.push(lastDate ? formatDate(lastDate) : "");
            });

            return result;
        });
        this.calculatedRepetitionDates.subscribe((v) => {
            if (v) {
                this.repetitionDates([]);
                _.forEach(v, (date: string) => {
                    this.repetitionDates.push(ko.observable(date).extend({
                        invalid: (v) => {
                            if (!v || isInvalidCalendarDate(v)) {
                                return getTranslation("Invalid date");
                            }

                            return isInvalidDateCallback ? isInvalidDateCallback(v) : false;
                        },
                    }));
                });
            }
        });
    }

}

export class RepeatedInstancesComponent {

    constructor() {

        return {
            viewModel: RepeatedInstancesViewModel,
            template: template,
        };
    }
}
