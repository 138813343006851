import { MaybeObservable } from "knockout";
import * as ko from "knockout";

declare module "knockout" {
    export interface BindingHandlers {
        valueAsNumber?: {
            init(element: HTMLInputElement, valueAccessor: () => MaybeObservable<number>): void;
            update(element: HTMLInputElement, valueAccessor: () => MaybeObservable<number>): void;
        };
    }

}

/**
 * Update the given observable with the number entered to a number-input.
 *
 * This replaces some of the parsed values to ensure there are always
 * numbers or values that are displayed as an empty string (not just NaN).
 *
 * If the input is empty, the observable is set undefined.
 * If the input is not a valid number, the observable is set null.
 * Otherwise the observable will is to the entered number.
 */
ko.bindingHandlers.valueAsNumber = {
    init: function (element, valueAccessor) {

        function tagNameLower(element: HTMLElement) {
            // For HTML elements, tagName will always be upper case; for XHTML elements, it'll be lower case.
            // Possible future optimization: If we know it's an element from an XHTML document (not HTML),
            // we don't need to do the .toLowerCase() as it will always be lower case anyway.
            return element && element.tagName && element.tagName.toLowerCase();
        }

        if (tagNameLower(element) !== "input" || element.type !== "number") {
            throw "input type number required";
        }

        const bindingValue = valueAccessor();
        if (ko.isObservable(bindingValue)) {
            element.addEventListener("input", function () {
                if (element.validity.badInput) {
                    bindingValue(null);
                } else if (isNaN(element.valueAsNumber)) {
                    bindingValue(undefined);
                } else {
                    // only read values of number input elements from valueAsNumber property
                    bindingValue(element.valueAsNumber);
                }
            });
        }
    },
    update: function (element, valueAccessor) {
        const bindingValue = valueAccessor();
        if (ko.isObservable(bindingValue)) {
            const newValue = bindingValue();
            // only read or write values of number input elements from/to valueAsNumber property
            if (typeof newValue === "number" && newValue !== element.valueAsNumber) {
                element.valueAsNumber = newValue;
            } else if (typeof newValue === "undefined") {
                // this is the exception to the above rule
                // this is the only way to set elements value to "no-set"
                element.value = "";
            }
        } else {
            element.valueAsNumber = bindingValue;
        }
    },
};
