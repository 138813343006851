/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_addIndependentDocument } from '../models/Body_addIndependentDocument';
import type { Body_removeDocumentSubjects } from '../models/Body_removeDocumentSubjects';
import type { Body_storeDocument } from '../models/Body_storeDocument';
import type { Body_updateIndependentDocument } from '../models/Body_updateIndependentDocument';
import type { DocumentWidgetSeed } from '../models/DocumentWidgetSeed';
import type { IndependentDocumentDetails } from '../models/IndependentDocumentDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentsService {

    /**
     * Get Document Widget Seed
     * Get a seed for the document widget.
     * @returns DocumentWidgetSeed Successful Response
     * @throws ApiError
     */
    public static getDocumentWidgetSeed({
        requestBody,
    }: {
        requestBody: Record<string, Array<number>>,
    }): CancelablePromise<DocumentWidgetSeed> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documents/seed',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Document
     * Upload a document.
     *
     * Return the ID of the document.
     * @returns number Successful Response
     * @throws ApiError
     */
    public static storeDocument({
        formData,
    }: {
        formData: Body_storeDocument,
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documents/',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Document Subjects
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeDocumentSubjects({
        documentId,
        requestBody,
    }: {
        documentId: number,
        requestBody: Body_removeDocumentSubjects,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/documents/{document_id}/subjects',
            path: {
                'document_id': documentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Document Id
     * Delete a document.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteDocument({
        documentId,
    }: {
        documentId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Independent Document Id
     * Get details of an independent document.
     * @returns IndependentDocumentDetails Successful Response
     * @throws ApiError
     */
    public static getIndependentDocumentDetails({
        documentId,
    }: {
        documentId: number,
    }): CancelablePromise<IndependentDocumentDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documents/independent/{document_id}',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Independent Document Id
     * Update an independent document.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateIndependentDocument({
        documentId,
        requestBody,
    }: {
        documentId: number,
        requestBody?: Body_updateIndependentDocument,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/documents/independent/{document_id}',
            path: {
                'document_id': documentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Independent Document
     * Add an independent document.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addIndependentDocument({
        requestBody,
    }: {
        requestBody: Body_addIndependentDocument,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documents/independent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
