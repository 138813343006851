/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_scheduleReport } from '../models/Body_scheduleReport';
import type { Body_updateReport } from '../models/Body_updateReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScheduledReportsService {

    /**
     * Get Report Details Endpoint
     * Returns details of a scheduled report
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getReportDetails({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scheduled_reports/report/{report_id}',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Report Endpoint
     * Update a scheduled report
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static updateReport({
        reportId,
        requestBody,
    }: {
        reportId: number,
        requestBody: Body_updateReport,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scheduled_reports/report/{report_id}',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Report Endpoint
     * Delete a scheduled report
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static deleteReport({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scheduled_reports/report/{report_id}',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Available Reports Endpoint
     * Returns a list of available reports
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAvailableReports(): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scheduled_reports/available_reports',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Available Repetitions Endpoint
     * Returns a list of available repetitions
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAvailableRepetitions(): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scheduled_reports/available_repetitions',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Report Endpoint
     * Schedule a report
     * @returns number Successful Response
     * @throws ApiError
     */
    public static scheduleReport({
        requestBody,
    }: {
        requestBody: Body_scheduleReport,
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scheduled_reports/report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
