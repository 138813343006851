/**
 * Show a popup to set the responsible for a cage.
 *
 * @param cageId: Database ID of the cage.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";

import {
    GetCageResponsibleResponse,
    CagesService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";

import template from "./setCageResponsible.html";

interface Params {
    cageId: number;
    eventTarget: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetCageResponsibleViewModel {
    private readonly dialog: HtmlDialog;

    // params
    private readonly cageId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly responsibleId: Observable<number>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly fetchInProgress = ko.observable(true);
    private readonly submitInProgress= ko.observable(false);
    public readonly seed: Observable<GetCageResponsibleResponse | undefined> = ko.observable();


    constructor(dialog: HtmlDialog, params: Params) {

        this.dialog = dialog;
        this.cageId = params.cageId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.responsibleId = ko.observable();

        CagesService
            .getCageResponsible({ cageId: this.cageId })
            .then((response) => {
                this.seed(response);
                this.responsibleId(response.responsible_id);
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        this.canSubmit = ko.pureComputed(() => {
            return !this.submitInProgress()
                && !this.fetchInProgress()
                && this.responsibleId() !== this.seed()?.responsible_id;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    public submit = () => {
        this.submitInProgress(true);

        CagesService
            .setCageResponsible({
                cageId: this.cageId,
                responsibleId: this.responsibleId(),
            })
            .then(() => {
                this.dialog.close();
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }
                notifications.showNotification(getTranslation("Cage responsible changed"), "success");
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.submitInProgress(false));
    };
}

export const showSetCageResponsible = htmlDialogStarter(SetCageResponsibleViewModel, template, params => ({
    name: "SetCageResponsible",
    width: 400,
    title: params.title,
    position: { anchor: params.eventTarget },
}));
