/**
 * Show a pop-up to set RFID for given subject (id).
 *
 * @param animalId: Database ID of the animal for which to set RFID.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";

import {
    GetAnimalRFIDResponse,
    AnimalsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";

import template from "./setRFID.html";

interface Params {
    animalId?: number;
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetRFIDViewModel {
    public readonly allowEdit: boolean;
    public readonly canApply: PureComputed<boolean>;
    public readonly seed: Observable<GetAnimalRFIDResponse | undefined> = ko.observable();

    private readonly animalId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;
    private readonly dialog: HtmlDialog;
    private readonly fetchInProgress = ko.observable(true);
    private readonly applyInProgress = ko.observable(false);
    private readonly reloadRequired = ko.observable(false);
    private readonly rfID: CheckExtended<Observable<string>>;


    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;

        this.animalId = params.animalId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.allowEdit = session.userPermissions.animal_set_rfid
            && Boolean(this.animalId);

        this.rfID = ko.observable().extend({
            invalid: (value) => {
                return !!value && session.pyratConf.RFID_REGEX && !value.match(new RegExp(session.pyratConf.RFID_REGEX))
                    ? getTranslation("No valid RFID")
                    : false;
            },
        });

        AnimalsService
            .getAnimalRfId({ animalId: this.animalId })
            .then((response) => {
                this.seed(response);
                this.rfID(response.rfid);
                this.dialog.setTitle(
                    getTranslation("Set RFID for %s").replace("%s", String(response.eartag_or_id)),
                );
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        this.canApply = ko.pureComputed(() => {
            return !this.applyInProgress()
                && !this.fetchInProgress()
                && this.rfID.isValid()
                && this.rfID() !== this.seed()?.rfid;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadRequired() && typeof this.reloadCallback === "function") {
                this.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);

        AnimalsService
            .setAnimalRfId({
                animalId: this.animalId,
                rfid: this.rfID(),
            })
            .then(() => {
                notifications.showNotification(getTranslation("RFID updated"), "success");
                this.dialog.close();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.applyInProgress(false));
    };
}

export const showSetRFID = htmlDialogStarter(SetRFIDViewModel, template, params => ({
    name: "SetRFID",
    width: 270,
    title: getTranslation("Set RFID"),
    position: { anchor: params.eventTarget },
}));
