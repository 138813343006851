/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_getStrainIdsByName } from '../models/Body_getStrainIdsByName';
import type { StrainBlockDetails } from '../models/StrainBlockDetails';
import type { StrainNameDetailsWithGroup } from '../models/StrainNameDetailsWithGroup';
import type { StrainOption } from '../models/StrainOption';
import type { StrainsBySexForOwners } from '../models/StrainsBySexForOwners';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StrainsService {

    /**
     * List Strain Options
     * @returns StrainOption Successful Response
     * @throws ApiError
     */
    public static getStrainsForSetting({
        status,
        used,
        ownerUserId,
        permittedUser,
    }: {
        status?: ('available' | 'blocked' | null),
        used?: ('live' | 'dead' | 'unused' | null),
        ownerUserId?: Array<number>,
        permittedUser?: Array<number>,
    }): CancelablePromise<Array<StrainOption>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains/for_setting',
            query: {
                'status': status,
                'used': used,
                'owner_user_id': ownerUserId,
                'permitted_user': permittedUser,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Strain Options
     * @returns StrainOption Successful Response
     * @throws ApiError
     */
    public static getStrainsForFilter({
        status,
        used,
        ownerUserId,
        permittedUser,
    }: {
        status?: ('available' | 'blocked' | null),
        used?: ('live' | 'dead' | 'unused' | null),
        ownerUserId?: Array<number>,
        permittedUser?: Array<number>,
    }): CancelablePromise<Array<StrainOption>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains/for_filter',
            query: {
                'status': status,
                'used': used,
                'owner_user_id': ownerUserId,
                'permitted_user': permittedUser,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Strain Block Details
     * Get details for blocking a strain.
     * @returns StrainBlockDetails Successful Response
     * @throws ApiError
     */
    public static getStrainBlockDetails({
        strainId,
    }: {
        strainId: number,
    }): CancelablePromise<StrainBlockDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains/{strain_id}/block',
            path: {
                'strain_id': strainId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Block Strain
     * Block a strain.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static blockStrain({
        strainId,
        action,
        reassignTo,
    }: {
        strainId: number,
        action: 'reassign_mice' | 'reassign_mutations' | 'block',
        reassignTo?: (number | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/strains/{strain_id}/block',
            path: {
                'strain_id': strainId,
            },
            query: {
                'action': action,
                'reassign_to': reassignTo,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unblock Strain
     * Unblock a strain.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static unblockStrain({
        strainId,
    }: {
        strainId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/strains/{strain_id}/block',
            path: {
                'strain_id': strainId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Strains By Sex For Owners
     * Get strains for filter depending on the selected owner
     *
     * Send result as JSON response to client (a dict containing the strains divided by sex).
     *
     * :param owner_ids: '__all_for_alias__' to get strains of all aliases of the user's alias;
     * or ID(s) of scientist strains shall be fetched for
     * or Empty to get strains of all the user's aliases
     * @returns StrainsBySexForOwners Successful Response
     * @throws ApiError
     */
    public static getStrainsBySexForOwners({
        ownerIds,
    }: {
        ownerIds?: Array<number>,
    }): CancelablePromise<StrainsBySexForOwners> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains/strains_by_sex_for_owners',
            query: {
                'owner_ids': ownerIds,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Strains By Usage For Owners
     * Get strains for filter depending on the selected owner
     *
     * Send result as JSON response to client (a list of dicts containing
     * the strains divided by active/inactive).
     *
     * :param owner_ids: ['__all_for_alias__'] to get strains of all aliases of the user's alias;
     * or ID(s) of scientist strains shall be fetched for ('__all_for_alias__' might be included);
     * or None to get strains of all the user's aliases.
     *
     * :param filter_name: Name of a filter for which the strains are retrieved;
     * can be 'animal' or 'pup'; if not specified, all strains will be fetched.
     *
     * :param pup_strains: If pup strains should be fetched instead of animal strains;
     * should only be True when `filter` is not specified.
     *
     * :param unused: Will be passed to strain.get_strains_for_filter to indicate if we also want unused strains.
     *
     * :param active_or_inactive: Should the response contain active or inactive or both strains.
     * @returns StrainNameDetailsWithGroup Successful Response
     * @throws ApiError
     */
    public static getStrainsByUsageForOwners({
        ownerIds,
        filterName,
        pupStrains = false,
        unused = false,
        activeOrInactive = 'both',
    }: {
        ownerIds?: null,
        filterName?: ('animal' | 'pup' | null),
        pupStrains?: boolean,
        unused?: boolean,
        activeOrInactive?: 'both' | 'active' | 'inactive',
    }): CancelablePromise<Array<StrainNameDetailsWithGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains/strains_by_usage_for_owners',
            query: {
                'owner_ids': ownerIds,
                'filter_name': filterName,
                'pup_strains': pupStrains,
                'unused': unused,
                'active_or_inactive': activeOrInactive,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Strain Ids By Name
     * Get strain IDs from strain names with placeholders
     *
     * This is a post request, because the list of possible strain IDs
     * might be too long to be sent as a query parameter.
     *
     * :param strain_names: Strain names with placeholders.
     *
     * :param possible_strain_ids: All strain IDs that are possible for the
     * owner currently selected in the filter; the result will only IDs
     * that are included here; this is to not reveal strain information
     * that the user is not allowed to see.
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getStrainIdsByName({
        requestBody,
    }: {
        requestBody: Body_getStrainIdsByName,
    }): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/strains/strain_ids_by_name',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
