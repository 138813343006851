import { Observable } from "knockout";
import * as _ from "lodash";

import {
    CommentsService,
    ListFilterDefinition,
} from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import { writeException } from "../../lib/excepthook";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    normalizeDate,
    showLoading,
} from "../../lib/utils";

import filterTemplate from "./commentListFilter.html";


interface Arguments extends ListViewArguments {
    export_args: any;
}

// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = (filter: ListFilterModel) => ({
    contains: ListFilterItem,

    comment_date_from: class extends ListFilterItem {
        private value: CheckExtended<Observable>;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_to"), compareFromDate),
            });
        }
        public valid = () => this.value.isValid();
    },

    comment_date_to: class extends ListFilterItem {
        private value: CheckExtended<Observable>;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_from"), compareToDate),
            });
        }
        public valid = () => this.value.isValid();
    },

    reply_pending: ListFilterItem,

    creator_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    subject: class extends ListFilterItem {
        private readonly value: Observable;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: function (v) {
                    // unselect other subjects when "All" is selected
                    return _.some(v, function (subject) {
                        return !subject;
                    })
                        ? [undefined]
                        : v;
                },
            });

            this.text = _.map(seed.possible_values, "name");
        }

        deserialize = (newValue: string[]) => {
            this.value(newValue || []);
        };

        serialize = () => {
            if (
                !this.value() ||
                !this.value().length ||
                _.some(this.value(), function (subject) {
                    return !subject;
                })
            ) {
                return undefined;
            }

            return this.value();
        };
    },

    visible: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    page_size: ListFilterItem,
});

class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox actions
        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Comment filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });
        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        // Custom actions

        this.listView.onCellClick("td.subject a[data-subject=\"animal_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_animal_list", {
                animalid: _.map(String(identifier).split(","), (animalId) => parseInt(animalId, 10)),
            });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"pup_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_pup_list", {
                pupid: _.map(String(identifier).split(","), (pupId) => parseInt(pupId, 10)),
            });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"embryo_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_embryo_list", { embryo_id: identifier });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"cage_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_cage_list", {
                cageid: _.map(String(identifier).split(","), (cageId) => parseInt(cageId, 10)),
            });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"order_request_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_order_request_list", { id: identifier });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"service_request_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_service_request_list", { id: identifier });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"sperm_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_sperm_list", {
                sperm_id: _.map(String(identifier).split(","), (spermId) => parseInt(spermId, 10)),
            });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"tank_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_tank_list", {
                tank_id: _.map(String(identifier).split(","), (tankId) => parseInt(tankId, 10)),
            });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"license_id\"]", (args) => {
            const identifier = args.element.dataset.identifier;
            mainMenu.openAndResetListFilter("get_license_list", {
                license_id: _.map(String(identifier).split(","), (licenseId) => parseInt(licenseId, 10)),
            });
        });

        this.listView.onCellClick("td.visibility.visible_comment.clickable", (args) => {
            showLoading(CommentsService.markCommentHidden({ commentId: parseInt(args.rowId, 10) }))
                .then(() => {
                    this.listView.reload();
                    notifications.showNotification(getTranslation("Comment marked as hidden."), "success");
                })
                .catch((error) => {
                    writeException(error);
                });
        });

        this.listView.onCellClick("td.visibility.hidden_comment.clickable", (args) => {
            showLoading(CommentsService.markCommentVisible({ commentId: parseInt(args.rowId, 10) }))
                .then(() => {
                    this.listView.reload();
                    notifications.showNotification(getTranslation("Comment marked as visible."), "success");
                })
                .catch((error) => {
                    writeException(error);
                });
        });
    }
}

export const initCommentList = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
