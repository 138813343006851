import * as ko from "knockout";

import {
    HtmlDialog,
    HtmlDialogParams,
    KnockoutPopup,
    PopupParams,
} from "../lib/popups";


/** Create a dialog starter function from the given model and template.
 *
 * Warning: Do not give Observables as parameters to dialogs, they should act
 * only on initially the given data or callback. It works, but it will make a
 * transition away from knockout harder.
 *
 * Basically, this creates virtual dom element, instantiates the given model
 * and ko-binds it to the element.
 *
 * @param viewModel: View model class.
 * @param template: Template of the dialog.
 * @param config: Configuration the dialog.
 * @returns: The model instance.
 */
export const dialogStarter = <Model, Params>(
    viewModel: { new(params: Params, dialog: KnockoutPopup): Model },
    template: string,
    config: PopupParams | ((params: Params) => PopupParams) = {},
): (params: Params) => Model => {

    return (params): Model => {
        const container = document.createElement("div");

        let dialog: KnockoutPopup;
        if (typeof config === "function") {
            dialog = new KnockoutPopup(container, config(params));
        } else {
            dialog = new KnockoutPopup(container, config);
        }
        const model = new viewModel(params, dialog);
        container.innerHTML = template;
        ko.applyBindings(model, container);
        dialog.addOnClose(() => {
            if (dialog.params.destroyOnClose) {
                ko.cleanNode(container);
            }
        });
        return model;
    };

};


/** Create a dialog starter function from the given model and template.
 *
 * Same as the other dialogStarter, but with HtmlDialog as base class.
 */
export const htmlDialogStarter = <Model, Params, ConstructorParams>(
    viewModel: { new(dialog: HtmlDialog, params: Params, constructorParams: ConstructorParams): Model },
    template: string,
    config: HtmlDialogParams | ((params: Params, constructorParams: ConstructorParams) => HtmlDialogParams) = {},
    constructorParams?: ConstructorParams,
): (params: Params) => Model => {

    return (params): Model => {
        const container = document.createElement("div");

        let dialog: HtmlDialog;
        if (typeof config === "function") {
            dialog = new HtmlDialog(container, config(params, constructorParams));
        } else {
            dialog = new HtmlDialog(container, config);
        }
        const model = new viewModel(dialog, params, constructorParams);
        container.innerHTML = template;
        ko.applyBindings(model, container);
        dialog.addOnClose(() => {
            ko.cleanNode(container);
        });
        return model;
    };

};
