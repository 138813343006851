import { Observable } from "knockout";
import * as _ from "lodash";

import {
    DocumentsService,
    ListFilterDefinition,
} from "../../backend/v1";
import {
    showIndependentDocumentDetails,
    showMutationDetails,
    showOrderRequestDetails,
    showProjectDetails,
} from "../../dialogs";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
} from "../../lib/utils";

import filterTemplate from "./documentListFilter.html";


// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = (filter: ListFilterModel) => ({
    min_upload_date: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("max_upload_date"), compareFromDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    max_upload_date: class extends ListFilterItem {
        public value: CheckExtended<Observable<string>>;
        constructor(value: Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);
            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("min_upload_date"), compareToDate),
            });

            this.valid = () => {
                return this.value.isValid();
            };
        }
    },

    upload_user_id: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    subject: class extends ListFilterItem {
        private value: Observable<string[] | undefined[]>;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
            this.value = value.extend({
                normalize: (v) => {
                    // unselect other subjects when "All" is selected
                    return _.some(v, (subject) => !subject) ? [undefined] : v;
                },
            });
        }

        public deserialize = (newValue: string[]) => {
            this.value(newValue || []);
        };

        public serialize = () => {
            if (!this.value() || !this.value().length || _.some(this.value(), (subject) => !subject)) {
                return undefined;
            }
            return this.value();
        };
    },

    name: ListFilterItem,

    page_size: ListFilterItem,
});

class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: ListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Document filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        // Independent document

        this.listView.onMenuBoxClick("new-independent-document-button", () => {
            showIndependentDocumentDetails({ documentId: null });
        });

        this.listView.onCellClick("td.label a, td.reference a", (args) => {
            showIndependentDocumentDetails({ documentId: parseInt(args.rowId, 10) });
        });

        // Subject links

        // links to detail popups
        this.listView.onCellClick("td.subject a[data-subject=\"animal_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            if (ids.length > 1) {
                mainMenu.openAndResetListFilter("get_animal_list", { animalid: ids });
            } else {
                openListDetailPopup(getUrl(cgiScript("mousedetail.py"), { animalid: ids[0] }));
            }
        });

        this.listView.onCellClick("td.subject a[data-subject=\"pup_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            if (ids.length > 1) {
                mainMenu.openAndResetListFilter("get_pup_list", { pupid: ids });
            } else {
                openListDetailPopup(getUrl(cgiScript("pupdetail.py"), { animalid: ids[0] }));
            }
        });

        this.listView.onCellClick("td.subject a[data-subject=\"strain_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            if (ids.length > 1) {
                mainMenu.openAndResetListFilter("get_strain_list", { id: ids.join(",") });
            } else {
                openListDetailPopup(getUrl(cgiScript("edit_strain.py"), { strainid: ids[0] }));
            }
        });

        this.listView.onCellClick("td.subject a[data-subject=\"mutation_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            if (ids.length > 1) {
                mainMenu.openAndResetListFilter("get_mutation_list", { id: ids.join(",") });
            } else {
                showMutationDetails({
                    mutationId: ids[0],
                });
            }
        });

        this.listView.onCellClick("td.subject a[data-subject=\"project_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            if (ids.length > 1) {
                mainMenu.openAndResetListFilter("get_project_list", { id: ids.join(",") });
            } else {
                showProjectDetails({
                    projectId: ids[0],
                });
            }
        });

        this.listView.onCellClick("td.subject a[data-subject=\"work_request_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));

            if (ids.length > 1) {
                mainMenu.openAndResetListFilter("get_work_request_list", { id: ids });
            } else {
                openListDetailPopup(getUrl(cgiScript("requestdetail.py"), { incidentid: ids[0] }));
            }
        });

        this.listView.onCellClick("td.subject a[data-subject=\"order_request_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            if (ids.length > 1) {
                mainMenu.openAndResetListFilter("get_order_request_list", { id: ids });
            } else {
                showOrderRequestDetails({
                    orderRequestId: ids[0],
                });
            }
        });

        this.listView.onCellClick("td.subject a[data-subject=\"user_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            if (ids.length > 1) {
                mainMenu.openAndResetListFilter("get_user_list", { user_id: ids });
            } else {
                openListDetailPopup(getUrl(cgiScript("userdetail.py"), { userid: ids[0] }));
            }
        });

        this.listView.onCellClick("td.subject a[data-subject=\"building_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            mainMenu.openAndResetListFilter("get_location_list", { building_id: ids.join(","), select_parents: true });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"area_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            mainMenu.openAndResetListFilter("get_location_list", { area_id: ids.join(","), select_parents: true });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"room_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            mainMenu.openAndResetListFilter("get_location_list", { room_id: ids.join(","), select_parents: true });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"rack_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            mainMenu.openAndResetListFilter("get_location_list", { rack_id: ids.join(","), select_parents: true });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"embryo_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            mainMenu.openAndResetListFilter("get_embryo_list", { embryo_id: ids });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"sperm_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            mainMenu.openAndResetListFilter("get_sperm_list", { sperm_id: ids });
        });

        this.listView.onCellClick("td.subject a[data-subject=\"tank_id\"]", (args) => {
            const ids = args.element.dataset.identifier.split(",").map((id: string) => parseInt(id, 10));
            mainMenu.openAndResetListFilter("get_tank_list", { tank_id: ids });
        });

        // Document actions

        this.listView.onCellClick("td.delete .icon_delete", (args) => {
            DocumentsService.deleteDocument({ documentId: parseInt(args.rowId, 10) })
                .then(() => {
                    this.listView.reload();
                })
                .catch(() => {
                    notifications.showModal(getTranslation("Document could not be deleted"), {
                        type: "error",
                        onClose: () => this.listView.reload(),
                    });
                });
        });

    }
}

export const initDocumentList = (args: ListViewArguments): void => {
    new List(document.querySelector("div.listview"), args);
};
