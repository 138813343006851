import dialogPolyfill from "dialog-polyfill";

import {
    getSessionItem,
    setSessionItem,
} from "../browserStorage";
import { getTranslation } from "../localize";

// import required for Safari < 15.4 (released 2022-03, required until 2024-03)

import "./notifications.scss";

type NotificationType = "success" | "error" | "information";

interface Toast {
    text: string;
    type: NotificationType;
    sticky: boolean;
}

/** Helper to show toast style notifications. **/
class Toaster {
    public readonly container: HTMLUListElement = document.createElement("ul");
    private toastStack: Toast[] = [];
    private animationDuration = 300;

    constructor() {
        this.container.classList.add("notification-toaster");
        getSessionItem<Toast[]>("toaster", []).then((toastStack) => {
            for (const toast of toastStack) {
                this.toastStack.push(toast);
                this.renderNotification(toast, false);
            }
        });
        window.addEventListener("load", () => document.body.append(this.container), { once: true });
    }

    /** Shows a notification with the given text and type. */
    public addNotification = (text: string, type: NotificationType = "information") => {
        const toast: Toast = { text, type, sticky: false };
        this.toastStack.push(toast);
        setSessionItem("toaster", this.toastStack).then(() => this.renderNotification(toast));
    };

    /** Shows a sticky notification with the given text and type. **/
    public addStickyNotification = (text: string, type: NotificationType = "information") => {
        const toast: Toast = { text, type, sticky: true };
        this.toastStack.push(toast);
        setSessionItem("toaster", this.toastStack).then(() => this.renderNotification(toast));
    };

    /** Clear all notifications. **/
    public clearNotifications = (keepSticky = true) => {
        if (keepSticky) {
            this.toastStack = this.toastStack.filter((toast) => toast.sticky);
        } else {
            this.toastStack = [];
        }
        setSessionItem("toaster", this.toastStack).then(() => {
            if (keepSticky) {
                Array.from(this.container.childNodes).forEach((li: HTMLLIElement, index) => {
                    setTimeout(() => {
                        if (li.dataset.toastSticky !== "true") {
                            li.classList.add("close-toast-animation");
                            setTimeout(() => li.remove(), this.animationDuration);
                        }
                    }, (index * this.animationDuration) / 2);
                });
            } else {
                Array.from(this.container.childNodes).forEach((li: HTMLLIElement, index) => {
                    setTimeout(() => {
                        li.classList.add("close-toast-animation");
                        setTimeout(() => li.remove(), this.animationDuration);
                    }, (index * this.animationDuration) / 2);
                });
            }
        });
    };

    private renderNotification = (toast: Toast, animate = true) => {
        const li = document.createElement("li");
        li.dataset.toastType = toast.type;
        li.dataset.toastSticky = toast.sticky.toString();
        const message = document.createElement("p");
        message.innerText = toast.text;
        li.append(message);
        li.addEventListener("click", () => {
            const index = this.toastStack.findIndex((t) => t == toast);
            if (index !== -1) {
                this.toastStack.splice(index, 1);
                setSessionItem("toaster", this.toastStack).then(() => {
                    li.classList.add("close-toast-animation");
                    setTimeout(() => li.remove(), this.animationDuration);
                });
            }
        });
        if (animate) {
            li.classList.add("open-toast-animation");
            setTimeout(() => li.classList.remove("open-toast-animation"), this.animationDuration);
        }
        this.container.append(li);
    };
}

interface ModalOptions {
    type?: NotificationType;
    okText?: string;
    onClose?: () => void;
}

interface ConfirmOptions {
    type?: NotificationType;
    onCancel?: () => void;
    okText?: string;
    cancelText?: string;
}

export class Notifications {
    private toaster: Toaster = new Toaster();

    /**
     * Register a click handler to close all non-sticky notifications when clicking
     * somewhere in the window. Unfortunately, this is required to be called once
     * for all windows - also in frames.
     *
     * @param w Window element to act on.
     */
    registerNotificationMessageCleaner(w: Window): void {
        w.addEventListener("click", (event) => {
            if (!this.toaster.container.contains(event.target as Node)) {
                this.toaster.clearNotifications();
            }
        }, true);
    }

    /**
     * Show a bottom left notification, which disappears on next click.
     *
     * This is a replacement for the old "notificationMessage".
     *
     * @param text The message to show.
     * @param type Type of the notification (defined in NotificationType).
     */
    public showNotification = (text: string, type: NotificationType = "information") => {
        this.toaster.addNotification(text, type);
    };

    /**
     * Show a bottom left notification, which requires a click on a little cross to close.
     *
     * @param text The message to show.
     * @param type Type of the notification (defined in NotificationType).
     */
    public showStickyNotification = (text: string, type: NotificationType = "information") => {
        this.toaster.addStickyNotification(text, type);
    };

    /**
     * Show a centered modal popup with a predefined closing Ok button.
     *
     * This is a replacement for the old "alertPopup".
     *
     * @param text The message to show.
     * @param type Type class of the notification (defined in NotificationType).
     * @param onClose Callback, called once the popup is closed.
     * @param okText Caption to show on the Ok button.
     */
    public showModal = (
        text: string,
        {
            type = "information",
            okText = getTranslation("OK"),
            onClose = () => {
                /**/
            },
        }: ModalOptions = {},
    ) => {
        // setup dialog
        const dialog = document.createElement("dialog");
        dialogPolyfill.registerDialog(dialog);
        dialog.addEventListener("click", (event) => event.stopPropagation());
        dialog.classList.add("alert-dialog");
        dialog.dataset.alertType = type;
        dialog.addEventListener("close", () => {
            onClose();
            dialog.remove();
        });

        // add message
        const message = document.createElement("p");
        message.innerText = text;
        dialog.append(message);

        // add button
        const buttonRow = document.createElement("div");
        const okButton = document.createElement("button");
        okButton.innerText = okText;
        okButton.classList.add("confirm");
        okButton.autofocus = true;
        okButton.addEventListener("click", () => dialog.close());
        buttonRow.append(okButton);
        dialog.append(buttonRow);

        // show dialog
        document.body.append(dialog);
        dialog.showModal();
        return dialog;
    };

    /**
     * Show a centered modal popup with a Cancel and an Ok button.
     *
     * This is a replacement for the old "confirmPopup".
     *
     * @param text The message to show.
     * @param type Type class of the notification (defined in the stylesheet).
     * @param onOk Callback, called in case the user clicks Ok button.
     * @param onCancel Callback, called in case the user clicks Cancel button.
     * @param okText Caption to show on the Ok button.
     * @param cancelText Caption to show on the Cancel button.
     */
    public showConfirm = (
        text: string,
        onOk = () => {
            /**/
        },
        {
            type = "information",
            onCancel = () => {
                /**/
            },
            okText = getTranslation("OK"),
            cancelText = getTranslation("Cancel"),
        }: ConfirmOptions = {},
    ) => {
        // setup dialog
        const dialog = document.createElement("dialog");
        dialogPolyfill.registerDialog(dialog);
        dialog.addEventListener("click", (event) => event.stopPropagation());
        dialog.classList.add("alert-dialog");
        dialog.dataset.alertType = type;
        dialog.addEventListener("close", () => {
            dialog.remove();
            onCancel();
        });

        // add message
        const message = document.createElement("p");
        message.innerText = text;
        dialog.append(message);

        // add buttons
        const buttonRow = document.createElement("div");
        const okButton = document.createElement("button");
        okButton.innerText = okText;
        okButton.classList.add("confirm");
        okButton.autofocus = true;
        okButton.addEventListener("click", () => {
            dialog.remove();
            onOk();
        });
        const cancelButton = document.createElement("button");
        cancelButton.innerText = cancelText;
        cancelButton.addEventListener("click", () => {
            dialog.remove();
            onCancel();
        });
        buttonRow.append(cancelButton, okButton);
        dialog.append(buttonRow);

        // show dialog
        document.body.append(dialog);
        dialog.showModal();
        return dialog;
    };
}
