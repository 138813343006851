/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JavaScriptErrorDetails } from '../models/JavaScriptErrorDetails';
import type { Version } from '../models/Version';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SystemService {

    /**
     * Get Version
     * Get the current PyRAT version.
     * @returns Version Successful Response
     * @throws ApiError
     */
    public static getVersion(): CancelablePromise<Version> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/system/version',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Exception
     * Write an exception to the server.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static writeException({
        requestBody,
    }: {
        requestBody: JavaScriptErrorDetails,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/system/exception',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
