/**
 * Validating PyRAT eartags and prefixes and cagenumbers.
 *
 * Requires getTranslation.
 */
import { getTranslation } from "./localize";
import { session } from "./pyratSession";

export class EartagUtils {
    readonly prefixEartagSize: number;
    readonly suffixEartagSize: number;
    readonly cageSuffixSite: number;

    constructor() {
        this.prefixEartagSize = session.pyratConf.PREFEARTAGSIZE;
        this.suffixEartagSize = session.pyratConf.EARTAGSIZE - session.pyratConf.PREFEARTAGSIZE;
        this.cageSuffixSite = session.pyratConf.CAGESUFSIZE;
    }

    /**
     * Check and return false if prefixString is a valid prefix.
     * Return an errormessage otherwise.
     *
     * (Javascript implementation of lib.eartag.check_prefix).
     */

    public checkPrefix = (prefixString: string) => {
        if (prefixString && /^[A-Z0-9]*$/.test(prefixString) && prefixString.length === this.prefixEartagSize) {
            return false;
        }
        return getTranslation("Invalid prefix. Prefixes must be alphanumeric and %s characters in length.").replace(
            "%s",
            String(this.prefixEartagSize),
        );
    };

    /**
     * Check and return false if suffixString is a valid suffix.
     * Return an errormessage otherwise.
     *
     * (Javascript implementation of lib.eartag.check_suffix).
     */
    public checkSuffix = (suffixString: string) => {
        if (!/^([A-Z]*)([0-9]*)$/.test(suffixString)) {
            return getTranslation("The suffix format is incorrect");
        }
        if (suffixString.length !== this.suffixEartagSize) {
            return getTranslation("Invalid suffix, it should be %s characters.").replace(
                "%s",
                String(this.suffixEartagSize),
            );
        }
        return false;
    };

    /**
     * Check and return false if eartagString represents a valid eartag.
     * Return an errormessage otherwise.
     *
     * (Javascript implementation of lib.eartag.check_eartag).
     */
    public checkEartag = (eartagString: string) => {
        const s = (eartagString || "").split("-");
        if (s.length !== 2) {
            return getTranslation("ID must be of the form: PREFIX-SUFFIX");
        }
        const prefix = s[0];
        const suffix = s[1];
        return this.checkPrefix(prefix) || this.checkSuffix(suffix);
    };

    /**
     * Check and return false if suffixString is a valid cage suffix.
     * Return an errormessage otherwise.
     *
     * (Javascript implementation of lib.cage.check_cage_suffix().
     */
    public checkCageSuffix = (suffixString: string) => {
        if (!/^([0-9]*)$/.test(suffixString)) {
            return getTranslation("The cage suffix must be a number");
        }
        if (suffixString.length !== this.cageSuffixSite) {
            return (
                getTranslation("The cage suffix was the wrong size. It should be") +
                " " +
                this.cageSuffixSite +
                " " +
                getTranslation("characters in length.")
            );
        }
        return false;
    };

    /**
     * Check and return false if cageNumberString is a valid cage number.
     * Return an errormessage otherwise.
     *
     * cageNumberString must not contain the cage type prefix letter.
     */
    public checkCageNumber = (cageNumberString: string) => {
        const s = (cageNumberString || "").split("-");
        if (s.length !== 2) {
            return getTranslation("Cage ID must be of the form: PREFIX-SUFFIX");
        }
        const prefix = s[0];
        const suffix = s[1];
        return this.checkPrefix(prefix) || this.checkCageSuffix(suffix);
    };
}
