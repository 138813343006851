/* Generation of matrix codes (barcodes, qr-codes, …)
 *
 * These are custom elements and functions to render so called matrix code. Most common
 * version of it is barcode and qr-code.
 */


/** Custom element to render a barcode
 *
 * Example:
 *     <ce-barcode
 *             height="64"
 *             style="margin: 10px;"
 *             value="38"></ce-barcode>
 *
 * Setting the width is also supported, but it is not recommended because barcode width
 * changes with the length of it's value.
 *
 * See https://github.com/lindell/JsBarcode for further options.
 */
customElements.get("ce-barcode") || customElements.define("ce-barcode", (

    class extends HTMLElement {

        static readonly observedAttributes = ["value", "width", "height"];

        constructor() {
            super();
        }

        public connectedCallback(): void {
            this.draw();
        }

        public attributeChangedCallback(): void {
            if (document.readyState === "complete") {
                this.draw();
            }
        }

        private draw(): void {
            this.innerHTML = "";
            const value = this.getAttribute("value");
            if (value) {
                const img = document.createElement("img");
                if (this.getAttribute("width")) {
                    img.setAttribute("width", this.getAttribute("width"));
                }
                if (this.getAttribute("height")) {
                    img.setAttribute("height", this.getAttribute("height"));
                }
                import("jsbarcode").then((JsBarcode) => {
                    // @ts-expect-error: JsBarcode is not typed properly
                    JsBarcode.default(img, value, {
                        margin: 0,
                        displayValue: false,
                    });
                });
                this.appendChild(img);
            }
        }
    }

));

/** Custom element to render a barcode
 *
 * Example:
 *     <ce-qr-code
 *             width="64"
 *             height="64"
 *             style="margin: 10px;"
 *             value="38"></ce-qr-code>
 *
 * It is recommended to set width and height to the same value (to render a square qr code).
 *
 * See https://github.com/soldair/node-qrcode for further options.
 */

customElements.get("ce-qr-code") || customElements.define("ce-qr-code", (

    class extends HTMLElement {

        static readonly observedAttributes = ["value", "width", "height"];

        constructor() {
            super();
        }

        public connectedCallback(): void {
            this.draw();
        }

        public attributeChangedCallback(): void {
            if (document.readyState === "complete") {
                this.draw();
            }
        }

        private draw(): void {
            this.innerHTML = "";
            const value = this.getAttribute("value");
            if (value) {
                const img = document.createElement("img");
                if (this.getAttribute("width")) {
                    img.setAttribute("width", this.getAttribute("width"));
                }
                if (this.getAttribute("height")) {
                    img.setAttribute("height", this.getAttribute("height"));
                }
                import("qrcode").then((QrCode) => {
                    QrCode.toDataURL(value, {
                        margin: 0,
                    }).then((data) => {
                        img.src = data;
                    });
                });
                this.appendChild(img);
            }
        }

    }

));
