import {
    Observable,
    observable,
    ObservableArray,
    observableArray,
    PureComputed,
    pureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    LicenseSignOffStep,
    LicenseSignOffUser,
    LicensesService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";

import template from "./licenseSignOff.html";

import "./licenseSignOff.scss";


interface Params {
    licenseId: number;
    reloadCallback?: () => void;
}

class SignOffStepModel {
    public licenseSignOff: LicenseSignOffViewModel;
    public isLastEmpty: PureComputed<boolean>;
    public isSignedOff: PureComputed<boolean>;
    public stepNumber: PureComputed<number>;
    public stepUsers: ObservableArray<LicenseSignOffUser>;
    public users: PureComputed<LicenseSignOffUser[]>;
    public userId: Observable<number>;
    public currentUserData: PureComputed<LicenseSignOffUser>;

    constructor(licenseSignOff: LicenseSignOffViewModel, stepUsers?: LicenseSignOffStep[]) {
        this.licenseSignOff = licenseSignOff;
        this.isLastEmpty = pureComputed(() => {
            // whether this is the last, empty step that allows to add a new step
            return !this.stepUsers().length && this === _.last(licenseSignOff.signOffSteps());
        });

        this.stepNumber = pureComputed(() => {
            const idx = _.indexOf(licenseSignOff.signOffSteps(), this);

            if (idx >= 0 && !this.isLastEmpty()) {
                return idx + 1;
            }
        });

        this.users = pureComputed(() => {
            // users available in select drop-down
            return _.filter(licenseSignOff.signOffUsers(), (user) => {
                return _.every(this.stepUsers(), (stepUser) => {
                    return stepUser.user_id !== user.user_id;
                });
            });
        });
        this.userId = observable(); // user ID selected in drop-down
        this.stepUsers = observableArray(stepUsers);

        this.isSignedOff = pureComputed(() => {
            const idx = _.indexOf(licenseSignOff.signOffSteps(), this);

            return (
                idx >= 0 &&
                _.every(licenseSignOff.signOffSteps(), (otherStep, otherIdx) => {
                    // all users in current and previous steps must have signed off
                    return (
                        otherIdx > idx ||
                        _.every(otherStep.stepUsers(), (user) => {
                            return Boolean(user.sign_off_date);
                        })
                    );
                })
            );
        });
        this.currentUserData = pureComputed(() => {
            // if currentUser can sign off this step, return his user/sign off data
            return _.find(this.stepUsers(), (user) => {
                return user.user_id && user.user_id === licenseSignOff.currentUserId();
            });
        });
    }
    public addUser = () => {
        const selectedUser = _.find(this.users(), (user) => {
            return user.user_id === this.userId();
        });

        if (selectedUser) {
            this.stepUsers.splice(0, 0, selectedUser);

            if (_.last(this.licenseSignOff.signOffSteps()) === this) {
                // add another empty step when the last step contains at least one user
                this.licenseSignOff.signOffSteps.push(new SignOffStepModel(this.licenseSignOff));
            }
        }
    };
    public removeUser = (user: LicenseSignOffStep) => {
        this.stepUsers.remove(user);

        while (
            this.licenseSignOff.signOffSteps().length > 1 &&
            !_.last(_.dropRight(this.licenseSignOff.signOffSteps())).stepUsers().length
        ) {
            this.licenseSignOff.signOffSteps.remove(_.last(this.licenseSignOff.signOffSteps()));
        }
    };
}
class LicenseSignOffViewModel {
    public licenseId: number;
    public dialog: HtmlDialog;
    public reloadRequired: Observable<boolean>;
    public inProgress: Observable<boolean>;
    public allowEdit: Observable<boolean>;
    public currentUserId: Observable<number>;
    public globalStatus: Observable<string>;
    public signOffUsers: ObservableArray<LicenseSignOffUser>;
    public signOffSteps: ObservableArray<SignOffStepModel>;
    public currentStep: PureComputed<SignOffStepModel | undefined>;
    public proceedSignOff: Observable<boolean>;
    public allowProceedSignOff: PureComputed<boolean>;
    public ownSignOffDate: PureComputed<string>;

    constructor(dialog: HtmlDialog, { licenseId, reloadCallback }: Params) {
        this.dialog = dialog;
        this.licenseId = licenseId;

        this.reloadRequired = observable(false);
        dialog.addOnClose(() => {
            if (this.reloadRequired() && typeof reloadCallback === "function") {
                reloadCallback();
            }
        });

        this.inProgress = observable(true);
        this.allowEdit = observable();
        this.currentUserId = observable();
        this.globalStatus = observable();
        this.signOffUsers = observableArray();
        this.signOffSteps = observableArray();
        LicensesService.getLicenseSignOff({ licenseId: licenseId || 0 })
            .then((data) => {
                this.dialog.setTitle(data.popup_title);
                this.allowEdit(data.current_user_allow_edit_sign_off || false);
                this.currentUserId(data.current_user_id || undefined);
                if ("global_status" in data) {
                    this.globalStatus(data.global_status);
                }
                this.signOffUsers(data.sign_off_users || undefined);

                if (data.sign_off_steps) {
                    this.signOffSteps(
                        data.sign_off_steps.map((stepUsers) => {
                            return new SignOffStepModel(this, stepUsers);
                        }),
                    );
                } else {
                    this.signOffSteps(undefined);
                }
                if (this.allowEdit()) {
                    // add empty step for start
                    this.signOffSteps.push(new SignOffStepModel(this));
                }
            })
            .catch((reason) => {
                // error, probably the user cannot view the license,
                // let page be reloaded in order to get the license out of the list
                this.reloadRequired(true);
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    writeException(reason);
                    throw reason;
                }
            })
            .finally(() => {
                this.inProgress(false);
            });

        this.proceedSignOff = observable(false);
        this.currentStep = pureComputed(() => {
            if (this.globalStatus() === "submitted") {
                return this.signOffSteps().find((step) => {
                    return !step.isSignedOff() && step.stepUsers().length;
                });
            }
        });
        this.allowProceedSignOff = pureComputed(() => {
            return (
                this.currentStep() &&
                this.currentStep().currentUserData() &&
                !this.currentStep().currentUserData().sign_off_date
            );
        });
        this.ownSignOffDate = pureComputed(() => {
            return (
                (this.currentStep() &&
                    this.currentStep().currentUserData() &&
                    this.currentStep().currentUserData().sign_off_date) ||
                undefined
            );
        });
    }
    public closePopup = () => {
        this.dialog.close();
    };

    public saveSignOff = () => {
        this.inProgress(true);

        LicensesService.saveLicenseSignOff({
            licenseId: this.licenseId || 0, // setting default sign off when ID is not specified
            requestBody: {
                sign_off_steps: _.map(this.signOffSteps(), (step) => {
                    return _.map(step.stepUsers(), (user) => {
                        return user.user_id;
                    });
                }),
                proceed_sign_off: !!(this.allowProceedSignOff() && this.proceedSignOff()),
            },
        })
            .then(() => {
                notifications.showNotification(getTranslation("Changes saved"), "success");
                this.dialog.close();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
                    writeException(reason);
                    throw reason;
                }
            })
            .finally(() => {
                this.reloadRequired(true);
                this.inProgress(false);
            });
    };
}
export const showLicenseSignOff = htmlDialogStarter(LicenseSignOffViewModel, template, {
    name: "LicenseSignOff",
    width: 360,
    position: { inset: { top: 20, right: 20 } },
    closeOthers: true,
});
