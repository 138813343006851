import { setTag } from "@sentry/browser";

interface SessionValues {
    pyratId: string;
    sessionId: string;
    requestId: string;
    correlationId: string;
    pyratVersion: {
        version: string;
        longVersion: string;
        gitCommit: string;
        gitDescription: string;
    };
    pyratConf: { [key: string]: any };
    localesConf: {
        decimalSymbol: string;
        timeShorthand: string;
        weekendDays: number[];
    };
    userPermissions: { [key: string]: boolean };
    userId: number;
    userName: string;

    aliasId: number;
}

export let session: SessionValues = window.top?.pyratFrontend?.session;

export const setSessionValues = (sessionConstants: SessionValues) => {
    if (window !== window.top) {
        throw "not int top context";
    }
    else if (window.top.pyratFrontend.session) {
        throw "session is already set";
    }
    session = sessionConstants;
    window.top.pyratFrontend.session = sessionConstants;
    setTag("release", sessionConstants.pyratVersion.gitDescription);
};
