/**
 * Show a popup to move or sacrifice animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param withSetSex: Whether to include the "set_sex" action or not.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Computed,
    Observable,
    ObservableArray,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import {
    LicenseClassificationOption,
    LicensesService,
} from "../backend/v1";
import {
    LocationItem,
    PreselectLocationItem,
} from "../knockout/components/locationPicker/locationPicker";
import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import {
    getTranslation,
    TranslationTemplates,
} from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    AjaxResponse,
    cgiScript,
    ConfirmableAjaxResponse,
    getFormData,
    getUrl,
} from "../lib/utils";

import template from "./setSexMoveSacrifice.html";


interface Params {
    animalId?: number;
    pupId?: number;
    withSetSex?: boolean;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface SetSexSeed {
    sex: "m" | "f" | "?";
}

interface ExistingCageSeed {
    is_mother_with_live_pups: boolean;
    sex: "m" | "f" | "?";
    select_rack_id: number;
    select_cage_id: number;
}

interface NewCageSeed {
    is_mother_with_live_pups: boolean;
    sex: "m" | "f" | "?";
    select_rack_id: number;
    prefix_list: {
        prefix: string;
        owner_id: number;
        owner_fullname: string;
        selected: boolean;
    }[];
    cage_categories: [];
    cage_types: [];
}

interface SacrificeSeed {
    is_mother_with_live_pups: boolean;
    is_alive: boolean;
    species_id: number;
    strain_id: number;
    current_severity_level_id: number;
    current_licence_id: number;
    current_classification_id: number;
    current_license_type_id: number;
    keep_assigned_licence_option: boolean;
    sacrifice_reasons: [];
    sacrifice_methods: [];
    severity_levels: [];
    licenses: [];
}

interface Seed {
    set_sex: SetSexSeed;
    move_to_existing_cage: ExistingCageSeed;
    move_to_new_cage: NewCageSeed;
    sacrifice: SacrificeSeed;
}

interface Tab {
    key: string;
    label: string;
    appendSetSexAction?: ko.Subscribable<boolean>;
    visible: ko.Subscribable<boolean>;
}


class SetSexTab {
    public dialog: KnockoutPopup;

    // params
    public animalId: number;
    public pupId: number;
    public closeCallback: () => void;
    public reloadCallback: () => void;

    // state
    public seed: Observable<SacrificeSeed>;
    public sex: Observable<string>;

    public canSubmit: PureComputed<boolean>;
    public submitInProgress: Observable<boolean>;
    public errors: ko.ObservableArray<string>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.sex = ko.observable();

        this.canSubmit = ko.pureComputed(() => {
            return !(
                this.submitInProgress());
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
    }

    public load = (seed: SetSexSeed) => {
        this.sex(seed.sex);
    };


    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            action: "set_sex",
            new_sex: this.sex() || "",
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        fetch(cgiScript("set_sex_move_sacrifice.py"), { method: "POST", body: this.getFormData() })
            .then(response => response.json()).then((response: ConfirmableAjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error",
                );
            });
    };
}

class ExistingCageTab {
    public dialog: KnockoutPopup;

    // params
    public animalId: number;
    public pupId: number;
    public closeCallback: () => void;
    public reloadCallback: () => void;

    // state
    public sex: Observable<string>;
    public isMotherWithPups: Observable<boolean>;
    public moveMotherWithPups: Observable<boolean>;

    public preselectLocation: PreselectLocationItem;
    public selectedLocation: Observable<LocationItem>;
    public selectedLocationTitle: Observable<string>;
    public selectedLocationText: ko.Computed<string>;
    public preselectCageId: number;
    public selectedCage: Observable<{ cagenumber: string }>;
    public selectedCageTitle: Observable<string>;
    public unselectCageTrigger: Observable<any>;
    public cleanupTrigger: Observable<any>;
    public cagenumber: CheckExtended<Observable<string>>;

    public confirmSanitaryStatus: Observable<boolean>;
    public confirmMoveResetPlugDate: Observable<boolean>;
    public confirmMoveResetPregnancy: Observable<boolean>;
    public canSubmit: PureComputed<boolean>;
    public submitInProgress: Observable<boolean>;
    public errors: ko.ObservableArray<string>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.sex = ko.observable();
        this.isMotherWithPups = ko.observable(false);
        this.moveMotherWithPups = ko.observable(true);

        this.selectedLocation = ko.observable();
        this.selectedLocationTitle = ko.observable();
        this.selectedLocationText = ko.computed(() => {
            if (this.selectedLocation()) {
                return this.selectedLocation().full_name;
            }
            return "";
        });
        this.selectedCage = ko.observable();
        this.selectedCageTitle = ko.observable();
        this.preselectLocation = null;
        this.preselectCageId = null;
        this.cagenumber = ko.observable().extend({
            invalid: function(v) {
                return !v;
            },
        });
        this.unselectCageTrigger = ko.observable();
        this.cleanupTrigger = ko.observable();


        this.cagenumber.subscribe((v) => {
            if (this.selectedCage()
                    && this.selectedCage().cagenumber !== v) {
                this.unselectCageTrigger.valueHasMutated();
            }
        });
        this.selectedCage.subscribe((v) => {
            if (v) {
                this.cagenumber(v.cagenumber);
            }
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(
                this.submitInProgress() ||
                this.cagenumber.isInvalid());
        });

        this.confirmSanitaryStatus = ko.observable(false);
        this.confirmMoveResetPlugDate = ko.observable(false);
        this.confirmMoveResetPregnancy = ko.observable(false);
        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
    }

    public load = (seed: ExistingCageSeed) => {
        this.sex(seed.sex);
        this.isMotherWithPups(seed.is_mother_with_live_pups);
        if (seed.select_rack_id) {
            this.preselectCageId = seed.select_cage_id;
        }
        if (seed.select_rack_id) {
            this.preselectLocation = { type: "rack", id: seed.select_rack_id };
        }
    };


    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            action: "move_to_existing_cage",
            new_sex: this.sex() || "",
            cage_number: this.cagenumber() || "",
            move_mother_with_pups: this.moveMotherWithPups() ? 1 : 0,
            confirm_sanitary_status: this.confirmSanitaryStatus() ? 1 : 0,
            confirm_move_reset_plug_date: this.confirmMoveResetPlugDate() ? 1 : 0,
            confirm_move_reset_pregnancy: this.confirmMoveResetPregnancy() ? 1 : 0,
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        fetch(cgiScript("set_sex_move_sacrifice.py"), { method: "POST", body: this.getFormData() })
            .then(response => response.json()).then((response: ConfirmableAjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else if (response.confirm && response.confirm === "confirm_sanitary_status") {
                    notifications.showConfirm(
                        response.message,
                        () => {
                            this.confirmSanitaryStatus(true);
                            // post again, this time with confirmed sanitary status
                            this.submit();
                        },
                        {
                            onCancel: () => {
                                this.submitInProgress(false);
                            },
                        },
                    );
                } else if (response.confirm && response.confirm === "confirm_move_reset_plug_date") {
                    notifications.showConfirm(
                        response.message,
                        () => {
                            this.confirmMoveResetPlugDate(true);
                            // post again, this time with confirmed reset of plug date
                            this.submit();
                        },
                        {
                            onCancel: () => {
                                this.submitInProgress(false);
                            },
                        },
                    );
                } else if (response.confirm && response.confirm === "confirm_move_reset_pregnancy") {
                    notifications.showConfirm(
                        response.message,
                        () => {
                            this.confirmMoveResetPregnancy(true);
                            // post again, this time with confirmed reset of pregnancy
                            this.submit();
                        },
                        {
                            onCancel: () => {
                                this.submitInProgress(false);
                            },
                        },
                    );
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error",
                );
            });
    };
}

class NewCageTab {
    public dialog: KnockoutPopup;

    // params
    public animalId: number;
    public pupId: number;
    public closeCallback: () => void;
    public reloadCallback: () => void;

    // state
    public seed: Observable<NewCageSeed>;
    public sex: Observable<string>;
    public isMotherWithPups: Observable<boolean>;
    public moveMotherWithPups: Observable<boolean>;
    public preselectLocation: PreselectLocationItem;
    public selectedLocation: CheckExtended<Observable<LocationItem>>;
    public cleanLocationsTrigger: Observable<any>;
    public rackId: Computed<number>;
    public prefix: CheckExtended<Observable<string>>;
    public cageType: Observable<string>;
    public cageCategoryId: CheckExtended<Observable<number>>;
    public cagePosition: Observable<string>;
    public confirmSanitaryStatus: Observable<boolean>;
    public canSubmit: PureComputed<boolean>;
    public submitInProgress: Observable<boolean>;
    public errors: ko.ObservableArray<string>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.seed = ko.observable();
        this.sex = ko.observable("");
        this.isMotherWithPups = ko.observable(false);
        this.moveMotherWithPups = ko.observable(true);

        this.selectedLocation = ko.observable();
        this.preselectLocation = null;
        this.cleanLocationsTrigger = ko.observable();

        this.selectedLocation.extend({
            invalid: (v) => {
                return !!(session.pyratConf.MANDATORY_LOCATION && !v);
            },
        });

        this.rackId = ko.computed(() => {
            return this.selectedLocation() ? this.selectedLocation().rack_id : undefined;
        });

        this.prefix = ko.observable().extend({ invalid: (value) => !value });
        this.cageType = ko.observable();
        this.cageCategoryId = ko.observable().extend({
            invalid: function(v) {
                return !v;
            },
        });

        this.cagePosition = ko.observable("");

        this.canSubmit = ko.pureComputed(() => {
            return !(
                this.submitInProgress() ||
                this.selectedLocation.isInvalid() ||
                this.cageCategoryId.isInvalid() ||
                this.prefix.isInvalid()
            );
        });

        this.confirmSanitaryStatus = ko.observable(false);
        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
    }

    public load = (seed: NewCageSeed) => {
        this.sex(seed.sex);
        this.isMotherWithPups(seed.is_mother_with_live_pups);
        if (seed.select_rack_id) {
            this.preselectLocation = { type: "rack", id: seed.select_rack_id };
        }
        this.seed(seed);

        // preselect the first item in the prefix list which is marked as 'selected'
        seed.prefix_list.every((item) => {
            if (item.selected === true) {
                this.prefix(item.prefix + "|" + item.owner_id);
                return false;
            }
            return true;
        });
    };


    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            action: "move_to_new_cage",
            new_sex: this.sex() || "",
            rack_id: this.rackId() || "",
            prefix: this.prefix() || "",
            cage_type: this.cageType() || "",
            cage_category_id: this.cageCategoryId() || "",
            cage_position: this.cagePosition() || "",
            move_mother_with_pups: this.moveMotherWithPups() ? 1 : 0,
            confirm_sanitary_status: this.confirmSanitaryStatus() ? 1 : 0,
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        fetch(cgiScript("set_sex_move_sacrifice.py"), { method: "POST", body: this.getFormData() })
            .then(response => response.json()).then((response: ConfirmableAjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else if (response.confirm && response.confirm === "confirm_sanitary_status") {
                    notifications.showConfirm(
                        response.message,
                        () => {
                            this.confirmSanitaryStatus(true);
                            // post again, this time with confirmed sanitary status
                            this.submit();
                        },
                        {
                            onCancel: () => {
                                this.submitInProgress(false);
                            },
                        },
                    );
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error",
                );
            });
    };
}

class SacrificeTab {
    public dialog: KnockoutPopup;

    // params
    public animalId: number;
    public pupId: number;
    public closeCallback: () => void;
    public reloadCallback: () => void;

    // state
    public licenseDenom: string;
    public seed: Observable<SacrificeSeed>;
    public isAlive: Observable<boolean>;
    public isMotherWithPups: Observable<boolean>;
    public sacrificeMotherWithPups: Observable<boolean>;
    public sacrificeReasonId: CheckExtended<Observable<number>>;
    public sacrificeMethodId: CheckExtended<Observable<number>>;

    public speciesId: Observable<number>;
    public strainId: Observable<number>;
    public licenseId: Observable<number>;
    public classificationId: CheckExtended<Observable<number>>;
    public keepAssignedLicense: Observable<boolean>;
    public classifications: FetchBackendExtended<ObservableArray<LicenseClassificationOption>>;
    public replaceCurrentLicense: Observable<boolean>;
    public showLicenseOveruse: Observable<boolean>;
    public confirmLicenseOveruse: Observable<boolean>;
    public severityLevelId: Observable<number>;
    public severityLevelMessage: Observable<string>;
    public severityLevelIsUserSelected: Observable<boolean>;
    public severityLevelIdAutomatic: PureComputed<number>;
    public licenseTypeId: PureComputed<number>;
    public comments: Observable<string>;

    public canSubmit: PureComputed<boolean>;
    public submitInProgress: Observable<boolean>;
    public errors: ko.ObservableArray<string>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.licenseDenom = TranslationTemplates.license({ cap: true, trans: true });
        this.seed = ko.observable();
        this.isAlive = ko.observable();
        this.isMotherWithPups = ko.observable(false);
        this.sacrificeMotherWithPups = ko.observable(true);

        this.sacrificeReasonId = ko.observable().extend({
            invalid: (v) => {
                return !!(session.pyratConf.MANDATORY_SACRIFICE_REASON && !v);

            },
        });
        this.sacrificeMethodId = ko.observable().extend({
            invalid: (v) => {
                return !!(session.pyratConf.MANDATORY_SACRIFICE_METHOD && !v);
            },
        });

        this.speciesId = ko.observable();
        this.strainId = ko.observable();
        this.licenseId = ko.observable();
        this.classificationId = ko.observable().extend({
            invalid: (v) => {
                if (session.pyratConf.MANDATORY_SACRIFICE_LICENSE_FIELDS &&
                    (!this.keepAssignedLicense() && !(this.licenseId() && this.classificationId()))) {
                    return getTranslation("You must set a valid license before sacrificing an animal");
                }
                return !!(!this.keepAssignedLicense() && this.licenseId() && !v);
            },
        });

        this.classifications = ko.observableArray().extend({
            fetchBackend: () => {
                if (this.speciesId() && this.licenseId()) {
                    return LicensesService.getLicenseClassificationOptions({
                        licenseId: this.licenseId(),
                        speciesId: this.speciesId(),
                        strainId: [this.strainId()],
                        addDelimiter: true,
                    });
                }
            },
        });

        this.replaceCurrentLicense = ko.observable(session.pyratConf.LICENSE_ASSIGN_PRESELECT_REPLACE_EXISTING);
        this.showLicenseOveruse = ko.observable(false);
        this.confirmLicenseOveruse = ko.observable(false);

        this.keepAssignedLicense = ko.observable(false);
        this.licenseTypeId = ko.pureComputed(() => {
            if (this.keepAssignedLicense()) {
                return this.seed().current_license_type_id || undefined;
            }
            if (this.licenseId()) {
                const license = _.find(this.seed().licenses || [], { id: this.licenseId() });
                return license.license_type_id;
            }
        });

        this.severityLevelId = ko.observable();
        this.severityLevelMessage = ko.observable("");
        this.severityLevelIsUserSelected = ko.observable(false);
        this.severityLevelIdAutomatic = ko.pureComputed(() => {
            // Automatically set severity level based on sacrifice reason, license type & severity level
            // defined in SACRIFICE_SEVERITY_LEVEL configuration option
            if (
                session.pyratConf.MANDATORY_SACRIFICE_LICENSE_FIELDS &&
                session.pyratConf.SACRIFICE_SEVERITY_LEVEL &&
                this.sacrificeReasonId() &&
                this.licenseTypeId()
            ) {
                const sacrificeSeverityLevels = session.pyratConf.SACRIFICE_SEVERITY_LEVEL instanceof Array ?
                    session.pyratConf.SACRIFICE_SEVERITY_LEVEL :
                    [session.pyratConf.SACRIFICE_SEVERITY_LEVEL];

                const severityLevel = _.find(sacrificeSeverityLevels, _.partial(_.isMatch, _, {
                    sacrifice_reason_id: this.sacrificeReasonId(),
                    license_type_id: this.licenseTypeId(),
                }));

                if (severityLevel) {
                    return severityLevel.severity_level_id;
                }
            }

            // Automatically set classification's severity level by default
            if (
                this.classificationId()
                && !this.keepAssignedLicense()
                && session.pyratConf.SEVERITY_LEVEL_USE_CLASSIFICATION_DEFAULT
            ) {
                return this.classifications().find(({ id }) => id === this.classificationId())?.severity_level_id;
            }

            return this.seed()?.current_severity_level_id || undefined;
        });

        // set the severity level to the automatic value
        // unless the user has already selected a value (see #19554)
        this.severityLevelIdAutomatic.subscribe((v) => {
            if (!this.severityLevelIsUserSelected() && v !== this.severityLevelId()) {
                this.severityLevelId(v);
                this.severityLevelMessage(getTranslation("Severity level was automatically set"));
            }
        });

        // reset warning when the user chooses a value
        this.severityLevelId.subscribe((v) => {
            if (v !== this.severityLevelIdAutomatic()) {
                this.severityLevelMessage("");
                this.severityLevelIsUserSelected(true);
            }
        });

        this.comments = ko.observable();

        this.canSubmit = ko.pureComputed(() => {
            return !(
                this.submitInProgress() ||
                this.sacrificeReasonId.isInvalid() ||
                this.sacrificeMethodId.isInvalid() ||
                this.classificationId.isInvalid());
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
    }

    public load = (seed: SacrificeSeed) => {
        this.isAlive(seed.is_alive);
        this.isMotherWithPups(seed.is_mother_with_live_pups);
        this.speciesId(seed.species_id);
        this.strainId(seed.strain_id);
        this.severityLevelId(seed.current_severity_level_id || undefined);
        this.severityLevelIsUserSelected(false);
        this.seed(seed);
    };

    public styleLicenceOptions = (opt: HTMLOptionElement, item: {id: number; name: string; disabled: boolean}) => {
        if (item && item.id === -1) {
            opt.className = "delimiter";
            opt.disabled = true;
        }
    };

    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            action: "sacrifice",
            comments: this.comments() || "",
            sacrifice_reason_id: this.sacrificeReasonId() || "",
            sacrifice_method_id: this.sacrificeMethodId() || "",
            severity_level_id: this.severityLevelId() || "",
            classification_id: this.classificationId() || "",
            replace_current_license: this.replaceCurrentLicense() ? 1 : 0,
            confirm_licence_overuse: this.confirmLicenseOveruse() ? 1 : 0,
            sacrifice_mother_with_pups: this.sacrificeMotherWithPups() ? 1 : 0,
            keep_assigned_licence: this.keepAssignedLicense() ? 1 : 0,
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    private sendData = () => {
        this.errors([]);
        this.submitInProgress(true);
        this.showLicenseOveruse(false);

        fetch(cgiScript("set_sex_move_sacrifice.py"), { method: "POST", body: this.getFormData() })
            .then(response => response.json())
            .then((response: ConfirmableAjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else if (response.confirm && response.confirm === "confirm_licence_overuse") {
                    notifications.showModal(response.message);
                    this.showLicenseOveruse(true);
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error",
                );
            });
    };

    public submit = () => {
        if (session.pyratConf.MANDATORY_SACRIFICE_LICENSE_FIELDS) {
            notifications.showConfirm(
                getTranslation("Please confirm that you are sacrificing the selected animals"),
                this.sendData,
            );
        } else {
            this.sendData();
        }
    };
}

class PopupViewModel {
    public dialog: KnockoutPopup;

    // params
    public animalId: number;
    public pupId: number;
    public withSetSex: boolean;
    public closeCallback: () => void;
    public reloadCallback: () => void;

    // state
    public tabs: Tab[];
    public selectedTab: ko.Observable<Tab>;

    // tab models
    public setSexTab: SetSexTab;
    public existingCageTab: ExistingCageTab;
    public newCageTab: NewCageTab;
    public sacrificeTab: SacrificeTab;
    public seed: FetchExtended<Observable<AjaxResponse<Seed>>>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.withSetSex = params.withSetSex;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        // tab definitions
        this.setSexTab = new SetSexTab(params, dialog);
        this.existingCageTab = new ExistingCageTab(params, dialog);
        this.newCageTab = new NewCageTab(params, dialog);
        this.sacrificeTab = new SacrificeTab(params, dialog);

        this.tabs = [
            {
                key: "set_sex",
                label: getTranslation("Set sex"),
                visible: ko.pureComputed(() => {
                    return Boolean(this.pupId && this.withSetSex && session.userPermissions.animal_set_sex);
                }),
            }, {
                key: "move_to_existing_cage",
                label: getTranslation("Existing cage"),
                appendSetSexAction: ko.pureComputed(() => {
                    return Boolean(this.animalId && this.withSetSex && session.userPermissions.animal_set_sex);
                }),
                visible: ko.pureComputed(() => {
                    return Boolean(this.animalId && session.userPermissions.animal_set_cage);
                }),
            }, {
                key: "move_to_new_cage",
                label: getTranslation("New cage"),
                appendSetSexAction: ko.pureComputed(() => {
                    return Boolean(this.animalId && this.withSetSex && session.userPermissions.animal_set_sex);
                }),
                visible: ko.pureComputed(() => {
                    return Boolean(this.animalId && session.userPermissions.animal_set_cage &&
                        session.userPermissions.cage_set_location);
                }),
            }, {
                key: "sacrifice",
                label: getTranslation("Sacrifice"),
                visible: ko.pureComputed(() => {
                    return Boolean(!this.withSetSex &&
                        this.sacrificeTab.isAlive() && session.userPermissions.animal_sacrifice);
                }),
            },
        ];

        this.selectedTab = ko.observable();

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.animalId) {
                    return fetch(getUrl(cgiScript("set_sex_move_sacrifice.py"), { animal_id: this.animalId }), { signal });
                } else if (this.pupId) {
                    return fetch(getUrl(cgiScript("set_sex_move_sacrifice.py"), { pup_id: this.pupId }), { signal });
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed.success) {
                // load tabs seed
                this.setSexTab.load(seed.set_sex);
                this.existingCageTab.load(seed.move_to_existing_cage);
                this.newCageTab.load(seed.move_to_new_cage);
                this.sacrificeTab.load(seed.sacrifice);

                // select the first tab which is visible
                const firstTabVisible = _.find(this.tabs, (tab) => { return !!tab.visible(); });
                if (firstTabVisible) {
                    this.selectedTab(firstTabVisible);
                } else {
                    throw new Error("no visible tab found");
                }
            }
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }
}

export const showSetSexMoveSacrifice = dialogStarter(PopupViewModel, template, params => ({
    name: "SetSexMoveSacrifice",
    width: 450,
    anchor: params.eventTarget ? params.eventTarget : { top: 20, left: undefined },
    escalate: false,
    closeOthers: true,
    title: params.title,
}));
