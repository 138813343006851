/**
 * Open a page in the current window, using POST in a virtual form element.
 *
 * @param url - The URL to open.
 * @param data - The Data to send in the POST request.
 */
export const virtualPostRequest = (url: string, data: { [key: string]: string }): void => {
    const form = document.createElement("form");

    for (const name in data) {
        if (Object.prototype.hasOwnProperty.call(data, name)) {
            const input = document.createElement("input");
            input.setAttribute("name", name);
            input.value = data[name];
            form.append(input);
        }
    }

    form.setAttribute("action", url);
    form.setAttribute("method", "post");
    form.style.display = "none";

    // old browsers require the form to be inside the body
    document.querySelector("body").append(form);

    form.submit();
    form.remove();
};
