import {
    fromError,
    StackFrame,
} from "stacktrace-js";
import * as URI from "urijs";

import { SystemService } from "../backend/v1";

import {
    setSessionItem,
    getSessionItem,
} from "./browserStorage";
import { session } from "./pyratSession";


let reportCounter = 0;

export const raiseTestException = () => {
    throw new Error("Test exception");
};

/**
 * Report the error to the server.
 *
 * @param error The error to report.
 */
export const writeException = (error: Error) => {
    // resolve .map files
    fromError(error).then((stack: StackFrame[]) => {
        try {
            if (error.stack.length < 1) {
                // Exceptions without stack are almost impossible
                // to resolve and actually exist only more in Internet Explorer.
                return undefined;
            }
        } catch {
            return undefined;
        }
        const topFrame = stack[0];
        let relativePath = URI(topFrame.fileName);
        if (relativePath.is("absolute")) {
            relativePath = relativePath.relativeTo(window.baseUrl);
        }
        getSessionItem("history").then((history) => {
            SystemService.writeException({
                requestBody: {
                    message: error.message,
                    url: topFrame.fileName,
                    line_number: topFrame.lineNumber,
                    column_number: topFrame.columnNumber,
                    error,
                    stack: stack
                        .join("\n")
                        .replaceAll(window.baseUrl, "")
                        .replaceAll(session?.sessionId, "X"),
                    relative_path: relativePath.path(),
                    sessionid: session?.sessionId,
                    location: location.href,
                    environment: ExceptHook.sampleEnvironment(),
                    history: history,
                    agent: window.navigator.userAgent,
                },
            }).then(() => {
                // pass
            });
        });
    });
};

export class ExceptHook {
    /**
     * Get all possible information from the current browser window.
     *
     * @returns Object, with all possible information.
     */
    public static sampleEnvironment() {
        return {
            // @ts-expect-error: The value of frameInTop is set through the templater.
            frameInTop: Boolean(window.frameInTop),
            frames: ExceptHook.inspectFrames(window.top),
        };
    }

    /**
     * Get the current date time in ISO 8601 format.
     *
     * Try to use the browser function for that, with accurate time zone
     * information, but fall back to a simplified version.
     *
     * @returns The current date and time as string.
     */
    private static getISODateString(): string {
        const date = new Date();

        function padZero(n: number) {
            return n < 10 ? "0" + n : n;
        }

        try {
            return date.toISOString();
        } catch (e) {
            return (
                date.getUTCFullYear() +
                "-" +
                padZero(date.getUTCMonth() + 1) +
                "-" +
                padZero(date.getUTCDate()) +
                "T" +
                padZero(date.getUTCHours()) +
                ":" +
                padZero(date.getUTCMinutes()) +
                ":" +
                padZero(date.getUTCSeconds()) +
                "Z"
            );
        }
    }

    /**
     * Get all attributes of the dom element as object.
     *
     * Example:
     *      > attributesObject($('<td colspan=2>Foo</td>').get(0))
     *      < Object {colspan: "2"}
     *
     * @param element The element to inspect.
     * @returns An object, containing the elements attributes.
     */
    private static attributesObject(element: Element) {
        const obj: { [key: string]: any } = {};
        const attributes = element.attributes;

        for (const index in attributes) {
            if (Object.prototype.hasOwnProperty.call(attributes, index)) {
                const nodeName = attributes[index].nodeName;
                obj[nodeName] = attributes[index].nodeValue;
            }
        }
        return obj;
    }

    /**
     * Split url GET parameters into an object.
     *
     * @param w Window to the parameter from.
     * @returns Found GET parameters.
     */
    private static locationSearchParameters(w: Window) {
        const parametersItems = w.location.search.substr(1).split("&");
        const parameters: { [key: string]: any } = {};

        for (let index = 0; index < parametersItems.length; index++) {
            const tuple = parametersItems[index].split("=");
            const key = decodeURIComponent(tuple[0]);
            const value = decodeURIComponent(tuple.slice(1).join("="));
            if (key.length) {
                parameters[key] = value;
            }
        }

        return parameters;
    }

    /**
     * Get all input/select element information from window.document.
     *
     * @param w Window to the the parameter from.
     * @returns Fount inputs an their content.
     */
    private static formsDetails(w: Window) {
        const inputAttributesByName = function (
            w: Window,
            elements: HTMLCollectionOf<HTMLButtonElement | HTMLInputElement | HTMLSelectElement>,
        ) {
            const forms: { [key: string]: { [key: string]: any }[] } = {};

            function formName(e: HTMLButtonElement | HTMLInputElement | HTMLSelectElement) {
                if (e && e.form) {
                    return e.form.getAttribute("action") + " (" + e.form.getAttribute("name") + ")";
                }
            }

            function selectedOption(e: HTMLSelectElement) {
                return e.options[e.selectedIndex].value || e.options[e.selectedIndex].text;
            }

            for (let index = 0; index < elements.length; index++) {
                const e = elements[index];
                const name = formName(e);
                if (!forms[name]) {
                    forms[name] = [];
                }
                forms[name].push({
                    node: e.nodeName,
                    attributes: ExceptHook.attributesObject(e),
                    selected: e instanceof HTMLSelectElement ? selectedOption(e) : undefined,
                });
            }

            return forms;
        };

        return Object.assign(
            {},
            inputAttributesByName(w, w.document.getElementsByTagName("input")),
            inputAttributesByName(w, w.document.getElementsByTagName("select")),
            inputAttributesByName(w, w.document.getElementsByTagName("button")),
        );
    }

    /**
     * Get all possible frame details from window (including sub frames).
     *
     * @param w Window to the parameter from.
     * @returns Found frames and their details.
     */
    private static inspectFrames(w: Window) {
        const frameList: { [key: number]: any } = {};

        const frameDetails = (w: Window) => ({
            name: w.name,
            href: w.location && w.location.href,
            parameters: w.location && ExceptHook.locationSearchParameters(w),
            forms: w.document && ExceptHook.formsDetails(w),
            frames: w.frames && w.frames.length ? ExceptHook.inspectFrames(w) : [],
        });

        for (let i = 0; i < w.frames.length; i++) {
            if (w.frames[i].location) {
                if (
                    !w.frames[i].location.pathname.endsWith("/static/empty.html") &&
                    !(w.frames[i].location.href === "about:blank")
                ) {
                    try {
                        frameList[i] = frameDetails(w.frames[i]);
                    } catch (e) {
                        // pass
                    }
                }
            }
        }
        return frameList;
    }

    /**
     * Event handler to write user interactions to the sessionStorage
     * @param event Default Javascript event object.
     */
    private static eventHandler(event: MouseEvent | KeyboardEvent) {
        const target = event.target as HTMLInputElement;
        const action = {
            event: event.type,
            time: ExceptHook.getISODateString(),
            node: target.nodeName,
            text: String(target.textContent || target.value).trim(),
            attributes: ExceptHook.attributesObject(target),
        };

        getSessionItem("history", []).then((value) => {
            setSessionItem("history", value.slice(-9).concat(action));
        });
    }

    /**
     * Register event handlers to write user interactions to a local session storage object.
     */
    public registerInteractionTracker(): void {
        document.addEventListener(
            "click",
            (event) => {
                return ExceptHook.eventHandler(event);
            },
            true,
        );
        document.addEventListener(
            "keydown",
            (event) => {
                if (event.key === "Enter") return ExceptHook.eventHandler(event);
            },
            true,
        );
    }

    /**
     * Register event handlers to send exceptions to the server.
     */
    public registerOnErrorHandler(): void {
        // post the js errors to cgi script where it will be logged.
        window.addEventListener("error", (event) => {
            if (reportCounter >= 3) {
                // do not kill the server with too many messages
                return undefined;
            } else {
                writeException(event.error);
                reportCounter++;
            }
        });
    }
}
