/**
 * Show a popup to set the owner for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";
import * as _ from "lodash";

import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import {
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    AjaxResponse,
    cgiScript,
    getFormData,
    getUrl,
} from "../lib/utils";

import template from "./setAnimalOwner.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    owner_id: number;
    owners: {
        userid: number;
        fullname: string;
    }[];
}

class SetAnimalOwnerViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly ownerId: CheckExtended<Observable<number>>;
    private readonly seed: FetchExtended<Observable<AjaxResponse<Seed>>>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly errors: ko.ObservableArray<string>;
    private readonly notUpdatedPupsWarning: Observable<{ text: string; click: () => void; close: () => void }>;


    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.ownerId = ko.observable().extend({
            invalid: (v) => {
                return !v;
            },
        });

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.animalId) {
                    return fetch(getUrl(cgiScript("set_animal_owner.py"), { animal_id: this.animalId }), { signal });
                } else if (this.pupId) {
                    return fetch(getUrl(cgiScript("set_animal_owner.py"), { pup_id: this.pupId }), { signal });
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.ownerId(seed.owner_id);
            }
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.seed.inProgress() ||
                     this.ownerId.isInvalid());
        });

        this.submitInProgress = ko.observable(false);
        this.notUpdatedPupsWarning = ko.observable();
        this.errors = ko.observableArray([]);

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            owner_id: this.ownerId() || "",
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.notUpdatedPupsWarning(null);
        this.errors([]);

        fetch(cgiScript("set_animal_owner.py"), { method: "POST", body: this.getFormData() })
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    if (response.not_updated_pup_cage_ids) {
                        const notUpdatedPupCageIds = response.not_updated_pup_cage_ids;
                        this.notUpdatedPupsWarning({
                            text:
                                _.template(getTranslation("Show cages (<%- count %>)."))({ count: notUpdatedPupCageIds.length }),
                            click: () => {
                                this.dialog.close();
                                mainMenu.openAndResetListFilter("get_cage_list", { cageid: notUpdatedPupCageIds });
                            },
                            close: () => {
                                this.dialog.close();
                                if (typeof this.reloadCallback === "function") {
                                    this.reloadCallback();
                                }
                            },
                        });

                    } else {
                        this.dialog.close();
                        if (typeof this.reloadCallback === "function") {
                            this.reloadCallback();
                        }
                    }
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error",
                );
            });
    };
}

export const showSetAnimalOwner = dialogStarter(SetAnimalOwnerViewModel, template, params => ({
    name: "SetAnimalOwner",
    width: 330,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    title: params.title,
}));
