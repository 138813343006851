/**
 * Show a cryotank browser pop-up.
 *
 * @param cryotankId
 *        The ID of the cryotank to show.
 *
 * @param cryotankPath
 *        The path to start from, representing the labels of the path elements.
 *        Defaults to the outermost cryotank container (parent/root).
 *        When `allowSelectAnyPath` or `allowAddContent` is `true` then the
 *        selected path can be written back to this observable.
 *
 * @param allowEditPathSettings
 *        Whether to show a button to edit the current path element.
 *        (Set the color of it.)
 *
 * @param allowSelectAnyPath
 *        Whether it is possible to select the nodes for usage outside of the
 *        cryotank browser, including nodes that have children, not
 *        restricting to the straws only (e.g. in filters to choose a parent
 *        cryotank node).
 *        Needs `cryotankPath` as observable to write the path back.
 *
 * @param allowAddContent
 *        Whether new content shall be added to the cryotank.
 *        If `true` then it is possible to select child nodes for usage outside
 *        of the cryotank browser.
 *        Needs `cryotankPath` as observable to write the path back.
 *
 * @param closeCallback
 *        Function to call whenever the popup is closed, whether data was
 *        applied or not (e.g. to unhighlight a row in listview table).
 *
 *
 * Known use cases:
 *
 * (1) browse cryotank starting from outermost container, the cryotank itself (root node)
 *     e.g. from a row in the cryotank list
 *     no initial path, can set colors, cannot select nodes, cannot add content
 *
 * (2) browse cryotank starting from a given node
 *     e.g. viewing from the position of a content (a cryopreserved embryo group or sperm)
 *     initial path as `string`, cannot set colors, cannot select nodes, cannot add content
 *
 * (3) cryotank picker for filtering the content
 *     e.g. in embryo list filter and sperm list filter
 *     path as `observable` to write back the selected path,
 *     cannot set colors, can select all kind of nodes, cannot add content
 *
 * (4) cryotank picker to place cryopreserved content into the cryotank
 *     e.g. in embryo QS, embryo import, sperm QS, sperm import
 *     path as `observable` to write back the selected path,
 *     cannot set colors, can select only child nodes, can add content
 *
 *                          (1)     (2)     (3)         (4)
 * cryotankPath             -       string  observable  observable
 * allowEditPathSettings    true    false   false       false
 * allowSelectAnyPath       false   false   true        false
 * allowAddContent          false   false   false       true
 *
 */

import * as ko from "knockout";

import {
    CryotankCell,
    CryotankCellContent,
    CryotankCellDisplayData,
    CryotankNode,
    CryotankPathData,
    CryotanksService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { notifications } from "../lib/pyratTop";

import template from "./cryotankBrowser.html";
import "./cryotankBrowser.scss";

interface Params {
    cryotankId: number;
    cryotankPath?: ko.ObservableArray<string> | Array<string>;
    allowEditPathSettings?: boolean;
    allowSelectAnyPath?: boolean;
    allowAddContent?: boolean;
    closeCallback?: () => void;
}

interface CryotankSelection extends CryotankNode {
    content?: CryotankCell;
}

class CryotankBrowserViewModel {
    private readonly dialog: HtmlDialog;

    private cryotankSelection: ko.Observable<CryotankSelection>;  // currently displayed node
    private loadDisplayDataInProgress: ko.Observable<boolean>;
    private cryotankDisplayData: ko.Observable<CryotankPathData>;
    private labelPath: ko.PureComputed<string[]>;
    private pathEditorMode: ko.Observable<boolean>;
    private pathEditorAvailable: (index: number) => boolean;
    private cryotankVisibleBreadcrumbs: ko.PureComputed<Array<CryotankNode>>;
    private canNavigate: ko.PureComputed<boolean>;
    private cryotankPathNewLabel: ko.Observable<string>;
    private cryotankPathNewLabelExists: ko.PureComputed<boolean>;
    private cryotankPathSelectable: ko.PureComputed<boolean>;
    private applyPathSelection: () => void;
    private applyPathColorInProgress: ko.Observable<boolean>;
    private loadContentDataInProgress: ko.Observable<boolean>;
    private cryotankContentData: ko.Observable<CryotankCellContent>;

    // params
    private readonly cryotankId: number;
    private readonly cryotankPath: ko.ObservableArray<string>;
    private readonly allowAddContent: boolean;

    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;

        this.cryotankId = params.cryotankId;
        this.cryotankPath = ko.observableArray(ko.unwrap(params.cryotankPath) || []);
        this.allowAddContent = params.allowAddContent || false;

        if ((params.allowSelectAnyPath || params.allowAddContent) && ko.isObservable(params.cryotankPath)) {
            params.cryotankPath.subscribe(() => {
                this.dialog.close();
            });
        }

        this.dialog.addOnClose(() => {
            if (params.closeCallback) {
                params.closeCallback();
            }
        });

        this.cryotankSelection = ko.observable();

        this.loadDisplayDataInProgress = ko.observable(false);
        this.cryotankDisplayData = ko.observable();

        this.labelPath = ko.pureComputed(() => {
            if (this.cryotankDisplayData()) {
                return this.cryotankDisplayData().label_path.concat(this.cryotankSelection() ? [this.cryotankSelection().label] : []);
            }
        });

        this.pathEditorMode = ko.observable(false);
        this.pathEditorAvailable = (index) => {
            return params.allowEditPathSettings
                   && Boolean(this.cryotankDisplayData())
                   && index > 0  // 0 is cryotank node (root node)
                   && index + 1 === this.cryotankVisibleBreadcrumbs().length  // show only on current element
                   && Boolean(this.cryotankDisplayData().content_count)  // show only if there is content
                   && (this.cryotankSelection() ? Boolean(this.cryotankSelection().content?.id) : true);  // show only with content in selection
        };

        this.cryotankPath.subscribe((cryotankPath) => {
            this.pathEditorMode(false);

            this.loadDisplayDataInProgress(true);
            CryotanksService.getContainerDetails({
                cryotankId: this.cryotankId,
                labelPath: cryotankPath || [],
            }).then((response) => {
                this.cryotankDisplayData(response);
                this.loadDisplayDataInProgress(false);
            }).catch(() => {
                notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
                this.loadDisplayDataInProgress(false);
            });
        });
        this.cryotankSelection.subscribe((cryotankSelection) => {
            this.pathEditorMode(false);

            if (cryotankSelection) {
                this.loadContentDataInProgress(true);
                CryotanksService.getStrawDetails({
                    cryotankId: this.cryotankId,
                    labelPath: this.labelPath() || [],
                }).then((response) => {
                    this.cryotankContentData(response);
                    this.loadContentDataInProgress(false);
                }).catch(() => {
                    notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
                    this.loadContentDataInProgress(false);
                });
            } else {
                this.cryotankContentData(undefined);
            }
        });
        this.cryotankDisplayData.subscribe((displayData) => {
            this.cryotankSelection(displayData.preselect);
        });

        // the breadcrumbs to display, depending on loading state
        this.cryotankVisibleBreadcrumbs = ko.pureComputed(() => {
            let path: Array<CryotankNode> = [];

            if (this.cryotankDisplayData()) {
                if (this.loadDisplayDataInProgress()) {
                    path = this.cryotankDisplayData().breadcrumbs.slice(0, this.cryotankPath().length);
                } else {
                    path = this.cryotankDisplayData().breadcrumbs.slice(0);
                }
            }

            if (this.cryotankSelection()) {
                path.push(this.cryotankSelection());
            }

            return path;
        });

        this.canNavigate = ko.pureComputed(() => !this.loadDisplayDataInProgress() && !this.pathEditorMode());

        this.cryotankPathNewLabel = ko.observable();
        this.cryotankPathNewLabelExists = ko.pureComputed(() => {
            let used = false;

            if (this.cryotankDisplayData() && this.cryotankPathNewLabel()) {
                this.cryotankDisplayData()?.rows?.forEach((row) => {
                    row.forEach((column) => {
                        if (column.label === this.cryotankPathNewLabel()) {
                            used = true;
                        }
                    });
                });
            }

            return used;
        });

        this.cryotankPathSelectable = ko.pureComputed(() => {
            return this.canNavigate()
                   && this.cryotankDisplayData()
                   && (this.allowAddContent && Boolean(this.cryotankSelection()) || params.allowSelectAnyPath);
        });

        this.applyPathSelection = () => {
            if (ko.isObservable(params.cryotankPath)) {
                params.cryotankPath(this.labelPath());
            }
        };

        this.applyPathColorInProgress = ko.observable(false);

        this.loadContentDataInProgress = ko.observable(false);
        this.cryotankContentData = ko.observable();

        this.cryotankPath.notifySubscribers(this.cryotankPath());  // trigger initial loading of data
    }

    private togglePathEditorMode = () => {
        this.pathEditorMode(!this.pathEditorMode());
    };

    private selectLabel = (kind: string, label: string, content: CryotankCell) => {
        if (this.canNavigate()) {
            this.cryotankSelection({ kind, label, content });
        }

        this.pathEditorMode(false);
    };

    private goToLabel = (label: string) => {
        if (this.canNavigate()) {
            this.cryotankPath.push(label);
        }

        this.pathEditorMode(false);
    };

    private goToDepth = (depth: number) => {
        if (this.canNavigate()) {
            if (this.cryotankPath().length > depth) {
                this.cryotankPath(this.cryotankPath().slice(0, depth));
            } else {
                this.cryotankSelection(undefined);
            }
        }

        this.pathEditorMode(false);
    };

    private cryotankCellClicked = (data: CryotankCellDisplayData) => {
        if (data.label) {
            if (data.selectable) {
                this.selectLabel(this.cryotankDisplayData().content_kind, data.label, data.content);
            } else {
                this.goToLabel(data.label);
            }
        }
    };

    private cryotankPathAddElement = () => {
        if (this.cryotankDisplayData() && this.cryotankPathNewLabel() && !this.cryotankPathNewLabelExists()) {
            this.cryotankPath.push(this.cryotankPathNewLabel());
            this.cryotankPathNewLabel(undefined);
        }
    };

    private applyPathColor = (color: "white" | "red" | "orange" | "yellow" | "green" | "blue" | "cyan") => {
        this.applyPathColorInProgress(true);
        CryotanksService.updateCryotankPathColor({
            cryotankId: this.cryotankId,
            color: color,
            requestBody: this.labelPath(),
        }).then(() => {
            this.pathEditorMode(false);
            this.applyPathColorInProgress(false);
            this.cryotankPath.notifySubscribers(this.cryotankPath());
        }).catch(() => {
            notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
            this.applyPathColorInProgress(false);
        });
    };

}

export const showCryotankBrowser = htmlDialogStarter(CryotankBrowserViewModel, template, {
    name: "CryotankBrowser",
    position: {
        inset: { top: 20, left: 20 },
    },
    width: 1200,
    modal: true,
    closeOthers: true,
    title: getTranslation("Cryotank"),
});
