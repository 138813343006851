import {
    components,
    isObservable,
    Subscribable,
    unwrap,
} from "knockout";
import type { Options } from "nouislider";

export type { Options as SliderOptions } from "nouislider";


interface SliderComponentParams {
    options: Options | Subscribable<Options>;

    // Called at the "end of slide".
    onSet?: (value: number[]) => void;

    // Called after every little change.
    onUpdate?: (value: number[]) => void;
}


class SliderComponentViewModel {

    private params: SliderComponentParams;

    constructor(params: SliderComponentParams, componentInfo: components.ComponentInfo) {
        this.params = params;
        const element = componentInfo.element as HTMLElement;
        // const element = componentInfo.element.firstChild as HTMLElement;
        element.style.display = "block";
        import("nouislider").then((noUiSlider) => {
            import(/* webpackChunkName: "slider" */"./slider.scss");
            const slider = noUiSlider.create(element, unwrap(params.options));

            if (isObservable(params.options)) {
                params.options.subscribe((v: any) => slider.updateOptions(v, false));
            }

            if (typeof params.onUpdate === "function") {
                slider.on("update", (values, handle, unencodedValues) =>
                    params.onUpdate(unencodedValues),
                );
            }
            if (typeof params.onSet === "function") {
                slider.on("set", (values, handle, unencodedValues) =>
                    params.onSet(unencodedValues),
                );
            }
        });

    }
}

export class SliderComponent {

    constructor() {

        return {
            viewModel: {
                createViewModel: (params: any, componentInfo: components.ComponentInfo) => {
                    return new SliderComponentViewModel(params, componentInfo);
                },
            },
            template: "<!-- -->",
        };
    }
}
