import {
    observable,
    Observable,
    observableArray,
    ObservableArray,
} from "knockout";

import {
    BasicUser,
    AnnouncementDetails,
    AnnouncementsService,
    UsersService,
} from "../backend/v1/";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";

import announcementDetailsTemplate from "./announcementDetails.html";

interface AnnouncementDetailParams {
    announcementId: number | null;
    closeCallback?: () => void;
    reloadCallback: () => void;
}

class AnnouncementDetailsViewModel {
    public readonly dialog: HtmlDialog;
    public readonly announcementId: number;
    public readonly reloadCallback: () => void;

    public readonly error: Observable<string> = observable();
    public readonly isLoading: Observable<boolean> = observable(true);
    public readonly saveInProgress: Observable<boolean> = observable(false);
    public readonly deleteInProgress: Observable<boolean> = observable(false);
    public readonly reloadRequired: Observable<boolean> = observable(false);

    public readonly possibleOwners: ObservableArray<BasicUser> = observableArray();

    public readonly type: Observable<AnnouncementDetails["type"]> = observable();
    public readonly title: Observable<AnnouncementDetails["title"]> = observable();
    public readonly body: Observable<AnnouncementDetails["body"]> = observable();
    public readonly creatorId: Observable<AnnouncementDetails["creator_id"]> = observable();
    public readonly public: Observable<AnnouncementDetails["public"]> = observable();
    public readonly publicFrom: Observable<AnnouncementDetails["public_from"]> = observable();
    public readonly publicThrough: Observable<AnnouncementDetails["public_through"]> = observable();
    public readonly permanent: Observable<AnnouncementDetails["permanent"]> = observable();

    public readonly previewMode: Observable<boolean> = observable(false);
    public readonly resetUserStatus: Observable<boolean> = observable(false);

    constructor(dialog: HtmlDialog, params: AnnouncementDetailParams) {
        this.dialog = dialog;
        this.announcementId = params.announcementId;

        dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        const creatorListPromise = UsersService.getUsersForSetting({ level: ["admin"] }).then((data) => {
            this.possibleOwners(data);
        });

        Promise.all([creatorListPromise]).then(() => {
            if (this.announcementId === null) {
                this.dialog.setTitle(getTranslation("Create Announcement"));
                this.title("");
                this.type("login");
                this.body("");
                this.creatorId(session.userId);
                this.public(false);
                this.publicFrom("");
                this.publicThrough("");
                this.permanent(false);
                this.isLoading(false);
            } else {
                this.dialog.setTitle(getTranslation("Edit Announcement"));
                this.isLoading(true);
                this.error("");
                AnnouncementsService.getAnnouncement({ announcementId: this.announcementId })
                    .then((data) => {
                        this.title(data.title);
                        this.type(data.type);
                        this.body(data.body);
                        this.creatorId(data.creator_id);
                        this.public(data.public);
                        this.publicFrom(data.public_from);
                        this.publicThrough(data.public_through);
                        this.permanent(data.permanent);
                    })
                    .catch((reason) => {
                        if (typeof reason.body?.detail == "string") {
                            this.error(reason.body?.detail);
                        } else {
                            this.error(getTranslation("General error."));
                            writeException(reason);
                        }
                    })
                    .finally(() => {
                        this.isLoading(false);
                    });
            }
        });
    }

    public saveAnnouncement() {
        this.error("");
        this.saveInProgress(true);
        if (this.announcementId === null) {
            // Create new announcement
            AnnouncementsService.createAnnouncement({
                requestBody: {
                    type: this.type(),
                    title: this.title(),
                    body: this.body(),
                    creator_id: this.creatorId(),
                    public: this.public(),
                    public_from: this.publicFrom() || null,
                    public_through: this.publicThrough() || null,
                    permanent: this.permanent(),
                },
            })
                .then(() => {
                    notifications.showNotification(getTranslation("Announcement created."), "success");
                    this.reloadRequired(true);
                    this.dialog.close();
                })
                .catch((reason) => {
                    if (typeof reason.body?.detail == "string") {
                        this.error(reason.body?.detail);
                    } else {
                        this.error(getTranslation("General error."));
                        writeException(reason);
                    }
                })
                .finally(() => {
                    this.saveInProgress(false);
                });
        } else {
            // Update existing announcement
            AnnouncementsService.updateAnnouncement({
                announcementId: this.announcementId,
                requestBody: {
                    type: this.type(),
                    title: this.title(),
                    body: this.body(),
                    creator_id: this.creatorId(),
                    public: this.public(),
                    public_from: this.publicFrom() || null,
                    public_through: this.publicThrough() || null,
                    permanent: this.permanent(),
                },
                resetUserStatus: this.resetUserStatus(),
            })
                .then(() => {
                    notifications.showNotification(getTranslation("Announcement updated."), "success");
                    this.reloadRequired(true);
                    this.dialog.close();
                })
                .catch((reason) => {
                    if (typeof reason.body?.detail == "string") {
                        this.error(reason.body?.detail);
                    } else {
                        this.error(getTranslation("General error."));
                        writeException(reason);
                    }
                })
                .finally(() => {
                    this.saveInProgress(false);
                });
        }
    }

    public deleteAnnouncement() {
        notifications.showConfirm(getTranslation("Do you really want to delete this announcement?"), () => {
            this.deleteInProgress(true);
            AnnouncementsService.deleteAnnouncement({ announcementId: this.announcementId })
                .then(() => {
                    notifications.showNotification(getTranslation("Announcement deleted."), "success");
                    this.reloadRequired(true);
                    this.dialog.close();
                })
                .catch((reason) => {
                    if (typeof reason.body?.detail == "string") {
                        notifications.showNotification(reason.body?.detail, "error");
                    } else {
                        notifications.showNotification(getTranslation("General error."), "error");
                        writeException(reason);
                    }
                })
                .finally(() => {
                    this.deleteInProgress(false);
                });
        });
    }
}

export const showAnnouncementDetails = htmlDialogStarter(AnnouncementDetailsViewModel, announcementDetailsTemplate, {
    name: "AnnouncementDetails",
    width: 450,
    height: "auto",
    closeOthers: true,
});
