import * as ko from "knockout";
import {
    Computed,
    Observable,
} from "knockout";
import * as _ from "lodash";

import { SystemStatusService } from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import { getTranslation } from "../../lib/localize";
import { notifications } from "../../lib/pyratTop";
import {
    getReadableByteSizeObject,
    ReadableByteSize,
} from "../../lib/utils";
import "./style.scss";


interface DiskSettings {
    id: number;
    space_warning_limit: number;
    send_warning_email: boolean;
    disk_type: string;
}

interface MemorySettings {
    id: number;
    memory_warning_limit: number;
    send_warning_email: boolean;
}

interface Settings {
    memory_settings?: MemorySettings;
    logs_disk_settings?: DiskSettings;
    mysql_disk_settings?: DiskSettings;
    system_disk_settings?: DiskSettings;
    uploads_disk_settings?: DiskSettings;
}

interface Options extends Settings {
    max_memory_warning_limit?: number;
    max_logs_disk_space_warning_limit?: number;
    max_mysql_disk_space_warning_limit?: number;
    max_system_disk_space_warning_limit?: number;
    max_uploads_disk_space_warning_limit?: number;
}

interface WarningLimit extends ReadableByteSize {
    max?: number;
}

class PyratStatusSettings {

    /* options */
    public options: Options;

    /* internals */
    public settings: Settings;
    public memoryWarningLimit: WarningLimit;
    public logsDiskSpaceWarningLimit: WarningLimit;
    public mysqlDiskSpaceWarningLimit: WarningLimit;
    public systemDiskSpaceWarningLimit: WarningLimit;
    public uploadsDiskSpaceWarningLimit: WarningLimit;

    /* state */
    public valid: Computed<boolean>;
    public enabled: Observable<boolean>;
    public saveInProgress: Observable<boolean>;
    public memoryWarningEmail: Observable<boolean>;
    public logsDiskSpaceWarningEmail: Observable<boolean>;
    public mysqlDiskSpaceWarningEmail: Observable<boolean>;
    public systemDiskSpaceWarningEmail: Observable<boolean>;
    public uploadsDiskSpaceWarningEmail: Observable<boolean>;
    public memoryWarningLimitValue: CheckExtended<Observable<number>>;
    public logsDiskSpaceWarningLimitValue: CheckExtended<Observable<number>>;
    public mysqlDiskSpaceWarningLimitValue: CheckExtended<Observable<number>>;
    public systemDiskSpaceWarningLimitValue: CheckExtended<Observable<number>>;
    public uploadsDiskSpaceWarningLimitValue: CheckExtended<Observable<number>>;

    constructor(options: Options) {

        this.options = options;

        /* settings */
        this.settings = {} as Settings;
        this.memoryWarningLimit = {} as WarningLimit;
        this.logsDiskSpaceWarningLimit = {} as WarningLimit;
        this.mysqlDiskSpaceWarningLimit = {} as WarningLimit;
        this.systemDiskSpaceWarningLimit = {} as WarningLimit;
        this.uploadsDiskSpaceWarningLimit = {} as WarningLimit;

        /* observables */
        this.enabled = ko.observable(false);
        this.saveInProgress = ko.observable(false);

        this.memoryWarningEmail = ko.observable(false);
        this.memoryWarningLimitValue = ko.observable();
        this.logsDiskSpaceWarningEmail = ko.observable(false);
        this.logsDiskSpaceWarningLimitValue = ko.observable();
        this.mysqlDiskSpaceWarningEmail = ko.observable(false);
        this.mysqlDiskSpaceWarningLimitValue = ko.observable();
        this.systemDiskSpaceWarningEmail = ko.observable(false);
        this.systemDiskSpaceWarningLimitValue = ko.observable();
        this.uploadsDiskSpaceWarningEmail = ko.observable(false);
        this.uploadsDiskSpaceWarningLimitValue = ko.observable();

        /**
         * Validation and error handling
         */
        this.valid = ko.pureComputed(() => {
            return (this.memoryWarningEmail() === false
                    || (this.memoryWarningLimitValue() && this.memoryWarningLimitValue.isValid()))
                && (this.logsDiskSpaceWarningEmail() === false
                    || (this.logsDiskSpaceWarningLimitValue() && this.logsDiskSpaceWarningLimitValue.isValid()))
                && (this.mysqlDiskSpaceWarningEmail() === false
                    || (this.mysqlDiskSpaceWarningLimitValue() && this.mysqlDiskSpaceWarningLimitValue.isValid()))
                && (this.systemDiskSpaceWarningEmail() === false
                    || (this.systemDiskSpaceWarningLimitValue() && this.systemDiskSpaceWarningLimitValue.isValid()))
                && (this.uploadsDiskSpaceWarningEmail() === false
                    || (this.uploadsDiskSpaceWarningLimitValue() && this.uploadsDiskSpaceWarningLimitValue.isValid()));
        });

        /**
         * Validate free memory warning limit input field
         */
        this.memoryWarningLimitValue.extend({
            invalid: (v) => {
                return !(_.isNumber(v || 1) && (v || 1) >= 1 && (v || 1) <= (this.memoryWarningLimit.max || 1))
                    && getTranslation("Value must not be greater than") + ": " + this.memoryWarningLimit.max;
            },
        });

        /**
         * Validate logs disk space warning limit input field
         */
        this.logsDiskSpaceWarningLimitValue.extend({
            invalid: (v) => {
                return !(_.isNumber(v || 1) && (v || 1) >= 1 && (v || 1) <= (this.logsDiskSpaceWarningLimit.max || 1))
                    && getTranslation("Value must not be greater than") + ": " + this.logsDiskSpaceWarningLimit.max;
            },
        });

        /**
         * Validate mysql disk space warning limit input field
         */
        this.mysqlDiskSpaceWarningLimitValue.extend({
            invalid: (v) => {
                return !(_.isNumber(v || 1) && (v || 1) >= 1 && (v || 1) <= (this.mysqlDiskSpaceWarningLimit.max || 1))
                    && getTranslation("Value must not be greater than") + ": " + this.mysqlDiskSpaceWarningLimit.max;
            },
        });

        /**
         * Validate system disk space warning limit input field
         */
        this.systemDiskSpaceWarningLimitValue.extend({
            invalid: (v) => {
                return !(_.isNumber(v || 1) && (v || 1) >= 1 && (v || 1) <= (this.systemDiskSpaceWarningLimit.max || 1))
                    && getTranslation("Value must not be greater than") + ": " + this.systemDiskSpaceWarningLimit.max;
            },
        });

        /**
         * Validate uploads disk space warning limit input field
         */
        this.uploadsDiskSpaceWarningLimitValue.extend({
            invalid: (v) => {
                return !(_.isNumber(v || 1) && (v || 1) >= 1 && (v || 1) <= (this.uploadsDiskSpaceWarningLimit.max || 1))
                    && getTranslation("Value must not be greater than") + ": " + this.uploadsDiskSpaceWarningLimit.max;
            },
        });

        this.init();
    }

    /**
     * Initialize settings
     */
    public init = () => {
        this.enabled(Boolean(Object.keys(this.options).length));  // enable form if options are not empty (status record exists)

        /* memory usage warning */
        if (this.options.memory_settings) {
            this.settings.memory_settings = this.options.memory_settings;

            if (this.options.memory_settings.memory_warning_limit) {
                this.memoryWarningLimit = getReadableByteSizeObject(this.options.memory_settings.memory_warning_limit);
                this.memoryWarningLimitValue(this.memoryWarningLimit.value);
            }
            this.memoryWarningEmail(Boolean(this.options.memory_settings.send_warning_email));
        }
        if (this.options.max_memory_warning_limit) {
            this.memoryWarningLimit.max = this.getMaxWarningLimit(this.options.max_memory_warning_limit);
        }

        /* logs disk usage warning */
        if (this.options.logs_disk_settings) {
            this.settings.logs_disk_settings = this.options.logs_disk_settings;

            if (this.options.logs_disk_settings.space_warning_limit) {
                this.logsDiskSpaceWarningLimit = getReadableByteSizeObject(this.options.logs_disk_settings.space_warning_limit);
                this.logsDiskSpaceWarningLimitValue(this.logsDiskSpaceWarningLimit.value);
            }
            this.logsDiskSpaceWarningEmail(Boolean(this.options.logs_disk_settings.send_warning_email));
        }
        if (this.options.max_logs_disk_space_warning_limit) {
            this.logsDiskSpaceWarningLimit.max = this.getMaxWarningLimit(this.options.max_logs_disk_space_warning_limit);
        }

        /* mysql disk usage warning */
        if (this.options.mysql_disk_settings) {
            this.settings.mysql_disk_settings = this.options.mysql_disk_settings;

            if (this.options.mysql_disk_settings.space_warning_limit) {
                this.mysqlDiskSpaceWarningLimit = getReadableByteSizeObject(this.options.mysql_disk_settings.space_warning_limit);
                this.mysqlDiskSpaceWarningLimitValue(this.mysqlDiskSpaceWarningLimit.value);
            }
            this.mysqlDiskSpaceWarningEmail(Boolean(this.options.mysql_disk_settings.send_warning_email));
        }
        if (this.options.max_mysql_disk_space_warning_limit) {
            this.mysqlDiskSpaceWarningLimit.max = this.getMaxWarningLimit(this.options.max_mysql_disk_space_warning_limit);
        }

        /* system disk usage warning */
        if (this.options.system_disk_settings) {
            this.settings.system_disk_settings = this.options.system_disk_settings;

            if (this.options.system_disk_settings.space_warning_limit) {
                this.systemDiskSpaceWarningLimit = getReadableByteSizeObject(this.options.system_disk_settings.space_warning_limit);
                this.systemDiskSpaceWarningLimitValue(this.systemDiskSpaceWarningLimit.value);
            }
            this.systemDiskSpaceWarningEmail(Boolean(this.options.system_disk_settings.send_warning_email));
        }
        if (this.options.max_system_disk_space_warning_limit) {
            this.systemDiskSpaceWarningLimit.max = this.getMaxWarningLimit(this.options.max_system_disk_space_warning_limit);
        }

        /* uploads disk usage warning */
        if (this.options.uploads_disk_settings) {
            this.settings.uploads_disk_settings = this.options.uploads_disk_settings;

            if (this.options.uploads_disk_settings.space_warning_limit) {
                this.uploadsDiskSpaceWarningLimit = getReadableByteSizeObject(this.options.uploads_disk_settings.space_warning_limit);
                this.uploadsDiskSpaceWarningLimitValue(this.uploadsDiskSpaceWarningLimit.value);
            }
            this.uploadsDiskSpaceWarningEmail(Boolean(this.options.uploads_disk_settings.send_warning_email));
        }
        if (this.options.max_uploads_disk_space_warning_limit) {
            this.uploadsDiskSpaceWarningLimit.max = this.getMaxWarningLimit(this.options.max_uploads_disk_space_warning_limit);
        }

        // _.defer(this.afterRender);
    };

    /**
     * Activate all warnings for email
     */
    public activateAllWarnings = () => {
        this.memoryWarningEmail(true);
        this.logsDiskSpaceWarningEmail(true);
        this.mysqlDiskSpaceWarningEmail(true);
        this.systemDiskSpaceWarningEmail(true);
        this.uploadsDiskSpaceWarningEmail(true);
    };

    /**
     * Cancel & reset status settings
     */
    public cancel = () => {
        this.memoryWarningEmail(false);
        this.logsDiskSpaceWarningEmail(false);
        this.mysqlDiskSpaceWarningEmail(false);
        this.systemDiskSpaceWarningEmail(false);
        this.uploadsDiskSpaceWarningEmail(false);

        this.memoryWarningLimitValue(this.memoryWarningLimit.value);
        this.logsDiskSpaceWarningLimitValue(this.logsDiskSpaceWarningLimit.value);
        this.mysqlDiskSpaceWarningLimitValue(this.mysqlDiskSpaceWarningLimit.value);
        this.systemDiskSpaceWarningLimitValue(this.systemDiskSpaceWarningLimit.value);
        this.uploadsDiskSpaceWarningLimitValue(this.uploadsDiskSpaceWarningLimit.value);
    };

    /**
     * Save status settings
     */
    public save = () => {
        const coefficient = Math.pow(1024, 3);
        this.saveInProgress(true);
        SystemStatusService.saveSystemStatusSettings({
            requestBody: {
                memory_settings: Object.assign({}, this.settings.memory_settings, {
                    send_warning_email: this.memoryWarningEmail(),
                    memory_warning_limit: this.memoryWarningLimitValue() * coefficient,
                }),
                disk_settings: [
                    Object.assign({}, this.settings.logs_disk_settings, {
                        disk_type: "logs",
                        send_warning_email: this.logsDiskSpaceWarningEmail(),
                        space_warning_limit: this.logsDiskSpaceWarningLimitValue() * coefficient,
                    }),
                    Object.assign({}, this.settings.mysql_disk_settings, {
                        disk_type: "mysql",
                        send_warning_email: this.mysqlDiskSpaceWarningEmail(),
                        space_warning_limit: this.mysqlDiskSpaceWarningLimitValue() * coefficient,
                    }),
                    Object.assign({}, this.settings.system_disk_settings, {
                        disk_type: "system",
                        send_warning_email: this.systemDiskSpaceWarningEmail(),
                        space_warning_limit: this.systemDiskSpaceWarningLimitValue() * coefficient,
                    }),
                    Object.assign({}, this.settings.uploads_disk_settings, {
                        disk_type: "uploads",
                        send_warning_email: this.uploadsDiskSpaceWarningEmail(),
                        space_warning_limit: this.uploadsDiskSpaceWarningLimitValue() * coefficient,
                    }),
                ],
            },
        })
            .then(() => {
                notifications.showNotification(
                    getTranslation("Saving status report settings was successful."),
                    "success",
                );
                window.location.reload();
            })
            .catch(() => notifications.showNotification(getTranslation("General unexpected error occurred."), "error"))
            .finally(() => this.saveInProgress(false));
    };

    /**
     * Convert max warning limit (Bytes) to a human-readable number.
     *
     * @param maxWarningLimit (number of bytes)
     * @returns Human readable number (if decimal, rounded down to first decimal place)
     */
    private getMaxWarningLimit = (maxWarningLimit: number): number => {
        return Math.floor(getReadableByteSizeObject(maxWarningLimit, 2).value * 10) / 10;
    };
}

export const initPyratStatusSettings = (options: Options): void => {
    ko.applyBindings(new PyratStatusSettings(options));
};
