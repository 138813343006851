import * as ko from "knockout";
import {
    Computed,
    Observable,
    ObservableArray,
} from "knockout";
import * as _ from "lodash";

import {
    Body_importTanks,
    LicenseClassificationOption,
    LicenseClassificationOptionDelimiter,
    LicenseOption,
    LicensesService,
    TankImportDialogData,
    TanksService,
} from "../backend/v1";
import { UsersService } from "../backend/v1/services/UsersService";
import {
    LocationItem,
    PreselectLocationItem,
} from "../knockout/components/locationPicker/locationPicker";
import { TankPosition } from "../knockout/components/locationPicker/tankPicker";
import { showTankPicker } from "../knockout/components/locationPicker/tankPickerDialog";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import {
    formatDate,
    parseDate,
} from "../lib/flatpickr";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    addDays,
    isInvalidCalendarDate,
    normalizeDate,
} from "../lib/utils";

import template from "./tankAnimalImport.html";

import "./tankAnimalImport.scss";


interface TankAnimalImportParams {
    crossingId?: number;
    strainId?: number;
    reloadCallback?: () => void;
}
interface Tank {
    occupied: Computed<boolean>;
    tankComment: Observable<string>;
    tankPosition: Observable<string>;
    tankType: Observable<"experiment" | "stock">;
    ageLevel: Observable<"larva" | "juvenile" | "adult">;
    males: CheckExtended<Observable<string>>;
    females: CheckExtended<Observable<string>>;
    unknowns: CheckExtended<Observable<string>>;
    aliveCount?: CheckExtended<Computed<number>>;
}

class TankAnimalImportViewModel {

    private readonly dialog: HtmlDialog;

    public errors: ObservableArray<string>;
    public dialogData: Observable<TankImportDialogData> = ko.observable();
    public fetchInProgress: Observable<boolean> = ko.observable(false);
    public submitInProgress: Observable<boolean> = ko.observable(false);
    public reloadCallback?: () => void;

    public crossingId: Observable<number>;
    public ageLevel: Observable<"larva" | "juvenile" | "adult">;
    public species: CheckExtended<Observable<{ id: number }>>;
    public strainId: CheckExtended<Observable<number>>;
    public strainType: Observable<string>;
    public customStrain: Observable<string>;
    public projectIds: ObservableArray<number>;
    public owner: CheckExtended<Observable<{ userid: number }>>;
    public responsible: CheckExtended<Observable<{ userid: number }>>;
    public origin: CheckExtended<Observable<{ id: number; name: string }>>;
    public licenseId: CheckExtended<Observable<number>>;
    public selectedClassifications: ObservableArray<number>;
    public classificationId: CheckExtended<Observable<number>>;
    public licenseAssignDate: CheckExtended<Observable<string>>;
    public overuseLicense: Observable<boolean>;
    public geneticBackground: Observable<{ id: number; name: string }>;
    public generation: Observable<string>;
    public location: Observable<LocationItem>;
    public preselectLocation: PreselectLocationItem;
    public dateOfBirth: CheckExtended<Observable<string>>;
    public dateOfRelease: CheckExtended<Observable<string>>;

    public availableResponsibles: FetchBackendExtended<Observable<{ userid: number }>>;
    public availableLicenses: FetchBackendExtended<ObservableArray<LicenseOption>>;
    public availableClassifications: FetchBackendExtended<ObservableArray<LicenseClassificationOption|LicenseClassificationOptionDelimiter>>;
    public occupiedPositions: FetchBackendExtended<Observable<string[]>>;

    public tanks: ObservableArray<Tank>;

    public addTank = () => {

        const position = ko.observable("").extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });
        const lastTank = _.last(this.tanks());
        const newTank: Tank = {
            occupied: ko.pureComputed(() => {
                return _.includes(this.occupiedPositions(), position());
            }),
            tankComment: ko.observable(undefined),
            tankPosition: position,
            tankType: ko.observable(lastTank ? lastTank.tankType() : "stock"),
            ageLevel: ko.observable(lastTank ? lastTank.ageLevel() : "larva"),
            males: ko.observable().extend({
                invalid: (v) => {
                    return !(((_.isNumber(v) && String(v).match(/^\d+$/)) || _.isUndefined(v)) && (v || 0) >= 0);
                },
            }),
            females: ko.observable().extend({
                invalid: (v) => {
                    return !(((_.isNumber(v) && String(v).match(/^\d+$/)) || _.isUndefined(v)) && (v || 0) >= 0);
                },
            }),
            unknowns: ko.observable().extend({
                invalid: (v) => {
                    return !(((_.isNumber(v) && String(v).match(/^\d+$/)) || _.isUndefined(v)) && (v || 0) >= 0);
                },
            }),
        };

        newTank.aliveCount = ko.pureComputed(function () {
            return ((parseInt(newTank.males(), 10) || 0) +
                (parseInt(newTank.females(), 10) || 0) +
                (parseInt(newTank.unknowns(), 10) || 0));
        }).extend({
            invalid: v => !(v > 0),
        });

        this.tanks.push(newTank);
    };

    public removeTank = (item: Tank) => {
        this.tanks(_.without(this.tanks(), item));
    };

    public showPositionPicker = (tank: Tank, event: MouseEvent) => {
        showTankPicker({
            location: this.location(),
            clickEvent: event,
            onApply: (position: TankPosition) => {
                tank.tankPosition(position.tank_position);
            },
        });
    };

    public submit = (showAfterImport = false) => {
        this.errors.removeAll();
        this.submitInProgress(true);

        const data: Body_importTanks = {
            crossing_id: this.crossingId(),
            species_id: (this.species() || {}).id,
            project_ids: this.projectIds(),
            owner_id: (this.owner() || {}).userid,
            responsible_id: (this.responsible() || {}).userid,
            origin_id: (this.origin() || {}).id,
            license_classification_id: this.classificationId(),
            license_assign_date: this.licenseAssignDate(),
            confirmed_license_overuse: this.overuseLicense() || false,
            genetic_background_id: (this.geneticBackground() || {}).id,
            generation: this.generation(),
            rack_id: (this.location() || {}).db_id,
            date_of_birth: this.dateOfBirth(),
            date_of_release: this.dateOfRelease(),
            tanks: this.tanks().map((tank) => {
                return {
                    age_level: tank.ageLevel(),
                    comment: tank.tankComment(),
                    number_of_male: parseInt(tank.males(), 10) || 0,
                    number_of_female: parseInt(tank.females(), 10) || 0,
                    number_of_unknown: parseInt(tank.unknowns(), 10) || 0,
                    tank_position: tank.tankPosition(),
                    tank_type: tank.tankType(),
                };
            }),
        };

        if (this.strainType() === "known") {
            data["strain_id"] = this.strainId();
        } else if (this.strainType() === "new") {
            data["strain_name"] = this.customStrain();
        }

        TanksService
            .importTanks({ requestBody: data })
            .then((tankIds) => {
                notifications.showNotification(getTranslation("New tank(s) successfully added"), "success");
                if (showAfterImport) {
                    mainMenu.openAndResetListFilter("get_tank_list", { neighbor_of_tank_id: tankIds });
                } else if (typeof this.reloadCallback === "function") {
                    this.dialog.addOnClose(this.reloadCallback);
                }
                this.dialog.close();
            })
            .catch((error) => {
                if (typeof error.body?.detail == "string") {
                    this.errors.push(error.body.detail);
                } else {
                    this.errors.push(getTranslation("Import error."));
                    writeException(error);
                    throw error;
                }
            })
            .finally(() => this.submitInProgress(false));
    };

    // summary

    public malesTotal = () => {
        return _.reduce(this.tanks(), function (m, r) {
            return m + (parseInt(r.males(), 10) || 0);
        }, 0);
    };

    public femalesTotal = () => {
        return _.reduce(this.tanks(), function (m, r) {
            return m + (parseInt(r.females(), 10) || 0);
        }, 0);
    };

    public unknownsTotal = () => {
        return _.reduce(this.tanks(), function (m, r) {
            return m + (parseInt(r.unknowns(), 10) || 0);
        }, 0);
    };


    // validate

    public valid = () => {

        if (this.licenseId.isInvalid()) {
            return false;
        }

        if (this.classificationId.isInvalid()) {
            return false;
        }

        if (this.licenseAssignDate.isInvalid()) {
            return false;
        }

        if (this.dateOfRelease.isInvalid()) return false;

        if (this.dateOfBirth.isInvalid()) return false;

        if (this.species.isInvalid()) return false;

        if (this.origin.isInvalid()) return false;

        if (this.owner.isInvalid()) return false;

        if (this.strainId.isInvalid()) return false;

        if (this.responsible.isInvalid()) return false;

        if (!_.every(_.map(this.tanks(), function (tank) {
            return tank.males.isValid()
                && tank.females.isValid()
                && tank.unknowns.isValid()
                && tank.aliveCount.isValid();
        }))) return false;

        // noinspection RedundantIfStatementJS
        if (!this.location()) return false;

        return true;
    };


    constructor(dialog: HtmlDialog, params: TankAnimalImportParams = {}) {

        this.dialog = dialog;
        this.errors = ko.observableArray([]);
        this.submitInProgress = ko.observable(false);

        this.tanks = ko.observableArray([]);
        this.crossingId = ko.isObservable(params.crossingId) ? params.crossingId : ko.observable(params.crossingId);
        this.reloadCallback = params.reloadCallback;

        this.ageLevel = ko.observable(undefined);
        this.species = ko.observable(undefined);
        this.projectIds = ko.observableArray([]);
        this.owner = ko.observable(undefined);
        this.responsible = ko.observable(undefined);
        this.origin = ko.observable(undefined);
        this.geneticBackground = ko.observable(undefined);
        this.generation = ko.observable(undefined);
        this.location = ko.observable(undefined);
        this.preselectLocation = undefined;
        this.dateOfBirth = ko.observable(undefined);
        this.dateOfRelease = ko.observable(undefined);

        this.licenseId = ko.observable(undefined).extend({
            invalid: (v) => {
                if (session.pyratConf.AQUATIC_MANDATORY_LICENSE) {
                    return !v;
                } else {
                    return false;
                }
            },
        });

        this.selectedClassifications = ko.observableArray([]);
        this.classificationId = ko.observable().extend({
            invalid: (v) => {
                return Boolean(!v && this.licenseId());
            },
        });
        this.licenseAssignDate = ko.observable().extend({
            normalize: normalizeDate,
            invalid: v => !_.isUndefined(v) && isInvalidCalendarDate(v),
        });
        this.overuseLicense = ko.observable(false);


        // special strain creation handling
        this.strainId = ko.observable(undefined);
        this.strainType = ko.observable("known");
        this.customStrain = ko.observable("").extend({
            invalid: v => !(v.length),
        });

        this.dateOfBirth.subscribe((v) => {
            const today = new Date();

            if (v && !isInvalidCalendarDate(v)) {
                const parsedDate = parseDate(v);

                if (parsedDate > today) {
                    notifications.showConfirm(
                        getTranslation("Date of birth in the future. Proceed anyway?"),
                        function () {
                            return true;
                        },
                        {
                            onCancel: () => {
                                this.dateOfBirth(undefined);
                                this.dateOfRelease(undefined);
                            },
                        },
                    );
                }

                this.dateOfRelease(formatDate(addDays(parsedDate, session.pyratConf.AQUATIC_DATE_OF_RELEASE_OFFSET)));
            }
        });

        this.strainId.extend({
            invalid: () => {

                if (session.pyratConf.AQUATIC_MANDATORY_STRAIN) {
                    if (this.strainType() === "known") {
                        return _.isUndefined(this.strainId());
                    } else if (this.strainType() === "new") {
                        return !_.size(this.customStrain());
                    }
                }

                return false;
            },
        });

        this.responsible.extend({
            invalid: (v: any) => !(session.pyratConf.AQUATIC_MANDATORY_RESPONSIBLE ? !_.isUndefined(v) : true),
        });
        this.dateOfBirth.extend({
            invalid: (v: any) => !(v && !isInvalidCalendarDate(v)),
        });
        this.dateOfRelease.extend({
            invalid: (v: any) => !(v && !isInvalidCalendarDate(v)),
        });
        this.species.extend({
            invalid: (v: any) => _.isUndefined(v),
        });
        this.origin.extend({
            invalid: (v: any) => !(session.pyratConf.IMPORT_ORIGIN_REQUIRED ? !_.isUndefined(v) : true),
        });
        this.owner.extend({
            invalid: (v: any) => _.isUndefined(v),
        });

        // get dependent values

        this.availableResponsibles = ko.observable().extend({
            fetchBackend: () => {
                if (this.owner()) {
                    return UsersService.getResponsibles({ userId: [this.owner().userid] });
                }
            },
        });

        this.availableLicenses = ko.observableArray().extend({
            fetchBackend: () => {
                if (this.species()) {
                    return LicensesService.getLicenseOptions({
                        speciesId: this.species().id,
                        strainId: [this.strainType() === "known" ? this.strainId() || 0 : -1],
                    });
                }
            },
        });

        this.availableClassifications = ko.observableArray().extend({
            fetchBackend: () => {
                if (this.licenseId() && this.species()) {
                    return LicensesService.getLicenseClassificationOptions({
                        licenseId: this.licenseId(),
                        speciesId: this.species().id,
                        strainId: [this.strainType() === "known" ? this.strainId() || 0 : -1],
                    });
                }
            },
        });

        this.occupiedPositions = ko.observable().extend({
            fetchBackend: () => {
                const positions = _.filter(_.invokeMap(this.tanks(), "tankPosition"), _.identity);
                if (this.location() && positions.length) {
                    return TanksService.isPositionOccupied({
                        status: "open",
                        tankPosition: positions,
                        rackId: this.location().db_id,
                    });
                }
            },
        });

        this.fetchInProgress(true);
        TanksService
            .getTankImportDialogData({ crossingId: this.crossingId() })
            .then((result: TankImportDialogData) => {
                this.dialogData(result);

                _.defer(() => {
                    const preselectedStrain = _.find(result.strains, { id: result.strain_id });

                    /* Owner */
                    // use current selected alias as default owner
                    if (result.current_alias_id) {
                        this.owner(result.owners.find(({ userid }) => userid === result.current_alias_id));
                    }

                    /* Responsible */
                    this.availableResponsibles.subscribeOnce((responsibles) => {
                        // @ts-expect-error: object as predicate is missing in type definition
                        this.responsible(_.find(responsibles, { userid: result.responsible_id }));
                    });

                    /* Species */
                    this.species(_.find(result.species, { selected: true }));

                    /* Line / Strain (from param) */
                    if (params.strainId) {
                        const knownStrain = _.find(result.strains, { id: params.strainId });
                        if (knownStrain) {
                            this.species(_.find(result.species, { id: knownStrain.species_id }));
                            this.strainType("known");
                            this.strainId(knownStrain.id);
                        }
                    }

                    /* Line / Strain (from crossing) */
                    if (preselectedStrain) {
                        this.species(_.find(result.species, { id: preselectedStrain.species_id }));
                        this.strainType("known");
                        this.strainId(preselectedStrain.id);
                    }


                    /* License and classification from crossing */
                    this.availableLicenses.subscribeOnce(() => this.licenseId(result.license_id));
                    this.availableClassifications.subscribeOnce(() => this.classificationId(result.classification_id));

                    // obtain parent animal details on animal import after crossing
                    if (result.parents && result.parents.length) {

                        const firstParent = _.head(result.parents);

                        /* Owner */
                        this.owner(_.find(result.owners, { userid: firstParent.owner_id }));

                        /* Project */
                        this.projectIds(firstParent.projects.map(({ id }) => id));

                        /* Origin */
                        // default value "Born in house"
                        this.origin(_.find(result.origins, { id: 1 }));

                        /* Species */
                        // If a strain_id is defined in the crossing (default), we obtained the
                        // matching species, otherwise we try to take the one from the first parent.
                        if (!result.strain_id) {
                            this.species(_.find(result.species, { id: firstParent.species_id }));
                        }

                        /* Genetic Background */
                        this.geneticBackground(
                            _.find(result.genetic_backgrounds, { id: firstParent.genetic_background_id }),
                        );

                        /* Licence */
                        this.availableLicenses.subscribeOnce(() => {
                            // If no license is specified in the crossing then preselect the license of the first parent
                            if (!result.license_id && firstParent.licence_id) {
                                this.licenseId(firstParent.licence_id);
                            }
                        });

                        /* Classification */
                        this.availableClassifications.subscribeOnce(() => {
                            // If no classification is specified in the crossing then preselect the classification of the first parent
                            if (!result.classification_id && firstParent.classification_id) {
                                this.classificationId(firstParent.classification_id);
                            }
                        });

                        /* Location */
                        this.preselectLocation = result.common_parent_location;
                    }
                });
            })
            .catch((reason) => {
                this.errors.push(getTranslation("General unexpected error occurred."));
                writeException(reason);
            })
            .finally(() => this.fetchInProgress(false));

        // add a first single tank as initialization
        this.addTank();
    }
}

// dialog starter
export const showTankAnimalImport = htmlDialogStarter(TankAnimalImportViewModel, template, {
    name: "TankAnimalImport",
    closeOthers: true,
    title: getTranslation("Tank Import"),
    width: 600,
    position: {
        inset: { top: 20 },
    },
});
