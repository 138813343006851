import { LocationsService } from "../../backend/v1";
import { showLocationDetails } from "../../dialogs/locationDetails";
import {
    getSessionItem,
    setSessionItem,
} from "../../lib/browserStorage";
import {
    EstablishTree,
    ListFilterItem,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import {
    getUrl,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./locationListFilter.html";


interface LocationListArguments extends ListViewArguments {
    total_count: number;
}

const ListFilters = () => ({
    status: ListFilterItem,
    transfer_destination: ListFilterItem,
});

class List {
    private readonly listView: ListView;
    private readonly args: ListViewArguments;

    private tree: EstablishTree;

    public showLocationContent = (
        menuItemName: string,
        locationFilterKey: string,
        additionalFilter: Record<string, any> | null,
        locationId: string,
    ) => {
        let locationIdArray: number[];
        if (typeof locationId === "string") {
            locationIdArray = locationId.split("-").map((v) => parseInt(v, 10));
        } else {
            locationIdArray = [locationId];
        }

        if (locationIdArray.length === 1) {
            mainMenu.openAndResetListFilter(menuItemName, {
                [locationFilterKey]: {
                    type: "building",
                    id: locationIdArray[0],
                },
                ...(additionalFilter || {}),
            });
        } else if (locationIdArray.length === 2) {
            mainMenu.openAndResetListFilter(menuItemName, {
                [locationFilterKey]: {
                    type: "area",
                    id: locationIdArray[1],
                },
                ...(additionalFilter || {}),
            });
        } else if (locationIdArray.length === 3) {
            mainMenu.openAndResetListFilter(menuItemName, {
                [locationFilterKey]: {
                    type: "room",
                    id: locationIdArray[2],
                },
                ...(additionalFilter || {}),
            });
        } else if (locationIdArray.length === 4) {
            mainMenu.openAndResetListFilter(menuItemName, {
                [locationFilterKey]: {
                    type: "rack",
                    id: locationIdArray[3],
                },
                ...(additionalFilter || {}),
            });
        }
    };

    constructor(listViewElement: HTMLDivElement, args: LocationListArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Location filter"),
            });
        });

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("list-print-button", () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        this.listView.onCellClick(
            "td.visible_open_cage_count a," +
                "td.visible_open_breeding_cage_count a, " +
                "td.visible_open_experiment_cage_count a, " +
                "td.visible_open_stock_cage_count a",
            (args) => {
                const additionalFilter = (args.event.target as HTMLLinkElement).dataset;
                this.showLocationContent("get_cage_list", "location", additionalFilter, args.rowId);
            },
        );

        this.listView.onCellClick("td.visible_live_animal_count a", (args) => {
            this.showLocationContent("get_animal_list", "cage_location", { state: "live" }, args.rowId);
        });

        this.listView.onCellClick("td.visible_live_pup_count a", (args) => {
            this.showLocationContent("get_pup_list", "cage_location", { state: "live" }, args.rowId);
        });

        // links to detail popups

        this.listView.onMenuBoxClick("add-building", () => {
            LocationsService.createLocation({ parentLocationId: null }).then((response) => {
                const locationId = response;
                const newParent = String(locationId).split("-").slice(0, -1).join("-");
                if (newParent) {
                    getSessionItem(this.args.view_name + ".location_ids_to_expand", []).then(
                        (locationIdsToExpand: string[]) => {
                            locationIdsToExpand.push(newParent);
                            setSessionItem(this.args.view_name + ".location_ids_to_expand", locationIdsToExpand);
                        },
                    );
                }

                showLocationDetails({
                    locationId: locationId,
                    reloadCallback: this.listView.reload,
                });
            }).catch((error) => {
                if (error?.body?.detail) {
                    notifications.showNotification(error.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                }
            });
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            const locationIds = this.listView.getSelectedRowIds();
            if (!locationIds.length) {
                notifications.showModal(getTranslation("Please select items you want to export!"));
            } else {
                showColumnSelect({
                    viewName: this.args.view_name,
                    mode: "export",
                    exportArgs: {
                        location_id_or_child: locationIds.length === args.total_count ? undefined : locationIds,
                        page_start: 0,
                        page_size: "",
                    },
                });
            }
        });

        this.listView.onCellClick("td.add_location a", (args) => {
            LocationsService.createLocation({ parentLocationId: args.rowId }).then((response) => {
                const locationId = response;
                const newParent = String(locationId).split("-").slice(0, -1).join("-");
                if (newParent) {
                    getSessionItem(this.args.view_name + ".location_ids_to_expand", []).then(
                        (locationIdsToExpand: string[]) => {
                            locationIdsToExpand.push(newParent);
                            setSessionItem(this.args.view_name + ".location_ids_to_expand", locationIdsToExpand);
                        },
                    );
                }

                showLocationDetails({
                    locationId: locationId,
                    reloadCallback: this.listView.reload,
                });
            }).catch((error) => {
                if (error?.body?.detail) {
                    notifications.showNotification(error.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                }
            });
        });

        this.listView.onCellClick("td.name a", (args) => {
            showLocationDetails({
                locationId: args.rowId,
                reloadCallback: this.listView.reload,
            });
        });

        // establish tree structure (unless order by type)
        if (!args.current_order.length || args.current_order[0][0] !== "type") {
            getSessionItem(args.view_name + ".location_ids_to_expand", []).then((locationIdsToExpand) => {
                this.tree = new EstablishTree(this.listView, {
                    rowKeysToExpand: locationIdsToExpand,
                    onChanged: () => {
                        setSessionItem(`${args.view_name}.location_ids_to_expand`, locationIdsToExpand);
                    },
                });
            });
        }
    }
}

export const initLocationList = (args: LocationListArguments): void => {
    new List(document.querySelector("div.listview"), args);
};
