import * as ko from "knockout";
import * as _ from "lodash";

import {
    IdNameProperty,
    ListFilterDefinition,
    QualityProperties,
} from "../../backend/v1";
import {
    showComments,
    showCryotankBrowser,
    showSetStrawLabel,
    showSpermDetails,
    showSpermImport,
    showSpermQuickselect,
} from "../../dialogs";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    frames,
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import {
    cgiScript,
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./spermListFilter.html";

interface SpermListViewArguments extends ListViewArguments {
    export_args: any;
    sperm_cryopreservation_state_id: number;
}

interface ProjectOption extends IdNameProperty {
    group?: "active" | "inactive" | "expired";
    owner_fullname?: string;
}

interface StrainOption {
    strain_id: number;
    strain_name: string;
    strain_official_name?: string;
}

interface StrainOfficialNameOption {
    id: string;
    strain_official_name: string;
}

const SpermListFilters = (filter: ListFilterModel, args: SpermListViewArguments) => ({
    owner_id: class extends ListFilterItem {
        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
        }
    },

    origin_id: class extends ListFilterItem {
        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
        }
    },

    state_id: class extends ListFilterItem {
        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
        }
    },

    cryotank_id: class extends ListFilterItem {
        private readonly optionsCaption: ko.PureComputed<string>;
        private readonly staticValues: Array<IdNameProperty>;
        private readonly possibleValues: Array<IdNameProperty>;
        private readonly disable: ko.PureComputed<boolean>;

        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.optionsCaption = ko.pureComputed(() => {
                if (this.disable()) {
                    return getTranslation("Cryopreserved only");
                }

                return getTranslation("All");
            });

            this.staticValues = [{ id: 0, name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values || []);

            this.disable = ko.pureComputed(() => {
                return filter.getValue("state_id") !== args.sperm_cryopreservation_state_id;
            });
            this.disable.subscribe((disable) => {
                if (disable) {
                    this.deserialize(undefined);
                }
            });

            this.text = seed.possible_values?.map((v: IdNameProperty) => v.name);
        }
    },

    cryotank_path: class extends ListFilterItem {
        private readonly cryotankId: ko.PureComputed<number>;
        private readonly cryotankPath: ko.ObservableArray<string>;
        private readonly disable: ko.Observable<boolean>;

        constructor(value: ko.Observable<string[]>, seed: ListFilterDefinition) {
            super(value, seed);

            this.cryotankId = ko.pureComputed(() => {
                return filter.getValue("state_id") === args.sperm_cryopreservation_state_id
                       && filter.getValue("cryotank_id")
                       || undefined;
            });
            this.cryotankId.subscribe((cryotankId) => {
                this.disable(!cryotankId);
                this.cryotankPath([]);
            });
            this.cryotankPath = ko.observableArray();
            this.disable = ko.observable(true);

            setTimeout(() => {
                this.cryotankPath(seed.current_value || []);
            }, 0);
        }

        public serialize = () => this.cryotankPath().length ? this.cryotankPath() : undefined;
    },

    cryopreservation_freeze_date_from: class extends ListFilterItem {
        private value: CheckExtended<ko.Observable<string>>;
        private disable: ko.PureComputed<boolean>;

        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("cryopreservation_freeze_date_to"), compareFromDate),
            });

            this.disable = ko.pureComputed(() => {
                return filter.getValue("state_id") !== args.sperm_cryopreservation_state_id;
            });
            this.disable.subscribe((disable) => {
                if (disable) {
                    this.deserialize(undefined);
                }
            });
        }

        public valid = () => {
            return this.value.isValid();
        };
    },

    cryopreservation_freeze_date_to: class extends ListFilterItem {
        private value: CheckExtended<ko.Observable<string>>;
        private disable: ko.PureComputed<boolean>;

        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("cryopreservation_freeze_date_from"), compareToDate),
            });

            this.disable = ko.pureComputed(() => {
                return filter.getValue("state_id") !== args.sperm_cryopreservation_state_id;
            });
            this.disable.subscribe((disable) => {
                if (disable) {
                    this.deserialize(undefined);
                }
            });
        }

        public valid = () => {
            return this.value.isValid();
        };
    },

    project_id: class extends ListFilterItem {
        private readonly staticValues: Array<IdNameProperty>;
        private readonly possibleValues: ProjectOption[];

        constructor(value: ko.Observable<number>, seed: ListFilterDefinition) {
            super(value, seed);

            this.staticValues = [{ id: 0, name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values || []);
            this.text = [].concat(...(seed.possible_values?.map((v: ProjectOption) => [v.name, v.owner_fullname]) || []));
        }
    },

    strain_name_or_id: class extends ListFilterItem {
        private readonly staticValues: StrainOption[];
        private readonly possibleValues: StrainOption[];
        private readonly selectemValue: ko.ObservableArray<StrainOption & {
            strain_id: number | string;
            valid?: ko.Observable<boolean>;
        }>;
        private readonly currentCustomValues: ko.ObservableArray<string>;

        constructor(value: ko.Observable<(number | string)[]>, seed: ListFilterDefinition) {
            super(value, seed);

            this.selectemValue = ko.observableArray();
            this.currentCustomValues = ko.observableArray();

            this.selectemValue.subscribe((newValue) => {
                const strainOfficialNameFilter = filter.allFilters().strain_official_name;
                let newSelectedOfficialNames;

                newValue.forEach((option) => {
                    // @ts-expect-error: types 'number' and 'string' have no overlap
                    if (option.strain_id === option.strain_name && option.strain_id.indexOf("*") === -1) {
                        option.valid(false);
                    }
                });

                // @ts-expect-error: strainOfficialNameFilter model needs to implement the disable method
                if (strainOfficialNameFilter && !strainOfficialNameFilter.model.disable()) {
                    newSelectedOfficialNames = [...new Set(newValue?.filter((strainOption) => {
                        // leave out the custom values, their official names are not known in advance
                        // @ts-expect-error: types 'number' and 'string' have no overlap
                        return strainOption.strain_id !== strainOption.strain_name;
                    }).map((strainOption) => {
                        return strainOption.strain_official_name || null;
                    }) || [])];
                    strainOfficialNameFilter.model.deserialize(newSelectedOfficialNames.length ? newSelectedOfficialNames : undefined);
                }
            });
            this.staticValues = [{ strain_id: 0, strain_name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values || []);
            this.text = seed.possible_values?.map((v: StrainOption) => v.strain_name);
            this.valid = ko.pureComputed(() => this.selectemValue().every((option) => !option.valid || option.valid()));

            this.deserialize = (newValue) => {
                const customValues = Array.isArray(newValue) ? newValue.filter((value) => typeof value === "string") : [];

                if (customValues.length) {
                    this.currentCustomValues(customValues);
                }
                value(newValue);
            };
        }
    },

    strain_official_name: class extends ListFilterItem {
        private readonly staticValues: StrainOfficialNameOption[];
        private readonly possibleValues: ko.PureComputed<StrainOfficialNameOption[]>;
        private readonly disable: ko.PureComputed<boolean>;
        private readonly selectemValue: ko.ObservableArray<StrainOfficialNameOption>;

        constructor(value: ko.Observable<string[]>, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = seed.possible_values?.map((v: { strain_official_name: string }) => v.strain_official_name);

            this.disable = ko.pureComputed(() => {
                // disable the official name when some custom strain is selected;
                // the official name is not known in advance therefore all official
                // names must be accepted
                const strainNameOrIdFilter = filter.allFilters().strain_name_or_id;

                // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                return strainNameOrIdFilter && strainNameOrIdFilter.model.selectemValue()?.some((strainOption: StrainOption) => {
                    // @ts-expect-error: types 'number' and 'string' have no overlap
                    return strainOption.strain_id === strainOption.strain_name;
                }) || false;
            });
            this.disable.subscribe((newValue) => {
                const strainNameOrIdFilter = filter.allFilters().strain_name_or_id;

                if (newValue) {
                    this.deserialize(undefined);
                } else if (strainNameOrIdFilter) {
                    // restore the selected items
                    // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                    strainNameOrIdFilter.model.selectemValue.valueHasMutated();
                }
            });
            this.selectemValue = ko.observableArray();
            this.selectemValue.subscribe((newValue) => {
                const strainNameOrIdFilter = filter.allFilters().strain_name_or_id;
                let newSelectedStrainIds;

                if (!this.disable() && strainNameOrIdFilter) {
                    // @ts-expect-error: Property 'possibleValues' does not exist on type 'ListFilterItem<any>'
                    newSelectedStrainIds = (strainNameOrIdFilter.model.possibleValues?.filter((strainOption: StrainOption) => {
                        const selectedByOfficialName = newValue?.some((officialNameOption) => {
                            return (!strainOption.strain_official_name && !officialNameOption.id) ||
                                    strainOption.strain_official_name === officialNameOption.id;
                        }) || false;
                        // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                        const selectedByNameWithId = strainNameOrIdFilter.model.selectemValue()?.some((strainNameOption: StrainOption) => {
                            return strainOption.strain_id === strainNameOption.strain_id;
                        }) || false;
                        // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                        const missingInStrainSelect = strainNameOrIdFilter.model.selectemValue()?.every((strainNameOption: StrainOption) => {
                            return (strainOption.strain_official_name !== strainNameOption.strain_official_name &&
                                        (strainOption.strain_official_name || strainNameOption.strain_official_name)) ||
                                    // @ts-expect-error: types 'number' and 'string' have no overlap
                                    strainNameOption.strain_id === strainNameOption.strain_name;
                        }) || false;

                        return selectedByOfficialName && (selectedByNameWithId || missingInStrainSelect);
                    // @ts-expect-error: strainNameOrIdFilter model needs to implement the selectemValue method
                    }) || []).concat(strainNameOrIdFilter.model.selectemValue()?.filter((strainOption: StrainOption) => {
                        // @ts-expect-error: types 'number' and 'string' have no overlap
                        return strainOption.strain_id === strainOption.strain_name;
                    }) || []).map((strainOption: StrainOption) => strainOption.strain_id);
                    strainNameOrIdFilter.model.deserialize(newSelectedStrainIds.length ? newSelectedStrainIds : undefined);
                }
            });
            this.staticValues = [{ id: null, strain_official_name: getTranslation("None") }];
            this.possibleValues = ko.pureComputed(() => {
                const strainNameOrIdFilter = filter.allFilters().strain_name_or_id;
                const possibleValues = <Array<StrainOfficialNameOption>> Object.values((strainNameOrIdFilter?.seed.possible_values?.filter((strainOption: StrainOption) => {
                    return strainOption.strain_official_name;
                }) || []).map((strainOption: StrainOption) => {
                    return {
                        id: strainOption.strain_official_name,
                        strain_official_name: strainOption.strain_official_name,
                    };
                }).reduce((uniqueStrainOptions: { [id: string]: StrainOfficialNameOption }, strainOption: StrainOfficialNameOption) => {
                    const uniqueKey = "id";

                    if (!Object.hasOwn(uniqueStrainOptions, strainOption[uniqueKey])) {
                        uniqueStrainOptions[strainOption[uniqueKey]] = strainOption;
                    }

                    return uniqueStrainOptions;
                }, {})).sort((a: StrainOfficialNameOption, b: StrainOfficialNameOption) => {
                    const nameA = a.strain_official_name.toLowerCase();
                    const nameB = b.strain_official_name.toLowerCase();

                    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                });

                return this.staticValues.concat(possibleValues);
            });
        }
    },

    quality_rating: class extends ListFilterItem {
        private readonly ratings: ko.ObservableArray<"very_poor" | "poor" | "fair" | "good" | "very_good">;

        constructor(value: ko.Observable<undefined>, seed: ListFilterDefinition) {
            super(value, seed);

            this.ratings = ko.observableArray();

            this.text = [].concat(...(seed.possible_values?.map((v: QualityProperties) => [v.name, v.label]) || []));
        }

        private ratingClicked = (name: "very_poor" | "poor" | "fair" | "good" | "very_good") => {
            if (this.ratings().includes(name)) {
                this.ratings.remove(name);
            } else {
                this.ratings.push(name);
            }
        };

        public serialize = () => this.ratings().length ? this.ratings() : undefined;

        public deserialize = (newValue: any) => {
            this.ratings(!newValue ? [] : Array.isArray(newValue) ? newValue : [newValue]);
        };
    },

    comment: ListFilterItem,

    comment_date_from: class extends ListFilterItem {
        private value: CheckExtended<ko.Observable<string>>;

        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_to"), compareFromDate),
            });
        }

        public valid = () => {
            return this.value.isValid();
        };
    },

    comment_date_to: class extends ListFilterItem {
        private value: CheckExtended<ko.Observable<string>>;

        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("comment_date_from"), compareToDate),
            });
        }

        public valid = () => {
            return this.value.isValid();
        };
    },

    reply_pending: ListFilterItem,

    sperm_donor_eartag: ListFilterItem,

    max_not_modified_days: class extends ListFilterItem {
        private value: CheckExtended<ko.Observable<string>>;

        constructor(value: ko.Observable<string>, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                invalid: (v) => !((_.isNumber(v) || _.isUndefined(v)) && (v || 0) >= 0),
            });
        }

        public valid = () => {
            return this.value.isValid();
        };
    },

    page_size: ListFilterItem,
});

class SpermList {
    private listView: ListView;
    private args: SpermListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: SpermListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: (filter) => SpermListFilters(filter, args),
                filterTemplate: filterTemplate,
                title: getTranslation("Sperm filter"),
            });
        });

        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("qs-button", this.showQuickSelect);

        this.listView.onMenuBoxClick("print-button", () => {
            printUrl(getUrl(window.location.href, {
                show_print: "true",
            }));
        });

        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({
                viewName: args.view_name,
                mode: "export",
                exportArgs: args.export_args,
            });
        });

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("sperm-import-button", () => {
            showSpermImport({
                reloadCallback: this.reload,
            });
        });

        // Table Body

        this.listView.onCellClick("td.cryotank_address a", (args) => {
            const htmlDecode = document.createElement("div");

            htmlDecode.innerHTML = args.element.dataset.cryotankPath;

            this.listView.highlightRow(args.rowId);

            showCryotankBrowser({
                closeCallback: () => this.listView.unHighlightRow(args.rowId),
                cryotankId: parseInt(args.element.dataset.cryotankId, 10),
                cryotankPath: JSON.parse(htmlDecode.textContent),
            });
        });

        this.listView.onCellClick("td.set_straw_label a", (args) => {
            showSetStrawLabel({
                cryotankContentId: parseInt(args.element.dataset.contentId, 10),
                eventTarget: args.element,
                reloadCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.state_label, td.volume", (args) => {
            this.showSpermDetails(args.rowId, parseInt(args.rowId, 10));
        });

        this.listView.onCellClick("td.sperm_donor_animals a, td.oocyte_donor_animals a", (args) => {
            this.showAnimalDetails(args.rowId, parseInt(args.element.dataset.animalId, 10));
        });

        this.listView.onCellClick("td.revitalization_embryos a", (args) => {
            mainMenu.openAndResetListFilter("get_embryo_list", {
                embryo_groupkey: args.element.dataset.groupkey,
            });
        });

        this.listView.onCellClick("td.comments.clickable", (args) => {
            showComments({
                origin: "sperm",
                subjects: { sperm_id: parseInt(args.rowId, 10) },
                onClose: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reSeedCallback: () => {
                    this.reload(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });
    }

    public reload = (rowId?: string) => {
        this.listView.reload({
            flashRowId: rowId,
        });
    };

    public showQuickSelect = () => {
        if (!this.listView.getSelectedRowIds().length) {
            notifications.showModal(getTranslation("No sperm selected"));
        } else {
            showSpermQuickselect({
                spermIds: this.listView.getSelectedRowIds()?.map((v) => parseInt(v, 10)) || [],
                reloadCallback: this.reload,
            });
        }
    };

    public showSpermDetails = (highlightRowId: string, spermId: number) => {
        this.listView.highlightRow(highlightRowId);

        showSpermDetails({
            closeCallback: () => this.listView.unHighlightRow(highlightRowId),
            reloadCallback: () => {
                this.reload(highlightRowId);
            },
            spermId: spermId,
        });
    };

    public showAnimalDetails = (highlightRowId: string, animalId: number) => {
        this.listView.highlightRow(highlightRowId);

        frames.openListDetailPopup(getUrl(cgiScript("mousedetail.py"), {
            animalid: animalId,
        }), () => this.listView.unHighlightRow(highlightRowId));
    };
}

export const initSpermList = (args: SpermListViewArguments): void => {
    new SpermList(document.querySelector("div.listview"), args);
};
