/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_executeQuickselectActions } from '../models/Body_executeQuickselectActions';
import type { Body_getQuickselectActionsSeed } from '../models/Body_getQuickselectActionsSeed';
import type { Body_importTanks } from '../models/Body_importTanks';
import type { CrossingDetailData } from '../models/CrossingDetailData';
import type { NameLabelProperty } from '../models/NameLabelProperty';
import type { TankDetails } from '../models/TankDetails';
import type { TankImportDialogData } from '../models/TankImportDialogData';
import type { TankQuickselectActionsResponse } from '../models/TankQuickselectActionsResponse';
import type { TankQuickselectActionsSeedResponse } from '../models/TankQuickselectActionsSeedResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TanksService {

    /**
     * Is Position Occupied
     * Check if given positions of a tank are occupied.
     *
     * Returns the set of positions that are already occupied.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static isPositionOccupied({
        rackId,
        status,
        tankPosition,
    }: {
        /**
         * Database ID of rack.
         */
        rackId: number,
        /**
         * Status of tank.
         */
        status: 'open' | 'closed',
        /**
         * Position inside the rack.
         */
        tankPosition?: Array<string>,
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tanks/is_position_occupied',
            query: {
                'rack_id': rackId,
                'status': status,
                'tank_position': tankPosition,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Quickselect
     * @returns TankQuickselectActionsSeedResponse Successful Response
     * @throws ApiError
     */
    public static getQuickselectActionsSeed({
        requestBody,
    }: {
        requestBody: Body_getQuickselectActionsSeed,
    }): CancelablePromise<TankQuickselectActionsSeedResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tanks/quickselect:get_actions_seed',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Quickselect
     * @returns TankQuickselectActionsResponse Successful Response
     * @throws ApiError
     */
    public static executeQuickselectActions({
        requestBody,
    }: {
        requestBody: Body_executeQuickselectActions,
    }): CancelablePromise<TankQuickselectActionsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tanks/quickselect:execute_actions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tank Details Endpoint
     * Get tank details.
     * @returns TankDetails Successful Response
     * @throws ApiError
     */
    public static getTankDetails({
        tankId,
        withNeighbors = true,
    }: {
        tankId: number,
        withNeighbors?: boolean,
    }): CancelablePromise<TankDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tanks/{tank_id}/details',
            path: {
                'tank_id': tankId,
            },
            query: {
                'with_neighbors': withNeighbors,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Age Levels Endpoint
     * Get age levels.
     * @returns NameLabelProperty Successful Response
     * @throws ApiError
     */
    public static getAgeLevels(): CancelablePromise<Array<NameLabelProperty>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tanks/age_levels',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Age Level Endpoint
     * Update tank age level.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateAgeLevel({
        tankId,
        ageLevel,
    }: {
        tankId: number,
        ageLevel: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tanks/{tank_id}/update_age_level',
            path: {
                'tank_id': tankId,
            },
            query: {
                'age_level': ageLevel,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Crossing Detail Data Endpoint
     * Get crossing detail data.
     * @returns CrossingDetailData Successful Response
     * @throws ApiError
     */
    public static getCrossingDetailData({
        crossingId,
    }: {
        crossingId: number,
    }): CancelablePromise<CrossingDetailData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tanks/crossings/{crossing_id}/details',
            path: {
                'crossing_id': crossingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Crossing Endpoint
     * Delete crossing.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCrossing({
        crossingId,
    }: {
        crossingId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tanks/crossings/{crossing_id}',
            path: {
                'crossing_id': crossingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Discard Crossing Endpoint
     * Discard crossing.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static discardCrossing({
        crossingId,
    }: {
        crossingId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tanks/crossings/{crossing_id}:discarded',
            path: {
                'crossing_id': crossingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Keep Crossing Endpoint
     * Keep crossing.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static keepCrossing({
        crossingId,
    }: {
        crossingId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tanks/crossings/{crossing_id}:discarded',
            path: {
                'crossing_id': crossingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Repeat Crossing Endpoint
     * Repeat crossing.
     *
     * Returns the id of the new crossing.
     * @returns number Successful Response
     * @throws ApiError
     */
    public static repeatCrossing({
        crossingId,
    }: {
        crossingId: number,
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tanks/crossings/{crossing_id}:repeat',
            path: {
                'crossing_id': crossingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Crossing Endpoint
     * Update crossing.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCrossing({
        crossingId,
        crossingTanks,
        responsibleId,
        strainId,
        classificationId,
        raisedTanks,
        recordDate,
        description,
        action,
    }: {
        crossingId: number,
        crossingTanks: number,
        responsibleId: number,
        strainId: number,
        classificationId?: (number | null),
        raisedTanks?: (number | null),
        recordDate?: (string | null),
        description?: (string | null),
        action?: ('discard' | 'set_up' | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tanks/crossings/{crossing_id}:update',
            path: {
                'crossing_id': crossingId,
            },
            query: {
                'crossing_tanks': crossingTanks,
                'responsible_id': responsibleId,
                'strain_id': strainId,
                'classification_id': classificationId,
                'raised_tanks': raisedTanks,
                'record_date': recordDate,
                'description': description,
                'action': action,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Print Crossing Label Endpoint
     * Print crossing label.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static printCrossingLabel({
        crossingId,
        offset,
        printer,
    }: {
        crossingId: number,
        offset: number,
        printer: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tanks/crossings/{crossing_id}/print_label',
            path: {
                'crossing_id': crossingId,
            },
            query: {
                'offset': offset,
                'printer': printer,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Label Endpoint
     * Get tank label.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getTankLabel({
        tankId,
    }: {
        tankId: number,
    }): CancelablePromise<(string | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tanks/{tank_id}/get_label',
            path: {
                'tank_id': tankId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Label Endpoint
     * Update tank label.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateTankLabel({
        tankId,
        label,
    }: {
        tankId: number,
        label: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tanks/{tank_id}/update_label',
            path: {
                'tank_id': tankId,
            },
            query: {
                'label': label,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tank Import Dialog Data Endpoint
     * Get tank import dialog data.
     * @returns TankImportDialogData Successful Response
     * @throws ApiError
     */
    public static getTankImportDialogData({
        crossingId,
    }: {
        crossingId?: (number | null),
    }): CancelablePromise<TankImportDialogData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tanks/get_tank_import_dialog_data',
            query: {
                'crossing_id': crossingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Tanks Endpoint
     * Import tanks.
     *
     * Returns list of imported tank ids (added to database).
     * @returns number Successful Response
     * @throws ApiError
     */
    public static importTanks({
        requestBody,
    }: {
        requestBody: Body_importTanks,
    }): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tanks/import_tanks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
