import { actionLog as actionLogInstance } from "./actionLog";
import * as framesUtils from "./frames";
import * as mainMenuUtils from "./mainMenu";
import { Notifications } from "./notifications";
import { ProcedureShortcuts } from "./procedureShortcuts";

export const frames: typeof framesUtils = window == window.top ? framesUtils : window.top.pyratFrontend.top.frames;
export const notifications: Notifications = window == window.top ? new Notifications() : window.top.pyratFrontend.top.notifications;
export const procedureShortcuts: ProcedureShortcuts = window == window.top ? new ProcedureShortcuts() : window.top.pyratFrontend.top.procedureShortcuts;
export const mainMenu: typeof mainMenuUtils = window == window.top ? mainMenuUtils : window.top.pyratFrontend.top.mainMenu;
export const actionLog: typeof actionLogInstance = window == window.top ? actionLogInstance : window.top.pyratFrontend.top.actionLog;
