import {
    Computed,
    ObservableArray,
    Observable,
} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";

import { CheckExtended } from "../knockout/extensions/invalid";
import { getTranslation } from "../lib/localize";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import {
    cgiScript,
    getUrl,
    getFormData,
    AjaxResponse,
} from "../lib/utils";

interface Catalog {
    id: number;
    name: string;
}

interface Species {
    id: number;
    name: string;
    weight_unit: string;
}

interface Strain {
    id: number;
    name_with_id: string;
    species_id: number;
}

interface Arguments {
    filter_catalogs: Catalog[];
    filter_catalog_id: number;
    catalogs: Catalog[];
    species: Species[];
    strains: Strain[];
    catalog_items: {
        id: number;
        catalog_id: number;
        catalog_name: string;
        product_type: "animal" | "additional";
        reference_number: string;
        species_id: number;
        species_name: string;
        species_weight_unit: string;
        strain_id: number;
        strain_name_with_id: string;
        description: string;
        sex: "m" | "f" | undefined;
        catalog_age_weight: string;
        import_age: number;
        import_weight: number;
        available: boolean;
        deletable: boolean;
    }[];
}

interface CatalogItem {
    id: Observable<number>;
    catalog_id: CheckExtended<Observable<number>>;
    product_type: Observable<"animal" | "additional">;
    reference_number: Observable<string>;
    species_id: CheckExtended<Observable<number>>;
    strain_id: CheckExtended<Observable<number>>;
    description: CheckExtended<Observable<string>>;
    sex: CheckExtended<Observable<"m" | "f" | undefined>>;
    catalog_age_weight: CheckExtended<Observable<string>>;
    import_age: CheckExtended<Observable<number>>;
    import_weight: CheckExtended<Observable<number>>;
    import_weight_unit: Computed<string>;
    available: Observable<boolean>;
    catalog_available: Observable<boolean>;
    deletable?: Observable<boolean>;
    changed?: Observable<boolean>;
    deleted?: Observable<boolean>;

    // dropdown lists individually for each row
    catalogs: Catalog[];
    species: Species[];
    strains: Computed<Strain[]>;
}

interface SubmitData {
    update_catalog_items: {
        id: number | undefined;
        catalog_id: number;
        product_type: "animal" | "additional";
        reference_number: string| undefined;
        species_id: number | undefined;
        description: string | undefined;
        strain_id: number | undefined;
        sex: "m" | "f" | undefined;
        catalog_age_weight: string | undefined;
        import_age: number;
        import_weight: number;
        available: boolean;
    }[];
}

class OrderRequestCatalogItemList {

    public seed: Arguments;
    public catalogItems: ObservableArray<CatalogItem>;
    public readonlyMode: boolean;
    public hideUnavailable: Observable<boolean>;
    public filterCatalogId: Observable<number>;
    public error: Observable<string>;
    public submitInProgress: Observable<boolean>;
    public canSubmit: Computed<boolean>;

    constructor(args: Arguments) {

        this.seed = args;

        this.catalogItems = ko.observableArray(_.map(args.catalog_items, (row) => {
            return this.getRowModel(row);
        }));
        this.readonlyMode = !session.userPermissions.order_request_catalog_update;
        this.hideUnavailable = ko.observable(true);
        this.filterCatalogId = ko.observable(this.seed.filter_catalog_id);
        this.error = ko.observable();
        this.submitInProgress = ko.observable(false);
        this.canSubmit = ko.computed(() => {
            const anyChange = _.some(this.catalogItems(), function(row) {
                return row.changed() || row.deleted();
            });
            const anyInvalid = _.some(this.catalogItems(), function(row) {
                return row.catalog_id.isInvalid()
                    || row.species_id.isInvalid()
                    || row.strain_id.isInvalid()
                    || row.sex.isInvalid()
                    || row.catalog_age_weight.isInvalid()
                    || row.import_age.isInvalid()
                    || row.import_weight.isInvalid()
                    || row.description.isInvalid();
            });

            return !this.submitInProgress()
                && !anyInvalid
                && anyChange;
        });
    }

    public highlightSaveButton = () => {
        const saveButton = document.getElementById("save_button");

        saveButton.classList.add("blink_twice");
        saveButton.addEventListener("animationend", function () {
            this.classList.remove("blink_twice");
        });
    };

    private getRowModel = (row?: Arguments["catalog_items"][0]) => {
        const rowModel: CatalogItem = {
            "id": ko.observable(),
            "catalogs": this.seed.catalogs,
            "catalog_id": ko.observable().extend({ invalid: (v) => { return !v; } }),
            "product_type": ko.observable(),
            "reference_number": ko.observable(),
            "species": this.seed.species,
            "species_id": ko.observable(),
            "strains": ko.pureComputed(() => {
                const strains: Strain[] = this.seed.strains;
                // add potential inactive/missing related strain
                if (row?.strain_id && !_.find(strains, { "id": row.strain_id })) {
                    strains.push({
                        "id": row.strain_id,
                        "species_id": row.species_id,
                        "name_with_id": row.strain_name_with_id,
                    });
                }

                if (rowModel.species_id()) {
                    // return strains according to the selected species
                    return strains.filter((strain) => { return rowModel.species_id() === strain.species_id; });
                }
                return strains;
            }),
            "strain_id": ko.observable(),
            "description": ko.observable(),
            "sex": ko.observable(),
            "catalog_age_weight": ko.observable(),
            "import_age": ko.observable(),
            "import_weight": ko.observable(),
            "import_weight_unit": ko.pureComputed(() => {
                return rowModel.species_id() ?
                    _.find(rowModel.species, { id: rowModel.species_id() }).weight_unit : "";
            }),
            "available": ko.observable(),
            "catalog_available": ko.observable(),
            "deletable": ko.observable(),
            "changed": ko.observable(false),
            "deleted": ko.observable(false),

        };

        rowModel.catalog_id.subscribe((catalogId) => {
            // catalog is available when it is found in the list of available catalogs
            rowModel.catalog_available(Boolean(this.seed.catalogs.find((catalog) => {
                return catalog.id === catalogId;
            })));
        });

        if (row) {
            // existing catalog item

            // add inactive (missing) values
            rowModel.catalogs = _.concat(rowModel.catalogs,
                (row.catalog_id && !_.find(rowModel.catalogs, { "id": row.catalog_id })) ?
                    [{ "id": row.catalog_id, "name": row.catalog_name }] : []);
            rowModel.species = _.concat(rowModel.species,
                (row.species_id && !_.find(rowModel.species, { "id": row.species_id })) ?
                    [{ "id": row.species_id, "name": row.species_name, "weight_unit": row.species_weight_unit }] : []);

            rowModel.id(row.id);
            rowModel.catalog_id(row.catalog_id);
            rowModel.product_type(row.product_type);
            rowModel.reference_number(row.reference_number);
            rowModel.species_id(row.species_id || undefined);
            rowModel.strain_id(row.strain_id || undefined);
            rowModel.description(row.description);
            rowModel.sex(row.sex || undefined);
            rowModel.catalog_age_weight(row.catalog_age_weight);
            rowModel.import_age(row.import_age);
            rowModel.import_weight(row.import_weight);
            rowModel.available(row.available);
            rowModel.deletable(row.deletable);

            const handleChange = () => {
                rowModel.changed(true);
                this.highlightSaveButton();
            };

            rowModel.catalog_id.subscribeOnce(handleChange);
            rowModel.product_type.subscribeOnce(handleChange);
            rowModel.reference_number.subscribeOnce(handleChange);
            rowModel.species_id.subscribeOnce(handleChange);
            rowModel.strain_id.subscribeOnce(handleChange);
            rowModel.description.subscribeOnce(handleChange);
            rowModel.sex.subscribeOnce(handleChange);
            rowModel.catalog_age_weight.subscribeOnce(handleChange);
            rowModel.import_age.subscribeOnce(handleChange);
            rowModel.import_weight.subscribeOnce(handleChange);
            rowModel.available.subscribeOnce(handleChange);

        } else {
            // new catalog item
            rowModel.available(true);
            rowModel.changed(true);
        }

        rowModel.species_id.extend({
            invalid: (v) => {
                return (rowModel.product_type() === "animal" && !v);
            },
        });

        rowModel.strain_id.extend({
            invalid: (v) => {
                return (rowModel.product_type() === "animal" && !v);
            },
        });

        rowModel.sex.extend({
            invalid: (v) => {
                return (rowModel.product_type() === "animal" && !v);
            },
        });

        rowModel.catalog_age_weight.extend({
            invalid: (v) => {
                return (rowModel.product_type() === "animal" && !v);
            },
        });

        rowModel.import_age.extend({
            invalid: (v) => {
                if (rowModel.product_type() === "animal") {
                    if (!rowModel.import_weight()) {
                        return !v;
                    }
                }
                return false;
            },
        });

        rowModel.import_weight.extend({
            invalid: (v) => {
                if (rowModel.product_type() === "animal") {
                    if (!rowModel.import_age()) {
                        return !v;
                    }
                }
                return false;
            },
        });

        rowModel.description.extend({
            invalid: (v) => {
                return (rowModel.product_type() === "additional" && !v);
            },
        });

        return rowModel;
    };

    public cancel = () => {
        location.href = getUrl(cgiScript("order_request_catalog_item_list.py"));
    };

    public showCatalogList = () => {
        location.href = getUrl(cgiScript("order_request_catalog_list.py"));
    };

    public showImportPage = () => {
        location.href = getUrl(cgiScript("order_request_catalog_import.py"));
    };

    public filterList = () => {
        location.href = getUrl(cgiScript("order_request_catalog_item_list.py"), { catalog_id: this.filterCatalogId() });
    };

    public addItem = () => {
        this.catalogItems.push(this.getRowModel());
        this.highlightSaveButton();
    };

    public removeItem = (item: CatalogItem) => {
        if (item.id() && !item.deleted()) {
            item.deleted(true);
            this.highlightSaveButton();
        } else if (item.id() && item.deleted()) {
            item.deleted(false);
        } else {
            this.catalogItems.remove(item);
        }
    };

    private getRequestData = () => {
        const requestData: SubmitData = {
            update_catalog_items: _.map(_.filter(this.catalogItems(), (row) => {
                return row.changed() || row.deleted();
            }), function (row) {
                return {
                    "id": row.id(),
                    "catalog_id": row.catalog_id(),
                    "product_type": row.product_type(),
                    "reference_number": row.reference_number() || undefined,
                    "species_id": row.species_id() || undefined,
                    "strain_id": row.strain_id() || undefined,
                    "description": row.description() || undefined,
                    "sex": row.sex() || undefined,
                    "catalog_age_weight": row.catalog_age_weight() || undefined,
                    "import_age": row.import_age() || undefined,
                    "import_weight": row.import_weight() || undefined,
                    "available": row.available(),
                    "changed": row.changed(),
                    "deleted": row.deleted(),
                };
            }),
        };
        return requestData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.error("");

        fetch(cgiScript("order_request_catalog_item_list.py"), {
            method: "POST",
            body: getFormData({
                request: JSON.stringify(this.getRequestData()),
            }),
        })
            .then((response) => response.json())
            .then((response: AjaxResponse<any>) => {
                if (response.success) {
                    window.location.reload();
                    notifications.showNotification(getTranslation("Changes saved"), "success");
                } else {
                    // validation errors
                    this.submitInProgress(false);
                    this.error(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.error(getTranslation("Action failed. The data could not be saved. Please try again."));
            });
    };

}


export const initOrderRequestCatalogItemList = (args: Arguments): void => {
    ko.applyBindings(new OrderRequestCatalogItemList(args));
};
