import {
    LocaleKey as flatpickrLocaleKey,
    localize as localizeFlatpickr,
} from "../lib/flatpickr";
import { frames } from "../lib/pyratTop";
import { logoWink } from "../lib/pyratTop/frames";
import SessionPoll from "../lib/sessionPoll";

interface Arguments {
    sessionTimeout: number;
    flatpickrLocale: flatpickrLocaleKey;
    flatpickrDateFormat: string;
    flatpickrFirstDayOfWeek: number;
}


export const initMainPage = (args: Arguments): void => {

    // If this is not loaded in the top frame, redirect it.
    if (window != window.top) {
        window.top.location.href = window.location.href;
    }

    new SessionPoll(args.sessionTimeout);

    // This is not nice, but this way to handle the detail popup,
    // as a single reused instance, should be removed eventually.
    // TODO: Remove any usage of the detailPopup Frame
    frames.initDetailPopup();

    setInterval(() => {
        const rnd = Math.round(Math.random() * 5000);
        setTimeout(logoWink, rnd);
    }, 6000);
    document.getElementById("pyrat_logo").addEventListener("mouseenter", logoWink);

    localizeFlatpickr(args.flatpickrLocale, args.flatpickrDateFormat, args.flatpickrFirstDayOfWeek);
};
