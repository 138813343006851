import * as ko from "knockout";
import {
    Observable,
    ObservableArray,
} from "knockout";

import { ListFilterDefinition } from "../../backend/v1";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    normalizeDate,
} from "../../lib/utils";

export const TankReleaseReportListFilterModel = (filter: ListFilterModel) => {

    // noinspection JSPotentiallyInvalidUsageOfThis
    return {
        origin_id: class extends ListFilterItem {
            public readonly possibleValues: {id: number; name: string}[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);
                this.text = seed.possible_values?.map((item: any) => item.name);
                this.possibleValues = [{ id: 0, name: getTranslation("None") }].concat(seed.possible_values);
            }
        },

        release_date_from: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("release_date_to"), compareFromDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        release_date_to: class extends ListFilterItem {
            private value: CheckExtended<Observable>;

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.value = value.extend({
                    normalize: normalizeDate,
                    invalid: (v) => checkDateRangeField(v, () => filter.getValue("release_date_from"), compareToDate),
                });
            }

            public valid = () => this.value.isValid();
        },

        strain_name_or_id: class extends ListFilterItem {
            private readonly currentCustomValues: ObservableArray;
            private selectemValue: ObservableArray;

            public readonly possibleValues: {id: number; name_with_id: string}[];

            constructor(value: Observable, seed: ListFilterDefinition) {
                super(value, seed);

                this.currentCustomValues = ko.observableArray();
                this.selectemValue = ko.observableArray();

                this.selectemValue.subscribe((newValue) => {
                    newValue.forEach((option) => {
                        if (option.id === option.name_with_id && option.id.indexOf("*") === -1) {
                            option.valid(false);
                        }
                    });
                });

                this.possibleValues = [{ id: 0, name_with_id: getTranslation("None") }].concat(seed.possible_values);

                this.text = ko.pureComputed(() => {
                    return seed.possible_values.map((item: any) => item.name_with_id);
                });

                this.valid = ko.pureComputed(() => {
                    return this.selectemValue().every((option) => {
                        return !option.valid || option.valid();
                    });
                });

                this.deserialize = (newValue) => {
                    const customValues = Array.isArray(newValue)
                        ? newValue.filter((value) => { return typeof value === "string"; })
                        : [];

                    if (customValues.length) {
                        this.currentCustomValues(customValues);
                    }
                    value(newValue);
                };
            }
        },
    };
};
