import {
    showComments,
    showOrderRequestDetails,
    showSetAnimalResponsible,
    showSetCageLabel,
    showSetCohortId,
    showSetEartag,
    showSetGeneration,
    showSetGeneticBackground,
    showSetLabID,
    showSetLicense,
    showSetMedicalConditions,
    showSetMutation,
    showSetProjects,
    showSetRFID,
    showSetSexMoveSacrifice,
    showSetStrain,
    showSetStudStatus,
    showSetWeight,
    showStudQuickselect,
} from "../../dialogs";
import {
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    SearchBox,
    SearchMode,
    setListFilter,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import { session } from "../../lib/pyratSession";
import {
    mainMenu,
    notifications,
} from "../../lib/pyratTop";
import { openListDetailPopup } from "../../lib/pyratTop/frames";
import {
    cgiScript,
    copyToClipboard,
    getUrl,
    printUrl,
} from "../../lib/utils";
import { pyratFrontend } from "../../pyratFrontend";

import { AnimalListFilters } from "./animalListFilter";
import filterTemplate from "./animalListFilter.html";


interface Arguments extends ListViewArguments {
    animal_id_to_show?: number;
    wr_class_id_after_weaning?: number;
    search_mode: SearchMode;
}

class AnimalList {
    private listView: ListView;
    private args: Arguments;
    private requireReload = false;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox buttons

        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: AnimalListFilters,
                filterTemplate: filterTemplate,
                title: args.view_name === "puplist" ? getTranslation("Pup filter") : getTranslation("Animal filter"),
            });
        });

        // TODO: move into listview.ts code?
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick("qs-button", this.showQuickSelect);

        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });

        this.listView.onMenuBoxClick("export-order-button", (event) => {
            const orderRequestId = parseInt((event.target as HTMLElement).getAttribute("order_request_id"), 10);
            this.showOrderExport(orderRequestId);
        });

        this.listView.onMenuBoxClick("copy-id-button", () => {
            copyToClipboard([...this.listView.touchSelect.selectedRows].map((row) => {
                return row.querySelector("td.eartag_or_id").textContent;
            }).join(", "));
        });

        // studs
        this.listView.onMenuBoxClick("stud-qs-button", this.showStudQuickSelect);

        // printing
        this.listView.onMenuBoxClick(["print-button", "pup-print-button", "stud-print-button"], () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });

        // Table Body

        // links to detail popups
        this.listView.onCellClick("td.eartag_or_id a", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                openListDetailPopup(getUrl(cgiScript("pupdetail.py"), { animalid: args.rowId.substring(1) }), () =>
                    this.listView.unHighlightRow(args.rowId),
                );
            } else {
                openListDetailPopup(getUrl(cgiScript("mousedetail.py"), { animalid: args.rowId }), () =>
                    this.listView.unHighlightRow(args.rowId),
                );
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.parents a", (args) => {
            this.showAnimalDetails(args.rowId, parseInt(args.element.getAttribute("parentid"), 10));
        });

        this.listView.onCellClick("td > a.animal-single-parent", (args) => {
            this.showAnimalDetails(args.rowId, parseInt(args.element.getAttribute("parent_id"), 10));
        });

        this.listView.onCellClick("td.cagenumber a", (args) => {
            this.showCageDetails(args.rowId, parseInt(args.element.getAttribute("cageid"), 10));
        });

        this.listView.onCellClick("td.wean_cage_number a", (args) => {
            this.showCageDetails(args.rowId, parseInt(args.element.getAttribute("cageid"), 10));
        });

        this.listView.onCellClick(".animal-single-request", (args) => {
            openListDetailPopup(
                getUrl(cgiScript("requestdetail.py"), { incidentid: args.element.getAttribute("incidentid") }),
                () => this.listView.unHighlightRow(args.rowId),
            );
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick(
            "td.requests a.animal-multiple-request, td.requests_long a.animal-multiple-request",
            (args) => {
                mainMenu.openAndResetListFilter("get_work_request_list", {
                    id: String(args.element.getAttribute("incidentids")).split(",").map((v) => parseInt(v, 10)),
                    status_id_or_unresolved: -1,
                });
            },
        );

        this.listView.onCellClick("td.last_order_request_id a", (args) => {
            pyratFrontend.dialogs.showOrderRequestDetails({
                orderRequestId: parseInt((args.event.target as HTMLAnchorElement).dataset.orderId, 10),
            });
        });

        // special summarybox order request link
        listViewElement
            .querySelector(".summarybox .export-with-order-request-id")
            ?.addEventListener("click", (event) => {
                showOrderRequestDetails({
                    orderRequestId: parseInt((event.target as HTMLElement).getAttribute("order_request_id"), 10),
                });
                return false;
            });

        // links to set_ popups

        this.listView.onCellClick("td.cagelabel.clickable", (args) => {
            const cageid = parseInt(args.element.querySelector("a").getAttribute("cageid"), 10);
            showSetCageLabel({
                cageId: cageid,
                eventTarget: args.element,
                title: args.title,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reloadAnimalList(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.cohort_id_icon a", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetCohortId({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetCohortId({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.comments.clickable, td.comments_with_event.clickable", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showComments({
                    origin: "pup",
                    subjects: { pup_id: [parseInt(args.rowId.substring(1), 10)] },
                    reSeedCallback: () => this.requireReload = true,
                    onClose: () => this.requireReload
                        ? this.reloadAnimalList(args.rowId)
                        : this.listView.unHighlightRow(args.rowId),
                });
            } else {
                showComments({
                    origin: "animal",
                    subjects: { animal_id: [parseInt(args.rowId, 10)] },
                    reSeedCallback: () => this.requireReload = true,
                    onClose: () => this.requireReload
                        ? this.reloadAnimalList(args.rowId)
                        : this.listView.unHighlightRow(args.rowId),
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.generation.clickable", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetGeneration({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetGeneration({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.gen_bg.clickable", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetGeneticBackground({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetGeneticBackground({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.labid.clickable, td.labid_icon a", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetLabID({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetLabID({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick(
            "td.classification.clickable, td.classification_description.clickable, td.licence_title.clickable, td.licence_number.clickable, td.licence_assign_history.clickable",
            (args) => {
                if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                    showSetLicense({
                        subjects: { pup_ids: [parseInt(args.rowId.substring(1), 10)] },
                        reloadCallback: () => {
                            this.reloadAnimalList(args.rowId);
                        },
                        closeCallback: () => {
                            this.listView.unHighlightRow(args.rowId);
                        },
                    });
                } else {
                    showSetLicense({
                        subjects: { animal_ids: [parseInt(args.rowId, 10)] },
                        reloadCallback: () => {
                            this.reloadAnimalList(args.rowId);
                        },
                        closeCallback: () => {
                            this.listView.unHighlightRow(args.rowId);
                        },
                    });
                }
                this.listView.highlightRow(args.rowId);
            },
        );

        this.listView.onCellClick(
            "td.medical_condition.clickable, td.medical_condition_with_event.clickable",
            (args) => {
                if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                    showSetMedicalConditions({
                        pupId: parseInt(args.rowId.substring(1), 10),
                        eventTarget: args.element,
                        title: args.title,
                        closeCallback: () => {
                            this.listView.unHighlightRow(args.rowId);
                        },
                        reloadCallback: () => {
                            this.reloadAnimalList(args.rowId);
                        },
                    });
                } else {
                    showSetMedicalConditions({
                        animalId: parseInt(args.rowId, 10),
                        eventTarget: args.element,
                        title: args.title,
                        closeCallback: () => {
                            this.listView.unHighlightRow(args.rowId);
                        },
                        reloadCallback: () => {
                            this.reloadAnimalList(args.rowId);
                        },
                    });
                }
                this.listView.highlightRow(args.rowId);
            },
        );

        this.listView.onCellClick("td.move a", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetSexMoveSacrifice({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetSexMoveSacrifice({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.mutation.clickable, td.show_add a", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetMutation({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetMutation({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.project.clickable", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetProjects({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    selectType: pyratFrontend.session.pyratConf.MULTIPLE_PROJECTS ? "multiple" : "single",
                    eventTarget: args.element,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetProjects({
                    animalId: parseInt(args.rowId, 10),
                    selectType: pyratFrontend.session.pyratConf.MULTIPLE_PROJECTS ? "multiple" : "single",
                    eventTarget: args.element,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.rename_icon a", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetEartag({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetEartag({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.responsible_fullname.clickable", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetAnimalResponsible({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetAnimalResponsible({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.rfid.clickable, td.rfid_icon a", (args) => {
            showSetRFID({
                animalId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reloadAnimalList(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick(
            "td.strain_name.clickable, td.strain_name_id.clickable, td.strain_name_with_id.clickable",
            (args) => {
                if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                    showSetStrain({
                        pupId: parseInt(args.rowId.substring(1), 10),
                        eventTarget: args.element,
                        title: args.title,
                        closeCallback: () => {
                            this.listView.unHighlightRow(args.rowId);
                        },
                        reloadCallback: () => {
                            this.reloadAnimalList(args.rowId);
                        },
                    });
                } else {
                    showSetStrain({
                        animalId: parseInt(args.rowId, 10),
                        eventTarget: args.element,
                        title: args.title,
                        closeCallback: () => {
                            this.listView.unHighlightRow(args.rowId);
                        },
                        reloadCallback: () => {
                            this.reloadAnimalList(args.rowId);
                        },
                    });
                }
                this.listView.highlightRow(args.rowId);
            },
        );

        this.listView.onCellClick("td.weight.clickable", (args) => {
            if (typeof args.rowId === "string" && args.rowId.indexOf("p") === 0) {
                showSetWeight({
                    pupId: parseInt(args.rowId.substring(1), 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            } else {
                showSetWeight({
                    animalId: parseInt(args.rowId, 10),
                    eventTarget: args.element,
                    title: args.title,
                    closeCallback: () => {
                        this.listView.unHighlightRow(args.rowId);
                    },
                    reloadCallback: () => {
                        this.reloadAnimalList(args.rowId);
                    },
                });
            }
            this.listView.highlightRow(args.rowId);
        });

        this.listView.onCellClick("td.stud_status_name.clickable", (args) => {
            showSetStudStatus({
                animalId: parseInt(args.rowId, 10),
                eventTarget: args.element,
                closeCallback: () => {
                    this.listView.unHighlightRow(args.rowId);
                },
                reloadCallback: () => {
                    this.reloadAnimalList(args.rowId);
                },
            });
            this.listView.highlightRow(args.rowId);
        });

        /* link to animal based pedigree */
        this.listView.onCellClick("td.pedigree_graph a", (args) => {
            mainMenu.open("get_colony_pedigree", {
                kind: "animal_pedigree",
                label: (args.event.target as HTMLElement).dataset.eartag,
            });
        });

        // initialize the RFID searchbox
        const rfidFilterElement = listViewElement.querySelector(".searchbox .rfid_search");
        if (rfidFilterElement && pyratFrontend.session.pyratConf.RFID_REGEX) {
            new SearchBox({
                element: rfidFilterElement.parentElement,
                searchCode: "rfid",
                onSubmitCallback: (value, searchMode) => {
                    setListFilter(
                        args.view_name,
                        {
                            rfid: value,
                        },
                        () =>
                            (window.location.href = getUrl(
                                window.location.href,
                                {
                                    page_start: 0,
                                    show_animal_detail: searchMode === "single" ? "true" : undefined,
                                },
                                {
                                    clearParams: true,
                                    clearHash: true,
                                },
                            )),
                    );
                },
            });
        }

        // initialize the barcode searchbox
        const barcodeFilterElement = listViewElement.querySelector(".searchbox .barcode_filter");
        if (barcodeFilterElement && pyratFrontend.session.pyratConf.BARCODE) {
            new SearchBox({
                element: barcodeFilterElement.parentElement,
                searchCode: "barcode",
                searchMode: args.search_mode,
                onSubmitCallback: (value) => {
                    setListFilter(args.view_name, {
                        cagenumber: value,
                    });
                },
            });
        }
    }

    public showQuickSelect = () => {
        if (!this.listView.getSelectedRowIds().length) {
            notifications.showModal(getTranslation("No animals selected."));
            return;
        }
        pyratFrontend.top.frames.openQuickSelect(cgiScript("quickselect_animal.py"), {
            sessionid: session.sessionId,
            animals: this.listView.getSelectedRowIdsString(),
        });
    };

    public showStudQuickSelect = () => {
        if (!this.listView.getSelectedRowIds().length) {
            notifications.showModal(getTranslation("No animals selected."));
            return;
        }
        showStudQuickselect({
            actions: [], // means all actions
            animalIds: this.listView.getSelectedRowIds().map((v) => parseInt(v, 10)),
            reloadCallback: () => this.reloadAnimalList(),
        });
    };

    public showOrderExport = (orderid: number) => {
        openListDetailPopup(
            getUrl(cgiScript("order_export.py"), {
                animals: this.listView.getSelectedRowIdsString(),
                request_id: orderid,
            }),
        );
    };

    public showPupDetails = (highlightRowId: string, detailPupIdD: number) => {
        this.listView.highlightRow("p" + highlightRowId);
        openListDetailPopup(getUrl(cgiScript("pupdetail.py"), { animalid: detailPupIdD }), () =>
            this.listView.unHighlightRow(highlightRowId),
        );
    };

    public showAnimalDetails = (highlightRowId: string, openAnimalId: number) => {
        this.listView.highlightRow(highlightRowId);
        openListDetailPopup(getUrl(cgiScript("mousedetail.py"), { animalid: openAnimalId }), () =>
            this.listView.unHighlightRow(highlightRowId),
        );
    };

    public showCageDetails = (highlightRow: string, cageId: number) => {
        this.listView.highlightRow(highlightRow);
        openListDetailPopup(getUrl(cgiScript("cagedetail.py"), { cageid: cageId }), () =>
            this.listView.unHighlightRow(highlightRow),
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public reloadAnimalList = (rowId?: string) => {
        this.listView.reload({ flashRowId: rowId });
    };
}

export const initAnimalList = (args: Arguments): void => {
    const animalList = new AnimalList(document.querySelector("div.listview"), args);

    // @ts-expect-error: required by procedure shortcuts
    window.animalList = animalList;

    if (args.animal_id_to_show) {
        // open the animal details if a single conf.RFID_REGEX was requested
        animalList.showAnimalDetails(String(args.animal_id_to_show), args.animal_id_to_show);
    }

    // open work request after weaning
    if (args.wr_class_id_after_weaning) {
        openListDetailPopup(
            getUrl(cgiScript("new_request.py"), { preselect_wr_class_id: args.wr_class_id_after_weaning }),
            () => mainMenu.openAndSetListFilter("get_animal_list", {
                update_filter: 1,
                wr_class_id_after_weaning: null,
            }),
        );
    }
};
