import { Observable } from "knockout";
import * as _ from "lodash";

import { ListFilterDefinition } from "../../backend/v1";
import { showCompetenceDetails } from "../../dialogs";
import { CheckExtended } from "../../knockout/extensions/invalid";
import {
    ListFilterItem,
    ListFilterModel,
    ListView,
    ListViewArguments,
    OrderBy,
    resetListFilter,
    showColumnSelect,
    showListFilter,
} from "../../lib/listView";
import { getTranslation } from "../../lib/localize";
import {
    checkDateRangeField,
    compareFromDate,
    compareToDate,
    getUrl,
    normalizeDate,
    printUrl,
} from "../../lib/utils";

import filterTemplate from "./trainingRecordListFilter.html";


interface Arguments extends ListViewArguments {
    export_args: any;
}

// noinspection JSPotentiallyInvalidUsageOfThis
const ListFilters = (filter: ListFilterModel) => ({

    competence_name_like: ListFilterItem,

    competent_user_ids: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "fullname");
        }
    },

    competent_to_train_user_ids: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "fullname");
        }
    },

    permitted_procedure_ids: class extends ListFilterItem {
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);
            this.text = _.map(seed.possible_values, "name");
        }
    },

    applicable_species: class extends ListFilterItem {
        private staticValues: { name: string; id: number }[];
        private possibleValues: { name: string; id: number }[];
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.text = _.map(seed.possible_values, "name");
            this.staticValues = [{ id: 0, name: getTranslation("None") }];
            this.possibleValues = this.staticValues.concat(seed.possible_values);
        }
    },

    min_expiration_date: class extends ListFilterItem {
        private value: CheckExtended<Observable>;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("max_expiration_date"), compareFromDate),
            });

        }
        public valid = () => this.value.isValid();
    },

    max_expiration_date: class extends ListFilterItem {
        private value: CheckExtended<Observable>;
        constructor(value: Observable, seed: ListFilterDefinition) {
            super(value, seed);

            this.value = value.extend({
                normalize: normalizeDate,
                invalid: (v) => checkDateRangeField(v, () => filter.getValue("min_expiration_date"), compareToDate),
            });

        }
        public valid = () => this.value.isValid();
    },

    page_size: ListFilterItem,
});

class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: Arguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        // MenuBox actions
        this.listView.onMenuBoxClick("list-filter-button", () => {
            showListFilter({
                viewName: args.view_name,
                filterModels: ListFilters,
                filterTemplate: filterTemplate,
                title: getTranslation("Training records filter"),
            });
        });
        this.listView.onMenuBoxClick("apply-filter-preset", this.listView.applyFilterPreset);

        this.listView.onMenuBoxClick(["print-button", "pup-print-button", "stud-print-button"], () => {
            printUrl(getUrl(window.location.href, { show_print: "true" }));
        });
        this.listView.onMenuBoxClick("remove-filter-button", () => {
            resetListFilter(args.view_name);
        });
        this.listView.onMenuBoxClick("export-to-excel", () => {
            showColumnSelect({ viewName: args.view_name, mode: "export", exportArgs: args.export_args });
        });

        // Custom actions
        this.listView.onMenuBoxClick("add-button", () => {
            showCompetenceDetails({
                competenceId: null,
                reloadCallback: this.listView.reload,
            });
        });
        this.listView.onCellClick("td.competence_name.clickable", (args) => {
            const competenceId = parseInt(args.rowId, 10);
            if (competenceId) {
                showCompetenceDetails({
                    competenceId: competenceId,
                    closeCallback: () => this.listView.unHighlightRow(args.rowId),
                    reloadCallback: () => this.listView.reload({ flashRowId: args.rowId }),

                });
                this.listView.highlightRow(args.rowId);
            }
        });

    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public reload = (rowId?: string) => {
        this.listView.reload({ flashRowId: rowId });
    };
}

export const initTrainingRecordList = (args: Arguments): void => {
    new List(document.querySelector("div.listview"), args);
};
