/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdNameProperty } from '../models/IdNameProperty';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OriginsService {

    /**
     * Get Origins For Setting
     * Get the list of origins for setting an origin.
     * @returns IdNameProperty Successful Response
     * @throws ApiError
     */
    public static getOriginsForSetting(): CancelablePromise<Array<IdNameProperty>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/origins/for_setting',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deactivate Origin Endpoint
     * Deactivate an origin.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deactivateOrigin({
        originId,
    }: {
        originId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/origins/{origin_id}/active',
            path: {
                'origin_id': originId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Activate Origin Endpoint
     * Activate an origin.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static activateOrigin({
        originId,
    }: {
        originId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/origins/{origin_id}/active',
            path: {
                'origin_id': originId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Origin Endpoint
     * Delete an origin.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteOrigin({
        originId,
    }: {
        originId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/origins/{origin_id}',
            path: {
                'origin_id': originId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
