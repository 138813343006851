import "./apiClientLlist.scss";
import { ApiService } from "../../backend/v1";
import { writeException } from "../../lib/excepthook";
import {
    ListView,
    ListViewArguments,
    OrderBy,
} from "../../lib/listView";
import {
    centeredPopup,
    getUrl,
} from "../../lib/utils";


class List {
    private listView: ListView;
    private args: ListViewArguments;

    constructor(listViewElement: HTMLDivElement, args: ListViewArguments) {
        this.args = args;

        this.listView = new ListView(
            listViewElement,
            args.view_name,
            new OrderBy(args.current_order, args.default_order_column),
        );

        /* Row actions */

        this.listView.onCellClick("tr.client_row td.status.enabled_row.clickable",  (args) => {
            ApiService.disableClient({ clientId: parseInt(args.rowId, 10) }).then(() => {
                this.listView.reload();
            });
        });

        this.listView.onCellClick("tr.client_row td.status.disabled_row.clickable",  (args) => {
            ApiService.enableClient({ clientId: parseInt(args.rowId, 10) }).then(() => {
                this.listView.reload();
            });
        });

        this.listView.onCellClick("tr.user_row td.status.enabled_row.clickable",  (args) => {
            ApiService.disableUser({ userId: parseInt(args.rowId, 10) }).then(() => {
                this.listView.reload();
            });
        });

        this.listView.onCellClick("tr.user_row td.status.disabled_row.clickable",  (args) => {
            ApiService.enableUser({ userId: parseInt(args.rowId, 10) }).then(() => {
                this.listView.reload();
            });
        });

        this.listView.onCellClick("tr.client_row td.user_action input[name=request_access]",  (args) => {
            centeredPopup(getUrl("token", { api_client_id: args.rowId }), "_blank", 600, 800);
        });

        this.listView.onCellClick("tr.user_row td.user_action input[name=delete]",  (args) => {
            args.element.classList.add("loading");
            ApiService.deleteUser({ userId: parseInt(args.rowId, 10) }).then(() => {
                this.listView.reload();
            }).catch((reason) => {
                writeException(reason);
            }).finally(() => {
                args.element.classList.remove("loading");
            });
        });

    }
}

export const initApiClientList = (args: ListViewArguments): void => {
    new List(document.querySelector("div.listview"), args);
};
