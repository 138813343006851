/**
 * Show a pop-up to set lab ID for given subject (id).
 *
 * @param pupId: Database ID of the pup for which to set lab ID.
 +
 * @param animalId: Database ID of the animal for which to set lab ID.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {
    Observable,
    PureComputed,
} from "knockout";

import {
    GetAnimalLabIdResponse,
    AnimalsService,
    CancelablePromise,
    PupsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import { notifications } from "../lib/pyratTop";
import { EmptyObject } from "../lib/utils";

import template from "./setLabID.html";

interface Params {
    pupId?: number;
    animalId?: number;
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetLabIDViewModel {
    public readonly allowEdit: boolean;
    public readonly canApply: PureComputed<boolean>;
    public readonly seed: Observable<GetAnimalLabIdResponse | undefined> = ko.observable();

    private readonly pupId: number;
    private readonly animalId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;
    private readonly dialog: HtmlDialog;
    private readonly fetchInProgress = ko.observable(true);
    private readonly applyInProgress = ko.observable(false);
    private readonly reloadRequired = ko.observable(false);
    private readonly labId: CheckExtended<Observable<string>>;


    constructor(dialog: HtmlDialog, params: Params) {
        this.dialog = dialog;

        this.pupId = params.pupId;
        this.animalId = params.animalId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.allowEdit = session.userPermissions.animal_set_labid
            && Boolean(this.pupId || this.animalId);

        this.labId = ko.observable().extend({
            invalid: (value) => {
                return !!value && !value.match(new RegExp("^[^,]{0,80}$"))
                    ? getTranslation("The lab id may consist of up to 80 characters and not contain a comma.")
                    : false;
            },
        });

        let request: CancelablePromise<GetAnimalLabIdResponse>;
        if (this.animalId) {
            request = AnimalsService.getAnimalLabId({ animalId: this.animalId });
        } else if (this.pupId) {
            request = PupsService.getPupLabId({ pupId: this.pupId });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then((response) => {
                this.seed(response);
                this.labId(response.lab_id);
                this.dialog.setTitle(
                    getTranslation("Set lab ID for %s").replace("%s", String(response.eartag_or_id)),
                );
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.fetchInProgress(false));

        this.canApply = ko.pureComputed(() => {
            return !this.applyInProgress()
                && !this.fetchInProgress()
                && this.labId.isValid()
                && this.labId() !== this.seed()?.lab_id;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadRequired() && typeof this.reloadCallback === "function") {
                this.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);

        let request: CancelablePromise<EmptyObject>;
        if (this.animalId) {
            request = AnimalsService.setAnimalLabId({
                animalId: this.animalId,
                labId: this.labId(),
            });
        } else if (this.pupId) {
            request = PupsService.setPupLabId({
                pupId: this.pupId,
                labId: this.labId(),
            });
        } else {
            throw new Error("Invalid parameters.");
        }

        request
            .then(() => {
                notifications.showNotification(getTranslation("Lab ID updated"), "success");
                this.dialog.close();
            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    notifications.showNotification(reason.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("General error."), "error");
                    writeException(reason);
                }
            })
            .finally(() => this.applyInProgress(false));
    };
}

export const showSetLabID = htmlDialogStarter(SetLabIDViewModel, template, params => ({
    name: "SetLabID",
    width: 300,
    title: getTranslation("Set lab ID"),
    position: { anchor: params.eventTarget },
}));
