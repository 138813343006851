import * as ko from "knockout";
import * as _ from "lodash";

import {
    CommentWidgetSeed,
    GetProjectDetailsResponse,
    ProjectsService,
} from "../backend/v1";
import { dialogStarter } from "../knockout/dialogStarter";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    frames,
    notifications,
} from "../lib/pyratTop";
import {
    cgiScript,
    getUrl,
    isInvalidCalendarDate,
} from "../lib/utils";

import template from "./projectDetails.html";
import createBudgetTemplate from "./projectDetailsCreateBudget.html";


interface Params {
    projectId: number | null;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Budget {
    id: number;
    name: string;
}


class CreateBudgetModel {

    public projectDetails;
    public dialog;
    public newBudgetName: ko.Observable<string>;

    constructor(projectDetails: ProjectDetailsViewModel, dialog: KnockoutPopup) {
        this.projectDetails = projectDetails;
        this.dialog = dialog;
        this.newBudgetName = ko.observable().extend({
            trim: true,
            invalid: function(v) {
                return !v;
            },
        });
    }

    public createBudget = () => {
        this.projectDetails.applyInProgress(true);
        ProjectsService.createProjectBudget({ name: this.newBudgetName() })
            .then(response => {
                this.projectDetails.budgets(response.budgets || undefined);
                this.projectDetails.budgetId(response.budget_id || undefined);
                this.dialog.close();
            })
            .catch((error) => {
                if (typeof error?.body?.detail == "string") {
                    notifications.showNotification(error.body.detail, "error");
                } else {
                    notifications.showNotification(getTranslation("An error occurred. Please try again."), "error");
                    writeException(error);
                }

            })
            .finally(() => {
                this.projectDetails.applyInProgress(false);
            });
    };
}

const showCreateBudget = dialogStarter(CreateBudgetModel, createBudgetTemplate, {
    title: getTranslation("Create new budget"),
    width: 360,
    height: "auto",
    modal: true,
    anchor: {
        top: 210,
        right: 40,
        left: undefined,
        bottom: undefined,
    },
});

class ProjectDetailsViewModel {

    private readonly params;
    private readonly dialog;
    private readonly createAllowed: boolean;
    private readonly updateAllowed: boolean;

    private readonly seed: ko.Observable<GetProjectDetailsResponse>;
    private readonly seedInProgress = ko.observable(false);
    private readonly projectName: CheckExtended<ko.Observable<string>>;
    private readonly projectLeaderId: CheckExtended<ko.Observable<number>>;
    private readonly categoryId: ko.Observable<number>;
    private readonly expirationDate: CheckExtended<ko.Observable<string>>;
    private readonly description: ko.Observable<string>;

    public budgets: ko.ObservableArray<Budget>;
    public budgetId: ko.Observable<number>;
    public showCreateBudget: () => void;

    private readonly permittedUsers: ko.Computed<any[]>;
    private readonly isPermittedToAll: ko.Computed<boolean>;
    private readonly newPermittedUserId: ko.Observable<number>;
    private readonly otherUsers: ko.Computed<any[]>;
    private readonly commentWidgetSeed: ko.Observable<CommentWidgetSeed>;

    public  readonly applyInProgress: ko.Observable<boolean>;
    private readonly selectedTab: ko.Observable<"permissions" | "comments" | "documents">;
    private readonly setPermissionInProgress: ko.Observable<boolean>;
    private readonly errors: ko.ObservableArray<string>;
    private readonly reloadRequired: ko.Observable<boolean>;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.params = params;
        this.dialog = dialog;

        this.createAllowed = session.userPermissions.project_create;
        this.updateAllowed = session.userPermissions.project_update;

        if (params.projectId) {
            dialog.setTitle(getTranslation("Project details"));
        } else {
            dialog.setTitle(getTranslation("Create new project"));
        }

        dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        /* initial data */
        this.seed = ko.observable();
        this.refreshSeed();

        /* content */
        this.projectName = ko.observable().extend({
            trim: true,
            invalid: function(v) {
                return !v;
            },
        });

        this.projectLeaderId = ko.observable().extend({
            invalid: function (v) { return _.isUndefined(v); },
        });

        this.categoryId = ko.observable();

        this.expirationDate = ko.observable().extend({
            invalid: function (v) { return v ? isInvalidCalendarDate(v): false; },
        });

        this.budgets = ko.observableArray();
        this.budgetId = ko.observable();
        this.showCreateBudget = _.partial(showCreateBudget, this);

        this.description = ko.observable();

        this.permittedUsers = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed && "id" in seed.project_details) {
                return _.filter(seed.project_details.permitted_users, (ownerData) => {
                    // do not show project leader in the list
                    return ownerData.user_id !== this.projectLeaderId();
                });
            }
            return [];
        });
        this.isPermittedToAll = ko.pureComputed(() => {
            return _.some(this.permittedUsers(), function (ownerData) {
                return ownerData.user_id === 0;
            });
        });

        this.newPermittedUserId = ko.observable();
        this.otherUsers = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed) {
                return [{
                    userid: 0,
                    fullname: getTranslation("All"),
                }].concat(_.filter(seed.owners, (ownerData) => {
                    // offer all users that are not yet permitted users and who are not the project leader
                    return ownerData.userid !== this.projectLeaderId() &&
                            _.every(this.permittedUsers(), function (u) { return ownerData.userid !== u.user_id; });
                }));
            }
            return [];
        });

        this.commentWidgetSeed = ko.observable();

        this.seed.subscribeOnce((seed: GetProjectDetailsResponse) => {
            if (seed) {
                setTimeout(() => {
                    this.budgets(seed.budgets || []);
                    this.projectLeaderId(seed.project_details.owner_id);

                    if (this.params.projectId && "id" in seed.project_details) {
                        this.projectName(seed.project_details.name);
                        this.categoryId(seed.project_details.category_id);
                        this.expirationDate(seed.project_details.expiration_date);
                        this.budgetId(seed.project_details.budget_id);
                        this.description(seed.project_details.description);
                        this.commentWidgetSeed(seed.comment_widget_seed || undefined);
                    }
                }, 0);
            }
        });

        /* internals */
        this.selectedTab = ko.observable("permissions");
        this.applyInProgress = ko.observable(false);
        this.setPermissionInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
        this.reloadRequired = ko.observable(false);
    }

    private reseedComments = (seed: CommentWidgetSeed) => {
        this.commentWidgetSeed(seed);
        this.reloadRequired(true);
    };

    private showStrainDetail = (strainId: number) => {
        frames.detailPopup.open(getUrl(
            cgiScript("edit_strain.py"), { strainid: strainId }, { absoluteUrl: true }),
        );
    };

    private canEdit = () => {
        if (!this.params.projectId && this.createAllowed) {
            return true;
        } else if (this.params.projectId && this.updateAllowed) {
            return true;
        }
        return false;
    };

    private canSubmit = ko.pureComputed(() => {
        if (!this.canEdit()) {
            return false;
        } else if (this.applyInProgress()) {
            return false;
        } else if (this.projectName.isInvalid() ||
                   this.projectLeaderId.isInvalid() ||
                   this.expirationDate.isInvalid()) {
            return false;
        }
        return true;
    });

    private refreshSeed = () => {
        this.seedInProgress(true);
        ProjectsService.getProjectDetails({ projectId: this.params.projectId })
            .then((response) => this.seed(response))
            .finally(() => this.seedInProgress(false));
    };

    private cancel = () => {
        this.dialog.close();
    };

    private createProject = () => {
        this.applyInProgress(true);
        this.errors([]);
        ProjectsService.createProject({
            name: this.projectName(),
            ownerId: this.projectLeaderId(),
            categoryId: this.categoryId(),
            expirationDate: this.expirationDate() || null,
            budgetId: this.budgetId(),
            description: this.description(),
        })
            .then(() => {
                // close popup and show success message
                this.reloadRequired(true);
                this.dialog.close();
                notifications.showNotification(getTranslation("Project created"), "success");
            })
            .catch((error) => {
                if (typeof error?.body?.detail == "string") {
                    this.errors.push(error.body.detail);
                } else {
                    this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again."));
                    writeException(error);
                }
            })
            .finally(() => this.applyInProgress(false));
    };

    private updateProject = () => {
        this.applyInProgress(true);
        this.errors([]);
        ProjectsService.updateProject({
            projectId: this.params.projectId,
            name: this.projectName(),
            ownerId: this.projectLeaderId(),
            categoryId: this.categoryId(),
            expirationDate: this.expirationDate() || null,
            budgetId: this.budgetId(),
            description: this.description(),
        })
            .then(() => {
                // reload popup and show success message
                this.reloadRequired(true);
                this.refreshSeed();
                notifications.showNotification(getTranslation("Project updated"), "success");
            })
            .catch((error) => {
                if (typeof error?.body?.detail == "string") {
                    this.errors.push(error.body.detail);
                } else {
                    this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again."));
                    writeException(error);
                }
            })
            .finally(() => this.applyInProgress(false));
    };

    private setPermission = (data: {add_user_id: number} | {delete_user_id: number}) => {
        this.setPermissionInProgress(true);
        this.errors([]);

        if ("add_user_id" in data) {
            ProjectsService.addUserToProject({ projectId: this.params.projectId, userId: data.add_user_id })
                .then(() => {
                    this.reloadRequired(true);
                    this.refreshSeed();
                    notifications.showNotification(getTranslation("Permission added"), "success");
                })
                .catch((error) => {
                    if (typeof error?.body?.detail == "string") {
                        this.errors.push(error.body.detail);
                    } else {
                        this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again."));
                        writeException(error);
                    }
                })
                .finally(() => this.setPermissionInProgress(false));
        }
        else if ("delete_user_id" in data) {
            ProjectsService.removeUserFromProject({ projectId: this.params.projectId, userId: data.delete_user_id })
                .then(() => {
                    this.reloadRequired(true);
                    this.refreshSeed();
                    notifications.showNotification(getTranslation("Permission revoked"), "success");
                })
                .catch((error) => {
                    if (typeof error?.body?.detail == "string") {
                        this.errors.push(error.body.detail);
                    } else {
                        this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again."));
                        writeException(error);
                    }
                })
                .finally(() => this.setPermissionInProgress(false));
        } else {
            throw new Error("Invalid data for setPermission");
        }
    };

    /**
     * Set requirement for reloading the list frame after detail popup was closed
     */
    public setReloadRequired = () => {
        this.reloadRequired(true);
    };
}

export const showProjectDetails = dialogStarter(ProjectDetailsViewModel, template, {
    name: "ProjectDetails",
    width: 590,
    anchor: { top: 20, right: 20 },
    closeOthers: true,
});
