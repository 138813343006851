import {
    Observable,
    observable,
    ObservableArray,
    observableArray,
    PureComputed,
    pureComputed,
} from "knockout";

import {
    IndependentDocumentDetails,
    DocumentsService,
} from "../backend/v1";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { writeException } from "../lib/excepthook";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import {
    baseUrl,
    getUrl,
} from "../lib/utils";

import licenseDetailTemplate from "./independentDocumentDetails.html";

interface Params {
    documentId: number | null;
}

class IndependentDocumentViewModel {
    dialog: HtmlDialog;
    documentId: number;
    errorMessage: Observable<string> = observable();
    newDocumentIds: ObservableArray<number> = observableArray();
    documentsPendingAction: Observable<boolean> = observable();
    documentLabel: Observable<string> = observable("");
    documentReference: Observable<string> = observable("");
    documentData: Observable<IndependentDocumentDetails> = observable();
    documentHref: PureComputed<string>;
    dataChanged: PureComputed<boolean>;
    canApply: PureComputed<boolean>;

    constructor(dialog: HtmlDialog, { documentId }: Params) {
        this.dialog = dialog;
        this.documentId = documentId;
        if (documentId) {
            dialog.setTitle(getTranslation("Edit document"));
            // dialog.setTitle(getTranslation("Edit document") + ": " + this.documentData().name);
            DocumentsService.getIndependentDocumentDetails({ documentId: documentId }).then((response) => {
                this.documentData(response);
                this.documentLabel(response.label || "");
                this.documentReference(response.reference || "");
            });
        } else {
            dialog.setTitle(getTranslation("Add document"));
        }

        this.documentHref = pureComputed(() => {
            if (documentId) {
                return getUrl(baseUrl(`frontend/documents/${documentId}`));
            }
        });
        this.dataChanged = pureComputed(() => {
            if (this.newDocumentIds().length > 0) return true;
            if (this.documentLabel() != (this.documentData()?.label || "")) return true;
            if (this.documentReference() != (this.documentData()?.reference || "")) return true;
            return false;
        });
        this.canApply = pureComputed(() => {
            return (
                !this.documentsPendingAction() &&
                this.dataChanged() &&
                !!(documentId || this.newDocumentIds().length > 0)
            );
        });
    }
    public applyChanges = () => {
        this.errorMessage(undefined);
        this.documentsPendingAction(true);
        if (this.documentId) {
            DocumentsService.updateIndependentDocument({
                documentId: this.documentId,
                requestBody: {
                    label: this.documentLabel(),
                    reference: this.documentReference(),
                },
            })
                .then(() => {
                    this.dialog.close();
                    location.reload();
                })
                .catch((reason) => {
                    if (reason?.body?.detail) {
                        this.errorMessage(reason.body.detail);
                    } else {
                        writeException(reason);
                    }
                })
                .finally(() => {
                    this.documentsPendingAction(false);
                });
        } else {
            DocumentsService.addIndependentDocument({
                requestBody: {
                    document_ids: this.newDocumentIds(),
                    label: this.documentLabel(),
                    reference: this.documentReference(),
                },
            })
                .then(() => {
                    this.dialog.close();
                    location.reload();
                })
                .catch((reason) => {
                    if (reason?.body?.detail) {
                        this.errorMessage(reason.body.detail);
                    } else {
                        writeException(reason);
                    }
                })
                .finally(() => {
                    this.documentsPendingAction(false);
                });
        }
    };
}

export const showIndependentDocumentDetails = htmlDialogStarter(IndependentDocumentViewModel, licenseDetailTemplate, {
    name: "IndependentDocumentDetails",
    width: 500,
    height: "auto",
    position: {
        inset: {
            top: 20,
            right: 20,
        },
    },
    closeOthers: true,
});
