import * as ko from "knockout";

import { CryotanksService } from "../backend/v1";
import { dialogStarter } from "../knockout/dialogStarter";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { notifications } from "../lib/pyratTop";


interface Seed {
    label: string;
}

class SetStrawLabelModel {

    private readonly dialog: KnockoutPopup;
    private readonly cryotankContentId: number;
    private readonly reloadCallback: () => void;

    public readonly strawLabel: ko.Observable<string>;
    public readonly fetchInProgress: ko.Observable<boolean>;
    public readonly updateInProgress: ko.Observable<boolean>;
    public readonly seed: ko.Observable<Seed>;

    constructor(params: { cryotankContentId: number; eventTarget?: HTMLElement; reloadCallback: () => void }, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.reloadCallback = params.reloadCallback;
        this.cryotankContentId = params.cryotankContentId;
        this.strawLabel = ko.observable("");
        this.fetchInProgress = ko.observable(true);
        this.updateInProgress = ko.observable(false);

        CryotanksService.getStrawLabel({
            cryotankContentId: this.cryotankContentId,
        }).then((response) => this.strawLabel(response)).catch((response) => {
            if (typeof response.body?.detail === "string") {
                notifications.showNotification(response.body.detail, "error");
            } else {
                notifications.showNotification(getTranslation("Error while loading the data. Please try again."), "error");
            }
        }).finally(() => this.fetchInProgress(false));
    }

    public applyLabel = () => {
        this.updateInProgress(true);
        CryotanksService.setStrawLabel({
            cryotankContentId: this.cryotankContentId,
            strawLabel: this.strawLabel(),
        }).then(() => {
            this.dialog.close();
            if (this.reloadCallback) {
                this.reloadCallback();
            }
        }).catch((response) => {
            if (typeof response.body?.detail === "string") {
                notifications.showNotification(response.body.detail, "error");
            } else {
                notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
            }
        }).finally(() => this.updateInProgress(false));
    };

}

export const showSetStrawLabel = dialogStarter(SetStrawLabelModel, `
    <div class="set_straw_label_popup">
        <form class="flexible_container" style="margin: 10px;">
            <input maxlength="32"
                   class="flex_inside_main_box"
                   data-bind="textInput: strawLabel,
                              attr: {placeholder: getTranslation('Label')},
                              disable: fetchInProgress() || updateInProgress(),
                              hasFocus: !(fetchInProgress() || updateInProgress())">

            <input type="submit"
                   class="confirm"
                   data-bind="value: getTranslation('Apply'),
                              disable: fetchInProgress() || updateInProgress(),
                              click: applyLabel">
        </form>
    </div>
`, params => ({
    name: "SetStrawLabel",
    width: 300,
    title: getTranslation("Set straw label"),
    anchor: params.eventTarget,
    closeOthers: true,
}));
